import useStyles from "../../../../../style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import EAIcon from "../../../../../components/common/EAIcon";
import React, {useCallback} from "react";
import i18next from "i18next";
import {Typography} from "@mui/material";
import EAButton from "../../../../../components/common/EAButton";
import {useFormContext, useWatch} from "react-hook-form";
import _ from "lodash";
import clsx from "clsx";

const FinalEditStep = ({calculation, onCancel, onContinue}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const {
    formState,
    handleSubmit,
  } = formMethods;

  let calculationName = useWatch({ name: `name`});

  const submitForm = useCallback((data) => {
    if (_.isEmpty(formState.errors)) {
      onContinue(data);
    }
  }, [formState, onContinue]);

  const handleSave = useCallback(() => {
    handleSubmit(submitForm)();
  }, [handleSubmit, submitForm]);

  return (
    <>
      <div className={classes.deleteRoot}>

        {/* Title */}
        <div className={classes.imgWrapperAlert}>
          <EAIcon icon={"warning-snack"} className={classes.imgContentWarning}/>
        </div>
        <span className={classes.bolder}>
          {i18next.t("calculator.dialog.final.editCalcul", { status: i18next.t(`calculator.table.status.${calculation.enabled}`) })}
        </span>
        <span className={classes.subtitle}>
          {i18next.t("calculator.dialog.final.resultUpdate")}
        </span>
      </div>

      {/* Content */}
      <div key={calculation.id} style={{marginTop: "24px"}}>
        <div className={classes.calculInfoBlocWrapper}>
          <Typography className={clsx(classes.calculInfoBlocContent, "dt")}>
            {calculationName}
          </Typography>
        </div>
      </div>

      {/* Action */}
      <div className={classes.finalButtonWrapper}>
        <EAButton
          onClick={onCancel}
          content={i18next.t("cancel")}
          colorVariant={"secondary"}
          size={"large"}
        />
        <EAButton
          onClick={(e) => {
            e.preventDefault();
            handleSave();
          }}
          content={i18next.t("validate")}
          colorVariant={"warningDark"}
          size={"large"}
        />
      </div>
    </>
  )
}

export default FinalEditStep;
