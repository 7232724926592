import React, { useState, useContext, useRef, useEffect } from "react";
import EADialog from "components/common/EADialog";
import EALinearProgress from "components/common/EALinearProgress";
import { Context } from "states/Store";
import { ERROR, Exports } from "services/common/Constants";
import { getExportFileFromAccountDocuments } from "services/edge/AccountService";
import i18next from "i18next";
import { Box, Typography } from "@mui/material";
import EAButton from "components/common/EAButton";
import { isAbortError } from "services/common/ApiUtils";

function DownloadDialog({
  isOpen = false,
  exportType,
  count = 0,
  filters,
  isAnswerFilterActive = false,
  sortModel,
  columnsVisibility,
  onCancel = () => {},
}) {
  const [state, dispatch] = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);

  const abortCtrlRef = useRef();

  useEffect(() => {
    (async function downloadExport () {
      setIsLoading(true);
      if(abortCtrlRef.current) {
        abortCtrlRef.current.abort();
      }

      abortCtrlRef.current = new AbortController();
      const signal = abortCtrlRef.current.signal;
      
      let file = null;
      const exportDto = { columnsVisibility };
      switch (exportType) {
        case Exports.CSV:
        case Exports.EXCEL:
          try {
            file = await getExportFileFromAccountDocuments(
              state.account.id,
              exportType,
              filters,
              isAnswerFilterActive,
              sortModel,
              exportDto,
              null,
              signal
            );
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
              setIsLoading(false);
            }
          }
          break;
        default:
          console.log("Type d'export tiers non géré");
      }
  
      if (file !== null) {
        setIsLoading(false);
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([file.arrayBuffer]));
        a.download = file.filename;
        a.click();
      }
    })();
    
    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [state.account, exportType, filters, isAnswerFilterActive, sortModel, columnsVisibility, dispatch])

  return (
    <EADialog
      title={i18next.t("myDocuments.exportDialogTitle", { exportType: exportType === Exports.CSV ? "CSV" : "Excel" })}
      isOpen={isOpen}
      onCancel={onCancel}
      hasActions={false}
      maxWidth="sm"
      fullWidth
      isFeedBack={true}
    > 
      <Box>
        <Typography mb={2} variant="body2">
          {isLoading ? (
            i18next.t("myDocuments.exportStarted", { count })
          ) : (
            i18next.t("myDocuments.exportDownloaded")
          )}
        </Typography>
        <EALinearProgress isLoading={isLoading} delay={3000} />
        {!isLoading && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <EAButton 
              size={"small"}
              content={i18next.t("myDocuments.returnToList")}
              onClick={onCancel}
            />
          </Box>
        )}
      </Box>
    </EADialog>
  );
}
export default DownloadDialog;
