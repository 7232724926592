import React from "react";
import i18next from "i18next";
import { MenuItem } from "@mui/material";
import useStyles from "style/js-style/containers/users/users-list/UsersDataTableStyle";
import EAIcon from "components/common/EAIcon";

export const DocumentManagementActionMenu = ({
  documentsSelected,
  onOpenDeleteDialog,
  onOpenEditDialog
}) => {
  const { classes } = useStyles();
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState(false);

  const handleClick = () => {
    setActionMenuIsOpen(!actionMenuIsOpen);
  };

  return (
    <div
      className={documentsSelected.length < 1 ? classes.dnone : classes.actionMenuWrapper}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick} >
      <div className={classes.actionMenuTitle}>
        {i18next.t("actions")}
        <EAIcon 
          icon={!actionMenuIsOpen ? "arrow/arrow-to-down_small" : "arrow/arrow-to-up_small"}
          className={classes.titleArrow} 
        />
      </div>

      <div className={actionMenuIsOpen ? "" : classes.dnone}>
        <div className={classes.actionMenuSeparate} />
        <MenuItem
          className={classes.actionMenuItem}
          disabled={false}
          onClick={() => onOpenEditDialog(documentsSelected)}>
          {i18next.t("demandes.documentManagement.tableAction.modify")}
        </MenuItem>
        <MenuItem
          className={classes.actionMenuItem}
          disabled={false}
          onClick={() => onOpenDeleteDialog(documentsSelected)}>
          {i18next.t("demandes.documentManagement.tableAction.delete")}
        </MenuItem>
      </div>
    </div>
  );
};
