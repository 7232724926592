import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

export default function EALinearProgress({
  delay = 1000,
  start = 0,
  end = 80,
  step = 5,
  isLoading = true,
  label
}) {
  const [progress, setProgress] = React.useState(start);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress >= end ? end : prevProgress + step);
    }, delay);
    
    if(!isLoading) {
      setProgress(100);
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [delay, start, end, step, isLoading]);

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={progress}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
         {label} {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
