import i18next from "i18next";
import React from "react";

export const CountSelectedRow = ({ data, variant }) => {
  let rowCount = data?.length || 0;
  return (
    rowCount > 0 && (
      <b style =
        { variant === "ml2"
          ? {marginLeft: "2rem" }
          : null
        }
      >
        { rowCount + " " }
        { rowCount > 1
          ? i18next.t("selectedLines")
          : i18next.t("selectedLine")
        }
      </b>
    )
  )
}
