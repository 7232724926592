import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    flex: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginBottom: "8px",
      marginTop: "32px"
    },
    flexBetween : {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "12px",
    },
    boxContainer : {
        padding: "18px 26px",
        borderRadius: 12,
        backgroundColor: theme.palette.background.greyLight,
        shadowColor: "rgba(0, 0, 0, 0.07)",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowRadius: 5,
        shadowOpacity: 1
    },
    divider: {
        margin:"8px 0"
    },
    switchLabel : {
        color: theme.palette.primary.main,
        "& *": {
            fontSize: "14px",
            fontWeight: "700"
        }
    },
    inputId: {
        width: "270px",
        borderRadius: "6px",
        backgroundColor: theme.palette.background.white
    },
    iconAction : {
        border: "1px solid",
        borderRadius: "15px",
        borderColor: theme.palette.grey.grey300,
        color: theme.palette.primary.main,
        boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
    },
    archive: {
        padding: "2px"
    },
    updateComment: {
        fontSize: "12px",
        marginLeft: "8px",
        color: theme.palette.grey.grey600,
    }
}));

export default useStyles;
