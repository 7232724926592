import { Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";
import i18next from "i18next";
import TPSearchZone from "./TPSearchZone";

const useStyles = makeStyles()((theme) => ({
	title: {
        marginBottom: "22px"
    },
}));

function TPSearch() {
	const { classes } = useStyles();

    return (
        <>
            <Typography variant="h2" className={classes.title}>
                {i18next.t("addThirdparty.title")}
            </Typography>
            <TPSearchZone/>
        </>
    );
}

export default TPSearch;
