import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    spinner : {
      position: 'absolute',
      marginLeft: '14px',
      color: '#373534',
      opacity: '0.5',
    }
}));

function EASpinner({size=20, style}) {
    const { classes } = useStyles();
    return (
      <CircularProgress
        className={classes.spinner}
        size={size}
        style={style}
      />
    )
}

export default EASpinner;