import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  rootRow: {
    backgroundColor: "white",
    width: "100%",
    height: "640px",
    padding: "28px 22px",
    display: "flex",
    gap: "15px",
    borderRadius: "0 12px 12px 12px",
  },
  rootColumn: {
    backgroundColor: "white",
    width: "100%",
    height: "1690px !important",
    padding: "28px 22px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    borderRadius: "0 12px 12px 12px",
  },
  elementWrapper: {
    height: "100%",
    padding: "24px 20px",
    overflow: "hidden",
    borerRadius: "12px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",

  },
  graphAreaTitle: {
    textTransform: "none",
    color: "#373534",
    fontFamily: "Metric",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "14px",
  },
  typeWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circularType: {
    width: "300px",
    height: "300px",
    marginBottom: "50px",
  },
  lineTypeWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  lineTypeWrapperRow: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    justifyContent: "center",
    alignItems: 'flex-start',
    padding: "55px 0 0 32px",
    position: "relative",
  },
  lineType: {
    width: "100%",
    height: "50px"
  },
  lineTypeColumn: {
    height: "80%",
    width: "calc(100% / 8)"
  },
  legend: {
    width: "50%",
  },
  legendVariante: {
    width: "50%",
    position: 'absolute',
    bottom: "24px",
    left: "50px"
  },
  skeletonRoot: {
    width: "100%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mapToolBar: {
    position: "absolute",
    top: "-37px",
    right: 0,
    width: "88px",
    height: "25px",
  },
  emptyInCircle: {
    width: "150px",
    height: "150px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "absolute",
    top: "calc(50% - 75px - 24px)",
    right: "calc(50% - 75px)"
  },
}));

export default useStyles;
