import {eaFetch} from "../common/ApiUtils";
import {getEnv} from "../../config/Config";

export async function getDossierEvent(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/event/dossiers`
  );
}

export async function getDocumentEvent(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/event/documents`
  );
}

export async function getUserEvent(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/event/users`
  );
}

export async function getThirdpartyEvent(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/event/thirparty`
  );
}

export async function getAllUserEventSubscriptions(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/event/notifications`
  );
}

export async function updateUserEventSubscriptions(accountId, eventSubscriptions, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PATCH",
    `365/account/${accountId}/event/notifications`,
    eventSubscriptions
  );
}

export async function getAuditTrail(accountId, thirdpartyId, dossierId, documentId, dispatch) {
  let url = `365/account/${accountId}/event/download?thirdpartyId=${thirdpartyId}`;
  if (dossierId != null) {
    url = url + `&dossierId=${dossierId}`;
  }  
  if (documentId != null) {
    url = url + `&documentId=${documentId}`;
  }
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    url
  );
}
