import React, { useState } from "react";
import useStyles from "style/js-style/containers/administration/roles/privileges/RessourcePaginationStyle";
import { Pagination } from "@mui/material";

function RessourcePagination(props) {
  const { totalRecords, pageSize, onPageChange } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const { classes } = useStyles();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  return (
    <>
      {(totalRecords > pageSize) &&
        <Pagination
          className={classes.root}
          count={Math.ceil(totalRecords / pageSize)}
          page={currentPage}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
          color="primary"
        />}
    </>
  );
}

export default RessourcePagination;
