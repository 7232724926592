import React from "react";
import ROUTES from "../../../config/Routes";
import i18next from "i18next";
import { EADataGridCell } from "../../../components/common/datatable/EADataGrid";

export const balanceSheetColumns = () => [
  {
    field: "balanceSheetDate",
    headerName: i18next.t("lastBalanceSheetDate"),
    align: "center",
    flex: 0.17,
    renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
  },
  {
    field: "turnover",
    headerName: i18next.t("turnover"),
    flex: 0.17,
    align: "center",
    renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
  },
  {
    field: "workforce",
    headerName: i18next.t("workforce"),
    flex: 0.17,
    align: "center",
    renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
  },
];

export const principalsColumns = () => [
  {
    field: "function",
    headerName: i18next.t("function"),
    flex: 0.17,
    renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
  },
  {
    field: "name",
    headerName: i18next.t("lastName"),
    flex: 0.17,
    renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
  },
  {
    field: "firstname",
    headerName: i18next.t("firstName"),
    flex: 0.17,
    renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
  },
];

export const createDossierFromTp = (
  accountId,
  thirdparty,
  history,
  location
) => {
  history.push(`/${accountId}/${ROUTES.TP_ADD_DOSSIER.replace(':thirdpartyId', thirdparty.thirdpartyId)}`, {
    prevPath: location.pathname,
  });
};
