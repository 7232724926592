import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dropZone: {
    border: "dashed 2px " + theme.palette.grey.grey300,
    padding: "13px",
    borderRadius: "12px",
    minWidth: "253px",
    maxHeight: "175px",
    background: "white",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  innerDropZone: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "9px",
    overflow: 'hidden',
    zIndex: 1,
  },
  iconImg: {
    backgroundColor: "#F2F3F766",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    padding: "7px",
  },
  dropZoneNotice: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    color: "rgba(126, 126, 143, 1)",
  },
  boldText: {
    fontWeight: "700",
    color: theme.palette.grey.grey700,
    fontSize: "12px",
  },
  boldGreenText: {
    fontWeight: "700",
    color: theme.palette.primary.primary600,
    fontSize: "12px",
  },
  boldErrorText: {
    fontWeight: "700",
    color: theme.palette.error.error500,
    fontSize: "12px",
  },
  subText: {
    color: theme.palette.grey.grey500,
    fontSize: "10px",
    fontStyle: "italic",
    marginBottom: "4px"
  },
  upButton: {
    color: "white !important",
    boxShadow: "none !important",
  },
  dropBackGround: {
    zIndex: 0,
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
  }
}));

export default useStyles;