import * as am5 from "@amcharts/amcharts5";
import { formatNumber } from "./Utils";
import {isEqual, isEmpty, unionBy} from "lodash";
import { geoDataCountries } from "./geoDataCountries";
import countries from "i18n-iso-countries";
import i18next from "i18next";
import { InduedAllScores, splitInduedFiltersByScore } from "./InduedUtils";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { errorColor, orangeColor, primary, purpleColor } from "style/globalStyle";

export const majorActivitiesColors = {
  A: "#10154D",
  B: "#1A237E",
  C: "#5C6BC0",
  D: "#3949AB",
  E: "#2735C1",
  F: "#4350D9",
  G: "#536DFE",
  H: "#5864DD",
  I: "#6D78E1",
  J: "#8C9EFF",
  Z: "#9FA8DA",
};

export const documentsColorsByStatus = {
  nbValid: primary, //"#2D9F40"
  nbAbsent: "#C4C4C4",
  nbCtrlInProgress: "#3949AB", //infoColor
  nbActionDORequired: orangeColor, //"#FF9A00"
  nbBelowExpectation: purpleColor, //"#6D00CC"
  nbInvalid: errorColor, //"#CC0001"
};

const statusCodeToCountType = {
  MISSING: "nbAbsent",
  IN_PROGRESS: "nbCtrlInProgress",
  ACTION_REQUIRED: "nbActionDORequired",
  BELOW_EXPECTATION: "nbBelowExpectation",
  NOT_VALID: "nbInvalid",
  VALID: "nbValid"
}

const getStatusCodeByStatusCountType = (statusCountType) => {
  return Object.keys(statusCodeToCountType).find(key => statusCodeToCountType[key] === statusCountType);
}

// Hide label if lower than 4%
export const hidePieSliceLabel = (value, total) => (value / total) * 100 < 3.5;

export const getShownPieDataByBarFilters = (data, filters) => {
  // Split bar filters by condition
  const splitFilters = splitBarFiltersByCondition(filters, getStatusFromFilter);
  // Calc sum of split filters
  Object.keys(splitFilters).forEach(res => {
    splitFilters[res] = splitFilters[res].map(x=>x.value).reduce((a, b) => a + b, 0)
  })
  // Map new total items to show
  const total = formatNumber(Object.values(splitFilters).reduce((a, b) => a + b, 0));
  return {
    data: data
      ?.map((x) => ({
        ...x,
        value: splitFilters[x.id],
        formattedValue: formatNumber(splitFilters[x.id]),
        labelSettings: {
          forceHidden: hidePieSliceLabel(splitFilters[x.id], total),
        },
      }))
      ?.filter(x => x.value && x.value !== 0),
    total,
  };
};

const splitBarFiltersByCondition = (arr, condition) => {
  const result = {};
  arr.forEach(item => {
    const key = condition(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
  });
  return result;
}

const getStatusFromFilter = filter => {
  const idParts = filter.id.split('-');
  if (idParts.length > 1) {
    return idParts[idParts.length - 1];
  }
  return ""
};

export const filterCountByStatus = (filters, status, item) => {
  return filters?.some(filter => filter.id === status) ? item[status] : 0;
}

const totalFilterCountByStatus = (filters, item) => {
  return Object.keys(documentsColorsByStatus).reduce((sum, status) => sum + filterCountByStatus(filters, status, item), 0);
};

/**
 * Returns the color to use for a value in a gradiant
 *
 * @param min : Array of the lowest color of the gradiant in RGB
 * @param max : Array of the Highest color of the gradiant in RGB
 * @param percent : The percentage in the gradiant
 * @returns Array of rgb colors
 */
export function pickHex(min, max, percent) {
  let w1 = percent;
  let w2 = 1 - w1;
  let rgb = [
    Math.round(min[0] * w1 + max[0] * w2),
    Math.round(min[1] * w1 + max[1] * w2),
    Math.round(min[2] * w1 + max[2] * w2),
  ];
  return rgb;
}

export const getTooltip = (chart) => {
  const tooltip = am5.Tooltip.new(chart.root, {
    getFillFromSprite: false,
    getStrokeFromSprite: false,
    autoTextColor: false,
    getLabelFillFromSprite: false
  });
  tooltip.get("background").setAll({
    fill: am5.color("#535362"),
    stroke: am5.color("#535362"),
  });
  return tooltip;
};

export const getTotalGeoData = (data) =>
  data?.map((x) => x.count).reduce((partialSum, a) => partialSum + a, 0) || 0;
export const getHighestGeoDataValue = (data) =>
  Math.max(...data?.map((x) => x.count)) || 0;

export const mapWorldData = (countriesData) => {
  const geoDataCountriesValues = geoDataCountries.map((x) => ({
    ...x,
    name: countries.getName(x.id, "fr"),
    value: 0,
    formattedValue: "0",
  }));

  let tpCountriesMappedData = countriesData?.map((x) => ({
    id: x.countryCode,
    value: x.count || 0,
    formattedValue: formatNumber(x.count || 0),
    name: countries.getName(x.countryCode, "fr"),
  }));
  return unionBy(tpCountriesMappedData, geoDataCountriesValues, "id");
};

export const mapDepartmentData = (departmentData) => {
  const departments = Array.from({ length: 96 }, (v, k) => {
    const depNbr = k + 1;
    return {
      id: "FR-" + (k < 9 ? "0" + depNbr : depNbr),
      value: 0,
      formattedValue: "0",
    };
  });
  departments.push(
    { id: "FR-6AE", value: 0, formattedValue: "0" }, // Alsace (Bas-Rhin + Haut-Rhin)
    { id: "FR-2A", value: 0, formattedValue: "0" }, // Corse Nord
    { id: "FR-2B", value: 0, formattedValue: "0" }, // Corse Sud
    { id: "FR-973", value: 0, formattedValue: "0", name: "Guyane française" },
    { id: "FR-971", value: 0, formattedValue: "0" }, // Guadeloupe
    { id: "FR-972", value: 0, formattedValue: "0" }, // Martinique
    { id: "FR-976", value: 0, formattedValue: "0" }, // Mayotte
    { id: "FR-974", value: 0, formattedValue: "0" }, // Réunion
    { id: "FR-PF", value: 0, formattedValue: "0", name: "Polynésie française" }
  );

  const basRhinCount = departmentData?.find((x) => x.department === "67")?.count || 0;
  const hautRhinCount = departmentData?.find((x) => x.department === "68")?.count || 0;

  const departmentMappedData = departmentData?.map((x) => ({
    id: x.department === "987" ? "FR-PF" : "FR-" + x.department,
    value: x.count || 0,
    formattedValue: formatNumber(x.count || 0),
  }));
  departmentMappedData.push({
    id: "FR-6AE",
    value: basRhinCount + hautRhinCount,
    formattedValue: formatNumber(basRhinCount + hautRhinCount),
  });

  return unionBy(departmentMappedData, departments, "id");
};

export const mapDepartmentsFilters = (filters) => {
  let res = filters;
  res = filters.map((x) => ({
    ...x,
    id: getMapDepartmentsByChartCode(x.id),
  }));
  return res;
};

export const deleteFilterChipHandler = (chartFilters, type, filter) => {
  const newFilterArr = chartFilters[type].filter((x) => !isEqual(x, filter));
  if(newFilterArr.length > 0) {
    return {
      ...chartFilters,
      [type]: newFilterArr,
    };
  } else {
    delete chartFilters[type];
    return {...chartFilters};
  }
}

export const getGraphFiltersKey = (accountId, tab, subTab) => {
  let res = `${accountId}_${tab}`
  if(subTab) {
    res+= `_${subTab}`
  }
  res+="_graphFilters";
  return res;
}

// Map Specific cases and return array of possible departments for each one
const getMapDepartmentsByChartCode = (depChartCode) => {
  switch (depChartCode) {
    case "FR-6AE": // Alsace
      return ["67", "68"];
    case "FR-2A": // Corse du Nord
      return ["200", "201"];
    case "FR-2B": // Corse du Sud
      return ["202"];
    case "FR-PF": // Polynésie française
      return ["987"];
    default:
      // Remove "FR-" from filter Id
      return [depChartCode.slice(3)];
  }
};

export const buildBarStatusSerieItems = (type) => {
  return Object.keys(documentsColorsByStatus).map(key => (
    {
      label: localizeTranslate(i18next.t(`dashboard.${type}.status.${key}`)),
      value: key,
      settings: {
        fill: documentsColorsByStatus[key],
      },
    })
  )
}

export const buildPieStatusData = (data, type) => {
  const total = data.all;
  const filteredStates = Object.keys(documentsColorsByStatus).filter((x) => data[x] !== 0);
  return {
    data: filteredStates.map((k) => ({
      id: k,
      itemLabel: localizeTranslate(i18next.t(`dashboard.${type}.status.${k}`)),
      type: type,
      value: data[k],
      formattedValue: formatNumber(data[k]),
      settings: {
        fill: am5.color(documentsColorsByStatus[k]),
      },
      labelSettings: {
        forceHidden: hidePieSliceLabel(data[k], total),
      },
    })),
    total: formatNumber(total),
  }
}

export const mapResourceToBarStatusChart = (data, categoryField, categoryFieldCode, type) => {
  return data.map((x) => ({
    ...x,
    categoryField: localizeTranslate(x[categoryField]),
    categoryFieldCode: x[categoryFieldCode],
    formatted: {
      nbAbsent: formatNumber(x.nbAbsent),
      nbCtrlInProgress: formatNumber(x.nbCtrlInProgress),
      nbInvalid: formatNumber(x.nbInvalid),
      nbBelowExpectation: formatNumber(x.nbBelowExpectation),
      nbActionDORequired: formatNumber(x.nbActionDORequired),
      nbValid: formatNumber(x.nbValid),
      nbTotal: formatNumber(x.nbTotal),
    },
    type: type
  }))
  .sort((a, b) =>
    b.categoryField
      .trim()
      .toLowerCase()
      .localeCompare(a.categoryField.trim().toLowerCase())
  );
}

export const filterBarChartByPieStatusType = (pieFilters, countByType) => {
  if (isEmpty(pieFilters)) {
    return countByType;
  } else {
    return countByType?.map((x) => ({
      ...x,
      nbAbsent: filterCountByStatus(pieFilters, "nbAbsent", x),
      nbCtrlInProgress: filterCountByStatus(pieFilters, "nbCtrlInProgress", x),
      nbInvalid: filterCountByStatus(pieFilters, "nbInvalid", x),
      nbBelowExpectation: filterCountByStatus(pieFilters, "nbBelowExpectation", x),
      nbActionDORequired: filterCountByStatus(pieFilters, "nbActionDORequired", x),
      nbValid: filterCountByStatus(pieFilters, "nbValid", x),
      nbTotal : totalFilterCountByStatus(pieFilters, x),
      formatted: {
        nbAbsent: formatNumber(filterCountByStatus(pieFilters, "nbAbsent", x)),
        nbCtrlInProgress: formatNumber(filterCountByStatus(pieFilters, "nbCtrlInProgress", x)),
        nbInvalid: formatNumber(filterCountByStatus(pieFilters, "nbInvalid", x)),
        nbBelowExpectation: formatNumber(filterCountByStatus(pieFilters, "nbBelowExpectation", x)),
        nbActionDORequired: formatNumber(filterCountByStatus(pieFilters, "nbActionDORequired", x)),
        nbValid: formatNumber(filterCountByStatus(pieFilters, "nbValid", x)),
        nbTotal: formatNumber(totalFilterCountByStatus(pieFilters, x)),
      },
    }));
  }
}

// Build filters for thirdparties list from chart
export const buildThirdpartiesFilters = (filters) => {
  let countries, departments, majorActivities, contacts, indued, transparency, financialStrengthScore;
  // Risks Indued chart filters
  if(InduedAllScores.includes(filters?.pie?.[0]?.type)) {
    const induedFiltersByScore = splitInduedFiltersByScore(filters?.pie);
    indued = induedFiltersByScore;
  } else if (filters?.pie?.[0]?.type === 'financialStrength') {
    financialStrengthScore = filters?.pie?.map(x => x.id?.toUpperCase());
  } else if(filters?.bar?.length > 0) {
    // Transparency chart filters
    transparency = filters?.bar?.map(x => x.id);
  } else {
    // Thirdparties chart filters
    countries = filters?.map?.map(x => x.id);
    departments = filters?.map?.filter(x => x.id.startsWith("FR-"))?.map(x => x.id.substring(3));
    if(departments?.length > 0 ) {
      countries = ["FR"];
    }
    contacts = filters?.pie?.map(x => x.id.toUpperCase())
    majorActivities = filters?.treeMap?.map(x => x.id)
  }
  return {countries, departments, majorActivities, contacts, indued, transparency, financialStrengthScore};
}


// Build filters for documents list from chart
export const buildDocumentsFilters = (filters) => {
  let result = [];
  filters?.bar?.forEach(item => {
    let lastSeparatorIndex = item.id.lastIndexOf("-");
    let documentCode = item.id.slice(0, lastSeparatorIndex);
    let status = item.id.slice(lastSeparatorIndex + 1);
    if(documentCode === "all") documentCode = "";
    result.push({
      documentCode,
      start: null,
      end: null,
      status: getStatusCodeByStatusCountType(status)
    })
  });
  filters?.pie?.forEach(item => {
    result.push({documentCode: "", start: null, end: null, status: getStatusCodeByStatusCountType(item.id)})
  })
  return result;
}



// Build filters for dossiers list from chart
export const buildDossiersFilters = (filters) => {
  let result = [];
  let defaultValue = {
    //dossier
    dossierType: "",
    reference: "",
    start: null,
    end: null,
    status: "",
    //thirdparty
    name: "",
    code: "",
    companyIdType: "CIT003",
    identifier: "",
  }
  filters?.bar?.forEach(item => {
    let [dossierType, status] = item.id.split("-");
    if(dossierType === "all") dossierType = "";
    result.push({
      ...defaultValue,
      dossierType,
      status: getStatusCodeByStatusCountType(status)
    })
  });
  filters?.pie?.forEach(item => {
    result.push({
      ...defaultValue,
      status: getStatusCodeByStatusCountType(item.id)
    })
  })
  return result;
}


// ------- Filter Data for lists --------

export const filterDossiers = (dossiers, filters) => {
  let filteredDoss = dossiers;
  Object.keys(filters).forEach((type) => {
    if (!isEmpty(filters[type]) && !isEmpty(dossiers)) {
      switch (type) {
        case "bar":
          filteredDoss = [];
          filters[type].forEach((filter) => {
            filteredDoss = [
              ...filteredDoss,
              ...dossiers.filter((doss) => {
                return (
                  filter.keyLabel === doss.dossierTypeLibelle &&
                  filter.label === i18next.t(`dashboard.dossiers.status.${statusCodeToCountType[doss.statusCode]}`)
                );
              }),
            ];
          });
          break;
        case "pie":
          filteredDoss = filteredDoss?.filter((doss) =>
            filters[type].some((f) => f.id === statusCodeToCountType[doss.statusCode])
          );
          break;
        default:
          break;
      }
    }
  });
  return filteredDoss;
};

export const filterDocuments = (documents, filters) => {
  let filteredDocs = documents;
  Object.keys(filters).forEach((type) => {
    if (!isEmpty(filters[type]) && !isEmpty(documents)) {
      switch (type) {
        case "bar":
          filteredDocs = [];
          filters[type].forEach((filter) => {
            filteredDocs = [
              ...filteredDocs,
              ...documents.filter((doc) => {
                return (
                  filter.keyLabel === doc.nameFr &&
                  filter.label === i18next.t(`dashboard.documents.status.${statusCodeToCountType[doc.statusCode]}`)
                );
              }),
            ];
          });
          break;
        case "pie":
          filteredDocs = filteredDocs?.filter((doc) =>
            filters[type].some((f) => f.id === statusCodeToCountType[doc.statusCode])
          );
          break;
        default:
          break;
      }
    }
  });
  return filteredDocs;
};
