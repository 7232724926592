import React from "react";
import { makeStyles } from 'tss-react/mui';
import i18next from "i18next";
import EAButton from "../EAButton";

const useStyles = makeStyles()(() => ({
  createBtn: {
    marginLeft: "8px",
  },
  crossBtn: {
    fontSize: "1rem",
    fontWeight: 500,
    marginRight: ".5rem",
  },
}));

export const ThirdpartyAddButton = ({onClickHandler, disabled}) => {
    return (
        <DataTableButton
            onClickHandler={onClickHandler}
            label={i18next.t("addThirdparty.title")}
            disabled={disabled}
        />
    )
};

const DataTableButton = ({onClickHandler, label, disabled = false}) =>{
    const { classes } = useStyles();
    return (
          <EAButton
            content={
              <>
                <span className={classes.crossBtn}>+</span>
                {label}
              </>
            }
            onClick={onClickHandler}
            size="small"
            className={classes.createBtn}
            disabled={disabled}
          />
    );
}

export default DataTableButton;
