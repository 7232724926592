import { Container, Paper, Typography, Alert, AlertTitle } from "@mui/material";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import React, { useContext, useCallback, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Layout from "./components/Layout";
import ROUTES from "./config/Routes";
import { Context } from "./states/Store";
import { theme } from "./style/globalStyle";
import { withTranslation } from "react-i18next";
import Dossiers from "./containers/dossiers/dossiers-list/Dossiers";
import DossierCreation from "./containers/dossiers/create-dossier/index";
import TPSearch from "./containers/creation-form/thirdparty-search";
import DossierSheet from "./containers/dossiers/dossier-sheet/DossierSheet";
import Documents from "./containers/account-documents/Documents";
import Thirdparties from "./containers/thirdparties/thirdparties-list/Thirdparties";
import Users from "./containers/users/users-list/Users";
import UserProfile from "./containers/users/user-profile/UserProfile";
import EADocViewer from "./containers/doc-viewer";
import TPSheet from "./containers/thirdparties/thirdparty-sheet/TPSheet";
import UnAuthorized from "./components/UnAuthorized.js";
import { LicenseInfo } from '@mui/x-license-pro';
import DashBoard from "./containers/dashboard/Dashboard";
import AdminSettings from "./components/AdminSettings";
import EAButton from "./components/common/EAButton";
import i18n from "./i18n/i18n";
import { addLicense as am5AddLicence } from "@amcharts/amcharts5";
import Roles from "./containers/administration/roles/Roles";
import {
  RoleBasedAccessControlContext,
  getPrivilegeAscendants,
  NAVIGATION_REQUIRED_PRIVILEGES,
  SPECIFIC_PRIVILEGES,
} from "./services/common/RolesUtils";
import useKeycloak from "components/common/hooks/useKeycloak";
import { initRum } from "components/common/hooks/useDatadogRum";
import Events from "./containers/events/Events";
import { initLocalize } from "i18n/LocalizeUtils";
import Loader from "containers/loader/Loader";
import { getCurrentUser } from "services/edge/UserService";
import { datadogRum } from '@datadog/browser-rum';


LicenseInfo.setLicenseKey(
  "937431cab6a61afd4f8f41cf81ca8ee4Tz03OTc4OSxFPTE3MzMwNDU0MTgwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);

//chart licence
am5AddLicence("AM5C374640337");
//map licence
am5AddLicence("AM5M374640337");

const AppRoute = ({
  home,
  path,
  component: Component,
  layout: Layout,
  variant,
  crumbs,
  isAuthorized,
  ...rest
}) => (
  <Route
    {...rest}
    path={path}
    render={(props) => (
      <Layout variant={variant} crumbs={crumbs} isAuthorized={isAuthorized}>
        <Component {...props} {...rest} />
      </Layout>
    )}
  />
);

// TODO: Change the no match page !
function NoMatch({ home }) {
  let location = useLocation();
  let history = useHistory();

  return (
    <Layout home={home}>
      <Container style={{ padding: "4em", margin: "0 auto", width: "50%" }}>
        <Paper>
          <Alert severity="warning">
            <AlertTitle>Chemin introuvable</AlertTitle>
            <p>Le chemin {location.pathname} n'existe pas !</p>
            <Typography variant="inherit">
              <EAButton
                onClick={() => history.push("/")}
                content={i18n.t("goToHome")}
              />
            </Typography>
          </Alert>
        </Paper>
      </Container>
    </Layout>
  );
}

const getPathWithAccount = (path) => `/:accountId/${path}`;

function App() {
  const [state, dispatch] = useContext(Context);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [userLang, setUserLang] = useState();
  const { loginKeycloak } = useKeycloak()
  

  useEffect(() => {
    initRum();
    loginKeycloak();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userLang) {
      initLocalize(userLang, dispatch);
    }
  }, [dispatch, userLang]);

  useEffect(() => {
    if (state.account && state.keycloak) {

      window.StonlyWidget('sendData', {
        contactForm: {
          email: state.keycloak.tokenParsed.email,
          additionalInfo: {
            origine: "365",
            nom: state.keycloak.tokenParsed.family_name,
            prenom: state.keycloak.tokenParsed.given_name,
            email: state.keycloak.tokenParsed.email,
            role: isGlobalAdmin ? "SUPER_ADMIN" : state.roles.map(r => r.name).toString(),
            compte: state.account.account,
            navigateur: navigator.userAgent
          }
        }
      });
    }
  }, [state.roles, state.account, state.keycloak, isGlobalAdmin]);

  useEffect(() => {
    async function getCurrentUserInfo() {
      let result = await getCurrentUser();
      setIsGlobalAdmin(result.content.superAdmin);
      setUserLang(result.content.lang);
    }
    if (state.user?.id) {
      getCurrentUserInfo()

      datadogRum.setUser({
        id: state.user.id,
        email: state.user.email
    })

    }
  }, [state.user]);

  // For non scoped resources: this makes checks against all user roles
  const hasAnyOfPrivileges = useCallback((...privileges) => {
    let bHasAnyOfPrivileges = false;
    if (isGlobalAdmin) {
      bHasAnyOfPrivileges = true;
    } else {
      let involvedRoles = state.roles || [];
      if (!_.isEmpty(involvedRoles)) {
        bHasAnyOfPrivileges = involvedRoles.some(ownedRole => {
          let grantedPrivilegesNames = ownedRole.flatPrivileges.map(p => p.name)
          return privileges.some(priv => {
            return grantedPrivilegesNames.includes(priv.name) ||
              [...getPrivilegeAscendants(priv)].some(asc => {
                return grantedPrivilegesNames.includes(asc)
              });
          })
        })
      }
    }
    return bHasAnyOfPrivileges;
  }, [state.roles, isGlobalAdmin]);

  const hasAnyOfPrivilegesOnScopedResourceImpl = useCallback((scopedResource, ...privileges) => {
    let bHasAnyOfPrivileges = false;
    if (isGlobalAdmin) {
      bHasAnyOfPrivileges = true;
    } else {
      const scopedResourceAuthorizingRoles = scopedResource?.authorizingRoles || [];

      if (!_.isEmpty(state.roles)) {
        // Pick up roles from state.roles using their ids
        const involvedRoles = state.roles.filter(r => scopedResourceAuthorizingRoles.includes(r.id));

        if (!_.isEmpty(involvedRoles)) {
          bHasAnyOfPrivileges = involvedRoles.some(ownedRole => {
            let grantedPrivileges = ownedRole.flatPrivileges;
            let grantedPrivilegesNames = ownedRole.flatPrivileges.map(p => p.name)
            return privileges.some(priv => {

              // Verify if the privilege is specific to a resource type
              const specificPrivilege = SPECIFIC_PRIVILEGES.find(sp => sp.privilege === priv.name);

              // Get the id of the resource if the privilege is specific to a resource type
              let id = null;
              if (specificPrivilege) {
                switch (specificPrivilege.type) {
                  case 'DOCUMENT':
                    id = scopedResource.typeDocumentId;
                    break;
                  case 'DOSSIER':
                    id = scopedResource.dossierId;
                    break;
                  case 'THIRDPARTY':
                    id = scopedResource.thirdpartyId;
                    break;
                  default:
                    break;
                }
              }

              // Verify if the privilege is granted on the resource
              const isPrivilegeOnRessourceGranted = !specificPrivilege ||
                (specificPrivilege && grantedPrivileges?.some(p => p.name === priv.name && p.ressources.includes(id)));

              return (isPrivilegeOnRessourceGranted) &&
                (grantedPrivilegesNames.includes(priv.name) || [...getPrivilegeAscendants(priv)].some(asc => {
                  return grantedPrivilegesNames.includes(asc)
                }))
            })
          })
        }
      }
    }

    return bHasAnyOfPrivileges;

  }, [state.roles, isGlobalAdmin]);

  const rbacContext = useMemo(
    () => ({
      hasAnyOfPrivileges: hasAnyOfPrivileges,
      hasAnyOfPrivilegesOnScopedResourceImpl: hasAnyOfPrivilegesOnScopedResourceImpl,
      isGlobalAdmin: isGlobalAdmin
    }),
    [isGlobalAdmin, hasAnyOfPrivileges, hasAnyOfPrivilegesOnScopedResourceImpl]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <RoleBasedAccessControlContext.Provider value={rbacContext}>
              <Router>
                <Switch>
                  <AppRoute
                    exact
                    path={[`/${ROUTES.DEFAULT}`, getPathWithAccount(ROUTES.DASHBOARD)]}
                    layout={Layout}
                    active
                    component={DashBoard}
                    crumbs={{ data: [] }}
                  />
                  <AppRoute
                    exact
                    path={[`/${ROUTES.ANSWER}`]}
                    // no layout
                    layout={Layout}
                    active
                    component={Loader}
                    crumbs={{ data: [] }}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.DOSSIERS)}
                    layout={Layout}
                    active
                    component={Dossiers}
                    crumbs={{ data: [{ label: "breadcrumb.dossiers" }] }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.dossier)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ADMIN)}
                    layout={Layout}
                    component={AdminSettings}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.admin)}
                    variant={"withoutAppBar"}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ARCHIVES.DOSSIERS)}
                    layout={Layout}
                    active={false}
                    component={Dossiers}
                    crumbs={{
                      data: [
                        { label: "breadcrumb.archives" },
                        { label: "breadcrumb.dossiers" },
                      ],
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.dossier)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.TP_ADD_DOSSIER)}
                    layout={Layout}
                    component={DossierCreation}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.thirdparties",
                          path: ROUTES.THIRDPARTIES,
                        },
                      ],
                      hasAdditionalData: true,
                    }}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.DOSSIER_DETAIL)}
                    layout={Layout}
                    component={DossierSheet}
                    active
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.dossiers",
                          path: ROUTES.DOSSIERS,
                        },
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.dossier)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.DOSSIER_DOCUMENT_DETAIL)}
                    layout={Layout}
                    component={EADocViewer}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.dossiers",
                          path: ROUTES.DOSSIERS,
                        },
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.document)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ARCHIVES.DOSSIER_DOCUMENT_DETAIL)}
                    layout={Layout}
                    component={EADocViewer}
                    crumbs={{
                      data: [
                        { label: "breadcrumb.archives" },
                        {
                          label: "breadcrumb.dossiers",
                          path: ROUTES.ARCHIVES.DOSSIERS,
                        },
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.document)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ARCHIVES.DOSSIER_DETAIL)}
                    layout={Layout}
                    component={DossierSheet}
                    active={false}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.archives"
                        },
                        {
                          label: "breadcrumb.dossiers",
                          path: ROUTES.ARCHIVES.DOSSIERS
                        }
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.dossier)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.THIRDPARTIES)}
                    layout={Layout}
                    active
                    component={Thirdparties}
                    crumbs={{ data: [{ label: "breadcrumb.thirdparties" }] }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.thirdparty)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ARCHIVES.THIRDPARTIES)}
                    layout={Layout}
                    active={false}
                    component={Thirdparties}
                    crumbs={{
                      data: [
                        { label: "breadcrumb.archives" },
                        { label: "breadcrumb.thirdparties" },
                      ],
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.thirdparty)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.THIRDPARTY_ADD)}
                    layout={Layout}
                    component={TPSearch}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.thirdparties",
                          path: ROUTES.THIRDPARTIES,
                        },
                        { label: "breadcrumb.addThirdparty" },
                      ],
                    }}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.EVENTS)}
                    layout={Layout}
                    component={Events}
                    crumbs={{ data: [{ label: "breadcrumb.events" }] }}
                    isAuthorized={isGlobalAdmin}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.THIRDPARTY_DETAIL)}
                    layout={Layout}
                    active
                    component={TPSheet}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.thirdparties",
                          path: ROUTES.THIRDPARTIES
                        }
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.thirdparty)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.THIRDPARTY_DOSSIER_DETAIL)}
                    layout={Layout}
                    component={DossierSheet}
                    active
                    crumbs={{
                      data: [],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.dossier)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.THIRDPARTY_DOCUMENT_DETAIL)}
                    layout={Layout}
                    component={EADocViewer}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.thirdparties",
                          path: ROUTES.THIRDPARTIES,
                        },
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.document)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.THIRDPARTY_DOSSIER_DOCUMENT_DETAIL)}
                    layout={Layout}
                    component={EADocViewer}
                    crumbs={{
                      data: [],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.document)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ARCHIVES.THIRDPARTY_DOSSIER_DOCUMENT_DETAIL)}
                    layout={Layout}
                    component={EADocViewer}
                    crumbs={{
                      data: [],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.document)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ARCHIVES.THIRDPARTY_DETAIL)}
                    layout={Layout}
                    active={false}
                    component={TPSheet}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.archives"
                        },
                        {
                          label: "breadcrumb.thirdparties",
                          path: ROUTES.ARCHIVES.THIRDPARTIES
                        }
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.thirdparty)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ARCHIVES.THIRDPARTY_DOSSIER_DETAIL)}
                    layout={Layout}
                    component={DossierSheet}
                    active
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.archives"
                        }
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.dossier)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.DOCUMENTS)}
                    layout={Layout}
                    component={Documents}
                    crumbs={{ data: [{ label: "breadcrumb.documents" }] }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.document)}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.PROFILE)}
                    layout={Layout}
                    component={UserProfile}
                    variant={"withoutAppBar"}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.USERS)}
                    layout={Layout}
                    component={Users}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.user)}
                    variant={"withoutAppBar"}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.ROLES)}
                    layout={Layout}
                    component={Roles}
                    isAuthorized={ hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.role) }
                    variant={"withoutAppBar"}
                  />
                  <AppRoute
                    exact
                    path={getPathWithAccount(ROUTES.DOCUMENT_DETAIL)}
                    layout={Layout}
                    component={EADocViewer}
                    crumbs={{
                      data: [
                        {
                          label: "breadcrumb.documents",
                          path: ROUTES.DOCUMENTS,
                        },
                      ],
                      hasAdditionalData: true,
                    }}
                    isAuthorized={hasAnyOfPrivileges(...NAVIGATION_REQUIRED_PRIVILEGES.document)}
                  />
                  <Route path="/unauthorized">
                    <UnAuthorized />
                  </Route>
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </Router>
            </RoleBasedAccessControlContext.Provider>
          </ScopedCssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default withTranslation()(App);
