import React, { useContext, useState } from "react";
import {
  FormGroup,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import i18next from "i18next";
import { useParams } from "react-router";
import { ERROR } from "../../services/common/Constants";
import {
  checkThirdpartyCode,
  updateTPCode,
} from "../../services/edge/ThirdpartyService";
import { isEmpty } from "../../services/common/Utils";
import { Context } from "../../states/Store";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import EADialog from "../common/EADialog";
import FeedBackPopUp from "../FeedBackPopUp";
import useStyles from "../../style/js-style/components/thirdparties/ThirdpartyUpdateDialogStyle";

const InputBloc = ({ label, children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.formContainer}>
      <div className={classes.inputLabelWrapper}>
        <FormLabel className={classes.inputLabel}>
          {label}
        </FormLabel>
      </div>
      <div>
        <FormGroup>{children}</FormGroup>
      </div>
    </div>
  );
};

const FORM_ID = "tp-update-form-id";
const tpCodeMaxLength = 255;

const ThirdpartyUpdateDialog = ({
  isOpen,
  tpCode,
  onTpCodeChange,
  handleClose,
}) => {
  const { classes } = useStyles();
  const [dispatch] = useContext(Context);
  const [isThirdpartyUpdate, setIsThirdpartyUpdate] = useState(false);
  const { accountId, thirdpartyId } = useParams();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({ mode: "onChange", delayError: 500 });

  const verifyThirdpartyCode = AwesomeDebouncePromise(async (value) => {
    if (value && value.trim() !== "") {
      try {
        const thirdPartyToUpdate = {
          supplierCode: value.trim(),
        };
        await checkThirdpartyCode(accountId, thirdpartyId, thirdPartyToUpdate, null);
      } catch (error) {
        if (
          error?.message?.messages?.error &&
          error?.message?.messages?.error[0]?.code
        ) {
          return i18next.t(`infos.${error.message.messages.error[0].code}`);
        } else {
          return i18next.t("infos.INTERNAL_ERROR");
        }
      }
    }
  }, 1000);

  const handleCreation = async (data) => {
    if (isEmpty(errors)) {
      const thirdPartyToUpdate = {
        supplierCode: data.tpCode.trim(),
      };
      try {
        await updateTPCode(
          accountId,
          thirdpartyId,
          thirdPartyToUpdate,
          dispatch
        );
        setIsThirdpartyUpdate(true);
        onTpCodeChange(data.tpCode);
      } catch (error) {
        if (
          error?.message?.messages?.error &&
          error?.message?.messages?.error[0]?.code
        ) {
          setError("tpCode", {
            message: i18next.t(`infos.${error.message.messages.error[0].code}`),
          });
        } else {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "TP_CODE_EDIT_ERROR" },
          });
          handleClose();
        }
      }
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      title={i18next.t("updateThirdparty.title")}
      onCancel={handleClose}
      formId={FORM_ID}
      hasActions={!isThirdpartyUpdate}
      maxWidth={isThirdpartyUpdate ? "popupxs" : "popupsm"}
      fullWidth
      isFeedBack={isThirdpartyUpdate}
      validateBtnLabel={"valid"}
    >
      {/* After confirm */}
      {isThirdpartyUpdate && (
        <FeedBackPopUp
          onClick={handleClose}
          imgSrc={"/img/icons/pen.svg"}
          content={ i18next.t("updateThirdparty.thirdpartyUpdated") }
          imgSize={"small"}
          variant={"valid"}
        />
      )}

      {/* Before confirm */}
      {!isThirdpartyUpdate && (
        <form id={FORM_ID} onSubmit={handleSubmit(handleCreation)}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
              <InputBloc label={`${i18next.t("tpCode")} `}>
                <Controller
                  control={control}
                  name="tpCode"
                  defaultValue={tpCode || ""}
                  rules={{
                    maxLength: {
                      value: tpCodeMaxLength,
                      message: i18next.t("infos.E011_VAR", {
                        input: i18next.t("theTpCode"),
                        maxLength: tpCodeMaxLength,
                      }),
                    },
                    validate: (value) => verifyThirdpartyCode(value),
                  }}
                  render={({ field }) => (
                    <TextField
                      id="tpCode"
                      placeholder={i18next.t("thirdpartySheet.thirdCodePlaceholder")}
                      className={classes.descriptionInput}
                      variant="outlined"
                      error={!!errors.tpCode}
                      fullWidth
                      helperText={!!errors.tpCode ? errors.tpCode.message : ""}
                      {...field}
                    />
                  )}
                />
              </InputBloc>
            </Grid>
          </Grid>
        </form>
      )}
    </EADialog>
  );
};

export default ThirdpartyUpdateDialog;
