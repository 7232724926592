import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { AcquisitionChannel, ERROR, statusEngCode } from "../../services/common/Constants";
import { getDocumentHistoric } from "../../services/edge/DossierService";
import { Context } from "../../states/Store";
import { Grid, Tab, Tabs } from "@mui/material";
import i18next from "i18next";
import PanelFormData from "./PanelFormData";
import PanelAdditionalChecks from "./PanelAdditionalChecks";
import PanelHistoric from "./PanelHistoric";
import { getEnv, useQuery } from "../../config/Config";
import PanelExtractedData from "./PanelExtractedData";
import {
  getEvidenceDetail,
  getEvidenceExtractionDetail,
  getEvidenceFile,
  getEvidenceMultiple,
  getFileStatus,
} from "../../services/edge/EvidenceService";
import { getThirdparty } from "../../services/edge/ThirdpartyService";
import PanelThirdparty from "./PanelThirdparty";
import PanelDocumentSummary from "./PanelDocumentSummary";
import EATabPanel from "../../components/common/EATabPanel";
import { Box } from "@mui/system";
import useStyles from "../../style/js-style/containers/doc-viewer/IndexStyle.js";
import PanelInformations from "./PanelInformations";
import { hasInformationsPlus, shouldDisplayDocPanel } from "../../services/common/DocumentUtils";
import EABackground from "../../components/EABackground";
import PanelStatus from "./PanelStatus";
import PanelValidation from "./PanelValidation";
import { isEmpty } from "lodash";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import PanelNotes from "./PanelNotes";
import ROUTES from "config/Routes";
import EAButton from "components/common/EAButton";
import EAIcon from "components/common/EAIcon";
import { getAuditTrail } from "services/edge/EventsService";
import clsx from "clsx";
import PanelListMultiple from "./PanelListMultiple";
import { isAbortError } from "services/common/ApiUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Scrollbars from "react-custom-scrollbars-2";

const declUrlAPI = getEnv("REACT_APP_API_URL_FORM")

function a11yProps(index) {
  return {
    id: `doc-viewer-tab-${index}`,
    "aria-controls": `doc-viewer-tabpanel-${index}`,
    value: index,
  };
}

const EADocViewer = () => {
  const { classes } = useStyles();

  const docMultiAbortCtrlRef = useRef();
  const docHistoAbortCtrlRef = useRef();

  const { thirdpartyId, reponseId, dossierId } = useParams();
  let history = useHistory();
  const queryThirdpartyId = useQuery().get("thirdpartyId");
  const queryDossierId = useQuery().get("dossierId");
  const [state, dispatch] = useContext(Context);
  const [historicEvidences, setHistoricEvidences] = useState();
  const [thirdparty, setThirdparty] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileId, setSelectedFileId] = useState();
  const [selectedReponseId, setSelectedReponseId] = useState();
  const [selectedThirdpartyId, setSelectedThirdpartyId] = useState();
  const [selectedDossierId, setSelectedDossierId] = useState();
  const [displayDocumentPanel, setDisplayDocumentPanel] = useState(true);
  const [displayInformationPanel, setDisplayInformationPanel] = useState(true);
  const { hasAnyOfPrivileges, hasAnyOfPrivilegesOnScopedResourceImpl } = useContext(RoleBasedAccessControlContext);

  const [selectedEvidence, setSelectedEvidence] = useState();
  const [evidenceExtractionDetails, setEvidenceExtractionDetails] = useState();
  const [documentInfos, setDocumentInfos] = useState();
  const [fileStatus, setFileStatus] = useState();
  const [statusInfo, setStatusInfo] = useState({});
  const [tabIndex, setTabIndex] = React.useState(0);
  const [crumbsInfos, setCrumbsInfos] = React.useState({});

  const hideThirdpartyChipForStatus = ["NOT_VALID", "BELOW_EXPECTATION"];

  const [canAnswer, setCanAnswer] = useState(false);
  const [isAnswerButton, setIsAnswerButton] = useState(false);

  const [hasAnswerPrivilege, setHasAnswerPrivilege] = useState(false);
  const [hasRejectPrivilege, setHasRejectPrivilege] = useState(false);
  const [hasApprovePrivilege, setHasApprovePrivilege] = useState(false);

  const [showPanelStatus, setShowPanelStatus] = useState(true);

  const [dossierMonitored, setDossierMonitored] = useState(false);

  const [downloadingAudit, setDownloadingAudit] = useState(false);

  const [listEvicenceMulti, setListEvicenceMulti] = useState();
  const [selectedMulti, setSelectedMulti] = useState();

  useEffect(() => {
    setSelectedDossierId(dossierId || queryDossierId);
  }, [dossierId, queryDossierId])

  useEffect(() => {
    setSelectedThirdpartyId(thirdpartyId || queryThirdpartyId);
  }, [thirdpartyId, queryThirdpartyId])

  const hideThirdpartyChip = hideThirdpartyChipForStatus.includes(fileStatus);

  const dispatchCrumbsData = useCallback(
    (infos) => {
      const lastCrumbs = [
        {
          label: "breadcrumb.documents",
          hidden: true,
        },
        {
          label: infos.documentName,
        },
      ];

      if (dossierId) {
        const dossierInitialRoute = infos.dossierMonitored === false ? ROUTES.ARCHIVES : ROUTES;
        // On vient de TP->DossierSheet->DocViewer
        if (thirdpartyId) {
          const tpInitialRoute = infos.thirdpartyOnboarded ? ROUTES : ROUTES.ARCHIVES;
          lastCrumbs.unshift({
            label: "breadcrumb.thirdparties",
            path: tpInitialRoute.THIRDPARTIES,
          },
            {
              label: infos.thirdpartyName,
              path: tpInitialRoute.THIRDPARTY_DETAIL.replace(":thirdpartyId", thirdpartyId)
            },
            {
              label: infos.dossierTypeLabel,
              path: dossierInitialRoute.THIRDPARTY_DOSSIER_DETAIL.replace(":thirdpartyId", thirdpartyId).replace(":dossierId", dossierId)
            });
          if (infos.dossierMonitored === false || !infos.thirdpartyOnboarded) {
            lastCrumbs.unshift({ label: "breadcrumb.archives" })
          }
        }
        // On vient de DossierSheet->DocViewer
        if (queryThirdpartyId) {
          lastCrumbs.unshift({
            label: infos.dossierTypeLabel,
            path: dossierInitialRoute.DOSSIER_DETAIL.replace(":dossierId", dossierId)
          });
        }
        // On vient de TP->DocViewer
      } else if (thirdpartyId) {
        lastCrumbs.unshift({
          label: infos.thirdpartyName,
          path: ROUTES.THIRDPARTY_DETAIL.replace(":thirdpartyId", thirdpartyId)
        });
      }

      const hasUndefinedLabel = lastCrumbs.some(item => item.label === undefined);
      if (!hasUndefinedLabel) {
        dispatch({
          type: "CRUMBS",
          crumbsData: { lastCrumbs },
        });
      }
    }, [dispatch, thirdpartyId, queryThirdpartyId, dossierId]);

  useEffect(() => {
    setSelectedReponseId(reponseId);
  }, [reponseId]);

  useEffect(() => {
    if (!isEmpty(crumbsInfos)) {
      dispatchCrumbsData(crumbsInfos)
    }
  }, [crumbsInfos, dispatchCrumbsData])

  // Fetch Evidence Detail
  useEffect(() => {
    async function getEvidence() {
      try {
        const resultEvidenceDetail = await getEvidenceDetail(
          state.account.id,
          selectedReponseId,
          dispatch
        );
        const content = resultEvidenceDetail.content;
        if (content?.metadata?.REASON_NOT_FILLING != null) {
          content?.formdata.push({
            type: "string",
            key: "",
            title: i18next.t("viewer.reasonNotFilling.title"),
            order: 0,
            hasPriority: false,
            value: i18next.t(`viewer.reasonNotFilling.${content?.metadata?.REASON_NOT_FILLING}`)
          });
          content?.formdata.push({
            type: "string",
            key: "",
            title: i18next.t("viewer.reasonNotFilling.titleDetail"),
            order: 1,
            hasPriority: false,
            value: content?.metadata?.REASON_NOT_FILLING_DETAIL
          });
        }
        setSelectedEvidence(content);
        setSelectedFileId(content?.id);
        setDocumentInfos({
          documentName: content.nameFr,
          typeDocumentId: content.typeDocumentId,
          documentCode: content.documentCode,
        });

        setCrumbsInfos((x) => ({
          ...x,
          documentName: content.nameFr,
        }))
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "FILE_NOT_FOUND" },
        });
      }
    }
    if (state.account && selectedReponseId) {
      getEvidence();
    }
  }, [selectedReponseId, state.account, dispatch]);

    // Fetch Evidence Detail
    useEffect(() => {
      async function getEvidenceExtractionDetails() {
        try {
          const resultExtractionEvidenceDetail = await getEvidenceExtractionDetail(
            state.account.id,
            selectedReponseId,
            dispatch
          );
          const content = resultExtractionEvidenceDetail.content;
          setEvidenceExtractionDetails(content);

        } catch (error) {
          console.log('extraction details not found', error);
        }
      }

      if (state.account && selectedReponseId) {
        getEvidenceExtractionDetails();
      }
    }, [selectedReponseId, state.account, dispatch]);

  // Fetch Evidence Detail
  const fetchEvidenceMulti = useCallback(async () => {
      try {
        if(docMultiAbortCtrlRef.current) {
          docMultiAbortCtrlRef.current.abort();
        }

        docMultiAbortCtrlRef.current = new AbortController();
        const signal = docMultiAbortCtrlRef.current.signal;

        if (selectedEvidence?.multiplicite === 'MULTIPLE') {
          const resultEvidenceMulti = await getEvidenceMultiple(
            state.account.id,
            selectedReponseId,
            selectedDossierId,
            selectedEvidence?.typeDocumentId,
            null,
            signal
          );
          let list = resultEvidenceMulti?.content?.map(e => {return {id: e.responseId,
            uploadDate: e.uploadDate,
            expirationDate: e.expirationDate,
            referenceName: e?.formdata.filter(d => d.hasPriority === true)?.[0]?.title,
            referenceValue: e?.formdata.filter(d => d.hasPriority === true)?.[0]?.value,
            statusReason: e?.statusReason
          }})
          setListEvicenceMulti(list);
          let selected = list.filter(m => m.id === parseInt(selectedReponseId));
          setSelectedMulti(selected[0]);
        }
      } catch (error) {
        if(!isAbortError(error)) {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "MULTIPLE_EVIDENCE_ERROR" },
          });
        }
      }

  }, [selectedReponseId, state.account, selectedEvidence, selectedDossierId, dispatch]);

  useEffect(() => {
    if (state.account && selectedReponseId && selectedReponseId === reponseId) {
      fetchEvidenceMulti();
    }
    return () => {
      docMultiAbortCtrlRef.current?.abort();
    }
  }, [selectedReponseId, state.account, reponseId, fetchEvidenceMulti]);

  useEffect(() => {
    setCanAnswer(selectedEvidence?.acquisition === AcquisitionChannel.DO && dossierMonitored && selectedDossierId !== undefined);
  }, [selectedEvidence, selectedDossierId, dossierMonitored])

  const fetchFileStatus = useCallback(async () => {
    try {
      const res = await getFileStatus(
        state.account.id,
        selectedReponseId,
        null
      );

      if (res.content) {
        const statusInf = {};
        statusInf.statusReason = res.content["statusReason"];
        statusInf.statusDetail = res.content["statusReasonDetail"];
        statusInf.statusActorEmail = res.content["statusActorEmail"];
        statusInf.isArchived = res.content["isArchived"];
        setStatusInfo(statusInf);
        let status = statusInf.isArchived === "false" ? res.content["status"] : "";
        setFileStatus(status);
        setIsAnswerButton([statusEngCode.IN_PROGRESS, statusEngCode.MISSING].includes(res.content["status"]));
      }

    } catch (error) {
      console.error("Error ", error)
      console.error('Status not found')
    }
  }, [state.account, selectedReponseId]);

  // Fetch File Status code
  useEffect(() => {
    if (state.account && selectedReponseId) {
      fetchFileStatus();
    }
  }, [selectedReponseId, state.account, fetchFileStatus]);

  // Fetch File's Thirdparty Infos
  useEffect(() => {
    async function fetchThirdparty() {
      try {
        const result = await getThirdparty(
          state.account.id,
          selectedThirdpartyId,
          dispatch
        );
        if (result.content) {
          setThirdparty({
            thirdpartyId: result.content.thirdpartyId,
            thirdpartyName: result.content.name,
            thirdpartyCode: result.content.thirdpartyCode,
            authorizingRoles: result.content.authorizingRoles,
            thirdpartyActivityLabel: result.content.activityLabel,
          });
        }
        setCrumbsInfos((x) => ({
          ...x,
          thirdpartyName: result.content.name,
          thirdpartyOnboarded: result.content.onboarded
        }))
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "THIRDPARTY_NOT_FOUND" },
        });
      }
    }
    if (state.account && selectedThirdpartyId) {
      fetchThirdparty();
    }
  }, [selectedThirdpartyId, state.account, dispatch]);

  // Fetch Document's historic
  useEffect(() => {
    async function fetchHistoricDocument() {
      try {
        if(docHistoAbortCtrlRef.current) {
          docHistoAbortCtrlRef.current.abort();
        }

        docHistoAbortCtrlRef.current = new AbortController();
        const signal = docHistoAbortCtrlRef.current.signal;

        const result = await getDocumentHistoric(
          state.account.id,
          selectedDossierId,
          documentInfos?.typeDocumentId,
          dispatch,
          signal
        );
        setHistoricEvidences(result);
        setCrumbsInfos((x) => ({
          ...x,
          dossierTypeLabel: result?.[0]?.dossierTypeLibelle || i18next.t("free"),
          dossierMonitored: result?.[0]?.dossierMonitored
        }))
        setDossierMonitored(result?.[0]?.dossierMonitored)
      } catch (error) {
        if(!isAbortError(error)) {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "HISTORIC_EVIDENCE_ERROR" },
          });
        }
      }
    }
    if (state.account && selectedDossierId && documentInfos?.typeDocumentId) {
      fetchHistoricDocument();
    }
    return () => {
      docHistoAbortCtrlRef.current?.abort();
    }
  }, [dispatch, selectedDossierId, documentInfos, state.account]);

  // Fetch the file to show
  useEffect(() => {
    async function fetchEvidenceFile() {
      try {
        const { arrayBuffer } = await getEvidenceFile(
          state.account.id,
          selectedFileId,
          dispatch
        );
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        setSelectedFile(URL.createObjectURL(blob));
      } catch (error) {
        setSelectedFile(null);
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "FILE_NOT_FOUND" },
        });
      }
    }
    if (state.account && selectedFileId && selectedFileId != null) {
      fetchEvidenceFile();
    }
  }, [dispatch, selectedFileId, state.account]);

  // Change the document And evidence selected
  const onSelectHistoricEvidence = (historicEvidence) => {
    setSelectedFileId(historicEvidence.id);
    let newpath = history.location.pathname.replace(selectedReponseId, historicEvidence?.responseId);
    history.replace(newpath + history.location.search);
  };

  // Change the document selected on click on Multiple Evidence
  const onSelectMultipleEvidence = (multipleEvidence) => {
    let newpath = history.location.pathname.replace(selectedReponseId, multipleEvidence?.id);
    history.replace(newpath + history.location.search);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setDisplayDocumentPanel(shouldDisplayDocPanel(selectedEvidence, statusInfo, evidenceExtractionDetails));
  }, [selectedEvidence, statusInfo, evidenceExtractionDetails]);

  useEffect(() => {
    setDisplayInformationPanel(
      hasInformationsPlus(selectedEvidence, statusInfo)
    );
  }, [selectedEvidence, statusInfo]);

  // Set initial active tab
  useEffect(() => {
    setTabIndex(displayDocumentPanel ? 0 : historicEvidences ? 1 : 2);
  }, [displayDocumentPanel, displayInformationPanel, historicEvidences]);

  useEffect(() => {
    // check privileges
    const verifObject = { typeDocumentId: documentInfos?.typeDocumentId, authorizingRoles: thirdparty?.authorizingRoles };
    setHasAnswerPrivilege(hasAnyOfPrivilegesOnScopedResourceImpl(verifObject, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER));
    setHasRejectPrivilege(hasAnyOfPrivilegesOnScopedResourceImpl(verifObject, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT));
    setHasApprovePrivilege(hasAnyOfPrivilegesOnScopedResourceImpl(verifObject, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE));
  }, [documentInfos, thirdparty, hasAnyOfPrivilegesOnScopedResourceImpl, hasAnswerPrivilege, hasRejectPrivilege, hasApprovePrivilege]);

  const fillForm = () => {
    window.location.href = (declUrlAPI + `/compteDO/${state.account.id}/document/${documentInfos?.typeDocumentId}/dossier/${selectedDossierId}`)
  }

  const downloadAuditTrail = useCallback(() => {
    if (state.account && thirdparty?.thirdpartyId && selectedDossierId && reponseId) {

      (async function fetchData() {
        try {
          setDownloadingAudit(true);
          const resp = await getAuditTrail(
            state.account.id,
            thirdparty.thirdpartyId,
            selectedDossierId,
            reponseId,
            dispatch
          );
          // download the file from the returned link
          const link = document.createElement("a");
          link.href = resp.content?.slice(1, -1);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloadingAudit(false);

        } catch (e) {
          if (!e?.message.messages?.error) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "INTERNAL_ERROR" },
            });
          }
          setDownloadingAudit(false);
        }
      })();
    }
  }, [thirdparty?.thirdpartyId, selectedDossierId, reponseId, dispatch, state.account]);

  return (
    <Grid className={classes.root} container>
      <EABackground />

      {/* Header */}
      <Grid item xs={12} className={classes.header}>
        {selectedEvidence && (
          <div className={classes.block_de_gauche}>
            <PanelDocumentSummary
              evidence={selectedEvidence}
              documentName={selectedEvidence.nameFr}
            />
            {(canAnswer || hideThirdpartyChip) && thirdparty && <PanelThirdparty thirdparty={thirdparty} nameOnly={canAnswer || hideThirdpartyChip} />}
          </div>
        )}
        <div className={classes.vDivider}></div>
        <div className={classes.block_de_droite}>
          {!canAnswer && !hideThirdpartyChip && thirdparty && <PanelThirdparty thirdparty={thirdparty} />}
          {fileStatus && showPanelStatus && dossierMonitored && (
            <PanelStatus
              fileStatus={fileStatus}
              fileStatusReason={statusInfo?.statusReason}
              responseId={selectedEvidence?.responseId}
              enableBelowExpectationStatus={selectedEvidence?.enableBelowExpectationStatus}
              isDO={canAnswer}
              hasRejectPrivilege={hasRejectPrivilege}
              hasApprovePrivilege={hasApprovePrivilege}
              handleUpdateStatus={() => {
                fetchEvidenceMulti();
                fetchFileStatus();
              }}
            />
          )}
          {canAnswer && <EAButton
            colorVariant={isAnswerButton ? "warning" : "blue"}
            content={
              <>
                <EAIcon icon={"answer_white"} className={classes.iconImg} />
                <span>{i18next.t(isAnswerButton ? "answer" : "answerUpdate")}</span>
              </>
            }
            onClick={fillForm}
            disabled={!hasAnswerPrivilege}
            className={classes.answerBtn}
          />}
        </div>
      </Grid>

      <div className={classes.mainContent}>
        {/* Tab Titles */}
        <div className={classes.infoPanel}>
          <Box className={classes.tabsWrapper}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="dashboard tabs"
            >
              {displayDocumentPanel && (
                <Tab label={i18next.t("document")} {...a11yProps(0)} className={classes.tab + " " + (tabIndex === 0 ? classes.selectedTab : classes.unselectedTab)} />
              )}
              {historicEvidences && (
                <Tab label={i18next.t("historic")} {...a11yProps(1)} className={classes.tab + " " + (tabIndex === 1 ? classes.selectedTab : classes.unselectedTab)} />
              )}
              {displayInformationPanel && (
                <Tab label={`${i18next.t("informations")} +`} {...a11yProps(2)} className={classes.tab + " " + (tabIndex === 2 ? classes.selectedTab : classes.unselectedTab)} />
              )}
              {selectedEvidence && (
                <Tab label={i18next.t("notes.title")}
                  {...a11yProps(3)}
                  className={classes.tab + " " + (tabIndex === 3 ? classes.selectedTab : classes.unselectedTab)}
                  disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_NOTES)}
                />
              )}

            </Tabs>
            <div className={classes.auditBtnContainer}>
              <EAButton
                colorVariant="secondary_dark"
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_AUDIT_TRAIL) || downloadingAudit}
                onClick={() => downloadAuditTrail()}
                className={classes.auditTrailBtn + " " + classes.auditTrailBtnDocViewer + " " + (downloadingAudit ? classes.auditBtnLoading : "")}
                content={
                  <>
                    <EAIcon
                      icon={"audit-trail-doc_grey"}
                      className={classes.iconImg}
                    />
                    {i18next.t("downloadAuditTrail")}
                  </>
                }
              />
            </div>
          </Box>

          {/* Panels */}
          <Scrollbars  style={{ width: "100%", height: "90%" }}>
          <div className={classes.panelWrapper}>

            {displayDocumentPanel && (
              <EATabPanel value={tabIndex} index={0}>
                {selectedEvidence && (
                  <div style={{ marginTop: "10px" }}>
                    {dossierMonitored && !isEmpty(statusInfo?.statusReason) && (
                      <PanelValidation statusInfo={statusInfo}></PanelValidation>
                    )}
                    <PanelAdditionalChecks checks={selectedEvidence.metadata} />
                    <PanelExtractedData
                      documentCode={documentInfos?.documentCode}
                      contents={selectedEvidence.contents}
                      extractorResults={evidenceExtractionDetails}
                    />
                    <PanelFormData data={selectedEvidence.formdata} />
                  </div>
                )}
              </EATabPanel>
            )}

            {historicEvidences && (
              <EATabPanel value={tabIndex} index={1}>
                <PanelHistoric
                  historic={historicEvidences}
                  onSelectHistoricEvidence={onSelectHistoricEvidence}
                  setShowPanelStatus={setShowPanelStatus}
                />
              </EATabPanel>
            )}
            {displayInformationPanel && (
              <EATabPanel value={tabIndex} index={2}>
                {selectedEvidence && (
                  <PanelInformations
                    evidence={selectedEvidence}
                    thirdpartyName={thirdparty?.thirdpartyName}
                    documentType={documentInfos?.typeDocumentId}
                    statusInfo={statusInfo}
                  />
                )}
              </EATabPanel>
            )}
            {selectedEvidence &&
              <EATabPanel value={tabIndex} index={3}>
                <PanelNotes evidence={selectedEvidence} />
              </EATabPanel>
            }
          </div>
        </Scrollbars>
        </div>

        {/* Doc-viewer */}
        <div className={classes.docViewer}>
        {listEvicenceMulti?.length >= 1  && (
          <PanelListMultiple listEvicenceMulti={listEvicenceMulti} selectedMulti={selectedMulti} onSelectMultipleEvidence={onSelectMultipleEvidence} setShowPanelStatus={setShowPanelStatus}/>
        )}
          {selectedFile ? (
            <embed
              src={selectedFile}
              height="1000"
              width="100%"
              type="application/pdf"
            />
          ) : (
            <div className={clsx(classes.infoPanel, classes.docPlaceholder, classes.infoPanelDocViewer)}>
              {selectedEvidence?.metadata?.REASON_NOT_FILLING && (
                <>
                  <EAIcon icon="noDocuments" style={{ width: "40px" }} />
                  <div style={{ fontSize: "18px", color: "#9A9AAF" }}>
                    {i18next.t("viewer.noAvailableDocument")}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default EADocViewer;
