import { makeStyles } from 'tss-react/mui';
import {drawerWidth, ReducedDrawerWidth} from "../../globalStyle";

const useStyles = makeStyles()((theme) => ({
    // Lateral wrapper
    drawer: {
        width: drawerWidth,
    },
    drawerClosed: {
        width: ReducedDrawerWidth,
    },
    drawerPaper: {
        backgroundColor: theme.palette.background.white,
        color: theme.palette.grey.main,
        width: drawerWidth,
        border: "none",
        overflow: "visible",
        boxShadow: "rgb(0 0 0 / 17%) 33px 0 13px -41px",
    },
    drawerPaperClosed: {
        width: ReducedDrawerWidth,
        overflowY: "visible",
        border: "none",
        justifyContent: "center",
        boxShadow: "rgb(0 0 0 / 17%) 33px 0 13px -41px",
    },

    // Logo
    toolbar: theme.mixins.toolbar,
    logoContainer: {
        marginTop: "0.60rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        cursor: "pointer",
    },
    logoContainerReduced: {
        marginTop: "0.60rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        cursor: "pointer",
        position: "absolute",
        top: "-10px",
        left: "10px",
    },
    logo: {
        width: "140px",
        margin: "0 auto",
        position: "relative",
        top: "8px",
        right: "20px",
    },
    logoTextPart: {
        position: "relative",
        left: "152px",
        width: "30px",
        bottom: "2px",
        fontWeight: 600,
        fontSize: "14px",
        color: theme.palette.primary.main,
    },

    // Reduce menu icon
    reduceMenuWrapper: {
        position: "absolute",
        top: "90%",
        right: "-16px",
        padding: "0.5rem",
        backgroundColor: "white",
        cursor: "pointer",
        borderRadius: "4px",

        "&:hover": {
            transform: "scale(1.1)",
        }
    },

    // Nav
    list: {
        color: theme.palette.grey.grey600,

        "& .MuiListItemText-primary": {
            fontSize: "14px",
            fontWeight: "bold",
        },
        "& .MuiListItem-root": {
            display: "flex",
            alignItems: "center",
        },
        "& .MuiListItemIcon-root": {
            minWidth: "32px",
        },
        "& .subItem": {
            padding: "0 0 0 1rem",
            color: theme.palette.grey.grey300,
        },
    },
    customMenuIconContainer: {
        width: "20px",
        marginRight: "10px",
        display: "flex",
        justifyContent: "center",
    },
    footerLinks: {
        paddingBottom: "2rem",

        "& .MuiListItemText-primary": {
            color: theme.palette.grey.grey300,
            fontSize: "12px",
        },
        "& .MuiListItem-root": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        // Change minHeight if we change drawer content
        "@media screen and (min-height: 554px)": {
            position: "fixed",
            bottom: 0,
        },
    },

    // My account
    accountSelectorContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "1.75rem 1rem 0 1rem",

        "& .MuiInputLabel-root": {
            color: theme.palette.grey.grey400,
            fontSize: "12px",
            fontWeight: 400,
            margin: "0",
            position: "relative !important",
            top: "24px !important",
            left: "-2px !important",

            "& .Mui-focused": {
                margin: "0",
                position: "relative !important",
                top: "24px !important",
                left: "-2px !important",
                color: "red"
            },
        },
        "& .MuiSelect-root": {
            background: "white",
            padding: "0.25rem 0.75rem 0.375rem 0.625rem",
        },

    },
    accountSelectorInnerText: {
        color: theme.palette.grey.grey300,
        fontWeight: 600,
        fontSize: "12px !important",
        border: 0,
        height: "32px",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",

        "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
            },
        },

        "& .MuiOutlinedInput-notchedOutline": {
           borderColor: "white",
           boxShadow: "1px 1px 3px " + theme.palette.grey.grey200,
        },
    },

    subItem: {
        padding: "0 0 0 1rem",
        color: theme.palette.grey.grey300,
    },
    // Active link menu
    active: {
        color: theme.palette.primary.main + "!important",
    },
    activeItem: {
        backgroundColor: theme.palette.primary.primary100,
    },
    decoActive: {
      width: "7px",
      height: "44px",
      borderRadius: "0 5px 5px 0",
      backgroundColor: theme.palette.primary.primary600,
      position: "absolute",
      left: 0,
      top:0,
    },
}));

export default useStyles;
