import ROUTES from "../../config/Routes";
import {NAVIGATION_REQUIRED_PRIVILEGES} from "./RolesUtils";

export const FOOTER_ITEMS = [
  {
    text: "footerLinks.GDPR.label",
    link: "footerLinks.GDPR.url",
  },
  {
    text: "footerLinks.security.label",
    link: "footerLinks.security.url",
  },
  {
    text: "footerLinks.CGU.label",
    link: "footerLinks.CGU.url",
  }
];

export const getDrawerOptions = (state, dispatch) => {
  return [
    {
      type: "item",
      text: "menu.dashboard",
      icon: "dashboard",
      iconType: "custom",
      value: ROUTES.DASHBOARD,
      hasChildren: false,
      childrenDisplayed: false,
      display: true,
      neededPrivileges: null,
    },
    {
      type: "item",
      text: "menu.myThirdParties",
      icon: "thirdparty",
      iconType: "custom",
      value: ROUTES.THIRDPARTIES,
      hasChildren: false,
      childrenDisplayed: false,
      display: true,
      neededPrivileges: [...NAVIGATION_REQUIRED_PRIVILEGES.thirdparty],
    },
    {
      type: "item",
      text: "menu.myDossiers",
      icon: "folder",
      iconType: "custom",
      value: ROUTES.DOSSIERS,
      hasChildren: false,
      childrenDisplayed: false,
      display: true,
      onClick: () => dispatch({ type: "RESET_DOSSIER_FILTERS" }),
      neededPrivileges: [...NAVIGATION_REQUIRED_PRIVILEGES.dossier],
    },
    {
      type: "item",
      text: "menu.myDocuments",
      icon: "documents",
      iconType: "custom",
      value: ROUTES.DOCUMENTS,
      hasChildren: false,
      childrenDisplayed: false,
      display: true,
      neededPrivileges: [...NAVIGATION_REQUIRED_PRIVILEGES.document],
    },
    {
      type: "item",
      text: "menu.events",
      icon: "events",
      iconType: "custom",
      value: ROUTES.EVENTS,
      hasChildren: false,
      childrenDisplayed: false,
      display: true,
      neededPrivileges: null,// TODO à modifier une fois la ajouté la gestions des events dans les roles
    },
    {
      type: "item",
      text: "menu.myArchives",
      icon: "archive_box",
      iconType: "custom",
      hasChildren: true,
      value: "archives",
      childrenDisplayed: false,
      display: true,
      neededPrivileges: [...NAVIGATION_REQUIRED_PRIVILEGES.thirdparty, ...NAVIGATION_REQUIRED_PRIVILEGES.dossier],
    },
    {
      type: "subItem",
      parent: "menu.myArchives",
      text: "menu.arch_myThirdParties",
      icon: "underscore",
      iconType: "custom",
      value: ROUTES.ARCHIVES.THIRDPARTIES,
      disabled: false,
      display: true,
      neededPrivileges: [...NAVIGATION_REQUIRED_PRIVILEGES.thirdparty],
    },
    {
      type: "subItem",
      parent: "menu.myArchives",
      text: "menu.arch_myDossiers",
      icon: "underscore",
      iconType: "custom",
      value: ROUTES.ARCHIVES.DOSSIERS,
      disabled: false,
      display: true,
      neededPrivileges: [...NAVIGATION_REQUIRED_PRIVILEGES.dossier],
    },
    // TODO: Uncomment when we add the functionality
    // {
    //   type: "subItem",
    //   parent:"menu.myArchives",
    //   text: "menu.arch_myDocuments",
    //   icon: "underscore",
    //   iconType: "custom",
    //   value: ROUTES.ARCHIVES.DOCUMENTS,
    //   disabled: true,
    //   display: true,
    //   profiles: ALL_PROFILES
    // }
  ];
}
