import React from 'react';

const MainHeaderCell = ({ name }) => {
  return (
    <span
      style={{
        color: "#656575",
        fontWeight: 500,
        fontSize: "12px",
      }}
    >
      {name}
    </span>
  )
};

export default MainHeaderCell;
