import { Grid, Paper } from "@mui/material";
import React from "react";

function SectionContentWrapper({ children }) {
  return (
    <>
      <Grid container justifyContent="space-evenly" spacing={0}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper elevation={0} style={{ marginTop: "17px" }}>
              {children}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SectionContentWrapper;
