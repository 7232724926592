import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import useStyles from "../../../style/js-style/containers/users/user-profile/UserProfileStyle";
import i18next from "i18next";
import React, { useContext, useEffect, useState, useCallback } from "react";
import "react-phone-input-2/lib/style.css";
import { Context } from "../../../states/Store";
import EAIcon from "../../../components/common/EAIcon";
import { RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { isEmpty } from "lodash";
import {
  isEventPrivilege,
  isEventTaskPrivilege,
  DOSSIER_EVENT_MANAGEMENT,
  DOCUMENT_EVENT_MANAGEMENT,
  EVENT_TASKS,
} from "services/common/RolesUtils";
import EventPrivilegeBlock from "containers/administration/roles/privileges/common/EventPrivilegeBlock";
import RoleCheckBox from "containers/administration/roles/privileges/common/RoleCheckBox";
import {ERROR, SUCCESS} from "services/common/Constants";
import EAButton from "components/common/EAButton";
import { getAllUserEventSubscriptions, updateUserEventSubscriptions } from "services/edge/EventsService";

function UserNotifications() {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const { isGlobalAdmin } = React.useContext(RoleBasedAccessControlContext);
  const [grantableSubscriptions, setGrantableSubscriptions] = useState();
  const [grantableTasksSubscriptions, setGrantableTasksSubscriptions] = useState();
  const [userEventSubscriptions, setUserEventSubscriptions] = useState([]);
  const [selectedEventFrequency, setSelectedEventFrequency] = useState("INSTANT");
  const [userEventTasksSubscriptions, setUserEventTasksSubscriptions] = useState([]);
  const [selectedEventTasksFrequency, setSelectedEventTasksFrequency] = useState("INSTANT");
  const [shownTasks, setShownTasks] = useState([]);

  // This gives us the list of PRIVILEGE_EVENT that are grantable to the user via its roles if not a superadmin
  useEffect(() => {
    if (!isEmpty(state.roles)) {
      const eventGrantablePrivileges = state.roles.reduce(
        (accumulator, role) => {
          role.flatPrivileges?.forEach(
            (privilege) =>
              isEventPrivilege(privilege.name) &&
              accumulator.add(privilege.name)
          );
          return accumulator;
        },
        new Set()
      );

      const eventGrantableTasks = state.roles.reduce(
        (accumulator, role) => {
          role.flatPrivileges?.forEach(
            (privilege) =>
              isEventTaskPrivilege(privilege.name) &&
              accumulator.add(privilege.name)
          );
          return accumulator;
        },
        new Set()
      );
      setGrantableSubscriptions([...eventGrantablePrivileges]);
      setGrantableTasksSubscriptions([...eventGrantableTasks]);
    }
  }, [state.roles]);

  const isEventTaskActive = useCallback((privilegeName) => {
    return userEventTasksSubscriptions?.includes(privilegeName);
  }, [userEventTasksSubscriptions]);

  const toggleEventTask = (privilegeName) => {
    setUserEventTasksSubscriptions((prev) => {
      prev = prev.includes(privilegeName)
        ? [...prev.filter((x) => x !== privilegeName)]
        : [...prev, privilegeName];
      return prev;
    });
  }

  useEffect(() => {
    const shownTasksNames = EVENT_TASKS.filter(task => isGlobalAdmin || grantableTasksSubscriptions?.includes(task));
    setShownTasks(
      shownTasksNames.map(name => ({
        name: i18next.t(`privileges.${name}`),
        action: () => toggleEventTask(name),
        isActive: isEventTaskActive(name),
        isGrantable: isGlobalAdmin,
        variant: "topItemLight" //TODO: Change with task variant for css
      }))
    );
  }, [isGlobalAdmin, grantableTasksSubscriptions, isEventTaskActive])

  useEffect(() => {
    if (state.user && state.account) {
      (async function fetchData() {
        try {
          const res = await getAllUserEventSubscriptions(state.account.id);
          if(res.content) {
            const eventsSubs = res.content.filter(x => !x.eventType.includes("TASK"));
            setUserEventSubscriptions(eventsSubs?.map(x => `PRIVILEGE_EVENT_${x.eventType}`));
            setSelectedEventFrequency(eventsSubs?.[0]?.frequency || "INSTANT");

            const tasksSubs = res.content.filter(x => x.eventType.includes("TASK"));
            setUserEventTasksSubscriptions(tasksSubs?.map(x => `PRIVILEGE_EVENT_${x.eventType}`));
            setSelectedEventTasksFrequency(tasksSubs?.[0]?.frequency || "INSTANT");
          }
        } catch (e) {
          dispatch({
            type: "ALERT",
            alert: {
              type: ERROR,
              msg: e?.message?.error?.[0]?.message || "INTERNAL_ERROR"
            },
          });
        }
      })();
    }
  }, [state.user, state.account, dispatch]);

  // checkbox is shown active if the privilege is active or is a parent with every grantable child active
  const isSubscriptionActive = (privilege) => {
    if(isEmpty(privilege.childPrivileges)) {
      return userEventSubscriptions?.includes(privilege.name) || (!isGlobalAdmin && !grantableSubscriptions?.includes(privilege.name))
    } else {
      return privilege.childPrivileges.filter(child => isEventPrivilege(child.name)).every(child => isSubscriptionActive(child))
    }
  };

  const isSubscriptionActiveGroupped = (...privileges) => {
    return privileges.every((p) => isSubscriptionActive(p));
  };

  const isSubscriptionShown = (privilege) => {
    if(isEmpty(privilege.childPrivileges)) {
      return isGlobalAdmin || grantableSubscriptions?.includes(privilege.name);
    } else {
      return privilege.childPrivileges.filter(child => isEventPrivilege(child.name)).some(child => isSubscriptionShown(child))
    }
  };

  const isSubscriptionShownGroupped = (...privileges) => {
    return privileges.every((p) => isSubscriptionShown(p));
  };

  // Function to Remove or Add an EVENT PRIVILEGE SUBSCRIPTION
  const togglePrivilegeSubscription = (...privileges) => {
    privileges?.forEach(privilege => {
      if(!isEmpty(privilege.childPrivileges)) {
        privilege.childPrivileges.forEach(child => isSubscriptionShown(child) && (isSubscriptionActive(child) === isSubscriptionActive(privilege)) && togglePrivilegeSubscription(child));
      } else {
        setUserEventSubscriptions((prev) => {
          prev = prev.includes(privilege.name)
            ? [...prev.filter((x) => x !== privilege.name)]
            : [...prev, privilege.name];
          return prev;
        });
      }
    })
  };

  const selectAll = (...privileges) => {
    privileges?.forEach(privilege => {
      if(!isEmpty(privilege.childPrivileges)) {
        privilege.childPrivileges.forEach(child => selectAll(child));
      } else {
        setUserEventSubscriptions((prev) => {
          return [...prev, privilege.name]
        });
      }
    })
  }

  const unselectAll = (...privileges) => {
    privileges?.forEach(privilege => {
      if(!isEmpty(privilege.childPrivileges)) {
        privilege.childPrivileges.forEach(child => unselectAll(child));
      } else {
        setUserEventSubscriptions((prev) => {
          return [...prev.filter((x) => x !== privilege.name)]
        });
      }
    })
  }

  const handlEventsFrequency = (event, value) => {
    setSelectedEventFrequency(value)
  }

  const handlEventsTasksFrequency = (event, value) => {
    setSelectedEventTasksFrequency(value)
  }

  const saveEventSubscriptions = async () => {
    const userEventSubscriptionsDto = userEventSubscriptions?.map(sub => ({eventType: sub.substring(16), frequency: selectedEventFrequency})) || [];
    const userEventTasksSubscriptionsDto = userEventTasksSubscriptions?.map(sub => ({eventType: sub.substring(16), frequency: selectedEventTasksFrequency})) || [];
    try {
      await updateUserEventSubscriptions(state.account.id, [...userEventSubscriptionsDto, ...userEventTasksSubscriptionsDto])
      dispatch({
        type: "ALERT",
        alert: { type: SUCCESS, msg: i18next.t("userProfile.saveSuccess") },
      });
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: error?.message?.messages?.error?.[0]?.code || "INTERNAL_ERROR" },
      });
    }

  }

  return (
    <>
      {/* Save */}
      <EAButton
        onClick={saveEventSubscriptions}
        content={<EAIcon icon={"save"} />}
        className={classes.saveButton}
      />

      {/* Tasks */}
      <div className={classes.myTaskWrapper}>
       <div className={classes.myTaskMainContentWrapper}>
        {/* Title */}
        <div className={classes.myTaskMainTitle}>
          <EAIcon icon="profil/myTasks" className={classes.myTaskTitleIcon} />
          <span>{i18next.t("userProfile.myTasks")}</span>
        </div>

        {/* Content */}
        <div className={classes.myTaskMainContent}>
          {shownTasks.map((item, index) => (
            <RoleCheckBox item={item} key={index} variant="profile"/>
          ))}
        </div>
       </div>

       {/* Task Frequency */}
       <div className={classes.myTaskFrequency}>
         <div className={classes.myTaskFrequencyTitle}>
           {i18next.t(`userProfile.frequency.emailFrequency`)}
         </div>
          <FormControl>
            <RadioGroup row value={selectedEventTasksFrequency} onChange={handlEventsTasksFrequency}>
              <FormControlLabel
                value="INSTANT"
                control={
                  <Radio
                    size="small"
                    color="primary"
                    icon={<EAIcon icon={"input/radio-unchecked"} />}
                    checkedIcon={<EAIcon icon={"input/radio-checked"} />}
                  />
                }
                label={i18next.t(`userProfile.frequency.immediate`)}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      {/* Events */}
      <div className={classes.eventWrapper}>
        <div className={classes.eventMainContentWrapper}>
          {/* Title */}
          <div className={classes.eventMainTitle}>
            <EAIcon icon="profil/events" className={classes.eventTitleIcon} />
            <span>{i18next.t(`userProfile.events`)}</span>
          </div>

          {/* Content */}
          <div className={classes.eventMainContent}>
            {/*Dossier*/}
            <EventPrivilegeBlock
              data={DOSSIER_EVENT_MANAGEMENT(
                togglePrivilegeSubscription,
                isSubscriptionActive,
                isSubscriptionShown,
                isSubscriptionActiveGroupped,
                isSubscriptionShownGroupped,
                selectAll,
                unselectAll
              )}
              grantMode="hide"
              variant="profile"
            />
            {/* Document */}
            <EventPrivilegeBlock
              data={DOCUMENT_EVENT_MANAGEMENT(
                togglePrivilegeSubscription,
                isSubscriptionActive,
                isSubscriptionShown,
                isSubscriptionActiveGroupped,
                isSubscriptionShownGroupped,
                selectAll,
                unselectAll
              )}
              grantMode="hide"
              variant="profile"
            />
          </div>
        </div>

        {/* Event Frequency */}
        <div className={classes.eventFrequency}>
          <div className={classes.myTaskFrequencyTitle}>
            {i18next.t(`userProfile.frequency.emailFrequency`)}
          </div>
          <FormControl>
            <RadioGroup row value={selectedEventFrequency} onChange={handlEventsFrequency}>
              <FormControlLabel
                value="INSTANT"
                control={
                  <Radio
                    size="small"
                    color="primary"
                    icon={<EAIcon icon={"input/radio-unchecked"} />}
                    checkedIcon={<EAIcon icon={"input/radio-checked"} />}
                  />
                }
                label={i18next.t(`userProfile.frequency.immediate`)}
              />
            </RadioGroup>
          </FormControl>
        </div>

      </div>
    </>
  );
}

export default UserNotifications;
