import { Box, Chip, Skeleton, Tooltip } from "@mui/material";
import EAIcon from "components/common/EAIcon";
import useStyles from "../../style/js-style/components/thirdparties/PanelFinancialStrengthStyle";
import i18next from "i18next";
import { useContext, useMemo, useState } from "react";
import { Context } from "states/Store";
import { activateDataBlocksForThirdParty, deactivateDataBlocksForThirdParty } from "services/edge/DataBlocksService";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import EAButton from "components/common/EAButton";
import { ERROR } from "services/common/Constants";
import { theme } from "style/globalStyle";
import { ArrowDownward, ArrowUpward, RemoveCircle } from "@mui/icons-material";
import EADialog from "components/common/EADialog";
import EAFinancialStrengthLineChart from "components/common/charts/EAFinancialStrengthLineChart";


const GraphfinancialStrength = ({riskStyle, evolution, nombreLevel, riskSegment}) => {
  const { classes } = useStyles();

  const getHeightForUnKnown = (i, nombreLevel) => {
    switch(i) {
      case 3 : 
        return nombreLevel === 5 ? `calc(65px/${nombreLevel}*${i+1} + 20px)` : `85px`
      case 4 : 
        return nombreLevel === 5 ? `85px` : `calc(65px/${nombreLevel}*${i-1} + 20px)`
      case 5 : 
        return `calc(65px/${nombreLevel}*${i-1} + 20px)`
      default : 
        return `calc(65px/${nombreLevel}*${i+1} + 20px)`
    }
  }

  const getGraphEvolutionStyleFromRiskSegment = (riskSegment) => {
    if(riskSegment === "0") {
      return {color : theme.palette.error.error700, text: i18next.t("financialStrength.riskVeryHigh")}
    } else if(riskSegment === "4") {
      return {color : theme.palette.error.error600, text: i18next.t("financialStrength.riskHigh")}
    } else if(riskSegment === "3") {
      return {color : theme.palette.warning.warning500, text: i18next.t("financialStrength.riskMedium")}
    } else if(riskSegment === "2") {
      return {color : theme.palette.primary.main, text: i18next.t("financialStrength.riskLow")}
    } else if(riskSegment === "1") {
      return {color : theme.palette.primary.primary400, text: i18next.t("financialStrength.riskVeryLow")}
    } else {
      return {color : theme.palette.grey.grey300, text: i18next.t("financialStrength.riskUnknown")}
    }
  }

  const [graphEvolutionOpen, setGraphEvolutionOpen] = useState(false);

  const dataGraph = useMemo(() => {
    return evolution.map(item => {
      // we keep only last five years score
      if(new Date().getFullYear() - 5 > new Date(item.date).getFullYear()) 
          return null;

      const {color, text} = getGraphEvolutionStyleFromRiskSegment(item.score);
      return {
        date: new Date(item.date).getTime(), 
        year: new Date(item.date).getFullYear(),
        score: item.score === "0" ? 5 : parseInt(item.score), 
        text: text,
        color : color, 
      }
    }).filter(item =>  {
      return item !== null && item.date !== null && item.score !== null && !isNaN(item.score)
    })
  }, [evolution])

  return (
    <div className={classes.riskSegmentGraph}>
      <div className={riskStyle.background}>
          <div className={classes.scoreLabel}>
              <span>{i18next.t("financialStrength.labelLong")}</span>
                <Tooltip title={dataGraph?.length < 2 ? i18next.t("financialStrength.notEnoughDataForEvolutionGraph"): ""}>
                  <span>
                  <Chip label={i18next.t("financialStrength.showHistoric")} variant="outlined" size="small" clickable 
                  className={classes.buttonHistorique} disabled={dataGraph?.length < 2} onClick={() => setGraphEvolutionOpen(true)}/>
                  </span>
                 
                </Tooltip>
                
          </div>
          <div className={classes.flexCenter}>
              <IconeEvolutionScore riskStyle={riskStyle} evolution={evolution} isUnKnown={riskStyle.isUnKnown} />
              <span style={{marginLeft: "10px"}}>{riskStyle.text}</span>
          </div>
          <div className={classes.columnsParentGraph}>
            {Array(nombreLevel).fill(1).map((el, i) => {
              const isMainBackground = riskSegment >= String(i+1) || riskSegment ==="0" || riskStyle.isUnKnown 
              return <span key={i} className={`${classes.columnGraph} ${isMainBackground ? "" : classes.columnEmpty}`} style={{
                backgroundColor: isMainBackground ? riskStyle.mainColor : riskStyle.secondaryColor, 
                height: riskStyle.isUnKnown ? getHeightForUnKnown(i+1, nombreLevel) : `calc(65px/${nombreLevel}*${i+1} + 20px)`
              }}></span>
            })

            }           

          </div>
      </div>

      <EADialog isOpen={graphEvolutionOpen} title={i18next.t("financialStrength.evolutionScore")}
        hasActions={false} onClose={() => setGraphEvolutionOpen(false)} maxWidth="md">
          <div className={classes.graphEvolutionContent}>
          <EAFinancialStrengthLineChart id="EAfinancialStrengthLineChart" data={dataGraph}/>
          </div>
          
      </EADialog>
        
    </div>
  )
}

const IconeEvolutionScore = ({evolution, riskStyle, isUnKnown}) => {
  const { classes } = useStyles();


  if(evolution?.length < 2) {
    return(<></>)
  } else if(isUnKnown) {
      return (
        <span className={classes.iconeRiskEvolution} style={{backgroundColor: riskStyle.secondaryDarkerColor}}>
          <RemoveCircle />
        </span>
      )
  } else {
    // calcul if score is higher or lower
    let isRiskLower = null;
    for (let i = evolution.length -1; i > 0; i--) {
      if(evolution[i].score !== evolution[i-1].score) {
        // manage 0 is higher risk than
        if(evolution[i].score  === "0" || evolution[i-1].score === "0") {
          isRiskLower = evolution[i].score > evolution[i-1].score;
        } else {
          isRiskLower = evolution[i].score < evolution[i-1].score;
        }
       
        break;
      }
  }
    if(isRiskLower === null) return(<></>);

    return (
      <span className={classes.iconeRiskEvolution} style={{backgroundColor: riskStyle.secondaryDarkerColor}}>
        {isRiskLower ? 
         <ArrowDownward style={{color: riskStyle.mainColor}} />
        :
         <ArrowUpward style={{color: riskStyle.mainColor}}/>
      }
       
      </span>
    )
  }
}

const ScoreInformations = ({financialStrength, riskStyle, lang}) => {
  const { classes } = useStyles();



  return(
    <div className={classes.scoreInformations}>
        <div style={{display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div className={classes.standardRatingValue}>
              <div className={classes.leftBarRisk} style={{backgroundColor: riskStyle.mainColor}}></div>
              <div>
                <span style={{fontSize: "32px", fontWeight: 700, lineHeight: "42px", color: theme.palette.black.dark}}>{financialStrength.rating}</span>
                <span className={classes.subLabelRisk}>Rating</span>
              </div>
              <Chip label={riskStyle.badgeText} size="small" style={{
                color: riskStyle.textColor ?  riskStyle.textColor : riskStyle.mainColor, 
                backgroundColor: riskStyle.secondaryColor, 
                fontWeight: 700, 
                fontSize: "10px"
                }} />
          </div>
          <div className={classes.riskSegmentInfos}>
                <span style={{color: riskStyle.mainColor, fontSize: "32px"}}>{financialStrength.score ? financialStrength.score : "-"}</span>
                <span className={classes.subLabelRisk}>{financialStrength.isFailureScore ? "ClassScore" :"Risk"}</span>
          </div>
        </div>
        <div>
          <div className={classes.informationDescriptionTitle}>{i18next.t("financialStrength.riskSegmentCommentTitle")}</div>
          <div className={classes.informationDescriptionContent}>
            {lang === "fr" ? financialStrength.riskSegmentDescriptionFr : financialStrength.riskSegmentDescription }</div>
            <p className={classes.informationDescriptionContent}>
            {lang=== "fr" ? financialStrength.financialStrengthDescriptionFr : financialStrength.financialStrengthDescription }
            </p>
        </div>
       
    </div>
  )
}



const PanelFinancialStrength = ({financialStrength, thirdPartyId, evolutionScore, onRiskChange}) => {
    const { classes } = useStyles();
    const [state, dispatch] = useContext(Context);
    const [financialStrengthRecu, setfinancialStrengthRecu] = useState();
    const [isWaitingScore, setIsWaitingScore] = useState(false);
    const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

    //Quand le score de santé financière est activé, on récupère la réponse pour afficher les données, au lieu de recharger tout le thirdparty
    const financialStrengthToUse = financialStrengthRecu || financialStrength;


    // Activer/Désactiver le scoring du score de santé financière pour le tiers
    const declencherfinancialStrength = async () => {
        setIsWaitingScore(true);
        try {
          const reponse = await activateDataBlocksForThirdParty(state.account.id, thirdPartyId, "financialStrength");
          setfinancialStrengthRecu(reponse.content);
          onRiskChange && onRiskChange();
        } catch (error) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "ACTIVATE_FINANCIAL_STRENGTH_ERROR" },
            });
          }
          setIsWaitingScore(false);

      }
      const arreterfinancialStrength = async () => {
        try {
          await deactivateDataBlocksForThirdParty(state.account.id, thirdPartyId, "financialStrength")
          setfinancialStrengthRecu({
            dnbAssessment: null
          })
          onRiskChange && await onRiskChange();
        } catch (error) {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "DEACTIVATE_FINANCIAL_STRENGTH_ERROR" },
          });
        }
      }


      //Gestion des différentes couleurs et textes selon le riskLevel

      const riskLevel = financialStrengthToUse?.riskLevel;

      const riskStyle = riskLevel === 'very_high' ? 
      {
        background : classes.veryHighBakground,
        text: i18next.t("financialStrength.riskVeryHigh"),
        badgeText: "High risk", 
        mainColor: theme.palette.error.error700, 
        secondaryColor : theme.palette.error.error100, 
        secondaryDarkerColor : theme.palette.error.error200, 
        isUnKnown: false
      } : riskLevel === 'high' ? 
      {
        background : classes.highBakground,
        text: i18next.t("financialStrength.riskHigh"),
        badgeText: "High risk",
        mainColor: theme.palette.error.error600, 
        secondaryColor : theme.palette.error.error100, 
        secondaryDarkerColor : theme.palette.error.error200, 
        isUnKnown: false
      } : riskLevel === 'medium' ? 
      {
        background : classes.mediumBackground, 
        text: i18next.t("financialStrength.riskMedium"),
        badgeText: "Moderate risk", 
        mainColor: theme.palette.warning.warning500, 
        textColor: theme.palette.warning.warning600, 
        secondaryColor : theme.palette.warning.warning100, 
        secondaryDarkerColor : theme.palette.warning.warning200, 
        isUnKnown: false
      } : riskLevel === 'low' ? 
      {
        background : classes.lowBackground, 
        text: i18next.t("financialStrength.riskLow"),
        badgeText: "Low risk", 
        mainColor: theme.palette.primary.main, 
        secondaryColor : theme.palette.primary.primary200, 
        secondaryDarkerColor : theme.palette.primary.primary300, 
        isUnKnown: false
      } : riskLevel === 'very_low' ? 
      {
        background : classes.veryLowBackground,
        text: i18next.t("financialStrength.riskVeryLow") ,
        badgeText: "Minimum risk", 
        mainColor: theme.palette.primary.primary400, 
        secondaryColor : theme.palette.primary.primary100, 
        secondaryDarkerColor :  theme.palette.primary.primary200, 
        isUnKnown: false
      } : {
        background : classes.unknownBackground, 
        text: i18next.t("financialStrength.riskUnknown"),
        badgeText: "Undetermined risk", 
        mainColor: theme.palette.grey.grey300, 
        textColor: theme.palette.grey.grey700,
        secondaryColor : theme.palette.grey.grey100, 
        secondaryDarkerColor : theme.palette.grey.grey200, 
        isUnKnown: true
      };



      //====  JSX =======

      if(isWaitingScore) {
        return (
            <Box className={classes.rootNoData}>
            <div className={classes.skeleton}>
              <Skeleton variant="rect" className={classes.skeletonContent} />
              <Skeleton variant="rect" className={classes.skeletonContent} />
              <Skeleton variant="rect" className={classes.skeletonContent} />
              <Skeleton variant="rect" className={classes.skeletonContent} />
              <Skeleton variant="rect" className={classes.skeletonContent} />
              <Skeleton variant="rect" className={classes.skeletonContent} />
              <Skeleton variant="rect" className={classes.skeletonContent} />
              <Skeleton variant="rect" className={classes.skeletonContent} />
            </div>
            </Box>
        )
      }

    if(financialStrength && !financialStrengthToUse.dnbAssessment ) {
        return (
            <Box className={classes.rootNoData}>
              <EAIcon icon="search_off" />
              <div className={classes.noDataText}>
                {i18next.t("financialStrength.scoreNotActivated")}
              </div>

              <EAButton 
                colorVariant="primaryLight"
                onClick={declencherfinancialStrength}
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE)}
                className={classes.button}
                customStyle={{boxShadow: "none"}}
                content={
                    <span className={classes.buttonContent}>
                        <EAIcon icon="newEnrichment"/>
                        {i18next.t("financialStrength.activation")}
                    </span>}
              />
            </Box>
          );
    }


    return (
        <div className={classes.financialStrengthContent}>

          <GraphfinancialStrength riskStyle={riskStyle} evolution={evolutionScore} 
            nombreLevel={financialStrengthToUse.isFailureScore ? 5 : 4} 
            riskSegment={financialStrengthToUse.score}
            />
          <ScoreInformations financialStrength={financialStrengthToUse} riskStyle={riskStyle} lang={state.lang} />
           <EAButton 
                colorVariant="errorLight"
                onClick={arreterfinancialStrength}
                customStyle={{boxShadow: "none", minHeight: "30px" }}
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE)}
                className={classes.buttonDesactivation}
                content={
                    <span className={classes.buttonStopContent}>
                        <EAIcon icon="stop_scoring"/>
                        {i18next.t("financialStrength.desactivation")}
                    </span>}
              />

        </div>
    )

}
export default PanelFinancialStrength;