import React, { useContext, useEffect, useState } from "react";
import DossiersDataTable from "../../../components/dossiers/DossiersDataTable";
import i18next from "i18next";
import { ERROR } from "../../../services/common/Constants";
import { Context } from "../../../states/Store";
import { getAccountDossiersSearch } from "../../../services/edge/DossierService";
import ListLayout from "../../../components/common/ListLayout";
import { initialDossierTableSort } from "components/dossiers/DossiersDataTableUtils";
import { useRef } from "react";
import { DossiersListSearchForm } from "./DossiersListSearchForm/DossiersListSearchForm";
import { useCallback } from "react";
import { isAbortError } from "services/common/ApiUtils";
import { useListFormFilters } from "components/common/hooks/useListFormFilters";

function Dossiers({ active }) {
  const [state, dispatch] = useContext(Context);
  const [dossiers, setDossiers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [globalCount, setGlobalCount] = React.useState(0);

  const [sortModel, setSortModel] = useState(initialDossierTableSort("dossiers-list"));

  const abortControllerRef = useRef();

  const {
    formFilters,
    saveSessionFormFilters,
  } = useListFormFilters("dossiers-list");

  const fetchDossiers = useCallback(
    (page, rowPerPage, filters, sortModel) => {

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      (async function fetchData() {
        try {
          const resp = await getAccountDossiersSearch(
            state.account.id,
            active,
            active ? true : null,
            { page, rowPerPage },
            filters,
            sortModel,
            null,
            signal
          );
          setDossiers(resp.content || []);
          setGlobalCount(resp.globalCount || resp.totalElements)
          dispatch({
            type: "PAGINATION",
            pagination: {
              page: resp.page,
              rowPerPage: resp.size,
              count: resp.totalElements,
            },
          });
          setIsLoading(false);
        } catch (e) {
          if(!isAbortError(e)) {
            if(e?.message?.error?.[0]?.code === "NOT_FOUND") {
              setDossiers([]);
            } else {
              dispatch({
                type: "ALERT",
                alert: { type: ERROR, msg: "DOSSIERS_ERROR" },
              });
            }
          }
          setIsLoading(false);
        }
      })();
    },
    [state.account, dispatch, active]
  );


  // -------- Data ------- //
  useEffect(() => {
    if (state.account && formFilters) {
      fetchDossiers(
        state.pagination.page,
        state.pagination.rowPerPage,
        formFilters,
        sortModel
      );
    }
    return () => {
      abortControllerRef.current?.abort();
    }
  }, [
    state.account,
    fetchDossiers,
    state.pagination.page,
    state.pagination.rowPerPage,
    formFilters,
    sortModel,
  ]);

  const handleSearch = (data) => {
    saveSessionFormFilters(data);
    dispatch({
      type: "PAGINATION",
      pagination: {
        page: 0,
        rowPerPage: state.pagination.rowPerPage,
      },
    });
  }

  // Reset pagination on component unmount
  useEffect(() => {
    return () => {
      dispatch({
        type: "PAGINATION",
        pagination: {
          page: 0,
          rowPerPage: 50,
          count: 0,
        },
      });
    };
  }, [dispatch]);

  return (
    <ListLayout
      count={state.pagination?.count}
      totalCount={globalCount}
      icon={active ? "drawer/folder_active" : "drawer/archive_box_active"}
      title={i18next.t( `dossierTable.title.${active ? "active" : "inactive"}`)}
      isLoading={isLoading}
      filterField={
        formFilters && (
          <DossiersListSearchForm
            filters={formFilters}
            submitHandler={handleSearch}
          />
        )
      }
    >
      {/* DataGrid list */}
      { dossiers &&
        <DossiersDataTable
          dossiers={dossiers}
          monitored={active}
          component="dossiers-list"
          paginationMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          filters={formFilters}
        />
      }
    </ListLayout>
  );
}

export default Dossiers;
