import React, { useEffect } from "react";
import i18next from "i18next";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import useStyles from "./DocumentsListSearchFormStyle";
import EAIcon from "components/common/EAIcon";
import {
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import _ from "lodash";
import EAAutoCompleteInput from "components/inputs/EAAutoCompleteInput";
import EAButton from "components/common/EAButton";
import EADateRangePicker from "components/common/EADateRangePicker";
import { statusEngCode } from "services/common/Constants";
import { HighlightOff } from "@mui/icons-material";
import { AdvancedSearchButtons } from "components/lists-advanced-search/AdvancedSearchButtons";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";

const defaultValue = { documentCode: "", start: null, end: null, status: "" };

export const DocumentsListSearchForm = ({
  filters,
  documentsOptions,
  submitHandler = () => {},
}) => {
  const { classes } = useStyles();

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: {
      documents: [defaultValue],
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
  });

  useEffect(() => {
    setValue("documents", filters.documents || [defaultValue]);
  }, [setValue, filters]);

  const submitForm = (data) => {
    if (_.isEmpty(errors)) {
      submitHandler(data);
    }
  };

  const getStatusOptions = () => {
    return Object.keys(statusEngCode)
      .filter((x) => x !== "INCONNU")
      .map((x) => ({
        label: i18next.t(`viewer.status.statusLabel.${x}`),
        value: x,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };
  
  const handleReset = () => {
    const formDefaultValues = {documents: [defaultValue]};
    formMethods.reset(formDefaultValues);
    submitHandler(formDefaultValues);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id="thirdparty-search-form"
        onSubmit={handleSubmit(submitForm)}
        className={classes.formContainer}
      >
        <Grid container spacing={2} padding={"0 22px"}>
          {documentsOptions?.length > 0 &&
            fields?.map((field, index) => {
              return (
                <Grid item container spacing={2} key={field.id}>
                  <Grid item xs={3}>
                    <SearchFormInputBloc label={"Document"}>
                      <EAAutoCompleteInput
                        fieldName={`documents.${index}.documentCode`}
                        options={documentsOptions}
                        placeholder={"Tous"}
                        variant="outlined"
                        limitTags={1}
                      />
                    </SearchFormInputBloc>
                  </Grid>
                  <Grid item xs={3}>
                    <SearchFormInputBloc label={"Déposé entre"}>
                      <EADateRangePicker
                        formMethods={formMethods}
                        startName={`documents.${index}.start`}
                        endName={`documents.${index}.end`}
                        ref={null}
                      />
                    </SearchFormInputBloc>
                  </Grid>
                  <Grid item xs={3}>
                    <SearchFormInputBloc label={"Statut"}>
                      <EAAutoCompleteInput
                        fieldName={`documents.${index}.status`}
                        options={getStatusOptions()}
                        placeholder={"Tous"}
                        variant="outlined"
                        limitTags={1}
                      />
                    </SearchFormInputBloc>
                  </Grid>
                  <Grid item xs={3} display="flex">
                    <div className={classes.removeBtnContainer}>
                      {formMethods.getValues("documents")?.length > 1 && (
                        <Tooltip placement="top" title={i18next.t("delete")}>
                          <IconButton
                            aria-label={`remove search doc ${index + 1}`}
                            onClick={() => remove(index)}
                            className={classes.removeBtn}
                            size="large"
                          >
                            <HighlightOff />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                    {index === 0 && (
                      <Box display="flex" mt="auto">
                        <AdvancedSearchButtons handleReset={handleReset} />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          <Grid item>
            <EAButton
              borderless
              onClick={() => append(defaultValue)}
              type="button"
              content={
                <div className={classes.addDocBtn}>
                  <EAIcon
                    icon={"cross_green"}
                    extension="png"
                    style={{ width: "10px", marginRight: "6px" }}
                  />
                  <span>{i18next.t("addFilter")}</span>
                </div>
              }
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
