import React, { useContext, useEffect } from "react";
import { Typography } from "@mui/material";
import { Context } from "../../../states/Store";
import i18next from "i18next";
import _ from "lodash";
import EAButton from "../EAButton";
import useSelectedAccountId from "../hooks/useSelectedAccountId";
import { useHistory } from "react-router";
import EAChartFiltersChips from "./EAChartFilterChips";
import useStyles from "../../../style/js-style/components/common/charts/EAChartFiltersStyle";
import { getGraphFiltersKey } from "services/common/ChartUtils";

export const EAChartFilters = ({ tab, subTab }) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const accountId = useSelectedAccountId();
  const history = useHistory();

  const handleDelete = (type, data) => {
    dispatch({
      type: "REMOVE_GRAPH_FILTER",
      filter: { data, type, accountId, tab, subTab },
    });
  };

  const hasFilters = () => {
    if (_.isEmpty(state.graphFilters)) return false;
    let res = false;
    Object.keys(state.graphFilters).forEach((type) => {
      if (!_.isEmpty(state.graphFilters[type])) {
        res = true;
      }
    });
    return res;
  };

  const filterListHandler = () => {
    if (tab !== "risks") {
      history.push(`/${accountId}/${tab}`, { showChartFilters: true });
    } else {
      if (["indued", "transparency", "financialStrength"].includes(subTab)) {
        history.push(`/${accountId}/thirdparties`, { showChartFilters: true });
      }
    }
  };

  useEffect(() => {
    if (accountId) {
      const filters = JSON.parse(localStorage.getItem(getGraphFiltersKey(accountId, tab, subTab))) || {};
      dispatch({
        type: "INIT_GRAPH_FILTER",
        filters,
      });
    }
  }, [accountId, tab, subTab, dispatch]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "18px",
        }}
      >
        <Typography variant="h4" className={classes.selection}>
          {i18next.t("dashboard.filters.title")}
        </Typography>
        <EAButton
          content={i18next.t("dashboard.filters.showResultBtn")}
          size="small"
          onClick={filterListHandler}
          customStyle={{
            zIndex: 1,
          }}
          disabled={!hasFilters()}
        />
      </div>
      {hasFilters() && (
        <EAChartFiltersChips
          filters={state.graphFilters}
          deleteHandler={handleDelete}
          withBackground={false}
        />
      )}
    </>
  );
};
