import React, { useContext } from 'react';
import {
	Typography,
	TextField,
	Grid,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import i18next from 'i18next';
import { rejectDocument } from '../../services/edge/DocumentService';
import EADialog from '../../components/common/EADialog';
import EAInputBloc from "../../components/common/EAInputBloc";
import EAIcon from '../../components/common/EAIcon';
import { Context } from '../../states/Store';
import useStyles from "../../style/js-style/containers/doc-viewer/StatusRejectDialogStyle.js";
import { ERROR } from '../../services/common/Constants';
import { isEmpty } from '../../services/common/Utils';


const StatusRejectDialog = ({ isOpen, responseId, enableBelowExpectationStatus, handleOnReject, handleClose }) => {
	const { classes } = useStyles();
	const [state, dispatch] = useContext(Context);

	const id = isOpen ? 'filters-dialog' : undefined;

	const maxLengthComment = 1000;

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ mode: "onChange", delayError: 500 });

	const handleReject = async (data) => {
		if (isEmpty(errors)) {
			const statusComment = {
				comment: data.comment
			}
			try {
				await rejectDocument(state.account.id, responseId, statusComment, null);
				handleOnReject();
			} catch (error) {
				dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DOC_STATUS_UPDATE_ERROR" } });
			}
		}
	}

	const handleDialogClose = () => {
		handleClose();
	}

	return (
		<EADialog
			id={id}
			isOpen={isOpen}
			onCancel={handleDialogClose}
			onValidate={handleSubmit(handleReject)}
			hasActions={true}
			maxWidth="popupxs"
			variant="redValid"
			fullWidth
			btnSize="regular"
			isFeedBack={false}
			customBtnStyle={{ paddingBottom: "24px !important" }}
			customDialogStyle={{ paddingBottom: "32px !important" }}
		>
			<form>
				{/* Header */}
				<div className={classes.headerWrapper}>
					<EAIcon icon={"nonconforme"} className={classes.imgContent} />
					<div>
						<Typography variant="body1" className={classes.dialogText}>
							{i18next.t(`viewer.status.${enableBelowExpectationStatus ? "rejectBelowExpectationPopupTitle" : "rejectPopupTitle"}`)}
						</Typography>
					</div>
				</div>

				<Grid item xs={12}>
					<EAInputBloc label={`${i18next.t("reason")}*`}>
						<Controller
							control={control}
							name="comment"
							rules={{
								required: i18next.t("infos.required"),
								maxLength: {
									value: maxLengthComment,
									message: i18next.t("infos.E011_VAR", {
										input: i18next.t("theReason"),
										maxLength: maxLengthComment,
									}),
								},
							}}
							render={({ field }) => (
								<TextField
									id="reject-reason-detail"
									variant="outlined"
									fullWidth
									{...field}
									multiline
									className={classes.inputText}
									minRows={2}
									maxRows={5}
									error={!!errors.comment}
									helperText={
										!!errors.comment ? errors.comment.message : ""
									}
								/>
							)}
						/>
					</EAInputBloc>
					<Typography variant="body1" className={classes.captionText}>
						{!enableBelowExpectationStatus && i18next.t("viewer.status.captionText")}
					</Typography>
				</Grid>
			</form>
		</EADialog>
	);
};

export default StatusRejectDialog;
