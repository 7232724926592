import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  rootNoData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7F8FC",
    color: "#9A9AAF",
    height: "224px",
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.03)",
    fontWeight: 400,
    fontSize: "14px",
  },
  noDataText: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#9A9AAF",
    marginTop: "16px",
  },
  rootData: {
    height: "224px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px 5px",
    gap: "38px 79px",
    flexWrap: "wrap",
    overflow: "auto",
  },
  rootItem: {
    borderRadius: "10px",
    boxShadow: "0px 6px 16px 0px rgba(21, 12, 115, 0.06)",
    width: "250px",
    height: "69px",
    display: "flex",
    position: "relative",
  },
  itemIcon: {
    borderRadius: "10px 0 0 10px",
    width: "69px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  itemContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    flex: 1,
    padding: "15px 10px",
  },
  itemName: {
    color: "#373534",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: "-0.12px",
    maxWidth: "161px",
    overflow: "hidden",
    height: "70px",
    textOverflow: "ellipsis",
  },
  itemResult: {
    textAlign: "right",
    fontFamily: "Metric",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "120%",
    letterSpacing: "-0.18px",
    width: "100%",
    height: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
  },
  itemUpdate: {
    color: "#9A9AAF",
    fontSize: "10px",
    lineHeight: "16px",
    position: "absolute",
    bottom: "-20px",
    right: 0,
  },
}));

export default useStyles;
