import EAIcon from "components/common/EAIcon";
import i18next from "i18next";
import useStyles from "./InvitationImportDialogStyle";
import { useContext } from "react";
import { Context } from "states/Store";
import { ERROR, SUCCESS } from "services/common/Constants";
import { sendInvitationImportThirdParties } from "services/edge/ThirdpartyService";

const { default: EADialog } = require("components/common/EADialog")

const InvitationImportDialog = ({isOpen, onClose, importId}) => {

    const { classes } = useStyles();
    const [state, dispatch] = useContext(Context);


    const sendInvitation = () => {

        try {
            sendInvitationImportThirdParties(state.account.id, importId, dispatch);
            onClose();
            dispatch({
                type: "ALERT",
                alert: { type: SUCCESS, msg: i18next.t("imports.confirmationAlertInvitationImport") },
              });

        } catch (error) {
            if ( error?.message?.messages?.error && error?.message?.messages?.error[0]?.code) {
                dispatch({ type: "ALERT", alert: {type: ERROR, msg : i18next.t(`infos.${error.message.messages.error[0].code}`)} });
              }
        }
    }

    return <EADialog isOpen={isOpen}
                onCancel={onClose}
                validateBtnLabel="validate"
                fullWidth={true}
                onValidate={sendInvitation}>

            <div className={classes.content}>
                <div className={classes.icon}><EAIcon icon="send_invitation_green" /></div>
                <span className={classes.title}>{i18next.t("imports.table.actionsInvit")}</span>
                <span className={classes.description}>{i18next.t("imports.table.invitationDescription")}</span>
            </div>




    </EADialog>
}

export default InvitationImportDialog;
