import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  // Delete Pop-up
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  rootMessageMulti: {
    marginTop: "12px",
    fontSize: "14px",
    color: theme.palette.grey.grey500,
    whiteSpace: "pre-line"
  },
  imgWrapper: {
    backgroundColor: "#FFEBEB",
    padding: "1rem",
    borderRadius: "50%",
    width: "55px",
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  imgContent: {
    width: "21px",
    height: "27px"
  },
  imgContentMulti: {
    width: "26px"
  },
  imgWrapperMulti: {
    backgroundColor: "#FFEBEB",
    padding: "1rem",
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  bolder: {
    fontWeight: 700,
    fontFamily: "Metric",
    fontSize: "20px",
    color: theme.palette.grey.grey700,
    // maxWidth: "370px",
    textAlign: "center",
  },
  bolderDossier: {
    fontWeight: 700,
    fontFamily: "Metric",
    fontSize: "20px",
    color: theme.palette.grey.grey700,
    textAlign: "center",
  },
  attributeInfoBlocWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: theme.palette.grey.grey700,

  },
  attributeInfoBlocContent : {
    backgroundColor: "#F2F3F7",
    padding: "12px 24px 12px 24px",
    minWidth: "574px",
    "&:last-child": { paddingBottom: "24px" },
    "&:first-child": { paddingTop: "24px" },
  },

  // FeedBack Pop-Up
  feedBackWrapper: {
    marginBottom: "1.5rem",
  },
  feedBackCross: {
    position: "absolute",
    top: "23px",
    right: "23px",
    cursor: "pointer",
  },
  feedBackTitle:{
    marginBottom: "2rem",
  },
  feedBackContent:{
    backgroundColor: "#FFEBEB",
    padding: "1rem",
    borderRadius: "50%",
    width: "3rem",
    height: "3rem",
    marginRight: "1rem",
  },
  feedBackImg:{
    width: "1rem",
    position: "relative",
    top: "5px",
  },
}));

export default useStyles;
