import React, { useState } from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFieldArray, useFormContext } from "react-hook-form";
import { find } from "lodash";
import i18next from "i18next";
import { getAvailableOperatorOptions } from "../../../../../services/common/RulesUtils";
import useStyles from "../../../../../style/js-style/containers/administration/rules/RuleFormsStyle";
import CriterionFormFields from "./CriterionFormFields";
import EAButton from "../../../../../components/common/EAButton";

const CriteriaFormArray = ({ criterionFieldOptions }) => {
  const { classes } = useStyles();
  const [hoveredLine, setHoveredLine] = useState(null);

  const { control } = useFormContext();

  const { fields: criteria, append, remove } = useFieldArray({
    control,
    name: "criteria",
  });

  return (
    <Grid container spacing={2}>
      {criteria.map((criterion, index) => {
        return (
          <Grid
            item
            xs={12}
            key={criterion.id}
            onMouseEnter={() => setHoveredLine(index)}
            onMouseLeave={() => setHoveredLine(null)}
          >
            <CriterionFormFields
              index={index}
              isHovered={hoveredLine === index}
              onRemove={() => remove(index)}
              criterionFieldOptions={criterionFieldOptions}
              initialOperatorOptions={getAvailableOperatorOptions(
                criterion.field,
                criterionFieldOptions
              )}
              initialValueOptions={
                find(criterionFieldOptions, { value: criterion.field })?.valueOptions || []
              }
              fieldName={`criteria.${index}`}
            />
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <EAButton
          borderless={true}
          className={classes.addChoiceWrapper}
          onClick={(e) => {
            e.preventDefault();
            append({
              id: "",
              entityKey: "sch_ea_365.v_third_parties",
              field: "",
              fieldType: "",
              attributeId: "",
              operator: "",
              values: [],
            });
          }}
          content={
            <div className={classes.addChoiceBtn}>
              <AddIcon style={{ width: "16px", marginRight: "7px" }} />
              <span style={{ fontSize: "12px" }}>
                {i18next.t("rules.configuration.criteria.actions.addCriterion")}
              </span>
            </div>
          }
        />
      </Grid>
    </Grid>
  );
};

export default CriteriaFormArray;
