import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    label: {
        color: theme.palette.grey.grey500,
        fontSize: "14px",
        fontWeight: 700,
        marginBottom: "0.5rem",
        marginTop: "1rem",
    },
    inputWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        padding: "1rem",
    },
    documentsListWrapper: {
        backgroundColor: theme.palette.grey.grey100,
        border: "1px solid " + theme.palette.grey.grey200,
        height: "80%",
        borderRadius: "16px",
        padding: "1rem 0.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        gap: "0.2rem",
    },
    documentItem: {
        backgroundColor: "white",
        border: "1px solid " + theme.palette.grey.grey200,
        borderRadius: "12px",
        padding: "0.5rem",
        color: theme.palette.grey.grey500,
        fontSize: "12px",
        display: "flex",
        gap: "0.5rem",
    },
    referenceInput: {
        width: "calc(100% - 16px)",

        "& .MuiOutlinedInput-root":{
            border: "none",
        },
        "& .MuiOutlinedInput-input": {
        },
    },
    descriptionInput: {
        width: "calc(100% - 16px)",
        "& .MuiOutlinedInput-root":{
            border: "none",
            padding: "7px 12px",
        },
    },
    folderInput: {
        width: "auto",

        "& .MuiOutlinedInput-root":{
            paddingRight: "1rem !important",
            border: "none",
        },
    },
    popUpFeedBackTitle: {
        position: "absolute",
        top: "1rem",
        left: "1rem",
    },
}));

export default useStyles;
