import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        fontSize: "11px",
        textTransform: "none",
        borderRadius: "15px",
        marginRight: "10px",
        height: "30px",
        borderColor: theme.palette.grey.grey100,

        "& .MuiChip-labelSmall": {
            padding: "0 10px",
        },
    }
}));

export default useStyles;
