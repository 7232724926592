import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    snackbar : {
        maxWidth: "650px",

        "& .MuiAlert-root" : {
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: "14px",
            borderRadius: "16px",
            boxShadow: "rgb(23 23 23 / 7%) 0px 0px 16px 0px"
        },
        "& .MuiAlert-icon": {
            display: "flex",
            alignItems: "center"
        }
    },
    "snackbar-success": {
        "& .MuiAlert-root" : {
            backgroundColor: theme.palette.primary.primary100,
            color: theme.palette.primary.primary800,
        }
    },
    "snackbar-error": {
        "& .MuiAlert-root" : {
            backgroundColor: theme.palette.error.error100,
            color: theme.palette.error.error700,
        }
    },
    "snackbar-info": {
        "& .MuiAlert-root" : {
            backgroundColor: theme.palette.grey.grey100,
            color: theme.palette.primary.primary800,
        }
    },


    
}));

export default useStyles;
