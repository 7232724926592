import React, { useEffect } from "react";
import i18next from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormHelperText,
  MenuItem,
  Select
} from "@mui/material";
import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import _ from "lodash";
import { EASelectChip } from "components/rules/EASelectChip";

const CalculatorSelectInput = ({
  fieldName,
  isRequired = false,
  label = '',
  placeholder = '',
  options,
  onChange = () => { },
  validate = () => { return null },
  variant = 'standard', //mui variant ('filled' | 'outlined' | 'standard')
  uxVariant = "standard",
  labelVariant = 'standard', //standard, chip
  subTitle,
  setValidIsDisabled,
  dt = false,
  ...other
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    if (formMethods.formState && setValidIsDisabled) {
      setValidIsDisabled(!_.isEmpty(formMethods.formState.errors))
    }
  }, [formMethods.formState, setValidIsDisabled])

  return (
    <>
      <span className={classes.formLabel}>
        {label && `${label}`}
      </span>
      <div className={classes.formGroup}>
        <Controller
          control={control}
          name={fieldName}
          rules={{
            validate: async (value) => {
              if (isRequired) {
                const isEmpty = !value || value.length === 0;

                if (isEmpty) {
                  return i18next.t("infos.required");
                } else {
                  clearErrors(fieldName);
                }
              }

              return await validate(value);
            }
          }}
          render={({ field }) => (
            <Select
              {...field}
              value={formMethods.getValues(fieldName) || ""}
              id={`${fieldName}-input-id`}
              className={classes.inputSelect}
              variant={variant}
              style={{ width: uxVariant === "criteriaInput" ? "100%" : "" }}
              renderValue={(selected) => {
                if (labelVariant === 'chip') {
                  return (
                    <EASelectChip
                      selected={selected}
                      options={options}
                      placeholder={placeholder}
                      onDelete={() => setValue(fieldName, '')}
                      dt={dt}
                    />
                  );
                } else {
                  if (selected === "") {
                    return (
                      <span className={classes.placeHolder}>
                        {placeholder}
                      </span>
                    );
                  } else {
                    let entry = _.find(options, { value: selected });

                    return (
                      <span style={{ color: "#2E2E3A" }} className={dt || entry?.dt ? 'dt' : ""}>
                        {(entry === undefined) ? selected : entry.label}
                      </span>
                    );
                  }
                }
              }}
              error={!!_.get(errors, `${fieldName}`)}
              onChange={(e) => {
                setValue(fieldName, e.target.value);
                onChange(e.target.value);
                trigger(fieldName);
              }}
              displayEmpty
              {...other}
            >
              {options?.map((option, index) => (
                <MenuItem
                  key={`${option.value}-${index}`}
                  value={option.value}
                  className={dt || option?.dt ? 'dt' : ""}
                  disabled={!!option?.disabled}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {
          !!_.get(errors, `${fieldName}`) && (
            <FormHelperText className="Mui-error" style={{ position: "absolute" }}>
              {_.get(errors, `${fieldName}.message`)}
            </FormHelperText>
          )
        }
      </div>
    </>
  );
}

export default CalculatorSelectInput;
