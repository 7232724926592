import React from "react";
import i18next from "i18next";
import EAButton from "components/common/EAButton";
import useStyles from "./DossierModelDialogStyle";
import EAIcon from '../../../../../components/common/EAIcon';
import { Typography } from '@mui/material';
import clsx from 'clsx';

const DossierModelDialogConfirmUpdate = ({
  onCancel,
  onContinue,
  data
}) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.confirmRoot}>

        {/* Title */}
        <div className={classes.imgWrapperAlert}>
          <EAIcon icon={"warning-snack"} className={classes.imgContentWarning}/>
        </div>
        <span className={classes.bolder}>
          {/*Modification d’un modèle inactif.*/}
          {i18next.t("demandes.dossierModel.confirmDialog.title", { status: i18next.t(`demandes.dossierModel.status.options.${data.active ? "active" : "inactive"}`).toLowerCase() })}
        </span>
      </div>

      {/* Content */}
      <div key={"calculation.id"} style={{marginTop: "24px"}}>
        <div className={classes.infoBlocWrapper}>
          <Typography className={clsx(classes.infoBlocContent, "dt")}>
            {data.libelle}
          </Typography>
        </div>
      </div>

        {/* Annuler / Confirmer */}
        <div className={classes.finalButtonWrapper}>
          <EAButton
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
            content={i18next.t("cancel")}
            colorVariant={"secondary"}
            size={"large"}
          />
          <EAButton
            onClick={(e) => {
              e.preventDefault();
              onContinue();
            }}
            content={i18next.t("validate")}
            colorVariant={"warningDark"}
            size={"large"}
          />
        </div>
    </>
  );
};

export default DossierModelDialogConfirmUpdate;
