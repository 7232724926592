import React, {useContext, useEffect, useState} from "react";
import {
  Typography,
  Grid,
  FormLabel,
  FormGroup,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import useStyles from "../../../style/js-style/containers/users/user-profile/UserUnsubscribeDialogStyle";
import { Controller, useForm } from "react-hook-form";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import { isEmpty } from "../../../services/common/Utils";
import { ERROR } from "../../../services/common/Constants";
import {
  deleteAccountUser,
  verifyUserPassword,
} from "../../../services/edge/UserService";
import EADialog from "../../../components/common/EADialog";
import clsx from "clsx";
import EAIcon from "../../../components/common/EAIcon";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import _ from "lodash";
import useKeycloak from "components/common/hooks/useKeycloak";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

const InputBloc = ({ label, children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.formContainer}>
      <Grid item xs={12} md={4}>
        <FormLabel className={classes.formLabel}>{label} *</FormLabel>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormGroup>{children}</FormGroup>
      </Grid>
    </div>
  );
};

const UserUnsubscribeDialog = ({ isOpen, handleClose }) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const id = isOpen ? "filters-dialog" : undefined;
  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
  } = useForm({ mode: "onChange", delayError: 500 });
  const {logoutKeycloak} = useKeycloak();
  const [disabledValidate, setDisabledValidate] = useState(true);

  // eslint-disable-next-line
  useEffect(() => {
    setDisabledValidate(
      !_.isEmpty(formState.errors.password)
    )
  }, [formState]);

  const handleUnsubscribe = async (data) => {
    if (isEmpty(errors)) {
      try {
        startLoading();
        await deleteAccountUser(
          state.account.id,
          state.user.id,
          data.password,
          null
        );

        logoutKeycloak();

      } catch (error) {
				dispatch({
					type: "ALERT",
					alert: {
						type: ERROR,
						msg: error.message?.error?.[0]?.message || "INTERNAL_ERROR",
					},
				});
      }finally {
        stopLoading();
      }
    }
  };

  const handleDialogClose = () => handleClose();

  const validateUserPwd = AwesomeDebouncePromise(async (value) => {
    try {
      await verifyUserPassword(state.account.id, state.user.id, value, null);
    } catch (e) {
			if (e?.message?.error?.[0]?.reason?.includes("Wrong password")) {
				return i18next.t("userProfile.errorIncorrectPassword");
			}
    }
  }, 500);

  return (
    <EADialog
      id={id}
      isOpen={isOpen}
      onCancel={handleDialogClose}
      onValidate={handleSubmit(handleUnsubscribe)}
      isValidateDisabled={disabledValidate || isRequestLoading}
      validateBtnLabel={i18next.t("userProfile.unsubscribeButton")}
      variant={"redValid"}
      style={{ zIndex: 1301 }}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(handleUnsubscribe)}>
        {/* Header : icon + title */}
        <Grid item xs={12} className={classes.popupHeader}>
          <EAIcon icon={"exit-to-app"} className={classes.topHeaderIcon} />
        </Grid>

        {/* main content */}
        <Grid container alignItems="center">
          <Typography
            variant={"h2"}
            className={clsx(classes.dialogText, classes.dialogTextTitle)}
          >
            {i18next.t("userProfile.confirmTitle")}
          </Typography>
          <Typography
            variant="body1"
            className={clsx(classes.dialogText, classes.dialogTextMessage)}
          >
            {i18next.t("userProfile.unsubscribeInfo")}
          </Typography>

          {/* Password input */}
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <InputBloc label={`${i18next.t("userProfile.passwordInputLabel")}`}>
              <Controller
                control={control}
                name="password"
                rules={{
                  required: i18next.t("infos.required"),
                  validate: (value) => validateUserPwd(value),
                }}
                render={({ field }) => (
                  <TextField
                    id="password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    placeholder={i18next.t("userProfile.actualPassword")}
                    className={classes.input}
                    fullWidth
                    error={!!errors.password}
                    helperText={
                      !!errors.password ? errors.password.message : ""
                    }
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={i18next.t("passwordBtnAriaLabel")}
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            size="large"
                            sx={{
                              position: "absolute",
                              right: "16px",
                            }}
                          >
                            {showPassword ? (
                              <Tooltip title={i18next.t("hide")}>
                                <EAIcon icon={"closed-eye"} />
                              </Tooltip>
                            ) : (
                              <Tooltip title={i18next.t("show")}>
                                <EAIcon icon={"open-eye"} />
                              </Tooltip>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </InputBloc>
          </Grid>
        </Grid>
      </form>
    </EADialog>
  );
};

export default UserUnsubscribeDialog;
