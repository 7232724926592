import React, { useCallback, useState } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { find } from "lodash";
import i18next from "i18next";
import { getAvailableOperatorOptions, getAvailableValueOptions } from "../../../../../services/common/RulesUtils";
import useStyles from "../../../../../style/js-style/containers/administration/rules/RuleFormsStyle";
import {
  AttributeType,
  FieldType,
  Operator
} from "../../../../../services/common/Constants";
import RuleSelectInput from "../../../../../components/rules/RuleSelectInput";
import CriterionValues from "./CriterionValues";

const CriterionFormFields = ({
  index,
  isHovered,
  onRemove,
  criterionFieldOptions,
  initialOperatorOptions = [],
  initialValueOptions = [],
  fieldName,
}) => {
  const { classes } = useStyles();

  const [availableOperatorOptions, setAvailableOperatorOptions] = useState(
    initialOperatorOptions
  );
  const [availableValueOptions, setAvailableValueOptions] = useState(
    initialValueOptions
  );

  const formMethods = useFormContext();
  const { setValue, getValues, clearErrors } = formMethods;

  const onFieldChange = (newValue) => {
    let criterionFieldEntry = find(criterionFieldOptions, { value: newValue });

    setValue(`${fieldName}.field`, newValue);

    //Field type
    setValue(`${fieldName}.fieldType`, criterionFieldEntry.type);

    //Attribute Id
    if (criterionFieldEntry.type === FieldType.ATTRIBUTE) {
      setValue(`${fieldName}.attributeId`, criterionFieldEntry.attributeId);
    } else {
      setValue(`${fieldName}.attributeId`, ``);
    }

    //Criterion operator
    let filteredOperatorOptions = getAvailableOperatorOptions(
      newValue,
      criterionFieldOptions
    );
    setAvailableOperatorOptions(filteredOperatorOptions);

    if (filteredOperatorOptions?.length > 0) {
      setValue(`${fieldName}.operator`, filteredOperatorOptions[0].value);
    } else {
      setValue(`${fieldName}.operator`, "");
    }
    clearErrors(`${fieldName}.operator`);

    //Criterion values
    let filteredValueOptions = getAvailableValueOptions(
      newValue,
      criterionFieldOptions
    );
    setAvailableValueOptions(filteredValueOptions);

    setValue(`${fieldName}.values`, []);
    //Clear all previous errors that might have been raised on 'values' field
    clearErrors(`${fieldName}.values`);
  };

  const onOperatorChange = (newValue) => {
    setValue(`${fieldName}.operator`, newValue);

    /*
      Criterion value initialization depends on both the field type and the applied operator.
      'values' is initialized to an empty array for cases where we expect multiple values, otherwise it is initialized 
      to an array of a single empty field.
    */
    switch (newValue) {
      case Operator.IN_SET:
      case Operator.NOT_IN_SET:
      case Operator.IS_NULL:
      case Operator.NOT_NULL:
        setValue(`${fieldName}.values`, []);
        break;

      case Operator.EQUAL:
      case Operator.CONTAINS:
        let currentCriterionValue = getValues(`${fieldName}`);
        let criterionFieldEntry = find(criterionFieldOptions, {
          value: currentCriterionValue.field,
        });

        if (
          criterionFieldEntry.type === FieldType.ATTRIBUTE &&
          criterionFieldEntry.subType === AttributeType.LIST_MULTIPLE
        ) {
          setValue(`${fieldName}.values`, []);
        } else {
          setValue(`${fieldName}.values`, [""]);
        }
        break;

      default:
        setValue(`${fieldName}.values`, [""]);
        break;
    }

    //Clear all previous errors that might have been raised on 'values' field
    clearErrors(`${fieldName}.values`);
  };

  const renderDeleteButton = useCallback(() => {
    return (
      <Tooltip placement="top" title={i18next.t("delete")}>
        <IconButton
          onClick={onRemove}
          className={classes.valuesIconBtnClosed}
          size="large"
        >
          <HighlightOff />
        </IconButton>
      </Tooltip>
    );
  }, [onRemove, classes]);

  return (
    <div style={{display: 'flex'}}>
      <div className={classes.ruleCriteriaDeco}/>
      <Grid container spacing={2} style={{ alignItems: "center" }}>
        <Grid item xs={12} md={5} sx={{ paddingTop: "0 !important" }}>
          <RuleSelectInput
            fieldName={`${fieldName}.field`}
            label={`${i18next.t(
              "rules.configuration.criteria.fields.field.label"
            )}`}
            placeholder={i18next.t(
              `rules.configuration.criteria.fields.field.placeholder`
            )}
            options={criterionFieldOptions}
            onChange={(newValue) => {
              onFieldChange(newValue);
            }}
            validate={() => {
              return null;
            }}
            labelVariant="chip"
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: "0 !important" }}>
          <RuleSelectInput
            fieldName={`${fieldName}.operator`}
            label={`${i18next.t(
              "rules.configuration.criteria.fields.operator.label"
            )}`}
            placeholder={i18next.t(
              `rules.configuration.criteria.fields.operator.placeholder`
            )}
            options={availableOperatorOptions}
            onChange={(newValue) => {
              onOperatorChange(newValue);
            }}
            validate={() => {
              return null;
            }}
            labelVariant="chip"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ paddingTop: "0 !important" }}>
          <CriterionValues
            fieldName={`${fieldName}.values`}
            showLabel
            index={index}
            criterionFieldOptions={criterionFieldOptions}
            availableValueOptions={availableValueOptions}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          {/*Temporary. This should be managed as it is done for attribute values (using styles)*/}
          {isHovered && renderDeleteButton()}
        </Grid>
      </Grid>
    </div>
  );
};

export default CriterionFormFields;
