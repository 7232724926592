import React, {useContext, useEffect, useState} from "react";
import {Typography} from "@mui/material";
import i18next from "i18next";
import {Context} from  "../../../states/Store";
import EADialog from "../../../components/common/EADialog";
import {getAccountRequirements} from "../../../services/edge/AccountService";
import DossierChoiceDocuments from "../create-dossier/DossierChoiceDocuments";
import DossierUpdateDocumentRecap from "./DossierUpdateDocumentRecap"
import {ERROR} from "../../../services/common/Constants";
import {updateRequiredDocuments} from "../../../services/edge/DossierService";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";
import { localizeTranslate } from "i18n/LocalizeUtils";

const DossierUpdateDocumentDialog = ({ dossierId, isOpen, handleClose, documents}) => {
  const [state, dispatch] = useContext(Context);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [documentsSelected, setDocumentsSelected] = React.useState(documents);
  const [accountDocuments, setAccountDocuments] = useState([]);
  const [newDocumentsList, setNewDocumentsList] = useState([]);
  const [removedDocumentsList, setRemovedDocumentsList] = useState([]);
  const [loader, setLoader] = useState(true);
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();

  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          setLoader(true);
          const results = await getAccountRequirements(state.account.id, dispatch);
          setAccountDocuments(
              results.content.map((x) => {
                return {
                  id: x.code,
                  descriptionFr : localizeTranslate(x.descriptionFr)
                };
              })
          );
        }
        finally {
          setLoader(false);
        }
      })();
    }
  }, [state.account, dispatch]);

  const handleDialogClose = () => handleClose(isUpdated);

  const validateClickHandler = async () => {
    if(isValidated) {
      try {
        startLoading();
        const requiredDocuments = {newRequiredDocuments : documentsSelected};
        await updateRequiredDocuments(
            state.account.id,
            dossierId,
            requiredDocuments,
            dispatch
        );
        setIsUpdated(true);
      } catch (error) {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "REQ_DOCUMENTS_EDIT_ERROR" },
          });
        }finally {
        stopLoading();
      }
      }
    else {
      const unSelectedDocumentsCodes = documents?.filter(doc => !documentsSelected.includes(doc));
      const selectedDocuments = accountDocuments?.filter(doc => documentsSelected.includes(doc.id)) ;
      const unSelectedDocuments = accountDocuments?.filter(doc => unSelectedDocumentsCodes.includes(doc.id));
      selectedDocuments.sort((a,b) => { return a.descriptionFr.localeCompare(b.descriptionFr)});
      setNewDocumentsList(selectedDocuments);
      unSelectedDocuments.sort((a,b) => { return a.descriptionFr.localeCompare(b.descriptionFr)});
      setRemovedDocumentsList(unSelectedDocuments);

    }
    setIsValidated(true);
  };

  return (
    <EADialog
      isOpen={isOpen}
      title={i18next.t("dossierSheet.gestionDossier")}
      onCancel={handleDialogClose}
      hasActions={!isUpdated}
      maxWidth="md"
      fullWidth
      onValidate = {validateClickHandler}
      isValidateDisabled={isRequestLoading}
    >
      {!isUpdated ? (
        <>
          {!isValidated ? (
              <>
                {!loader && (
                    <DossierChoiceDocuments
                    documents={accountDocuments}
                    selection={{
                      items: documentsSelected,
                      setItems: setDocumentsSelected,
                    }}
                />
                )}
              </>
          ):(
              <>
                <DossierUpdateDocumentRecap
                    selectedDocuments={newDocumentsList}
                    removedDocuments={removedDocumentsList}
                  />
              </>
          )}
        </>
      ) : (
        <>
          <Typography variant="body1" align="center">
            {i18next.t("updateDocument.succes")}
          </Typography>

        </>
      )}
    </EADialog>
  );
};

export default DossierUpdateDocumentDialog;
