import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  greenBadge: {
    color: "#2D9F40",
    backgroundColor: "#D7F4DC",
    borderRadius: "0 10px 10px 0",
    padding: "4px 8px",
    fontSize: "12px"
  },
  redBadge: {
    color: "#CC0001",
    backgroundColor: "#FFEBEB",
    borderRadius: "0 10px 10px 0",
    padding: "4px 8px",
    fontSize: "12px"
  },
  title: {
    fontSize: "18px",
    color: "#2E2E3A",
    fontWeight: 500,
    fontFamily: "Metric"
  },
  subTitle: {
    fontSize: "16px",
    color: "#7E7E8F",
    fontWeight: 400,
    position: 'relative',
    top: "-5px"
  },
  rootNoData: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7F8FC",
    color: "#9A9AAF",
    height: "224px",
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.03)",
    fontWeight: 400,
    fontSize: "14px",
    paddingTop: "20px",
  },
  noDataText: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#9A9AAF",
    marginTop: "16px",
    position: "relative",
    top: "-12px"
  },
  mentionRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "20px",
  },
  mentionLogo: {
    width: "80px",
    marginRight: "10px"
  },
  mentionLink: {
    color: "#429BDA",
    fontSize: "10px",
  },
  mentionLinkBold: {
    color: "#429BDA",
    fontSize: "10px",
    marginRight: "5px",
    fontWeight: 500,
  },
  transparencyLogoWrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  rank: {
    borderRadius: "50%",
    width: "67px",
    height: "67px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  score: {
    fontSize: "72px",
    fontWeight: "700",
    alignSelf: 'center',
    position: 'relative',
    top: '-5px',
  },
  countryFlag:{
    width: '60px !important',
    height: '60px !important',
    margin: "0 -20px"
  },
  transparencyInfo: {
    position: "absolute",
    bottom: "-20px",
    left: 0
  },

  // evolution info
  evolutionInfo: {
    position: "absolute",
    right: "16px",
    top: "16px",
    display: "flex"
  },
  evolutionNegative: {
    background: "#2D9F40",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'relative',
    top: "-3px",
    left: "4px",
    filter: "drop-shadow(0px 0.7471592426300049px 1.6050087213516235px rgba(45, 159, 64, 0.04)) drop-shadow(0px 1.7955275774002075px 3.8570592403411865px rgba(45, 159, 64, 0.06)) drop-shadow(0px 3.380819082260132px 7.262500286102295px rgba(45, 159, 64, 0.08)) drop-shadow(0px 6.030803203582764px 12.955059051513672px rgba(45, 159, 64, 0.10)) drop-shadow(0px 11.27995491027832px 24.231014251708984px rgba(45, 159, 64, 0.12))"
  },
  evolutionStable: {
    background: "#656575",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'relative',
    top: "-3px",
    left: "4px",
    filter: "drop-shadow(0px 0.7471592426300049px 1.6050087213516235px rgba(101, 101, 117, 0.04)) drop-shadow(0px 1.7955275774002075px 3.8570592403411865px rgba(101, 101, 117, 0.06)) drop-shadow(0px 3.380819082260132px 7.262500286102295px rgba(101, 101, 117, 0.08)) drop-shadow(0px 6.030803203582764px 12.955059051513672px rgba(101, 101, 117, 0.10)) drop-shadow(0px 11.27995491027832px 24.231014251708984px rgba(101, 101, 117, 0.12))"
  },
  evolutionPositive: {
    background: "#CC0001",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'relative',
    top: "-3px",
    left: "4px",
    filter: "drop-shadow(0px 0.7471592426300049px 1.6050087213516235px rgba(204, 0, 1, 0.04)) drop-shadow(0px 1.7955275774002075px 3.8570592403411865px rgba(204, 0, 1, 0.06)) drop-shadow(0px 3.380819082260132px 7.262500286102295px rgba(204, 0, 1, 0.08)) drop-shadow(0px 6.030803203582764px 12.955059051513672px rgba(204, 0, 1, 0.10)) drop-shadow(0px 11.27995491027832px 24.231014251708984px rgba(204, 0, 1, 0.12))"
  },

  // Layout
  panelWithDataRoot: {
    position: "relative",
    display: 'flex',
    justifyContent: 'center',
    gap: "9px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset",
    padding: "22px 15px",
  },
  scoreRoot: {
    display: 'flex',
    height: "167px",
    maxWidth: "200px",
    flexDirection: 'column',
    borderRadius: "12px",
    width: "20%",
    padding: "15px 22px",
    backgroundRepeat: 'no-repeat',
    boxShadow: "0px 2.76726px 8.55082px 0px rgba(210, 208, 225, 0.07), 0px 6.6501px 20.54881px 0px rgba(210, 208, 225, 0.10), 0px 12.52155px 38.6916px 0px rgba(210, 208, 225, 0.12), 0px 22.33631px 69.0192px 0px rgba(210, 208, 225, 0.14), 0px 41.77761px 129.09282px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)"
  },
  CountryRoot: {
    display: 'flex',
    height: "167px",
    maxWidth: "200px",
    flexDirection: 'column',
    width: "20%",
    padding: "15px 22px",
    borderRadius: "12px",
    backgroundRepeat: 'no-repeat',
    boxShadow: "0px 2.76726px 8.55082px 0px rgba(210, 208, 225, 0.07), 0px 6.6501px 20.54881px 0px rgba(210, 208, 225, 0.10), 0px 12.52155px 38.6916px 0px rgba(210, 208, 225, 0.12), 0px 22.33631px 69.0192px 0px rgba(210, 208, 225, 0.14), 0px 41.77761px 129.09282px 0px rgba(210, 208, 225, 0.17), 0px 100px 309px 0px rgba(210, 208, 225, 0.24)"
  },
  GraphRoot: {
    position: 'relative',
    height: "167px",
    maxWidth: "450px",
    width: "60%",
    borderRadius: "12px",
    overflow: 'hidden',
  },
  countryFlagWrapper: {
    transform: "scale(1.2)",
    position: 'relative',
    top: "15px",
    left: '5px',
    alignSelf: 'center',
  },
  graphTitleWrapper: {
    position: 'absolute',
    top: "15px",
    left: "22px",
  },
  graphYearWrapper: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: "90%",
    "& :first-child": {
      position: "relative",
      left: "5px"
    },
    "& :nth-child(2)": {
      position: "relative",
      left: "-8px"
    },
    "& :nth-child(3)": {
      position: "relative",
      left: "-6px"
    },
    "& :nth-child(4)": {
      position: "relative",
      left: "-4px"
    },
  },
  graphYearItem: {
    color: "rgba(154, 154, 175, 1)",
    fontSize: "10px",
    fontWeight: 500,
  },
}));

export default useStyles;
