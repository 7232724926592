import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { Context } from "../../../states/Store";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import { getTooltip } from "../../../services/common/ChartUtils";
import i18next from "i18next";
import useSelectedAccountId from "../../../components/common/hooks/useSelectedAccountId";

function EAActivitiesChart({ data, tab, hidden }) {
  const [state, dispatch] = useContext(Context);
  const chartRef = useRef();
  const seriesRef = useRef();
  const accountId = useSelectedAccountId();

  useLayoutEffect(() => {
    const root = am5.Root.new("activities");
    if (accountId) {
      root.setThemes([am5themes_Animated.new(root)]);
      const chart = root.container.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout,
        })
      );

      const series = chart.children.push(
        am5hierarchy.Treemap.new(root, {
          singleBranchOnly: false,
          calculateAggregates: true,
          downDepth: 1,
          upDepth: -1,
          initialDepth: 0,
          topDepth: 1,
          valueField: "value",
          fillField: "color",
          categoryField: "name",
          childDataField: "children",
          nodePaddingOuter: 0,
          nodePaddingInner: 0,
          layoutAlgorithm: "binary",
        })
      );

      // ---- ToolTip ---- //
      const tooltip = getTooltip(chart);
      tooltip.set(
        "labelHTML",
        `
        <div style="max-width: 300px; color: #FFF">
            <div style="margin-bottom: 10px">{name}</div>
            <div>Nombre : {formattedValue} / {formattedTotal}</div>
            <div>Pourcentage : {percent}%</div>
        </div>
      `
      );
      series.set("tooltip", tooltip);

      series.rectangles.template.setAll({
        strokeWidth: 1,
        toggleKey: "active",
        interactive: true,
      });

      series.labels.template.setAll({
        fontSize: 10,
        fontWeight: 400,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 12,
        paddingBottom: 12,
        textAlign: "center",
        oversizedBehavior: "truncate",
      });

      series.rectangles.template.states.create("hover", {
        fillOpacity: 0.5,
      });
      series.rectangles.template.states.create("active", {
        fillOpacity: 1,
        fill: am5.color("#FFC90B"),
      });

      series.rectangles.template.events.on("click", (ev) => {
        const rectangle = ev.target;
        const active = !rectangle.get("active");
        const selectedData = rectangle.dataItem.dataContext;
        dispatch({
          type: active ? "ADD_GRAPH_FILTER" : "REMOVE_GRAPH_FILTER",
          filter: {
            type: "treeMap",
            accountId,
            tab,
            data: {
              id: selectedData.code,
              label: selectedData.name,
              keyLabel: i18next.t(`dashboard.thirdparties.majorActivity`),
            },
          },
        });
      });

      series.appear(1000, 100);

      chartRef.current = chart;
      seriesRef.current = series;
    }
    return () => root && root.dispose();
  }, [accountId, tab, dispatch]);

  useEffect(() => {
    if (data) {
      seriesRef.current.data.setAll([data]);
    }
  }, [data]);

  // Set active selected items.
  useEffect(() => {
    if (data) {
      const children = chartRef.current.children?.values?.[0]?.dataItems?.[0]?.get(
        "children"
      );
      if (children) {
        children.forEach((item) => {
          const rectangle = item.get("rectangle");
          const label = item.get("label");
          const isActive = !!state.graphFilters.treeMap?.find(
            (x) => x.id === item.dataContext.code
          );
          rectangle.set("active", isActive);
          if(isActive) {
            label.set("fill", am5.color("#373534"))
          } else {
            label.set("fill", am5.color("#FFF"))
          }
        });
      }
    }
  }, [data, state.graphFilters.treeMap]);

  return <div id="activities" style={{ visibility: hidden ? 'hidden' : 'visible', width: "100%", height: hidden ? "0px" : "500px" }}></div>;
}

export default EAActivitiesChart;
