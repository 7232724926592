import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import useStyles from "../../style/js-style/containers/doc-viewer/DocViewerFromJsonStyle";
import i18next from "i18next";

const DocViewerFromJson = ({ body, hideFirstLevelKey = false, hideSecondLevelKey= false, keysToExclude = [], header, translationPrefix, translateValuesForKeys = [], openRowsOnLoad = false, translatValues = false }) => {
  const [jsonBody, setJsonBody] = useState(body);
  const [openRows, setOpenRows] = useState({});
  const { classes } = useStyles();

  const translateKey = useCallback((key) => {
    // if no value ( or a value with the same prefix, return the original key)
    if (translatValues === false) {
      return key;
    }
    return i18next.t(`${translationPrefix}.${key}`) === `${translationPrefix}.${key}` ? key : i18next.t(`${translationPrefix}.${key}`);
  }, [translationPrefix, translatValues]);

  const sortObjectKeys = useCallback((obj) => {
    if (Array.isArray(obj)) {
      const containsNonObjects = obj.every(item => typeof item !== 'object' || item === null || typeof item === 'string');
      if (containsNonObjects) {
        return obj.slice().sort((a, b) => {
          if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
          } else {
            return 0;
          }
        });
      } else {
        return obj.map(item => sortObjectKeys(item));
      }
    } else if (typeof obj === 'object' && obj !== null) {
      const sortedKeys = Object.keys(obj).sort((a, b) => translateKey(a).localeCompare(translateKey(b)));
      const sortedObj = {};
      sortedKeys.forEach((key) => {
        sortedObj[key] = sortObjectKeys(obj[key]);
      });

      return sortedObj;
    } else {
      return obj;
    }
  }, [translateKey]);

  useEffect(() => {  
    let newBody = {};
    if (hideFirstLevelKey) {
      Object.keys(body).forEach((key) => {
        newBody = { ...newBody, ...body[key] };
      });
      if (hideSecondLevelKey) {
        let secondLevelKeys = Object.keys(newBody).filter(
          (key) => typeof newBody[key] === 'object' && newBody[key] !== null
        );
        secondLevelKeys.forEach((key) => {
          if (!keysToExclude.includes(key)) {
            newBody = { ...newBody, ...newBody[key] };
            delete newBody[key];
          }
        });
      }
    } else {
      newBody = body;
    }
  
    // sort the object keys at all levels
    newBody = sortObjectKeys(newBody);
  
    setJsonBody(newBody);
  }, [body, hideFirstLevelKey, hideSecondLevelKey, keysToExclude, sortObjectKeys, translateKey]);
  

  useEffect(() => {
    if (jsonBody && openRowsOnLoad) {
      const initialOpenRows = {};
      const traverse = (obj, parentKey = '') => {
        Object.keys(obj).forEach(key => {
          const currentKey = parentKey ? `${parentKey}.${key}` : key;
          initialOpenRows[currentKey] = true;
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            traverse(obj[key], currentKey);
          }
        });
      };
      traverse(jsonBody);
      setOpenRows(initialOpenRows);
    }
  }, [jsonBody, openRowsOnLoad]);

  const toggleRow = key => {
    setOpenRows(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };


  const renderJsonValue = (value, key, index = 0) => {
    if (keysToExclude.includes(key.split('.').pop())) {
      return null;
    }

    const isRowOpen = openRows[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      return (
        <div className={classes.objectInline} style={{ display: 'flex', alignItems: isRowOpen ? 'flex-start' : 'center' }}>
          <IconButton onClick={(e) => { toggleRow(key); e.stopPropagation() }} style={{ padding: 0, margin: isRowOpen ? '3px 0' : '0' }}>
            {isRowOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          {!isRowOpen && <div className={classes.simpleValue} onClick={(e) => { toggleRow(key); e.stopPropagation() }} style={{ display: 'inline', cursor: "pointer" }}>{`${translateKey('item')} ${String(index + 1).padStart(2, '0')}`}</div>}
          <div style={{ width: '100%', display: isRowOpen ? 'block' : 'none' }}>
            <Table>
              <TableBody>
                {Object.keys(value).map(innerKey => (
                  <TableRow key={`${key}.${innerKey}`} className={classes.simpleObjectRow}>
                    <div>{translateKey(innerKey)}</div>
                    <div>{renderJsonValue(value[innerKey], `${key}.${innerKey}`)}</div>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      );
    } else if (Array.isArray(value)) {
      const arrayLength = value.length;
      return (
        <>
          <IconButton onClick={(e) => { toggleRow(key); e.stopPropagation() }} style={{ padding: 0 }}>
            {isRowOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          <div style={{ display: 'inline' }}>{isRowOpen ? `[${arrayLength}]` : `[${arrayLength}]`}</div>
          <div style={{ width: '100%', display: isRowOpen ? 'block' : 'none' }}>
            {value.map((item, index) => (
              <div key={`${key}.${index}`}>
                <TableRow>
                  <TableCell onClick={(e) => { toggleRow(key); e.stopPropagation() }} style={{ cursor: 'pointer' }}>{index === 0 ? translateKey(key.split('.').pop()) : ''}</TableCell>
                  <TableCell>{renderJsonValue(item, `${key}.${index}`, index)}</TableCell>
                </TableRow>
              </div>
            ))}
          </div>
        </>
      );
    } else {
      // Render primitive value
      if (typeof value === 'boolean') {
        return <div className={classes.simpleValue}> {translateKey(value.toString())} </div>
      } else if (typeof value === 'string' && translateValuesForKeys.includes(key.split('.').pop())) {
        return <div className={classes.simpleValue}> {translateKey(value)} </div>
      } else if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
        return <div className={classes.simpleValue}> {new Date(value).toLocaleDateString()} </div>
      } else {
        return <div className={classes.simpleValue}> {value != null ? value : translateKey('empty')}</div>
      }
    }
  };

  const renderJson = (obj, parentKey = '') => {
    return Object.keys(obj).map(key => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const value = obj[key];

      if (!keysToExclude.includes(currentKey.split('.').pop())) {
        if (Array.isArray(value)) {
          // If value is an array, render each item in a row
          return (
            <>
              <TableRow key={currentKey}>
                <TableCell className={classes.tableCellObject} onClick={(e) => { toggleRow(currentKey); e.stopPropagation() }} style={{ cursor: 'pointer' }}>
                  <IconButton onClick={(e) => { toggleRow(currentKey); e.stopPropagation() }} style={{ padding: 0 }}>
                    {openRows[currentKey] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                  </IconButton>
                  {translateKey(currentKey.split('.').pop())}
                </TableCell>
                <TableCell>
                  <div style={{ width: '100%' }}>
                    {openRows[currentKey] ? value.map((item, index) => (
                      <div key={`${currentKey}.${index}`}>
                        <TableRow>{renderJsonValue(item, `${currentKey}.${index}`, index)}</TableRow>
                      </div>
                    )) : <TableRow className={classes.simpleValue} onClick={(e) => {toggleRow(currentKey); e.stopPropagation()}}  style={{ cursor: 'pointer' }}>{`${value.length} ${translateKey(value.length > 1 ? 'items' : 'item')}`}</TableRow>
                    }
                  </div>
                </TableCell>
              </TableRow>
            </>
          );
        } else if (typeof value === 'object' && value !== null) {
          // If value
          // If value is an object, recursively render its properties
          return (
            <>
              <TableRow key={currentKey}>
                <TableCell onClick={(e) => { toggleRow(currentKey); e.stopPropagation(); }} style={{ cursor: 'pointer' }}>
                  <IconButton onClick={(e) => { toggleRow(currentKey); e.stopPropagation(); }} style={{ padding: 0 }}>
                    {openRows[currentKey] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                  </IconButton>
                  {translateKey(currentKey.split('.').pop())}
                </TableCell>
                <TableCell>
                  <div style={{ width: '100%', display: openRows[currentKey] ? 'block' : 'none' }}>
                    {renderJson(value, currentKey)}
                  </div>
                </TableCell>
              </TableRow>
            </>
          );
        } else {
          return (
            <TableRow key={currentKey}>
              <TableCell>{translateKey(currentKey.split('.').pop())}</TableCell>
              <TableCell >{renderJsonValue(value, currentKey)}</TableCell>
            </TableRow>
          );
        }
      } else {
        return null;
      }
    });
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        {header && (
          <TableHead>
            <TableRow>
              <TableCell>{header}</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {renderJson(jsonBody)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocViewerFromJson;
