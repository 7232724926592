import { Container, Paper, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { Trans } from "react-i18next";
import { useHistory } from "react-router";
import { getEnv } from "../config/Config";

const useStyles = makeStyles()((theme) => ({
    containerBackground: {
        padding: "4em", 
        width: "100%", 
        height: "100vh", 
        maxWidth: "100%", 
        display: "flex",
        background: "no-repeat center/100% url(/img/background/2560_1440.png)"
    },
    paperBackground: {
        padding: "2em", 
        width: "40%", 
        margin: "auto", 
        paddingLeft: "3em"
    },
    textStyle: {
        whiteSpace: 'pre-wrap',
        lineHeight: 2
    }

}));

const UnAuthorized = () => {

  let history = useHistory();

    const { classes } = useStyles();
    return (
        <Container className={classes.containerBackground}>
          <Paper className={classes.paperBackground}>          
            <Typography className={classes.textStyle}>
              Vous n’avez pas accès à la plateforme e-Attestations <span style={{color: "green"}}> 365.</span>
            </Typography>

            {history?.location?.search !== '?ssoMode=true' && (
              <>
              <Typography className={classes.textStyle}>
                Vous souhaitez accéder à la plateforme Donneurs d’ordre ?
              </Typography>

              <Typography className={classes.text}>
                <Trans i18nKey="unauthorized.redirect-link">Suivez <a href={getEnv("REACT_APP_DO_URL")}>ce lien</a> et connectez-vous avec vos identifiants habituels.</Trans>
              </Typography>
            </>
            )}
          </Paper>
        </Container>
    );
}

export default UnAuthorized;