import React, {
  useLayoutEffect
} from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import _ from "lodash";

function EATransparencyLineChart({ id, data }) {

  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    if (data && !_.isEmpty(data)) {

      let mostRecentData = data[data.length - 1];
      let firstData = data[0];

      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      // Remove the comma to separate hundreds
      root.numberFormatter.setAll({
        numberFormat: "#",
        numericFields: ["valueX"]
      });

      // Create chart
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 5
      }));

      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 70,
        inside: true
      });

      xRenderer.labels.template.setAll({
        visible: true,
        fontSize: 12,
        fill: "#7E7E8F"
      });

      xRenderer.grid.template.setAll({
        visible: false
      });

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        min: firstData.cpiYear, //min X value is the first year of the data
        max: mostRecentData.cpiYear,  //max X value is the last year of the data
        strictMinMax: true,
        valueField: "cpiYear",
        renderer: xRenderer,
        extraMax: 0.10, //Allow to have space on the right if a bullet go outside the container
      }));

      let yRenderer = am5xy.AxisRendererY.new(root, {})
      yRenderer.grid.template.setAll({
        visible: false
      });
      yRenderer.labels.template.set('visible', false);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: mostRecentData.countryScore > 15 ? mostRecentData.countryScore - 15 : 0, // Set the origin of y axis to the last value minus 15
        max: mostRecentData.countryScore + 15, // Set the max value of y axis to the last value plus 15
        extraMax: 0.15, //Allow to have space on top if a bullet go outside the container
        renderer: yRenderer
      }));

      // Add series
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "countryScore",
        valueXField: "cpiYear",
        sequencedInterpolation: true,
        stroke: am5.color("#77171B"),
        fill: am5.color(mostRecentData.color),
        maskBullets: false
      }));

      series.strokes.template.setAll({
        strokeWidth: 3
      });

      // Display color under the line of the graph
      series.fills.template.setAll({
        visible: true,
        fillOpacity: 1,
        fillGradient: am5.LinearGradient.new(root, {
          stops: [{
            color: am5.color(mostRecentData.color),
            opacity: 0.4,
          }, {
            color: am5.color(mostRecentData.color),
            opacity: 0.1,
          }],
          rotation: 90
        })
      });

      series.bullets.push(function (root, series, dataItem) {
        if (dataItem?.dataContext?.cpiYear === firstData.cpiYear) {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Label.new(root, {
              centerX: am5.p50,
              centerY: am5.p100,
              height: 25,
              fontSize: "10px",
              fontWeight: 700,
              text: "{valueY}",
              populateText: true,
              dy: -10,
              dx: 20,
              fill: am5.color("#495156"),
              background: am5.RoundedRectangle.new(root, {
                fill: "#F2F3F7",
                shadowColor: am5.color("#7E7E8F"),
                shadowBlur: 10,
                shadowOffsetY: 4,
                shadowOpacity: 0.3
              }),
            })
          });
        } else if (dataItem?.dataContext?.cpiYear === mostRecentData?.cpiYear) {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Label.new(root, {
              centerX: am5.p50,
              centerY: am5.p100,
              height: 30,
              fontSize: "12px",
              fontWeight: 700,
              text: "{valueY}",
              populateText: true,
              dy: -15,
              fill: am5.color("#495156"),
              background: am5.RoundedRectangle.new(root, {
                fill: "#F2F3F7",
                shadowColor: am5.color("#7E7E8F"),
                shadowBlur: 10,
                shadowOffsetY: 4,
                shadowOpacity: 0.3
              }),
            })
          });
        }else{
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Label.new(root, {
              centerX: am5.p50,
              centerY: am5.p100,
              height: 25,
              fontSize: "10px",
              fontWeight: 700,
              text: "{valueY}",
              populateText: true,
              dy: -15,
              fill: am5.color("#495156"),
              background: am5.RoundedRectangle.new(root, {
                fill: "#F2F3F7",
                shadowColor: am5.color("#7E7E8F"),
                shadowBlur: 10,
                shadowOffsetY: 4,
                shadowOpacity: 0.3
              }),
            })
          });
        }
      });

      // Display bullet with a circle if it's the last dataItem
      // Empty external circle
      series.bullets.push(function (root, series, dataItem) {
        if (dataItem?.dataContext?.cpiYear === mostRecentData.cpiYear) {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Circle.new(root, {
              strokeWidth: 2,
              radius: 10,
              stroke: series.get("fill"),
              fill: am5.color("#FFFFFF")
            })
          });
        }
      });
      // Inner dot
      series.bullets.push(function (root, series, dataItem) {
        if (dataItem?.dataContext?.cpiYear === mostRecentData.cpiYear) {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Circle.new(root, {
              radius: 7,
              fill: series.get("fill")
            })
          });
        }
      });

      // Display bullet with a label if it's the last dataItem
     /*  series.bullets.push(function (root, series, dataItem) { //The series param is needed even we don't use it !!!
        if (dataItem?.dataContext?.cpiYear === mostRecentData.cpiYear) {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              text: i18next.t("transparency.range"),
              centerX: am5.percent(50),
              centerY: am5.percent(100),
              populateText: true,
              dy: -10,
              fill: "#7E7E8F"
            })
          });
        }
      });
 */

      // Set data
      xAxis.data.setAll(data);
      series.data.setAll(data);

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);
    }

    return () => root?.dispose();
  }, [id, data]);

  return (
    <div
      style={{
        height: "200px",
        width: "100%"
      }}
    >
      <div
        style={{
          height: "180px",
          overflowY: data?.length > 8 ? "auto" : "hidden",
          overflowX: "hidden",
          display: "flex",
          alignItems: data?.length > 8 ? "flex-start" : "center",
          width: "100%",
          position: 'relative',
          left: "-15px",
          top: "37px",
        }}
      >
        <div
          id={id}
          style={{ width: "100%", height: "160px" }}
          onWheel={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
}

export default EATransparencyLineChart;
