import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ERROR, INFO } from "../../../services/common/Constants";
import CreationFormDossierInfosBloc from "../../creation-form/CreationFormDossierInfosBloc";
import { formatDossierCreationData } from "../../../services/common/ThirdpartyUtils";
import {
  createDossier
} from "../../../services/edge/DossierService";
import EAButton from "../../../components/common/EAButton";
import useStyles from "../../../style/js-style/containers/dossiers/create-dossier/DossierFillInfosStyle";
import {isEmpty} from "lodash";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

function DossierFillInfos({ thirdparty, documents, dossierTypes }) {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [documentsSelected, setDocumentsSelected] = React.useState([]);
  let history = useHistory();
  const formMethods = useForm({ mode: "onChange", delayError: 500 });

  useEffect(() => {
    if (!thirdparty) {
      history.push("/");
    }
    formMethods.setValue("expirationDate", null);
  }, [formMethods, history, thirdparty]);

  const submitDossier = async (data) => {
    dispatch({
      type: "ALERT",
      alert: {
        type: INFO,
        msg: "DOSSIER_CREATION_IN_PROGRESS",
        spinner: true,
      },
    });
    const body = formatDossierCreationData(data, thirdparty, documentsSelected);
    try {
      startLoading();
      const resultsDossierCreation = await createDossier(
        state.account.id,
        thirdparty?.thirdpartyId,
        body,
        dispatch
      );
      getProcess(resultsDossierCreation);
    } catch (error) {
    }finally {
      stopLoading();
    }
  };

  const getProcess = async (results) => {

    if (results.content?.errors && results.content?.errors[0]?.code) {
      // TODO: get all error codes for i18n
      if (results.content?.errors[0].code === "E112") {
        formMethods.setError("thirdpartyCode", {
          message: i18next.t("infos.E112"),
        });
      }
      dispatchError(results.content?.errors[0].code);
    } else {

      history.push(
        `/${state.account.id}/dossiers/${results.content.dossierId}`
      );
    }

  };

  const dispatchError = (name) => {
    dispatch({ type: "ALERT", alert: { type: ERROR, msg: name } });
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(submitDossier)}
          className={classes.dossierCreationForm}
        >
          <CreationFormDossierInfosBloc
            documents={documents}
            dossierTypes={dossierTypes}
            documentsSelection={{
              items: documentsSelected,
              setItems: setDocumentsSelected,
            }}
          />

          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              <EAButton
                className={classes.saveBtn}
                type="submit"
                disabled={!isEmpty(formMethods.formState.errors) || isRequestLoading}
                content={i18next.t("addDossier.buttonCreate")}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
}

export default DossierFillInfos;
