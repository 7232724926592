import React, {useState, useCallback, useContext} from "react";
import { Trans } from "react-i18next";

import EADialog from "components/common/EADialog";
import { ERROR, SUCCESS } from "services/common/Constants";
import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import { Context } from "states/Store";
import { updateCalculation } from "services/edge/CalculatorService";

import CalculatorForm from "../form/CalculatorForm";
import InitialStep from "./steps/InitialStep";
import DeclarationStep from "./steps/DeclarationStep";
import FinalEditStep from "./steps/FinalEditStep";
import {CalculatorFormSteps, cleanFormData} from "../../../../services/common/CalculatorUtils";

function CalculatorEditDialog({
    calculation,
    isOpen,
    onClose,
    onDelete,
    onValidateSuccess,
    formId
}) {
    const { classes } = useStyles();
    const [state, dispatch] = useContext(Context);
    const [currentStep, setCurrentStep] = useState(CalculatorFormSteps.INITIAL); // INITIAL, DECLARATION, FINAL

    const handleSubmit = useCallback(async (data) => {
        try {
            await updateCalculation(state.account.id, calculation?.id, cleanFormData(data), null);

            const name = data.name;
            const message = data.enabled
              ? <Trans i18nKey="calculator.notifications.toggleActivation"> Le calcul <span style={{ color: "#0B2810", fontWeight: 600 }}>"{{ name }}"</span> a bien été activé.</Trans>
              : <Trans i18nKey="calculator.notifications.save"> Le calcul <span style={{ color: "#0B2810", fontWeight: 600 }}>"{{ name }}"</span> a bien été enregistré.</Trans>

            dispatch({
                type: "ALERT",
                alert: { type: SUCCESS, msg: message },
            });

            onValidateSuccess();
        } catch (error) {
            const err =
                error?.message?.messages?.error && error?.message?.messages?.error[0];
            dispatch({
                type: "ALERT",
                alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
            });
        }
    }, [state.account, calculation, dispatch, onValidateSuccess]);

    return (
        <EADialog
            isOpen={isOpen}
            onCancel={() => {
                onClose();
            }}
            hasActions={false}
            maxWidth={[CalculatorFormSteps.INITIAL, CalculatorFormSteps.FINAL].includes(currentStep) ? "popupxs" : "popupxl"}
            fullWidth
            customDialogStyle={{ backgroundColor: [CalculatorFormSteps.INITIAL, CalculatorFormSteps.FINAL].includes(currentStep) ? "" : "#F2F3F7 !important", padding: [CalculatorFormSteps.INITIAL].includes(currentStep) ? "" : "42px !important" }}
        >
            <div className={["INITIAL"].includes(currentStep) ? classes.root : classes.rootDeclaration}>
                <CalculatorForm
                    calculation={calculation}
                    formId={formId}
                    submitHandler={() => { /* Do nothing */ }}
                >
                    {/* Modification d'un calcul */}
                    {[CalculatorFormSteps.INITIAL].includes(currentStep) &&
                        <InitialStep
                            calculation={calculation}
                            isEdition={true}
                            onCancel={() => {
                                onClose();
                            }}
                            onContinue={() => {
                                setCurrentStep(CalculatorFormSteps.DECLARATION);
                            }}
                        />
                    }

                    {/* Déclaration des variables/branches */}
                    {[CalculatorFormSteps.DECLARATION].includes(currentStep) &&
                        <DeclarationStep
                            calculation={calculation}
                            isEditionMode={true}
                            onClose={onClose}
                            onDelete={onDelete}
                            isActiveCalculation={calculation.enabled}
                            onCancel={() => {
                                setCurrentStep(CalculatorFormSteps.INITIAL);
                            }}
                            onContinue={() => {
                                setCurrentStep(CalculatorFormSteps.FINAL);
                            }}
                        />
                    }

                    {/* Confirmation de modification d'un calcul existant */}
                    {[CalculatorFormSteps.FINAL].includes(currentStep) &&
                      <FinalEditStep
                        calculation={calculation}
                        onClose={onClose}
                        isActiveCalculation={calculation.enabled}
                        onCancel={() => {
                            setCurrentStep(CalculatorFormSteps.DECLARATION);
                        }}
                        onContinue={handleSubmit}
                      />
                    }
                </CalculatorForm>
            </div>
        </EADialog>
    );
}

export default CalculatorEditDialog;
