import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { isEmpty } from "lodash";
import useStyles from "../../../../style/js-style/containers/administration/rules/RuleFormsStyle";
import { getRuleActionModel } from "services/common/RulesUtils";

const getFormDefaultValues = (rule) => {
  return {
    ruleId: rule?.id || "",
    name: rule?.name || "",
    enabled: rule?.enabled || false,
    accountId: rule?.accountId || "",
    criteria: rule?.criteria?.map((item) => {
      return {
        id: item.id,
        entityKey: item.entityKey,
        field: item.field,
        fieldType: item.fieldType,
        operator: item.operator,
        attributeId: item.attributeId,
        values: item.values || [{
          id: "",
          value: "",
        }],
      }
    }) || [
        {
          id: "",
          entityKey: "sch_ea_365.v_third_parties",
          field: "",
          fieldType: "",
          operator: "",
          attributeId: "",
          values: [],
        },
      ],

    actions: rule?.actions?.map((item) => {
      return {
        id: item?.id,
        type: item?.type,
        parameters: item?.parameters,
        ruleId: item?.ruleId,
      }
    }) || [
        getRuleActionModel(),
      ]
  };
};

const RuleForm = ({ rule, formId, submitHandler, children }) => {
  const { classes } = useStyles();

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: getFormDefaultValues(rule),
  });

  const {
    formState: { errors },
  } = formMethods;

  const submitForm = (data) => {
    if (isEmpty(errors)) {
      submitHandler(data);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id={formId}
        onSubmit={formMethods.handleSubmit(submitForm)}
        className={classes.formContainer}
      >
        {children}
      </form>
    </FormProvider>
  );
}

export default RuleForm;