import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogTitle:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "66px",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
    position: "absolute",
    top: 0,
    right: 0,
    width: "630px",
    backgroundColor: "white",
    zIndex: 9999,
  },
  dialogTitleMain: {
    color: "#9A9AAF",
    textAlign: "center",
    fontFamily: "Metric",
    fontSize: "20px",
    fontWeight: 600,
  },
  dialogTitleSub: {
    color: "#C6CBD9",
    fontSize: "14px",
    fontWeight: 400,
    position: "relative",
  },
  closeCross: {
    position: "absolute",
    top: "25px",
    right: "21px",
    cursor: "pointer",
    zIndex: 9000,
  },
  mainContentWrapper: {
    padding: "41px 37px",
    overflow: "auto",
    marginTop: "66px",
  },
  mouvementRoot: {
    marginBottom: "52px"
  },
  mouvementInfoWrapper: {
    marginLeft: "38px",
  },
  mouvementInfoItemWrapper: {
    marginBottom: "8px",
  },
  mouvementInfoTitle:{
    color: "#373534",
    fontSize: "14px",
    fontWeight: 700,
  },
  mouvementInfoOldValue: {
    color: "#656575",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    borderRadius: "12px",
    background: "#F2F3F7",
    padding: "4px 8px",
    width: "fit-content",
    marginTop: "5px",
    marginBottom: "5px",
  },
  mouvementInfoNewValue: {
    color: "#2D9F40",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    borderRadius: "12px",
    background: "#EFFBF1",
    padding: "4px 8px",
    width: "fit-content",
    marginTop: "5px",
    marginBottom: "5px",
  },
  mouvementInfoArrow: {
    margin: "0 7px",
  },
  sectionTitle: {
    color: "#16161E",
    fontFamily: "Metric",
    fontSize: "25px",
    fontWeight: 600,
  },
  sectionTitleIcon: {
    marginRight: "8px",
    position: "relative",
    top: "5px",
  },
  detailRoot: { },
  detailItemsWrapper: {
    marginTop: "22px",
  },
  detailItem: {
    display: "flex",
    gap: "0 19px"
  },
  detailItemBulletWrapper: {
    width: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  detailItemBullet: {
    padding: "8px 12px",
    backgroundColor: "#2E2E3A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderRadius: "10px",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
    fontWeight: 600,
    fontFamily: "Metric",
    gap: "8px",
    fontSize: "12px",
  },
  detailItemVerticalDividerDashed: {
    borderRight: "2px dashed #9A9AAFC0",
    height: "20px",
  },
  detailItemVerticalDivider: {
    flex: 1,
    borderRight: "2px solid #9A9AAF",
  },
  detailItemContent: {
    cursor: "pointer",
    position: "relative",
    flex: 1,
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
    padding: "5px 36px",
    marginBottom: "32px",
    backgroundColor: "white",
    overflow: "hidden",
  },
  detailItemLineLabel: {
    color: "#373534",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },
  detailItemLineValue: {
    color: "#373534",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
  },
  openedArrowUp: {
    position: "absolute",
    top: "14px",
    left: "16px",
    rotate: "180deg",
  },
  openedArrowDown: {
    position: "absolute",
    top: "14px",
    left: "16px",
  },
}));

export default useStyles;
