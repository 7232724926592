import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import EADatePicker from "components/common/EADatePicker";

const AttributeDateInput = ({onError = () => {}}) => {
  const formMethods = useFormContext();
  return (
    <Controller
      control={formMethods.control}
      name="value"
      render={({ field }) => (
        <EADatePicker
          {...field}
          formMethods={formMethods}
          onlyDate
          sxClass={{
            width: "200px",
            "& .MuiInputLabel-root": {
              left: "31px",
              top: "-9px"
            },
            "& .MuiInputLabel-shrink": {
              left: "0px",
              top: "0px"
            },
          }}
          onError={onError}
          ref={null}
        />
      )}
    />
  );
};
export default AttributeDateInput;
