import { Typography, Tooltip, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import EADataGrid from "../../../components/common/datatable/EADataGrid";
import EAPagination from "../../../components/common/datatable/EAPagination";
import { CountSelectedRow } from "../../../components/common/datatable/CountSelectedRow";
import useStyles from "../../../style/js-style/containers/dossiers/create-dossier/DossierChoiceDocumentsStyle";
import MainHeaderCell from "../../../components/common/datatable/MainHeaderCell";

const getDocumentDataTableColumns = (
  context
) => {
  return [
  {
    field: "descriptionFr",
    headerName: i18next.t("myDocuments.documentName"),
    flex: 1,
    renderHeader: () => (
      <MainHeaderCell
        name={i18next.t("myDocuments.documentName")}
      />
    ),
    renderCell: (params) => (
      <Tooltip title={params.row["descriptionFr"] || ""}>
        <Typography noWrap component={"span"}>
          {params.row["descriptionFr"] || ""}
        </Typography>
      </Tooltip>
    )},
    ...(context === "dossier-model" ? [
      {
        field: "code",
        headerName: i18next.t("myDocuments.documentCode"),
        flex: 1,
        renderHeader: () => (
          <MainHeaderCell
            name={i18next.t("myDocuments.documentCode")}
          />
        ),
        renderCell: (params) => (
            <Typography noWrap component={"span"} className="dt">
              {params.row["code"]}
            </Typography>
        )
      },
      {
        field: "category",
        headerName: i18next.t("myDocuments.category"),
        flex: 1,
        renderHeader: () => (
          <MainHeaderCell
            name={i18next.t("myDocuments.category")}
          />
        ),
        renderCell: (params) => (
          <Tooltip title={params.row["category"]}>
            <Typography noWrap component={"span"}>
              {params.row["category"]}
            </Typography>
          </Tooltip>
        )
      }
    ] : [])
]};

const documentDefaultVisibilityColumns = (
  context
) => {
  return context === "dossier-model" ? { selection: true, descriptionFr: true, code: true, category: true } : { selection: true, descriptionFr: true };
};

function DossierChoiceDocuments({
  documents,
  selection,
  hasCustomPagination = false,
  context = "default"
}) {
  const { classes } = useStyles();

  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({});

  useEffect(() => {
    setColumns(
      getDocumentDataTableColumns(context)
    );
    setVisibleColumns(
      documentDefaultVisibilityColumns(context)
    );
  }, [ context ]);

  return (
    <div className={classes.root}>
      { context === "dossier-model" && <div className={classes.divider}/> }
      <Paper elevation={0} className={classes.card}>
        <Grid container>
          {context === "default" &&
            <Grid item xs={12} pb={1}>
              <Typography variant="h3">
                {i18next.t("addDossier.documentSelection")}
              </Typography>
            </Grid>
          }
          <Grid item xs={12} className={classes.documents}>
            {columns && (
              <EADataGrid
                loading={false}
                inputRows={documents}
                hasCustomPagination={hasCustomPagination}
                selection={selection}
                disableColumnSelector
                initialVisibilityModel={visibleColumns}
                additionnalsLeftComponents={<CountSelectedRow data={selection?.items} />}
                columns={columns}
                sortingOrder={['desc', 'asc', null]}
                customSx={{
                  padding: 0,
                  "& .MuiDataGrid-main": { maxHeight: "270px", overflowY: "auto", border: "1px solid #E2E2EA", borderRadius: "4px" },
                  "& .MuiTablePagination-selectLabel": { display: "none !important" },
                }}
              />
            )}
          </Grid>
          {hasCustomPagination && <EAPagination isLoading={false} />}
        </Grid>
      </Paper>
    </div>
  );
}

export default DossierChoiceDocuments;
