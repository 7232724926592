import i18next from "i18next";
import { getEnv } from "../../config/Config";
import { eaFetch, objToQueryString } from "../common/ApiUtils";
import _ from "lodash";
import { FINANCIAL_STRENGTH_SCORES, FINANCIAL_STRENGTH_SCORES_VALUE } from "services/common/DataBlocksUtils";
import { InduedSeverityEnum } from "services/common/InduedUtils";
import { ThirdpartyAdditionalFieldsNames, Exports, ThirdpartyScoresFieldsNames } from "services/common/Constants";

export async function searchThirdparty(accountId, body, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/search`,
    body
  );
}


const cleanEmptyTpFilters = (data) => {
  let res = {};
  for (const [key, value] of Object.entries(data)) {
    if (_.isArray(value)) {
      if (!_.isEmpty(value)) {
        if (value.some((x) => _.isObject(x))) {
          // For select arrays containing objects like attributes
          const nonEmptyValues = value?.filter(
            (x) => (!_.isEmpty(x.values) || typeof x.values === "number") && x.values !== 'Invalid date'  //TODO: temporary date fix, should fix EADatePicker
          );
          !_.isEmpty(nonEmptyValues) && (res[key] = nonEmptyValues);
        } else {
          // For multi select simple arrays containing string
          res[key] = value;
        }
      }
    } else {
      !_.isEmpty(value) && (res[key] = value);
    }
  }
  if(!res['identifier']) delete res['companyIdType'];

  return res;
};

function buildThirdpartyFilters(filters) {
  const cleanedFilters = cleanEmptyTpFilters(_.cloneDeep(filters));

  let result = {
    scores: {}
  };
  if (cleanedFilters) {
    var indued = cleanedFilters?.indued;
    var complex = {
      attributes: cleanedFilters?.attributes,
      calculatedAttributes: cleanedFilters?.calculatedAttributes,
      indicators: cleanedFilters?.indicators,
    };
    const {
      attributes: _1,
      calculatedAttributes: _2,
      indicators: _3,
      indued: _4,
      ...newFilter
    } = cleanedFilters;

    for (const [key, value] of Object.entries(newFilter)) {
      if(Object.values(ThirdpartyScoresFieldsNames).includes(key)) {
        // Add 'VERY HIGH' for risk segment when 'HIGH' is selected
        if(key === 'financialStrengthScore') {
          if(value?.includes(FINANCIAL_STRENGTH_SCORES.HIGH)) {
            value.push(FINANCIAL_STRENGTH_SCORES.VERY_HIGH)
          }
          result.scores[key] = value?.map(x => FINANCIAL_STRENGTH_SCORES_VALUE[x])
        } else {
          result.scores[key] = value;
        }
      } else {
        result[key] = value;
      }
    }
    for (const [key, arr] of Object.entries(complex)) {
      if(!_.isEmpty(arr)) {
        result[key] = {}
        arr.forEach(({ id, values }) => {
          result[key][id] = values !== "notSpecified" ? [values] : null;
        });
      }
    }

    if (indued) {
      result.scores.indued = {}
      for (let { score, values } of indued) {
        result.scores.indued[score] = values?.map(x => InduedSeverityEnum[x]);
      }
    }
  }

  return result;
}

const buildThirdpartiesSort = (sortModel) => {
  let sort = {};
  if (!_.isEmpty(sortModel)) {
    sortModel?.forEach((x) => {
      sort[x.field] = x.sort;
    });
  }
  return sort;
}

export async function getAccountThirdparties(
  accountId,
  onboarded,
  pagination,
  filters,
  sortModel,
  dispatch,
  signal
) {

  const body = {
    filters: {thirdparties: [buildThirdpartyFilters(filters)]},
    sort: buildThirdpartiesSort(sortModel),
    outputs: Object.values(ThirdpartyAdditionalFieldsNames)
  };

  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/advanced-search?onboarded=${onboarded}&${objToQueryString(pagination)}`,
    body, null, null, signal
  );
}

export async function getExportFileFromAccountThirdParties(
  accountId,
  onboarded,
  exportType = Exports.CSV,
  filters,
  sortModel,
  exportDto,
  dispatch
) {

  const body = {
    filters: {thirdparties: [buildThirdpartyFilters(filters)]},
    sort: buildThirdpartiesSort(sortModel),
    outputs: Object.values(ThirdpartyAdditionalFieldsNames),
    ...exportDto
  };

  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/advanced-search/${exportType.toLowerCase()}-export?onboarded=${onboarded}`,
    body,
    null,
    true
  );
}

export async function getThirdparty(accountId, thirdpartyId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdpartyId}`
  );
}

export async function stopThirdparty(
  accountId,
  thirdpartyId,
  deactivationReason,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/stop`,
    { deactivationReason }
  );
}

export async function getThirdpartyDocuments(
  accountId,
  thirdpartyId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/thirdparties/${thirdpartyId}/documents`
  );
}

export async function addThirdparty(accountId, thirdpartyToAdd, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties`,
    thirdpartyToAdd
  );
}

export async function getThirdpartyRisks(accountId, thirdpartyId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdpartyId}/risks`
  );
}

export async function refreshThirdpartyIndued(
  accountId,
  thirdpartyId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/indued/refresh`
  );
}

export async function launchThirdpartyNewDueligence(
  accountId,
  thirdpartyId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/indued/due_diligence`
  );
}

export async function getAllTPContacts(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/contacts`
  );
}

export async function getTPContacts(accountId, thirdpartyId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdpartyId}/contacts`
  );
}

export async function createTPContacts(
  accountId,
  thirdpartyId,
  userToCreate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/contacts`,
    userToCreate
  );
}

export async function updateTPCode(
  accountId,
  thirdpartyId,
  supplierImport,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/thirdparties/${thirdpartyId}`,
    supplierImport
  );
}

export async function checkThirdpartyCode(
  accountId,
  thirdpartyId,
  supplierImport,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/checkCodeExist`,
    supplierImport
  );
}

export async function deleteTPContacts(accountId, contactsToDelete, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/contacts`,
    contactsToDelete
  );
}

export async function reinviteTPContacts(
  accountId,
  thirdpartyId,
  contactId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/contacts/${contactId}/reinvite`
  );
}

export async function getAuthorizingRolesForThirdParty(
  accountId,
  thirdPartyId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdPartyId}/authorizing-roles`
  );
}

export async function getSummaryImportThirdParties(accountId, thirdPartiesImport, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/thirdparties/imports/summary`, 
    thirdPartiesImport
  );
}

export async function importThirdPartiesFromFile(
  accountId,
  thirdPartiesImport,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/thirdparties/imports`,
    thirdPartiesImport
  );
}

export async function getListImportThirdParties(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/thirdparties/imports`
  );
}
export async function sendInvitationImportThirdParties(
  accountId,
  importId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/thirdparties/imports/invitation/${importId}`
  );
}
export async function saveFileImportThirdParties(
  accountId,
  importId,
  formData,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "MULTIPART",
    `365/account/${accountId}/thirdparties/imports/file/${importId}`,
    formData
  );
}
export async function getFileImportThirdParties(accountId, importId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/thirdparties/imports/file/${importId}`,
    null,
    null,
    true
  );
}
export async function getRapportImportThirdParties(
  accountId,
  importId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/thirdparties/imports/rapport/${importId}?lang=${i18next.language}`,
    null,
    null,
    true
  );
}

export async function getThirdpartyCalculatedAttributes(
  accountId,
  thirdPartyId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdPartyId}/calculated-attributes`
  );
}

export async function getThirdPartyIndicators(
  accountId,
  thirdPartyId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdPartyId}/indicators`
  );
}

export async function getThirdPartyScores(accountId, thirdpartyId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdpartyId}/scores`
  );
}

export async function getTransparencyScoreCount(accountId, dispatch, signal) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/thirdparties/transparency-score`,
    null, null, null, signal
  );
}
