import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import { Context } from "../../../states/Store";
import i18next from "i18next";
import useSelectedAccountId from "../hooks/useSelectedAccountId";

export const EALegendChart = ({ id, tab, subTab, chartType, series, height = "50px" }) => {
  const [activeBars, setActiveBars] = useState();
  const [, dispatch] = useContext(Context);
  const accountId = useSelectedAccountId();

  useEffect(() => {
    if (chartType === "barXY") {
      const res = {};
      series.forEach((serie) => {
        res[serie.get("valueXField")] = false;
      });
      setActiveBars(res);
    }
  }, [chartType, series]);

  useLayoutEffect(() => {
    const legendRoot = am5.Root.new(id);
    if (accountId) {
      const legendItemsLength = series?.length;

      const legend = legendRoot.container.children.push(
        am5.Legend.new(legendRoot, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          y: am5.percent(50),
          centerY: am5.percent(50),
          clickTarget: "none",
          layout: am5.GridLayout.new(legendRoot, {
            maxColumns: legendItemsLength === 4 ? 2 : 4,
            fixedWidthGrid: true,
          }),
        })
      );
      legend.markers.template.setAll({
        width: 16,
        height: 10,
      });
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 0,
        cornerRadiusTR: 0,
        cornerRadiusBL: 0,
        cornerRadiusBR: 0,
        y: am5.percent(50),
        centerY: am5.percent(50),
      });
      legend.labels.template.setAll({
        fill: am5.color("#373534"),
        fontSize: "12px",
        fontWeight: "400",
      });
      legend.valueLabels.template.set("forceHidden", true);

      legend.itemContainers.template.set("cursorOverStyle", "pointer");
      // Hover Data item from legend
      legend.itemContainers.template.events.on("pointerover", (ev) => {
        const item = ev.target.dataItem.dataContext;
        const component = item.component;
        if (component && component.hoverDataItem) {
          component.hoverDataItem(item);
        }
      });

      // Unhover Data item from legend
      legend.itemContainers.template.events.on("pointerout", (ev) => {
        const item = ev.target.dataItem.dataContext;
        const component = item.component;
        if (component && component.hoverDataItem) {
          component.unhoverDataItem(item);
        }
      });

      // Select Data item from legend on click
      legend.itemContainers.template.events.on("click", (ev) => {
        const dataContext = ev.target.dataItem.dataContext;
        // Click for barXY Chart
        if (chartType === "barXY") {
          const valueXField = dataContext.get("valueXField");
          const isActive = !activeBars[valueXField];
          const dataType = dataContext.dataItems?.[0]?.dataContext?.type;
          const sum = dataContext.dataItems
            ?.map((dataItem) => dataItem.dataContext[valueXField])
            .reduce((partialSum, a) => partialSum + a, 0);
          dispatch({
            type: "CLEAR_GRAPH_FILTER_BY_ID",
            filter: { type: "bar", idEndsWith: valueXField },
          });
          dispatch({
            type: isActive ? "ADD_GRAPH_FILTER" : "REMOVE_GRAPH_FILTER",
            filter: {
              type: "bar",
              accountId,
              tab,
              subTab,
              data: {
                id: `all-${valueXField}`,
                label: i18next.t(`dashboard.${dataType}.status.${valueXField}`),
                keyLabel: i18next.t(`dashboard.filters.${dataType}`),
                value: sum,
              },
            },
          });
          setActiveBars((prevState) => ({
            ...prevState,
            [valueXField]: isActive,
          }));
        }
        // Click for Pie Chart
        if (chartType === "pie") {
          const slice = dataContext.get("slice");
          const active = !slice.get("active");
          const selectedData = slice.dataItem.dataContext;
          dispatch({
            type: active ? "ADD_GRAPH_FILTER" : "REMOVE_GRAPH_FILTER",
            filter: {
              type: "pie",
              accountId,
              tab,
              subTab,
              data: {
                id: selectedData.id,
                type: selectedData.type,
                label: selectedData.itemLabel,
                keyLabel: i18next.t(`dashboard.filters.${selectedData.type}`),
              },
            },
          });
        }
      });
      legend.data.pushAll(series);
    }

    return () => legendRoot && legendRoot.dispose();
  }, [id, series, tab, subTab, accountId, chartType, activeBars, dispatch]);

  return <div id={id} style={{ marginTop: "20px", width: "100%", height }} />;
};
