import { DossiersAdditionalFieldsNames, EDGE_DATE_FORMAT, Exports } from "services/common/Constants";
import { getEnv } from "../../config/Config";
import { eaFetch, objToQueryString } from "../common/ApiUtils";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";

export async function importDossier(accountId, body, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers`,
    body
  );
}

export async function getImportResult(accountId, processId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/process/${processId}`
  )
}

export async function createDossier(accountId, thirdpartyId, body, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/dossiers`,
    body
  );
}

export async function getAllDossiers(accountId, active, lang, withAttributes, onboarded, dispatch) {
  let url = `365/account/${accountId}/dossiers/all?monitored=${active}&lang=${lang}&withAttributes=${withAttributes}`;
  if (onboarded != null) {
    url = url + `&onboarded=${onboarded}`;
  }
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    url
  );
}

const buildDossiersFilters = (filters) => {

  const filtersCopy = cloneDeep(filters);

  let result = {
    dossiers: Array.from({ length: filtersCopy.dossiers?.length || 0}, () => ({}))
  };

  // Clean filters and get separate attributes array
  let attributesArray = filtersCopy.dossiers?.map(filter => {
    const { attributes } = filter;
    delete filter.attributes;
    delete filter.isAdvancedSearchShown;
    if(isEmpty(filter.identifier)) delete filter.companyIdType;
    return attributes;
  });

  attributesArray?.forEach((attributes, index) => {
    result.dossiers[index].attributes = {}
    attributes?.forEach(attr => {
      if(!isEmpty(attr.values)) {
        result.dossiers[index].attributes[attr.id] = attr.values !== "notSpecified" ? [attr.values] : null;
      }
    })
  })

  filtersCopy.dossiers?.forEach((line, index) => {
    Object.keys(line)?.forEach(key => {
      let value = line[key];

      // Format date to iso before append
      if (value && (moment.isMoment(value) || moment(value).isValid())) {
        value = moment(value).format(EDGE_DATE_FORMAT);
      }

      result.dossiers[index][key] = value;
    })
  })

  return result;
}

const buildDossiersSort = (sortModel, monitored) => {

  const sortModelCopy = cloneDeep(sortModel);
  
  let sort = {};

  if (!isEmpty(sortModelCopy)) {
    sort = {}
    sortModelCopy?.forEach((x) => {
      // Send the corresponding key for status sort
      if(x.field === 'statusCode') x.field = 'globalStatutDemandeId';

      // Send the corresponding key for endDate
      if(x.field === "endDate") x.field = monitored ? 'dossierExpirationDate' : 'dossierStoppingDate';

      sort[x.field] = x.sort;
    });
  }

  return sort;
}


export async function getAccountDossiersSearch(accountId, monitored, onboarded,
  pagination,
  filters,
  sortModel,
  dispatch,
  signal
) {

  const body = {
    filters: buildDossiersFilters(filters),
    sort: buildDossiersSort(sortModel, monitored),
    outputs: Object.values(DossiersAdditionalFieldsNames),
  };


  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers/advanced-search?${getDossierSearchUrlParams(monitored, onboarded, pagination)}`,
    body, null, null, signal
  );
}

export async function getDossiersByCriteria(
  accountId,
  searchCriteria,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers?${searchCriteria}`
  );
}

export async function getDossiersCountByType(
  accountId,
  dispatch,
  signal
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/count-type`,
    null, null, null, signal
  );
}

export async function getDossierSheet(accountId, dossierId, lang, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/dossiers/${dossierId}?lang=${lang}&withHistoric=true`
  );
}

export async function updateDossier(
  accountId,
  dossierId,
  dossierToUpdate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers/${dossierId}`,
    dossierToUpdate
  );
}

export async function updateRequiredDocuments(
    accountId,
    dossierId,
    requiredDocuments,
    dispatch
) {
  return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "POST",
      `account/${accountId}/dossiers/${dossierId}/requirements`,
      requiredDocuments
  );
}

export async function stopDossier(
  accountId,
  dossierId,
  deactivationReason,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers/${dossierId}/stop`,
    { deactivationReason: deactivationReason }
  );
}

export async function reactivateDossier(
  accountId,
  dossierId,
  reactivateDossierData,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers/${dossierId}/reactivate`,
    reactivateDossierData
  );
}

export async function downloadAllDossierDocs(accountId, dossierId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/${dossierId}/files/download`
  );
}

export async function getDossier(accountId, dossierId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/${dossierId}`
  );
}

export async function getDossierDocument(
  accountId,
  dossierId,
  documentId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/${dossierId}/documents/${documentId}`
  );
}

export async function getDocumentHistoric(
  accountId,
  dossierId,
  typeDocumentId,
  dispatch,
  signal
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/dossiers/${dossierId}/documents/historic?typeDocumentId=${typeDocumentId}`,
    null,
    null,
    false,
    signal
  );
}

export async function getAuthorizingRolesForDossier(accountId, dossierId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/${dossierId}/authorizing-roles`,
  );
}

export async function getExportFileFromAccountDossiers(
  accountId,
  onboarded,
  monitored,
  exportType = Exports.CSV,
  filters,
  sortModel,
  exportDto,
  dispatch
) {


  const body = {
    filters: buildDossiersFilters(filters),
    sort: buildDossiersSort(sortModel, monitored),
    outputs: Object.values(DossiersAdditionalFieldsNames),
    ...exportDto
  };

  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers/advanced-search/${exportType.toLowerCase()}-export?${getDossierSearchUrlParams(monitored, onboarded)}`,
    body,
    null,
    true
  );
}

const getDossierSearchUrlParams = (monitored, onboarded, pagination) => {
  let url = `monitored=${monitored}`;
  if (onboarded != null) {
    url += `&onboarded=${onboarded}`;
  }
  if(pagination) {
    url += `&${objToQueryString(pagination)}`;
  }
  return url;
}