import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import EADialog from "../../../components/common/EADialog";
import { ERROR } from "../../../services/common/Constants";
import { createAttribute } from "../../../services/edge/AttributesService";
import AttributeForm from "./AttributeForm";
import { hasAttributeListItems } from "../../../services/common/AttributeUtils";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

const FORM_ID = "attribute-creation-form-id";

const AttributeCreateDialog = ({ bondType, isOpen, onClose, onValidateSuccess }) => {
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [isCreationDone, setIsCreationDone] = useState(false);
  const [isValidateDisabled, setIsValidateDisabled] = useState(false);

  useEffect(() => {
    setIsCreationDone(!isOpen);
  }, [isOpen]);

  const handleCreateAttribute = async (attributeToSave) => {
    try {
      startLoading();
      if (!hasAttributeListItems(attributeToSave.type)) {
        delete attributeToSave.values;
      }
      attributeToSave.bondType = bondType;
      await createAttribute(state.account.id, attributeToSave, null);
      setIsCreationDone(true);
      onValidateSuccess();
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }finally {
      stopLoading();
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      title={i18next.t("attributes.createTitle") + " " + i18next.t("attributes." + bondType)}
      onCancel={() => {
        onClose();
      }}
      formId={FORM_ID}
      hasActions={!isCreationDone}
      maxWidth="popupsm"
      fullWidth
      isValidateDisabled={isValidateDisabled || isRequestLoading}
      isFeedBack={isCreationDone}
      validateBtnLabel={i18next.t("valid")}
    >
      {/* After confirm popup */}
      {isCreationDone && (
        <FeedBackPopUp
          onClick={onClose}
          imgSrc={"/img/icons/cross_green.png"}
          content={i18next.t("attributes.createDone", {type: i18next.t("attributes." + bondType) + (bondType === "dossier" ? "s" : "")})}
          variant="valid"
          imgSize={"small"}
        />
      )}

      {/* Before confirm popup */}
      {!isCreationDone && (
        <AttributeForm
          formId={FORM_ID}
          submitHandler={handleCreateAttribute}
          bondType={bondType}
          setIsValidateDisabled={setIsValidateDisabled}
        />
      )}
    </EADialog>
  );
};

export default AttributeCreateDialog;
