import { eaFetch } from "../common/ApiUtils";
import { getEnv } from "../../config/Config";


export async function getAccountCalculations(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/calculations`
  );
}

export async function getAccountCalculationById(accountId, calculationId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/calculations/${calculationId}`
  );
}

export async function createCalculation(accountId, calculationDto, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/calculations`,
    calculationDto
  );
}

export async function updateCalculation(accountId, calculationId, calculationDto, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/calculations/${calculationId}`,
    calculationDto
  );
}

export async function toggleCalculationStatus(accountId, calculationId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/calculations/${calculationId}/status`
  );
}

export async function deleteCalculation(accountId, calculationId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/calculations/${calculationId}`
  );
}

export async function isCalculationNameAuthorized(accountId, calculationName, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/calculations/check-calculation-name`,
    { name: calculationName }
  );
}
