import React from "react";
import i18next from "i18next";
import { Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import EASelectInput from "components/inputs/EASelectInput";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";

export const DossiersSearchThirdparty = ({ lineIndex }) => {
  const { control } = useFormContext();

  return (
    <>
      <Grid item xs={3}>
        <SearchFormInputBloc label={i18next.t("Tiers")}>
          <Controller
            control={control}
            name={`dossiers.${lineIndex}.name`}
            render={({ field }) => (
              <TextField
                {...field}
                id="thirdparty-name-id"
                variant="outlined"
                placeholder={"Indiquez le nom du tiers"}
                fullWidth
              />
            )}
          />
        </SearchFormInputBloc>
      </Grid>
      <Grid item xs={3}>
        {/* Code tiers */}
        <SearchFormInputBloc label={i18next.t("Code tiers")}>
          <Controller
            control={control}
            name={`dossiers.${lineIndex}.code`}
            render={({ field }) => (
              <TextField
                {...field}
                id="thirdparty-code-id"
                variant="outlined"
                placeholder="Code tiers"
                fullWidth
              />
            )}
          />
        </SearchFormInputBloc>
      </Grid>
      <Grid item xs={2}>
        {/* Type identifiant */}
        <SearchFormInputBloc label={i18next.t("Identifiant")}>
          <EASelectInput
            fieldName={`dossiers.${lineIndex}.companyIdType`}
            options={[
              { value: "CIT003", label: "Duns" },
              { value: "CIT001", label: "Identifiant" },
              { value: "CIT002", label: "TVA" },
            ]}
          />
        </SearchFormInputBloc>
      </Grid>
      <Grid item xs={2}>
        {/* Identifiant */}
        <SearchFormInputBloc label="">
          <Controller
            control={control}
            name={`dossiers.${lineIndex}.identifier`}
            render={({ field }) => (
              <TextField
                {...field}
                id="identifier-id"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </SearchFormInputBloc>
      </Grid>
    </>
  );
};
