import React, { useContext, useEffect } from "react";
import { Context } from "../../../states/Store";
import EABarXYChart from "../../../components/common/charts/EABarXYChart";
import ROUTES from "../../../config/Routes";
import { buildBarStatusSerieItems, filterBarChartByPieStatusType, mapResourceToBarStatusChart } from "services/common/ChartUtils";

function DossiersStatesChart({ dossiersCount }) {
  const [state] = useContext(Context);
  const [dossiersCountByType, setDossiersCountByType] = React.useState();
  const [shownDossiersCountByType, setShownDossiersCountByType] = React.useState();
  const [seriesItems, setSeriesItems] = React.useState();

  useEffect(() => {
    setSeriesItems(buildBarStatusSerieItems("dossiers"));
    setDossiersCountByType(mapResourceToBarStatusChart(dossiersCount, "typeDossierLibelle", "typeDossierCode", "dossiers"));
  }, [dossiersCount]);

  // Filter Data when other graph items are selected
  useEffect(() => {
    setShownDossiersCountByType(filterBarChartByPieStatusType(state.graphFilters.pie, dossiersCountByType));
  }, [dossiersCountByType, state.graphFilters.pie]);

  return (
    <EABarXYChart
      id="dossiersStates"
      data={shownDossiersCountByType}
      seriesItems={seriesItems}
      tab={ROUTES.DOSSIERS}
    />
  );
}

export default DossiersStatesChart;
