import React from "react";
import { Chip, Stack, Tooltip } from "@mui/material";
import EAIcon from "../EAIcon";
import useStyles from "../../../style/js-style/components/common/charts/EAChartFilterChipsStyle";

const chartOrder = {
  map: 1,
  treeMap: 2,
  bar: 3,
  pie: 4,
};

const EAChartFiltersChips = ({ filters, deleteHandler, withBackground=true }) => {
  const { classes } = useStyles();
  return (
    <Stack direction="row" flexWrap="wrap" spacing={1} className={withBackground ? classes.container : classes.containerWithoutBg}>
      {Object.keys(filters)
        ?.sort((a, b) => (chartOrder[a] > chartOrder[b] ? 1 : -1))
        ?.map((type) => {
          return filters[type]?.map((filter) => (
            <Tooltip
              title={`${filter.keyLabel}: ${filter.label}`}
              key={`${filter.id}-${filter.label}`}
              arrow
              disableInteractive
              disableFocusListener={filter.keyLabel.length < 16 && filter.label.length < 16}
              disableHoverListener={filter.keyLabel.length < 16 && filter.label.length < 16}
              >
              <Chip
                key={`${filter.id}-${filter.label}`}
                className={classes.chip}
                label={
                  <div className={classes.chipText}>
                    <div className={classes.chipKeyLabel}>{filter.keyLabel}</div>
                    <div className={classes.chipKeyValue}> {`: ${filter.label}`}</div>
                  </div>
                }
                deleteIcon={
                  <div>
                    <EAIcon icon="cross" className={classes.deleteIcon} />
                  </div>
                }
                onClick={() => deleteHandler(type, filter)}
                onDelete={() => deleteHandler(type, filter)}
              />
            </Tooltip>
          ));
        })}
    </Stack>
  );
};

export default EAChartFiltersChips;
