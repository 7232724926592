import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  tableCell: {
    borderTop: "1px solid " + theme.palette.grey.grey300,
    borderBottom: "1px solid " + theme.palette.grey.grey300,
    fontSize: "12px",
    fontWeight: 400,
    color: "#1A1919",
    padding: "11px 14px !important",
  },
  tableCellDescribe: {
    borderTop: "1px solid " + theme.palette.grey.grey300,
    borderBottom: "1px solid " + theme.palette.grey.grey300,
    fontSize: "12px",
    fontWeight: 700,
    color: theme.palette.grey.grey500,
    padding: "11px 14px !important",
  },
  displayedItem : {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
    width: "fit-content"
  },
  tableRow: {
    background: "linear-gradient(0deg, rgba(245, 245, 245, 0.3), rgba(245, 245, 245, 0.3)), #FFFFFF",

    "& .MuiTableCell-root": {
      padding: "11px 14px !important"
    },
  },
  centered: {
    textAlign: "center"
  },
  noDataContent: {
    color: theme.palette.grey.grey500,
    fontWeight: 700,
    fontSize: "12px",
  },
  header: {
    fontWeight: 400,
    fontSize: "14px",
    color: theme.palette.grey.grey400,
    borderTop: "1px solid " + theme.palette.grey.grey300,
    borderBottom: "1px solid " + theme.palette.grey.grey300,
    padding: "9px 14px !important",
    width: "100%",
  },
  root: {
    "&:nth-of-type(odd)": {
      background:
        "linear-gradient(0deg, rgba(245, 245, 245, 0.3), rgba(245, 245, 245, 0.3)), #FFFFFF",
    },
  },
}));

export default useStyles;
