import React, { useCallback, useContext, useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import i18next from "i18next";
import { formatNumber } from "../../../services/common/Utils";
import EAPieChart from "../../../components/common/charts/EAPieChart";
import { hidePieSliceLabel, mapDepartmentsFilters } from "../../../services/common/ChartUtils";
import { Context } from "../../../states/Store";
import { getAccountThirdpartyStates } from "../../../services/edge/AccountService";
import ROUTES from "../../../config/Routes";
import { ThirdPartyBlocSkeleton } from "components/common/skeleton/SkeletonDashboard";
import { EANoData } from "components/common/EANoData";
import { localizeTranslate } from "i18n/LocalizeUtils";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { useRef } from "react";
import { ERROR } from "services/common/Constants";
import { isAbortError } from "services/common/ApiUtils";

const states = {
  subscribed: {
    color: "#60D273",
    order: 1
  },
  invited: {
    color: "#377DFF",
    order: 2
  },
  invitation_expired: {
    color: "#FF3F40",
    order: 3
  },
  without_contact: {
    color: "#C4C4C4",
    order: 4
  },
};

function TpStatesChart() {
  const [state, dispatch] = useContext(Context);
  const [thirdpartiesStatesCount, setThirdpartiesStatesCount] = React.useState();
  const [mappedStates, setMappedStates] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasTPs, setHasTPs] = React.useState(false);
  const accountId = useSelectedAccountId();

  const abortCtrlRef = useRef();

  // Reset values on account change
  useEffect(() => {
    setThirdpartiesStatesCount(undefined);
    setMappedStates(undefined);
  }, [accountId])

  const fetchTpStates = useCallback(
    (query) => {

      if(abortCtrlRef.current) {
        abortCtrlRef.current.abort();
      }
  
      abortCtrlRef.current = new AbortController();
      const signal = abortCtrlRef.current.signal;

      if (accountId && state.account) {
        (async () => {
          try {
            setIsLoading(true);
            const res = await getAccountThirdpartyStates(
              accountId,
              query,
              null,
              signal
            );

            const total = res.content
              ?.map((d) => d.count)
              .reduce((partialSum, a) => partialSum + a, 0);

            setIsLoading(false)
            setHasTPs(total > 0)

            setThirdpartiesStatesCount(res.content)
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
            } 
          }
        })();
      }
    },
    [accountId, dispatch, state.account]
  );

  // Map counts to match pie data structure
  useEffect(() => {
    if(thirdpartiesStatesCount) {
      const total = thirdpartiesStatesCount
      ?.map((d) => d.count)
      .reduce((partialSum, a) => partialSum + a, 0);
  
      setMappedStates({
        data: thirdpartiesStatesCount?.map((state) => ({
          id: state.registrationStatus,
          itemLabel: localizeTranslate( i18next.t( `dashboard.thirdparties.indicateur.${state.registrationStatus}`)),
          type: "thirdparties",
          value: state.count,
          formattedValue: formatNumber(state.count),
          settings: {
            fill: am5.color(states[state.registrationStatus].color),
          },
          labelSettings: {
            forceHidden: hidePieSliceLabel(state.count, total),
          },
        }))
        .sort((a, b) => (states[a.id].order > states[b.id].order ? 1 : -1)),
        total: formatNumber(total)
      });
    }
  }, [thirdpartiesStatesCount])

  // Build filters query from graph filters
  useEffect(() => {
    let queryObj = {};
    if (state.graphFilters?.map?.length > 0) {
      const isCountry =
        state.graphFilters.map[0].keyLabel ===
        i18next.t("dashboard.filters.country");

      if (isCountry) {
        queryObj.countries = state.graphFilters.map.map((x) => x.id);
      } else {
        queryObj.departments = mapDepartmentsFilters(
          state.graphFilters.map
        ).map((x) => x.id);
      }
    }
    if (state.graphFilters?.treeMap?.length > 0) {
      queryObj.majorCodes = state.graphFilters.treeMap.map((x) => x.id);
    }
    fetchTpStates(queryObj);

    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [fetchTpStates, state.graphFilters?.map, state.graphFilters?.treeMap]);

  return (
    !isLoading ? (
      <>
        {hasTPs ?
          <EAPieChart
            id="thirdpartiesStates"
            data={mappedStates}
            tab={ROUTES.THIRDPARTIES}
          /> :
          <EANoData
            style={{ width:'100%', height: 'calc(100% - 30px)'}}
            label={i18next.t("dashboard.thirdparties.nodata")}
            icon="noThirdparties"
          />
        }
      </>
    ) : <ThirdPartyBlocSkeleton />
  )
}

export default TpStatesChart
