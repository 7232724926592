import React, { useCallback, useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { FormGroup, FormHelperText, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import EAButton from "components/common/EAButton";
import useStyles from "./DossierModelDialogStyle";
import { ERROR, REGEXP_DOSSIER_MODEL_CODE } from "services/common/Constants";
import { checkCodeAndLabel } from "services/edge/DossierModelService";
import { isEmpty, find } from "lodash";
import { Context } from "states/Store";
import clsx from 'clsx';

const DossierModelDialogConfigStep = ({
  isCreationMode,
  onContinue,
  onCancel
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isContinueClicked, setIsContinueClicked] = useState(false);

  const {
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    formState,
    trigger
  } = useFormContext();

  const verifyCodeAndLabel = useCallback(async () => {
    try {
      const results = await checkCodeAndLabel(
        state.account.id,
        getValues("id"),
        getValues("code"),
        getValues("libelle"),
        null
      );
      if (isCreationMode && results?.content?.code) {
        setError('code', { type: 'manual', message: i18next.t("demandes.dossierModel.createDialog.codeExistsError") });
      }
      if (results?.content?.label) {
        setError('libelle', { type: 'manual', message: i18next.t("demandes.dossierModel.editDialog.labelExistsError") });
      }
      return results;
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: "INTERNAL_ERROR" },
      });
    }
  }, [state.account, isCreationMode, dispatch, getValues, setError])


  const natureOptions = [{
    value: "true",
    label: i18next.t("demandes.dossierModel.lock.options.locked")
  }, {
    value: "false",
    label: i18next.t("demandes.dossierModel.lock.options.unlocked")
  }];

  // We continue to next step if the validation is done and no errors are found.
  useEffect(() => {
    const validate = async () => {
      if (
        isContinueClicked &&
        !formState.isValidating
      ) {
        const result = await verifyCodeAndLabel();
        if (result && isEmpty(result.content)) {
          onContinue();
        }
      }
      setIsContinueClicked(false);
    }
    if (isContinueClicked) {
      validate();
    }
  }, [isContinueClicked, verifyCodeAndLabel, formState, onContinue]);

  return (
    <>
      <div className={classes.inputWrapper}>
        {/* Name label + input */}
        <div className={classes.inputItemWrapper}>
          <FormLabel className={classes.formLabel}>
            {`${i18next.t("demandes.dossierModel.editDialog.label")} * `}
          </FormLabel>
          <FormGroup>
            <Controller
              control={control}
              name="libelle"
              rules={{
                required: i18next.t("infos.required"),
                validate: (value) => {
                  const isNotValid = value.length > 100;
                  if (isNotValid) {
                    const typeErrMsg = i18next.t("demandes.dossierModel.editDialog.labelLengthError");
                    setError('libelle', { type: 'manual', message: typeErrMsg })
                    return typeErrMsg
                  } else {
                    clearErrors('libelle');
                  }
                },
              }}
              render={({ field }) => (
                <TextField
                  id="libelle"
                  className={clsx(classes.textField, errors.libelle && classes.textFieldError)}
                  placeholder={i18next.t("demandes.dossierModel.createDialog.placeholder.modelLabel")}
                  variant="outlined"
                  error={!!errors.libelle}
                  fullWidth
                  helperText={!!errors.libelle ? errors.libelle.message : ""}
                  {...field}
                />
              )}
            />
          </FormGroup>
        </div>

        {/* Code input */}
        <div style={{ maxWidth: "197px", position: 'relative' }}>
          <FormLabel className={classes.formLabel}>
            {isCreationMode ? `${i18next.t("demandes.dossierModel.editDialog.code")} * ` : `${i18next.t("demandes.dossierModel.editDialog.code")}`}
          </FormLabel>
          <FormGroup>
            <Controller
              control={control}
              name="code"
              rules={{
                required: i18next.t("infos.required"),
                validate: (value) => {
                  // Code is disabled on edit mode so we bypass its validation.
                  if(!isCreationMode) return null;

                  const isNotValid = !value.match(REGEXP_DOSSIER_MODEL_CODE);
                  if (isNotValid) {
                    const typeErrMsg = i18next.t("demandes.dossierModel.createDialog.codeFormatError");
                    setError('code', { type: 'manual', message: typeErrMsg })
                    return typeErrMsg;
                  } else if (value.length > 100) {
                    const typeErrMsg = i18next.t("demandes.dossierModel.createDialog.codeLengthError");
                    setError('code', { type: 'manual', message: typeErrMsg })
                    return typeErrMsg;
                  } else {
                    clearErrors('code');
                  }
                },
              }}
              render={({ field }) => (
                <>
                  <TextField
                    id="code"
                    variant="outlined"
                    placeholder={i18next.t("demandes.dossierModel.createDialog.placeholder.genericLabel")}
                    className={clsx(!isCreationMode ? classes.textFieldDisable : classes.textField, errors.code && classes.textFieldError)}
                    error={isCreationMode && !!errors.code}
                    fullWidth
                    sx={!isCreationMode ? { background: "#f8f9fb", borderRadius: "12px" } : {}}
                    helperText={isCreationMode && !!errors.code ? errors.code.message : ""}
                    {...field}
                  />
                  {!isCreationMode && <div className={classes.inputOverlay} />}
                </>
              )}
            />
          </FormGroup>
        </div>

        {/* Nature label + input */}
        <div className={classes.inputItemWrapper}>
          <FormLabel className={classes.formLabel}>
            {`${i18next.t("demandes.dossierModel.editDialog.nature")} * `}
          </FormLabel>
          <FormGroup style={{ minWidth: "150px" }}>
            <Controller
              control={control}
              name="lock"
              rules={{
                required: i18next.t("infos.required"),
              }}
              render={({ field }) => (
                <Select
                  id="lock"
                  variant="outlined"
                  className={classes.select}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <span className={classes.placeHolder}>
                          {i18next.t("demandes.dossierModel.createDialog.placeholder.genericLabel")}
                        </span>
                      )
                    } else {
                      return (
                        <span>
                          {find(natureOptions, { value: selected }).label}
                        </span>
                      )
                    }
                  }}
                  error={!!errors.lock}
                  {...field}
                >
                  {isCreationMode && (
                    <MenuItem disabled value="">
                      <span className={classes.placeHolder}>
                        {i18next.t("demandes.dossierModel.createDialog.placeholder.genericLabel")}
                      </span>
                    </MenuItem>
                  )}
                  {natureOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {
              !!errors.lock && (
                <FormHelperText className="Mui-error">
                  {errors.lock.message}
                </FormHelperText>
              )
            }
          </FormGroup>
        </div>
      </div>

      {/* Buttons */}
      <div className={classes.btnWrapper}>
        <EAButton
          onClick={() => { onCancel(); }}
          customStyle={{ width: "97px" }}
          content={i18next.t("cancel")}
          colorVariant="secondary"
        />

        <EAButton
          onClick={(e) => {
            e.preventDefault();
            trigger().then(canContinue => { canContinue && setIsContinueClicked(true); });
          }}
          customStyle={{ width: "160px" }}
          disabled={!isEmpty(formState.errors)}
          content={i18next.t("continue")}
          colorVariant={"primary"}
        />
      </div>
    </>
  );
};

export default DossierModelDialogConfigStep;
