import React from "react";
import { Paper } from "@mui/material";
import i18next from "i18next";
import useStyles from "../../../style/js-style/containers/dossiers/dossiers-sheet/DossierSheetCompletenessStyle";
import { formatDate } from "../../../services/common/Utils";
import EAIcon from "../../../components/common/EAIcon";
import clsx from "clsx";

function DossierArchivedCard({ dossier = false }) {
  const { classes } = useStyles();

  return (
    <Paper elevation={0} className={clsx(classes.rootArchived, classes.root)}>
      <EAIcon
        icon={"dossier-archived"}
        className={classes.documentIconArchived}
      />
      <p className={classes.archivedText}>{i18next.t("folderArchived")}</p>
      <div className={classes.archivedInfo}>
        <EAIcon icon={"stop"} className={classes.archiveStopedAtIcon} />
        <span className={classes.archiveStopedAt}>
          {i18next.t("dashboard.thirdparties.stopAt")}
          <span className="dt">
            {dossier.stoppingDate && formatDate(dossier.stoppingDate)}
          </span>
        </span>
      </div>
      <div className={classes.commentArchivedWrapper}>
        <p className={classes.commentArchived}>
          <span className={classes.commentArchivedBold}>
            {i18next.t("dashboard.thirdparties.comment")}
          </span>
          :{" "}
          {dossier.deactivationReason ? (
            <span className="dt">{dossier.deactivationReason}</span>
          ) : (
            i18next.t("dashboard.thirdparties.notComment")
          )}
        </p>
      </div>
    </Paper>
  );
}

export default DossierArchivedCard;
