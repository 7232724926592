import {
  Grid,
  Paper,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "style/js-style/containers/dashboard/TabStyle";

import TransparencyBarChart from "./TransparencyBarChart";
import * as am5 from "@amcharts/amcharts5";
import { getTransparencyScoreCount } from "services/edge/ThirdpartyService";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { ERROR } from "services/common/Constants";
import { Context } from "states/Store";
import { localizeTranslate } from "i18n/LocalizeUtils";
import i18next from "i18next";
import { EANoData } from "components/common/EANoData";
import { TransparencyBlocSkeleton } from '../../../../components/common/skeleton/SkeletonDashboard';
import { useRef } from "react";
import { isAbortError } from "services/common/ApiUtils";

function TabTransparency() {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [mappedScoreCount, setMappedScoreCount] = useState({});
  const [year, setYear] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [noThirdparties, setNoThirdparties] = useState(true);
  const accountId = useSelectedAccountId();

  const abortCtrlRef = useRef();

  const fetchScoreCount = useCallback(
    () => {

      if(abortCtrlRef.current) {
        abortCtrlRef.current.abort();
      }
  
      abortCtrlRef.current = new AbortController();
      const signal = abortCtrlRef.current.signal;

      if (accountId && state.account) {
        (async () => {
          try {
            const res = await getTransparencyScoreCount(
              accountId,
              null,
              signal
            );
            let mappedScoresCounts = [];
            setYear(res?.content?.year);
            for(const transparencyScore of res?.content?.results) {
              if (transparencyScore.count > 0) {
                setNoThirdparties(false);
              }
              if (transparencyScore.interval === "Non Publié") {
                mappedScoresCounts.push({
                  code: transparencyScore.code,
                  interval: localizeTranslate(transparencyScore.interval),
                  value: transparencyScore.count,
                  settings: {
                    fill: am5.color(transparencyScore.color),
                  },
                })
              } else {
                mappedScoresCounts.push({
                  code: transparencyScore.code,
                  interval: transparencyScore.interval,
                  value: transparencyScore.count,
                  settings: {
                    fill: am5.color(transparencyScore.color),
                  },
                })
              }
            }
            setMappedScoreCount(mappedScoresCounts);
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
            } 
          }finally {
            setIsLoading(false);
          }
        })();
      }
    },
    [accountId, dispatch, state.account]
  );

  useEffect(() => {
    fetchScoreCount();

    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [fetchScoreCount]);

  return (
    <Paper elevation={0} className={classes.paperContainer}>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.graphPaper}>
            { isLoading
              ? (
                <div style={{height: "530px"}}>
                  <TransparencyBlocSkeleton />
                </div>
              )
              : noThirdparties ?
                <EANoData
                  style={{ width: '100%', height: '100%', minHeight: '400px', marginBottom: '30px', background: 'white' }}
                  label={i18next.t("dashboard.thirdparties.nodata")}
                  icon="noThirdparties"
                />
              : <TransparencyBarChart transparencyScoreCount={mappedScoreCount} year={year}></TransparencyBarChart>
            }
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TabTransparency;
