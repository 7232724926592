import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { getAllActiveTypeDoc } from "services/edge/DossierModelService";
import { Context } from "states/Store";
import { localizeTranslate } from "i18n/LocalizeUtils";
import DossierChoiceDocuments from "containers/dossiers/create-dossier/DossierChoiceDocuments";
import EAButton from "components/common/EAButton";
import EAIcon from "components/common/EAIcon";
import useStyles from "./DossierModelDialogStyle";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";

const DossierModelDialogDocStep = ({
  isCreationMode,
  dossierModel = {},
  onCancel,
  onDelete,
  onContinue,
  setData = () => {}
}) => {
  const [state, dispatch] = useContext(Context);
  const [allDocuments, setAllDocuments] = useState();
  const [documentsSelected, setDocumentsSelected] = useState(dossierModel?.askedDocs?.map((d) => d.code) || []);
  const [sortedDocuments, setSortedDocuments] = useState();
  const { classes } = useStyles();
  const formMethods = useFormContext();

  const {
    formState: { errors },
    handleSubmit,
    getValues,
    setValue
  } = formMethods;

  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          const results = await getAllActiveTypeDoc(state.account.id, dispatch);
          setAllDocuments(
            results.content.map((x) => {
              return {
                id: x.code,
                descriptionFr: localizeTranslate(x.name),
                code: x.code,
                category: x.category,
                selected: false
              };
            })
          );
        }
        catch {
          // TODO something
        }
      })();
    }
  }, [state.account, dispatch]);

  useEffect(() => {
    setSortedDocuments(
      allDocuments?.map(d => (
        {
          ...d,
          selected: documentsSelected?.includes(d.code)
        }
      )).sort((a, b) => (a.selected ? -1 : 1))
    );
  }, [documentsSelected, allDocuments]);

  const submitForm = (data) => {
    data = {
      ...data,
      docCode: documentsSelected,
      docToAdd: documentsSelected?.map(c => allDocuments.find(d => d.code === c)),
      active: getValues('active')
    };
    if (isEmpty(errors)) {
      setData(data);
      onContinue(data);
    }
  };

  const handleFinalizeSave = () => {
    handleSubmit(submitForm)();
  };

  return (
    <>
      {sortedDocuments ? (
        <DossierChoiceDocuments
          documents={sortedDocuments}
          selection={{
            items: documentsSelected,
            setItems: setDocumentsSelected,
          }}
          context="dossier-model"
        />
      ) : (
        <>
          <EAIcon icon={"loop"} />
          <span style={{ fontWeight: "bold", textAlign: "center" }}>{i18next.t("loading")}</span>
        </>
      )}

      {/* --- Actions --- */}
      {/* Supprimer le modele */}
      <div className={classes.declarationControls}>
      {/* For now, we can't delete a model */}
{/*         {!isCreationMode &&
          <EAButton
            borderless={true}
            colorVariant="error"
            onClick={(e) => {
              e.preventDefault();
              onDelete([dossierModel?.id]);
            }}
            content={
              <div>
                <EAIcon
                  icon={"delete_red"}
                  className={classes.deleteModelImg}
                />
                {i18next.t("demandes.dossierModel.editDialog.deleteAction")}
              </div>
            }
            customStyle={{ fontSize: "14px", position: "absolute", bottom: "28px" }}
          />
        } */}

        {/* Annuler / Enregistrer / Activer */}
        <div className={classes.declarationControlsRight}>
          <EAButton
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
            content={i18next.t("cancel")}
            customStyle={{ width: "80px" }}
            colorVariant={"secondary"}
          />

          <EAButton
            onClick={(e) => {
              e.preventDefault();
              handleFinalizeSave();
            }}
            content={i18next.t("save")}
            colorVariant={"primaryLight"}
            customStyle={{ width: "117px" }}
          />

          {!dossierModel?.active ? (
            <EAButton
              onClick={(e) => {
                e.preventDefault();
                setValue(`active`, true);
                handleFinalizeSave();
              }}
              content={i18next.t("activate")}
              customStyle={{ width: "117px" }}
            />
          ) : (
            <EAButton
              onClick={(e) => {
                e.preventDefault();
                setValue(`active`, false);
                handleFinalizeSave();
              }}
              content={i18next.t("deactivate")}
              colorVariant={"success"}
              customStyle={{ width: "117px" }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DossierModelDialogDocStep;
