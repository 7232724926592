import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: "10px",
    filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.07))",
  },
  headerWrapper: {
    backgroundColor: "white",
    height: "54px",
    borderRadius: "4px",
    padding: "0 17px",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "calc(100% + 16px)",
  },
  subItemsWrapper: {
    backgroundColor: "white",
    paddingBottom: "21px",
    width: "calc(100% + 16px)",
  },
  arrowIcon: {
    position: "absolute",
    top: "23px",
    right: "23px",
  },
  arrowIconReverse: {
    position: "absolute",
    top: "23px",
    right: "23px",
    transform: "rotate(180deg)",
  },
  // title
  titleWrapper: {
    flex: 1,
    marginRight: "32px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  titleMain: {
    fontFamily: "'Metric'",
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "uppercase",
    color: "#16161E",
  },
  titleAllPart: {
    fontFamily: "'Metric'",
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "lowercase",
    color: "#373534"
  },
  titleSubTitle: {
    fontFamily: "'Metric'",
    fontWeight: 400,
    fontSize: "14px",
    textTransform: "lowercase",
    color: "#9A9AAF"
  },
  // form
  formWrapper: {
    width: "calc(100% - 36px)",
    margin: "15px 18px 42px 18px",
    padding: "16px 32px 26px 32px",
    minHeight: "171px",
    left: "17px",
    top: "70px",
    background: "#F2F3F780",
  },
  formTitle: {
    fontFamily: "'Metric'",
    fontWeight: 600,
    fontSize: "16px",
    color: "#373534",
    marginBottom: "19px"
  },
  formTitleDeco: {
    position: "absolute",
    height: "48px",
    left: "18px",
    top: "71px",
    border: "2px solid #2D9F40"
  },
  formLine: {
    flex: 1,
    minHeight: "87px",
  },
  formAddLineButton: {
    fontFamily: "'Metric'",
    fontWeight: 600,
    fontSize: "12px",
    color: "#2D9F40"
  },
  addButtonContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    left: "3px",
  },
}));

export default useStyles;
