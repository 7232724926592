import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  subTitle:{
    fontWeight: 400,
    fontSize: "12px",
    color: theme.palette.grey.grey500,
  },
  mainContent: {
    marginTop: "13px",
    padding: "2px 31px 11px 31px",
    backgroundColor: theme.palette.grey.grey100,
  },
  modifyBloc: {
    marginTop: "22px",
  },
  modifyBlocLast: {
    marginBottom: 0
  },
}));

export default useStyles;
