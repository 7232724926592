import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
   //------View without data -------
    rootNoData: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F7F8FC",
        color: "#9A9AAF",
        height: "224px",
        boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.03)",
        fontWeight: 400,
        fontSize: "14px",
      },
      noDataText: {
        fontFamily: "'Metric'",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        color: "#9A9AAF",
        marginTop: "5px",
        marginBottom: "20px"
      },
      buttonContent : {
        display: 'flex', 
        gap: "5px",
        fontSize: "12px",
        alignItems: "center",
        color: theme.palette.primary.main
      },

      //------View  skeleton -------
      skeleton: {
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "15px",
        alignItems: "center",
        padding: "20px"
      },
      skeletonContent: {
        height: "80%",
        width: "100%"
      },
      //------View with Data -------
      financialStrengthContent: {
        paddingTop: "16px",
        position: "relative",
        display: "flex", 
        gap: "16px"

      }, 
      buttonDesactivation: {
        position: "absolute", 
        padding: "6px 12px !important",
        right: "8px", 
        top: "8px",
        boxShadow: "none"
      },
      buttonStopContent: {
        display: 'flex', 
        gap: "5px",
        fontSize: "12px",
        alignItems: "center",
        color: theme.palette.error.error600
      },

      riskSegmentGraph: {
        borderRadius: "10px",
        background: theme.palette.background.white,
        boxShadow: "0px 6px 16px 0px rgba(21, 12, 115, 0.06)",
        width: "304px",
        height: "168px", 
        overflow: 'hidden',
        flexShrink: 0, 
        "& > div" : {
          width: "304px",
          height: "158px", 
          borderRadius: "10px",
          fontSize: "26px", 
          fontWeight: 600,
        }
      },
      scoreLabel : {
        display: "flex", 
        fontWeight: 500, 
        fontSize: "14px", 
        alignItems: "center", 
        padding: "10px",
        gap: "10px",
        color: theme.palette.black.dark

      },
      buttonHistorique: {
        color: theme.palette.grey.grey500, 
        backgroundColor: "#FFF",
        "&:hover, &:focus" : {
          backgroundColor : theme.palette.grey.grey100 + " !important"
        }
      },
      veryHighBakground: {
        background: "linear-gradient(180deg, #FFEBEB -9.6%, rgba(255, 235, 235, 0.00) 55.1%), #FFF",
        color: theme.palette.error.error800
      },
      highBakground : {
        background: "linear-gradient(180deg, #FFEBEB -9.6%, rgba(255, 235, 235, 0.00) 55.1%), #FFF",
        color: theme.palette.error.error700
      },
      mediumBackground : {
        background: "linear-gradient(180deg, #FFF7EB -9.6%, rgba(255, 247, 235, 0.00) 55.1%), #FFF",
        color: theme.palette.warning.warning500
      },
      lowBackground : {
        background: "linear-gradient(180deg, rgba(96, 210, 115, 0.50) -9.6%, rgba(96, 210, 115, 0.00) 55.1%), #FFF",
        color: theme.palette.primary.primary600
      }, 
      veryLowBackground : {
        background: "linear-gradient(180deg, #D7F4DC -9.6%, rgba(215, 244, 220, 0.00) 55.1%), #FFF",
        color: theme.palette.primary.primary400
      }, 
      unknownBackground: {
        background: "linear-gradient(180deg, #E2E2EA -9.6%, rgba(226, 226, 234, 0.00) 55.1%), #FFF",
        color: theme.palette.grey.grey700
      },
      iconeRiskEvolution : {
        borderRadius: "200px",
        boxShadow: "0px 18px 40px 0px rgba(112, 144, 176, 0.12)",
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        padding: "16px", 
        width: "26px",
        height: "26px"
      },

      flexCenter: {
        display: "flex", 
        alignItems: "center", 
        marginLeft: "10px"
      },
      columnsParentGraph : {
        display: "flex", 
        overflow:'hidden', 
        position:'relative', 
        alignItems: "flex-end"
      },
      columnGraph : {
        borderRadius: "7px 7px var(--none, 0px) var(--none, 0px)",
        width: "100%",
        height: "85px",         
        margin: "1px", 
      },
      columnEmpty : {
        position: "relative",
        "&:after" : {
          borderRadius: "7px 7px var(--none, 0px) var(--none, 0px)",
          content:"' '", 
          position: 'absolute',
          width: "100%", 
          height: "100%",
          background: "repeating-linear-gradient(337deg, #bc606000, #606dbc00 3px, #46579800 0px, #ababab0d 5px)"
        }
      },

      graphEvolutionContent: {
        backgroundColor: theme.palette.grey.grey100, 
        minWidth: "685px",
        minHeight: "348px", 
        display: "flex", 
        justifyContent: "center",
        padding: "20px 40px"
      },


      scoreInformations: {
        position: "relative",
        display: "grid", 
        gridTemplateColumns: "0.6fr 1.4fr",
        maxWidth: "500px",
        columnGap: "38px",
        borderRadius: "10px",
        background: "linear-gradient(90deg, rgb(71 233 255 / 9%) 0%, rgb(228 213 237 / 14%) 100%)",
        padding: "20px 20px 15px 20px", 
      }, 
      standardRatingValue: {
        paddingLeft: "11px",
        position: "relative",
        justifySelf: "center"
      },
      leftBarRisk : {
        width: "4px", 
        height: "95%",
        borderRadius: "4px", 
        marginRight: "5px",
        position: "absolute",
        left: "0px",
      },
      subLabelRisk: {
        marginLeft: "2px",
        fontSize: "10px", 
        fontWeight: 700, 
        color: theme.palette.grey.main, 
        lineHeight: "42px", 
      },
      riskSegmentInfos: {
        fontWeight: 700,
        lineHeight: "42px", 
        alignSelf: "center"
      },
      informationDescriptionTitle : {
        fontSize: "12px", 
        fontWeight: "600", 
        color: theme.palette.grey.main, 
        lineHeight: "16px", 
        display: "flex", 
        alignItems: "center"
      }, 
      informationDescriptionContent : {
        fontSize: "10px", 
        fontWeight: "400", 
        color: theme.palette.black.dark, 
      },
      iconeDescription : {
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        width: "19px",
        border: "0.75px solid", 
        borderRadius: "4px",
        borderColor: theme.palette.grey.grey200, 
        padding: "2px 6px",
        backgroundColor: "white", 
        marginRight: "5px"
      }
}));

export default useStyles;