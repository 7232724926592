import { makeStyles } from 'tss-react/mui';
import {darkGrey, primary} from "../../../../globalStyle";

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: "22px",
  },
  card: {
    padding: "1.5rem",
    marginBottom: "1.5rem",
  },
  dossierCreationForm: {
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  dateInput: {
    margin: 0,
  },
  saveBtn: {
    textTransform: "none",
    float: "right",
    marginTop: "1.5rem",

    "&:disabled": {
      opacity: 0.7,
      color: "white",
      background: primary,
    },
  },
  inputLabel: {
    marginBottom: "0.5rem",
    fontSize: "0.75rem",
    color: darkGrey,
  },
  errorMsg: {
    textAlign: "right",
    color: "#bf0000",
    fontSize: "16px",
    marginRight: "6px",

    "& .iconErrorMsg": {
      position: "relative",
      top: "6px",
    },

    "& .txtErrorMsg": {
      marginLeft: "3px",
    },
  },
  select: {
    "& .MuiSelect-root": {
      paddingTop: "9px",
      paddingBottom: "9px",
    },
  },
}));

export default useStyles;
