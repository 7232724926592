import { eaFetch } from "../common/ApiUtils";

// https://geo.api.gouv.fr/decoupage-administratif/departements for more info
export async function getAllFrenchDepartments(dispatch) {
  return eaFetch(
    dispatch,
    "",
    "GET",
    `/assets/departements.json`,
    null,
    null,
    false
  );
}