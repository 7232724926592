import { useState } from 'react';

/**
 * Hook pour la gestion de l'état des boutons submit
 */
export const useRequestLoading = ()  => {
  const [isRequestLoading, setRequestLoading] = useState(false);

  const startLoading = () => {
    setRequestLoading(true);
  };

  const stopLoading = () => {
    setRequestLoading(false);
  };

  return { isRequestLoading, startLoading, stopLoading };
}
