import { Box, Grid, Tab, Tabs, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Context } from "../../../states/Store";
import { ERROR } from "../../../services/common/Constants";
import i18next from "i18next";
import {
  getThirdparty,
  getThirdpartyDocuments, getThirdPartyIndicators,
  getThirdPartyScores,
  getTPContacts,
} from "../../../services/edge/ThirdpartyService";
import {
  getAllNoteTP,
  createNoteTP,
  updateNoteTP,
  deleteNoteTP
} from "../../../services/edge/NoteService";
import ThirdpartyInfos from "./ThirdpartyInfos";
import ContactsDataTable from "../../tp-contacts/contacts-list/ContactsDataTable";
import {
  balanceSheetColumns,
  createDossierFromTp,
  principalsColumns,
} from "./ThirdpartySheetUtils";
import EADataGrid from "../../../components/common/datatable/EADataGrid";
import {
  formatDate,
  formatNumber,
  formatNumberCurrency,
} from "../../../services/common/Utils";
import useStyles from "../../../style/js-style/containers/thirdparties/sheet/TPSheetStyle.js";
import EATabPanel from "../../../components/common/EATabPanel";
import ThirdDocumentsInfo from "./ThirdDocumentsInfo";
import ThirdpartyStopDialog from "./ThirdpartyStopDialog";
import { getDossiersByCriteria } from "../../../services/edge/DossierService";
import AttributeValuesDataTable from "../../attribute-values/AttributeValuesDataTable";
import AddingButton from "../../../components/thirdparties/AddingButton";
import EAAccordion from "../../../components/EAAccordion";
import DossiersDataTable from "../../../components/dossiers/DossiersDataTable";
import DocumentsDataTable from "../../../components/documents/DocumentsDataTable";
import UserCreationDialog from "../../users/users-list/UserCreationDialog";
import EAIcon from "../../../components/common/EAIcon";
import EAButton from "../../../components/common/EAButton";
import clsx from "clsx";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "../../../services/common/RolesUtils";
import ThirdpartyRisks from "./ThirdpartyRisks";
import { theme } from "../../../style/globalStyle";
import { isEmpty } from "lodash";
import NotesList from "containers/notes/NotesList";
import { getAuditTrail } from "services/edge/EventsService";
import { canAnswerDocument, isUpdatableDocument } from "services/common/DocumentUtils";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { InitialDocumentsTableSort } from "components/documents/DocumentsDataTableUtils";

const fetchThirdpartyDossiers = async (monitored, account, thirdparty) => {
  if (account && thirdparty) {
    try {
      let searchCriteria = `page=0&pageSize=9999&withAttributes=true&monitored=${monitored}&thirdpartyId=${thirdparty.thirdpartyId}`;
      const results = await getDossiersByCriteria(
        account.id,
        searchCriteria,
        null
      );
      // Order results by Creation Date
      let resultsOrdered = results.content.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      );
      return resultsOrdered;
    } catch (error) {
      return [];
    }
  }
};

function TPSheet() {
  const { classes } = useStyles();
  let { thirdpartyId } = useParams();
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges, hasAnyOfPrivilegesOnScopedResourceImpl } = React.useContext(RoleBasedAccessControlContext);

  const [thirdparty, setThirdparty] = useState();
  const [contacts, setContacts] = React.useState([]);

  const [balanceSheetData, setBalanceSheetData] = React.useState([]);
  const [isStopDialogOpen, setIsStopDialogOpen] = useState(false);
  const [principals, setPrincipals] = React.useState([]);
  const [loaderContacts, setLoaderContacts] = React.useState(true);

  const [thirdpartyDocuments, setThirdpartyDocuments] = useState([]);
  const [answerCount, setAnswerCount] = useState(0);
  const [tpVisibleDocsCount, setTpVisibleDocsCount] = useState(null);
  const [tpVisibleDossCount, setTpVisibleDossCount] = useState(null);
  const [tpVisibleContactsCount, setTpVisibleContactsCount] = useState(null);
  const [tpVisibleArchiveDossCount, setTpVisibleArchiveDossCount] = useState(null);
  const [thirdpartyActiveDossiers, setThirdpartyActiveDossiers] = useState();
  const [thirdpartyArchives, setThirdpartyArchives] = useState();
  const [isCreateContactDialogOpen, setIsCreateContactDialogOpen] = useState(false);
  const [thirdpartyRisks, setThirdpartyRisks] = useState();
  const [thirdpartyIndicators, setThirdpartyIndicators] = useState();
  const [tpNotes, setTPNotes] = useState();
  const [sortModel, setSortModel] = useState(InitialDocumentsTableSort);

  const [downloadingAudit, setDownloadingAudit] = useState(false);
  const [value, setValue] = useState(0);
  const [tabChangedByUserAction, seTabChangedByUserAction] = useState(false);

  const hasAnyOfPrivilegesOnScopedResource = useCallback((...privileges) => {
    let result = false;

    if (thirdparty) {
      result = hasAnyOfPrivilegesOnScopedResourceImpl(thirdparty, ...privileges);
    }

    return result;
  }, [thirdparty, hasAnyOfPrivilegesOnScopedResourceImpl]);

  const getInitialTabIndex = useCallback(() => {
    let index = 0;

    if (hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD)) {
      index = 0;
    } else if (hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES) ||
      hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY) ||
      hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY)) {
      index = 1;
    } else if (hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS)) {
      index = 2;
    } else if (hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET)) {
      index = 3;
    } else if (hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS)) {
      index = 4;
    }

    return index;
  }, [hasAnyOfPrivilegesOnScopedResource, hasAnyOfPrivileges]);

  const history = useHistory();
  let location = useLocation();

  const fetchThirdPartyScores = useCallback(() => {
    async function getScorings() {
      try {
        const result = await getThirdPartyScores(
          state.account.id,
          thirdpartyId,
          dispatch
        );
        setThirdpartyRisks(result.content);

      } catch (error) {
        console.error(error);
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "THIRDPARTY_NOT_FOUND" },
        });
      }
    }

    if (state.account) {
      getScorings();
    }
  }, [dispatch, state.account, thirdpartyId]);

  useEffect(() => {
    if(!tabChangedByUserAction) {
      setValue(getInitialTabIndex());
    }
  }, [tabChangedByUserAction, getInitialTabIndex])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab === "risks") {
      setValue(4);
    }
  }, []);

  const fetchThirdpartyInfos = useCallback(() => {
    async function getThirdpartyInfos() {
      try {
        const result = await getThirdparty(
          state.account.id,
          thirdpartyId,
          dispatch
        );
        setThirdparty(result.content);
        dispatch({
          type: "CRUMBS",
          crumbsData: {
            lastCrumbs: [{ label: result.content.name, disableTranslation: true }],
          },
        });

        // Map balance Sheet Data
        setBalanceSheetData([
          {
            id: 1,
            balanceSheetDate: formatDate(result.content.lastBalanceSheetDate),
            turnover: formatNumberCurrency(
              result.content.turnover,
              result.content.turnoverCurrency
            ),
            workforce: formatNumber(result.content.workforce),
          },
        ]);

        // Map principals
        let mappedPrincipals = result.content.principals?.map((p, i) => {
          return {
            id: i,
            ...p,
          };
        });
        setPrincipals(mappedPrincipals);

        // Thirdparty Scorings (indueD, Transparency, Financial Strength, etc.)
        fetchThirdPartyScores()

      } catch (error) {
        console.error(error);
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "THIRDPARTY_NOT_FOUND" },
        });
      }
    }

    async function getIndicators() {
      try {
        const result = await getThirdPartyIndicators(
          state.account.id,
          thirdpartyId,
          dispatch
        );

        setThirdpartyIndicators(result?.content)
      } catch (e) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "THIRDPARTY_NOT_FOUND" },
        });
      }
    }

    if (state.account && state.user) {
      getThirdpartyInfos();
      getIndicators();
    }
  }, [dispatch, state.account, state.user, thirdpartyId, fetchThirdPartyScores]);

  const fetchTPNotes = useCallback(() => {
    async function getTPNotes() {
      try {
        const result = await getAllNoteTP(
          state.account.id,
          thirdpartyId,
          null
        );
        setTPNotes(result.content);
      } catch (error) {
        setTPNotes(null);
      }
    }

    if (state.account && thirdpartyId && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_NOTES)) {
      getTPNotes();
    } else {
      setTPNotes(null);
    }
  }, [thirdpartyId, state.account, hasAnyOfPrivilegesOnScopedResource]);

  useEffect(() => {
    fetchTPNotes()
  }, [fetchTPNotes]);

  const updateNoteTPCallback = useCallback(async (noteOId, noteContent) => {
    await updateNoteTP(state.account.id, thirdpartyId, noteOId, noteContent);

  }, [state.account, thirdpartyId]);

  const createNoteTPCallback = useCallback(async (noteContent) => {
    await createNoteTP(state.account.id, thirdpartyId, noteContent);

  }, [state.account, thirdpartyId]);

  const deleteNoteTPCallback = useCallback(async (noteOId) => {
    await deleteNoteTP(state.account.id, thirdpartyId, noteOId);

  }, [state.account, thirdpartyId]);

  // Fetch Third party informations
  useEffect(() => {
    fetchThirdpartyInfos();
  }, [fetchThirdpartyInfos]);

  const fetchContacts = useCallback(() => {
    if (state.account && thirdparty?.onboarded) {
      setLoaderContacts(true);
      (async function fetchData() {
        try {
          const resp = await getTPContacts(
            state.account.id,
            thirdparty.thirdpartyId,
            dispatch
          );

          // Map thirdparties
          let mappedContacts = resp?.content?.map((user) => {
            return {
              ...user,
              id: user.pcuId,
              name: user.lastName,
              firstname: user.firstName,
              invitationdate: user.invitationDate,
              lastactivitydate: user.lastActivityDate,
              tpname: user.tpName,
              tpid: user.tpId,
              tpaccountid: user.tpAccountId,
            };
          });
          setLoaderContacts(false);
          setContacts(mappedContacts);
        } catch (e) {
          if (!e?.message.messages?.error) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "INTERNAL_ERROR" },
            });
          }
          setLoaderContacts(false);
        }
      })();
    }
  }, [thirdparty, dispatch, state.account]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  // Fetch Onboarded Third party Active Dossiers
  useEffect(() => {
    async function searchThirdpartyDossiers() {
      const dossiersMonitored = await fetchThirdpartyDossiers(
        true,
        state.account,
        thirdparty
      );
      setThirdpartyActiveDossiers(dossiersMonitored);
    }
    if (state.account && thirdparty?.onboarded) {
      searchThirdpartyDossiers();
    }
  }, [state.account, thirdparty]);

  // Fetch Onboarded Third party Archives
  useEffect(() => {
    async function searchThirdpartyDossiers() {
      const dossiersArchived = await fetchThirdpartyDossiers(
        false,
        state.account,
        thirdparty
      );
      setThirdpartyArchives(dossiersArchived);
    }
    if (state.account && thirdparty) {
      searchThirdpartyDossiers();
    }
  }, [state.account, thirdparty]);

  // Fetch Third party Documents
  useEffect(() => {
    async function getDocuments() {
      try {
        const result = await getThirdpartyDocuments(
          state.account.id,
          thirdparty.thirdpartyId,
          null
        );

        let responses = result.content
        // We concat both evidences while excluding evidences that have been stopped, to show other reasons
        .map((doc) => ({
          ...doc,
          evidences: [].concat(
            doc.evidences,
            doc.historicEvidences?.filter(
              (e) => e.statusReason !== "ARRET_DEMANDE"
            )
          ),
        }))
        .map((x) => {
          return {
            documentId: x.documentId,
            reponseId: x.evidences?.[0]?.responseId,
            fileId: x.evidences?.[0]?.id,
            depositorName: x.evidences?.[0]?.metadata?.depositor,
            transmitter: x.evidences?.[0]?.transmitter,
            nameFr: localizeTranslate(x.documentName),
            depositDate: x.evidences?.[0]?.uploadDate,
            endDate: x.evidences?.[0]?.expirationDate,
            present: x.evidences?.length > 0,
            thirdpartyId: thirdparty.thirdpartyId,
            statusCode: x.statusCode,
            authorizingRoles: x.authorizingRoles,
            typeDocumentId: x.typeDocumentId,
            acquisition: x.acquisition,
            dossierId: x.dossierId,
            dossierReference: x.dossierReference,
            dossierTypeLibelle: x.dossierTypeLibelle,
            isAnswerable: canAnswerDocument(x) && thirdparty.onboarded,
            refusedToFile: x.evidences?.[0]?.hasRefusedToFile,
            nbMultiple: x.nbMultiple
          };
        });
        responses = responses.filter(
          (value, index, self) => {
            if (value.acquisition === "DO") {
              return true;
            } else {
              return index === self.findIndex(
                (t) =>
                  t.reponseId === value.reponseId &&
                  t.nameFr === value.nameFr &&
                  t.thirdpartyName === value.thirdpartyName
              );
            }
          }
        );

        setThirdpartyDocuments(responses);
      } catch (error) {
        setThirdpartyDocuments([]);
      }
    }
    if (state.account && thirdparty?.onboarded) {
      getDocuments();
    }
  }, [dispatch, thirdparty, state.account]);

  useEffect(() => {
    setAnswerCount(thirdpartyDocuments?.filter((x) => (x.isAnswerable === true && !isUpdatableDocument(x)))?.length || 0);
  }, [thirdpartyDocuments])

  // Set tab value
  const handleChange = (event, newValue) => {
    setValue(newValue);
    seTabChangedByUserAction(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const downloadAuditTrail = useCallback(() => {
    if (state.account && thirdparty?.onboarded) {

      (async function fetchData() {
        try {

          setDownloadingAudit(true);


          const resp = await getAuditTrail(
            state.account.id,
            thirdparty.thirdpartyId,
            null,
            null,
            dispatch
          );

          // download the file from the returned link
          const link = document.createElement("a");
          link.href = resp.content?.slice(1, -1);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloadingAudit(false);
        } catch (e) {
          if (!e?.message.messages?.error) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "INTERNAL_ERROR" },
            });
          }
          setDownloadingAudit(false);
        }
      })();
    }
  }, [thirdparty, dispatch, state.account]);

  const ref = useRef();

  const mediaXS = useMediaQuery(theme.breakpoints.down("1390"));
  const mediaXXS = useMediaQuery(theme.breakpoints.down("1260"));

  return (
    <>
      {thirdparty && (
        <Grid container spacing={2} className={classes.root}>
          {/*Top Header*/}
          <Grid container item xs={12} className={classes.backToFront}>
            {/*Header info : name, adding date etc. */}
            <Grid item xs={9}>
              <Typography
                variant="subtitle2"
                className={
                  thirdparty.stoppingDate
                    ? classes.archivedFontHeaderTheme
                    : ""
                }
              >
                <EAIcon icon={"valid"} className={classes.topHeaderIcon} />
                <span className={classes.addDate}>
                  {i18next.t("dashboard.thirdparties.addAt")}{" "}
                  {thirdparty.dateCreation &&
                    <span className='dt'>{formatDate(thirdparty.followingDate)}</span>
                  }
                </span>
              </Typography>
              <Typography
                variant="h1"
                className={
                  thirdparty.onboarded ? "" : classes.archivedThemeHeader
                }
              >
                {" "}
                <span className="dt">{thirdparty.name}</span>{" "}
              </Typography>
              {thirdparty.etabActivityLabel && (
                <Typography
                  variant="subtitle2"
                  className={clsx(
                    thirdparty.onboarded
                      ? classes.topHeaderLabelActivity
                      : classes.topHeaderLabelActivityArchived,
                    "dt"
                  )}
                >
                  {thirdparty.etabActivityLabel}
                </Typography>
              )}
            </Grid>

            {/* Archive button */}
            <Grid container item xs={3}>
              <Grid item xs={12}>
                {thirdparty.onboarded && (
                  <Grid container item xs={12} justifyContent="flex-end" gap={2}>
                    <EAButton
                      colorVariant="secondary_dark"
                      disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_ARCHIVE_THIRD_PARTY)}
                      onClick={() => setIsStopDialogOpen(true)}
                      customStyle={{
                        height: "42px",
                        boxShadow:
                          "0px 11.28px 24.231px rgba(154, 154, 175, 0.115017), 0px 6.0308px 12.9551px rgba(154, 154, 175, 0.0953772), 0px 3.38082px 7.2625px rgba(154, 154, 175, 0.08), 0px 1.79553px 3.85706px rgba(154, 154, 175, 0.0646228), 0px 0.747159px 1.60501px rgba(154, 154, 175, 0.0449833)",
                      }}
                      content={
                        <>
                          <EAIcon
                            icon={"archive_grey"}
                            className={classes.archiveButtonIon}
                          />
                          {i18next.t("stopTheOnboarding")}
                        </>
                      }
                    />
                    {/* Audit trail button */}
                    <EAButton
                      colorVariant="secondary_dark"
                      disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_AUDIT_TRAIL) || downloadingAudit}
                      onClick={() => downloadAuditTrail()}
                      customStyle={{
                        height: "42px",
                        boxShadow:
                          "0px 11.28px 24.231px rgba(154, 154, 175, 0.115017), 0px 6.0308px 12.9551px rgba(154, 154, 175, 0.0953772), 0px 3.38082px 7.2625px rgba(154, 154, 175, 0.08), 0px 1.79553px 3.85706px rgba(154, 154, 175, 0.0646228), 0px 0.747159px 1.60501px rgba(154, 154, 175, 0.0449833)",
                        cursor: downloadingAudit ? "wait" : "pointer"
                      }}
                      content={
                        <>
                          <EAIcon
                            icon={"audit-trail-thirdparty_grey"}
                            className={classes.archiveButtonIon}
                          />
                          {i18next.t("downloadAuditTrail")}
                        </>
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>


          </Grid>

          {/* Header Tab */}
          <Grid item xs={9} className={classes.backToFront}>
            {/* Labels */}
            <Box className={classes.tabsWrapper}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="dashboard tabs"
              >
                <Tab
                  label={i18next.t("thirdpartySheet.identity")}
                  {...a11yProps(0)}                  
                  className={
                    classes.tab +
                    " " +
                    (!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD) ? classes.tabDisabled : value === 0 ? classes.selectedTab : classes.unselectedTab)
                  }
                  disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD)}
                  style={{ minWidth: mediaXS ? 0 : "146px", fontSize: mediaXXS ? "14px" : mediaXS ? "17px" : "18px", width: mediaXXS ? "100px" : "" }}
                />
                <Tab
                  label={i18next.t("thirdpartySheet.attributes")}
                  {...a11yProps(1)}
                  className={
                    classes.tab +
                    " " +
                    (!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES) &&
                      !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY) &&
                      !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES) ?
                      classes.tabDisabled : value === 1 ? classes.selectedTab : classes.unselectedTab)
                  }
                  disabled={
                    !hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES) &&
                    !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY) &&
                    !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY)
                  }
                  style={{ minWidth: mediaXS ? 0 : "146px", fontSize: mediaXXS ? "14px" : mediaXS ? "17px" : "18px", width: mediaXXS ? "80px" : "" }}
                />
                <Tab
                  label={i18next.t("thirdpartySheet.directions")}
                  {...a11yProps(2)}
                  className={
                    classes.tab +
                    " " +
                    (!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS) ? classes.tabDisabled : value === 2 ? classes.selectedTab : classes.unselectedTab)
                  }
                  disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS)}
                  style={{ minWidth: mediaXS ? 0 : "146px", fontSize: mediaXXS ? "14px" : mediaXS ? "17px" : "18px", width: mediaXXS ? "129px" : "" }}
                />
                <Tab
                  label={i18next.t("thirdpartySheet.bilan")}
                  {...a11yProps(3)}
                  className={
                    classes.tab +
                    " " +
                    (!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET) ? classes.tabDisabled : value === 3 ? classes.selectedTab : classes.unselectedTab)
                  }
                  disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET)}
                  style={{ minWidth: mediaXS ? 0 : "146px", fontSize: mediaXXS ? "14px" : mediaXS ? "17px" : "18px", width: mediaXXS ? "110px" : "" }}
                />
                {(!isEmpty(thirdpartyRisks) || !isEmpty(thirdpartyIndicators)) &&
                  <Tab
                    label={i18next.t("thirdpartySheet.risks")}
                    {...a11yProps(4)}
                    className={
                      classes.tab +
                      " " +
                      (!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS) ? classes.tabDisabled : value === 4 ? classes.selectedTab : classes.unselectedTab)
                    }
                    disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS)}
                    style={{ minWidth: mediaXS ? 0 : "146px", fontSize: mediaXXS ? "14px" : mediaXS ? "17px" : "18px", width: mediaXXS ? "70px" : "" }}
                  />
                }
              </Tabs>
            </Box>

            {/* General Data */}
            <EATabPanel value={value} index={0} variant="tpCustom" shadow>
              {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD) &&
                <ThirdpartyInfos thirdparty={thirdparty} />
              }
            </EATabPanel>

            {/* Attributes */}
            <EATabPanel value={value} index={1} variant="tpCustom" shadow>
              {(hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES) ||
                hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY) ||
                hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY)) &&
                <AttributeValuesDataTable
                  id={thirdpartyId}
                  bondType="thirdparty"
                  hasAnyOfPrivileges={hasAnyOfPrivileges}
                  hasAnyOfPrivilegesOnScopedResource={hasAnyOfPrivilegesOnScopedResource}
                />
              }
            </EATabPanel>

            {/* direction */}
            <div className={classes.marginTab}>
              <EATabPanel value={value} index={2} variant="tpCustom" shadow noMarginRight>
                {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS) &&
                  <EADataGrid
                    inputRows={principals}
                    columns={principalsColumns()}
                    pageSize={3}
                    variant={"sheet"}
                  />
                }
              </EATabPanel>
            </div>

            {/* bilan data */}
            <div className={classes.marginTab}>
              <EATabPanel value={value} index={3} variant="tpCustom" shadow noMarginRight>
                {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET) &&
                  <EADataGrid
                    inputRows={balanceSheetData}
                    columns={balanceSheetColumns()}
                    pageSize={3}
                    variant={"sheet"}
                  />
                }
              </EATabPanel>
            </div>

            {/* risks */}
            {(!isEmpty(thirdpartyRisks) || !isEmpty(thirdpartyIndicators)) && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS) && (
              <div className={classes.marginTab}>
                <EATabPanel value={value} index={4} variant="tpCustom" shadow={true} noMarginRight>
                  <ThirdpartyRisks risks={thirdpartyRisks} onRiskChange={() => fetchThirdpartyInfos()} indicators={thirdpartyIndicators} 
                   thirdPartyId={thirdparty.thirdpartyId} />
                </EATabPanel>
              </div>
            )}
          </Grid>

          {/* Document infos (present/missing) */}
          <Grid item xs={3} className={classes.documentsInfo}>
            <ThirdDocumentsInfo
              documents={thirdpartyDocuments}
              thirdparty={thirdparty}
            />
          </Grid>

          {/* Header background */}
          <div
            className={
              thirdparty.onboarded
                ? classes.headerBackground
                : classes.headerBackgroundArchived
            }
          ></div>

<Grid item xs={12} style={{ zIndex: 2, position: "relative", height: "15px" }}> </Grid>

          {/* Not Archived only */}
          {thirdparty.onboarded && (
            <>
              {/* Dossiers */}
              {hasAnyOfPrivilegesOnScopedResource(
                PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER,
                PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION,
                PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES
              ) &&
                <Grid item xs={12} className={classes.backToFront}>
                  <EAAccordion
                    title={i18next.t("dossier(s)Monitored")}
                    logo="dossier"
                    counter={tpVisibleDossCount !== null ? tpVisibleDossCount : thirdpartyActiveDossiers?.length}
                    totalCount={thirdpartyActiveDossiers?.length}
                    opened={true}
                    buttons={[
                      <Tooltip title={thirdparty.active ? "Ajouter un dossier" : "Etablissement fermé"}>
                        <div ref={ref}>
                          <AddingButton
                            label={i18next.t("dossierTable.addBtnLabel")}
                            handleClick={() =>
                              createDossierFromTp(
                                state.account.id,
                                thirdparty,
                                history,
                                location
                              )
                            }
                            disabled={!(thirdparty.active && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER))}
                          />
                        </div>
                      </Tooltip>
                    ]}
                  >
                    {thirdpartyActiveDossiers && (
                      <DossiersDataTable
                        dossiers={thirdpartyActiveDossiers}
                        monitored
                        component="thirdparty"
                        pageSize={10}
                        multiDataGridPage
                        sheetVariant={true}
                        onDeactivation={() => fetchThirdpartyInfos()}
                        onVisibleRowsCountChange={count => setTpVisibleDossCount(count)}
                        fromTp
                      />
                    )}
                  </EAAccordion>
                </Grid>
              }

              {/* Documents */}
              {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT) &&
                <Grid item xs={12}>
                  <EAAccordion
                    title={i18next.t("documents(s)AskedFor")}
                    logo="document"
                    counter={tpVisibleDocsCount !== null ? tpVisibleDocsCount : thirdpartyDocuments?.length}
                    totalCount={thirdpartyDocuments?.length}
                  >
                    <DocumentsDataTable
                      documents={thirdpartyDocuments}
                      component="thirdparty"
                      pageSize={10}
                      sortModel={sortModel}
                      onSortModelChange={setSortModel}
                      multiDataGridPage
                      sheetVariant={true}
                      onVisibleRowsCountChange={count => setTpVisibleDocsCount(count)}
                      answerCount={answerCount}
                    />
                  </EAAccordion>
                </Grid>
              }

              {/* Contacts */}
              {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_CONTACT) &&
                <Grid item xs={12}>
                  <EAAccordion
                    title={i18next.t("contactsTable.title")}
                    logo="contacts"
                    counter={tpVisibleContactsCount !== null ? tpVisibleContactsCount : contacts?.length}
                    totalCount={contacts?.length}
                    buttons={[
                      <AddingButton
                        label={i18next.t("contactsTable.createBtnLabel")}
                        handleClick={() => setIsCreateContactDialogOpen(true)}
                        disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_CREATE_CONTACT)}
                      />,
                    ]}
                  >
                    <ContactsDataTable
                      loader={loaderContacts}
                      contacts={contacts}
                      onContactsDeleted={() => fetchContacts()}
                      onContactsCreated={() => fetchContacts()}
                      tpOid={thirdpartyId}
                      pageSize={10}
                      multiDataGridPage
                      sheetVariant={true}
                      onVisibleRowsCountChange={count => setTpVisibleContactsCount(count)}
                    />
                  </EAAccordion>

                  {isCreateContactDialogOpen && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_CREATE_CONTACT) && (
                    <UserCreationDialog
                      isOpen={isCreateContactDialogOpen}
                      handleClose={() => setIsCreateContactDialogOpen(false)}
                      handleOnCreate={() => fetchContacts()}
                      tpContact={true}
                      tpId={thirdparty.thirdpartyId}
                    />
                  )}
                </Grid>
              }
            </>
          )}

          {/* Archives */}
          {hasAnyOfPrivilegesOnScopedResource(
            PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER,
            PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION,
            PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES
          ) &&
            <Grid item xs={12} className={classes.backToFront}>
              <EAAccordion
                title={i18next.t("archives")}
                logo="archive_green"
                counter={tpVisibleArchiveDossCount !== null ? tpVisibleArchiveDossCount : thirdpartyArchives?.length}
                totalCount={thirdpartyArchives?.length}
                opened={true}
              >
                {thirdpartyArchives && (
                  <DossiersDataTable
                    monitored={false}
                    dossiers={thirdpartyArchives}
                    component="thirdparty"
                    pageSize={10}
                    multiDataGridPage
                    sheetVariant={true}
                    onVisibleRowsCountChange={count => setTpVisibleArchiveDossCount(count)}
                  />
                )}
              </EAAccordion>
            </Grid>
          }

          {/* Pop-up archive */}
          {isStopDialogOpen && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_ARCHIVE_THIRD_PARTY) && (
            <ThirdpartyStopDialog
              thirdpartyId={thirdparty.thirdpartyId}
              isOpen={isStopDialogOpen}
              handleClose={() => setIsStopDialogOpen(false)}
            />
          )}

          {/* Notes list */}
          {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_NOTES) &&
            <Grid item xs={12} style={{ zIndex: 2, position: "relative" }}>
              <EAAccordion
                title={i18next.t("notes.title")}
                logo="note_green"
                counter={tpNotes?.length}
                opened={false}
                notificationCounter={0} // TODO change when notifications counter is available
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_NOTES)}
              >
                {tpNotes && (
                  <NotesList
                    notes={tpNotes}
                    fetchNotes={() => fetchTPNotes()}
                    createNote={createNoteTPCallback}
                    updateNote={updateNoteTPCallback}
                    deleteNote={deleteNoteTPCallback}
                    type="THIRD_PARTY"
                  />
                )}
              </EAAccordion>
            </Grid>
          }
        </Grid>
      )}
    </>
  );
}

export default TPSheet;
