import React, {useContext} from "react";
import useStyles from "../style/js-style/components/BreadCrumbStyle"
import {Context} from "../states/Store";
import i18next from "../i18n/i18n";
import {Link} from "react-router-dom";
import ROUTES, {getUrlWithAccountId} from "../config/Routes";
import clsx from "clsx";

const BreadCrumb = ({crumbs}) => {
  const { classes } = useStyles();
  const [state] = useContext(Context);

  return (
      <div className={state.drawerIsOpened ? classes.root : classes.rootReduced}>
          <Link
            to={getUrlWithAccountId(state.account?.id, ROUTES.DASHBOARD)}
            className={classes.link}
          >
            {` ${i18next.t("menu.dashboard")}`}
          </Link>
          &nbsp;/&nbsp;
          {crumbs?.map((crumb, index) => (
            <React.Fragment key={crumb.label+index}>
              {crumb.path ? (

                <Link
                  to={getUrlWithAccountId(state.account?.id, crumb.path)}
                  className={clsx(crumb.disableTranslation ? "dt" : "" ,classes.link)}
                >
                  {crumb.disableTranslation ? crumb.label : i18next.t(crumb.label)}
                </Link>
              ) : (
                <span className={clsx(crumb.disableTranslation ? "dt" : "" , index === crumbs.length-1 ? classes.lastPart : "")}>
                  {crumb.disableTranslation ? crumb.label : i18next.t(crumb.label)}
                </span>
              )}
              {index < crumbs.length-1 && " / "}
            </React.Fragment>
          ))}
      </div>
    );
}

export default BreadCrumb;
