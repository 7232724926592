import React, { useCallback, useEffect, useState } from "react";
import i18next from "i18next";
import _ from "lodash"
import { useFormContext, useWatch } from "react-hook-form";
import { TwitterPicker } from "react-color";
import { Box, Grid } from "@mui/material";

import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import EAIcon from "components/common/EAIcon";
import {
  CALCULATION_BRANCH_STRING_RESULT_MAX_LENGTH,
  CalculationResultType,
  checkExpression,
  getBranchModel,
} from "services/common/CalculatorUtils";

import CalculatorStandardInput from "components/calculator/CalculatorStandardInput";
import Criteria from "./Criteria";
import { REGEXP_DECIMAL } from "services/common/Constants";


const Branches = ({
  fieldName,
  resultType
}) => {
  const { classes } = useStyles();

  const formMethods = useFormContext();
  const {
    setValue,
  } = formMethods;

  const [defaultBranch, setDefaultBranch] = useState();
  const [otherBranches, setOtherBranches] = useState([]);

  let branches = useWatch({ name: `${fieldName}` });

  // Add a new branch
  const onAddEntry = useCallback(() => {
    setValue(fieldName, [...branches, getBranchModel()]);
  }, [fieldName, branches, setValue]);

  // Delete a branch
  const onRemoveEntry = useCallback((id) => {
    setValue(fieldName, [...branches.filter(criterion => criterion.id !== id)]);
  }, [fieldName, branches, setValue]);

  // En cas de suppression de la seule branche disponible on en recrée une vide
  useEffect(() => {
    if (_.size(_.filter(branches, { default: false })) < 1) {
      onAddEntry();
    }
  }, [branches, onAddEntry]);

  useEffect(() => {
    setDefaultBranch(_.find(branches, { default: true }));
    setOtherBranches(_.filter(branches, { default: false }));
  }, [branches]);

  return (
    <>
      <div className={classes.declarationBranchTitleWrapper}>
        <EAIcon icon="calculator/calculate" />
        <span className={classes.declarationBranchTitle}>
          {i18next.t("calculator.dialog.create.branches")}
        </span>
      </div>

      <div className={classes.declarationBranch}>
        {defaultBranch && (
          <DefaultPresentation
            data={defaultBranch}
            fieldName={`${fieldName}.0`}
            resultType={resultType}
          />
        )}

        {otherBranches && otherBranches.map((item, index) => {
          return (
            <StrandardPresentation
              key={`branch-${index + 1}`}
              index={index}
              data={item}
              fieldName={`${fieldName}.${index + 1}`}
              onRemove={() => onRemoveEntry(item.id)}
              resultType={resultType}
            />
          );
        })}
      </div>

      <div
        className={classes.declarationBranchAdd}
        onClick={() => onAddEntry()}
      >
        + {i18next.t("calculator.dialog.create.addBranch")}
      </div>
    </>
  );
}

const DefaultPresentation = ({ data, fieldName, resultType }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.defaultBranch}>
      <div className={classes.defaultBranchNotice}>
        <EAIcon icon={"warning-snack"} />
        <span>{i18next.t("calculator.dialog.create.defaultBranchNotice")}</span>
      </div>
      <div className={classes.defaultBranchForm}>
        <BranchResultValueField
          fieldName={`${fieldName}.resultValue`}
          resultType={resultType}
        />
        <div style={{ position: "relative" }}>
          <BranchColorPickerField
            fieldName={`${fieldName}.resultColorCode`}
            defaultValue={data?.resultColorCode}
          />
        </div>
      </div>
    </div>
  )
}

const StrandardPresentation = ({ data, fieldName, index, onRemove, resultType }) => {
  const { classes } = useStyles();

  const formMethods = useFormContext();
  const {
    setValue,
    trigger,
    setError,
    clearErrors
  } = formMethods;

  const [isOpen, setIsOpen] = useState(true);

  const flatCriteria = useWatch({ name: `${fieldName}.flatCriteria` });

  useEffect(() => {
    if ((flatCriteria === undefined) || _.isEmpty(flatCriteria)) {
      onRemove();
    }
  }, [flatCriteria, onRemove]);

  const validateExpression = useCallback((expr) => {
    return checkExpression(expr, formMethods.getValues(`${fieldName}.flatCriteria`));
  }, [formMethods, fieldName]);

  const onAddOrRemoveCriteriaEntry = useCallback(() => {
    trigger(`${fieldName}.formula`);
  }, [fieldName, trigger]);

  const onCriteriaOrderChange = useCallback(() => {
    setValue(`${fieldName}.formula`, ``);
    clearErrors(`${fieldName}.formula`);
  }, [fieldName, setValue, clearErrors]);


  return (
    <div className={classes.rootBranch}>
      <div className={classes.branch} onClick={() => setIsOpen(!isOpen)}>
        <EAIcon icon="arrow/arrow-to-up_large" className={!isOpen ? classes.arrowIcon : classes.arrowIconReverse} />
        <div className={classes.branchNotice}>
          <span>Branche {index + 1}</span>
        </div>

        <div className={classes.branchForm}>
          <BranchResultValueField
            fieldName={`${fieldName}.resultValue`}
            resultType={resultType}
          />
          <div style={{ position: "relative" }}>
            <BranchColorPickerField
              fieldName={`${fieldName}.resultColorCode`}
              defaultValue={data?.resultColorCode}
            />
          </div>
        </div>
      </div>

      <div style={{ display: isOpen ? "block" : "none" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Criteria
              fieldName={`${fieldName}.flatCriteria`}
              connectiveOperator={<></>}
              onSizeChange={onAddOrRemoveCriteriaEntry}
              onCriteriaOrderChange={onCriteriaOrderChange}
            />
          </Grid>

          <Grid item xs={12} margin={4}>
            <Box sx={{
              width: '718px',
              height: '83px',
              opacity: '0px',
              m: 0,
              p: 2,
              borderLeft: '2px solid #CC7B00',
              bgcolor: 'border-left: 2px solid #CC7B00',
              background: '#FFF7EB',
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
            }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className={classes.branchFormulaLabel}>
                    <EAIcon icon={"warning-snack"} />
                    <span>{`${i18next.t("calculator.dialog.create.label.formula")} *`}</span>
                  </div>
                  <div className={classes.branchFormulaNotice}>
                    {`${i18next.t("calculator.dialog.create.label.formulaInfo")}`}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <CalculatorStandardInput
                    fieldName={`${fieldName}.formula`}
                    label={``}
                    placeholder={`${i18next.t("calculator.dialog.create.label.formulaPlaceholder")}`}
                    isRequired={true}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(newValue) => { }}
                    validate={(value) => {
                      const errorMessage = validateExpression(value);

                      if (errorMessage === ``) {
                        clearErrors(`${fieldName}.formula`);
                        return null;
                      } else {
                        const message = i18next.t(`calculator.errors.${errorMessage}`);
                        setError(`${fieldName}.formula`, { type: "manual", message: message });
                        return message;
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const BranchResultValueField = ({ fieldName, resultType }) => {
  const formMethods = useFormContext();
  const {
    setError,
    clearErrors
  } = formMethods;

  return (
    <CalculatorStandardInput
      fieldName={fieldName}
      placeholder={i18next.t("calculator.dialog.create.placeholder.resultValue")}
      isRequired={true}
      style={{ background: "white", width: "330px" }}
      validate={(value) => {
        console.log("resultType: ", resultType);
        let errorMsg = null;

        switch (resultType) {
          case CalculationResultType.STRING:
            if (value.length > CALCULATION_BRANCH_STRING_RESULT_MAX_LENGTH) {
              errorMsg = i18next.t(
                "calculator.errors.resultMaxLengthExceeded",
                {
                  length: CALCULATION_BRANCH_STRING_RESULT_MAX_LENGTH,
                }
              );
              setError(fieldName, { type: "manual", message: errorMsg });
            } else {
              clearErrors(fieldName);
            }
            break;

          case CalculationResultType.NUMERIC:
            if (value && !value.match(REGEXP_DECIMAL)) {
              errorMsg = i18next.t("calculator.errors.numericResultNeeded");
              setError(fieldName, { type: "manual", message: errorMsg });
            } else {
              clearErrors(fieldName);
            }
            break;

          default:
            break;
        }

        return errorMsg;
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );
}

const BranchColorPickerField = ({ fieldName, defaultValue }) => {
  const { classes } = useStyles();

  const [pickerIsOpen, setPickerIsOpen] = useState(false);
  const [pickerValue, setPickerValue] = useState(defaultValue);

  const formMethods = useFormContext();
  const {
    setValue,
  } = formMethods;

  return (
    <>
      {pickerIsOpen &&
        <div
          style={{ position: "absolute", right: "-14px", top: "54px", zIndex: 200 }}
          onClick={(e) => e.stopPropagation()}>
          <TwitterPicker
            color={pickerValue}
            triangle={"top-right"}
            onChangeComplete={(color) => {
              setPickerValue(color.hex);
              setValue(`${fieldName}`, color.hex)
              setPickerIsOpen(!pickerIsOpen);
            }}
          />
        </div>
      }
      <div
        style={{ background: pickerValue === "" ? "#E2E2EA" : pickerValue, cursor: "pointer", zIndex: 1 }}
        className={classes.colorPickerWrapper}
        onClick={(e) => {
          e.stopPropagation();
          setPickerIsOpen(!pickerIsOpen);
        }}
      >
        {pickerValue === "" &&
          <EAIcon icon={"oblique-line"} style={{ position: "relative", right: "-3px", top: "1px", rotate: "5deg" }} />
        }
        <div className={classes.colorPickerPenIcon}>
          <EAIcon icon={"edit_dark"} />
        </div>
      </div>
    </>
  );
}

export default Branches;
