import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cross: {
    position: "absolute",
    top: "23px",
    right: "23px",
    cursor: "pointer",
  },
  title: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#535362",
    marginBottom: "32px",
  },
  mainContentWrapper: {
    width: "352px",
  },
  placeHolder: {
    color: theme.palette.grey.grey400,
    fontSize: "14px",
    fontWeight: 400,
  },
  multilineInput: {
    "& ::placeholder": {
      color: "#9A9AAF !important",
      opacity: 1,
    },
    "& .MuiOutlinedInput-root": {
      border: "none"
    }
  },
  inputSelect: {
    minWidth: "120px",
    border: "none"
  },
  inputText: {
    "& .MuiOutlinedInput-root": {
      border: "none"
    }
  },
  inputPathVariableItem: {
    minWidth: "150px",
    maxWidth: "150px",
    position: "relative",
    top: "-5px",
    margin: "0 5px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#535362"
  },
  inputVariableNameBranch: {
    width: "100%",
  },
  // Delete Pop-up
  deleteRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  imgWrapper: {
    backgroundColor: "#FFEBEB",
    padding: "1rem",
    borderRadius: "50%",
    width: "55px",
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  imgWrapperAlert: {
    backgroundColor: "#FFF7EB",
    // padding: "1rem",
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  imgContent: {
    width: "21px",
    height: "27px"
  },
  imgContentWarning: {
    width: "45px",
    height: "45px"
  },
  bolder: {
    fontWeight: 700,
    fontFamily: "Metric",
    fontSize: "20px",
    color: theme.palette.grey.grey700,
    maxWidth: "370px",
    textAlign: "center",
  },
  calculInfoBlocWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: theme.palette.grey.grey700,
  },
  calculInfoBlocContent: {
    backgroundColor: "#F2F3F7",
    padding: "13px 1.5rem",
    textAlign: "center",
    minWidth: "370px",
  },
  subtitle: {
    color: "#7E7E8F",
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "4px",
  },
  // declaration variables
  rootDeclaration: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  },
  declarationHeader: {
    marginBottom: "35px",
  },
  declarationHeaderCalcul: {
    color: "#16161E",
    fontFamily: "Metric",
    fontSize: "18px",
    fontWeight: 600,
  },
  declarationHeaderTitle: {
    color: "#16161E",
    fontFamily: "Metric",
    fontSize: "25px",
    fontWeight: 600,
  },
  declarationHeaderType: {
    borderRadius: "16px",
    border: "1px solid #F2F3F7",
    background: "#FFF",
    color: "#7E7E8F",
    fontFamily: "Roboto",
    fontSize: "10px",
    fontWeight: 700,
    marginLeft: "5px",
    position: "relative",
    top: "-3px",
    padding: "6px",
  },
  declarationVariables: {
    backgroundColor: "white",
    width: "100%",
    marginBottom: "27px",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    "& .MuiTabs-root": {
      "& .MuiTabs-scroller": {
        "& .MuiTabs-indicator": {
          backgroundColor: "#2D9F40",
        },
      },
    },
  },
  declarationBranch: {
    width: "100%",
  },
  declarationBranchTitleWrapper: {
    display: "flex",
    gap: "11px",
    marginBottom: "11px",
  },
  declarationBranchTitle: {
    color: "#373534",
    fontFamily: "Metric",
    fontSize: "18px",
    fontWeight: 600,
  },
  declarationBranchAdd: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 0",
    width: "100%",
    borderRadius: "4px",
    background: "#C6CBD950",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
    fontFamily: "Metric",
    fontSize: "14px",
    fontWeight: 600,
    color: "#535362",
    marginTop: "5px",
    cursor: "pointer",

    "&:hover": {
      background: "#C6CBD980",
    },
  },
  declarationControls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "27px",
  },
  declarationControlsLeft: {
    display: "flex",
    gap: "6px",
    marginLeft: "5px",
    padding: "10px 10px",
    cursor: "pointer",
  },
  declarationControlsRight: {
    display: "flex",
    gap: "16px",
  },
  deleteCalc: {
    color: "#CC0001",
    fontFamily: "Metric",
    fontSize: "12px",
    fontWeight: 600,
  },
  disabledDeleteBtn: {
    color: "#CC0001",
    fontFamily: "Metric",
    fontSize: "12px",
    fontWeight: 600,
    opacity: "30%",
    cursor: "default",
  },
  defaultBranch: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 49px 8px 14px",
    alignItems: "center",
    width: "100%",
    minHeight: "53px",
    borderRadius: "4px",
    background: "linear-gradient(0deg, rgba(255, 247, 235, 0.50) 0%, rgba(255, 247, 235, 0.50) 100%), #FFF",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
    marginBottom: "18px",
  },
  defaultBranchNotice: {
    color: "#CC7B00",
    fontFamily: "Metric",
    fontSize: "16px",
    fontWeight: 600,
    display: "flex",
    gap: "15px",
  },
  defaultBranchForm: {
    display: "flex",
    alignItems: "center",
    gap: "20px"
  },
  rootBranch: {
    position: "relative",
    marginBottom: "18px",
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)"
  },
  branch: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 49px 8px 14px",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  branchNotice: {
    fontFamily: "Metric",
    fontSize: "16px",
    fontWeight: 600,
    display: "flex",
    gap: "15px",
  },
  branchForm: {
    display: "flex",
    alignItems: "center",
    gap: "20px"
  },
  branchFormulaLabel: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18.2px',
    display: "flex",
    textAlign: 'left',
    gap: "12px",
  },
  branchFormulaNotice: {
    marginTop: '8px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '130%',
  },
  arrowIcon: {
    position: "absolute",
    top: "17px",
    right: "15px",
    width: "15px"
  },
  arrowIconReverse: {
    position: "absolute",
    top: "17px",
    right: "15px",
    width: "15px",
    transform: "rotate(180deg)",
  },
  branchFormWrapper: {
    minHeight: "71px",
    padding: "14px",
    backgroundColor: "rgba(242, 243, 247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "28px",
    fontWeight: 600,
  },
  tabsWrapper: {
    "& .MuiTabs-root": {
      "& .MuiTabs-scroller": {
        "& .MuiTabs-indicator": {
          backgroundColor: "#2D9F40",
        },
      },
    },
  },
  tab: {
    textTransform: "none",
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#242731 !important",
  },
  variableLineRoot: {
    width: "100%",
    height: "57px",
    backgroundColor: "rgba(242, 243, 247, 0.3)",
    borderRadius: "7px",
    marginBottom: "17px",
    position: "relative",
    padding: "8px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "28px",
    fontWeight: 600,
    flexDirection: "row",
  },
  branchCriteriaWrapper: {
    margin: "16px",
  },
  variableBranchLineRoot: {
    width: "100%",
    borderRadius: "7px",
    position: "relative",
    padding: "12px 40px 4px 4px",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row",
    gap: "16px",
  },
  variableLineLabel: {
    color: "rgba(126, 126, 143, 1)",
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: "1px"
  },
  variableLineNameItem: {
    width: "calc(50% - 75px)",
  },
  variableLineOperatorItem: {
    width: "15%",
    height: "50px"
  },
  variableLineValuesItem: {
    width: "calc(55% - 90px)",
    height: "50px"
  },
  variableLineSelectInput: {
    "& .MuiSelect-select": {
      padding: "0 !important",
      marginTop: "7px",
      marginBottom: "7px",
    },
  },
  variableInput: {
    background: "white",
    width: "217px",
    top: "2px",
    "& .MuiOutlinedInput-root": { borderRadius: "4px" },
    "& .MuiInputLabel-root": { margin: "-7px 0 0 0" },
    "& .Mui-focused": { margin: "0" },
    "& .MuiFormLabel-filled": { margin: "0", background: "linear-gradient(#F2F3F7 50%, white 50%)" },
  },
  variableInputError: {
    background: "white",
    width: "217px",
    top: "2px",
    "& .MuiOutlinedInput-root": { borderRadius: "4px", color: "#FF0001" },
    "& .MuiInputLabel-root": { margin: "-7px 0 0 0" },
    "& .Mui-focused": { margin: "0" },
    "& .MuiFormLabel-filled": { margin: "0", background: "linear-gradient(#F2F3F7 50%, white 50%)" },
  },
  pathSelectInput: {
    width: "150px",
    marginLeft: "14px",
    color: "#535362",
    fontWeight: 500,
    fontSize: "12px",
    "& .MuiSelect-select": {
      padding: "0px 20px 5px 5px !important"
    },
  },
  variableBranchLineCrossIcon: {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    cursor: "pointer",
  },
  variableLineCrossIcon: {
    position: "absolute",
    right: "16px",
    cursor: "pointer",
  },
  variableDivider: {
    fontWeight: "700",
    color: "#656575",
    marginLeft: "14px",
    fontSize: "14px",
  },
  addVariable: {
    color: "#2D9F40",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Metric",
  },
  addCriterion: {
    color: "#2D9F40",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Metric",
  },
  colorPickerWrapper: {
    width: "40px",
    height: "35px",
    borderRadius: "4px",
    border: "1px solid var(--white, #FFF)",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
    position: "relative",
    zIndex: 20,
  },
  colorPickerPenIcon: {
    backgroundColor: "white",
    width: "19px",
    height: "19px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.07))",
    position: "absolute",
    bottom: "-5px",
    right: "-5px",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
  },
  formLabel: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey.grey500,
  },
  selectPlaceholder: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9A9AAF"
  },
  selectedItem: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#7E7E8F"
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    width: "100%",
  },
  finalButtonWrapper: {
    paddingTop: "32px",
    display: "flex",
    gap: "16px",
  }
}));

export default useStyles;
