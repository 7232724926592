import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import DocumentsDataTable from "../../../components/documents/DocumentsDataTable";
import { canAnswerDocument, isUpdatableDocument } from "services/common/DocumentUtils";
import { InitialDocumentsTableSort } from "components/documents/DocumentsDataTableUtils";

function DossierDocumentsList({
  dossier,
  sheetVariant = false,
  onVisibleRowsCountChange = () => {},
}) {
  const [documents, setDocuments] = useState([]);
  const [answerCount, setAnswerCount] = useState(0);
  const [sortModel, setSortModel] = useState(InitialDocumentsTableSort);

  useEffect(() => {
    if (dossier.documents?.length > 0) {
      const formattedDocuments = dossier.documents
        // We concat both evidences while excluding evidences that have been stopped, to show other reasons
        .map((doc) => ({
          ...doc,
          present: doc.evidences?.length > 0,
          evidences: [].concat(
            doc.evidences,
            doc.historicEvidences?.filter(
              (e) => e.statusReason !== "ARRET_DEMANDE"
            )
          ),
        }))
        .map((doc) => ({
          ...doc,
          nameFr: doc.documentName,
          present: doc.present,
          depositorName: doc.evidences?.[0]?.metadata?.depositor,
          transmitter: doc.evidences?.[0]?.transmitter,
          depositDate: doc.evidences?.[0]?.uploadDate,
          endDate: doc.evidences?.[0]?.expirationDate,
          reponseId: doc.evidences?.[0]?.responseId,
          fileId: doc.evidences?.[0]?.id,
          thirdpartyId: dossier.thirdpartyId,
          thirdpartyOnboarded: dossier.thirdpartyOnboarded,
          dossierMonitored: dossier.monitored,
          isAnswerable: canAnswerDocument(doc) && dossier.monitored,
          refusedToFile: doc.hasRefusedToFile,
          nbMultiple: doc.nbMultiple
        }));

      setDocuments(formattedDocuments);
    } else {
      setDocuments([]);
    }
  }, [dossier]);

  useEffect(() => {
    setAnswerCount(documents?.filter((x) => (x.isAnswerable === true && !isUpdatableDocument(x)))?.length || 0);
  }, [documents])

  return (
    <>
      <Paper elevation={0} style={{ marginTop: "17px", width: "100%" }}>
        <DocumentsDataTable
          documents={documents}
          component="dossier"
          pageSize={10}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          multiDataGridPage
          sheetVariant={sheetVariant}
          isDossierMonitored={dossier.monitored}
          onVisibleRowsCountChange={onVisibleRowsCountChange}
          answerCount={answerCount}
        />
      </Paper>
    </>
  );
}

export default DossierDocumentsList;
