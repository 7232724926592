import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Store from "./states/Store";

const root = createRoot(document.getElementById("root"));

// TODO: Strict mode removed for react 18 upgrade, it keeps refreshing the app.
// Need to check the problem and fix it. It should work normally once deployed.
root.render(
  // <React.StrictMode>
  <Store>
    <App />
  </Store>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
