import { Box } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';

import EAButton from "components/common/EAButton";
import i18next from "i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import useStyles from "./ImportFileThirdPartiesStyle";
import { getAccountAttributesByBondType } from "services/edge/AttributesService";
import { Context } from "states/Store";
import { useMemo } from "react";
import { getListCountries } from "services/edge/ListService";
import OptionsImportDialog from "../OptionsImportDialog/OptionsImportDialog";
import NameImportDialog from "../NameImportDialog/NameImportDialog";
import SummaryImportDialog from "../SummaryImportDialog/SummaryImportDialog";
import { theme } from "style/globalStyle";
import EAIcon from "components/common/EAIcon";
import clsx from "clsx"
import { HoverableBloc } from "components/common/HoverableBloc";


const ImportFileThirdParties = ({updateLastImportId}) => {
    const { classes } = useStyles();
    const [state] = useContext(Context);

    //-----------  Get list Countries ------------------------
    const [countries, setCountries] = useState([]);

    const fetchCountries = useCallback(async () => {
        try {
          const results = await getListCountries();
          setCountries(results.content);
        } catch (error) {
            setCountries([]);
        }
      }, []);
    
      useEffect(() => {
            fetchCountries();
      }, [fetchCountries])

    //-----------  Get thirdParties attributes ------------------------
    const [attributesList, setAttributesList] = useState([]);

    const fetchAttributesList = useCallback(async () => {
        try {
          const results = await getAccountAttributesByBondType(state.account.id, "thirdparty", null);
          setAttributesList(
            results.content.map((x) => {
              return {
                ...x,
                typeLabel: i18next.t(`attributeType.${x.type}`),
              };
            })
          );
        } catch (error) {
          setAttributesList([]);
        }
        
      }, [state.account]);
    
      useEffect(() => {
        if (state.account) {
        fetchAttributesList();
        }
      }, [fetchAttributesList, state.account])

    //----------- columns /fields management ------------------------
    const initialFields = useMemo(() => [{  
          label: "ID E-ATTESTATIONS", 
          key: "thirdpartyId",
          alternateMatches: ["identifiant e-attestations", "id e-attestations"],
          fieldType: {
          type: "input",
          },
          example: "50382936800052",
        },
        {  
          label: "DUNS", 
          key: "duns",
          alternateMatches: ["duns"],
          fieldType: {
          type: "input",
          },
          example: "260004250",
        },
        {  
          label: "IDENTIFIANT LOCAL", 
          key: "idLocal",
          alternateMatches: ["id local", "siret", "siren", "SIRET", "SIREN", "id_local", "identifiant", "identifiant local", "registration number"],
          fieldType: {
          type: "input",
          },
          example: "50382936800052",
        },
        {  
          label: "TVA", 
          key: "tva",
          alternateMatches: ["tva", "VAT", "vat"],
          fieldType: {
          type: "input",
          },
          example: "FR69503829368",
        },
        {  
          label: i18next.t("imports.importDialogName.country") ,
          key: "country",
          alternateMatches: ["localisation", "country", "countries", "pays"],
          fieldType: {
              type: "input",
              options : countries.map(country => {return {label: country.code, value: country.code}})
          },
          example: "FR",
          validations: [
          {
              rule: "required",
              errorMessage: i18next.t("imports.importDialogName.fieldRequired"),
              level: "error",
          },
        ],
        },
        {  
          label: i18next.t("imports.importDialogName.codeThirdParty") ,
          key: "thirdpartyCode",
          alternateMatches: ["code", "ref", "reference", "code tiers"],
          fieldType: {
              type: "input",
          },
          example: "FOUR01",
          validations: [
            {
              rule: "regex",  
              value: /^.{0,255}$/,
              flags: "i",
              errorMessage: i18next.t("imports.importDialogName.fieldLengthMax"),
              level: "error",
          },
          ],
        }, 
        {  
          label: i18next.t("imports.importDialogName.contacts"),
          key: "contacts",
          alternateMatches: ["email", "mail","emails", "mails"],
          fieldType: {
              type: "input",
          },
          example: "john.doe@e-attestations.com;jane.doe@e-attestations.com",
        }
    ], [countries]);

    const [fields, setFields] = useState(initialFields);

    //-------- add attributes to fields managed--------

    const addAttributesToFields = useCallback(() => {
        let newListFields = [...initialFields]; 
        attributesList.forEach(attribute => {
            newListFields.push({
                label: attribute.label,
                key: "attribute_"+attribute.id,
                alternateMatches: [attribute.label],
                example: attribute.values ? attribute.values[0].value : attribute.label,
                fieldType: {type : "input"},
                validations: [
                  {
                      rule: /^.{0,255}$/,
                      errorMessage: i18next.t("imports.importDialogName.fieldLengthMax"),
                      level: "error",
                  },
                  ],
            })
        })
        setFields(newListFields);
    }, [attributesList, initialFields]);

    useEffect(() => {
        if(attributesList.length >0) {
            addAttributesToFields();
        }
    }, [addAttributesToFields, attributesList.length])

    //--------------- download file code countries --------------------
    const downloadCodeCountries = () => {
      const url = process.env.PUBLIC_URL + "/assets/e-Attestations_Country_IsoAlpha2.xlsx" 
      window.open(url);
    }

    //-------------- Validation Data after match columns step -----
    const verifyDataAftermatchColumnsStepHandler = (data) => {
      return data.map(element => {
        return {
          ...element, 
          duns: nettoyerIdentifiant(element.duns),
          tva: nettoyerIdentifiant(element.tva), 
          thirdpartyId: nettoyerIdentifiant(element.thirdpartyId), 
          idLocal: nettoyerIdentifiant(element.idLocal)
        }
      });
    }

    const nettoyerIdentifiant = (identifiant) => {
      return identifiant ? identifiant?.trim()?.replaceAll(",", "") : null;
    }


    //---------- Validation rows after change -----------------
    const validationRowsHandler = (data, addError) => {
      // Validation
      if (!data.duns && !data.thirdpartyId && !data.tva && !data.idLocal) {
        addError("duns", { message: i18next.t("imports.importDialogName.oneIdRequired"), level: "error" })
        addError("tva", { message: i18next.t("imports.importDialogName.oneIdRequired"), level: "error" })
        addError("thirdpartyId", { message: i18next.t("imports.importDialogName.oneIdRequired"), level: "error" })
        addError("idLocal", { message: i18next.t("imports.importDialogName.oneIdRequired"),  level: "error" })
      }
      if(data.country && !countries.map(country => country.code).includes(data.country.trim().toUpperCase())) {
        addError("country", { message: i18next.t("imports.importDialogName.invalidField"), level: "error" })
      }
      // Transformation
      return { ...data, country: data.country ? data.country.toUpperCase() : ""}
    }

     const validationTableHandler = (data, addError) => {
      // add warning if value of one of ids is duplicate
      const ids = ["tva", "duns", "thirdpartyId", "idLocal"]
       for (const id of ids) {
        const listeId = data.map(row => row[id]);
        const temp = [];
        for (var index = 0; index < listeId.length; index++) {
          if(listeId[index]) {
            if(temp.includes(listeId[index])) {
              addError(index, id, { message: i18next.t("imports.importDialogName.warningIdNotUnique"), level: "error" })
            } else {
              temp.push(listeId[index])
            }
          }
        } 
      }     
      return data
    } 

    // ---------- Translations -----------------
    const translationsStepsImport = {
        uploadStep: {
          title: i18next.t("imports.importDialogName.uploadFileStep"),
          manifestTitle: 
          <span key="manifestTitle">
            <span>{i18next.t("imports.importDialogName.expectDataTitle")}</span>
            <span className={classes.dialogSubTitleImportThirdParties}>{i18next.t("imports.importDialogName.expectDataSusTitle")}</span>
          </span>,
          manifestDescription: 
          <span key="manifestDescription" className={classes.importExplication}>
            <span className={classes.iconExplication}><ApartmentIcon/></span>
            <span>
              <span className={classes.bold}>{i18next.t("imports.importDialogName.supportId")}</span>
              <span>{i18next.t("imports.importDialogName.supportIdDescription")}</span>
            </span>
            <span className={classes.iconExplication}><LanguageIcon /></span>
            <span>
              <span className={classes.bold}>{i18next.t("imports.importDialogName.codeCountries")} : </span>
              <span>
                {i18next.t("imports.importDialogName.codeCountriesDescription")}
                <HoverableBloc className={classes.link} onClick={downloadCodeCountries}>Attestations_Country_IsoAlpha2</HoverableBloc>.
              </span>
            </span>
            <span className={classes.iconExplication}><SettingsEthernetIcon/></span>
            <span>
              <span className={classes.bold}>{i18next.t("imports.importDialogName.codeThirdParty")} : </span>
              <span>{i18next.t("imports.importDialogName.codeThirdPartyDescription")}</span>
              </span>
            <span className={classes.iconExplication}><PermContactCalendarIcon/></span>
            <span>
              <span className={classes.bold}>{i18next.t("imports.importDialogName.contacts")} : </span>
              <span>{i18next.t("imports.importDialogName.contactsDescription")}</span>
            </span>
            <span className={clsx(classes.iconExplication, classes.iconCustom)}><EAIcon icon={"attributs_tiers-ou-dossier"}/></span>
            <span>
              <span className={classes.bold}>{i18next.t("imports.importDialogName.attributes")} : </span>
              <span>{i18next.t("imports.importDialogName.attributesDescription")}
              {i18next.t("imports.importDialogName.attributesDescription2")}</span>
            </span>
          </span>,
          maxRecordsExceeded: (maxRecords) => `Too many records. Up to ${maxRecords} allowed`,
          dropzone: {
            title: <span key="dropzoneTitle" className={classes.dropZoneText}>
                    <span className={classes.iconDropzone}><CloudUploadSharpIcon /></span>
                    <span>
                      <span className={classes.drapDrop}>{i18next.t("imports.importDialogName.dragDropText")}</span>
                      <span>{i18next.t("imports.importDialogName.yourFile")}</span>
                    </span>
                    <span className={classes.dropzoneSubText}>{i18next.t("imports.importDialogName.formatFile")}</span>
                  </span>,
            errorToastDescription: i18next.t("imports.importDialogName.uploadRejected"),
            activeDropzoneTitle: i18next.t("imports.importDialogName.dropHere"),
            buttonTitle: i18next.t("imports.importDialogName.dropzoneButtonTitle"),
            loadingTitle: i18next.t("imports.importDialogName.processingUpload"),
          },
          selectSheet: {
            title: i18next.t("imports.importDialogName.selectSheetTitle"),
            nextButtonTitle: i18next.t("imports.importDialogName.nextButtonTitle"),
            backButtonTitle: i18next.t("imports.importDialogName.backButtonTitle"),
          },
        },
        selectHeaderStep: {
          title: i18next.t("imports.importDialogName.selectRowStep"),
          nextButtonTitle: i18next.t("imports.importDialogName.nextButtonTitle"),
          backButtonTitle: i18next.t("imports.importDialogName.backButtonTitle"),
        },
        matchColumnsStep: {
          title: i18next.t("imports.importDialogName.matchColumnStep"),
          nextButtonTitle: i18next.t("imports.importDialogName.nextButtonTitle"),
          backButtonTitle: i18next.t("imports.importDialogName.backButtonTitle"),
          userTableTitle: i18next.t("imports.importDialogName.userTableTitle"),
          templateTitle: i18next.t("imports.importDialogName.templateTitle"),
          selectPlaceholder: i18next.t("imports.importDialogName.selectPlaceholder"),
          ignoredColumnText: i18next.t("imports.importDialogName.ignoredColumnText"),
          subSelectPlaceholder: i18next.t("imports.importDialogName.subSelectPlaceholder"),
          matchDropdownTitle: "Match",
          unmatched: i18next.t("imports.importDialogName.unMatched"),
          duplicateColumnWarningTitle: i18next.t("imports.importDialogName.duplicateColumnWarningTitle"),
          duplicateColumnWarningDescription: i18next.t("imports.importDialogName.duplicateColumnWarningDescription"),
        },
        validationStep: {
          title: i18next.t("imports.importDialogName.validationStep.title"),
          nextButtonTitle: i18next.t("imports.importDialogName.validationStep.nextButtonTitle"),
          backButtonTitle: i18next.t("imports.importDialogName.backButtonTitle"),
          noRowsMessage: i18next.t("imports.importDialogName.validationStep.noRowsMessage"),
          noRowsMessageWhenFiltered: i18next.t("imports.importDialogName.validationStep.noRowsMessageWhenFiltered"),
          discardButtonTitle: i18next.t("imports.importDialogName.validationStep.discardButtonTitle"),
          filterSwitchTitle: i18next.t("imports.importDialogName.validationStep.filterSwitchTitle"),
        },
        alerts: {
          confirmClose: {
            headerTitle: i18next.t("imports.importDialogName.confirmClose.headerTitle"),
            bodyText: i18next.t("imports.importDialogName.confirmClose.bodyText"),
            cancelButtonTitle: i18next.t("imports.importDialogName.confirmClose.cancelButtonTitle"),
            exitButtonTitle: i18next.t("imports.importDialogName.confirmClose.exitButtonTitle"),
          },
          submitIncomplete: {
            headerTitle: i18next.t("imports.importDialogName.submitIncomplete.headerTitle"),
            bodyText: i18next.t("imports.importDialogName.submitIncomplete.bodyText"),
            bodyTextSubmitForbidden: i18next.t("imports.importDialogName.submitIncomplete.bodyTextSubmitForbidden"),
            cancelButtonTitle: i18next.t("imports.importDialogName.submitIncomplete.cancelButtonTitle"),
            finishButtonTitle: i18next.t("imports.importDialogName.submitIncomplete.finishButtonTitle"),
          },
          unmatchedRequiredFields: {
            headerTitle: i18next.t("imports.importDialogName.unMatchFieldRequiredTitle"), 
            bodyText: i18next.t("imports.importDialogName.unMatchFieldRequiredDescription"), 
            listTitle: i18next.t("imports.importDialogName.unMatchFieldRequiredList"), 
            cancelButtonTitle: i18next.t("imports.importDialogName.submitIncomplete.cancelButtonTitle"),
            continueButtonTitle: i18next.t("imports.importDialogName.nextButtonTitle"),
          },
          toast: {
            error:  i18next.t("imports.importDialogName.errors"),
          },
        },
      }

      //----------------------- THEME ------------------------------

      
      const customTheme = {
        colors: {
          rsi: {
            100: theme.palette.primary.primary200,
            200: theme.palette.primary.primary300,
            300: theme.palette.primary.primary400,
            400: theme.palette.primary.primary500,
            500: theme.palette.primary.primary600,
            600: theme.palette.primary.primary700,
            700: theme.palette.primary.primary800,
            800: theme.palette.primary.primary900,
            900: theme.palette.primary.primary900
          },
        },
        components: {
          UploadStep: {
            baseStyle: {
              heading: {
                display: "none"
              },
              dropzoneText: {
                size: "sm",
                lineHeight: 7,
                fontWeight: "semibold",
                color: theme.palette.grey.grey500,
                zIndex: 5
              },
              dropZoneBorder: "none",
              dropzoneButton: {
                mt: "1rem",
                marginBottom: "32px"
              },
            }
          },
          SelectHeaderStep: {
            baseStyle: {
              heading: {
                fontSize: "22px",
              },
            }
          },
          MatchColumnsStep: {
            baseStyle: {
              heading: {
                fontSize: "22px",
              },
            }
          },
          ValidationStep: {
            baseStyle: {
              heading: {
                fontSize: "22px",
              },
            }
          }, 
      },
      styles: {
        global: {
          // Cells colors
          ".rdg-cell.rdg-cell-error": {
            backgroundColor: "var(--rdg-error-cell-background-color)",
          },
          ".rdg-cell.rdg-cell-warning": {
            backgroundColor: "var(--rdg-warning-cell-background-color)",
          },
          ".rdg-cell.rdg-cell-info": {
            backgroundColor: "var(--rdg-info-cell-background-color)",
          },
          ".rdg-cell.rdg-example": {
            minHeight: "420px !important"
          },
          // colmns selected
          ".rdg-row[aria-selected=true]" : {
            backgroundColor : theme.palette.grey.grey100 +  " !important",
          },
          ".rdg-row[aria-selected=true]:hover" : {
            backgroundColor : theme.palette.grey.grey200 +  " !important",
          }, 
          ".rdg-row[aria-selected=true] .rdg-cell" : {
            backgroundColor : theme.palette.grey.grey100 +  " !important",
          },
          // radio, checkbox and switch
          ".rdg-radio": {
            backgroundColor : theme.palette.primary.primary800,
            color:  theme.palette.primary.primary500,
          },          
          ".chakra-radio__control[aria-checked=true], .chakra-radio__control[data-checked]" : {
            backgroundColor : theme.palette.primary.primary600 +  " !important",
            borderColor: theme.palette.primary.primary600 +  " !important",
          }, 
          ".chakra-checkbox__control[data-checked]" : {
            backgroundColor : theme.palette.primary.primary600 +  " !important",
            borderColor: theme.palette.primary.primary600 +  " !important",
          }, 
          ".chakra-switch__track[data-checked]" : {
            backgroundColor: theme.palette.primary.primary600 +  " !important"
          },
          // steps headers
          ".chakra-modal__content-container .cui-steps__step-icon-container" : {
            display: "none"
          },
          ".chakra-modal__content-container .cui-steps__horizontal-step" : {
              borderBottom: "8px solid rgba(45, 159, 64, 0.30)", 
              margin: "0 4px"
          },
          ".cui-steps__horizontal-step[data-highlighted], .cui-steps__horizontal-step:first-of-type" : {
              borderBottom: "8px solid" + theme.palette.primary.primary600
          },
          ".cui-steps__horizontal-step[data-highlighted] + .cui-steps__horizontal-step:not([data-highlighted])" : {
            borderBottom: "8px solid" + theme.palette.primary.primary600
          },
          ".cui-steps__horizontal-step:last-child" : {
              margin : "0 0 0 4px"
          },
          ".cui-steps__horizontal-step .cui-steps__horizontal-step-container > *": {
            marginLeft: 0
          },
          ".cui-steps__horizontal-step .cui-steps__horizontal-step-container span" : {
            fontWeight: "bold", 
            paddingBottom: "8px", 
            color: "inherit", 
            fontSize: "inherit"
          },
          ".cui-steps__horizontal-step" : {
            color : theme.palette.primary.primary600, 
            fontSize: "16px",
          },
          ".cui-steps__horizontal-step *[aria-current=step]" : {
            color : "black", 
            fontSize: "18px",
          },
          ".cui-steps__horizontal-step::after" : {
            display : "none"
          }, 
          //footer 
          "footer.chakra-modal__footer" : {
            display: "flex", 
            justifyContent: "flex-end"
          }, 
          "footer.chakra-modal__footer button" : {
            borderRadius: "20px", 
            maxWidth: "200px", 
          },
          //button remove line
          ".chakra-button:has(+ .chakra-switch)" : {
            color: theme.palette.grey.grey400 + " !important",
          },
          ".chakra-button:has(+ .chakra-switch):hover" : {
            backgroundColor: theme.palette.grey.grey100 + " !important",
          },
          // dropdowm
          "#react-select-dropdown-wrapper *[aria-selected=true]" : {
            backgroundColor: theme.palette.primary.primary100, 
            color: theme.palette.primary.primary600
          }, 
          // alertes
          "chakra-alert": {
            borderRadius: "24px !important", 
            backgroundColor: "yellow !important"
          }
        }
      }
    }

      //------------ data state && open/close dialog imports-------
      const [dataImport, setDataImport] = useState(null);
      const [options, setOptions] = useState(null);
      const [nameImport, setNameImport] = useState();
      const [summaryData, setSummaryData] = useState(null);
      const [fileImport, setFileImport] = useState(null);

      const [importfileIsOpen, setImportFileIsOpen] = useState(false);
      const [optionsImportIsOpen, setOptionsImportIsOpen] = useState(false);
      const [nameImportIsOpen, setNameImportIsOpen] = useState(false);
      const [summaryImportIsOpen, setSummaryImportIsOpen] = useState(false);

      const openImportFileHandler = () => {
          setImportFileIsOpen(true)
      }
  
      const closeImportFileHandler = () => {
          setImportFileIsOpen(false)
      }


      const openOptionsImportHalder = (data, file) => {
        setDataImport(data);
        setImportFileIsOpen(false);
        setOptionsImportIsOpen(true);
        setFileImport(file)

        // generate default name
        setNameImport(file.name.substring(0, file.name.lastIndexOf(".")))
      }

      const validateOptionsImportHandler = (data) => {
        setOptions(data);
        setSummaryData({
          thirdParties: dataImport,
          nameImport : nameImport,
          options: data,
          file: fileImport

        })
        setOptionsImportIsOpen(false);
        setNameImportIsOpen(true);
      }
      
      const validateNameImportHandler = (data) => {
        setNameImport(data.nameImport);
        setSummaryData({
          thirdParties: dataImport,
          nameImport : data.nameImport,
          options: options, 
          file: fileImport

        })
        setNameImportIsOpen(false);
        setSummaryImportIsOpen(true);
      }

    //---------- Sumbit import data --------------------

    const closeImport = () => {
      setNameImportIsOpen(false);
      setSummaryImportIsOpen(false);
      setOptionsImportIsOpen(false);
      setOptions(null);
      setSummaryData(null);
    }


    //========= RETURN ======================
    return(
        <div>

            <Box className={classes.boxStartImport}>           
                    <img
                      src={process.env.PUBLIC_URL + "/img/picture_import.svg"}
                      alt="import"
                    />

                    <div>
                      <h2 className={classes.titleImportThirdParties}>{i18next.t("imports.titleImport")}</h2>
                      <h4 className={classes.subTitleImportThirdParties}>{i18next.t("imports.subTitleImport")}</h4>

                      <EAButton content={i18next.t("imports.ouvrirImport")} onClick={openImportFileHandler}
                        customStyle={{width: "200px"}}/>
                    </div>

                    


            </Box>            

            <ReactSpreadsheetImport isOpen={importfileIsOpen} onClose={closeImportFileHandler} onSubmit={openOptionsImportHalder} 
                fields={fields} allowInvalidSubmit={true} translations={translationsStepsImport} rowHook={validationRowsHandler}
                matchColumnsStepHook={verifyDataAftermatchColumnsStepHandler}
                tableHook={validationTableHandler}
                customTheme={customTheme} id={classes.eaImport}
                dateFormat={i18next.t("date.dateFormat")}
                isNavigationEnabled
                maxFileSize={15000000}
                />

            {/* modals*/}

            <OptionsImportDialog 
              isOpen={optionsImportIsOpen} 
              onClose={closeImport}
              validationHandler={validateOptionsImportHandler}/>

            <NameImportDialog 
              isOpen={nameImportIsOpen}
              onClose={closeImport}
              validationHandler={validateNameImportHandler}
              initialValue={nameImport}/>

            <SummaryImportDialog 
              isOpen={summaryImportIsOpen} 
              onClose={closeImport}
              importData={summaryData}
              options={options}
              validationHandler={closeImport}
              attributesList={attributesList}
              updateLastImportId={updateLastImportId}/>

        </div>
    )
}

export default ImportFileThirdParties;