import React, {useCallback, useContext} from "react";
import AppBar from "@mui/material/AppBar";
import useStyles from "../style/js-style/components/EAAppBarStyle";
import MenuAppBar from "./MenuAppBar";
import { useLocation } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import {Context} from "../states/Store";

function EAAppBar({ crumbs }) {
  const { classes } = useStyles();
  const location = useLocation();
  const [state] = useContext(Context)

  const getPathAfterAccount = useCallback(() => {
    const route = location.pathname.split("/");
    route?.splice(0, 2);
    return route;
  }, [location.pathname]);

  return (
    <>
      <AppBar
        className={classes.appBar}
        position="fixed"
        sx={ state?.isMenuHighlighted ? { zIndex: 1301 } : {} }
      >
        {crumbs && <BreadCrumb path={getPathAfterAccount()} crumbs={crumbs} />}
        <MenuAppBar />
      </AppBar>
    </>
  );
}

export default EAAppBar;
