import React from "react";
import EAPieChart from "../../../components/common/charts/EAPieChart";
import EAIcon from "../../../components/common/EAIcon";
import useStyles from "../../../style/js-style/containers/dashboard/DashboardStyle";


function RiskScorePieChart({ subTab, scoreLabel="", scoreCount }) {
  const { classes } = useStyles();

  return (
    <>
      <div
        className={classes.pieContainer}
        style={{ display: scoreCount?.total === "0" ? "flex" : "none" }}
      >
        <EAIcon icon="search_off" />
        <div className={classes.pieNoDataText}>
          <span className={classes.bolder}>Aucun tiers</span> associés à votre
          recherche.
        </div>
      </div>

      {scoreCount?.total !== "0" && (
        <EAPieChart
          id={`${subTab}-${scoreLabel}-chart`}
          data={scoreCount}
          tab={"risks"}
          subTab={subTab}
        />
      )}
    </>
  );
}

export default RiskScorePieChart;
