import React, { useEffect } from "react";
import i18next from "i18next";
import {
  Box,
  Divider,
  Fade,
  Grid,
  TextField,
} from "@mui/material";
import useStyles from "./ThirdpartiesListSearchFormStyle";
import EAIcon from "components/common/EAIcon";
import {
  Controller,
  FormProvider,
  useForm,
  useWatch,
} from "react-hook-form";
import { useState } from "react";
import _ from "lodash";
import EAAutoCompleteInput from "components/inputs/EAAutoCompleteInput";
import EASelectInput from "../../../../components/inputs/EASelectInput";
import { useThirdpartyFormOptions } from "./useThirdpartyFormOptions";
import { ThirdpartiesAdvancedSearch } from "./ThirdpartiesAdvancedSearch/ThirdpartiesAdvancedSearch";
import { InduedAllScores } from "services/common/InduedUtils";
import { AdvancedSearchButtons } from "components/lists-advanced-search/AdvancedSearchButtons";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";

const getFormDefaultValues = () => {
  return {
    name: "",
    countries: [],
    departments: [],
    complete: "",
    code: "",
    companyIdType: "CIT003",
    identifier: "",
    majorActivities: [],
    contacts: [],
    indued: InduedAllScores.map((score) => ({ score, values: [] })),
    transparency: [],
  };
};

export const ThirdpartiesListSearchForm = ({
  filters,
  submitHandler = () => {}
}) => {
  const { classes } = useStyles();
  const [isDepartmentShown, setIsDepartmentShown] = useState(false);
  const {
    countriesOptions,
    departmentsOptions,
    dnbMajorOptions,
    registrationStatusOptions,
    induedOptions,
    transparencyOptions,
    financialStrengthScoreOptions,
    financialStrengthRatingOptions,
  } = useThirdpartyFormOptions();

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: getFormDefaultValues(),
  });

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    handleSubmit,
  } = formMethods;
  const countriesValue = useWatch({ control, name: "countries" });

  useEffect(() => {
    for (const key in filters) {
      setValue(key, filters[key]);
    }
  }, [setValue, filters]);

  useEffect(() => {
    setIsDepartmentShown(
      countriesValue?.length === 1 && countriesValue?.includes("FR")
    );
  }, [countriesValue]);

  const advancedSearchClickHandler = () => {
    const fieldName = "isAdvancedSearchShown";
    const isAdvancedSearchShown = getValues(fieldName);
    setValue(fieldName, !isAdvancedSearchShown);
  };

  const isAdvancedSearchShown = () => {
    return watch("isAdvancedSearchShown");
  };

  const submitForm = (data) => {
    if (_.isEmpty(errors)) {
      submitHandler(data);
    }
  };

  const handleReset = () => {
    const formDefaultValues = getFormDefaultValues();

    // Add fieldArrays for attributes and calculations reseting their values
    const currentValues = formMethods.getValues();
    currentValues.attributes &&
      (formDefaultValues.attributes = currentValues.attributes.map((x) => ({
        ...x,
        values: "",
      })));
    currentValues.calculatedAttributes &&
      (formDefaultValues.calculatedAttributes = currentValues.calculatedAttributes.map(
        (x) => ({ ...x, values: "" })
      ));
    currentValues.indicators &&
      (formDefaultValues.indicators = currentValues.indicators.map((x) => ({
        ...x,
        values: "",
      })));

    formMethods.reset(formDefaultValues);
    submitHandler(formDefaultValues);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id="thirdparty-search-form"
        onSubmit={handleSubmit(submitForm)}
        className={classes.formContainer}
      >
        {/* name */}
        <Grid container spacing={2} padding={"0 22px"}>
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <SearchFormInputBloc
                label={i18next.t("Tiers")}
                error={errors.name}
              >
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="thirdparty-name-id"
                      variant="outlined"
                      error={!!errors.name}
                      placeholder={"Indiquez le nom du tiers"}
                      fullWidth
                    />
                  )}
                />
              </SearchFormInputBloc>
            </Grid>
            <Grid item xs={isDepartmentShown ? 4 : 7}>
              {/* countries */}
              <SearchFormInputBloc label={i18next.t("Pays")}>
                <EAAutoCompleteInput
                  fieldName="countries"
                  options={countriesOptions}
                  placeholder={"Tous"}
                  variant="outlined"
                  multiple
                  limitTags={isDepartmentShown ? 2 : 3}
                />
              </SearchFormInputBloc>
            </Grid>
            {isDepartmentShown && (
              <Grid item xs={3}>
                {/* Departments */}
                {isDepartmentShown && (
                  <SearchFormInputBloc label={i18next.t("Département")}>
                    <EAAutoCompleteInput
                      fieldName="departments"
                      options={departmentsOptions}
                      placeholder={"Tous"}
                      variant="outlined"
                      multiple
                      limitTags={2}
                    />
                  </SearchFormInputBloc>
                )}
              </Grid>
            )}
            <Grid item xs={2}>
              {/* Complétude */}
              <SearchFormInputBloc label={i18next.t("Complétude")}>
                <EASelectInput
                  fieldName="complete"
                  options={[
                    { value: "true", label: "Complet" },
                    { value: "false", label: "Incomplet" },
                  ]}
                  defaultOption={"Tous"}
                />
              </SearchFormInputBloc>
            </Grid>
          </Grid>
          <Divider className={classes.mainSearchDivider} />
          <Grid
            item
            container
            spacing={2}
            alignItems="end"
            style={{ paddingTop: 0 }}
          >
            <Grid item xs={3}>
              {/* Code tiers */}
              <SearchFormInputBloc
                label={i18next.t("Code tiers")}
                error={errors.name}
              >
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="thirdparty-code-id"
                      variant="outlined"
                      error={!!errors.code}
                      placeholder="Code tiers"
                      fullWidth
                    />
                  )}
                />
              </SearchFormInputBloc>
            </Grid>
            <Grid item xs={2}>
              {/* Type identifiant */}
              <SearchFormInputBloc label={i18next.t("Identifiant")}>
                <EASelectInput
                  fieldName="companyIdType"
                  options={[
                    { value: "CIT003", label: "Duns" },
                    { value: "CIT001", label: "Identifiant" },
                    { value: "CIT002", label: "TVA" },
                  ]}
                />
              </SearchFormInputBloc>
            </Grid>
            <Grid item xs={2}>
              {/* Identifiant */}
              <SearchFormInputBloc label="" error={errors.name}>
                <Controller
                  control={control}
                  name="identifier"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="identifier-id"
                      variant="outlined"
                      error={!!errors.id}
                      fullWidth
                    />
                  )}
                />
              </SearchFormInputBloc>
            </Grid>
            <Grid item xs={3}>
              {/* Activités */}
              <SearchFormInputBloc label={i18next.t("thirdpartySearch.majorActivity")}>
                <EAAutoCompleteInput
                  fieldName="majorActivities"
                  options={dnbMajorOptions}
                  placeholder={"Tous"}
                  variant="outlined"
                  multiple
                />
              </SearchFormInputBloc>
            </Grid>
            <Grid item xs={2}>
              {/* Contacts */}
              <SearchFormInputBloc label={i18next.t("Contacts")}>
                <EAAutoCompleteInput
                  fieldName="contacts"
                  options={registrationStatusOptions}
                  placeholder={"Tous"}
                  variant="outlined"
                  multiple
                  limitTags={1}
                />
              </SearchFormInputBloc>
            </Grid>
          </Grid>
          <div
            className={classes.advancedSearchContainer}
            style={{ display: isAdvancedSearchShown() ? "block" : "none" }}
          >
            <Fade in={isAdvancedSearchShown()} timeout={500}>
              <div>
                <ThirdpartiesAdvancedSearch
                  induedOptions={induedOptions}
                  transparencyOptions={transparencyOptions}
                  financialStrengthScoreOptions={financialStrengthScoreOptions}
                  financialStrengthRatingOptions={financialStrengthRatingOptions}
                  filters={filters}
                />
              </div>
            </Fade>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="end"
            mr={0}
            mt={3.5}
            ml="auto"
          >
            <Box display="flex">
              <AdvancedSearchButtons handleReset={handleReset} />
            </Box>
            <Box
              display="flex"
              onClick={advancedSearchClickHandler}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <span className={classes.advancedSearchToggle}>
                {isAdvancedSearchShown()
                  ? i18next.t("simpleSearch")
                  : i18next.t("advancedSearch")}
              </span>
              <EAIcon
                icon={`arrow/keyboard_arrow_${
                  isAdvancedSearchShown() ? "up" : "down"
                }`}
                className={classes.advancedSearchIcon}
              />
            </Box>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};
