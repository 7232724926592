import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: "white",
    height: "275px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    gap: "15px",
    borderRadius: "0 12px 12px 12px"
  },
  cell: {
    width: "100%",
    display: "flex",
    height: "48px",
    borderRadius: "12px",
    // first
    "&:nth-child(1)": {
      opacity: 0.9,
    },
    "&:nth-child(2)": {
      opacity: 0.7,
    },
    "&:nth-child(3)": {
      opacity: 0.5,
    },
    "&:nth-child(4)": {
      opacity: 0.3,
    },
    
  },
  contentWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "9px",
    padding: "9px",
    backgroundColor: theme.palette.grey.grey100,
    borderRadius: "0 12px 12px 12px",
    opacity: 0.7,
  },
}));

export default useStyles;
