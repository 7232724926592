import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.primary100,
    borderRadius: "6px",
    padding: "12px 18px",
    lineHeight: "140%",
    maxWidth: "500px",
    maxHeight: "89px",
    minWidth: "150px"
  },
  rootDO: {
    maxWidth: "500px",
    maxHeight: "89px",
    minWidth: "150px",
    opacity: "0.6",
  },
  thirdTitle: {
    color: "#373534",
    opacity: "50%",
    fontFamily: "Metric",
    fontWeight: 600,
    fontSize: "14px",
    position: "relative",
    top: "-2px",
  },
  thirdTitlePart: {
    position: "relative",
    top: "-2px",
  },
  thirdName:{
    color: "#2E2E3A",
    fontFamily: "Metric",
    fontWeight: 600,
    fontSize: "20px",
    position: "relative",
    top: "-2px",
    lineHeight: "120%"
  },
  thirdActivity:{
    color: theme.palette.primary.primary400,
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "120%",
    position: "relative",
  },
  mr:{
    marginRight: "10px"
  },
  ml:{
    marginLeft: "10px",
    position: "relative",
    top: "-2px"
  },
  spreadTool: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
  },
}));

export default useStyles;
