import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    dossierInfosForm: {
        height: "100%",
        "& > div": {
            height: "100%",
            "& .dossierBtns": {
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
            },
        },
    },
    archiveBtn: {
        marginRight: "0.5rem",
    },
    auditBtnLoading: {
        cursor: "wait !important",
    },
    modifyBtn: {
        marginRight: "15px",
    },
    iconImg: {
        marginRight: "5px",
    },
}));

const archiveBtnCustomStyle = {
    height: "42px",
    position: "relative",
    top: "-2px",
    boxShadow:
      "0px 11.28px 24.231px rgba(154, 154, 175, 0.115017), 0px 6.0308px 12.9551px rgba(154, 154, 175, 0.0953772), 0px 3.38082px 7.2625px rgba(154, 154, 175, 0.08), 0px 1.79553px 3.85706px rgba(154, 154, 175, 0.0646228), 0px 0.747159px 1.60501px rgba(154, 154, 175, 0.0449833)",
}

export { useStyles, archiveBtnCustomStyle };
