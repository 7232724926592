import React from "react";
import { Box, Paper } from "@mui/material";
import useStyles from "../../style/js-style/containers/doc-viewer/DocViewerCardStyle";

const DocViewerCardTitle = ({items}) => {
  const { classes } = useStyles();
    return (
        <div className={classes.header}>
            {items?.map((item, index) => (
                <span
                  key={index}
                  className={index === 0 ? classes.cardTitle : classes.cardMore}
                >
                    {item}
                </span>
            ))}
        </div>
    )
}

const DocViewerCard = ({title, body, extractorResults}) => {
    const { classes } = useStyles();
    return (
        <Paper elevation={0} className={classes.root}>
            <Box display="flex" alignItems="center" className={classes.title}>
                <DocViewerCardTitle items={title}/>
            </Box>
            {body}
            {extractorResults}
        </Paper>
    )
}
export default DocViewerCard;
