import { Paper } from "@mui/material";
import TitleWithIcon from "components/common/TitleWithIcon";
import EADataGrid from "components/common/datatable/EADataGrid";
import i18next from "i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./ListImportsStyle";
import { importsListColumns } from "../importsUtils";
import { getFileImportThirdParties, getListImportThirdParties, getRapportImportThirdParties } from "services/edge/ThirdpartyService";
import { Context } from "states/Store";
import { ERROR } from "services/common/Constants";
import { RoleBasedAccessControlContext } from "services/common/RolesUtils";
import InvitationImportDialog from "../InvitationImportDialog/InvitationImportDialog";

const ListImports = ({lastImportId}) => {
    const { classes } = useStyles();

    const [state, dispatch] = useContext(Context);
    const {hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

    const [isLoading, setIsLoading] = useState(false);

    const [listImports, setListImports] = useState([]);

    const [openInvitationDialog, setOpenInvitationDialog] = useState(false);

    const [importForInvitation, setImportForInvitation] = useState(null);

    //---- LOAD DATA IMPORT ------------
    const getListImport = useCallback(async () => {
      try {
        setIsLoading(true);
        const listData = await getListImportThirdParties(state.account.id, dispatch);
        setListImports(listData.content.map((data, i) => {
          return {
            ...data, 
            id: data.name + "-"+i
          }
        }));
        setIsLoading(false);

    } catch (error) {
        if (
            error?.message?.messages?.error &&
            error?.message?.messages?.error[0]?.code
          ) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: i18next.t(`infos.${error.message.messages.error[0].code}`)},
            });
          } else {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "TP_CODE_EDIT_ERROR" },
            });
          }
    }
    }, [dispatch, state.account.id]);

    useEffect(() => {
      getListImport();
    }, [getListImport, lastImportId])

    const invitationHandler = (importId) => {
      setOpenInvitationDialog(true)
      setImportForInvitation(importId)
    }

    //download file import 
    const uploadFileHandler = async (importId) => {
      const {filename, arrayBuffer} = await getFileImportThirdParties(state.account.id, importId, dispatch)
      const blob = new Blob([arrayBuffer]);
      
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = filename
      a.click()
    }

    const uploadRapportImportHandler = async (importId) => {
      const {filename, arrayBuffer} = await getRapportImportThirdParties(state.account.id, importId, dispatch);

      const blob = new Blob([arrayBuffer]);
      
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = filename
      a.click()

    }


    return(
        <>
      <Paper elevation={0} className={classes.dataGridWrapper}>
        {
          <EADataGrid
          loading={isLoading}
          inputRows={listImports}
          columns={importsListColumns(hasAnyOfPrivileges, invitationHandler, uploadFileHandler, uploadRapportImportHandler)}
          initialVisibilityModel={{
            name: true, 
            filename: false,
            type: false, 
            numberAdded: true, 
            numberUpdated:  true, 
            numberArchived: true, 
            numberErrors: true, 
            numberWarnings: true, 
            importUserName: false, 
            importDate: true,
            invitationUserName : false, 
            invitationDate: true, 
            statut: true, 
            action: true
          }}
          hasCustomPagination
          additionnalsLeftComponents={<TitleWithIcon
            icon="assignment_green"
            title={i18next.t("imports.titleSummary")}
            resultCount={listImports.length}
            isLoading={isLoading}
            style={{marginLeft: "18px"}}
          />}
        />
        }
      </Paper>

      <InvitationImportDialog isOpen={openInvitationDialog} onClose={() => setOpenInvitationDialog(false)} importId={importForInvitation}/>
      </>
    )
};
export default ListImports