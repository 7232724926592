import i18next from "i18next";
import { cellWithTooltip, dateValueFormatter } from "../../../components/common/datatable/EADataGrid";
import DataTableActions from "../../../components/common/datatable/DataTableActions";
import React from "react";
import { PrivilegeEnum } from "../../../services/common/RolesUtils";

const calculationTableActions = (
  calculationId,
  active,
  openEditDialog,
  openDeleteDialog,
  onToggleStatus,
  hasAnyOfPrivileges
) => {
  return [
    {
      label: i18next.t("modify"),
      icon: "edit_grey",
      action: () => openEditDialog(calculationId),
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_PARAMETERS),
    },
    {
      label: active ? i18next.t("rules.table.columns.actions.disable") : i18next.t("rules.table.columns.actions.enable"),
      icon: active ? "pause_circle_filled" : "play_circle_filled",
      action: () => { onToggleStatus(calculationId) },
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS),
    },
    {
      label: i18next.t("delete"),
      icon: "delete_grey",
      action: () => openDeleteDialog(calculationId),
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_CALCULATION),
    }
  ];
};

export const calculationListColumns = (openEditDialog, openDeleteDialog, toggleStatus, hasAnyOfPrivileges) => {
  return [
    {
      field: "name",
      headerName: i18next.t("calculator.table.columns.name"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "nature",
      headerName: i18next.t("calculator.table.columns.nature"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value),
    },
    {
      field: "user",
      headerName: i18next.t("calculator.table.columns.by"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "creationDate",
      headerName: i18next.t("calculator.table.columns.dateCreation"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => dateValueFormatter(params),
    },
    {
      field: "description",
      headerName: i18next.t("calculator.table.columns.description"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <DataTableActions
              actions={calculationTableActions(
                params.row.id,
                params.row.enabled,
                openEditDialog,
                openDeleteDialog,
                toggleStatus,
                hasAnyOfPrivileges
              )}
            />
          </div>
        );
      },
    },
  ];
};

export const FORM_ID = {
  create: "calculation-create-form-id",
  edit: "calculation-edit-form-id",
  delete: "calculation-delete-form-id",
};
