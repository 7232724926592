import React, { useCallback, useContext, useEffect, useState } from "react";
import i18next from "i18next";
import _, { find } from "lodash";
import countries from "i18n-iso-countries";

import { Context } from "states/Store";
import { SUCCESS, ERROR, WARNING } from "services/common/Constants";
import EADataGrid from "components/common/datatable/EADataGrid";
import DataTableButton from "components/common/datatable/DataTableButtons";

import { getRules, toggleRuleStatus } from "services/edge/RuleService";
import { getAccountAttributesByBondType } from "services/edge/AttributesService";
import { getAccountCalculations } from "../../../services/edge/CalculatorService";
import { hasOption } from "services/common/Utils";
import { rulesListColumns } from "./RulesDataTableUtils";
import RuleCreateDialog from "./dialogs/RuleCreateDialog";
import RuleEditDialog from "./dialogs/RuleEditDialog";
import RuleDeleteDialog from "./dialogs/RuleDeleteDialog";

import useStyles from "style/js-style/containers/administration/rules/RulesDataTableStyle";
import { RuleActionType, RulesContext, getCriterionFieldOptions, isExistingRuleToActivateHaveErrors } from "services/common/RulesUtils";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import {CalculationNature} from "../../../services/common/CalculatorUtils";
import { getListActivity } from "services/edge/ListService";
import { FINANCIAL_STRENGTH_SCORES } from "services/common/DataBlocksUtils";


const MetaInfoHeader = ({ length }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.headerMetaInfo}>
      <span className={classes.headerMetaInfoBold}>
        {length > 1
          ? i18next.t("rules.insight1Multiple", { activeRulesCount: length })
          : i18next.t("rules.insight1", { activeRulesCount: length })
        }
      </span>
      {/* <span> {i18next.t("rules.concern")} </span>

      <EAIcon icon={"identifier"} className={classes.headerIcon}/>

      <span> {length} </span>
      <span className={classes.headerMetaInfoBold}>
        {i18next.t("thirdParty")}
      </span> */}
    </div>
  )
}

function RulesDataTable() {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const [loader, setLoader] = useState(true);

  const [rulesList, setRulesList] = useState([]);
  const [completeRulesList, setCompleteRulesList] = useState([]);

  // TODO: Uncomment when multiple rules deletion is working
  // const [rulesSelectedIds, setRulesSelectedIds] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [accountCountries, setAccountCountries] = useState([]);

  const [accountActivities, setAccountActivities] = useState([]);

  const [financialStrengthRatings, setFinancialStrengthRatings] = useState([]);
  const [financialStrengthScores, setFinancialStrengthScores] = useState([]);

  const [accountAttributes, setAccountAttributes] = useState([]);
  const [accountCalculatedAttributes, setAccountCalculatedAttributes] = useState([]);
  const [accountIndicators, setAccountIndicators] = useState([]);
  const [dossierTypes, setDossierTypes] = useState([]);
  const [actionTypes, ] = useState(Object.values(RuleActionType).map((val) => {
    return {
      value: val,
      label: i18next.t(`rules.configuration.ruleActions.types.${val}`)
    }
  }));


  const [criterionFieldOptions, setCriterionFieldOptions] = useState();

  const getTargetedThirpartiesCount = (ruleId) => {
    /*Fake data: make an api call instead*/
    return "6 (10%)";
  }

  const fetchRules = useCallback(async () => {
    try {
      setLoader(true);

      const results = await getRules(state.account.id, null);

      setCompleteRulesList(results.content);

      const mappedResults = results.content.map((entry) => {
        return {
          ...entry,
          id: entry.id,
          name: entry.name,
          thirdParties: getTargetedThirpartiesCount(entry.id),
          status: entry.enabled ? "active" : "inactive",
        };
      });

      // Order list by rule name
      let orderedResults = mappedResults.sort(
        (a, b) => a.name.localeCompare(b.name)
      );

      setRulesList(orderedResults);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      setRulesList([]);
    }
  }, [state.account, dispatch]);

  useEffect(() => {
    if (state.account) {
      async function fetchData() {
        try {
          const resp = await getListActivity(undefined, dispatch);
          setAccountActivities(resp.content.map(activity => {
            return {
              value: activity.code, 
              label: activity.descriptionComplete.trim(),
              level: activity.level
            }
          }).sort((a, b) => a.label.localeCompare(b.label)));

          let distinctCountries = [];
          let allCountries = countries.getNames(i18next.language, {select: "official"});
          for (const [key, value] of Object.entries(allCountries)) {
            distinctCountries.push({"value": key, "label": value});
          }
          const sortedMap = Array.from(distinctCountries).sort((a, b) => a.label.localeCompare(b.label));
          setAccountCountries(sortedMap);

        } catch (e) {
          dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
        }
      }

      fetchData();
    }
  }, [dispatch, state.account]);

  const fetchAttributes = useCallback(async () => {
    try {
      const results = await getAccountAttributesByBondType(state.account.id, 'thirdparty', null);
      const filteredAttributes = results.content.filter(r => r.type !== 'string');
      setAccountAttributes(filteredAttributes);
    } catch (error) {
      setAccountAttributes([]);
    }
  }, [state.account]);

  const fetchCalculations = useCallback(async () => {
    try {
      const results = await getAccountCalculations(state.account.id, null);
      let calculatedAttributes = [];
      let indicators = [];

      results.content.forEach( calcul => {
        if (calcul.nature === CalculationNature.INDICATOR){
          indicators.push({
            value: calcul.id,
            label: calcul.name,
            type: calcul.resultType
          });
        }
        if (calcul.nature === CalculationNature.CALCULATED_ATTRIBUTE){
          calculatedAttributes.push({
            value: calcul.id,
            label: calcul.name,
            type: calcul.resultType
          });
        }
      })

      setAccountCalculatedAttributes(calculatedAttributes);
      setAccountIndicators(indicators);
    } catch (error) {
      setAccountCalculatedAttributes([]);
      setAccountIndicators([]);
    }
  }, [state.account]);

  useEffect(() => {
    if (!_.isEmpty(state.referenceData)) {
      setFinancialStrengthRatings(
        state.referenceData.financialStrengthRating
          ?.map((x) => ({
            label: x.rating,
            value: x.rating,
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label))
      );
      setFinancialStrengthScores(
        state.referenceData.financialStrengthScore
          ?.filter(x => x.code !== FINANCIAL_STRENGTH_SCORES.VERY_HIGH) // We don't show 'very high' as for now, and it's treated as 'high'
          ?.map(x => ({
            label: i18next.t(`datablocks.financialStrength.severity.${x.code?.toLowerCase()}`),
            value: x.code,
          }))
      );
    }
  }, [state.referenceData])

  useEffect(() => {
    if (state.account) {
      fetchAttributes();
      fetchCalculations();
    }
  }, [state.account, fetchAttributes, fetchCalculations])


  const displayTransparency = useCallback(() => {
    return hasOption(state.accounts, state.account.id, "TRANSPARENCY");
  }, [state.account, state.accounts]);

  const disableTransparency = useCallback(() => {
    return !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE);
  }, [hasAnyOfPrivileges]);

  const displayFinancialStrength = useCallback(() => {
    return hasOption(state.accounts, state.account.id, "financialStrength");
  }, [state.account, state.accounts]);

  const disableFinancialStrength = useCallback(() => {
    return !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE);
  }, [hasAnyOfPrivileges]);

  useEffect(() => {
    if (accountCountries && accountActivities && accountAttributes && accountCalculatedAttributes &&
        accountIndicators && financialStrengthScores && financialStrengthRatings
    ) {
      let criterionFieldOptions = getCriterionFieldOptions(
        accountCountries,
        accountActivities,
        accountAttributes,
        accountCalculatedAttributes,
        accountIndicators,
        financialStrengthScores,
        financialStrengthRatings
      );
      let filteredCriterion = criterionFieldOptions;

      // Display / disable transparency
      if (!displayTransparency()) {
        filteredCriterion = criterionFieldOptions.filter(c => c.value !== "transparency_score");
      } else if (disableTransparency()) {
        filteredCriterion = filteredCriterion.map((c) => ({...c, isDisabled: c.value === "transparency_score"}));
      }

      // Display / disable financial Strength
      if (!displayFinancialStrength()) {
        filteredCriterion = filteredCriterion.filter(c => c.value !== "financial_strength_score" && c.value !== "financial_strength_rating");
      } else if (disableFinancialStrength()) {
        filteredCriterion = filteredCriterion.map((c) => ({
          ...c,
          isDisabled: c.value === "financial_strength_score" || c.value === "financial_strength_rating"
        }));
      }
      setCriterionFieldOptions(filteredCriterion);
    }
  }, [
    accountCountries, accountActivities, accountAttributes, accountCalculatedAttributes,
    accountIndicators, financialStrengthScores, financialStrengthRatings,
    displayTransparency, disableTransparency, displayFinancialStrength, disableFinancialStrength
  ])

  useEffect(() => {
    if (state.dossierTypes && !_.isEmpty(state.dossierTypes)) {
      const mappedDossierTypes = state.dossierTypes.map((dt) => {
        return {
          value: `${dt.code}`,
          label: dt.libelle
        }
      });

      setDossierTypes(mappedDossierTypes);
    }
  }, [state.dossierTypes]);

  useEffect(() => {
    if (state.account) {
      fetchRules();
    }
  }, [state.account, fetchRules]);

  const openDeleteDialog = (ruleId) => {
    setIsDeleteDialogOpen(true);
    setSelectedRules(
      /**
       * Actually, there is no need to filter here as only one single rule can be deleted.
       * */
      rulesList.filter(
        (rule) => rule.id === ruleId
      )
    );
  };

  const openEditDialog = (ruleId) => {
    setIsEditDialogOpen(true);
    setSelectedRules([
      rulesList.find((rule) => rule.id === ruleId),
    ]);
  };

  const onToggleRuleStatus = async (ruleId) => {
    try {
      let ruleToUpdate = find(completeRulesList, { id: ruleId });
      if (!ruleToUpdate.enabled && isExistingRuleToActivateHaveErrors(ruleToUpdate?.actions)) {
        openEditDialog(ruleToUpdate.id)
      } else {
        await toggleRuleStatus(state.account.id, ruleId, null);
        dispatch({
          type: "ALERT",
          alert: {
            type: ruleToUpdate.enabled ? WARNING : SUCCESS,
            msg: i18next.t(`rules.notifications.${ruleToUpdate.enabled ? "deactivate" : "activate"}`, { name: `${ruleToUpdate.name}` })
          }
        });
        fetchRules();
      }
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  };

  const context = React.useMemo(
    () => ({
      dossierTypes: dossierTypes,
      actionTypes: actionTypes.filter(action => {
        if(action.value.includes("FINANCIAL_STRENGTH")) {
          return hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE)
          && hasOption(state.accounts, state.account.id, "FINANCIALSTRENGTH")
        }
        return true;
      })
    }),
    [dossierTypes, actionTypes, hasAnyOfPrivileges, state.accounts, state.account.id]
  );

  return (
    <RulesContext.Provider value={context}>
      {/* Header */}
      <div className={classes.headerWrapper}>
        <MetaInfoHeader length={rulesList.filter((entry => !!entry.enabled)).length} />
        <DataTableButton
          onClickHandler={() => setIsCreateDialogOpen(true)}
          label={i18next.t("rules.createButtonLabel")}
          disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_RULE)}
        />
      </div>

      <div className={classes.separate} />

      {/* DataGrid */}
      <div className={classes.dataGridWrapper}>
        <EADataGrid
          loading={loader}
          inputRows={rulesList}
          columns={rulesListColumns(openEditDialog, openDeleteDialog, onToggleRuleStatus, hasAnyOfPrivileges)}
          hasCustomPagination
          additionnalsLeftComponents={<div className={classes.dataGridTitle}>{i18next.t("rules.datagridTitle")}</div>}
          pageSize={10}
        />
      </div>

      {/* PopUp : on create */}
      {isCreateDialogOpen && criterionFieldOptions && (
        <RuleCreateDialog
          isOpen={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onValidateSuccess={() => {
            setIsCreateDialogOpen(false);
            fetchRules();
          }}
          criterionFieldOptions={criterionFieldOptions}
        />
      )}

      {/* PopUp : on edit */}
      {isEditDialogOpen && criterionFieldOptions && (
        <RuleEditDialog
          rule={find(completeRulesList, { id: selectedRules[0].id })}
          isOpen={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onValidateSuccess={() => {
            setIsEditDialogOpen(false);
            fetchRules();
          }}
          onDeleteOpen={() => setIsDeleteDialogOpen(true)}
          criterionFieldOptions={criterionFieldOptions}
        />
      )}

      {/* PopUp : on delete */}
      {isDeleteDialogOpen && (
        <RuleDeleteDialog
          rule={selectedRules[0]}
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onValidateSuccess={() => {
            setIsDeleteDialogOpen(false);
            setIsEditDialogOpen(false);
            fetchRules();
          }}
        />
      )}
    </RulesContext.Provider>
  );
}

export default RulesDataTable;
