import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import EAIcon from "./EAIcon";
import { getDossierStatusFromCode } from "../../services/common/DocumentUtils";

const DossierStatusChip = ({ statusCode }) => {
    const [docStatus, setDocStatus] = useState();

    useEffect(() => {
        setDocStatus(getDossierStatusFromCode(statusCode));
    }, [statusCode])
    return (
        <Box display="flex">
            {docStatus && (
                <EAIcon icon={docStatus.icon} style={{width: "40px"}}/>
                    
            )}
        </Box>
    )
}
export default DossierStatusChip;
