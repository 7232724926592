import React from "react";
import DataTableActions from "components/common/datatable/DataTableActions";
import i18next from "i18next";
import {
  cellWithTooltip,
  dateValueFormatter,
} from "components/common/datatable/EADataGrid";
import MainHeaderCell from "components/common/datatable/MainHeaderCell";
import { PrivilegeEnum } from "services/common/RolesUtils";

const editableValue = [
  "list_unique",
  "list_multiple"
]

const attributesTableActions = (
  attributes,
  openEditDialog,
  openDeleteDialog,
  hasAnyOfPrivileges,
  bondType,
  type
) => {
  return [
    {
      label: i18next.t("modify"),
      icon: "edit_grey",
      action: () => openEditDialog(attributes[0]),
      isDisabled:
        bondType === "dossier"
          ? (!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_DOSSIER_ATTRIBUTE)
            && !(hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE) && editableValue.includes(type[0])))
          : (!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY_ATTRIBUTE)
            && !(hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE) && editableValue.includes(type[0])))
    },
    {
      label: i18next.t("delete"),
      icon: "delete_grey",
      action: () => openDeleteDialog(attributes),
      isDisabled:
        bondType === "dossier" ?
          !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE)
          :
          !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE)
    },
  ];
};

export const attributesListColumns = (openEditDialog, openDeleteDialog, hasAnyOfPrivileges, bondType) => {
  return [
    {
      field: "label",
      headerName: i18next.t("attributes.label"),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("attributes.label")} />
      ),
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "typeLabel",
      headerName: i18next.t("attributes.type"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, false),
    },
    {
      field: "creationDate",
      headerName: i18next.t("creationDate"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => dateValueFormatter(params),
    },
    {
      field: "updateDate",
      headerName: i18next.t("updateDate"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => dateValueFormatter(params),
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="dt" style={{ textAlign: "center" }}>
            <DataTableActions
              actions={attributesTableActions(
                [params.row.id],
                openEditDialog,
                openDeleteDialog,
                hasAnyOfPrivileges,
                bondType,
                [params.row.type],
              )}
            />
          </div>
        );
      },
    },
  ];
};
