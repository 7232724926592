import React, { useEffect } from "react";
import { Divider, Grid } from "@mui/material";
import EASelectInput from "../../../../../components/inputs/EASelectInput";
import { useFieldArray, useFormContext } from "react-hook-form";
import { isEmpty, find } from "lodash";
import { uniqWith } from "lodash";
import useStyles from "../ThirdpartiesListSearchFormStyle";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";

const getCalculationOptions = (calculation) => {
  return uniqWith(calculation?.branches, (a, b) => a.resultValue === b.resultValue)
          .sort((a,b) => {
            if(b.default) return -1;
            if(a.default) return 1;
            return a.resultValue.localeCompare(b.resultValue)
          })
          .map((x) => ({
            value: x.resultValue,
            label: x.resultValue,
          }))
};

export const ThirdpartiesSearchCalculatedAttributes = ({
  calculation,
  fieldName
}) => {
  return (
    <Grid item xs={3}>
      <SearchFormInputBloc label={calculation?.name} hasTooltip disableTranslation>
        <EASelectInput
          fieldName={fieldName}
          options={getCalculationOptions(calculation)}
          translateOptions={false}
          defaultOption={"Tous"}
        />
      </SearchFormInputBloc>
    </Grid>
  );
};
export const ThirdpartiesSearchIndicators = ({ indicators }) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const { fields, append } = useFieldArray({
    control: formMethods.control,
    name: "indicators",
  });

  useEffect(() => {
    if (isEmpty(fields)) {
      indicators?.sort((a,b) => a.name.localeCompare(b.name))?.forEach((calc) => {
        append({ id: calc.name, values: "" });
      });
    }
  }, [indicators, fields, append]);

  return (
    !isEmpty(indicators) && (
      <>
        <Divider className={classes.advancedSearchDivider} style={{marginBottom: 0}} />
        {fields.map((field, index) => {
          const fieldCalcId = formMethods.getValues(`indicators.${index}.id`);
          const calculation = find(indicators, { name: fieldCalcId });
          return calculation && (
            <Grid item xs={3} key={field.id}>
              <SearchFormInputBloc label={calculation.name} hasTooltip disableTranslation>
                <EASelectInput
                  fieldName={`indicators.${index}.values`}
                  options={getCalculationOptions(calculation)}
                  translateOptions={false}
                  defaultOption={"Tous"}
                />
              </SearchFormInputBloc>
            </Grid>
          );
        })}
      </>
    )
  );
};
