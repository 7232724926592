import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import _ from "lodash";
import { getTooltip } from "../../../services/common/ChartUtils";
import { EALegendChart } from "./EALegendChart";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import useSelectedAccountId from "../hooks/useSelectedAccountId";
import { localizeTranslate } from "i18n/LocalizeUtils";

const barSize = 55;

function EABarXYChart({ id, data, tab, seriesItems }) {
  const chartRef = useRef();
  const [shownSeries, setShownSeries] = useState([]);
  const [state, dispatch] = useContext(Context);
  const accountId = useSelectedAccountId();

  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    if (data && !_.isEmpty(data)) {
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panY",
          layout: root.verticalLayout,
          paddingLeft: 0,
        })
      );

      const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "categoryField",
          renderer: am5xy.AxisRendererY.new(root, {
            minGridDistance: 20,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
          fixAxisSize: true,
        })
      );
      yAxis.labelsContainer.set("tooltip", getTooltip(chart));

      const longestLabelLength = data?.reduce((a, b) => a.categoryField.length > b.categoryField.length ? a : b)?.categoryField?.length;
      const labelMaxWidth = (longestLabelLength * 7) < 300 ? (longestLabelLength * 7) + 10 : 300;
      const yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.setAll({
        fill: am5.color("#7E7E8F"),
        fontSize: "12px",
        textAlign: "left",
        fontWeight: "400",
        oversizedBehavior: "truncate",
        maxWidth: labelMaxWidth,
        minWidth: labelMaxWidth,
        tooltipText: "{categoryField}",
      });
      yRenderer.labels.template.setup = function (target) {
        target.set(
          "background",
          am5.Rectangle.new(root, {
            fill: am5.color(0x000000),
            fillOpacity: 0,
          })
        );
      };

      yAxis.data.setAll(data);

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: 100,
          numberFormat: "#'%'",
          strictMinMax: true,
          calculateTotals: true,
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      );
      const xRenderer = xAxis.get("renderer");

      xRenderer.labels.template.setAll({
        fill: am5.color("#7E7E8F"),
        fontSize: "12px",
      });

      chart.appear(1000, 100);

      chart.xAxes.getIndex(0);
      chartRef.current = chart;
    }

    return () => root?.dispose();
  }, [id, data]);

  const makeSeries = useCallback(
    (serieItem) => {
      const shouldCreateSerie = data?.filter(x => x[serieItem.value] > 0)?.length > 0;
      if (data && accountId && shouldCreateSerie) {
        const root = chartRef.current.root;
        const series = chartRef.current.series.push(
          am5xy.ColumnSeries.new(root, {
            name: serieItem.label,
            stacked: true,
            xAxis: chartRef.current.xAxes.getIndex(0),
            yAxis: chartRef.current.yAxes.getIndex(0),
            baseAxis: chartRef.current.yAxes.getIndex(0),
            valueXField: serieItem.value,
            valueXShow: "valueXTotalPercent",
            categoryYField: "categoryField",
          })
        );
        series.columns.template.setAll({
          toggleKey: "active",
          interactive: true,
        });
        series.set("tooltip", getTooltip(chartRef.current));

        series.columns.template.setAll({
          tooltipText: `${localizeTranslate('Nombre')} : {formatted.${serieItem.value}}/{formatted.nbTotal}\n${localizeTranslate('Pourcentage :')} {valueXTotalPercent.formatNumber('#.#')}%`,
          tooltipY: am5.percent(90),
          strokeOpacity: 0,
          fill: am5.color(serieItem.settings.fill),
          cursorOverStyle: "pointer",
        });

        series.columns.template.states.create("hover", {
          fillOpacity: 0.5,
        });
        series.columns.template.states.create("active", {
          fillOpacity: 1,
          fill: am5.color("#FFC90B"),
        });
        series.columns.template.events.on("click", (ev) => {
          const rectangle = ev.target;
          const selectedData = rectangle.dataItem.dataContext;
          const valueXField = rectangle.dataItem.component.get("valueXField");
          const active = !rectangle.get("active");
          dispatch({
            type: active ? "ADD_GRAPH_FILTER" : "REMOVE_GRAPH_FILTER",
            filter: {
              type: "bar",
              accountId,
              tab,
              data: {
                id: `${selectedData.categoryFieldCode}-${valueXField}`,
                label: i18next.t(
                  `dashboard.${selectedData.type}.status.${valueXField}`
                ),
                keyLabel: selectedData.categoryField,
                value: selectedData[valueXField],
              },
            },
          });
        });

        series.events.on("datavalidated", (ev) => {
          let series = ev.target;
          let chart = series.chart;
          let xAxis = chart.xAxes.getIndex(0);

          // Calculate how we need to adjust chart height
          let chartHeight =
            series.data.length * barSize +
            // series.data.length * (series.data.length === 1 ? 55 : 55) +
            xAxis.height() +
            chart.get("paddingTop", 0) +
            chart.get("paddingBottom", 0);

          // Set it on chart's container
          chart.root.dom.style.height = chartHeight + "px";
        });

        series.data.setAll(data);

        series.appear();

        series.bullets.push(() => {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              oversizedBehavior: "hide",
              fontSize: "11px",
              fontWeight: "700",
              text: "{valueX}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true,
            }),
          });
        });
        setShownSeries((prevState) => [...prevState, series]);
      }
    },
    [data, accountId, tab, dispatch]
  );

  useEffect(() => {
    setShownSeries([]);
    if(seriesItems) {
      seriesItems.forEach((serieItem) => {
        makeSeries(serieItem);
      });
    }
  }, [makeSeries, seriesItems]);

  // Set active selected items.
  useEffect(() => {
    if (data && !_.isEmpty(data)) {
      const series = chartRef.current.series;
      series?.values?.forEach((column) => {
        const valueXField = column.get("valueXField"); // ex: nbPresent
        column.dataItems.forEach((item) => {
          const valueYField = item.dataContext.categoryFieldCode; // document's code
          const rectangle = item.get("graphics");
          const label = item.bullets?.[0]?.get("sprite");
          if (state.graphFilters.bar?.length > 0) {
            const allBars = state.graphFilters.bar.find(
              (filter) => filter.id === `all-${valueXField}`
            );
            // If all bars of valueXField => activate them all
            if (allBars) {
               rectangle?.set("active", true);
                label?.set("fill", am5.color("#373534"))
            } else {
              // check if specific bar is in filter => activate/deactivate it
              const isActive = !!state.graphFilters.bar.find(
                (filter) => filter.id === `${valueYField}-${valueXField}`
              );
              rectangle?.set("active", isActive);
              if(isActive) {
                label?.set("fill", am5.color("#373534"))
              } else {
                label?.set("fill", am5.color("#FFF"))
              }
            }
          } else {
            // If no filter is active => deactivate all graph
            rectangle?.set("active", false);
            label?.set("fill", am5.color("#FFF"));
          }
        });
      });
    }
    // chartRef dependency needed to rerun effect when rectangle is set to set Active filters from session storage
  }, [data, chartRef.current?.series, state.graphFilters.bar]);

  return (
    <>
      <div
        style={{
          height: "500px",
        }}
      >
        <div
          style={{
            height: "450px",
            overflowY: data?.length > 8 ? "auto" : "hidden",
            overflowX: "hidden",
            display: "flex",
            alignItems: data?.length > 8 ? "flex-start" : "center",
          }}
        >
          <div
            id={id}
            style={{ width: "100%" }}
            onWheel={(e) => e.stopPropagation()}
          ></div>
        </div>
        <EALegendChart
          id={id + "-legend"}
          chartType="barXY"
          series={shownSeries}
          tab={tab}
        />
      </div>
    </>
  );
}

export default EABarXYChart;
