import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    mainRoot: {
        backgroundColor: "white",
        padding: "6px",
        borderRadius: "0 12px 12px 12px",
        transition: "all 0.3s",
        "&:hover": {
            boxShadow: "rgb(0 0 0 / 9%) 0px 25px 20px -20px",
            transition: "all 0.3s",
        },
    },
    root: {
        padding: "0",
        backgroundColor: theme.palette.grey.grey,
        height: "260px",
        overflowX: "hidden",
        boxShadow: "inset 0px 0px 16px 0px rgba(0,0,0,0.1)",
        borderRadius: "0 8px 8px 8px",
    },
    rootNoAttribute: {
        backgroundColor: theme.palette.grey.grey100,
        height: "260px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    noAttributeText: {
        fontWeight: 600,
        color: theme.palette.grey.grey400,
        fontSize: "18px",
    },
    noAttributeImg: {
        width: "42px",
        marginBottom: "15px",
    },
    attributeItemWrapper: {
        display: "flex",
        flexDirection: "row",
        margin: "10px",
        borderRadius: "12px",
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        minHeight: "52px",
    },
    attributeItemLabel: {
        backgroundColor: theme.palette.primary.primary200,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
        padding: ".5rem 1rem",
        borderRadius: "12px 0 0 12px",
        width: "300px",
    },
    attributeItemLabelDossier: {
        backgroundColor: "#EBFAEE",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
        padding: ".5rem 1rem",
        borderRadius: "12px 0 0 12px",
        width: "300px",
    },
    attributeName: {
        fontSize: "16px",
        color: "#373534",
        fontWeight: 700,
        fontFamily: "Metric",
        position: "relative",
    },
    attributeNameDossier: {
        fontSize: "16px",
        color: "#373534",
        fontWeight: 700,
        fontFamily: "Metric",
        position: "relative",
        left: "-10px",
    },
    attributeItemImg:{
        position: "relative",
        top: "2px",
    },
    breakWord:{
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        display: "-webkit-box",
        wordBreak: "break-all",
    },
    attributeItemDate: {
        padding: ".5rem 1rem",
        backgroundColor: theme.palette.primary.primary100,
        minWidth: "97px",
    },
    attributeItemDateDossier: {
        padding: ".5rem 1rem",
        backgroundColor: "#F7FDF8",
        minWidth: "97px",
    },
    attributeItemDateModifyLabel: {
        color: theme.palette.grey.grey400,
        fontSize: "12px",
        fontWeight: 400,
    },
    attributeItemDateModifyDate: {
        color: theme.palette.grey.grey700,
        fontSize: "12px",
        fontWeight: 600,
    },
    attributeItemValues: {
        padding: ".5rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
        backgroundColor: "white",
        borderRadius: "0 12px 12px 0",
    },
    shownValues:{
        fontSize: "12px",
        color:  theme.palette.black.dark,
    },
    noDataRootDossier: {
        backgroundColor: "white",
        padding: "12px",
        borderRadius: "0 12px 12px 12px",
    },
    noDataRootThird: {
        backgroundColor: "white",
        padding: "12px",
        marginRight: "16px",
        borderRadius: "0 12px 12px 12px",
    },
    attributesItemColor: {
        display: "inline-block",
        width: "22px",
        height: "22px",
        border: "1px solid white",
        borderRadius: "4px",
        marginRight: "6px",
        position: "relative",
        top: "6px"
    },
}));

export default useStyles;
