import EAIcon from "components/common/EAIcon";
import useStyles from "../../style/js-style/containers/loader/LoaderStyle";
import React, { useEffect } from "react";
import i18next from "i18next";
import ROUTES from "config/Routes";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

const Loader = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const lastVisited = localStorage.getItem('lastVisited')

  const redirectType = {
    SUCCESS: {
      type: 'success',
      loadingTime: 0,
      message: 'loader.anwerSuccessful',
      subMessage: 'loader.subAnwerSuccessful',
      icon: 'valid-rounded'
    },
    SIGN_ERROR: {
      type: 'error',
      loadingTime: 3,
      message: 'loader.signError',
      subMessage: 'loader.subSignError',
      icon: 'nonconforme'
    },
    SIGN_SUCCESS: {
      type: 'success',
      loadingTime: 7,
      message: 'loader.signSuccessful',
      subMessage: 'loader.subSignSuccessful',
      icon: 'signature_success_file'
    }
  }
  const hash = window.location.hash.split('#')[1];

  const [countDown, setCountDown] = React.useState(redirectType[hash]?.loadingTime || 0);

  useEffect(() => {
    if (countDown === 0) {
      if (lastVisited) {
        history.push(lastVisited);
      } else {
        history.push(ROUTES.DASHBOARD);
      }
    }
  }, [countDown, history, lastVisited])

  setTimeout(() => {
    if (countDown > 0) {
      setCountDown(countDown - 1);
    }
  }, 1000);

  return (
    <div className={classes.root}>
      {countDown > 0 && <div className={clsx(classes.loaderContainer, redirectType[hash]?.type === 'success' ? classes.loaderRootSuccess : classes.loaderRootError)}>
        <div className={classes.signatureSuccessContainer}>
          <div className={classes.iconWrapper}>
            <EAIcon icon={redirectType[hash]?.icon} className={classes.signatureSuccessIcon} />
          </div>
          <div className={clsx(classes.signatureSuccessTitle, redirectType[hash]?.type === 'error' && classes.signatureErrorTitle)}>{i18next.t(redirectType[hash]?.message)}</div>
        </div>

        <div class="dots-loader">
          {i18next.t(redirectType[hash]?.subMessage, { time: countDown })}
        </div>

      </div>}
    </div>
  );
}

export default Loader;
