import React, { useContext, useEffect, useState } from "react";
import { Alert, Collapse, IconButton, Snackbar } from "@mui/material";
import { Context } from "../states/Store";
import useStyles from "../style/js-style/components/EASnackBarStyle";
import i18next from "i18next";
import EASpinner from "./common/EASpinner";
import EAIcon from "./common/EAIcon";
import { ERROR, INFO, SUCCESS, WARNING } from "../services/common/Constants";
import clsx from "clsx";
import { isEmpty } from "services/common/Utils";

/**
 * EAIcon component with severity icon
 * @param severity ERROR | INFO | SUCCESS
 */
const EAAlertIcon = ({ severity }) => {
  switch (severity) {
    case ERROR: return <EAIcon icon={"error-rounded"} />;
    case INFO: return <EAIcon icon={"info-rounded"} />;
    case SUCCESS: return <EAIcon icon={"valid-rounded"} />;
    case WARNING: return <EAIcon icon={"warning-snack"} />;
    default: return;
  }
}

/**
 * EAIcon component with close icon
 * @param severity ERROR | INFO | SUCCESS
 */
const EAAlertCloseIcon = ({ severity }) => {
  switch (severity) {
    case ERROR: return <EAIcon icon={"close_red"} />;
    case INFO: return <EAIcon icon={"arrow/arrow-to-right_large_green"} />;
    case SUCCESS: return <EAIcon icon={"close_green"} />;
    case WARNING: return <EAIcon icon={"close_yellow"} />;
    default: return;
  }
}

const EASnackBar = () => {
  const [state, dispatch] = useContext(Context);
  const [alertOpen, setAlertOpen] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    setAlertOpen(true);
  }, [state.alert]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const formatErrorMsg = (msg) => {
    if (React.isValidElement(msg)) {
      return msg;
    } else if (Array.isArray(msg)) {
      return (
        <>
          {msg.map((item, index) => {
            if (typeof item === "object") {
              return formatErrorMsg(item);
            } else {
              let listErrors = item.split(":").filter(s => !isEmpty(s) && s !== "null");
              if (listErrors.length > 1) {
                return (
                  <div key={index}>
                    {i18next.t("infos." + listErrors[0])}
                    <ul>
                      {listErrors[1].split("\\").map(m => (<li className="dt" key={index}>{m}</li>))}
                    </ul>
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    {formatErrorMsg(listErrors[0])}
                  </div>
                )
              }
            }
          })}
        </>
      );
    } else if (typeof msg === "object") {
      if (msg?.code) {
        return i18next.t("infos." + msg.code) === "infos." + msg.code
          ? i18next.t(msg.message)
          : i18next.t("infos." + msg.code);
      }

      return formatErrorMsg(msg?.error);
    }

    return i18next.t("infos." + msg) === "infos." + msg
      ? i18next.t(msg)
      : i18next.t("infos." + msg);
  };

  return (
    state.alert && (
      <Collapse in={!!state.alert}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={1500}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className={clsx(classes.snackbar, `̀classes.snackbar-${state.alert.type}`)}
        >
          <Alert
            severity={state.alert.type}
            icon={<EAAlertIcon severity={state.alert.type} />}
            onClose={handleAlertClose}
            action={
              <IconButton
                style={{ marginLeft: "28px" }}
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch({ type: "ALERT", alert: null });
                }}
              >
                <EAAlertCloseIcon severity={state.alert.type} />
              </IconButton>
            }
          >
            {formatErrorMsg(state.alert.msg)}
            {state.alert.spinner && <EASpinner />}
          </Alert>
        </Snackbar>
      </Collapse>
    )
  );
};

export default EASnackBar;
