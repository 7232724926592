import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {

    padding: "5px 0 5px 50px",
    position: "relative",
  },
  separate: {
    borderBottom: "1px solid #E2E2EA",
  },
  noteHeader:{
    display: "flex",
    fontFamily: "Roboto",
    marginBottom: "8px"
  },
  name:{
    color: "#373534",
    fontSize: "12px",
    fontWeight: 500,
    marginRight: "6px",
  },
  date: {
    color: "#9A9AAF",
    fontSize: "9px",
    fontWeight: 400,
    position: "relative",
    top: "3px"
  },
  content: {
    color: "#656575",
    fontSize: "12px",
    // fontWeight: 600,
  },
  controlWrapper: {
    position: "absolute",
    top: "5px",
    right: "0",
  },
}));

export default useStyles;
