import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import { getTooltip, pickHex } from "../../../services/common/ChartUtils";
import EAIcon from "../EAIcon";
import useStyles from "../../../style/js-style/components/common/charts/EAMapChartStyle";
import clsx from "clsx";
import { Context } from "../../../states/Store";
import i18next from "i18next";
import useSelectedAccountId from "../hooks/useSelectedAccountId";

function EAMapChart({ mapData, map, type, tab }) {
  const [state, dispatch] = useContext(Context);
  const chartRef = useRef();
  const seriesRef = useRef();
  const templateRef = useRef();
  const { classes } = useStyles();
  const accountId = useSelectedAccountId();

  useLayoutEffect(() => {
    const root = am5.Root.new("mapChart");
    if (accountId) {
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: "translateX",
          panY: "translateY",
          projection: am5map.geoMercator(),
        })
      );

      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: map,
          valueField: "value",
          calculateAggregates: true,
          exclude: ["AQ"],
        })
      );

      polygonSeries.set("tooltip", getTooltip(chart));

      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name} : {formattedValue}",
        toggleKey: "active",
        interactive: true,
        cursorOverStyle: "pointer",
      });

      polygonSeries.mapPolygons.template.states.create("hover", {
        fillOpacity: 0.5,
      });
      polygonSeries.mapPolygons.template.states.create("active", {
        fillOpacity: 1,
        fill: am5.color("#FFC90B"),
      });

      polygonSeries.mapPolygons.template.events.on("click", (ev) => {
        const polygon = ev.target;
        const active = !polygon.get("active");
        const selectedData = polygon.dataItem.dataContext;
        dispatch({
          type: active ? "ADD_GRAPH_FILTER" : "REMOVE_GRAPH_FILTER",
          filter: {
            type: "map",
            accountId,
            tab,
            data: {
              id: selectedData.id,
              label: selectedData.name,
              keyLabel: i18next.t(`dashboard.filters.${type}`),
            },
          },
        });
      });

      // Set clicking on "water" to zoom out
      chart.chartContainer.get("background").events.on("click", function () {
        chart.goHome();
      });

      polygonSeries.appear(1000);
      chart.appear(1000, 100);

      chartRef.current = chart;
      seriesRef.current = polygonSeries;
      templateRef.current = polygonSeries.mapPolygons.template;
    }

    return () => root && root.dispose();
  }, [dispatch, accountId, tab, map, type]);

  useEffect(() => {
    if (seriesRef.current && mapData?.data && accountId) {
      seriesRef.current.data.setAll(mapData.data);
      seriesRef.current.set("heatRules", [
        {
          target: seriesRef.current.mapPolygons.template,
          dataField: "value",
          key: "fill",
          customFunction: (sprite, min, max, value) => {
            if (value === 0) {
              sprite.set("fill", am5.color(0xc6cbd9));
            } else {
              const percent =
                Math.round((value / mapData.highestValue) * 100) / 100;
              const color = pickHex([19, 37, 69], [30, 136, 229], percent); //maxColor: #132545  minColor: #1E88E5
              sprite.set("fill", am5.color(`rgb(${color.join(",")})`));
            }
          },
        },
      ]);

      // --- Zoom to single data country --- //
      seriesRef.current.events.on("datavalidated", (ev) => {
        chartRef.current.goHome();
        setTimeout(() => {
          const dataWithValues = mapData.data.filter((x) => x.value !== 0);
          var series = ev.target;
          if (dataWithValues.length === 1) {
            const singleDataItem = series.dataItems.find(
              (x) => x.dataContext.id === dataWithValues[0].id
            );
            series.zoomToDataItem(singleDataItem);
          } else {
            chartRef.current.goHome();
          }
        }, 100);
      });
    }
  }, [mapData, accountId]);

  // Set active selected items.
  useEffect(() => {
    if (mapData && accountId) {
      const dataItems = chartRef.current?.series.values[0].dataItems;
      dataItems?.forEach((item) => {
        const polygon = item.get("mapPolygon");
        const isActive = !!state.graphFilters.map?.find(
          (x) => x.id === item.dataContext.id
        );
        polygon.set("active", isActive);
      });
    }
  }, [mapData, accountId, state.graphFilters.map]);

  const handleZoomIn = () => chartRef.current.zoomIn();
  const handleZoomOut = () => chartRef.current.zoomOut();
  const handleCenterFocus = () => chartRef.current.goHome();

  return (
    <>
      <div className={classes.root}>
        <div
          onClick={handleZoomIn}
          className={clsx(classes.iconWrapper, classes.resizeWrapper)}
        >
          <EAIcon icon="map-zoomIn" />
        </div>
        <div
          onClick={handleZoomOut}
          className={clsx(classes.iconWrapper, classes.resizeWrapper)}
        >
          <EAIcon icon="map-zoomOut" />
        </div>
        <div
          onClick={handleCenterFocus}
          className={clsx(classes.iconWrapper, classes.cropWrapper)}
        >
          <EAIcon icon="map-center" />
        </div>
      </div>
      <div id="mapChart" className={clsx(type === 'department' ? "dt" : "", classes.mapChart)}></div>
    </>
  );
}

export default EAMapChart;
