import React from "react";
import { Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import i18next from "i18next";

const StatusChip = ({status, label}) => {
    const isActive = status === "active";

    return (
        <Chip
            className={isActive ? "" : "greyChip"}
            icon={isActive ? <CheckIcon style={{backgroundColor: "#D7F4DC", borderRadius: "50%"}}/> : <CloseIcon style={{backgroundColor: "#DADADA", borderRadius: "50%"}}/>}
            size="small"
            label={i18next.t(label + "."+ status)}
            color={isActive ? "primary": "default"}
            variant="outlined"
        />
    )
}
export default StatusChip;