import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reasonSelect: {
    "& .MuiSelect-root": {
      background: "white",
      padding: "0.25rem 0.75rem 0.375rem 0.625rem",
    },
  },
  menuItem: {
    minHeight: "24px",
  },
  labelInputRole: {
    minWidth: "329px",
    maxWidth: "512px",
    marginBottom: "20px",
    padding: "9px 4px !important",
    marginTop: "14px",
    color: theme.palette.grey.grey500,
    fontWeight: 700,
    border: "none",
  },
  subTitle:{
    textAlign: "center",
    marginTop: "9px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainContentHeaderPopUp: {
    gap: "20px",
    marginTop: "18px",
    marginBottom: "25px"
  },
  imgWrapper: {
    backgroundColor: theme.palette.grey.grey100,
    padding: "1rem",
    borderRadius: "50%",
    width: "45px",
    height: "45px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imgContent: {
    width: "35px",
    height: "32px",
  },
  emailInfo: {
    color: theme.palette.grey.grey500,
    fontSize: "12px",
  },
  userName: {
    fontSize: "14px",
    color: "#373534"
  },
  errorText:{
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "-16px",
    marginBottom: "0",
    marginLeft: "14px",
    color: "#FF0001",
  },
}));

export default useStyles;
