import React from "react";
import i18next from "i18next";
import { MenuItem } from "@mui/material";
import useStyles from "style/js-style/containers/users/users-list/UsersDataTableStyle";
import EAIcon from "components/common/EAIcon";

export const DossierModelActionMenu = ({
  dossierModelSelected,
  onOpenDeleteDialog,
  onChangeNature,
  onChangeActive
}) => {
  const { classes } = useStyles();
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState(false);

  const handleClick = () => {
    setActionMenuIsOpen(!actionMenuIsOpen);
  };

  return (
    <div
      className={dossierModelSelected.length < 1 ? classes.dnone : classes.actionMenuWrapper}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick} >
      <div className={classes.actionMenuTitle}>
        {i18next.t("actions")}
        <EAIcon
          icon={!actionMenuIsOpen ? "arrow/arrow-to-down_small" : "arrow/arrow-to-up_small"}
          className={classes.titleArrow}
        />
      </div>

      <div className={actionMenuIsOpen ? "" : classes.dnone}>
        <div className={classes.actionMenuSeparate} />
        <MenuItem
          className={classes.actionMenuItem}
          disabled={false}
          onClick={() => onChangeActive(dossierModelSelected, true)}>
          {i18next.t("demandes.dossierModel.tableAction.activate")}
        </MenuItem>
        <MenuItem
          className={classes.actionMenuItem}
          disabled={false}
          onClick={() => onChangeActive(dossierModelSelected, false)}>
          {i18next.t("demandes.dossierModel.tableAction.deactivate")}
        </MenuItem>
        <MenuItem
          className={classes.actionMenuItem}
          disabled={false}
          onClick={() => onChangeNature(dossierModelSelected, true)}>
          {i18next.t("demandes.dossierModel.tableAction.lock")}
        </MenuItem>
        <MenuItem
          className={classes.actionMenuItem}
          disabled={false}
          onClick={() => onChangeNature(dossierModelSelected, false)}>
          {i18next.t("demandes.dossierModel.tableAction.unlock")}
        </MenuItem>
        {/* For now, we can't delete a model */}
        {/* <MenuItem
          className={classes.actionMenuItem}
          disabled={false}
          onClick={() => onOpenDeleteDialog(dossierModelSelected)}>
          {i18next.t("demandes.dossierModel.tableAction.delete")}
        </MenuItem> */}
      </div>
    </div>
  );
};
