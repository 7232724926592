import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Context } from 'states/Store';
import { buildDocumentsFilters, buildDossiersFilters, buildThirdpartiesFilters } from 'services/common/ChartUtils';
import useSelectedAccountId from 'components/common/hooks/useSelectedAccountId';
import { isEmpty } from "lodash";

export function useListFormFilters(component) {
  const location = useLocation();
  const [formFilters, setFormFilters] = useState();
  const [graphFilters, setGraphFilters] = useState();
  const [state] = useContext(Context);
  const accountId = useSelectedAccountId();

  const buildComponentGraphFilters = useCallback(
    (savedGraphFilters) => {
      let result;
      switch (component) {
        case 'thirdparties-list':
          result = buildThirdpartiesFilters(savedGraphFilters);
          if(!isEmpty(result.indued) || !isEmpty(result.transparency) || !isEmpty(result.financialStrengthScore)) {
            result["isAdvancedSearchShown"] = true;
          }
          break;
        case 'dossiers-list':
          result = { dossiers: buildDossiersFilters(savedGraphFilters) };
          break;
        case 'documents-list':
          result = { documents: buildDocumentsFilters(savedGraphFilters) };
          break;
        default:
          result = {};
      }
      return result;
    },
    [component]
  );

  // Set graphFilters for the page
  useEffect(() => {
    if (location.state?.showChartFilters) {
      setGraphFilters(buildComponentGraphFilters(state.graphFilters));
    } else {
      setGraphFilters({});
    }
  }, [location, state.graphFilters, buildComponentGraphFilters]);

  const saveSessionFormFilters = useCallback((filters) => {
    sessionStorage.setItem(`${accountId}-${component}-filters`, JSON.stringify(filters));
    setFormFilters(filters);
  }, [accountId, component]);

  // Set Form Filters from graph Filters and Session saved filters
  useEffect(() => {
    if (graphFilters) {
      if(!isEmpty(graphFilters)) {
        saveSessionFormFilters(graphFilters);
      } else {
        const savedSessionFilters = JSON.parse(sessionStorage.getItem(`${accountId}-${component}-filters`));
        saveSessionFormFilters(savedSessionFilters || {});
      }
    }
  }, [accountId, component, graphFilters, saveSessionFormFilters]);

  return {
    formFilters,
    saveSessionFormFilters,
  };
}
