import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { frFR as dataGridBgBG } from "@mui/x-data-grid";
import { bgBG as coreBgBG } from "@mui/material/locale";
import { frFR as pickersBgBG } from "@mui/x-date-pickers";

// todo search using and change values in theme.palette...
export const infoColor = "#214093";
export const missingColor = "#919193";
export const lightGrey2 = "rgba(55, 53, 52, 0.9);";
export const lightPrimary = "#BFD8B8";
export const orangeColor = "#FF9A00"; // warning500
export const purpleColor = "#6D00CC"; 
export const errorColor = "#FF0001";  // error500
export const primary = "#2D9F40";     // primary600
export const darkGrey = "#656575";    // grey600

export const drawerWidth = 250; // size of lateral menu (extend)
export const ReducedDrawerWidth = 55; // size of lateral menu (reduce)

const theme365 = createTheme(
  {
    // ---- Breakpoints
    breakpoints: {
      values: {
        xs: 0,
        popupxs: 544,
        sm: 600,
        popupsm: 624,
        md: 900,
        popupmd: 858,
        lg: 1200,
        popuplg: 1054,
        xl: 1536,
        popupxl: 1353,
        inch16: 1536, // 16 inch screen
      },
    },
    // ---- Colors
    palette: {
      primary: {
        primary100: "#e5f6e8",
        primary200: "#D7F4DC",
        primary300: "#B0E8B9",
        primary400: "#60D273",
        primary500: "#3FC855",
        primary600: "#2D9F40",
        main: "#2D9F40",
        primary700: "#227730",
        primary800: "#0B2810",
        primary900: "#18260D",
      },
      grey: {
        grey100: "#F2F3F7",
        grey200: "#E2E2EA",
        grey300: "#C6CBD9",
        grey400: "#9A9AAF",
           main: "#9A9AAF", // grey400
        grey500: "#7E7E8F",
        grey600: "#656575",
        grey700: "#535362",
        grey800: "#2E2E3A",
        grey900: "#16161E",
      },
      warning: {
        warning100: "#FFF7EB",
        warning200: "#FFEBCC",
        warning300: "#FFBB54",
        warning400: "#FFAE33",
        warning500: "#FF9A00",
              main: "#FF9A00", // warning500
        warning600: "#CC7B00",
        warning700: "#995C00",
        warning800: "#663D00",
        warning900: "#331F00",
      },
      error: {
        error100: "#FFEBEB",
        error200: "#FFCCCC",
        error300: "#FF999A",
        error400: "#FF3F40",
        error500: "#FF0001",
            main: "#FF0001", // error500
        error600: "#CC0001",
        error700: "#990001",
        error800: "#660001",
        error900: "#330000",
      },
      black: {
        dark: "#373534"
      },
      background: {
        white: "#FFF",
        greyLight: "#F2F3F7", // grey-100
        default: "#F9F9FB", // grey-100
      },
    },

    // ---- Typography
    typography: {
      h1: {
        fontSize: "28px",
        fontWeight: 600,
        color: "#2E2E3A", // grey800
        fontFamily: "Metric"
      },
      h2: {
        fontSize: "25px",
        fontWeight: 600,
        color: "#2E2E3A", // grey800
        fontFamily: "Metric"
      },
      h3: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#2E2E3A", // grey800
        fontFamily: "Metric"
      },
      h4: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#2E2E3A", // grey800
        fontFamily: "Metric"
      },
      h5: {
        fontSize: "18px",
        fontWeight: 600,
        color: "#2E2E3A", // grey800
        fontFamily: "Metric"
      },
      h6: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#2E2E3A", // grey800
        fontFamily: "Metric"
      },
      subtitle1: {
        color: lightGrey2,
        fontSize: "0.8rem",
        textTransform: "uppercase",
      },
      subtitle2: {
        color: lightGrey2,
        fontSize: "0.9rem",
        fontWeight: "normal",
      },
      textOverflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },

    // MUI Components
    components: {
      MuiTabs: {
        styleOverrides: {
          root:{
            "& .MuiTabs-indicator": {
              backgroundColor: "white",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)": {
              padding: "7px 12px",
            },
            "& ::placeholder": {
              color: "#9A9AAF !important",
              opacity: 1,
              fontSize: "14px",
              fontWeight: 400
            },
          },
        },
      },
      MuiAlert: {
        // Overriding Alert info from default blue to primary green
        styleOverrides: {
          root: {
            padding: "8px 16px",
          },
          standardInfo: {
            color: primary,
            backgroundColor: lightPrimary,
            "& .MuiAlert-icon": {
              color: primary,
            },
          },
          outlinedInfo: {
            color: primary,
            borderColor: primary,
            "& .MuiAlert-icon": {
              color: primary,
            },
          },
          filledInfo: {
            backgroundColor: primary,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&.errorChip": {
              color: errorColor,
              borderColor: errorColor,
              "& svg": {
                color: errorColor,
              },
            },
            "&.infoChip": {
              color: infoColor,
              borderColor: infoColor,
              "& svg": {
                color: infoColor,
              },
            },
            "&.greyChip": {
              color: missingColor,
              borderColor: missingColor,
              "& svg": {
                color: missingColor,
              },
            },
            "& .label": {
              paddingLeft: "0 !important",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              position: "absolute",
            },
          }
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            zIndex: 1300,
          },
          paper: {
            borderRadius: "12px",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: darkGrey,
            color: "white",
            textAlign: "center",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "1.5rem !important",
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: darkGrey,
            whiteSpace: "nowrap",
          },
        },
      },
      // input datePicker
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            display: "flex",
            flexDirection: "row-reverse",
            borderRadius: "12px",
            border: "1px solid #E2E2EA", // grey200
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#9A9AAF", // grey400
          },
          "& .Mui-Focused": {
            margin: "0 !important",
          }
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              margin: 0,
            }
          },
        },
      },
      // Pagination
      MuiTablePagination: {
        styleOverrides: {
          root: {
            width: "110%",
            overflow: "hidden !important",
            "& .MuiInputBase-root": {
              marginLeft: 0,
            },
          },
          toolbar: {
            padding: "0 !important",
          },
          selectLabel: {
            position: "absolute",
            left: "58px",
            color: "#9A9AAF", // grey400
            fontSize: "12px",
            fontFamily: "Metric",
            fontWeight: 400,
          },
          select: {
            backgroundColor: "#E2E2EA", // grey200
            borderRadius: "16px",
            marginLeft: 0,
            color: "#7E7E8F",
            fontWeight: 700,
          },
          displayedRows: {
            backgroundColor: "#E2E2EA", // grey200
            borderRadius: "16px",
            padding: "0.4rem",
            position: "relative",
            right: "-12px",
            color: "#7E7E8F",
          },
          actions: {
            position: "relative",
            right: "-12px",
          },
        },
      },
      // DataGrid
      MuiDataGrid: {
        styleOverrides: {
          // meta info : selected line(s) in data grid
          selectedRowCount: {
            display: "none",
          },
          // header of datagrid
          columnHeadersInner: {
            borderRadius: "12px 12px 0 0 !important",
          },
          columnHeaderTitleContainer: {
            justifyContent: "flex-start !important"
          },
          // separator column
          columnSeparator: {
            opacity: "1 !important",
          },
          // data item
          cell: {
            justifyContent: "flex-start !important",
            outline: "none !important",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "space-around",
            padding: "0 1rem 1rem 1rem",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            padding: "0 9px 0 0"
          },
          input: {
            padding: "7px 12px"
          }
        }
      }
    },
  },
  {
    components: {
      // x-date-pickers translations
      MuiLocalizationProvider: {
        defaultProps: {
          localeText: {
            ...pickersBgBG.components.MuiLocalizationProvider.defaultProps
              .localeText,
            clearButtonLabel: "Supprimer",
          },
        },
      },
      // x-data-grid translations
      MuiDataGrid: {
        defaultProps: {
          localeText: {
            ...dataGridBgBG.components.MuiDataGrid.defaultProps.localeText,
            filterOperatorIsAnyOf: "fait partie de",
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "Export"
          },
        },
      },
    },
  },
  coreBgBG // core translations
);

export const theme = responsiveFontSizes(theme365);

// ---- Button TODO a supprimer une fois tout les btn remplacer
export const button = {
  xs: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 600,
    padding: "6px 10px",
    minWidth: "76px",
    minHeight: "28px",
    borderRadius: "10px",
    fontFamily: "Metric",
    lineHeight: "12px",
    border: "none",
  },
  small: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 600,
    padding: "8px 12px",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "12px",
    fontFamily: "Metric",
    lineHeight: "12px",
    border: "none",
  },
  regular: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    padding: "11px 16px",
    minWidth: "97px",
    minHeight: "40px",
    borderRadius: "16px",
    fontFamily: "Metric",
    lineHeight: "14px",
    border: "none",
  },
  large: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 800,
    padding: "12.5px 21px",
    minWidth: "126px",
    minHeight: "50px",
    borderRadius: "18px",
    fontFamily: "Metric",
    lineHeight: "18px",
    border: "none",
  },
};

export const noDatas = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  paddingBottom: "30px",
  fontSize: "20px",
};

export const dataGridSx = {
  border: "none",
  padding: "18px 18px 0 18px",
  "&.clickable": {
    "& .MuiDataGrid-dataContainer": {
      cursor: "pointer",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none !important",
    "& .MuiDataGrid-rowCount": {
      display: "none",
    },
  },
  // N'affiche pas le dernier séparateur de colonne
  "& .MuiDataGrid-columnHeader:last-child" :{
    "& .MuiDataGrid-columnSeparator": {
      display: "none"
    },
  },
  "& .MuiDataGrid-columnHeaderDraggableContainer[draggable=true]": {
    cursor: "grab !important",
  },
  "& .MuiDataGrid-columnHeaderDraggableContainer[draggable=true]:hover": {
    cursor: "grab !important",
  },
  "& .MuiDataGrid-columnHeader--sorted": {
    "& .MuiDataGrid-sortIcon": {
      opacity: "1 !important",
    },
  },
  "& .MuiDataGrid-sortIcon": {
    opacity: "0.5 !important",
  },
  "& .MuiDataGrid-menuIcon": {
    opacity: 0.5,
    visibility: "visible",
    width: "auto",
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
  },
  "& .MuiDataGrid-main": {
    borderRadius: "12px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.07)",
    overflow: "hidden",
  },
  "& .MuiDataGrid-columnHeadersInner": {
    background: theme365.palette.grey.grey100,
    color: theme365.palette.grey.grey400,
    fontSize: "12px",
  },
  "& .MuiDataGrid-cell--withRenderer > *": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiDataGrid-cell--withRenderer": {
    "& .MuiTypography-root": {
      fontSize: "12px !important",
    },
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#EFFBF1 !important", // primary100
  },
  "& .MuiDataGrid-row:nth-of-type(odd)":{
    background: "white",
    fontSize: "12px !important",
  },
  "& .MuiDataGrid-row:nth-of-type(even)":{
    background: "linear-gradient(0deg, rgba(245, 245, 245, 0.3), rgba(245, 245, 245, 0.3)), #FFFFFF",
    fontSize: "12px !important",
  },
  "& .MuiDataGrid-row:nth-of-type(even):hover":{
    background: "rgba(245, 245, 245, 0.8)",
  },
  "& .MuiDataGrid-row:nth-of-type(odd):hover":{
    background: "rgba(245, 245, 245, 0.8)",
  },
};
