import React, { useContext, useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import EASelectInput from "../../../../../components/inputs/EASelectInput";
import {
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { isEmpty, find, get as lodashGet } from "lodash";
import i18next from "i18next";
import { Context } from "states/Store";
import {
  ATTRIBUTE_MAX_TEXT_VALUE_LENGTH,
  AttributeType,
  ERROR,
} from "services/common/Constants";
import EADatePicker from "components/common/EADatePicker";
import {
  getAttributeMaxLengthRule,
  validateAttributeInputForType,
} from "services/common/AttributeUtils";
import { getAccountAttributesByBondType } from "services/edge/AttributesService";
import { ThirdpartiesSearchCalculatedAttributes } from "./ThirdpartiesSearchCalculations";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";

const getAttributeOptions = (attribute) => {
  const options = [];
  if (attribute.type === AttributeType.BOOLEAN) {
    options.push(
      { value: "true", label: i18next.t("yes") },
      { value: "false", label: i18next.t("no") }
    );
  }
  if (
    [AttributeType.LIST_MULTIPLE, AttributeType.LIST_UNIQUE].includes(
      attribute.type
    )
  ) {
    options.push(
      ...attribute?.values?.map((x) => ({ label: x.value, value: x.value }))
    );
  }
  options.sort((a,b) => a.label.localeCompare(b.label))
  options.push({ value: "notSpecified", label: i18next.t("notSpecified") })
  return options;
};

export function ThirdpartiesSearchAttributes({ calculatedAttributes }) {
  const [state, dispatch] = useContext(Context);
  const [attributes, setAttributes] = useState();
  const [bothAttributesFields, setBothAttributesFields] = useState();
  const formMethods = useFormContext();

  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          const res = await getAccountAttributesByBondType(
            state.account.id,
            "thirdparty",
            dispatch
          );
          setAttributes(res.content);
        } catch (e) {
          dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
          setAttributes([]);
        }
      })();
    }
  }, [state.account, dispatch]);

  const { fields: attributeFields, append: attributeAppend } = useFieldArray({
    control: formMethods.control,
    name: "attributes",
  });

  const {
    fields: calculatedAttributesFields,
    append: calculatedAttributesAppend,
  } = useFieldArray({
    control: formMethods.control,
    name: "calculatedAttributes",
  });

  useEffect(() => {
    if (isEmpty(attributeFields)) {
      attributes?.forEach((attr) => {
        attributeAppend({
          id: attr.label,
          values: "",
          type: "attributes",
          name: attr.label,
        });
      });
    }
  }, [attributes, attributeFields, attributeAppend]);

  useEffect(() => {
    if (isEmpty(calculatedAttributesFields)) {
      calculatedAttributes?.forEach((calc) => {
        calculatedAttributesAppend({
          id: calc.name,
          values: "",
          type: "calculatedAttributes",
          name: calc.name,
        });
      });
    }
  }, [
    calculatedAttributes,
    calculatedAttributesFields,
    calculatedAttributesAppend,
  ]);

  useEffect(() => {
    setBothAttributesFields(
      [...attributeFields, ...calculatedAttributesFields].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    );
  }, [attributeFields, calculatedAttributesFields]);

  return (
    <>
      {/* Attributes */}
      {
        //!isEmpty(bothAttributesFields) &&
        bothAttributesFields?.map((field) => {
          let currentFieldIndex;
          if (field.type === "attributes") {
            currentFieldIndex = attributeFields?.findIndex(
              (attrField) => attrField.name === field.name
            );
          } else {
            currentFieldIndex = calculatedAttributesFields?.findIndex(
              (calcField) => calcField.name === field.name
            );
          }

          if (field.type === "attributes") {
            // Render thirdparty attributes
            const attribute = find(attributes, { label: field.name });
            return (
              attribute && (
                <Grid item xs={3} key={field.id}>
                  <SearchFormInputBloc
                    label={attribute.label}
                    hasTooltip
                    disableTranslation
                  >
                    {(() => {
                      const fieldName = `attributes.${currentFieldIndex}.values`;
                      switch (attribute.type) {
                        case AttributeType.BOOLEAN:
                        case AttributeType.LIST_MULTIPLE:
                        case AttributeType.LIST_UNIQUE:
                          return (
                            <EASelectInput
                              fieldName={fieldName}
                              options={getAttributeOptions(attribute)}
                              translateOptions={false}
                              defaultOption={"Tous"}
                            />
                          );
                        case AttributeType.DATE:
                          return (
                            <Controller
                              control={formMethods.control}
                              name={fieldName}
                              render={({ field }) => (
                                <EADatePicker
                                  {...field}
                                  formMethods={formMethods}
                                  onlyDate
                                  ref={null}
                                  sxClass={{
                                    "& input::placeholder": {
                                      color: "#9A9AAF !important",
                                      opacity: 1,
                                    }
                                  }}
                                />
                              )}
                            />
                          );
                        default:
                          return (
                            <Controller
                              control={formMethods.control}
                              name={fieldName}
                              rules={{
                                maxLength: getAttributeMaxLengthRule(
                                  "theValue",
                                  ATTRIBUTE_MAX_TEXT_VALUE_LENGTH
                                ),
                                validate: (value) =>
                                  validateAttributeInputForType(
                                    attribute.type,
                                    value
                                  ),
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="attribute-value-id"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Rechercher"
                                  error={
                                    !!lodashGet(
                                      formMethods.formState.errors,
                                      fieldName
                                    )
                                  }
                                  helperText={
                                    !!lodashGet(
                                      formMethods.formState.errors,
                                      fieldName
                                    )
                                      ? lodashGet(
                                          formMethods.formState.errors,
                                          fieldName
                                        ).message
                                      : ""
                                  }
                                />
                              )}
                            />
                          );
                      }
                    })()}
                  </SearchFormInputBloc>
                </Grid>
              )
            );
          } else {
            // Render calculation
            const calculation = find(calculatedAttributes, {
              name: field.name,
            });
            return calculation && (
              <ThirdpartiesSearchCalculatedAttributes
                key={field.id}
                calculation={calculation}
                fieldName={`calculatedAttributes.${currentFieldIndex}.values`}
              />
            );
          }
        })
      }
    </>
  );
}
