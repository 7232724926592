import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  summaryHeader: {
    display: "flex",
    alignItems: "center"
  },
  headerPart: {
    fontSize: "12px",
    marginRight: "16px",
    marginLeft: "2px"
  },
  smallIcon: {
    width: "12px",
    height: "12px"
  },
  documentName: {
    fontSize: "30px",
    fontWeight: 600,
    fontFamily: "Metric",
    position: "relative",
    top: "-2px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
  },
}));

export default useStyles;
