import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: "64px",
  },
  backgroundOverlay: {
    position: "absolute",
    top: "204px",
    left: 0,
    right: "40px",
    bottom: 0,
    zIndex: 0,
    backgroundColor: "#F9F9FB",
  },
  mainHeaderWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.492) 0%, rgba(255, 255, 255, 0.48) 110.84%)",
    border: "1.5px solid white",
    borderRadius: "12px",
    padding: "18px",
    marginBottom: "18px",
    height: "98px"
  },
  mainHeaderLeftSide:{
    display: "flex",
    alignItems: "center",
    gap: "0 1rem",
  },
  title: {
    marginBottom: "22px",
  },
  createBtn: {
    marginLeft: "8px",
    textTransform: "none",
    borderRadius: "12px",
    fontWeight: 600,
    fontSize: "12px",
    boxShadow: "0px 11.28px 24.231px rgba(45, 159, 64, 0.115017), 0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772), 0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08), 0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228), 0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833)",

    "& .MuiButton-startIcon": {
      marginBottom: "4px",
    },
  },
  crossBtn: {
    fontSize: "1rem",
    fontWeight: 500,
    marginRight: ".5rem",
  },
  separate: {
    borderTop: "1px solid #E2E2EA",
    width: "100%",
    height: "1px",
    marginTop: "2rem",
  },
  userCounterWrapper: {
    display: "flex",
    alignItems: "center",
  },
  metaCountImg: {
    marginRight: "6px",
    width: "15px",
  },
  metaCountContent: {
    fontWeight: 700,
    color: theme.palette.grey.grey400,
    fontSize: "14px",
  },
  deleteAllBtn: {
    position: "absolute",
    left: "164px",
    top: "1.2rem",
    backgroundColor: theme.palette.grey.grey200,
    color: theme.palette.grey.grey600,
    fontWeight: 600,
    fontSize: "12px",
    borderRadius: "12px",
    display: "flex",
    gap: ".5rem"
  },
  deleteAllBtnDisabled: {
    display: "none",
  },
  reinvitAllBtn: {
    position: "absolute",
    left: "364px",
    top: "1.2rem",
    backgroundColor: theme.palette.grey.grey200,
    color: theme.palette.grey.grey600,
    fontWeight: 600,
    fontSize: "12px",
    borderRadius: "12px",
    display: "flex",
    gap: ".5rem"
  },

  actionMenuWrapper: {
    position: "absolute",
    top: "-10px",
    right: "-157px",
    zIndex: 5,
    border: "1px solid " + theme.palette.grey.grey200,
    borderRadius: "6px",
    backgroundColor: "white",
    cursor: "pointer",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    minWidth: "150px",

    "&:hover": {
      border: "1px solid " + theme.palette.primary.primary300,

      dnone:{
        display: "block",
      }
    },
  },
  actionMenuTitle: {
    fontWeight: 700,
    color: theme.palette.grey.grey500,
    padding: "10px 15px",
    fontSize: "12px",
  },
  actionMenuItem: {
    fontWeight: 400,
    color: theme.palette.grey.grey500,
    padding: ".5rem 1rem",
    fontSize: "14px",

    "&:hover": {
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary500,
      borderRadius: "0 0 6px 6px ",
    },
  },
  actionMenuSeparate: {
    width: "100%",
    borderTop: "0.5px solid " + theme.palette.grey.grey100,
    margin: 0,
    padding: 0,
    position: "relative",
    top: "-1px",
  },
  dnone: {
    display: "none",
  },
  multipleMenuItem: {
    position: "absolute",
    backgroundColor: "white",
  },
  titleArrow: {
    position: "absolute",
    top: "17px",
    right: "5px",
    width: ".7rem",
  },
  dataGridWrapper: {
    marginTop: "17px",
    backgroundColor: "transparent",
    maxHeight: "calc(100vh - 320px)",
    overflowY: "auto",
  },
}));

export default useStyles;
