import React from "react";
import i18next from "i18next";
import { formatDate } from "../../services/common/Utils";
import useStyles from "../../style/js-style/containers/doc-viewer/PanelDocumentSummaryStyle";
import EAIcon from "../../components/common/EAIcon";
import { Tooltip } from "@mui/material";
import { getDepositorOrTransmitter } from "../../services/common/ThirdpartyUtils";
import { isTransmittedByApi } from "../../services/common/DocumentUtils";

function PanelDocumentSummary({ evidence, documentName }) {
  const { classes } = useStyles();
  const name = documentName.charAt(0).toUpperCase() + documentName.slice(1);
  return (
    <div className={classes.root}>
      <div className={classes.summaryHeader}>
        {/* Adding at */}
        <EAIcon icon={"valid"} />
        <span className={classes.headerPart}>
          {i18next.t("addedOn")} <span className="dt">{formatDate(evidence.uploadDate)}</span>
        </span>
        {/* Adding by */}
        <EAIcon
          icon={
            isTransmittedByApi(evidence.transmitter) ? "api_icon" : "person"
          }
          className={classes.smallIcon}
        />
        <span className={classes.headerPart}>
          {i18next.t("by")} <span className="dt">{getDepositorOrTransmitter(evidence)}</span>
        </span>
      </div>
      {/* Document's name */}
      <Tooltip title={name} arrow>
        <div className={classes.documentName}>{name}</div>
      </Tooltip>
    </div>
  );
}

export default PanelDocumentSummary;
