import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  reasonListItem: {
    marginBottom: "5px",
    fontSize: "12px",
    color: "#1A1919",
  },
  realizedByBlocDate: {
    fontWeight: 400,
  },
  scoreLabel: {
    fontWeight: 600,
    fontSize: "12px",
  },
  scoreLabellow: {
    color: "#2D9F40"
  },
  scoreLabelmedium: {
    color: "#FF9A00"
  },
  scoreLabelhigh: {
    color: "#CC0001"
  },
  scoreLabelunknown: {
    color: "#535362"
  },
}));

export default useStyles;
