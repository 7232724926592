import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px",
  },
  headerMetaInfo: {
    fontWeight: 700,
    color: "#9A9AAF",
    fontSize: "14px",
    textTransform: "lowercase",
  },
  separate: {
    borderTop: "1px solid " + theme.palette.grey.grey200,
    width: "97%",
    margin: "auto",
    marginTop: "13px",
  },
  headerMetaWrapper: {
    display: "flex",
    alignItems: "center",
  },
  metaLabelIcon: {
    marginRight: "2px",
    width: "20px"
  },
  dataGridWrapper: {
    backgroundColor: "transparent",
    maxHeight: "calc(100vh - 420px)",
    overflowY: "auto",
  },
  dataGridTitle: {
    color: "#373534",
    fontFamily: "Metric",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  deleteItemIcon: {
    width: "7px",
  },
  chipsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: "10px 9px",
  },
  chip: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: 0,
    backgroundColor: theme.palette.grey.grey100,
    gap: "20.5px",
    fontSize: "12px",
    color: theme.palette.grey.grey700,
    fontWeight: 500,
    paddingRight: "8.5px",
    maxWidth: "200px"
  },
  largeChip: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: 0,
    backgroundColor: theme.palette.grey.grey100,
    gap: "20.5px",
    fontSize: "12px",
    width: "calc(90% + 8.5px)",
    color: theme.palette.grey.grey700,
    fontWeight: 500,
    paddingRight: "8.5px",
  },
  placeHolder: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9A9AAF",
    position: "relative",
    right: "12px"
  },
  formLabel: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey.grey500,
  },
}));

export default useStyles;
