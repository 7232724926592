import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: "10px",
    filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.07))",
    width: "calc(100% + 16px)",
  },
  headerWrapper: {
    backgroundColor: "white",
    height: "54px",
    borderRadius: "4px",
    padding: "0 17px",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  title: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#16161E",
    textTransform: "uppercase",
    flexGrow: 1,
  },
  titleWithIconWrapper: {
    display: "flex",
    flexDirection: "row",
    minWidth: "384px",
  },
  titleWithIconWrapperMin: {
    display: "flex",
    flexDirection: "row",
    width: "384px",
    marginRight: "16px",
  },
  blocWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    minWidth: "350px",
    gap: "10px",
  },
  arrowIcon: {
    position: "absolute",
    top: "23px",
    right: "23px",
  },
  arrowIconReverse: {
    position: "absolute",
    top: "23px",
    right: "23px",
    transform: "rotate(180deg)",
  },

  // RoleCheckBox
  // topDrop
  "roleCheckWrapper-topDrop": {
    "&.MuiFormControlLabel-root":{
      display: "flex",
      marginBottom: "5px",
      "& .MuiTypography-root": {
        fontWeight: 600,
        fontSize: "18px",
        fontFamily: "Metric"
      }
    }
  },
  "notificationItemCheckItem-topDrop": {
    borderRadius: 0,
  },

  // topItem
  "roleCheckWrapper-topItem": {
    "&.MuiFormControlLabel-root":{
      backgroundColor: "#EFFBF1",
      display: "flex",
      borderRadius: "8px !important",
      margin: "5px 10px",
      "& .MuiTypography-root": {
        color: "#16161E",
        fontSize: "14px",
        fontFamily: "Roboto"
      }
    }
  },
  "notificationItemCheckItem-topItem": {
    marginRight: "9px",
    borderRadius: "8px 0 0 8px !important",
    backgroundColor: "#D7F4DC",
  },

  // topItemLight
  "roleCheckWrapper-topItemLight": {
    "&.MuiFormControlLabel-root":{
      backgroundColor: "white",
      display: "flex",
      borderRadius: "8px !important",
      margin: "5px 10px",
      "& .MuiTypography-root": {
        color: "#16161E",
        fontSize: "14px",
        fontFamily: "Roboto"
      }
    }
  },
  "notificationItemCheckItem-topItemLight": {
    marginRight: "9px",
    borderRadius: "8px 0 0 8px !important",
    backgroundColor: "#D7F4DC",
  },

  // subDrop
  "roleCheckWrapper-subDrop": {
    "&.MuiFormControlLabel-root":{
      display: "flex",
      borderRadius: "8px !important",
      margin: "5px 10px",
      "& .MuiTypography-root": {
        fontWeight: 600,
        color: "#535362",
        fontSize: "14px",
        fontFamily: "Roboto"
      }
    }
  },
  "notificationItemCheckItem-subDrop": {
    marginRight: "9px",
    borderRadius: "8px 0 0 8px !important",
    backgroundColor: "#CDCCCB80!important",
  },

  // subItem
  "roleCheckWrapper-subItem": {
    "&.MuiFormControlLabel-root":{
      display: "flex",
      borderRadius: "8px !important",
      margin: "0px 20px",
      "& .MuiTypography-root": {
        color: "#16161E",
        fontSize: "14px",
        fontFamily: "Roboto"
      }
    }
  },
  "notificationItemCheckItem-subItem": { },

  // Lines
  notificationItemLine: {
    display: "flex",
    marginBottom: "5px",
    marginRight: "29px",
  },
  notificationItemCheck: {
    backgroundColor: "#D7F4DC",
    width: "39px",
    height: "39px",
    borderRadius: "8px 0 0 8px",
    zIndex: 8,
  },
  notificationItemText: {
    padding: "9px",
    borderRadius: "0 8px 8px 0",
    flex: 1,
    color: "#16161E",
    fontSize: "14px",
  },
  notificationLineCheckOverlay: {
    background: "white",
    opacity: "0.2",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0, left: 0, bottom: 0, right: 0,
    zIndex: 9999999,
    cursor: "auto"
  },

  // drop
  "panelRoot-topDrop": {
    backgroundColor: "white",
  },
  "panelHeader-topDrop": {
    height: "59px",
    display: "flex",
    alignItems: "center",
    padding: "0 20px 0 10px",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E2EA"
  },
  "panelRoot-subDrop": {
    backgroundColor: "rgba(242, 243, 247, 0.50)",
    borderRadius: "8px",
    margin: "5px 10px",
    paddingBottom: "7px",
  },
  "panelHeader-subDrop": {
    height: "39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    right: "10px",
    border: "none !important",
  },
  "itemsWrapper-topDrop": {
    backgroundColor: "white",
    paddingBottom: "21px",
    paddingTop: "10px",
  },
  "itemsWrapper-subDrop": {
    display: "flex",
    flexDirection: "column",
    padding: "19px 0",
    backgroundColor: "rgba(255, 255, 255, 0.48)",
    border: "1px solid white",
    margin: "8px 19px 19px 61px",
  },
  disabledBackground: {
    backgroundColor: "rgba(242, 243, 247, 0.50)",
  },
  dropPanelTitle: {
    color: "#16161E",
    fontFamily: "Metric",
    fontSize: "18px",
    fontWeight: 600,
    textTransform: "uppercase"
  },
  dropPanelCheckAllIcon: {
    marginRight: "10px",
    cursor: "pointer"
  },
  dropPanelActionItem: {
    color: "#656575",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
    cursor: "pointer",
    marginRight: "9px",
  },
  dropPanelContent: {
    marginTop: "15px",
    marginLeft: "18px",
  },
  subDropPanelContent: {
    margin: "15px",
    backgroundColor: "rgba(255, 255, 255, 0.48)",
    border: "1px solid white",
  },
}));

export default useStyles;
