import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "6px",
  },
  controle_status: {
    boxSizing: "border-box",
    background: "#3949AB",
    border: "2px solid #3949AB",
    borderRadius: "16px",
    color: "#FFFFFF"
  },
  action_status: {
    boxSizing: "border-box",
    background: "#FF9A00",
    border: "2px solid #FF9A00",
    borderRadius: "16px",
    color: "#FFFFFF"
  },
  invalide_status: {
    boxSizing: "border-box",
    background: "#CC0001",
    border: "2px solid #CC0001",
    borderRadius: "16px",
    color: "#FFFFFF"
  },
  below_expectation_status: {
    boxSizing: "border-box",
    background: "#6D00CC",
    border: "2px solid #6D00CC",
    borderRadius: "16px",
    color: "#FFFFFF"
  },
  valide_status: {
    boxSizing: "border-box",
    background: "#2D9F40",
    border: "2px solid #2D9F40",
    borderRadius: "16px",
    color: "#FFFFFF"
  },
  absent_status: {
    boxSizing: "border-box",
    background: "#7E7E8F",
    border: "2px solid #7E7E8F",
    borderRadius: "16px",
    color: "#FFFFFF"
  },
}));

export default useStyles;
