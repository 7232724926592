import { makeStyles } from 'tss-react/mui';

const useInputStyle = makeStyles()((theme) => ({
  inputText: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",
      border: "1px solid #E2E2EA",
      marginTop: "8px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #E2E2EA !important",
      },
    }
  },
}));

export default useInputStyle;
