import useStyles from "../../style/js-style/components/thirdparties/AddingButtonStyle.js";
import i18next from "i18next";
import EAButton from "../common/EAButton";

function AddingButton({ label, handleClick, disabled }) {
  const { classes } = useStyles();
  return (
    <>
      {label && (
        <EAButton
          content={
            <>
              <span className={classes.addButtonAddIcon}>+</span>
              {i18next.t(label)}
            </>
          }
          onClick={handleClick}
          className={classes.addButton}
          size="small"
          disabled={disabled}
        />
      )}
    </>
  );
}

export default AddingButton;
