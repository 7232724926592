import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback } from "react";
import countries from "i18n-iso-countries";
import useStyles from "../../../style/js-style/containers/thirdparties/sheet/ThirdpartyInfosStyle.js"

import i18next from "../../../i18n/i18n";
import {
  formatDate,
  formatNumberCurrency,
  formatActivityText
} from "../../../services/common/Utils";
import EAChip from "../../../components/EAChip";
import ThirdpartyIdentifiers from "../../../components/thirdparties/ThirdpartyIdentifiers";
import moment from "moment";
import { errorColor } from "../../../style/globalStyle";
import ThirdpartyUpdateDialog from "../../../components/thirdparties/ThirdpartyUpdateDialog";
import EditIcon from "@mui/icons-material/Edit";
import EAFlag from "../../../components/EAFlag";
import EAIcon from "../../../components/common/EAIcon";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils.js";

const TpInfo = ({ title, children, beforeLogo = false }) => {
  const { classes } = useStyles();
  return (
    <>
      <Typography variant="h6" className={classes.blocTitle}>
        {beforeLogo !== false ? (
          <EAIcon icon={beforeLogo} className={classes.icon} />
        ) : ""}
        <span>{title}</span>
      </Typography>
      {children}
    </>
  );
};

const ThirdpartyInfosTitle = ({ thirdparty }) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={0} className={classes.thirdpartyTitleContainer}>

      {/*Header info : nationality, size etc. */}
      <Grid item xs={12} md={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">

          {/* Country flag */}
          <EAFlag countryCode={thirdparty.addressCountryCode} />

          {/* collective proceedings */}
          {thirdparty.thirdpartyAlerts &&
            thirdparty.thirdpartyAlerts !== "NEANT" && (
              <EAChip
                label={i18next.t("collectiveProceedings")}
                color={errorColor}
              />
            )}

          {/* Headquarter*/}
          <EAChip label={thirdparty.headquarter ? i18next.t("headquarter") : i18next.t("secondaryEstablishment")} />

          {/* Catégorie */}
          {thirdparty.category && (
            <EAChip label={i18next.t(`thirdpartySheet.category.${thirdparty.category}`)} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

function ThirdpartyInfos({ thirdparty }) {
  const { classes } = useStyles();
  const [identifiers, setIdentifiers] = useState();
  const [thirdpartyCode, setThirdpartyCode] = useState(
    thirdparty.thirdpartyCode
  );
  const [
    isUpdateThirdPartyDialogOpen,
    setIsUpdateThirdPartyDialogOpen,
  ] = React.useState(false);
  const { hasAnyOfPrivilegesOnScopedResourceImpl } = useContext(RoleBasedAccessControlContext);

  const hasAnyOfPrivilegesOnScopedResource = useCallback((...privileges) => {
    let result = false;

    if (thirdparty) {
      result = hasAnyOfPrivilegesOnScopedResourceImpl(thirdparty, ...privileges);
    }

    return result;
  }, [thirdparty, hasAnyOfPrivilegesOnScopedResourceImpl]);

  // Get Third party Identifiers
  useEffect(() => {
    const res = [];

    if (hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY)) {
      res.push({
        type: i18next.t("tpCode"),
        value: thirdpartyCode || "",
        icon: <EditIcon fontSize="small" />,
        onIconClick: openUpdateThirdPartyPopup,
      });
    } else {
      res.push({
        type: i18next.t("tpCode"),
        value: thirdpartyCode || ""
      });
    }

    if (thirdparty.duns && !thirdparty.dunsEncrypted) {
      res.push({ type: "DUNS", value: thirdparty.duns });
    }
  
    res.push({ type: "Siret", value: thirdparty.siret });
    res.push({ type: "Siren", value: thirdparty.siren });
    res.push({ type: i18next.t("VAT"), value: thirdparty.tva });
    if (thirdparty.addressCountryCode?.toUpperCase() !== "FR") {
      thirdparty.listRegistrationNumber?.forEach((regNumber) => {
        res.push({ type: regNumber.libelle, value: regNumber.number });
      });
    }
    setIdentifiers(res);
  }, [thirdparty, thirdpartyCode, hasAnyOfPrivilegesOnScopedResource]);

  const openUpdateThirdPartyPopup = () => {
    setIsUpdateThirdPartyDialogOpen(true);
  };
  const changeShownTpCode = (tpCode) => {
    setThirdpartyCode(tpCode);
  };

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <ThirdpartyInfosTitle thirdparty={thirdparty} />
          </Grid>
          <Grid container item xs={12} spacing={2}>

            {/* Coordonates */}
            <Grid item xs={6} md={4}>
              <TpInfo title={i18next.t("thirdpartySheet.coordinates")} beforeLogo="coordinate">
                <Typography className={classes.typoLabel}><span className="dt">{thirdparty.addressStreet}</span></Typography><br />
                <Typography className={classes.typoLabel}> <span className="dt">{thirdparty.addressPostCode} {thirdparty.addressCity} </span></Typography><br />
                <Typography className={classes.typoContentUpper}>
                  {countries.getName(thirdparty.addressCountryCode, i18next.language)?.toUpperCase()}
                </Typography><br />
              </TpInfo>
            </Grid>

            {/* Informations */}
            <Grid item xs={6} md={5}>
              <TpInfo title={`Informations`} beforeLogo="information">
                <Tooltip
                  title={<>{i18next.t("creationDate")} : {(thirdparty.companyStartDate !== false ? <span className="dt">{formatDate(thirdparty.companyStartDate)} ({moment(thirdparty.companyStartDate).fromNow(true)})</span> : "")}</>}>
                  <div className={classes.typoLineWrapper}>
                    <Typography className={classes.typoLabel}> {i18next.t("creationDate")} : </Typography>
                    <Typography className={classes.typoContent}> {thirdparty.companyStartDate !== false ? <span className="dt">{formatDate(thirdparty.companyStartDate)} ({moment(thirdparty.companyStartDate).fromNow(true)})</span> : ""} </Typography><br />
                  </div>
                </Tooltip>
                <Tooltip title={i18next.t("legalCategory") + " : " + thirdparty.legalCategoryLabel || ""}>
                  <div className={classes.typoLineWrapper}>
                    <Typography className={classes.typoLabel}> {i18next.t("legalCategory")} : </Typography>
                    <Typography className={classes.typoContent}> {thirdparty.legalCategoryLabel || ""} </Typography><br />
                  </div>
                </Tooltip>
                <Tooltip title={<>{i18next.t("capital")} : <span>{formatNumberCurrency(thirdparty.capital, thirdparty.capitalCurrency)}</span></>}>
                  <div className={classes.typoLineWrapper}>
                    <Typography className={classes.typoLabel}> {i18next.t("capital")} : </Typography>
                    <Typography className={classes.typoContent}> <span className="dt">{formatNumberCurrency(thirdparty.capital, thirdparty.capitalCurrency)}</span> </Typography><br />
                  </div>
                </Tooltip>
                <Tooltip title={i18next.t("activity") + " : " + formatActivityText(thirdparty.etabActivityLabel, thirdparty.etabActivityCode, thirdparty.addressCountryCode)}>
                  <div className={classes.typoLineWrapper}>
                    <Typography className={classes.typoLabel}> {i18next.t("activity")} : </Typography>
                    <Typography className={classes.typoContent}> {formatActivityText(thirdparty.etabActivityLabel, thirdparty.etabActivityCode, thirdparty.addressCountryCode)} </Typography><br />
                  </div>
                </Tooltip>
                {thirdparty.subCategory && (
                  <Tooltip title={i18next.t("thirdpartySheet.sub-category.label") + " : " + i18next.t(`thirdpartySheet.sub-category.${thirdparty.subCategory}`)}>
                    <div className={classes.typoLineWrapper}>
                      <Typography className={classes.typoLabel}> {i18next.t("thirdpartySheet.sub-category.label")} : </Typography>
                      <Typography className={classes.typoContent}> {i18next.t(`thirdpartySheet.sub-category.${thirdparty.subCategory}`)} </Typography><br />
                    </div>
                  </Tooltip>
                )}
              </TpInfo>
            </Grid>

            {/* Identifiers */}
            <Grid item xs={6} md={3}>
              <TpInfo title={i18next.t("identifiers")} beforeLogo="identifier">
                <ThirdpartyIdentifiers
                  identifiers={identifiers}
                  size="full"
                />
              </TpInfo>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {isUpdateThirdPartyDialogOpen && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY) && (
        <ThirdpartyUpdateDialog
          isOpen={isUpdateThirdPartyDialogOpen}
          tpCode={thirdpartyCode}
          onTpCodeChange={changeShownTpCode}
          handleClose={() => setIsUpdateThirdPartyDialogOpen(false)}
        />
      )}
    </>
  );
}

export default ThirdpartyInfos;
