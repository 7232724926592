import React from "react";

import PanelDossierThirdparty from "./PanelDossierThirdparty";

function DossierTpInfos({ dossier }) {
  const dossierToThirdparty = (dos) => {
    return {
      name: dos.thirdpartyName,
      thirdpartyAlerts: dos.thirdpartyAlerts,
      category: "",
      subCategory: "",
      headquarter: dos.isHeadquarter,
      addressCountryCode: dos.thirdpartyCountryCode,
      etabActivityLabel: dos.thirdpartyActivityLabel,
      addressStreet: dos.thirdpartyAddress,
      addressPostCode: dos.thirdpartyPostCode,
      addressCity: dos.thirdpartyCity,
      siret: dos.siret,
      siren: dos.siren,
      tva: dos.tva,
      duns: dos.duns,
      listRegistrationNumber: dos.registrationNumbers,
      thirdpartyCode: dos.thirdpartyCode,
      thirdpartyId: dos.thirdpartyId,
      onboarded: dos.thirdpartyOnboarded
    };
  };

  return (
    <PanelDossierThirdparty
      dossier={dossier}
      thirdparty={dossierToThirdparty(dossier)}
    />
  );
}

export default DossierTpInfos;
