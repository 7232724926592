import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: "27px",
  },
  label: {
    fontSize: "14px",
    fontWeight: 700,
    color: theme.palette.grey.grey500,
  },
  input: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #E2E2EA !important",
      },
    },
  },
  divider: {
    borderTop: ".5px solid " + theme.palette.grey.grey200,
    borderBottom: 0,
    width: "100%",
    margin: "13px 0",
  },
  passwordControlLineText: {
    marginLeft: "15px",
    fontSize: "14px",
    color: "#373534",
    fontWeight: 400,
  },
  passwordIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
