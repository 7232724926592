import React, { useContext, useEffect, useState } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import i18next from "i18next";
import useStyles from "style/js-style/containers/administration/roles/RoleScopeFormStyle";
import {
  AttributeType,
  FieldType,
  Operator
} from "services/common/Constants";
import RuleSelectInput from "components/rules/RuleSelectInput";
import ScopeCriterionValues from "./ScopeCriterionValues";
import {
  getAvailableOperatorOptions,
  getAvailableValueOptions,
  PrivilegeEnum,
  RoleBasedAccessControlContext
} from "services/common/RolesUtils";

const ScopeCriterionFormFields = ({
  index,
  isHovered,
  onRemove,
  criterionFieldOptions,
  initialOperatorOptions = [],
  initialValueOptions = [],
  fieldName
}) => {
  const { classes } = useStyles();
  const [availableOperatorOptions, setAvailableOperatorOptions] = useState([]);
  const [restoreInitialOperatorOptions, setRestoreInitialOperatorOptions] = useState(true);

  const [availableValueOptions, setAvailableValueOptions] = useState([]);
  const [restoreAvailableValueOptions, setRestoreAvailableValueOptions] = useState(true);

  const formMethods = useFormContext();
  const { setValue, getValues, clearErrors } = formMethods;
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  useEffect(() => {
    if (restoreInitialOperatorOptions) {
      setAvailableOperatorOptions(initialOperatorOptions);
    }
  }, [initialOperatorOptions, restoreInitialOperatorOptions]);

  useEffect(() => {
    if (restoreAvailableValueOptions) {
      setAvailableValueOptions(initialValueOptions);
    }
  }, [initialValueOptions, restoreAvailableValueOptions]);

  const onFieldChange = (index, newValue) => {
    setRestoreInitialOperatorOptions(false);
    setRestoreAvailableValueOptions(false);

    let criterionFieldEntry = _.find(criterionFieldOptions, { value: newValue });
    setValue(`${fieldName}.${index}.field`, newValue);

    //Field type
    setValue(`${fieldName}.${index}.fieldType`, criterionFieldEntry.type);

    //Attribute Id
    if (criterionFieldEntry.type === FieldType.ATTRIBUTE) {
      setValue(
        `${fieldName}.${index}.attributeId`,
        criterionFieldEntry.attributeId
      );
    } else {
      setValue(
        `${fieldName}.${index}.attributeId`,
        ``
      );
    }

    //Criterion operator
    let filteredOperatorOptions = getAvailableOperatorOptions(
      newValue,
      criterionFieldOptions
    );
    setAvailableOperatorOptions(filteredOperatorOptions);

    if (filteredOperatorOptions?.length > 0) {
      setValue(`${fieldName}.${index}.operator`, filteredOperatorOptions[0].value);
    } else {
      setValue(`${fieldName}.${index}.operator`, "");
    }
    clearErrors(`${fieldName}.${index}.operator`);

    //Criterion values
    let filteredValueOptions = getAvailableValueOptions(
      newValue,
      criterionFieldOptions
    );
    setAvailableValueOptions(filteredValueOptions);

    setValue(`${fieldName}.${index}.values`, []);
    //Clear all previous errors that might have been raised on 'values' field
    clearErrors(`${fieldName}.${index}.values`);
  };

  const onOperatorChange = (index, newValue) => {
    setRestoreAvailableValueOptions(false);
    setValue(`${fieldName}.${index}.operator`, newValue);

    /*
      Criterion value initialization depends on both the field type and the applied operator.
      'values' is initialized to an empty array for cases where we expect multiple values, otherwise it is initialized
      to an array of a single empty field.
    */
    switch (newValue) {
      case Operator.IN_SET:
      case Operator.NOT_IN_SET:
      case Operator.IS_NULL:
      case Operator.NOT_NULL:
        setValue(`${fieldName}.${index}.values`, []);
        break;

      case Operator.EQUAL:
      case Operator.CONTAINS:
        let currentCriterionValue = getValues(`${fieldName}.${index}`);
        let criterionFieldEntry = _.find(criterionFieldOptions, {
          value: currentCriterionValue.field,
        });

        if (
          criterionFieldEntry.type === FieldType.ATTRIBUTE &&
          criterionFieldEntry.subType === AttributeType.LIST_MULTIPLE
        ) {
          setValue(`${fieldName}.${index}.values`, []);
        } else {
          setValue(`${fieldName}.${index}.values`, [""]);
        }
        break;

      default:
        setValue(`${fieldName}.${index}.values`, [""]);
        break;
    }

    //Clear all previous errors that might have been raised on 'values' field
    clearErrors(`${fieldName}.${index}.values`);
  };

  const renderDeleteButton = (index) => {
    return (
      <Tooltip placement="top" title={i18next.t("delete")}>
        <IconButton
          aria-label={`remove criterion ${index + 1}`}
          onClick={onRemove}
          className={classes.valuesIconBtnClosed}
          size="large"
        >
          <HighlightOff />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Grid container spacing={2} style={{ alignItems: "center" }}>
      <Grid item xs={12} md={5}>
        <RuleSelectInput
          fieldName={`${fieldName}.${index}.field`}
          label={
            index === 0 &&
            `${i18next.t("rules.configuration.criteria.fields.field.label")}`
          }
          placeholder={i18next.t(
            `rules.configuration.criteria.fields.field.placeholder`
          )}
          options={criterionFieldOptions}
          onChange={(newValue) => {
            onFieldChange(index, newValue);
          }}
          validate={() => { return null; }}
          labelVariant="chip"
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <RuleSelectInput
          fieldName={`${fieldName}.${index}.operator`}
          label={
            index === 0 &&
            `${i18next.t("rules.configuration.criteria.fields.operator.label")}`
          }
          placeholder={i18next.t(
            `rules.configuration.criteria.fields.operator.placeholder`
          )}
          options={availableOperatorOptions}
          onChange={(newValue) => {
            onOperatorChange(index, newValue);
          }}
          validate={() => { return null; }}
          labelVariant="chip"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <ScopeCriterionValues
          index={index}
          criterionFieldOptions={criterionFieldOptions}
          availableValueOptions={availableValueOptions}
          fieldName={fieldName}
        />
      </Grid>

      <Grid item xs={12} md={1}>
        {/*Temporary. This should be managed as it is done for attribute values (using styles)*/}
        {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_ROLE) && isHovered && renderDeleteButton(index)}
      </Grid>
    </Grid>
  );
};

export default ScopeCriterionFormFields;
