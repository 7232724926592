import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: "20px",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "120%",
    fontFamily: "Metric",
    marginBottom: "16px",
  },
  cardMore:{
    fontSize: "14px",
    fontWeight: 700,
    color: theme.palette.grey.grey400,
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default useStyles;
