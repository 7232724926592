import React from 'react';
import EAIcon from "../../../components/common/EAIcon";
import { Box, Chip, TextField, Typography } from "@mui/material";
import DataTableActions from "../../../components/common/datatable/DataTableActions";
import i18next from "i18next";
import {
  cellWithTooltip,
  dateValueFormatter,
} from "../../../components/common/datatable/EADataGrid";
import MainHeaderCell from "../../../components/common/datatable/MainHeaderCell";
import { PrivilegeEnum } from "../../../services/common/RolesUtils";

export const getInitialUsersTableSort = () => [
  {
    field: "name",
    sort: "asc",
  },
];

export const UsersDefaultVisibilityColumns = () => {
  return {
    phone: false,
    lastactivitydate: false
  };
};

const usersTableActions = (
  usersIds,
  row,
  openDeleteUsersPopup,
  openEditUsersPopup,
  openReinviteUsersPopup,
  hasAnyOfPrivileges,
  isMorePrivilegedThanUser
) => {
  return [
    {
      label: i18next.t('modify-profile'),
      icon: "modify-role",
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_USER) || !isMorePrivilegedThanUser(row),
      action: () => {
        openEditUsersPopup(row)
      }
    },
    {
      label: i18next.t('reinvite'),
      icon: "reinvit",
      isHidden: row.status === 'INSCRIT',
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_USER),
      action: () => {
        openReinviteUsersPopup(usersIds, row)
      }
    },
    {
      label: i18next.t('delete'),
      icon: "delete_grey",
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_USER) || !isMorePrivilegedThanUser(row),
      action: () => {
        openDeleteUsersPopup(usersIds, row);
      }
    },
  ];

};



export const usersTableColumns = (
  existingAccountRoles,
  openDeleteUsersPopup,
  openEditUsersPopup,
  openReinviteUsersPopup,
  hasAnyOfPrivileges,
  isMorePrivilegedThanUser
) => {

  function CustomFilterInputSingleSelect(props) {
    // eslint-disable-next-line
    const { item, applyValue, type, apiRef, focusElementRef } = props;
  
    return (
      <>
      <Typography style={{fontWeight: "400", fontSize: "12px", color: "#9A9AAF", lineHeight: "17.25px"}}>
       {i18next.t('value')}
      </Typography>
      <TextField
        id={`contains-input-${item.id}`}
        value={item.value}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        type={type || "text"}
        variant="standard"
        InputLabelProps={{
          shrink: true
        }}
        inputRef={focusElementRef}
        select
        SelectProps={{
          native: true
        }}
      >
        {["", ...existingAccountRoles].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </TextField>
      </>
    );
  }

  return [
    {
      field: "name",
      headerName: i18next.t('usersTable.columns.name'),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t('usersTable.columns.name')} />
      ),
      renderCell: (params) => cellWithTooltip(params.value,true, false),
    },
    {
      field: "firstname",
      headerName: i18next.t('usersTable.columns.firstname'),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t('usersTable.columns.firstname')} />
      ),
      renderCell: (params) => cellWithTooltip(params.value, true, false),
    },
    {
      field: "function",
      headerName: i18next.t('usersTable.columns.function'),
      flex: 0.08,
      renderCell: (params) => cellWithTooltip(params.value, true, false),
    },
    {
      field: "roles",
      headerName: i18next.t("usersTable.columns.roles"),
      flex: 0.08,
      type: "singleSelect",
      valueOptions: existingAccountRoles,
      valueFormatter: (params) => {
        if(typeof params.value?.join === "function") {
          return params.value.join();
        } else {
          return params.value || "";
        }
      },
      renderCell: (params) => cellWithTooltip(params.value, true, true),
      filterOperators: [
        {
          value: "contains",
          getApplyFilterFn: (filterItem) => {
            if (filterItem.value == null || filterItem.value === "") {
              return null;
            }
            return ({ value }) => {
              // if one of the cell values corresponds to the filter item
              return value.some((cellValue) => cellValue === filterItem.value);
            };
          },
          InputComponent: CustomFilterInputSingleSelect
        }
      ]
    },
    {
      field: "email",
      headerName: i18next.t('usersTable.columns.email'),
      flex: 0.15,
      renderCell: (params) => cellWithTooltip(params.value, true, false),
    },
    {
      field: "phone",
      headerName: i18next.t('usersTable.columns.phone'),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true, false),
    },
    {
      field: "lastactivitydate",
      headerName: i18next.t('usersTable.columns.lastactivitydate'),
      flex: 0.08,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <span className="dt">{dateValueFormatter(params)}</span>
    },
    {
      field: "status",
      headerName: i18next.t("usersTable.columns.status"),
      flex: 0.12,
      type: "singleSelect",
      valueOptions: [
        { value: "INSCRIT", label: i18next.t(`usersTable.status.INSCRIT`) },
        { value: "INVITE", label: i18next.t(`usersTable.status.INVITE`) },
        {
          value: "INVIATION EXPIREE",
          label: i18next.t(`usersTable.status.INVIATION EXPIREE`),
        },
      ],
      valueFormatter: (params) =>
        i18next.t(`usersTable.status.${params.value}`),
      renderCell: (params) => {
        return (
          <Box display="flex">
            {params.value === "INSCRIT" && (
              <Chip
                icon={<EAIcon icon="registered_green" />}
                size="small"
                label={i18next.t(`usersTable.status.${params.value}`)}
                color="primary"
                variant="outlined"
                sx={{
                  padding: "4px 8px",
                  backgroundColor: "#D7F4DC",
                  color: "#2D9F40",
                  fontWeight: 600,
                  fontSize: "10px",
                  borderRadius: "6px",
                  border: "none",
                }}
              />

            )}
            {(params.value === "INVIATION EXPIREE") && (
              <Chip
                className="errorChip"
                icon={<EAIcon icon="warning" />}
                size="small"
                label={i18next.t(`usersTable.status.${params.value}`)}
                variant="outlined"
                sx={{
                  padding: "4px 8px",
                  backgroundColor: "#FFEBEB",
                  color: "#CC0001",
                  fontWeight: 600,
                  fontSize: "10px",
                  borderRadius: "6px",
                  border: "none",
                }}
              />
            )}
            {(params.value === "INVITE") && (
              <Chip
                className="infoChip"
                icon={<EAIcon icon="registered_blue" />}
                size="small"
                label={i18next.t(`usersTable.status.${params.value}`)}
                variant="outlined"
                sx={{
                  padding: "4px 8px",
                  backgroundColor: "#CCDEFF",
                  color: "#0047CC",
                  fontWeight: 600,
                  fontSize: "10px",
                  borderRadius: "6px",
                  border: "none",
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <DataTableActions
              actions={usersTableActions(
                [params.row.id],
                params.row,
                openDeleteUsersPopup,
                openEditUsersPopup,
                openReinviteUsersPopup,
                hasAnyOfPrivileges,
                isMorePrivilegedThanUser
              )}
            />
          </div>
        );
      },
    }
  ]
};
