import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import i18nFr from "./i18n-fr.json";
import "moment/locale/fr";
import "moment/locale/es";
import countries from "i18n-iso-countries";
import moment from "moment";

i18next
  .use(initReactI18next)
  .init({
    resources: {
      fr: i18nFr
    },
    fallbackLng: "fr"
});

countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

moment.locale(i18next.language);

export default i18next;
