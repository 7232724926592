import { FormHelperText, TextField } from "@mui/material";
import EADialog from "components/common/EADialog";
import EAInputBloc from "components/common/EAInputBloc";
import i18next from "i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { isEmpty } from "services/common/Utils";
import { theme } from "style/globalStyle";
import CancelImportThirdParties from "../CancelImportThirdParties/CancelImportThirdParties";
import { Context } from "states/Store";
import { getListImportThirdParties } from "services/edge/ThirdpartyService";

const NameImportDialog = ({isOpen, onClose, validationHandler, initialValue}) => {

    //----------- Get existing names imports -----------------
    const [state, dispatch] = useContext(Context);
    const [existingNames, setExistingNames] = useState([]);
    

    const getListExistingName = useCallback(async () => {
        try {
            const listData = await getListImportThirdParties(state.account.id, dispatch);
            setExistingNames(listData.content.map(imports => {
                return imports.name
            }))

        } catch(error) {
            console.error(error);
        }
        
    }, [dispatch, state.account.id])

    useEffect(()=> {
        getListExistingName();
    }, [getListExistingName])


    //----------- name value -------------------

    const defaultValues =  useMemo(() => {
        return {nameImport: initialValue,}
    }, [initialValue]);

    const { control,  handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: defaultValues, 
        mode: "onChange"
      })

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues, reset])

    const submitForm = () => {
        handleSubmit(data => {
            if(isEmpty(errors)) {
                validationHandler(data);
            }
        })();
        reset(defaultValues);
    }

    //---------- Confirm close import--------------
    const [isOpenCloseConfirm, setIsOpenCloseConfirm] = useState(false)

    return <EADialog 
                isOpen={isOpen}
                title={i18next.t("imports.importDialogName.nameImportStep")}
                onCancel={(event, reason) => {
                    if(reason && reason === "backdropClick") return;
                    setIsOpenCloseConfirm(true);
                }}
                validateBtnLabel="import"
                fullWidth={true}
                isValidateDisabled={!isEmpty(errors)}
                onValidate={submitForm}>

                        <Controller 
                        name="nameImport"
                        control={control}
                        rules={{
                            required: i18next.t("infos.required"),
                            maxLength: {
                            value: 100,
                            message: i18next.t("imports.dialogs.nameImportLabelError"),
                            },
                            validate: value => {
                                if(existingNames.includes(value)) {
                                    return i18next.t("imports.importDialogName.errorExistingName")
                                } else {
                                    return undefined
                                }
                            }
                        }}
                        render={({ field }) =>
                        <EAInputBloc label={i18next.t("imports.dialogs.nameImportLabel")} required>
                            <TextField variant="outlined" fullWidth {...field} error={errors.nameImport}/>
                            {errors.nameImport &&
                            <FormHelperText style={{color: theme.palette.error.main}}>{errors.nameImport.message}</FormHelperText>
                            }
                        </EAInputBloc> }/>

                        <CancelImportThirdParties isOpen={isOpenCloseConfirm} onClose={() => setIsOpenCloseConfirm(false)} onValidate={onClose}/>

    </EADialog>
}

export default NameImportDialog;