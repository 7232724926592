import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  circleShape: {
    backgroundColor: theme.palette.primary.primary300,
    borderRadius: "50%",
  },
  circleShapeAdmin: {
    backgroundColor: theme.palette.background.white,
    borderRadius: "50%",
  },
  circleShapeGrey: {
    backgroundColor: theme.palette.grey.grey300,
    borderRadius: "50%",
    opacity: 0.6,
  },
  circleInnerText: {
    color: theme.palette.primary.primary700,
    fontWeight: "bold",
  },
  userInitials: {
    color: theme.palette.primary.primary700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userInitialsAdmin: {
    color: theme.palette.primary.primary700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  userInitialsGrey: {
    color: theme.palette.grey.grey700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  disabledAvatar: {
    opacity: 0.6
  }
}));

export default useStyles;
