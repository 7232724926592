import React from "react";
import i18next from "i18next";
import _ from "lodash";
import {
  ActivityLevel,
  AttributeType,
  EDGE_DATE_FORMAT,
  FieldDisplayVariant,
  FieldType,
  Operator
} from "./Constants";
import { find } from "lodash";
import { InduedAllScores } from "./InduedUtils";
import moment from "moment";
import { CalculationResultType, TargetFieldNature } from "./CalculatorUtils";

export const RULE_NAME_MAX_LENGTH = 100;

export const RulesContext = React.createContext({
  dossierTypes: [],
  actionTypes: []
});

export const RuleActionType = {
  DOSSIER_CREATION: "DOSSIER_CREATION", 
  FINANCIAL_STRENGTH_ACTIVATION: "FINANCIAL_STRENGTH_ACTIVATION", 
  FINANCIAL_STRENGTH_DEACTIVATION: "FINANCIAL_STRENGTH_DEACTIVATION"
}

export const RuleActionParameterNames = {
  DOSSIER_TYPE_CODE: "dossier_param_type_code",
  DOSSIER_REFERENCE: "dossier_param_reference",
  DOSSIER_REACTIVATE_ARCHIVED: "dossier_param_reactivate_archived"
}

export const getRuleActionModel = () => {
  return {
    id: "",
    type: "",
    parameters: {},
    ruleId: "",
  }
};

export const validateRule = (actions) => {
  if (_.isEmpty(actions)) {
    return "no-actions";
  }

  if (actions?.filter((x) => x.dossierTypeCode !== "" && x.dossierReference === "").length > 0) {
    return "no-refs";
  }

  if (actions?.filter((x) => x.dossierTypeCode !== "" && x.dossierReference !== "").length === 0) {
    return "no-dossier"
  }
  if(actions?.filter((x) => x.type?.includes("FINANCIAL_STRENGTH")).length > 1 ) {
    return "multiple-actions-not-allowed"
  }
}

export const isExistingRuleToActivateHaveErrors = (actions) => {
  return _.isEmpty(actions) ||
    /* CASE DOSSIER_CREATION */
    (actions?.filter((action) => action.type === RuleActionType.DOSSIER_CREATION && 
      (!action.parameters[`${RuleActionParameterNames.DOSSIER_REFERENCE}`] || !action.parameters[`${RuleActionParameterNames.DOSSIER_TYPE_CODE}`])).length > 0) ||
      /* CASE SCORE SANTE FINANCIERE */
      actions?.filter((action) => action.type?.includes("FINANCIAL_STRENGTH")).length > 1 
    }

export const getCriterionFieldOptions = (
  accountCountries = [],
  accountActivities,
  attributes = [],
  calculatedAttributes = [],
  indicators = [],
  financialStrengthScores = [],
  financialStrengthRatings = []
  
  ) => {
  return [
    {
      value: 'name',
      label: 'Tiers', //i18next.t('thirdpartiesTable.columns.name')
      type: FieldType.TEXT,
      valueOptions: [],
    },
    //Uncomment when handled correctly at backend side
    // {
    //   value: 'identifier',
    //   label: 'Identifiant', //i18next.t('thirdpartiesTable.columns.identifier')
    //   type: FieldType.TEXT,
    //   valueOptions: [],
    // },
    {
      value: 'third_party_code',
      label: 'Code tiers', //i18next.t('thirdpartiesTable.columns.thirdpartyCode')
      type: FieldType.TEXT,
      valueOptions: [],
    },
    {
      value: 'etab_activity_sic_major',
      label: 'Activité SIC major',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_MAJOR),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_2',
      label: 'Activité SIC 2',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_2_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_3',
      label: 'Activité SIC 3',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_3_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_4',
      label: 'Activité SIC 4',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_4_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_6',
      label: 'Activité SIC 6',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_6_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_8',
      label: 'Activité SIC 8',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_8_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'headquarter',
      label: 'Siège', //i18next.t('headquarter')
      type: FieldType.CUSTOM,
      valueOptions: [
        {
          value: 'true',
          label: i18next.t("headquarter")
        },
        {
          value: 'false',
          label: i18next.t("secondaryEstablishment")
        },
      ],
    },
    {
      value: 'address_post_code',
      label: 'Code postal', //i18next.t('thirdpartiesTable.columns.addressPostCode')
      type: FieldType.TEXT,
      valueOptions: [],
    },
    {
      value: 'address_city',
      label: 'Ville', //i18next.t('thirdpartiesTable.columns.addressCity')
      type: FieldType.TEXT,
      valueOptions: [],
    },
    {
      value: 'address_country_code',
      label: 'Pays', //i18next.t('thirdpartiesTable.columns.addressCountry')
      type: FieldType.CUSTOM,
      valueOptions: accountCountries,
    },
    //Uncomment when handled correctly at backend side
    // {
    //   value: 'following_date', //i18next.t('thirdpartiesTable.columns.followingDate')
    //   label: 'Ajouté le',
    //   type: FieldType.DATE,
    //   valueOptions: [],
    // },
    // {
    //     value: 'stopping_date', //i18next.t('thirdpartiesTable.columns.stoppingDate')
    //     label: 'Arrêté le',
    //     type: FieldType.DATE,
    //     valueOptions: [],
    // },
    // {
    //   value: 'registered', //i18next.t('dossierTable.columns.contact')
    //   label: 'Contact(s)',
    //   type: FieldType.CUSTOM,
    //   valueOptions: [
    //     {
    //       value: 'INSCRIT',
    //       label: i18next.t(`thirdpartiesTable.status.INSCRIT.label`)
    //     },
    //     {
    //       value: 'INVITE',
    //       label: i18next.t(`thirdpartiesTable.status.INVITE.label`)
    //     },
    //     {
    //       value: 'INVIATION EXPIREE',
    //       label: i18next.t(`thirdpartiesTable.status.INVIATION EXPIREE.label`)
    //     },
    //     {
    //       value: 'SANS CONTACT',
    //       label: i18next.t(`thirdpartiesTable.status.SANS CONTACT.label`)
    //     },
    //   ],
    // },
    ...attributes?.map((attr) => {
      return {
        value: `attribute-${attr.id}`,
        label: attr.label,
        type: FieldType.ATTRIBUTE,
        subType: attr.type,
        attributeId: attr.id,
        /*
          A boolean attribute doesn't have predefined value options on the backend side,
          but it's a primitive type that has limited options (true and false), so process
          it differently
        */
        valueOptions: (AttributeType.BOOLEAN === attr.type) ? [
          {
            value: "true",
            label: i18next.t("yes")
          },
          {
            value: "false",
            label: i18next.t("no")
          },
        ] : attr.values?.map((valueEntry) => {
          return {
            value: valueEntry.value,
            label: valueEntry.value
          }
        }) || [],
      };
    }),
    ...calculatedAttributes?.map((calculatedAttr) => {
      return {
        value: `${calculatedAttr.value}`,
        label: calculatedAttr.label,
        type: FieldType.CALCULATED_ATTRIBUTE,
        subType: calculatedAttr.type,
        valueOptions: [],
        fieldNature: TargetFieldNature.ATTRIBUTE,
      };
    }),
    ...indicators?.map((ind) => {
      return {
        value: `${ind.value}`,
        label: ind.label,
        type: FieldType.INDICATOR,
        subType: ind.type,
        valueOptions: [],
        fieldNature: TargetFieldNature.INDICATOR,
      };
    }),
    //TODO: Add only if indued is active for
    ...InduedAllScores.map(score => {
      return {
        value: score,
        label: i18next.t(`indued.scoresFull.${score}`),
        type: FieldType.RISK,
        valueOptions: [
          {
            value: "0",
            label: i18next.t("indued.severity.unknown")
          },
          {
            value: "1",
            label: i18next.t("indued.severity.low")
          },
          {
            value: "2",
            label: i18next.t("indued.severity.medium")
          },
          {
            value: "3",
            label: i18next.t("indued.severity.high")
          },
        ],
      };
    }),
    {
      value: "transparency_score",
      label: i18next.t("transparency.criterionLabel"),
      type: FieldType.NUMERIC,
      valueOptions: [],
      isDisabled: false
    },
    {
      value: "financial_strength_score",
      label: i18next.t("financialStrength.labelRuleScore"),
      type: FieldType.RISK,
      valueOptions: financialStrengthScores,
      isDisabled: false
    },
    {
      value: "financial_strength_rating",
      label: i18next.t("financialStrength.labelRuleRating"),
      type: FieldType.RISK,
      valueOptions: financialStrengthRatings,
      isDisabled: false
    }
  ];
}

export const getCriterionOperatorOptions = () => {
  return {
    TEXT: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      // {
      //     value: Operator.NOT_EQUAL,
      //     label: "N'est pas",
      // },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      // {
      //     value: Operator.NOT_CONTAINS,
      //     label: "Ne contient pas",
      // },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      // {
      //     value: Operator.NOT_STARTS_WITH,
      //     label: "Ne commence pas par",
      // },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      // {
      //     value: Operator.NOT_ENDS_WITH,
      //     label: "Ne se termine pas par",
      // },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
      // {
      //     value: Operator.NOT_IN_SET,
      //     label: "Ne fait pas partie de",
      // },
    ],

    NUMERIC: [
      {
        value: Operator.EQUAL,
        label: "="
      },
      {
        value: Operator.NOT_EQUAL,
        label: "!="
      },
      {
        value: Operator.GREATER_THAN,
        label: ">"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: ">="
      },
      {
        value: Operator.LESS_THAN,
        label: "<"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "<="
      },
      // {
      //     value: Operator.BETWEEN,
      //     label: "Est entre",
      // },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET,
      //     label: "Ne fait pas partie de"
      // }
    ],

    DATE: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Postérieure",
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Egal ou postérieure",
      },
      {
        value: Operator.LESS_THAN,
        label: "Antérieur",
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Egal ou antérieure",
      },
      // {
      //     value: Operator.BETWEEN,
      //     label: "Est entre",
      // },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      // {
      //   value: Operator.IN_SET,
      //   label: "Fait partie de",
      // },
      // {
      //     value: Operator.NOT_IN_SET,
      //     label: "Ne fait pas partie de",
      // }
    ],

    BOOLEAN: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET,
      //     label: "Ne fait pas partie de"
      // }
    ],

    CUSTOM: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET,
      //     label: "Ne fait pas partie de"
      // }
    ],

    ATTRIBUTE: [
      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE, DECIMAL, INTEGER, PERCENTAGE, BOOLEAN*/
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      // {
      //     value: Operator.NOT_EQUAL,
      //     label: "N'est pas",
      // },

      /*For LIST_MULTIPLE, LIST_UNIQUE, */
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      // {
      //     value: Operator.NOT_CONTAINS,
      //     label: "Ne contient pas",
      // },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      // {
      //     value: Operator.NOT_STARTS_WITH,
      //     label: "Ne commence pas par",
      // },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      // {
      //     value: Operator.NOT_ENDS_WITH,
      //     label: "Ne se termine pas par",
      // },

      /*For: DECIMAL, INTEGER, PERCENTAGE*/
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      // {
      //     value: Operator.BETWEEN,
      //     label: "Est entre",
      // },

      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE, DECIMAL, INTEGER, PERCENTAGE, BOOLEAN*/
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },

      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE, DECIMAL, INTEGER, PERCENTAGE, BOOLEAN*/
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE*/
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
      // {
      //     value: Operator.NOT_IN_SET,
      //     label: "Ne fait pas partie de",
      // },
    ],

    RISK: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET,
      //     label: "Ne fait pas partie de"
      // }
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      // {
      //   value: Operator.NOT_NULL,
      //   label: "N'est pas vide",
      // },
    ],

    CALCULATED_ATTRIBUTE: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    INDICATOR: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    ACTIVITY: [
      {
        value: Operator.EQUAL, // "IS"
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL, //"NOT"
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET, //"IS_ANY_OF"
        label: "Fait partie de"
      }
    ]
  };
}


export const getAvailableOperatorOptions = (field, criterionFieldOptions) => {
  let operatorOptions = [];
  let criterionFieldEntry = find(criterionFieldOptions, { value: field });
  if (criterionFieldEntry !== undefined) {
    operatorOptions = getCriterionOperatorOptions()[criterionFieldEntry.type];

    /*
      In the case of an 'ATTRIBUTE' field, Operator.IN_SET and Operator.NOT_IN_SET operators are not acceptable on 'LIST_MULTIPLE' & 'BOOLEAN'.
    */
    if (criterionFieldEntry.type === 'ATTRIBUTE') {
      switch (criterionFieldEntry.subType) {
        case AttributeType.LIST_UNIQUE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
          ].includes(entry.value));

          break;

        case AttributeType.LIST_MULTIPLE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value)).map((entry) => {
            switch (entry.value) {
              //This is ugly but let's change the label of Operator.CONTAINS operator here
              case Operator.CONTAINS:
                return {
                  value: entry.value,
                  label: 'Fait partie de',
                  // label: 'Contient une valeur parmi',
                }

              default:
                return entry;
            }
          });

          break;

        case AttributeType.DECIMAL:
        case AttributeType.INTEGER:
        case AttributeType.PERCENTAGE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
          ].includes(entry.value));

          break;

        case AttributeType.BOOLEAN:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value));
          break;

        case AttributeType.DATE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.BETWEEN, //TODO: Add these 3 later if asked for
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value)).map((entry) => formatDateOperatorLabel(entry));
          break;

        default:
          break;
      }
    } else if (criterionFieldEntry.type === 'INDICATOR' || criterionFieldEntry.type === 'CALCULATED_ATTRIBUTE') {
      switch (criterionFieldEntry.subType) {
        case CalculationResultType.STRING:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
          ].includes(entry.value));
          break;

        case CalculationResultType.NUMERIC:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
          ].includes(entry.value));
          break;

        case CalculationResultType.BOOLEAN:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value));
          break;

        default:
          break;
      }
    }
  }

  return operatorOptions;
};

export const getAvailableValueOptions = (field, criterionFieldOptions) => {
  let criterionFieldEntry = find(criterionFieldOptions, { value: field });

  return (criterionFieldEntry === undefined) ? [] : criterionFieldEntry.valueOptions;
};

export const getCriterionEntry = (field, criterionFieldOptions) => {
  return find(criterionFieldOptions, { value: field });
};

export const formatRuleNameList = (list) => {
  let result = "";
  for (let name of list) {
    result += name + ", ";
  }
  return result.slice(0, -2);
}

export const mapRulesCriteria = (criteria) => {
  // Map date criteria values from moment to iso date
  return criteria?.map(criterion => {
    if (moment.isMoment(criterion.values?.[0])) {
      criterion.values = criterion.values.map(d => moment(d).format(EDGE_DATE_FORMAT))
    }

    return criterion;
  })
}

export const formatDateOperatorLabel = (attributeOp) => {
  let operator = {
    value: attributeOp.value
  }

  switch (attributeOp.value) {
    case Operator.EQUAL:
      operator.label = "Est";
      break;
    case Operator.NOT_EQUAL:
      operator.label = "N'est pas";
      break;
    case Operator.GREATER_THAN:
      operator.label = "Postérieure";
      break;
    case Operator.GREATER_OR_EQUAL:
      operator.label = "Egal ou postérieure";
      break;
    case Operator.LESS_THAN:
      operator.label = "Antérieur";
      break;
    case Operator.LESS_OR_EQUAL:
      operator.label = "Egal ou antérieure";
      break;
    case Operator.IS_NULL:
      operator.label = "Est vide";
      break;
    case Operator.NOT_NULL:
      operator.label = "N'est pas vide";
      break;
    default:
      operator.label = attributeOp.label;
      break;
  }

  return operator;
}
