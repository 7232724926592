import React, { useContext, useEffect, useState } from "react";
import {
  PrivilegeEnum,
  RoleBasedAccessControlContext,
  RolePrivilegesEditorContext,
} from "services/common/RolesUtils";
import { Checkbox, FormControlLabel } from "@mui/material";
import useStyles from "style/js-style/containers/administration/roles/privileges/EventPrivilegeBlockStyle";

function RoleCheckBox({ item, variant }) {
  const { classes } = useStyles();
  const { isGlobalAdmin, hasAnyOfPrivileges } = useContext(
    RoleBasedAccessControlContext
  );
  const { isSelectedRoleReadOnly } = useContext(RolePrivilegesEditorContext);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setIsEditable(
      variant === 'profile' ?
        item.isGrantable :
        item.isGrantable && (isGlobalAdmin || (!isSelectedRoleReadOnly && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_ROLE)))
    );
  }, [isGlobalAdmin, variant, item, isSelectedRoleReadOnly, hasAnyOfPrivileges]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (isEditable) {
      item.action();
    }
  };

  // const isCheckBoxIndeterminate = () => {
  //   const someFalse = item?.items?.some(x => !x.isActive) || false;
  //   const allFalse = item?.items?.every(x => !x.isActive) || false;
  //   return someFalse && !allFalse;
  // }

  return (
    <FormControlLabel
      className={classes[`roleCheckWrapper-${item.variant}`]}
      sx={{
        "&.MuiFormControlLabel-root": {
          backgroundColor: (item.variant === "topItem" && !item.isActive) ? "#E2E2EA80 !important" : "",

          "& .MuiCheckbox-colorPrimary": {
            backgroundColor: item.variant === "subDrop" && item.backgroundColor ? item.backgroundColor + "!important" : ""
          }
        }
    }}
      control={
        <Checkbox
          checked={item.isActive}
          onClick={handleClick}
          disabled={!isEditable}
          className={classes[`notificationItemCheckItem-${item.variant}`]}
          sx={{backgroundColor: (["topItem","subDrop"].includes(item.variant) && !item.isActive) ? "#CDCCCB80 !important" : ""}}
          // indeterminate={isCheckBoxIndeterminate()}
        />
      }
      label={item.name}
      onClick={(e) => e.stopPropagation()}
    />
  );
}

export default RoleCheckBox;
