import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../../states/Store";
import { tpSearchResultColumns } from "./TPSearchResultTableUtils";
import { useHistory } from "react-router-dom";
import EADataGrid from "../../../components/common/datatable/EADataGrid";
import ROUTES from "../../../config/Routes";
import { addThirdparty } from "../../../services/edge/ThirdpartyService";
import { ERROR } from "../../../services/common/Constants";
import { RoleBasedAccessControlContext } from "services/common/RolesUtils";

function TPSearchResultTable({ loader, results }) {
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  let history = useHistory();
  const [tpSearchColumns, setTpSearchColumns] = useState([]);
  const [thirdpartyAddLoader, setThirdpartyAddLoader] = useState(false);
  const [thirdpartyIdLoader, setThirdpartyIdLoader] = useState(null);

  const handleResultRowClick = useCallback(
    async (row) => {
      setThirdpartyIdLoader(row.id)
      let thirdpartyId;
      if (!row.onboarded) {
        try {
          setThirdpartyAddLoader(true);
          const thirdpartyToAdd = {
            companyIdType: row.id?.length === 14 ? "CIT001" : "CIT003",
            companyIdValue: row.id,
            companyIdEncrypted: row.isIdEncrypted,
          };
          const response = await addThirdparty(
            state.account.id,
            thirdpartyToAdd,
            dispatch
          );
          if (response?.content) {
            thirdpartyId = response.content.thirdpartyId;
          }
        } catch (e) {
          if (e.message.messages.error[0].message.includes("restricted(INSEE)")) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "ERROR_RESTRICTED_INSEE" },
            });
          } else if (!e?.message.messages?.error) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "INTERNAL_ERROR" },
            });
          }
          setThirdpartyAddLoader(false);
        }
      }
      // Redirect to thirdparty detail
      if (thirdpartyId) {
        const route = ROUTES.THIRDPARTY_DETAIL.replace(
          ":thirdpartyId",
          thirdpartyId
        );
        history.push(`/${state.account.id}/${route}`, {
          prevPath: history.location.pathname,
        });
      }
    },
    [state.account, dispatch, history]
  );

  useEffect(() => {
    setTpSearchColumns(
      tpSearchResultColumns(handleResultRowClick, thirdpartyAddLoader, thirdpartyIdLoader, hasAnyOfPrivileges)
    );
  }, [handleResultRowClick, thirdpartyAddLoader, thirdpartyIdLoader, hasAnyOfPrivileges]);

  return (
    <>
      <EADataGrid
        loading={loader || thirdpartyAddLoader}
        inputRows={results}
        columns={tpSearchColumns}
        clickable={false}
      />
    </>
  );
}
export default TPSearchResultTable;
