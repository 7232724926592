import i18next from "i18next";
import moment from "moment";
import { EDGE_DATE_FORMAT, FREE } from "./Constants";

export const formatDossierCreationData = (
  data,
  thirdparty,
  documentsSelected
) => {
  if (data.expirationDate) {
    data.expirationDate = moment(data.expirationDate).format(EDGE_DATE_FORMAT);
  }

  if (data.dossierType !== FREE) {
    data.dossierTypeCode = data.dossierType;
  } else {
    data.dossierTypeCode = null;
    data.requiredDocuments = documentsSelected;
  }
  delete data.dossierType;

  if (thirdparty.companyIdType === "CIT001") {
    data.companyIdType = "CIT001";
    data.companyIdValue = thirdparty.companyIdValue;
    data.companyIdEncrypted = false;
  } else {
    data.companyIdType = "CIT003";
    data.companyIdValue = thirdparty.duns;
    data.companyIdEncrypted = thirdparty.dunsEncrypted;
  }
  return data;
};

export const getDepositorOrTransmitter = (evidence) => {
  const metadata = evidence.metadata;
  if (evidence.issuer === "ALTARES") {
    return (
      evidence.transmitter && i18next.t(`transmitters.${evidence.transmitter}`)
    );
  }
  if (metadata?.depositor) {
    return metadata?.depositor;
  } else {
    return (
      (evidence.transmitter && i18next.t(`transmitters.${evidence.transmitter}`))
    );
  }
};
