import React, { useCallback, useContext, useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import _ from "lodash";
import { Context } from "../../../states/Store";
import { getInitialUsersTableSort, UsersDefaultVisibilityColumns, usersTableColumns } from "./UsersTableUtils";
import { ERROR } from "../../../services/common/Constants";
import i18next from "i18next";
import { getAccountUsers } from "../../../services/edge/UserService";
import UserCreationDialog from "./UserCreationDialog";
import UserDeleteConfirmDialog from "./UserDeleteConfirmDialog";
import UserReinviteConfirmDialog from "./UserReinviteConfirmDialog";
import EADataGrid from "../../../components/common/datatable/EADataGrid";
import UserEditDialog from "./UserEditDialog";
import useStyles from "../../../style/js-style/containers/users/users-list/UsersDataTableStyle";
import { CountSelectedRow } from "../../../components/common/datatable/CountSelectedRow";
import MenuAppBar from "../../../components/MenuAppBar";
import { UsersMultiSelectionMenu } from "./UsersMultiSelectionMenu";
import EAIcon from "../../../components/common/EAIcon";
import EAButton from "../../../components/common/EAButton";
import EAPagination from "../../../components/common/datatable/EAPagination";
import SkeletonDataGrid from "../../../components/common/skeleton/SkeletonDataGrid";
import EABackground from "../../../components/EABackground";
import {
  PrivilegeEnum,
  RoleBasedAccessControlContext,
  cleanRoleHierarchy,
  getSortedFlatRoles,
} from "../../../services/common/RolesUtils";
import { getAccountRolesNames } from "services/edge/RoleService";

function UsersDataTable({ active }) {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges, isGlobalAdmin } = useContext(RoleBasedAccessControlContext);
  const [users, setUsers] = React.useState([]);
  const [shownUsers, setShownUsers] = React.useState([]);
  const [assignableRoles, setAssignableRoles] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = React.useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = React.useState(false);
  const [columnsVisibility, setColumnsVisibility] = React.useState();
  const [isReinviteUserDialogOpen, setIsReinviteUserDialogOpen] = React.useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = React.useState(false);
  const [tableColumns, setTableColumns] = React.useState([]);
  const [selectedUsersIds, setSelectedUsersIds] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [usersSelected, setUsersSelected] = React.useState([]);
  const [sortModel, setSortModel] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [existingAccountRoles, setExistingAccountRoles] = React.useState([]);

  const fetchUsers = useCallback(() => {
    if (state.account) {
      setLoader(true);
      (async function fetchData() {
        try {
          const resp = await getAccountUsers(state.account.id);
          setLoader(false);
          setUsers(resp.content);
          setIsLoading(false)
        } catch (e) {
          const msg =
            (e?.message?.error && e?.message?.error[0]?.code) ||
            "INTERNAL_ERROR";
          dispatch({ type: "ALERT", alert: { type: ERROR, msg } });
          setLoader(false);
        }
      })();
    }
  }, [dispatch, state.account]);

  const fetchAccountRoles = useCallback(() => {
    if (state.account) {
      setLoader(true);
      (async function fetchData() {
        try {
          const resp = await getAccountRolesNames(state.account.id);
          setLoader(false);
          setExistingAccountRoles(resp.content);
          setIsLoading(false)
        } catch (e) {
          const msg =
            (e?.message?.error && e?.message?.error[0]?.code) ||
            "INTERNAL_ERROR";
          dispatch({ type: "ALERT", alert: { type: ERROR, msg } });
          setLoader(false);
        }
      })();
    }
  }, [dispatch, state.account]);

  const isMorePrivilegedThanUser = useCallback((user) => {
    if (isGlobalAdmin) {
      return true;
    } else {
      return !user.roles.some(roleName => !assignableRoles.map(r => r.label).includes(roleName));
    }
  }, [isGlobalAdmin, assignableRoles]);

  useEffect(() => {
    fetchUsers();
    fetchAccountRoles();
  }, [fetchUsers, fetchAccountRoles]);

  useEffect(() => {
    if (state.user) {
      setTableColumns(
        usersTableColumns(
          existingAccountRoles,
          openDeleteUsersPopup,
          openEditUsersPopup,
          openReinviteUsersPopup,
          hasAnyOfPrivileges,
          isMorePrivilegedThanUser
        )
      );
    }
  }, [state.user, hasAnyOfPrivileges, isMorePrivilegedThanUser, existingAccountRoles]);

  useEffect(() => {
    let usersAfterFilters = users;
    // Map thirdparties
    let mappedUsers = usersAfterFilters.map((user) => {
      return {
        id: user.id,
        name: user.name,
        firstname: user.firstname,
        function: user.function,
        email: user.email,
        phone: user.phone,
        invitationdate: user.invitationDate,
        invitedby: user.invitedby,
        roles: user.roles,
        status: user.status,
        lastactivitydate: user.lastActivityDate,
      };
    });

    // Apply Toolbar filters
    state.usersToolbarFilters
      .filter((x) => x.value)
      ?.forEach((filter) => {
        mappedUsers = mappedUsers.filter((doss) => {
          if (filter.type === "text") {
            return doss[filter.key]
              ?.toLowerCase()
              .includes(filter.value?.toLowerCase());
          }
          if (filter.type === "select") {
            return doss[filter.key] === filter.value;
          }
          if (filter.type === "date" && doss[filter.key]) {
            return filter.value?.isSame(doss[filter.key], "day");
          } else {
            return false;
          }
        });
      });
    setShownUsers(mappedUsers);
  }, [users, active, state.usersToolbarFilters]);

  useEffect(() => {
    if (!_.isEmpty(state.roles)) {
      let filteredRoles = [];

      if (isGlobalAdmin) {//If it's the global admin we start by the default role since all account roles are fetched
        const defaultRole = _.find(state.roles, { default: true });

        filteredRoles = defaultRole ? [defaultRole] : [];
      } else {
        filteredRoles = cleanRoleHierarchy(state.roles);
      }

      let flatRoles = getSortedFlatRoles(filteredRoles).map((r) => {
        return {
          value: r.id,
          label: r.name
        }
      });

      setAssignableRoles(flatRoles);
    } else {
      setAssignableRoles([]);
    }
  }, [state.roles, isGlobalAdmin])

  const openCreateUserPopup = () => {
    setIsCreateUserDialogOpen(true);
  };

  const openDeleteUsersPopup = (usersIds, user) => {
    setIsDeleteUserDialogOpen(true);
    setSelectedUsersIds([...usersIds]);
    setSelectedUser(user);
  };

  const openReinviteUsersPopup = (usersIds, user) => {
    setIsReinviteUserDialogOpen(true);
    setSelectedUsersIds(usersIds);
    setSelectedUser(user);
  };

  const openEditUsersPopup = (user) => {
    setIsEditUserDialogOpen(true);
    setSelectedUser(user);
  };

  const UsersCounter = () => {
    return (
      <div className={classes.userCounterWrapper}>
        <EAIcon icon={"person"} className={classes.metaCountImg} />
        <span className={classes.metaCountContent}>{users.length} {i18next.t("adminSettings.users")}{users.length > 1 && "s"}</span>
      </div>
    )
  }

  useEffect(() => {
    setColumnsVisibility(UsersDefaultVisibilityColumns());
    setSortModel(getInitialUsersTableSort());
  }, []);

  return (
    <div className={classes.root}>
      {/* Menu */}
      <MenuAppBar variant={"users"} />

      {/* Header */}
      <EABackground />
      <div className={classes.backgroundOverlay}></div>
      <div className={classes.mainHeaderWrapper}>
        <div className={classes.mainHeaderLeftSide}>
          <EAIcon icon={"users-rounded"} />
          <Typography variant={"h1"}>Liste des utilisateurs</Typography>
        </div>
        <div>
          <EAButton
            size="small"
            onClick={openCreateUserPopup}
            disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_USER) || state?.account?.sso}
            content={
              <>
                <span className={classes.crossBtn}>+</span>
                {i18next.t("usersTable.createBtnLabel")}
              </>
            }
            className={classes.createBtn}
          />
        </div>
      </div>

      {/* DataGrid */}
      <Paper elevation={0} className={classes.dataGridWrapper}>
        {!isLoading
          ? (
            <EADataGrid
              loading={loader}
              inputRows={shownUsers}
              columns={tableColumns}
              hasCustomPagination
              additionnalsLeftComponents={
                <>
                  <UsersCounter />
                  <CountSelectedRow
                    data={usersSelected}
                    variant={"ml2"}
                  />
                  <UsersMultiSelectionMenu
                    users={shownUsers}
                    usersSelected={usersSelected}
                    openDeleteUsersPopup={openDeleteUsersPopup}
                  />
                </>
              }
              selection={{
                items: usersSelected,
                setItems: setUsersSelected,
              }}
              clickable={true}
              initialVisibilityModel={columnsVisibility}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                if (newSortModel[0] !== sortModel[0]) {
                  setSortModel(newSortModel);
                }
              }}
            />
          )
          : (<SkeletonDataGrid mainStyle={{ height: "calc(100vh - 420px)" }} />)
        }
        <EAPagination isLoading={isLoading} />
      </Paper>

      {/* Popup : Invite user */}
      {isCreateUserDialogOpen && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_USER) && (
        <UserCreationDialog
          isOpen={isCreateUserDialogOpen}
          handleClose={() => setIsCreateUserDialogOpen(false)}
          handleOnCreate={() => fetchUsers()}
          shownRoles={assignableRoles}
        />
      )}

      {/* Popup : Delete user */}
      {isDeleteUserDialogOpen && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_USER) && (
        <UserDeleteConfirmDialog
          usersIds={selectedUsersIds}
          userEmail={selectedUser?.email}
          isOpen={isDeleteUserDialogOpen}
          handleClose={() => setIsDeleteUserDialogOpen(false)}
          handleOnDelete={() => fetchUsers()}
        />
      )}

      {/* Popup : Resend invitation */}
      {isReinviteUserDialogOpen && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_USER) && (
        <UserReinviteConfirmDialog
          userId={selectedUsersIds}
          userEmail={selectedUser?.email}
          isOpen={isReinviteUserDialogOpen}
          handleClose={() => setIsReinviteUserDialogOpen(false)}
        />
      )}

      {/* Popup : Edit user role */}
      {isEditUserDialogOpen && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_USER) && (
        <UserEditDialog
          user={selectedUser}
          isOpen={isEditUserDialogOpen}
          handleClose={() => setIsEditUserDialogOpen(false)}
          handleOnEdit={() => fetchUsers()}
          shownRoles={assignableRoles}
        />
      )}
    </div>
  );
}
export default UsersDataTable;
