import i18next from "i18next";
import { DocumentFormDataType, SIS_ID, TRUSTPAIR, INDUED, AcquisitionChannel, statusEngCode } from "./Constants";
import { isEmpty } from "lodash";

export const formatDepositorOrTransmitter = (depositor, transmitter) => {
  if (depositor) {
    return depositor;
  }
  if (transmitter) {
    return i18next.t(`transmitters.${transmitter}`);
  }
  return "";
};

export const shouldDisplayDocPanel = (evidence, statusInfo, evidenceExtractionDetails) => {
  return (
    !isEmpty(evidence?.formdata) ||
    evidence?.contents ||
    evidence?.metadata?.[SIS_ID] ||
    evidence?.metadata?.[TRUSTPAIR] ||
    evidence?.metadata?.[INDUED] ||
    evidenceExtractionDetails?.content ||
    statusInfo?.statusReason
  );
};

export const hasInformationsPlus = (evidence, statusInfo) => {
  if (!evidence) return false;

  // Add Evidence Generic Infos
  if (evidence.expirationDate && statusInfo?.statusReason !== "REFUS_CLIENT") {
    return true;
  }
  if (evidence.format && evidence.format !== "NA") {
    return true;
  }
  if (evidence.issuer && evidence.issuer !== "NA") {
    return true;
  }
  if (
    evidence.metadata?.effectif_urssaf ||
    evidence.metadata?.masse_salariale_urssaf ||
    evidence.metadata?.derniere_periode_analysee_urssaf
  ) {
    return true;
  }
  // Add Specific Data Infos
  if (evidence.specificData) {
    return true;
  }

  // Indued infos
  if (evidence?.metadata?.[INDUED]?.due_diligence) {
    return true;
  }

  // Add IBAN Bank Data Infos
  return !!evidence.formdata?.find(
    (fd) => fd.type === DocumentFormDataType.IBAN
  );
};

export const getStatusFromCode = (code, fromDocument = false) => {
  switch (code) {
    case statusEngCode.IN_PROGRESS:
      return {
        label: i18next.t(`viewer.status.statusLabel.${statusEngCode.IN_PROGRESS}`),
        icon: "doc-status/icon_en_cours",
        class: "controle_status"
      };

    case statusEngCode.INCONNU:
    case statusEngCode.ACTION_REQUIRED:
      return {
        label: i18next.t(`viewer.status.statusLabel.${statusEngCode.ACTION_REQUIRED}`),
        icon:  fromDocument ? "doc-status/icon_action_requise-documentVariant" : "doc-status/icon_action_requise",
        class: "action_status"
      };
    case statusEngCode.BELOW_EXPECTATION:
      return {
        label: i18next.t(`viewer.status.statusLabel.${statusEngCode.BELOW_EXPECTATION}`),
        icon: fromDocument ? "doc-status/icon_belowexpectation_variant" : "doc-status/icon_belowexpectation",
        class: "below_expectation_status"
      };
    case statusEngCode.NOT_VALID:  
      return {
        label: i18next.t(`viewer.status.statusLabel.${statusEngCode.NOT_VALID}`),
        icon: fromDocument ? "doc-status/error-documentVariant" : "doc-status/error",
        class: "invalide_status"
      };

    case statusEngCode.VALID:
      return {
        label: i18next.t(`viewer.status.statusLabel.${statusEngCode.VALID}`),
        icon: fromDocument ? "doc-status/checked-documentVariant" : "doc-status/checked",
        class: "valide_status"
      };
    case statusEngCode.MISSING:
      return {
        label: i18next.t(`viewer.status.statusLabel.${statusEngCode.MISSING}`),
        icon: "doc-status/help_rounded",
        class: "absent_status"
      };

    default:
      return null;
  }

};

export const getDossierStatusFromCode = (code) => {
  switch (code) {
    case statusEngCode.IN_PROGRESS:
      return {
        label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.IN_PROGRESS}`),
        icon: "doc-status/folder-en-cours",
        class: "controle_status",
        headerClass: "headerBackgroundEnCours"
      };

    case statusEngCode.INCONNU:
    case statusEngCode.ACTION_REQUIRED:
      return {
        label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.ACTION_REQUIRED}`),
        icon: "doc-status/folder-action-requise",
        class: "action_status",
        headerClass: "headerBackgroundActionRequise"
      };
    case statusEngCode.BELOW_EXPECTATION:
      return {
        label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.BELOW_EXPECTATION}`),
        icon: "doc-status/folder-belowexpectation",
        class: "below_expectation_status",
        headerClass: "headerBackgroundInsuffisant"
      };
    case statusEngCode.NOT_VALID:  
      return {
        label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.NOT_VALID}`),
        icon: "doc-status/folder-non-conforme",
        class: "invalide_status",
        headerClass: "headerBackgroundNonConforme"
      };
    case statusEngCode.VALID:
      return {
        label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.VALID}`),
        icon: "doc-status/folder-conforme",
        class: "valide_status",
        headerClass: "headerBackgroundCompleted"
      };
    case statusEngCode.MISSING:
      return {
        label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.MISSING}`),
        icon: "doc-status/folder-absent",
        class: "absent_status",
        headerClass: "headerBackgroundNotCompleted"
      };
    default:
      return null;
  }

};

export const isInvalidStatusReason = ["REFUS_CLIENT", "REFUS_SUPPORT", "INTERRUPTION_TIERS", "EXPIRATION", "CONTROLES_INVALIDES", "MISE_A_JOUR_DECLARANT", "MISE_A_JOUR_SUPPORT"]

export const isTransmittedByApi = (transmitter) => {
  return ["EATTESTATIONS", "MPS", "INDUED"].includes(transmitter);
}

// Document is shown to declarants if acquision is FO or DO_FO, document is JUSTIF_IMM
export const isShownToFO = (acquisition, documentCode) => {
  return [AcquisitionChannel.FO, AcquisitionChannel.DO_FO].includes(acquisition) || documentCode === 'JUSTIF_IMM'
}

export const canAnswerDocument = (doc) => doc.acquisition === "DO";
export const isUpdatableDocument = (doc) => ![statusEngCode.IN_PROGRESS, statusEngCode.MISSING].includes(doc.statusCode);