import React, {useCallback, useContext, useEffect, useState} from "react";
import i18next from "i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import { get as lodashGet } from "lodash";
import useStyles from "../../style/js-style/containers/notes/NoteInputStyle";
import EAAvatar from "components/common/EAAvatar";
import { Context } from "states/Store";
import { ERROR } from "services/common/Constants";
import EAButton from "../common/EAButton";
import {getAccountUsers} from "../../services/edge/UserService";
import { Mention } from 'primereact/mention';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import {useRequestLoading} from "../common/hooks/useRequestLoading";

/** Rendu d'un item de la liste de suggestion */
const ItemTemplate = ({suggestion}) => {
  const { classes } = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={classes.suggestionItemWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <EAAvatar
        userName={suggestion?.firstname + " " + suggestion?.name}
        size={23}
        fontSize={"10px"}
        variant={isHovered ? "" : "display-note"}
      />
      <span className={classes.suggestionItemWrapper}>
        {suggestion?.firstname} {suggestion?.name}
      </span>
    </div>
  );
}

const NoteInput = ({
  noteToUpdate = {}, // to use in case of update note
  fieldName,
  onCancel = () => {},
  isRequired = false,
  placeholder = '',
  noteToEdit = null,
  onChange = () => { },
  setLastEdited,
  setNoteToEdit,
  fetchNotes,
  createNote,
  updateNote,
  disabled
}) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const formMethods = useForm({
    mode: "onChange",
    delayError: 500
  });
  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
    handleSubmit,
  } = formMethods;
  const [state, dispatch] = useContext(Context);
  const [userName, setUserName] = useState();
  const [defaultValue, setDefaultValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [users, setUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState([noteToEdit?.listMentionedUsers]);
  const [isEscapePressed, setIsEscapePressed] = useState(false);

  // Récupère la liste des utilisateurs du compte sélectionné
  useEffect(() => {
    async function getUsers() {
      try {
        const result = await getAccountUsers( state.account.id );
        setUsers(
          result.content
            .filter(user => user.status === "INSCRIT")
            .map((user) => {
              return {
                fullName: user.firstname + " " + user.name,
                name: user.name,
                firstname: user.firstname,
                userId: user.id
              };
            })
        );
      } catch (error) {
        setUsers(null);
      }
    }
    if (state.account){
      getUsers();
    }
  }, [state.account]);

  // Met à jour la valeur du champ lorsque defaultValue change
  useEffect(() => {
    formMethods.setValue(fieldName, defaultValue);
  }, [defaultValue, fieldName, formMethods]);

  // Récupère la note sélectionnée pour l'édition
  useEffect(()=>{
    if(noteToEdit !== null){
      setDefaultValue(noteToEdit.contenu);
      formMethods.setValue(fieldName, noteToEdit.contenu);
      setSelectedUsersIds(noteToEdit.listMentionedUsers);
      formMethods.trigger();
      setIsDirty(true);
    }else{
      setDefaultValue("")
    }
  }, [noteToEdit, fieldName, formMethods])

  useEffect(() => {
    if (noteToUpdate?.prenomNom) {
      setUserName(noteToUpdate.prenomNom);
    } else {
      setUserName(`${state.user.given_name} ${state.user.family_name}`);
    }
  }, [noteToUpdate, state.user.given_name, state.user.family_name])

  /** Sauvegarde la note */
  const handleSave = async (e) => {
    e.preventDefault()
    const value = formMethods.getValues("note");
    let filteredUsers = [];

    // Met à jour une note existante
    if (noteToEdit !== null){
      try {
        // on vérifie quelles mentions ont changés
        const mentionRegexp = /@(.*?) /g;
        const matchedMentions = value?.match(mentionRegexp);
        if (matchedMentions && matchedMentions.length >0) {
          const formattedMentions = matchedMentions.map(mention => mention.slice(1, -1))
          let nomPrenomSelected = selectedUsersIds.map(su => su.nomPrenom);
          let t  = formattedMentions.filter(selectedUser => nomPrenomSelected.includes(selectedUser));
          filteredUsers = selectedUsersIds.filter(su => t.includes(su.nomPrenom))?.map(selectedUser => selectedUser.userOid);
        }
        startLoading();
        await updateNote(noteToEdit.noteOId, { content: value, selectedUsersIds:  filteredUsers  });
        setLastEdited(noteToEdit);
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "NOTE_UPDATE_ERROR" },
        });
      }finally {
        stopLoading();
      }

    // Créer une nouvelle note
    }
    else {
      try {

        if(selectedUsersIds.filter(d => d).length > 0){
          filteredUsers = selectedUsersIds.filter(d =>d ).map(selectedUser => selectedUser.userOid);
        }
        startLoading();
        const response = await createNote({ content: value, selectedUsersIds: filteredUsers ? filteredUsers : [] });
        setLastEdited(response?.content);
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "NOTE_CREATION_ERROR" },
        });
      }finally {
        stopLoading();
      }
    }

    fetchNotes();               // Met à jour les données affichées
    initiate()                  // Réinitialise le champ
  }

  /** Réinitialise le champ */
  const initiate = useCallback( () => {
    formMethods.reset();
    formMethods.setValue("note", "");
    formMethods.clearErrors();
    setIsDirty(false);
    setInputFocused(false);
    setNoteToEdit(null);
    setSelectedUsersIds([]);
    onCancel();
  }, [formMethods, onCancel, setNoteToEdit])

  // Ajoute un listener pour surveiller l'appui sur la touche "Echap"
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setIsEscapePressed(true);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
  }, []);

  // Reinitialise l'input a son état d'origine si appui sur la touche "Echap"
  useEffect(() => {
    if (isEscapePressed) {
      initiate();
      setIsEscapePressed(false);
    }
  }, [isEscapePressed, initiate]);

  /** Fonction de tri pour les mentions */
  const onSearch = (event) => {
    const search = event.query;
    let filteredResult = [];

    for (const user of users) {
      const name = user.name;
      const firstName = user.firstname;

      if(user && (name.toLowerCase().includes(search.toLowerCase()) || firstName.toLowerCase().includes(search.toLowerCase()))){
        filteredResult.push(user)
      }
    }
    setSuggestions(filteredResult);
  }

  return (
    <div className={classes.root}>
      <div style={{display: "flex"}}>

        {/* Avatar */}
        { noteToEdit === null &&
          <EAAvatar
            style={{ marginRight: "15px" }}
            userName={userName}
            disabled
          />
        }

        {/* Input */}
        <div className={(inputFocused || isDirty) ? classes.formWrapperFocused : classes.formWrapper}>
          <FormProvider {...formMethods}>
            <form id={"note-form-id"} >
              <Controller
                control={control}
                name={fieldName}
                disabled={disabled}
                rules={{
                  validate: (value) => {
                    if (isRequired) {
                      const isEmpty = !value || value.length === 0;
                      if (isEmpty) {
                        return i18next.t("infos.required");
                      } else {
                        clearErrors(fieldName);
                      }
                    }

                    return "";
                  }
                }}
                render={({ field }) => (
                  <div className={classes.mentionRoot}>
                    <Mention
                      {...field}
                      inputId={`${fieldName}-input-id`}
                      onChange={(e) => {
                        formMethods.setValue(fieldName, e.target.value);
                        onChange(e.target.value);
                        trigger(fieldName);
                        e.target.value !== ""
                          ? setIsDirty(true)
                          : setIsDirty(false);
                      }}
                      value={formMethods.getValues(fieldName)}
                      onBlur={() => setInputFocused(false)}
                      onFocus={() => setInputFocused(true)}
                      suggestions={suggestions}
                      disabled={disabled}
                      onSearch={onSearch}
                      autoResize
                      field="fullName" // le champ qui sera affiché
                      placeholder={placeholder}
                      defaultValue={defaultValue}
                      itemTemplate={(suggestion) => <ItemTemplate suggestion={suggestion}/>}
                      onSelect={(e) => {
                        setSelectedUsersIds(oldArray => [...oldArray, {nomPrenom: e.suggestion.firstname+e.suggestion.name, userOid: e.suggestion.userId}])
                      }}
                      inputClassName={noteToEdit !== null || (inputFocused || isDirty) ? classes.inputTextFocused : classes.inputText}
                      scrollHeight={"300px"}
                    />
                  </div>
                )}
              />

              {/* Validation */}
              { ( noteToEdit !== null || inputFocused || isDirty ) &&
                <div className={classes.btnWrapper}>
                  <EAButton
                    content={i18next.t("valid")}
                    className={classes.validButton}
                    onClick={(e) => handleSubmit(handleSave(e))}
                    disabled={isRequestLoading}
                  />
                </div>
              }
            </form>
          </FormProvider>
        </div>
      </div>

      {/* Error */}
      { !!lodashGet(errors, `${fieldName}`) && noteToEdit !== null && (
        <div className={classes.error}>
          <FormHelperText className="Mui-error" style={{ position: "relative" }}>
            {lodashGet(errors, `${fieldName}.message`)}
          </FormHelperText>
        </div>
        )
      }

      {/* Cancel */}
      {(noteToEdit !== null || inputFocused || isDirty) &&
        <div className={classes.cancelNoteWrapper} style={{marginLeft: noteToEdit !== null ? "0" : "50px"}}>
          <span className={classes.cancelNoteText}>
            {i18next.t("notes.pressEchapFor")}
          </span>
          <span className={classes.cancelNoteLink} onClick={() => initiate()}>
            &nbsp;{i18next.t("cancel").toLowerCase()}.
          </span>
        </div>
      }
    </div>
  );
}

export default NoteInput;
