import React, { useContext, useState } from "react";
import i18next from "i18next";
import {
  Grid,
  Box,
} from "@mui/material";
import EADialog from "../../../../components/common/EADialog";
import RuleForm from "../form/RuleForm";
import {ERROR, SUCCESS, WARNING} from "../../../../services/common/Constants";
import { Context } from "../../../../states/Store";
import { toggleRuleStatus } from "../../../../services/edge/RuleService";
import RuleEditConfirmDialog from "./RuleEditConfirmDialog";
import RuleSummary from "../steps/RuleSummary";
import RuleConfiguration from "../steps/RuleConfiguration";

const FORM_ID = "rule-update-form-id";

const RuleEditDialog = ({
  isOpen,
  rule,
  onClose,
  onValidateSuccess,
  onDeleteOpen,
  criterionFieldOptions
}) => {
  const [state, dispatch] = useContext(Context);
  const [currentStep, setCurrentStep] = useState("CONFIGURATION"); // CONFIGURATION, SUMMARY, FINALISATION
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const handleToggleRuleStatus = async (rule) => {
    try {
      await toggleRuleStatus(state.account.id, rule.id, null);

      dispatch({
        type: "ALERT",
        alert: rule.enabled ?
          { type: WARNING, msg: i18next.t("rules.notifications.deactivate", { name: `${rule.name}` }) }
          :
          { type: SUCCESS, msg: i18next.t("rules.notifications.activate", { name: `${rule.name}` }) }
      });

      onValidateSuccess();
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  };

  const getCustomDialogStyle = (step) => {
    const title = {
      marginBottom: "17px !important",
      fontSize: "25px",
      color: "#16161E",
      fontWeight: 600
    }

    return (
      step !== "FINALIZATION"
        ? {
        backgroundColor: "#F2F3F7 !important",
        padding: "42px !important",
          "& h2": title
        }
        : {
        padding: "16px 28px !important",
          "& h2": title
      }
    )
  }

  return (
    <>
    <EADialog
      isOpen={isOpen}
      title={
        ["CONFIGURATION", "SUMMARY"].includes(currentStep)
          ? i18next.t("rules.updateDialog.configurationStepTitle")
          : i18next.t("rules.updateDialog.finalizationStepTitle")
      }
      onCancel={() => {
        onClose();
      }}
      formId={FORM_ID}
      hasActions={false}
      fullWidth={true}
      maxWidth={["CONFIGURATION", "SUMMARY"].includes(currentStep) ? "popuplg" : "popupsm"}
      customDialogStyle={getCustomDialogStyle(currentStep)}
      crossVisible={!["FINALIZATION"].includes(currentStep)}
    >
      <RuleForm
        rule={rule}
        formId={FORM_ID}
        submitHandler={() => {/*Do nothing, the submit handler is passed to the final step*/ }}
      >
        <Box sx={{ display: ["CONFIGURATION", "SUMMARY"].includes(currentStep) ? 'block' : 'none' }}>
          {/* <ConcernCounter count={5} type={"Tiers"} /> */}
          <Grid container spacing={"10px"}>
             <Grid item xs={12}>
              <RuleConfiguration
                open={currentStep === "CONFIGURATION"}
                criterionFieldOptions={criterionFieldOptions}
                onCancel={() => {
                  onClose()
                }}
                onContinue={() => {
                  setCurrentStep("SUMMARY")
                }}
                isEditionMode={true}
                name={rule.name}
              />
            </Grid>

          {rule && (
            <Grid item xs={12}>
              <RuleSummary
                open={currentStep === "SUMMARY"}
                criterionFieldOptions={criterionFieldOptions}
                onCancel={() => {
                  setCurrentStep("CONFIGURATION")
                }}
                onContinue={() => setIsConfirmDialogOpen(true)}
                onDelete={() => {
                  onDeleteOpen();
                }}
                onDeactivate= {() => {
                  handleToggleRuleStatus(rule);
                }}
                disabled={currentStep === "CONFIGURATION"}
                isEditionMode={true}
                isActiveRule={rule.enabled}
              />
            </Grid>
          )}
          </Grid>
        </Box>
      {/* PopUp : on edit confirm */}
      {isConfirmDialogOpen && (
        <RuleEditConfirmDialog
          rule={{...rule,
                  status: rule.enabled ? 'active' : 'inactive'}}
          isOpen={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          onValidateSuccess={() => {
            onValidateSuccess();
            setIsConfirmDialogOpen(false);
          }}
        />
      )}
      </RuleForm>
    </EADialog>


    </>
    )
}

export default RuleEditDialog;
