import React, { useState, useContext, useCallback } from "react";
import { Trans } from "react-i18next";

import EADialog from "components/common/EADialog";
import { ERROR, SUCCESS } from "services/common/Constants";
import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";

import { Context } from "states/Store";
import { createCalculation } from "services/edge/CalculatorService";

import CalculatorForm from "../form/CalculatorForm";
import DeclarationStep from "./steps/DeclarationStep";
import InitialStep from "./steps/InitialStep";
import { CalculatorFormSteps, cleanFormData } from "services/common/CalculatorUtils";

function CalculatorCreateDialog({ isOpen, onClose, onValidateSuccess, formId }) {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [currentStep, setCurrentStep] = useState(CalculatorFormSteps.INITIAL); // INITIAL, DECLARATION, FINAL

  const handleSubmit = useCallback(async (data) => {
    try {
      await createCalculation(state.account.id, cleanFormData(data), null);
      const name = data.name;
      const message = data.enabled ?
        <Trans i18nKey="calculator.notifications.toggleActivation"> Le calcul <span style={{ color: "#0B2810", fontWeight: 600 }}>"{{ name }}"</span> a bien été activé.</Trans>
        :
        <Trans i18nKey="calculator.notifications.save"> Le calcul <span style={{ color: "#0B2810", fontWeight: 600 }}>"{{ name }}"</span> a bien été enregistré.</Trans>

      dispatch({
        type: "ALERT",
        alert: { type: SUCCESS, msg: message },
      });

      onValidateSuccess();
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  }, [state.account, dispatch, onValidateSuccess]);

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={() => {
        onClose();
      }}
      hasActions={false}
      maxWidth={[CalculatorFormSteps.INITIAL].includes(currentStep) ? "popupxs" : "popupxl"}
      fullWidth
      customDialogStyle={{ backgroundColor: [CalculatorFormSteps.INITIAL].includes(currentStep) ? "" : "#F2F3F7 !important", padding: [CalculatorFormSteps.INITIAL].includes(currentStep) ? "" : "42px !important" }}
    >
      <div className={[CalculatorFormSteps.INITIAL].includes(currentStep) ? classes.root : classes.rootDeclaration}>
        <CalculatorForm
          formId={formId}
          submitHandler={() => { /* Do nothing */ }}
        >
          {/* Initialisation d'un nouveau calcul */}
          {[CalculatorFormSteps.INITIAL].includes(currentStep) &&
            <InitialStep
              onCancel={() => {
                onClose();
              }}
              onContinue={() => {
                setCurrentStep(CalculatorFormSteps.DECLARATION);
              }}
            />
          }

          {/* Déclaration des variables/branches */}
          {[CalculatorFormSteps.DECLARATION].includes(currentStep) &&
            <DeclarationStep
              onClose={onClose}
              onCancel={() => {
                setCurrentStep(CalculatorFormSteps.INITIAL);
              }}
              onContinue={handleSubmit}
            />
          }
        </CalculatorForm>
      </div>
    </EADialog>
  )
}

export default CalculatorCreateDialog;
