import { getEnv } from "config/Config";
import { eaFetch } from "services/common/ApiUtils";
import { objToQueryString } from "services/common/ApiUtils";

export async function refreshThirdpartiesIndued(accountId, thirdpartiesSelected, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/indued/refresh`,
    thirdpartiesSelected
  );
}

export async function launchThirdpartiesNewDueligence(accountId, thirdpartiesSelected, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/indued/due_diligence`,
    thirdpartiesSelected
  );
}


export async function getInduedScoresCount(accountId, induedScoreFilters, dispatch, signal) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/risks/indued?${objToQueryString(induedScoreFilters)}`,
    null, null, null, signal
  );
}