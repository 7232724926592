import React, { useContext, useState } from "react";
import i18next from "i18next";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { ERROR } from "../../../../services/common/Constants";
import { Context } from "../../../../states/Store";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { isEmpty } from "lodash";
import EAInputBloc from "../../../../components/common/EAInputBloc";
import EAButton from "../../../../components/common/EAButton";
import { validateRule, RULE_NAME_MAX_LENGTH, mapRulesCriteria } from "../../../../services/common/RulesUtils";
import useInputStyle from "../../../../style/js-style/common/inputStyle";
import useStyles from "../../../../style/js-style/containers/administration/rules/RuleFormsStyle";
import { isRuleNameAuthorized } from "../../../../services/edge/RuleService";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import RuleActivationErrors from "../../../../components/rules/RuleActivationErrors";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";

const RuleFinalization = ({ onCancel, onContinue, isRequestLoading }) => {
  const [state, dispatch] = useContext(Context);
  const [actionsListEmpty, setActionsListEmpty] = useState(false);
  const [noDossierSelected, setNoDossierSelected] = useState(false);
  const [hasEmptyRefs, setHasEmptyRefs] = useState(false);
  const [multipleSameActionNotAllowed, setMultipleSameActionNotAllowed] = useState(false);
  const [canRegisterAndEnable, setCanRegisterAndEnable] = useState(true);

  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const formMethods = useFormContext();
  const { classes: inputClass } = useInputStyle();
  const { classes } = useStyles();

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    trigger,
  } = formMethods;

  const actions = useWatch({ control, name: "actions" });

  const submitForm = (data) => {
    data = {
      ...data,
      criteria: mapRulesCriteria(data.criteria.filter((x) => x.field !== "")),
      actions: data.actions.filter((x) => x.dossierTypeCode !== ""),
    };

    if (isEmpty(errors)) {
      onContinue(data);
    }
  };

  const handleFinalizeSave = () => {
    handleSubmit(submitForm)();
  };

  const handleFinalizeEnable = () => {
    trigger("name");
    const activationErrors = validateRule(actions);

    if (!activationErrors) {
      handleSubmit(submitForm)();
    }

    if (activationErrors === 'no-actions') {
      setActionsListEmpty(true);
      setCanRegisterAndEnable(false);
    }
    if(activationErrors === "multiple-actions-not-allowed") {
      setCanRegisterAndEnable(false);
      setMultipleSameActionNotAllowed(true);
    }
  };

  const isValueAuthorized = async (value) => {
    try {
      const resp = await isRuleNameAuthorized(state.account.id, value, null);

      return !!resp.content;
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  };

  const validateRuleName = AwesomeDebouncePromise(async (value) => {
    let isValid = false;
    let typeErrMsg = "";

    if (value.length === 0) {
      typeErrMsg = i18next.t("infos.required");
    } else if (value.length > RULE_NAME_MAX_LENGTH) {
      typeErrMsg = i18next.t(
        "rules.finalization.fields.name.errors.maxLengthExceeded",
        {
          length: RULE_NAME_MAX_LENGTH,
        }
      );
    } else {
      let isRuleNameUnique = await isValueAuthorized(value);
      if (!isRuleNameUnique) {
        typeErrMsg = i18next.t(
          "rules.finalization.fields.name.errors.notUnique"
        );
      } else {
        isValid = true;
      }
    }

    if (isValid) {
      clearErrors(`name`);
    } else {
      setError(`name`, { type: "manual", message: typeErrMsg });
      return typeErrMsg;
    }
  }, 500);

  return (
    <div>
      <Grid item xs={12}>
        <RuleActivationErrors
          actionsListEmpty={actionsListEmpty}
          noDossierSelected={noDossierSelected}
          hasEmptyRefs={hasEmptyRefs}
          multipleSameActionNotAllowed={multipleSameActionNotAllowed}
          onActionsListEmptyClose={setActionsListEmpty}
          onNoDossierSelectedClose={setNoDossierSelected}
          onEmptyRefsClose={setHasEmptyRefs}
          onMultipleSameActionNotAllowedClose={setMultipleSameActionNotAllowed}
        />
        <EAInputBloc
          label={`${i18next.t("rules.finalization.fields.name.label")}*`}
          customLabelStyle={{ fontSize: "14px" }}
        >
          <>
            <Controller
              control={control}
              name={`name`}
              defaultValue={``}
              rules={{
                validate: (value) => validateRuleName(value),
              }}
              onChange={(e) => {
                setValue(`name`, e.target.value);
              }}
              render={({ field }) => (
                <TextField
                  className={inputClass.inputText}
                  id="rule-name-input-id"
                  error={!!errors.name}
                  fullWidth
                  {...field}
                  placeholder={i18next.t(
                    `rules.finalization.fields.name.placeholder`
                  )}
                />
              )}
            />

            {!!errors.name && (
              <FormHelperText className="Mui-error">
                {errors.name.message}
              </FormHelperText>
            )}
          </>
        </EAInputBloc>
      </Grid>

      <div className={classes.feedBackButtonWrapper}>
        <EAButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
            setCanRegisterAndEnable(true);
            setActionsListEmpty(false);
            setNoDossierSelected(false);
            setHasEmptyRefs(false);
            setMultipleSameActionNotAllowed(false);
            clearErrors("name");
          }}
          content={i18next.t("rules.finalization.actions.cancel")}
          colorVariant="secondary"
          customStyle={{ minWidth: "80px", width: "80px", borderRadius: "12px" }}
        />

        <EAButton
          onClick={(e) => {
            e.preventDefault();
            setValue(`enabled`, false);
            handleFinalizeSave();
          }}
          disabled={!isEmpty(errors) || isRequestLoading}
          content={i18next.t("rules.finalization.actions.register")}
          colorVariant={"primaryLight"}
          customStyle={{ width: "124px", borderRadius: "12px" }}
        />

        <EAButton
          onClick={(e) => {
            e.preventDefault();
            if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS)) {
              setValue(`enabled`, true);
              handleFinalizeEnable();
            }
          }}
          disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS) || !isEmpty(errors) || !canRegisterAndEnable || isRequestLoading}
          content={i18next.t("rules.finalization.actions.registerAndEnable")}
          customStyle={{ width: "192px", borderRadius: "12px" }}
        />
      </div>
    </div>
  );
};

export default RuleFinalization;
