import { makeStyles } from 'tss-react/mui';
import {darkGrey, orangeColor} from "../../../globalStyle";

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: "22px",
  },
  tpSearch: {
    padding: "1.5rem",
  },
  tpSearchContainer: {
    display: "flex",
    flexDirection: "column",
    "& .MuiInputLabel-root": {
      fontSize: "0.75rem",
      marginBottom: "0.5rem",
      color: darkGrey,
    },
    "& .MuiTypography-body1": {
      fontSize: "0.75rem",
      color: darkGrey,
    },
    "& .MuiSelect-root": {
      background: "white",
      padding: "0.5rem 0.75rem",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  countriesPaper: {
    maxHeight: "50%",
  },
  searchByRadios: {
    marginBottom: "0.5rem",
    "& .searchRadiosContainer": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiFormGroup-root": {
      display: "inline",
      marginLeft: "1rem",
    },
    "& .MuiRadio-root": {
      marginRight: "0.25rem",
    },
  },
  tpSearchBtn: {
    marginTop: "1rem",
    textTransform: "none",
    float: "right",
  },
  divider: {
    margin: "2rem 0",
  },
  resultWarning: {
    color: orangeColor,
  },
  tableContainer: {
    padding: "0 !important",
  },
}));

export default useStyles;
