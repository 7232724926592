import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  inputBlocContainer: {
    width: "100%",
    [`${theme.breakpoints.down('md')}`]: {
      paddingBottom: "1rem",
    },
    "& .MuiFormLabel-root": {
      whiteSpace: "nowrap",
    },
    "& .MuiFormGroup-root": {
      width: "100%",
      paddingTop: "0.5rem"
    },
    "&:first-child": {
      marginRight: "1rem"
    }
  },
  formContainer: {
    backgroundColor: "#F2F3F7",
    border: `1px solid #F2F3F7`,
    borderRadius: "5px",
    paddingBottom: "24px",
    paddingTop: "24px",
    marginLeft: "18px",
    marginTop: "28px",
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.white
    }
  },
  label: {
    whiteSpace: "nowrap",
    display: "inline-block",
    fontWeight: 700,
    color: theme.palette.grey.grey500,
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  advancedSearchToggle: {
    color: theme.palette.grey.grey500,
    textTransform: "uppercase",
    fontSize: "10px",
    marginTop: "14px",
    fontWeight: "600"
  },
  advancedSearchIcon: {
    alignSelf: "flex-end"
  },
  advancedSearchContainer: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    marginLeft: "16px",
    marginTop: "16px",
    border: `1px solid ${theme.palette.grey.grey100}`,
    borderRadius: "5px"
  },
  mainSearchDivider: {
    width: "98%",
    margin: "16px",
    borderColor: "#E2E2EA"
  },
  advancedSearchDivider: {
    width: "99%",
    margin: "16px",
    borderColor: "#E2E2EA"
  }
}));

export default useStyles;
