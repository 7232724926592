export const ERROR = 'error'
export const INFO = 'info'
export const SUCCESS = 'success'
export const WARNING = 'warning'

export const SEARCH_TYPE_PATTERNS = {
    DUNS : {
        pattern : /^\d{9}$/, msg : "infos.duns"
    },
    SIRET : {
        pattern : /^\d{14}$/, msg : "infos.localId"
    },
    SIREN : {
        pattern : /^\d{9}$/, msg : "infos.localId"
    },
    VAT : {
        pattern : /^[A-Za-z]{2}[A-Za-z0-9]+$/, msg : "infos.vat"
    },
    VAT_FR : {
        pattern : /^(FR)[0-9]{11}$/, msg : "infos.vat"
    }
}

export const DATA_TABLE_ACTIONS = {
    VIEW: {
        label: "view",
        icon: "modify-action",
        tooltip: "show",
        tooltipDisabled: "showDisabled",
    },
    ANSWER: {
        label: "answer",
        icon: "answer",
        tooltip: "answer",
        tooltipDisabled: "answerDisabled",
    },
    UPDATE_ANSWER: {
        label: "answerUpdate",
        icon: "answer_blue",
        tooltip: "answerUpdate",
        tooltipDisabled: "answerUpdateDisabled",
    },
}

export const DocumentFormDataType = {
    IBAN: 'iban',
    AMOUNT: 'amount',
    NUMBER: 'number',
    DECIMAL: 'decimal',
    PHONE: 'phone',
    EMAIL: 'email',
    DATE: 'date',
    TEXT: 'text',
    STRING: 'string',
    CHOICES: 'choices',
    RADIO: 'radio',
    CHECKBOX: 'checkobox',
    UNKNOWN: 'unknown'
}

export const AttributeType = {
    DATE: "date",
	LIST_MULTIPLE: "list_multiple",
	LIST_UNIQUE: "list_unique",
	DECIMAL: "decimal",
	INTEGER: "integer",
	PERCENTAGE: "percentage",
    STRING: "string",
	BOOLEAN: "boolean"
}
export const ATTRIBUTE_MAX_LABEL_LENGTH = 100;
export const ATTRIBUTE_MAX_LIST_VALUE_LENGTH = 100;
export const ATTRIBUTE_MAX_TEXT_VALUE_LENGTH = 255;
export const ATTRIBUTE_VALUES_MIN_SIZE = 2;

export const BANK_DATA_KEYS = [
    'bank_data_bank',
    'bank_data_bic',
    'bank_data_address',
    'bank_data_zip',
    'bank_data_city',
    'bank_data_country_iso',
    'bank_data_phone',
    'bank_data_fax',
    'bank_data_email',
    'bank_data_www',
    'sepa_data_sct',
    'sepa_data_sdd',
    'sepa_data_cor1',
    'sepa_data_b2b',
    'sepa_data_scc'
];

// eslint-disable-next-line
export const REGEXP_EMAIL = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const REGEXP_NAME = /^([a-zA-ZàáâãäåæçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ'ß'\-\s])+$/i;
export const REGEXP_PASSWORD = new RegExp("(?:(?=.*\\d)(?=.*[A-ZÀ-Ÿ])(?=.*[a-zà-ÿ])|(?=.*\\d)(?=.*[^A-ZÀ-Ÿa-zà-ÿ0-9])(?=.*[a-zà-ÿ])|(?=.*[^A-ZÀ-Ÿa-zà-ÿ0-9])(?=.*[A-ZÀ-Ÿ])(?=.*[a-zà-ÿ])|(?=.*\\d)(?=.*[A-ZÀ-Ÿ])(?=.*[^A-ZÀ-Ÿa-zà-ÿ0-9]))(?!.*(.)\\1{2,})[A-ZÀ-Ÿa-zà-ÿ0-9!~<>,;:_=?*+#.\"&§%°()\\|\\[\\]\\-\\$\\^\\@\\/]{10,128}");
export const REGEXP_INTEGER = /^[+-]?\d+$/i;
export const REGEXP_DECIMAL = /^[+-]?\d+([.,]?\d+)?$/i;
export const REGEXP_PERCENTAGE = /^[+-]?\d+([.,]?\d+)?%$/i;
export const REGEXP_SEMICOLON = /[;]+/i;
export const REGEXP_DOSSIER_MODEL_CODE = /^[A-Z_]+$/;


export const FREE = "FREE"

export const DefaultSelectProps = {
    MenuProps: {
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        style: { zIndex: 1301 }
    }
}

export const Subscription = {
    SIS_ID: "SISID",
    TRUSTPAIR: "TRUSTPAIR",
    INDUED: "INDUED",
    TRANSPARENCY: "TRANSPARENCY", 
    DATABLOCKS_FINANCIAL_STRENGTH: "FINANCIALSTRENGTH"
}

//TODO: use subscription instead of these ?!
export const SIS_ID = "sis_id";
export const TRUSTPAIR = "trustpair";
export const INDUED = "indued";

export const EDGE_DATE_FORMAT = "YYYY-MM-DD";

export const statusEngCode = {
    NOT_VALID :"NOT_VALID", 
    VALID: "VALID", 
    BELOW_EXPECTATION: "BELOW_EXPECTATION",
    MISSING: "MISSING", 
    IN_PROGRESS: "IN_PROGRESS", 
    ACTION_REQUIRED: "ACTION_REQUIRED", 
    INCONNU: "INCONNU"
};

export const AcquisitionChannel = Object.freeze({
    FO: "FO",
    DO: "DO",
    DO_FO: "DO_FO",
    PLATFORM: "PLATFORM",
});

export const ThirdpartyAdditionalFieldsNames = Object.freeze({
    SCORES: "scores",
    ATTRIBUTES: "attributes",
    CALCULATED_ATTRIBUTES: "calculatedAttributes",
    INDICATORS: "indicators",
});

export const ThirdpartyScoresFieldsNames = Object.freeze({
    INDUED: "indued",
    TRANSPARENCY: "transparency",
    FINANCIAL_STRENGTH_SCORE: "financialStrengthScore",
    FINANCIAL_STRENGTH_RATING: "financialStrengthRating",
});

export const DossiersAdditionalFieldsNames = Object.freeze({
    ATTRIBUTES: "attributes"
});

export const DOMTOMList = [
    "GP",
    "MQ",
    "GF",
    "YT",
    "RE",
    "BL",
    "MF",
    "PM",
    "WF",
    "NC",
    "PF"
];

export const preferredCountries = [
    "fr",
    "de",
    "it",
    "gb",
    "es",
    "be",
    "nl",
    "ch",
    "lu",
    "pt",
    "us",
    "cn",
  ];

export const FieldType = {
    TEXT: "TEXT",
    NUMERIC: "NUMERIC",
    DATE: "DATE",
    BOOLEAN: "BOOLEAN",
    CUSTOM: "CUSTOM",

    ATTRIBUTE: "ATTRIBUTE",
    RISK: "RISK",
    CALCULATED_ATTRIBUTE: "CALCULATED_ATTRIBUTE",
    INDICATOR: "INDICATOR",
    SOURCE_VARIABLE: "SOURCE_VARIABLE",
    FORM_FIELD: "FORM_FIELD",
    ACTIVITY: "ACTIVITY" 
};

export const FieldDisplayVariant = {
    STANDARD: "STANDARD", 
    AUTOCOMPLETE: "AUTOCOMPLETE"
}

export const Operator = {
    EQUAL: "EQUAL",
    NOT_EQUAL: "NOT_EQUAL",

    GREATER_THAN: "GREATER_THAN",
    GREATER_OR_EQUAL: "GREATER_OR_EQUAL",

    LESS_THAN: "LESS_THAN",
    LESS_OR_EQUAL: "LESS_OR_EQUAL",

    BETWEEN: "BETWEEN",

    IS_NULL: "IS_NULL",
    NOT_NULL: "NOT_NULL",

    CONTAINS: "CONTAINS",
    NOT_CONTAINS: "NOT_CONTAINS",

    STARTS_WITH: "STARTS_WITH",
    NOT_STARTS_WITH: "NOT_STARTS_WITH",

    ENDS_WITH: "ENDS_WITH",
    NOT_ENDS_WITH: "NOT_ENDS_WITH",

    IN_SET: "IN_SET",
    NOT_IN_SET: "NOT_IN_SET"

}

export const Exports = {
    CSV: "CSV",
    EXCEL : "EXCEL"
}

export const ActivityLevel = {
    SIC_MAJOR: 1,
    SIC_2_DIGITS: 2,
    SIC_3_DIGITS: 3,
    SIC_4_DIGITS: 4,
    SIC_6_DIGITS: 6,
    SIC_8_DIGITS: 8,
}
