import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataGridWrapper: {
    marginTop: "17px",
    backgroundColor: "transparent",
    maxHeight: "calc(100vh - 230px)",
    overflowY: "auto",
  },

  dataGridWrapperWithFilter: {
    maxHeight: "calc(100vh - 425px)",
  },
}));

export default useStyles;
