import React, {useEffect} from "react";
import i18next from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormHelperText,
  MenuItem,
  Select
} from "@mui/material";
import { find, get as lodashGet } from "lodash";
import useStyles from "style/js-style/containers/administration/roles/dialogsStyle";
import _ from "lodash";

const RoleSelectInput = ({
  fieldName,
  isRequired = false,
  label = '',
  placeholder = '',
  options,
  onChange = () => { },
  validate = () => { return null },
  variant = 'standard', //mui variant
  labelVariant = 'standard', //standard, chip
  subTitle,
  setValidIsDisabled
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    clearErrors,
  } = formMethods;

  useEffect(()=> {
    if (formMethods.formState && setValidIsDisabled){
      setValidIsDisabled(!_.isEmpty(formMethods.formState.errors))
    }
  },[formMethods.formState, setValidIsDisabled])

  return (
    <>
      <span className={classes.label}>
        {label}
      </span>
      {subTitle &&
        <span className={classes.subTitle}> {subTitle} </span>
      }
      <Controller
        control={control}
        name={fieldName}
        rules={{
          validate: (value) => {
              if (isRequired) {
                  const isEmpty = !value || value.length === 0;
                  if (isEmpty) {
                      return i18next.t("infos.required");
                  } else {
                      clearErrors(fieldName);
                  }
              }

              return validate(value);
          }
      }}
        render={({ field }) => (
          <Select
            {...field}
            value={formMethods.getValues(fieldName) || ""}
            id={`${fieldName}-input-id`}
            className={variant === "large" ? classes.inputSelectLarge : classes.inputSelect}
            variant="outlined"
            renderValue={(selected) => {
              if (selected === "") {
                return (
                  <div className={classes.selectPlaceholder}>
                    {i18next.t("roles.popup.parentPlaceholder")}
                  </div>
                )
              } else {
                let entry = find(options, { value: selected });

                return (
                  <span className={classes.selectItem}>
                    {(entry === undefined) ? selected : entry.label}
                  </span>
                );
              }
            }}
            error={!!lodashGet(errors, `${fieldName}`)}
            onChange={(e) => {
              setValue(fieldName, e.target.value);
              onChange(e.target.value);
              trigger(fieldName);
            }}
            displayEmpty
          >
            {options?.map((option, index) => (
              <MenuItem key={`${option.value}-${index}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {
        !!lodashGet(errors, `${fieldName}`) && (
          <FormHelperText className="Mui-error">
            {lodashGet(errors, `${fieldName}.message`)}
          </FormHelperText>
        )
      }
    </>
  );
}

export default RoleSelectInput;
