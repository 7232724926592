import i18next from "i18next";
import React from "react";
import RecapBloc from "./RecapBloc";
import useStyles from "../../style/js-style/components/attributes/EditAttributeRacapStyle";
import clsx from "clsx";

const EditAttributeRecap = ({ deletedValues, newValues, updatedValues, updateLabel, bondType, concernedRules =[] }) => {
  const { classes } = useStyles();
  return (
    <>
      <span className={classes.subTitle}>
        {i18next.t("attributes.editRecapText")}
      </span>
      <div className={classes.mainContent}>
          <div className={classes.modifyBloc}>
            <RecapBloc
              icon={bondType === "dossier" ? "dossier-label_green" : "thirdparty-label_green2"}
              extension="svg"
              text={i18next.t("attributes.editLabel")}
              values={updateLabel}
              isMultiValue
            />
          </div>
        {updatedValues?.length > 0 && (
          <div className={classes.modifyBloc}>
            <RecapBloc
              icon="pen"
              extension="svg"
              text={i18next.t("attributes.editUpdatedValues")}
              values={updatedValues}
              isMultiValue
            />
          </div>
        )}
        {newValues?.length > 0 && (
          <div className={classes.modifyBloc}>
            <RecapBloc
              icon="cross_green"
              extension="png"
              text={i18next.t("attributes.editNewValues")}
              values={newValues}
            />
          </div>
        )}
        {deletedValues?.length > 0 && (
          <div className={clsx(classes.modifyBlocLast, classes.modifyBloc)}>
            <RecapBloc
              icon="delete_red"
              extension="svg"
              text={i18next.t("attributes.editDeletedValues")}
              values={deletedValues}
            />
          </div>
        )}
        {concernedRules?.length > 0 && (
          <div className={clsx(classes.modifyBlocLast, classes.modifyBloc)}>
            <RecapBloc
              icon="pause_circle_filled"
              extension="svg"
              text={i18next.t("attributes.deactivateRule")}
              values={concernedRules}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default EditAttributeRecap;
