import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "6px",
    padding: "10px 15px 5px 15px",
    lineHeight: "140%",
    maxWidth: "500px",
    marginLeft: "10px",
    height: "100%",
    position: "relative",
    minWidth: "194px",
    minHeight: "67px",
  },
  rootDO: {
    display: "flex",
    flexDirection: "row",
    minHeight: "57px",
    minWidth: "194px",
    maxWidth: "500px !important",
    position: "relative",
    marginLeft: "10px",
    height: "57px !important",
    padding: "5px !important",
    backgroundColor:  theme.palette.primary.warning200,
    alignItems: "center",
    gap: "10px",
  },
  displayRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  displayRowTitle: {
    flexDirection: "row-reverse",
  },
  btnContainer: {
    background: "#FFFFFF",
    borderRadius: "6px",
    padding: "10px",
    position: "relative",
    left: "-13px",
    top: "11.5px",
    width: "280px",
    height: "38px",
  },
  btnContainerDO: {
    alignItems: "center",
    borderRadius: "6px",
    height: "38px !important",
    padding: "5px",
  },
  icon: {
    width: "46px",
    marginLeft: "6px",
  },
  iconDO: {
    width: "33px",
  },
  controle_status: {
    boxSizing: "border-box",
    background: "rgba(232, 234, 246, 0.3)",
    border: "2px solid #C5CAE9",
    borderRadius: "10px",
    color: "#3949AB",
  },
  action_status: {
    boxSizing: "border-box",
    background: "#FFF7EB80",
    border: "2px solid #FFEBCC",
    borderRadius: "10px",
    color: "#CC7B00",
    minWidth: "288px",
    maxWidth: "288px",
    height: "98px",
    padding: "0px 15px 5px 15px",
  },
  invalide_status: {
    boxSizing: "border-box",
    background: "rgba(255, 235, 235, 0.5)",
    border: "2px solid #FFCCCC",
    borderRadius: "10px",
    color: "#CC0001",
    padding: "8px 13px"
  },
  valide_status: {
    boxSizing: "border-box",
    background: "rgba(239, 251, 241, 0.5)",
    border: "2px solid #D7F4DC",
    borderRadius: "10px",
    color: "#2D9F40",
  },
  below_expectation_status: {
    boxSizing: "border-box",
    background: "rgba(230, 204, 255, 0.5)",
    border: "2px solid #E6CCFF",
    borderRadius: "10px",
    color: "#6D00CC",
    minHeight: "57px",
    minWidth: "169px",
    padding: "7px 10px",
    justifyContent: "center",
    "& div": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      gap: "10px",
    },
    "& img": {
      width: "29px",
    },
  },
  label: {
    fontFamily: 'Metric',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "120%",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.01em",
  },
  labelDO: {
    fontSize: "20px",
    marginLeft: "5px",
    marginTop: "-2px",
  },
  statusBtn: {
    height: "28px !important",
    minHeight: "28px !important",
    maxWidth: "85px !important",
    borderRadius: "10px !important",
    top: "0px !important",
    marginRight: "6px !important",
    "&:last-of-type": {
      marginRight: "0px !important",
    },
  },
  loadingPanelContainer: {
    opacity: 0.2,
  },
  loadingPanel: {
      borderRadius: "10px !important",
      border : "2px solid #ebebeb !important",
  },
}));

export default useStyles;
