import { deleteFilterChipHandler, getGraphFiltersKey } from "../services/common/ChartUtils";
import { setLocalStorageObj } from "../services/common/Utils";

const Reducer = (state, action) => {
  let newGraphFilters;
  let selectedGraphFilter;
  let graphFiltersKey;
  switch (action.type) {
    case "TOGGLE_DRAWER":
      return {
        ...state,
        toggleDrawer: action.toggleDrawer,
      };
    case "TOGGLE_MENU_HIGHTLIGHT":
      return {
        ...state,
        isMenuHighlighted: action.isMenuHighlighted,
      };
    case "OPENED_DRAWER":
      return {
        ...state,
        drawerIsOpened: action.drawerIsOpened,
      };
    case "PAGINATION":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.pagination
        },
      };
    case "ALERT":
      return {
        ...state,
        alert: action.alert,
      };
    case "ACCOUNTS":
      return {
        ...state,
        accounts: action.accounts,
      };
    case "SELECTED_ACCOUNT":
      return {
        ...state,
        account: action.account,
      };
    case "USER_ROLES":
      return {
        ...state,
        roles: action.roles,
      };
    case "USER_INFOS":
      return {
        ...state,
        user: action.user,
      };
    case "UPDATE_USER_INFOS":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    case "LANG" : 
      return {
        ...state,
        lang: action.lang,
    }
    case "TOKEN":
      return {
        ...state,
        token: action.token,
      };
      case "KEYCLOAK":
        return {
          ...state,
          keycloak: action.keycloak,
        };

    case "ACTIVITIES_COUNT":
      return {
        ...state,
        activitiesCount: action.activitiesCount,
      };

    case "DOSSIER_TYPES":
      return {
        ...state,
        dossierTypes: action.dossierTypes,
      };

    case "INACTIVE_DOSSIER_TYPES":
      return {
        ...state,
        inactiveDossierTypes: action.dossierTypes,
      };

    case "DOSSIER":
      return {
        ...state,
        dossier: action.dossier,
      };

    case "CRUMBS":
      return {
        ...state,
        crumbsData: action.crumbsData,
      };

    case "ADD_GRAPH_FILTER":
      selectedGraphFilter = state.graphFilters[action.filter.type] || [];
      newGraphFilters = {
        ...state.graphFilters,
        [action.filter.type]: [...selectedGraphFilter, action.filter.data],
      };
      graphFiltersKey = getGraphFiltersKey(action.filter.accountId, action.filter.tab, action.filter.subTab)
      setLocalStorageObj(graphFiltersKey, newGraphFilters)
      return {
        ...state,
        graphFilters: newGraphFilters,
      };

    case "REMOVE_GRAPH_FILTER":
      newGraphFilters = deleteFilterChipHandler(state.graphFilters, action.filter.type, action.filter.data);
      graphFiltersKey = getGraphFiltersKey(action.filter.accountId, action.filter.tab, action.filter.subTab)
      setLocalStorageObj(graphFiltersKey, newGraphFilters)
      return {
        ...state,
        graphFilters: newGraphFilters,
      };

    case "INIT_GRAPH_FILTER":
      return {
        ...state,
        graphFilters: action.filters,
      };

    case "AUTH_DONE":
      return {
        ...state,
        isAuthDone: action.isAuthDone,
      };

    case "PROVISIONING_ACCOUNT":
      return {
        ...state,
        provisioning: action.provisioning,
      };

    case "CLEAR_GRAPH_FILTER_BY_ID":
      selectedGraphFilter = state.graphFilters[action.filter.type] || [];
      newGraphFilters = {
        ...state.graphFilters,
        [action.filter.type]: selectedGraphFilter.filter(x => !x.id.endsWith(action.filter.idEndsWith))
      }
      graphFiltersKey = getGraphFiltersKey(action.filter.accountId, action.filter.tab, action.filter.subTab)
      setLocalStorageObj(graphFiltersKey, newGraphFilters)
      return {
        ...state,
        graphFilters: newGraphFilters,
      };

    case "CLEAR_GRAPH_FILTERS":
      graphFiltersKey = getGraphFiltersKey(action.filter.accountId, action.filter.tab, action.filter.subTab)
        setLocalStorageObj(graphFiltersKey, {})
      return {
        ...state,
        graphFilters: {},
      };

    case "REFERENCE_DATA":
      return {
        ...state,
        referenceData: action.data,
      };

    default:
      return state;
  }
};

export default Reducer;
