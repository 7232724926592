import i18next from "i18next";
import React, { useCallback, useContext, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Context } from "../../states/Store";
import useStyles from "../../style/js-style/components/common/HoverableBlocStyle";
import EAIcon from "./EAIcon";

function HoverableRedirectBloc({ route, children }) {
  const { classes } = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const [state] = useContext(Context);
  const { accountId } = useParams();

  const redirectLink = () => {
    return `/${getAccountId()}/${route}`;
  };

  const getAccountId = useCallback(() => {
    return accountId || state.account?.id;
  }, [accountId, state.account]);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(classes.wrapper, isHovered && classes.isHovered)}
    >
      {children}
      <div>
        {isHovered && (
          <Link to={redirectLink}>
            <EAIcon icon={"open_new_tab"} alt={i18next.t("open")} />
          </Link>
        )}
      </div>
    </div>
  );
}

function HoverableBloc({ children, className, onClick }) {
  const { classes } = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <span
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      className={clsx(isHovered && classes.isHoveredSimple, className)}
    >
      {children}
    </span>
  );
}

export default HoverableRedirectBloc;
export {HoverableBloc}


