import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#535362",
    marginBottom: "32px",
  },
  mainContentWrapper: {
    width: "352px",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    paddingBottom: "2rem",
    width: "100%",
  },
  label: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#7E7E8F",
  },
  inputText: {
    marginTop: "15px",
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",
      minWidth: "276px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #E2E2EA !important",
      },
    }
  },
  inputTextError: {
    marginTop: "15px",
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",
      minWidth: "276px",
      color: "#CC0001",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px #CC0001px solid #CC0001 !important",
      },
    }
  },
  inputSelect: {
    marginTop: "15px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px solid #E2E2EA !important",
    }
  },
  inputSelectLarge: {
    marginTop: "15px",
    width: "370px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px solid #E2E2EA !important",
    }
  },
  selectItem: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#7E7E8F"
  },
  selectPlaceholder: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9A9AAF"
  },
  roleWrapper: {
    background: "#F2F3F7",
    borderRadius: "4px",
    width: "371px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "32px",
  },
  roleName: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    color: "#535362"
  },
  subTitle: {
    fontFamily: "'Roboto'",
    fontWeight: 400,
    fontSize: "14px",
    color: "#7E7E8F",
    maxWidth: "370px",
    marginTop: "10px",
  },
}));

export default useStyles;
