import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { Grid, InputBase, Paper } from "@mui/material";
import NoteInput from "components/notes/NoteInput";
import NoteDisplay from "components/notes/NoteDisplay";
import useStyles from "../../style/js-style/containers/notes/NotesListStyle";
import SearchIcon from '@mui/icons-material/Search';
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";

const NotesList = ({
    notes,
    fetchNotes,
    createNote,
    updateNote,
    deleteNote,
    type
}) => {
    const { classes } = useStyles();
    const [searchValue, setSearchValue] = useState("")
    const [filteredNotes, setFilteredNotes] = useState([])
    const [noteToEdit, setNoteToEdit] = useState(null)
    const [lastEdited, setLastEdited] = useState(null)

    const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

    // Filtre les résultats des notes suivant la recherche
    useEffect(() => {
        let filteredResult = [];

        if (searchValue !== ""){
            for (const note of notes) {
                if(note.contenu !== null && note.prenomNom !== null && (note.contenu.toLowerCase().includes(searchValue.toLowerCase()) || note.prenomNom.toLowerCase().includes(searchValue.toLowerCase()))){
                    filteredResult.push(note)
                }
            }
            setFilteredNotes(filteredResult);
        } else{
            setFilteredNotes(notes);
        }
    }, [searchValue, notes])

    return (
        <>
            <Paper elevation={0} className={classes.root}>
                <Grid item xs={12} style={{display: "flex", flexDirection: "column"}}>

                    {/* Search */}
                    <div className={classes.inputWrapper}>
                        <SearchIcon/>
                        <InputBase
                          placeholder="Recherche"
                          inputProps={{ 'aria-label': 'search' }}
                          onChange={(e) => setSearchValue(e.target.value)}
                          sx={{fontSize: "14px"}}
                        />
                    </div>

                    {/* Input */}
                    <NoteInput
                        fieldName={`note`}
                        isRequired={true}
                        placeholder={i18next.t("notes.input.placeholder")+"..."}
                        setLastEdited={setLastEdited}
                        setNoteToEdit={setNoteToEdit}
                        disabled={!hasAnyOfPrivileges(PrivilegeEnum[`PRIVILEGE_CREATE_${type}_NOTES`])}
                        fetchNotes={fetchNotes}
                        createNote={createNote}
                    />
                </Grid>

                {/* Notes list */}
                <Grid item xs={12}>
                    {filteredNotes.map((item, index) => {
                        return (
                            <NoteDisplay
                              key={item.noteOId}
                              noteToDisplay={item}
                              isLastElement={index + 1 === filteredNotes.length}
                              fetchNotes={() => fetchNotes()}
                              setNoteToEdit={setNoteToEdit}
                              noteToEdit={noteToEdit}
                              lastEdited={lastEdited}
                              deleteNote={deleteNote}
                              type={type}
                              updateNote={updateNote}
                            />
                        )
                    })}
                </Grid>
            </Paper>
        </>
    );
}

export default NotesList;
