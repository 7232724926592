import React  from "react";
import { FormGroup, FormLabel, Grid } from "@mui/material";
import useStyles from "../../style/js-style/components/common/EAInputBlocStyle";

const EAInputBloc = ({ label, children, customLabelStyle, customChildStyle, required }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.formContainer}>
      <Grid item xs={12}>
        <FormLabel className={classes.label} style={customLabelStyle} required={required}>{label}</FormLabel>
        <FormGroup className={classes.input} style={customChildStyle}>{children}</FormGroup>
      </Grid>
    </div>
  );
};

export default EAInputBloc;
