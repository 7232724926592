import React, { useContext } from "react";
import {
  Typography,
} from "@mui/material";
import i18next from "i18next";

import { Context } from "../../../../states/Store";
import { ERROR, SUCCESS } from "../../../../services/common/Constants";
import EADialog from "../../../../components/common/EADialog";
import EAIcon from "../../../../components/common/EAIcon";
import { updateRule } from "../../../../services/edge/RuleService";
import useStyles from "../../../../style/js-style/containers/administration/rules/RuleEditConfirmDialogStyle";
import { useFormContext } from "react-hook-form";
import { mapRulesCriteria } from "services/common/RulesUtils";
import {useRequestLoading} from "../../../../components/common/hooks/useRequestLoading";

const RuleEditConfirmDialog = ({
  isOpen,
  rule,
  onClose,
  onValidateSuccess,
}) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const formMethods = useFormContext();
  const { handleSubmit } = formMethods;

  const onValidateEdit = async (data) => {
    data = {
      ...data,
      criteria: mapRulesCriteria(data.criteria.filter((x) => x.field !== "")),
      actions: data.actions.filter((x) => x.dossierTypeCode !== ""),
    };
    try {
      startLoading();
      await updateRule(state.account.id, rule.id, data, null);

      dispatch({
        type: "ALERT",
        alert: data.enabled ?
          { type: SUCCESS, msg: i18next.t("rules.notifications.activate", { name: `${data.name}` }) }
          :
          { type: SUCCESS, msg: i18next.t("rules.notifications.register", { name: `${data.name}` }) }
      });

      onValidateSuccess();
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }finally {
      stopLoading();
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={() => handleSubmit(onValidateEdit)()}
      hasActions={true}
      maxWidth="popupxs"
      fullWidth
      variant="warning"
      btnSize="large"
      isValidateDisabled={isRequestLoading}
    >
      <div className={classes.root}>
        <>
          <div className={classes.imgWrapper}>
            <EAIcon icon={"warning-snack"} className={classes.imgContent} />
          </div>
          <span className={classes.bolder}>
            {i18next.t("rules.confirmDialog.title", { status: i18next.t(`${rule.status}`).toLocaleLowerCase() })}
          </span>
          {rule.status === 'active' && (
            <span>
              {i18next.t("rules.confirmDialog.caption")}
            </span>
          )}
        </>
      </div>

      <div key={rule.id} component="div">
        <RuleInfoBloc rule={rule} />
      </div>
    </EADialog>
  );
}

const RuleInfoBloc = ({ rule }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.ruleInfoBlocWrapper}>
      <Typography className={classes.ruleInfoBlocContent}>
        {rule.name}
      </Typography>
    </div>
  );
};

export default RuleEditConfirmDialog;
