import { Grid, Paper, Typography } from "@mui/material";
import i18next from "i18next";
import React, { useContext, useEffect } from "react";
import { EANoData } from "../../../components/common/EANoData";
import useStyles from "../../../style/js-style/containers/dashboard/TabStyle";
import DossiersGlobalStatesChart from "./DossiersGlobalStatesChart";
import DossiersStatesChart from "./DossiersStatesChart";
import SkeletonDashboard, {SKELETON_TYPE} from "../../../components/common/skeleton/SkeletonDashboard";
import { getDossiersCountByType } from "services/edge/DossierService";
import { Context } from "states/Store";
import { ERROR, FREE } from "services/common/Constants";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { useRef } from "react";
import { isAbortError } from "services/common/ApiUtils";

function TabDossiers() {

  const [state, dispatch] = useContext(Context);
  const [dossiersCountType, setDossiersCountType] = React.useState([]);
  const [globalDossiersCount, setGlobalDossiersCount] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const accountId = useSelectedAccountId();

  const abortCtrlRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    
    if(abortCtrlRef.current) {
      abortCtrlRef.current.abort();
    }

    abortCtrlRef.current = new AbortController();
    const signal = abortCtrlRef.current.signal;

    if (accountId && state.account) {
      (async function fetchData() {
        try {
          const dossiersCount = await getDossiersCountByType(
            accountId,
            null,
            signal
          );

          if (dossiersCount) {
            setDossiersCountType(
              dossiersCount.content.countDossierType?.map(doss => ({
                ...doss,
                typeDossierLibelle: doss.typeDossierLibelle || i18next.t("free"),
                typeDossierCode: doss.typeDossierCode || FREE,
              }))
            )
            setGlobalDossiersCount({
              nbAbsent: dossiersCount.content.numberOfAbsentDossiers,
              nbCtrlInProgress: dossiersCount.content.numberOfCtrlInProgressDossiers,
              nbInvalid: dossiersCount.content.numberOfInvalidDossiers,
              nbBelowExpectation: dossiersCount.content.numberOfBelowExpectationDossiers,
              nbActionDORequired: dossiersCount.content.numberOfActionDORequiredDossiers,
              nbValid: dossiersCount.content.numberOfValidDossiers,
              all: dossiersCount.content.numberOfDossiers,
            });
          }

          setIsLoading(false)

        } catch (e) {
          if(!isAbortError(e)) {
            dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
          }
        }
      })();
    }

    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [dispatch, state.account, accountId])


  const { classes } = useStyles();

  return !isLoading ? (
    <>
      <Paper elevation={0} className={classes.paperContainer}>
        {globalDossiersCount?.all > 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <Paper className={classes.graphPaper}>
                <Typography variant="h3" className={classes.graphAreaTitle}>
                  {i18next.t("dashboard.dossiers.dossiersTypes")}
                </Typography>
                <DossiersStatesChart all={globalDossiersCount.all} dossiersCount={dossiersCountType} />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={5}>
              <Paper className={classes.graphPaper}>
                <Typography variant="h3" className={classes.graphAreaTitle}>
                  {i18next.t("dashboard.dossiers.dossiersStates")}
                </Typography>
                <DossiersGlobalStatesChart dossiersStates={globalDossiersCount} />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <EANoData label={i18next.t("dashboard.dossiers.nodata")} icon="noDossiers"/>
        )}
      </Paper>
    </>
  ) : (
    <SkeletonDashboard
      sections={[
        { title : i18next.t("dashboard.dossiers.dossiersTypes"),
          type : SKELETON_TYPE.LINES
        },
        { title : i18next.t("dashboard.dossiers.dossiersStates"),
          type : SKELETON_TYPE.CIRCULAR
        },
      ]}
    />
  )
}

export default TabDossiers;
