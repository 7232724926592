import React, { useCallback, useContext, useState } from "react";
import EADataGrid from "../../../../components/common/datatable/EADataGrid";
import { dossierModelListColumns } from "./DossierModelDataTableUtils";
import useStyles from "../../../../style/js-style/containers/administration/attributes/AttributesDataTableStyle";
import { CountSelectedRow } from "../../../../components/common/datatable/CountSelectedRow";
import DossierModelDeleteDialog from "./dialogs/DossierModelDeleteDialog";
import DossierModelEditDialog from "./dialogs/DossierModelEditDialog";
import { DossierModelActionMenu } from "./DossierModelActionMenu";
import { Context } from "states/Store";
import i18next from "i18next";
import { updateActiveMultipleDossierModel, updateNatureMultipleDossierModel } from "services/edge/DossierModelService";
import {  SUCCESS, WARNING } from "services/common/Constants";
import _ from "lodash";
import { Trans } from 'react-i18next';
import TitleWithIcon from "components/common/TitleWithIcon";
import { Typography } from "@mui/material";

function DossierModelDataTable({dossierModelList, fetchDossierModelList}) {
  const [state, dispatch] = useContext(Context);
  const [dossierModelSelectedIds, setDossierModelSelectedIds] = useState([]);
  const [selectedDossierModel, setSelectedDossierModel] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [filterRowCount, setFilterRowCount] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { classes } = useStyles();

  const updateNatureDossierModelList = useCallback(async (list, mustLock) => {

    if (!_.isEmpty(list)) {
    try {
      let message = "";
      if (mustLock) {
        if(list.length === 1) {
          const label = list[0]?.libelle;
          message = <Trans i18nKey="demandes.dossierModel.notifications.lockedSuccess">Le modèle "<b>{{label}}</b>" a bien été verrouillé.</Trans>;
        } else {
          message = i18next.t("demandes.dossierModel.notifications.lockedSuccessMulti");
        }
      } else {
        if (list.length === 1) {
          const label = list[0]?.libelle;
          message = <Trans i18nKey="demandes.dossierModel.notifications.unlockedSuccess">Le modèle "<b>{{label}}</b>" a bien été déverrouillé.</Trans>;
        } else {
          message = i18next.t("demandes.dossierModel.notifications.unlockedSuccessMulti");
        }
      }
      await updateNatureMultipleDossierModel(state.account.id, list, null);
      fetchDossierModelList();
      dispatch({
        type: "ALERT",
        alert: { type: SUCCESS, msg: message }
      });
    } catch (error) {

    }
  }
  }, [state.account, fetchDossierModelList, dispatch]);

  const updateActiveDossierModelList = useCallback(async (list, mustActivate) => {

    if (!_.isEmpty(list)) {
    try {
      let message = "";
      if (mustActivate) {
        if(list.length === 1) {
          const label = list[0]?.libelle;
          message = <Trans i18nKey="demandes.dossierModel.notifications.activateSuccess">Le modèle "<span className='dt' style={{fontWeight: "bold"}}>{{label}}</span>" a bien été activé.</Trans>;
        } else {
          message = i18next.t("demandes.dossierModel.notifications.activateSuccessMulti");
        }
      } else {
        if (list.length === 1) {
          const label = list[0]?.libelle;
          message = <Trans i18nKey="demandes.dossierModel.notifications.deactivateSuccess">Le modèle "<span className='dt' style={{fontWeight: "bold"}}>{{label}}</span>" a bien été désactivé.</Trans>;
        } else {
          message = i18next.t("demandes.dossierModel.notifications.deactivateSuccessMulti");
        }
      }
      await updateActiveMultipleDossierModel(state.account.id, list, null);
      fetchDossierModelList();
      dispatch({
        type: "ALERT",
        alert: { type: mustActivate ? SUCCESS : WARNING, msg: message }
      });
    } catch (error) {

    }
  }
  }, [state.account, fetchDossierModelList, dispatch]);

  const openDeleteDialog = (dossierModelIdsList) => {
    setIsDeleteDialogOpen(true);
    setSelectedDossierModel(
      dossierModelList.filter(
        (dossierModel) => dossierModelIdsList.indexOf(dossierModel.id) !== -1
      )
    );
  };

  const openEditDialog = (dossierModelId) => {
    setIsEditDialogOpen(true);
    setSelectedDossierModel([
      dossierModelList.find((dossierModel) => dossierModel.id === dossierModelId),
    ]);
  };


  const changeNature = (dossierModelIdsList, mustLock) => {
    let list = dossierModelList.filter(
      (dossierModel) => dossierModelIdsList.indexOf(dossierModel.id) !== -1
    ).map((dossierModel) => ({...dossierModel, lock : mustLock}));
    updateNatureDossierModelList(list, mustLock);
  };

  const changeActive = (dossierModelIdsList, mustActivate) => {
    let list = dossierModelList.filter(
      (dossierModel) => dossierModelIdsList.indexOf(dossierModel.id) !== -1
    ).map((dossierModel) => ({...dossierModel, active : mustActivate}));
    updateActiveDossierModelList(list, mustActivate);
  };

  return (
    <>
      <Typography variant="h3">
        <TitleWithIcon
          title={i18next.t("demandes.dossierModel.tableTitle")}
          icon={"demandes/dossier_model_green"}
          resultCount={filterRowCount}
          totalCount={dossierModelList?.length}
          variant={"accordion"}
          disabled={false}
        />
      </Typography>

      {/* DataGrid */}
      <div className={classes.dataGridWrapper}>
        <EADataGrid
          loading={false}
          inputRows={dossierModelList}
          columns={
            dossierModelListColumns(
              openEditDialog,
              openDeleteDialog,
              changeNature,
              changeActive)
          }
          customSx={{
            "& .MuiDataGrid-columnHeader:nth-child(2)": { color: "#7E7E8F" },
          }}
          selection={{
            items: dossierModelSelectedIds,
            setItems: setDossierModelSelectedIds,
          }}
          onStateChange={(e) => {
            setFilterRowCount(e.pagination.rowCount);
          }}
          additionnalsLeftComponents={
            <>
              <CountSelectedRow data={dossierModelSelectedIds} />
              <DossierModelActionMenu
                dossierModelSelected={dossierModelSelectedIds}
                onOpenDeleteDialog={openDeleteDialog}
                onChangeNature={changeNature}
                onChangeActive={changeActive}
              />
            </>
          }
          pageSize={10}
        />
      </div>

      {/* PopUp : on edit */}
      {isEditDialogOpen && (
        <DossierModelEditDialog
          dossierModel={selectedDossierModel[0]}
          isOpen={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onValidateSuccess={() => {
            setIsEditDialogOpen(false);
            fetchDossierModelList();
          }}
          onDeleteOpen={openDeleteDialog}
        />
      )}

      {/* PopUp : on delete */}
      {isDeleteDialogOpen && (
        <DossierModelDeleteDialog
          modelList={selectedDossierModel}
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onValidateSuccess={() => {
            setIsDeleteDialogOpen(false);
            fetchDossierModelList();
          }}
        />
      )}
    </>
  );
}
export default DossierModelDataTable;
