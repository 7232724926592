import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dataGridWrapper: {
    marginTop: "17px",
    backgroundColor: "transparent",
    maxHeight: "calc(100vh - 230px)",
    overflowY: "auto",
  },
}));

export default useStyles;
