import EADialog from "components/common/EADialog";
import i18next from "i18next";

const CancelImportThirdParties = ({isOpen, onClose, onValidate}) => {

    const validaitonAciton = () => {
        onClose();
        onValidate();
    }

    return (
        <EADialog
                isOpen={isOpen}
                title={i18next.t("imports.importDialogName.confirmClose.headerTitle")}
                onCancel={onClose}
                validateBtnLabel="imports.importDialogName.confirmClose.exitButtonTitle"
                variant="redValid"
                onValidate={validaitonAciton}>

                
                <div>{i18next.t("imports.importDialogName.confirmClose.bodyText")}</div>

                
        </EADialog>

    )
}

export default CancelImportThirdParties;