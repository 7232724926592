import { useState } from "react";
import EAIcon from "../EAIcon";
import { Button, List, ListItemButton, ListItemText, Popover, Typography } from "@mui/material";
import i18next from "i18next";
import { Exports } from "services/common/Constants";

// component for genarate export files (CSV/Excel) from backend
const EADataGridExport = ({exportHandler}) => {
    
      const [anchorEl, setAnchorEl] = useState(null);
    
      const externExportClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const externExportClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

      const exportStartIcon = () => {
        return (
          <EAIcon icon={"export"} alt={"export icon"} />
        )
      }

    return(<>
        <Button startIcon={exportStartIcon()} size="small" color="grey" 
            style={{ textTransform: 'none', fontSize: "11px", fontWeight: "600"}} onClick={externExportClick}>
                {i18next.t(`tableLabels.toolbar.export`)}
        </Button>
        <Popover
            open={open}
            id={id}
            anchorEl={anchorEl}
            onClose={externExportClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
            <List dense={true}>
            <ListItemButton onClick={() => exportHandler(Exports.CSV)} >
                <ListItemText primary={
                  <Typography size="1rem" >
                      {i18next.t(`tableLabels.toolbar.exportCsv`)}
                  </Typography>
                  }/>
            </ListItemButton>
            <ListItemButton onClick={() => exportHandler(Exports.EXCEL)}>
                <ListItemText primary={
                  <Typography size="1rem" >
                    {i18next.t(`tableLabels.toolbar.exportExcel`)}
                  </Typography>
                 } />
            </ListItemButton>
            </List>
        </Popover>
    </>)
}

export default EADataGridExport;