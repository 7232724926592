import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  inputBlocContainer: {
    width: "100%",
    [`${theme.breakpoints.down('md')}`]: {
      paddingBottom: "1rem",
    },
    "& .MuiFormLabel-root": {
      whiteSpace: "nowrap",
    },
    "& .MuiFormGroup-root": {
      width: "100%",
      paddingTop: "0.5rem"
    },
    "&:first-child": {
      marginRight: "1rem"
    }
  },
  label: {
    fontWeight: 700,
    color: theme.palette.grey.grey500,
    fontSize: "14px",
  },
}));

export default useStyles;
