import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    countryFlag:{
      margin: "0 -20px"
    },
    flagWrapper:{
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "19px",
      marginRight: "10px",
    },
    flagOverlay:{
      border: "4px solid white",
      position: "absolute",
      borderRadius: "12px",
    },
}));

export default useStyles;
