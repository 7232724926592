import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { ERROR } from "../../../../services/common/Constants";
import { Context } from "../../../../states/Store";
import { Controller, useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import EAInputBloc from "../../../../components/common/EAInputBloc";
import EAButton from "../../../../components/common/EAButton";
import { RULE_NAME_MAX_LENGTH } from "../../../../services/common/RulesUtils";
import useInputStyle from "../../../../style/js-style/common/inputStyle";
import useStyles from "../../../../style/js-style/containers/administration/rules/RuleFormsStyle";
import { isRuleNameAuthorized } from "../../../../services/edge/RuleService";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import EARuleAccordion from "../../../../components/rules/EARuleAccordion";
import CriteriaFormArray from "../form/criteria/CriteriaFormArray"
import RuleActions from "../form/actions/RuleActions";

const RuleConfiguration = ({
  open,
  criterionFieldOptions,
  onCancel,
  onContinue,
  isEditionMode = false,
  name,
}) => {
  const { classes } = useStyles();
  const { classes: inputClass } = useInputStyle();

  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const formMethods = useFormContext();
  const { trigger, formState } = formMethods;
  const [state, dispatch] = useContext(Context);
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = formMethods;

  const isValueAuthorized = async (value) => {
    try {
      const resp = await isRuleNameAuthorized(state.account.id, value, null);

      return !!resp.content;
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  };

  // Trigger Criteria and actions errors with debounce to avoid multiple button clicks
  const handleContinue = AwesomeDebouncePromise(() => {
    trigger(["criteria", "actions"]);
    setIsContinueClicked(true);
  }, [100]);

  const validateRuleName = AwesomeDebouncePromise(
    async (value, name) => {
      let isValid = false;
      let typeErrMsg = "";

      if (name && name === value) {
        isValid = true;
      } else {
        if (value.length === 0) {
          typeErrMsg = i18next.t("infos.required");
        } else if (value.length > RULE_NAME_MAX_LENGTH) {
          typeErrMsg = i18next.t(
            "rules.finalization.fields.name.errors.maxLengthExceeded",
            {
              length: RULE_NAME_MAX_LENGTH,
            }
          );
        } else {
          let isRuleNameUnique = await isValueAuthorized(value);

          if (!isRuleNameUnique) {
            typeErrMsg = i18next.t(
              "rules.finalization.fields.name.errors.notUnique"
            );
          } else {
            isValid = true;
          }
        }
      }

      if (isValid) {
        clearErrors(`name`);
      } else {
        setError(`name`, { type: "manual", message: typeErrMsg });
        return typeErrMsg;
      }
    },
    [500]
  );

  // We continue to next step if the validation is done and no errors are found.
  useEffect(() => {
    if (
      isContinueClicked &&
      !formState.isValidating &&
      isEmpty(formState.errors)
    ) {
      onContinue();
    }
    setIsContinueClicked(false);
  }, [isContinueClicked, formState, onContinue]);

  return (
    <EARuleAccordion
      buttons={[]}
      title={i18next.t("rules.configuration.title")}
      icon="settings_applications_green"
      opened={open}
    >
      <Grid container spacing={6}>

        {/* Rule name edition */}
        {isEditionMode && (
          <Grid
            item
            xs={12}
            md={6}
            className={classes.formConfigRuleName}
            style={{ padding: "14px 0 0 62px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={`h6`} className={classes.formSectionTitle}>
                  <span className={classes.formSectionTitleDeco} />
                  {i18next.t("rules.configuration.name.title")}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{padding: "0 0 0 16px !important"}}>
                <EAInputBloc customLabelStyle={{ fontSize: "14px" }}>
                  <>
                    <Controller
                      control={control}
                      name={`name`}
                      defaultValue={``}
                      rules={{
                        validate: (value) => validateRuleName(value, name),
                      }}
                      onChange={(e) => {
                        setValue(`name`, e.target.value);
                      }}
                      render={({ field }) => (
                        <TextField
                          className={inputClass.inputText}
                          id="rule-name-input-id"
                          error={!!errors.name}
                          fullWidth
                          {...field}
                          placeholder={i18next.t(
                            `rules.finalization.fields.name.placeholder`
                          )}
                        />
                      )}
                    />

                    {!!errors.name && (
                      <FormHelperText className="Mui-error">
                        {errors.name.message}
                      </FormHelperText>
                    )}
                  </>
                </EAInputBloc>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Rule criteria */}
        <Grid item xs={12} style={{ padding: isEditionMode ? "0 0 0 62px" : "32px 0 0 62px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{paddingTop: "0!important"}}>
              <Typography variant={`h6`} className={classes.formSectionTitle}>
                <span className={classes.formSectionTitleDeco} />
                {i18next.t("rules.configuration.criteria.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CriteriaFormArray
                criterionFieldOptions={criterionFieldOptions}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Rule actions */}
        <Grid item xs={12} style={{ padding: "14px 0 0 62px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <ActionsFormArray /> */}
              <RuleActions fieldName={`actions`} />
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <div className={classes.configurationButtonWrapper}>
          <EAButton
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
            content={i18next.t("rules.configuration.actions.cancel")}
            colorVariant="secondary"
            customStyle={{
              minWidth: "59px",
              fontSize: "14px",
              minHeight: "32px",
              height: "32px",
              padding: 0,
              borderRadius: "12px",
            }}
          />

          <EAButton
            onClick={(e) => {
              e.preventDefault();
              handleContinue();
            }}
            disabled={!isEmpty(formState.errors)}
            content={i18next.t("rules.configuration.actions.validate")}
            customStyle={{
              fontSize: "14px",
              minWidth: "94px",
              minHeight: "32px",
              height: "32px",
              padding: 0,
              borderRadius: "12px",
            }}
          />
        </div>
      </Grid>
    </EARuleAccordion>
  );
};

export default RuleConfiguration;
