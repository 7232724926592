import i18next from "i18next";
import useStyles from "./DossiersListSearchFormStyle";
import { HighlightOff } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import EAIcon from "components/common/EAIcon";

export const DossiersSearchLineActions = ({
  isAdvancedSearchShown = false,
  isDeleteBtnShown = false,
  onLineDeleteClick = () => {},
  onAdvancedSearchClick = () => {},
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        mr={0}
        mt={3.5}
        ml="auto"
      >
        {isDeleteBtnShown && (
          <Box display="flex" className={classes.removeBtnContainer}>
            <Tooltip placement="top" title={i18next.t("delete")}>
              <IconButton
                aria-label="Remove search dossier line"
                onClick={onLineDeleteClick}
                className={classes.removeBtn}
                size="large"
              >
                <HighlightOff />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          onClick={onAdvancedSearchClick}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <span className={classes.advancedSearchToggle}>
            {isAdvancedSearchShown ? i18next.t("simpleSearch") : i18next.t("advancedSearch")}
          </span>
          <EAIcon
            icon={`arrow/keyboard_arrow_${isAdvancedSearchShown ? "up" : "down"}`}
            className={classes.advancedSearchIcon}
          />
        </Box>
      </Box>
    </>
  );
};
