import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  selection: {
    height: "16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "130%",
    display: "flex",
    alignItems: "center",
    color: "#837E7C",
    marginLeft: "16px",
  },
}));

export default useStyles;
