import PropTypes from 'prop-types';

function EATabPanel(props) {
    const { children, value, index, variant = "default", shadow = false, noMarginRight = false, ...other } = props;
    const getStyle = (variant, shadow) => {
      let baseStyle = {};
      
      switch (variant) {
        case "tpCustom":
          baseStyle = { minHeight: "300px", background: "white", marginRight: "16px", borderRadius: "12px" };
          break;
        case "withoutBackgroundAndMargin":
          baseStyle = { borderRadius: "12px" };
          break;
        case "withoutBackground":
          baseStyle = { marginRight: "16px", borderRadius: "12px" };
          break;
        default:
          baseStyle = { background: "white" };
      }
      
      if (shadow) {
        baseStyle.boxShadow = "rgb(0 0 0 / 4%) 0px 25px 20px -20px";
      }
      
      if (noMarginRight) {
        baseStyle.marginRight = "0";
      }

      return baseStyle;
    };
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={getStyle(variant, shadow)}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  EATabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired
    ])
  };

  export default EATabPanel;
