import { Typography, Chip } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";
import i18next from "i18next";
import { Paper } from "@mui/material";
import { Grid } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { localizeTranslate } from "i18n/LocalizeUtils";

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: "22px",
  },
  card: {
    padding: "1.5rem",
    marginBottom: "1.5rem",
  },
  documents : {
    maxHeight: '400px',
    overflowY: 'auto',

    "& .MuiToolbar-root.MuiToolbar-regular" : {
      "& > div:nth-child(3)" : {
        display: 'none'
      },
      "& > div:nth-child(4)" : {
        paddingLeft: 0
      }
    },
    "& .datatable > div > div:nth-child(2)": {
      "& .MuiTableRow-root.MuiTableRow-head > :nth-child(1)": {
        padding: "6px 8px"
      }
    },
  },
}));

function DossierTypeListDocuments({ documents }) {
  const { classes } = useStyles();

  return (
    <>
      <Paper elevation={0} className={classes.card}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">{i18next.t("dossierSheet.documentsList")}</Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.documents}>
            <Grid container spacing={1}>
              {documents.map(d => (
                <Grid item xs={12} key={d.documentId}>
                   <Chip icon={<InsertDriveFileIcon />} label={localizeTranslate(d.descriptionFr)} variant="outlined" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

    </>
  );
}

export default DossierTypeListDocuments;
