import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";

export async function approveDocument(accountId, responseId, comment, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/responses/${responseId}/approve`,
    comment
  );
}

export async function rejectDocument(accountId, responseId, comment, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/responses/${responseId}/reject`,
    comment
  );
}

export async function getAuthorizingRolesForDocument(accountId, documentId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/documents/${documentId}/authorizing-roles`,
  );
}
