import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";

export async function getRules(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/rules`
  );
}

export async function getRuleById(accountId, ruleId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/rules/${ruleId}`
  );
}

export async function createRule(accountId, dossierRuleInput, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/rules`,
    dossierRuleInput
  );
}

export async function updateRule(accountId, ruleId, dossierRuleInput, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/rules/${ruleId}`,
    dossierRuleInput
  );
}

export async function toggleRuleStatus(accountId, ruleId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/rules/${ruleId}/status`
  );
}

export async function deleteRule(accountId, ruleId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/rules/${ruleId}`
  );
}

export async function isRuleNameAuthorized(accountId, name, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/rules/check-rule-name`,
    { name: name }
  );
}

export async function getTargettedThirdpartiesCount(accountId, ruleId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/rules/${ruleId}/count-thirdparties`
  );
}