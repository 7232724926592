import React, {useContext, useState} from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFieldArray, useFormContext } from "react-hook-form";
import { find } from "lodash";
import i18next from "i18next";
import useStyles from "../../../../../style/js-style/containers/administration/roles/RoleScopeFormStyle";
import ScopeCriterionFormFields from "./ScopeCriterionFormFields";
import EAButton from "../../../../../components/common/EAButton";
import {
  getAvailableOperatorOptions,
  PrivilegeEnum,
  RoleBasedAccessControlContext
} from "../../../../../services/common/RolesUtils";

const ScopeCriteriaFormFields = ({ criterionFieldOptions, fieldName }) => {
  const { classes } = useStyles();
  const [hoveredLine, setHoveredLine] = useState(null);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <Grid container spacing={2}>
      {fields.map((criterion, index) => {
        return (
          <Grid
            item
            xs={12}
            key={criterion.id}
            onMouseEnter={() => setHoveredLine(index)}
            onMouseLeave={() => setHoveredLine(null)}
          >
            {!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_ROLE) &&
              <div className={classes.blocOverlay}/>
            }
            <ScopeCriterionFormFields
              index={index}
              isHovered={hoveredLine === index}
              onRemove={() => remove(index)}
              criterionFieldOptions={criterionFieldOptions}
              initialOperatorOptions={getAvailableOperatorOptions(
                criterion.field,
                criterionFieldOptions
              )}
              initialValueOptions={
                find(criterionFieldOptions, { value: criterion.field })?.valueOptions || []
              }
              fieldName={fieldName}
            />
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <EAButton
          borderless={true}
          className={classes.addChoiceBtn}
          onClick={(e) => {
            e.preventDefault();
            append({
              id: "",
              qualifiableResourceType: fieldName === "thirdPartyQualifyingCriteria" ? "THIRD_PARTY" : "DOSSIER",
              field: "",
              fieldType: "",
              attributeId: "",
              operator: "",
              values: [],
            });
          }}
          content={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                left: "3px",
              }}
            >
              <AddIcon style={{ width: "16px", marginRight: "7px" }} />
              <span style={{ fontSize: "12px" }}>
                {i18next.t("rules.configuration.criteria.actions.addCriterion")}
              </span>
            </div>
          }
        />
      </Grid>
    </Grid>
  );
};

export default ScopeCriteriaFormFields;
