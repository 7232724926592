import * as numeral from "numeral";
import i18next from "../../i18n/i18n";
import moment from "moment";
import ROUTES from "../../config/Routes";
import { DOMTOMList } from "./Constants";

export const getRandomNumber = () => Math.round(Math.random() * 100);

export const capitalize = (str) =>
  str?.charAt(0)?.toUpperCase() + str?.slice(1);

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    default:
      return currency;
  }
};

export const formatNumber = (number, isDecimal) => {
  return numeral(number)
    .format(isDecimal ? "0,0.00" : "0,0")
    .replace(/,/g, " ");
};

export const formatChoices = (arrayOfObjects) => {
  let result = '';
  try {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      result += `\n Element ${i + 1}:\n`;
      for (const key in arrayOfObjects[i]) {
        result += `${key}: ${arrayOfObjects[i][key]},\n`;
      }
      result += '\n';
    }
  } catch (e) {
    console.error(e);
    result = JSON.stringify(arrayOfObjects);
  }
  return result;
}

export function truncate(input) {
  if (input.length > 50) {
    return input.substring(0, 50) + "...";
  }
  return input;
}

export function isEmpty(object) {
  return Object.keys(object).length === 0;
}

export function getDividedCountries(countries) {
  let favoriteCountries = [
    "FR",
    "DE",
    "IT",
    "GB",
    "ES",
    "BE",
    "NL",
    "CH",
    "LU",
    "PT",
    "US",
    "CN",
  ];
  let otherCountries = Object.keys(countries).filter(
    (country) => !favoriteCountries.includes(country)
  );
  return [
    favoriteCountries.map((iso2) => ({ key: iso2, label: countries[iso2] })),
    otherCountries
      .map((iso2) => ({ key: iso2, label: countries[iso2] }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];
}

export function formatActivityText(activityLabel, activityCode, country) {
  if (activityLabel && activityCode) {
    return `${activityCode} - ${activityLabel} (${
      country?.toUpperCase() === "FR" ? "NAF" : "D&B Standard Industry Code"
    })`;
  }
  return "";
}

export function formatLegalCategory(legalCategoryLabel, legalCategoryCode) {
  return `${legalCategoryLabel || ""} ${legalCategoryCode ? `(${legalCategoryCode})` : ""
    }`;
}

export function formatIssuer(issuer, thirdpartyName, fiscaleMereFille) {
  if (issuer) {
    let issuerLabel = i18next.t(`viewer.issuer.${issuer}`);
    if (issuer === "FOURNISSEUR") {
      issuerLabel = thirdpartyName;
    } else if (fiscaleMereFille) {
      issuerLabel += ` ${i18next.t("viewer.fiscale_mere_fille")}`;
    }
    return issuerLabel;
  } else {
    return "";
  }
}

export function formatThirdpartyIdentifier(tp) {
  if (tp.addressCountryCode?.toUpperCase() === "FR") {
    return `${tp.siret} (Siret)`;
  } else if (tp.duns && !tp.dunsEncrypted) {
    return `${tp.duns} (DUNS)`;
  } else {
    const fstRegNbr = tp.listRegistrationNumber[0];
    return fstRegNbr ? `${fstRegNbr.number} (${fstRegNbr.libelle})` : "";
  }
}

export function formatRegistrationStatus(status) {
  switch (status) {
    case "subscribed":
      return "INSCRIT";
    case "without_contact":
      return "SANS CONTACT";
    case "invitation_expired":
      return "INVIATION EXPIREE";
    case "invited":
      return "INVITE";
    default:
      return "SANS CONTACT";
  }
}

export function formatIdentifier(tp, countryCode, searchBy, searchByValue) {
  if (searchBy === "DUNS") {
    if (countryCode === "FR") {
      return `${searchByValue} (Duns)`;
    }
    return `${tp.id} (Duns)`;
  }
  if (searchBy === "VAT") {
    return `${tp.vatNumber} (TVA)`;
  }
  if (!tp.isIdEncrypted) {
    return `${tp.id} (${countryCode === "FR" || DOMTOMList.includes(countryCode) ? "Siret" : "Duns"})`;
  } else if (tp.localIdValue !== "0") {
    if (tp.localIdValue) {
      return `${tp.localIdValue} (${tp.localIdType})`;
    } else if (tp.registrationNumbers?.length > 0) {
      return `${tp.registrationNumbers[0].number} (${tp.registrationNumbers[0].libelle})`;
    }
  }
  return "";
}

export function isSiret(value) {
  value = value.trim();
  return !isNaN(value) && value.length === 14 ? true : false;
}

export function isSiren(value) {
  value = value.trim();
  return !isNaN(value) && value.length === 9;
}

export function verifyLuhn(number, size) {
  if (isNaN(number) || number?.length !== size) return false;

  if (number.startsWith("356000000")) {
    return true;
  }

  let bal = 0;
  let total = 0;
  for (let i = size - 1; i >= 0; i--) {
    let step = (number.charCodeAt(i) - 48) * (bal + 1);

    total += step > 9 ? step - 9 : step;
    bal = 1 - bal;
  }
  return total % 10 === 0 ? true : false;
}

export const formatDate = (datetime) => {
  return datetime ? moment(datetime).format(i18next.t("date.dateFormat")) : "";
};

export const formatDateAndTime = (datetime) => {
  return datetime ? moment(datetime).format(i18next.t("date.dateTimeFormat")) : "";
};

export const formatNumberCurrency = (number, currency) => {
  return number
    ? `${formatNumber(number, false)} ${getCurrencySymbol(currency)}`
    : "";
};

export const getViewerUrl = (accountId, document, component, dossierId, thirdpartyId) => {
  let route;
  switch (component) {
    case 'dossier':
      if (thirdpartyId) {
        const tpInitialRoute = document.thirdpartyOnboarded === false || !document.dossierMonitored ? ROUTES.ARCHIVES : ROUTES;
        route = tpInitialRoute.THIRDPARTY_DOSSIER_DOCUMENT_DETAIL
          .replace(":thirdpartyId", thirdpartyId)
          .replace(":dossierId", dossierId)
          .replace(":reponseId", document.reponseId);
      } else {
        const dossierInitialRoute = document.dossierMonitored ? ROUTES : ROUTES.ARCHIVES;
        route = dossierInitialRoute.DOSSIER_DOCUMENT_DETAIL
          .replace(":dossierId", dossierId)
          .replace(":reponseId", document.reponseId);
        route += `?thirdpartyId=${document.thirdpartyId}`
      }
      break;
    case 'documents-list':
      route = ROUTES.DOCUMENT_DETAIL.replace(":reponseId", document.reponseId)
      route += `?thirdpartyId=${document.thirdpartyId}&dossierId=${dossierId}`
      break;
      case 'thirdparty':
        route = ROUTES.THIRDPARTY_DOCUMENT_DETAIL
          .replace(":thirdpartyId", document.thirdpartyId)
          .replace(":reponseId", document.reponseId)
        route += `?dossierId=${dossierId}`
        break;
    default:
      break;
  }
  return `/${accountId}/${route}`;
}

export const hasOption = (accounts, accountId, subscriptionName) => {
  return accounts
    ?.find((x) => x.id === parseInt(accountId))
    ?.subscriptions.some((s) => s?.toLowerCase() === subscriptionName?.toLowerCase());
};

export const getUserProfile = (user, accountId) => {
  return JSON.parse(user.accounts_roles)?.find(
    (x) => x.id === parseInt(accountId)
  )?.profile;
};

//Math function to calculate quantile, with q is 0.25 for first quartile, for example
export const quantile = (arr, q) => {
  const sorted = arr.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;

  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const setLocalStorageObj = (id, obj) => {
  localStorage.setItem(id, JSON.stringify(obj));
};

export const formatFinancialStrengthRiskLevel = (riskLevel) => {

  if(!riskLevel) return i18next.t(`financialStrength.scoreNotActivated`);

  // We show very high risk level as high
  if(riskLevel === "very_high") riskLevel = "high"
  
  return i18next.t(`datablocks.financialStrength.severity.${riskLevel}`)
};
