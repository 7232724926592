import { MenuItem, Select } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DefaultSelectProps } from "../../services/common/Constants";

const AttributeUniqueSelectInput = ({ listItems }) => {
  const formMethods = useFormContext();
  return (
    <Controller
      control={formMethods.control}
      name="value"
      render={({ field }) => (
        <Select
          {...field}
          id="tp-attribute-select-id"
          variant="outlined"
          displayEmpty
          onChange={(event) =>
            formMethods.setValue("value", event.target.value)
          }
          MenuProps={DefaultSelectProps.MenuProps}
        >
          <MenuItem key={"notSpecified"} value={null}>
            <em>{i18next.t("notSpecified")}</em>
          </MenuItem>
          {listItems?.map((item) => (
            <MenuItem key={item} value={item}>
              <span className="dt">{item}</span>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};
export default AttributeUniqueSelectInput;
