import React, { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import i18next from "i18next";
import _ from "lodash";
import {
  AttributeType,
  FieldDisplayVariant,
  FieldType,
  Operator,
  REGEXP_DECIMAL,
  REGEXP_INTEGER,
  REGEXP_PERCENTAGE
} from "../../../../../services/common/Constants";
import RuleSelectInput from "../../../../../components/rules/RuleSelectInput";
import { getCriterionEntry } from "../../../../../services/common/RulesUtils";
import RuleStandardInput from "../../../../../components/rules/RuleStandardInput";
import RuleMultipleValueInput from "../../../../../components/rules/RuleMultipleValueInput";
import EAMultipleSelectInput from "../../../../../components/rules/EAMultipleSelectInput";
import EAInputBloc from "../../../../../components/common/EAInputBloc";
import EADatePicker from "../../../../../components/common/EADatePicker";
import { CalculationResultType } from "../../../../../services/common/CalculatorUtils";
import EAAutoCompleteInput from "components/inputs/EAAutoCompleteInput";

const ScopeCriterionValues = ({ index, criterionFieldOptions, availableValueOptions, fieldName }) => {
  const [selectedFieldType, setSelectedFieldType] = useState();
  const [selectedFieldSubType, setSelectedFieldSubType] = useState('');
  const [selectedOperator, setSelectedOperator] = useState();

  const formMethods = useFormContext();
  const {
    setValue,
    setError,
    clearErrors,
  } = formMethods;

  let criterionValue = useWatch({ name: `${fieldName}.${index}` });

  useEffect(() => {
    let criterionEntry = getCriterionEntry(criterionValue.field, criterionFieldOptions);

    setSelectedFieldType(criterionValue.fieldType);
    setSelectedFieldSubType(
      [
        FieldType.ATTRIBUTE,
        FieldType.CALCULATED_ATTRIBUTE,
        FieldType.INDICATOR
      ].includes(criterionValue.fieldType) ?
        criterionEntry?.subType
        :
        ''
    );
    setSelectedOperator(criterionValue.operator);
  }, [criterionValue, criterionFieldOptions]);

  switch (selectedFieldType) {
    case FieldType.TEXT:
      return (
        <>
          {(() => {
            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
              case Operator.CONTAINS:
              case Operator.NOT_CONTAINS:
              case Operator.STARTS_WITH:
              case Operator.NOT_STARTS_WITH:
              case Operator.ENDS_WITH:
              case Operator.NOT_ENDS_WITH:
                return (
                  <RuleStandardInput
                    fieldName={`${fieldName}.${index}.values.${0}`}
                    isRequired
                    label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                    placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                    validate={() => {
                      return null;
                    }}
                  />
                );

              case Operator.IS_NULL:
              case Operator.NOT_NULL:
                return (<></>);

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                return (
                  <RuleMultipleValueInput
                    fieldName={`${fieldName}.${index}.values`}
                    label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                    placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                    isRequired
                    validate={(value) => {
                      if (value.length === 0) {
                        setError(`${fieldName}.${index}.values`, {
                          type: 'manual',
                          message: i18next.t(`rules.configuration.criteria.fields.value.errors.isEmpty`)
                        });
                        return false;
                      } else {
                        clearErrors(`${fieldName}.${index}.values`);
                        return true;
                      }
                    }}
                  />
                );

              default:
                return (<></>);
            }
          })()}
        </>
      );

    case FieldType.NUMERIC:
      return (
        <>
          {(() => {
            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
              case Operator.GREATER_THAN:
              case Operator.GREATER_OR_EQUAL:
              case Operator.LESS_THAN:
              case Operator.LESS_OR_EQUAL:
                return (
                  <RuleStandardInput
                    fieldName={`${fieldName}.${index}.values.${0}`}
                    isRequired
                    label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                    placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                    validate={() => {
                      return null;
                    }}
                  />
                );

              case Operator.BETWEEN:
                return (
                  <>
                    {/*TODO*/}
                  </>
                );

              case Operator.IS_NULL:
              case Operator.NOT_NULL:
                return (<></>);

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                return (
                  <RuleMultipleValueInput
                    fieldName={`${fieldName}.${index}.values`}
                    label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                    placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                    isRequired
                    validate={(value) => {
                      if (value.length === 0) {
                        setError(`${fieldName}.${index}.values`, { type: 'manual', message: i18next.t(`rules.configuration.criteria.fields.value.errors.isEmpty`) });
                        return false;
                      } else {
                        clearErrors(`${fieldName}.${index}.values`);
                        return true;
                      }
                    }}
                  />
                );

              default:
                return (<></>);

            }
          })()}
        </>
      );

    case FieldType.DATE:
      return (
        <>
          {(() => {
            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
              case Operator.GREATER_THAN:
              case Operator.GREATER_OR_EQUAL:
              case Operator.LESS_THAN:
              case Operator.LESS_OR_EQUAL:
                return (
                  <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                    <Controller
                      control={formMethods.control}
                      name={`${fieldName}.${index}.values.${0}`}
                      render={({ field }) => (
                        <EADatePicker
                          name={field.name}
                          formMethods={formMethods}
                          disableHighlightToday
                          isRequired
                        />
                      )}
                    />
                  </EAInputBloc>
                );

              case Operator.BETWEEN:
                return (
                  <>
                    {/*TODO*/}
                  </>
                );

              case Operator.IS_NULL:
              case Operator.NOT_NULL:
                return (<></>);

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                return (
                  <>
                    {/*TODO*/}
                  </>
                );

              default:
                return (<></>);
            }
          })()}
        </>
      );

    case FieldType.BOOLEAN:
    case FieldType.CUSTOM:
    case FieldType.ACTIVITY:
      return (
        <>
          {(() => {
            const autoCompleteInputToUse = getCriterionEntry(criterionValue.field, criterionFieldOptions)
              ?.optionsDisplayVariant === FieldDisplayVariant.AUTOCOMPLETE;

            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
                if (autoCompleteInputToUse) {
                  return (
                    <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                      customLabelStyle={{ fontSize: "14px" }}>
                      <EAAutoCompleteInput
                        fieldName={`${fieldName}.${index}.values.${0}`}
                        options={availableValueOptions}
                        placeholder={i18next.t(`rules.configuration.criteria.fields.value.autocompletePlaceholder`)}
                        isRequired
                        multiple={false}
                      />
                    </EAInputBloc>
                  );
                } else {
                  return (
                    <RuleSelectInput
                      fieldName={`${fieldName}.${index}.values.${0}`}
                      label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                      placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                      options={availableValueOptions}
                      isRequired
                      validate={() => {
                        return null;
                      }}
                      labelVariant='chip'
                    />
                  );
                }

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                if (autoCompleteInputToUse) {
                  return (
                    <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                      <EAAutoCompleteInput
                        fieldName={`${fieldName}.${index}.values`}
                        options={availableValueOptions}
                        placeholder={i18next.t(`rules.configuration.criteria.fields.value.autocompletePlaceholder`)}
                        isRequired
                        multiple={true}
                      />
                    </EAInputBloc>
                  );
                } else {
                  return (
                    <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                      <EAMultipleSelectInput
                        fieldName={`${fieldName}.${index}.values`}
                        options={availableValueOptions}
                        isRequired
                        onChange={(e) => {
                          setValue(`${fieldName}.${index}.values`, e.target.value);
                        }}
                        placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                      />
                    </EAInputBloc>
                  );
                }

              default:
                return (<></>);
            }
          })()}
        </>
      );

    case FieldType.ATTRIBUTE:
      return (
        <>
          {(() => {
            switch (selectedFieldSubType) {
              case AttributeType.LIST_UNIQUE:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                          return (
                            <RuleSelectInput
                              fieldName={`${fieldName}.${index}.values.${0}`}
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              options={availableValueOptions}
                              isRequired
                              validate={() => {
                                return null;
                              }}
                              labelVariant='chip'
                            />
                          );

                        case Operator.NOT_EQUAL:
                          return (
                            <RuleStandardInput
                              fieldName={`${fieldName}.${index}.values.${0}`}
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              isRequired
                              validate={() => {
                                return null;
                              }}
                            />
                          );

                        case Operator.CONTAINS:
                        case Operator.NOT_CONTAINS:
                        case Operator.STARTS_WITH:
                        case Operator.NOT_STARTS_WITH:
                        case Operator.ENDS_WITH:
                        case Operator.NOT_ENDS_WITH:
                          return (
                            <RuleStandardInput
                              fieldName={`${fieldName}.${index}.values.${0}`}
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              isRequired
                              validate={() => {
                                /*TODO: custom validation if any*/
                                return null;
                              }}
                            />
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (
                            <>
                              {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                            </>
                          );

                        case Operator.IN_SET:
                          return (
                            <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                              <EAMultipleSelectInput
                                fieldName={`${fieldName}.${index}.values`}
                                options={availableValueOptions}
                                isRequired
                                onChange={(e) => {
                                  setValue(`${fieldName}.${index}.values`, e.target.value);
                                }}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              />
                            </EAInputBloc>
                          );

                        case Operator.NOT_IN_SET:
                          return (
                            <RuleMultipleValueInput
                              fieldName={`${fieldName}.${index}.values`}
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              isRequired
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}.${index}.values`, { type: 'manual', message: i18next.t(`rules.configuration.criteria.fields.value.errors.isEmpty`) });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}.${index}.values`);
                                  return true;
                                }
                              }}
                            />
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case AttributeType.LIST_MULTIPLE:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                          return (
                            <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                              <EAMultipleSelectInput
                                fieldName={`${fieldName}.${index}.values`}
                                options={availableValueOptions}
                                isRequired
                                onChange={(e) => {
                                  setValue(`${fieldName}.${index}.values`, e.target.value);
                                }}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              />
                            </EAInputBloc>
                          );

                        case Operator.CONTAINS:
                          return (
                            <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                              <EAMultipleSelectInput
                                fieldName={`${fieldName}.${index}.values`}
                                options={availableValueOptions}
                                isRequired
                                onChange={(e) => {
                                  setValue(`${fieldName}.${index}.values`, e.target.value);
                                }}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              />
                            </EAInputBloc>
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (
                            <>
                              {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                            </>
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case AttributeType.INTEGER:
              case AttributeType.DECIMAL:
              case AttributeType.PERCENTAGE:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                          case Operator.GREATER_THAN:
                          case Operator.GREATER_OR_EQUAL:
                          case Operator.LESS_THAN:
                          case Operator.LESS_OR_EQUAL:
                            return (
                              <RuleStandardInput
                                fieldName={`${fieldName}.${index}.values.${0}`}
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                isRequired
                                validate={(value) => {
                                  switch (selectedFieldSubType) {
                                    case AttributeType.INTEGER:
                                      return value && !value.match(REGEXP_INTEGER)
                                        ? i18next.t("attributes.errors.integer")
                                        : null;
                                    case AttributeType.DECIMAL:
                                      return value && !value.match(REGEXP_DECIMAL)
                                        ? i18next.t("attributes.errors.decimal")
                                        : null;
                                    case AttributeType.PERCENTAGE:
                                      return value && !value.match(REGEXP_PERCENTAGE)
                                        ? i18next.t("attributes.errors.percentage")
                                        : null;
                                    default:
                                      return null;
                                  }
                                }}
                              />
                            );

                          case Operator.BETWEEN:
                            return (
                              <>
                                {/*TODO: Two values*/}
                              </>
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                              </>
                            );

                          case Operator.IN_SET:
                          case Operator.NOT_IN_SET:
                            return (
                              <RuleMultipleValueInput
                                fieldName={`${fieldName}.${index}.values`}
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                isRequired
                                validate={(value) => {
                                  let isValid = true;
                                  //TODO: uncomment this when validation issue fixed
                                  switch (selectedFieldSubType) {
                                    case AttributeType.INTEGER:
                                      if (value && !value.match(REGEXP_INTEGER)) {
                                        setError(`${fieldName}.${index}.values`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.integer")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    case AttributeType.DECIMAL:
                                      if (value && !value.match(REGEXP_DECIMAL)) {
                                        setError(`${fieldName}.${index}.values`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.decimal")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    case AttributeType.PERCENTAGE:
                                      if (value && !value.match(REGEXP_PERCENTAGE)) {
                                        setError(`${fieldName}.${index}.values`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.percentage")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    default:
                                      break;
                                  }

                                  return isValid;
                                }}
                              />
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              case AttributeType.BOOLEAN:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return (
                              <RuleSelectInput
                                fieldName={`${fieldName}.${index}.values.${0}`}
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                options={[
                                  { value: "true", label: i18next.t("yes") },
                                  { value: "false", label: i18next.t("no") },
                                ]}
                                isRequired
                                validate={() => {
                                  /*TODO: custom validation if any*/
                                  return null;
                                }}
                                labelVariant='chip'
                              />
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                              </>
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              case AttributeType.DATE:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                        case Operator.GREATER_THAN:
                        case Operator.GREATER_OR_EQUAL:
                        case Operator.LESS_THAN:
                        case Operator.LESS_OR_EQUAL:
                          return (
                            <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                              <Controller
                                control={formMethods.control}
                                name={`${fieldName}.${index}.values.${0}`}
                                render={({ field }) => (
                                  <EADatePicker
                                    {...field}
                                    name={field.name}
                                    formMethods={formMethods}
                                    disableHighlightToday
                                    ref={null}
                                    isRequired
                                  />
                                )}
                              />
                            </EAInputBloc>
                          );

                        case Operator.BETWEEN:
                          return (
                            <>
                              {/*TODO*/}
                            </>
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (<></>);

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return (
                            <>
                              {/*TODO*/}
                            </>
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case AttributeType.STRING:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                        case Operator.CONTAINS:
                        case Operator.NOT_CONTAINS:
                        case Operator.STARTS_WITH:
                        case Operator.NOT_STARTS_WITH:
                        case Operator.ENDS_WITH:
                        case Operator.NOT_ENDS_WITH:
                          return (
                            <RuleStandardInput
                              fieldName={`${fieldName}.${index}.values.${0}`}
                              isRequired
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              validate={() => {
                                /*TODO: custom validation if any*/
                                return null;
                              }}
                            />
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (<></>);

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return (
                            <RuleMultipleValueInput
                              fieldName={`${fieldName}.${index}.values`}
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              isRequired
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}.${index}.values`, { type: 'manual', message: i18next.t(`rules.configuration.criteria.fields.value.errors.isEmpty`) });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}.${index}.values`);
                                  return true;
                                }
                              }}
                            />
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              default:
                break;
            }
          })()}
        </>
      );

    case FieldType.CALCULATED_ATTRIBUTE:
    case FieldType.INDICATOR:
      return (
        <>
          {(() => {
            switch (selectedFieldSubType) {
              case CalculationResultType.STRING:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                          return !_.isEmpty(availableValueOptions) ? (
                            <RuleSelectInput
                              fieldName={`${fieldName}.${index}.values.${0}`}
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              options={availableValueOptions}
                              isRequired
                              validate={() => {
                                return null;
                              }}
                              labelVariant="chip"
                            />
                          ) : (
                            <RuleStandardInput
                              fieldName={`${fieldName}.${index}.values.${0}`}
                              isRequired
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              validate={() => {
                                return null;
                              }}
                            />
                          );
                        case Operator.CONTAINS:
                        case Operator.NOT_CONTAINS:
                        case Operator.STARTS_WITH:
                        case Operator.NOT_STARTS_WITH:
                        case Operator.ENDS_WITH:
                        case Operator.NOT_ENDS_WITH:
                          return (
                            <RuleStandardInput
                              fieldName={`${fieldName}.${index}.values.${0}`}
                              isRequired
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              validate={() => {
                                return null;
                              }}
                            />
                          );
                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (<></>);

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return !_.isEmpty(availableValueOptions) ? (
                            <EAInputBloc label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`} customLabelStyle={{ fontSize: "14px" }}>
                              <EAMultipleSelectInput
                                fieldName={`${fieldName}.${index}.values`}
                                options={availableValueOptions}
                                isRequired
                                variant="ruleInput"
                                onChange={(e) => {
                                  setValue(`criteria.${index}.values`, e.target.value);
                                }}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                validate={(value) => {
                                  if (value.length === 0) {
                                    setError(`criteria.${index}.values`, {
                                      type: 'manual',
                                      message: i18next.t(`infos.required`)
                                    });
                                    return false;
                                  } else {
                                    clearErrors(`criteria.${index}.values`);
                                    return true;
                                  }
                                }}
                              />
                            </EAInputBloc>
                          ) : (
                            <RuleMultipleValueInput
                              fieldName={`${fieldName}.${index}.values`}
                              isRequired
                              label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                              placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`criteria.${index}.values`, {
                                    type: 'manual',
                                    message: i18next.t(`infos.required`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`criteria.${index}.values`);
                                  return true;
                                }
                              }}
                            />
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case CalculationResultType.NUMERIC:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return !_.isEmpty(availableValueOptions) ? (
                              <RuleSelectInput
                                fieldName={`${fieldName}.${index}.values.${0}`}
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                options={availableValueOptions}
                                isRequired
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                                labelVariant="chip"
                              />
                            ) : (
                              <RuleStandardInput
                                fieldName={`${fieldName}.${index}.values.${0}`}
                                isRequired
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                              />
                            );
                          case Operator.GREATER_THAN:
                          case Operator.GREATER_OR_EQUAL:
                          case Operator.LESS_THAN:
                          case Operator.LESS_OR_EQUAL:
                            return (
                              <RuleStandardInput
                                fieldName={`${fieldName}.${index}.values.${0}`}
                                isRequired
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                              />
                            );

                          case Operator.BETWEEN:
                            return (
                              <>
                                {/*TODO: Two values*/}
                              </>
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS UNDEFINED*/}
                              </>
                            );

                          case Operator.IN_SET:
                          case Operator.NOT_IN_SET:
                            return !_.isEmpty(availableValueOptions) ? (
                              <RuleSelectInput
                                fieldName={`${fieldName}.${index}.values`}
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                options={availableValueOptions}
                                isRequired
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                                labelVariant="chip"
                              />
                            ) : (
                              <RuleMultipleValueInput
                                fieldName={`${fieldName}.${index}.values`}
                                isRequired
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                              />
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              case CalculationResultType.BOOLEAN:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return (
                              <RuleSelectInput
                                fieldName={`${fieldName}.${index}.values.${0}`}
                                label={index === 0 && `${i18next.t("rules.configuration.criteria.fields.value.label")}`}
                                placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
                                options={[
                                  { value: "true", label: i18next.t("yes") },
                                  { value: "false", label: i18next.t("no") },
                                ]}
                                isRequired
                                validate={() => {
                                  return null;
                                }}
                                labelVariant="chip"
                              />
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS UNDEFINED*/}
                              </>
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              default:
                break;
            }
          })()}
        </>
      );

    default:
      return (
        <></>
      );
  }
}
export default ScopeCriterionValues;
