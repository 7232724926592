import React, { useContext } from "react";
import i18next from "i18next";

import EAIcon from "components/common/EAIcon";
import EADialog from "components/common/EADialog";
import useStyles from "style/js-style/containers/administration/roles/dialogsStyle";
import { ERROR, SUCCESS } from "services/common/Constants";
import { Context } from "states/Store";
import { createAccountRole } from "services/edge/RoleService";
import RoleForm from "containers/administration/roles/form/RoleForm";
import {Trans} from "react-i18next";


const FORM_ID = "rule-creation-form-id";

const RoleCreateDialog = ({
  baseRoleCandidates,
  isOpen,
  onClose = () => { },
  onValidateSuccess = () => { },
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);

  const handleSubmit = async (data) => {
    try {
      await createAccountRole(state.account.id, data, null);
      const roleName = data.name;

      dispatch({
        type: "ALERT",
        alert: {
          type: SUCCESS,
          msg: <Trans i18nKey="roles.alert.addRole"> Le rôle <span style={{color: "#0B2810", fontWeight: 600}}>"{{roleName}}"</span> a bien été ajouté.</Trans>
        },
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: {
          type: ERROR,
          msg: i18next.t("infos.INTERNAL_ERROR"),
        },
      });
    }
  }

  return (
    <EADialog
      isOpen={isOpen}
      fullWidth
      maxWidth="popupxs"
      customBtnStyle={{ paddingBottom: "24px !important" }}
      customDialogStyle={{ paddingBottom: "0 !important" }}
      onCancel={() => onClose()}
      hasActions={false}
    >
      <div className={classes.root}>
        <EAIcon icon="roles/popup-add-role" />
        <h2 className={classes.title}>
          {i18next.t("roles.popup.addRoles")}
        </h2>

        <div className={classes.mainContentWrapper}>
          <RoleForm
            formId={FORM_ID}
            submitHandler={handleSubmit}
            baseRoleCandidates={baseRoleCandidates}
            onCancel={onClose}
          />
        </div>
      </div>
    </EADialog >
  );
};

export default RoleCreateDialog;
