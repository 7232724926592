import React, { useState, useContext, useEffect } from "react";
import { Divider, Grid } from "@mui/material";
import i18next from "i18next";
import { ThirdpartiesSearchAttributes } from "./ThirdpartiesSearchAttributes";
import { ThirdpartiesSearchIndicators } from "./ThirdpartiesSearchCalculations";
import { ThirdpartiesSearchIndued } from "./ThirdpartiesSearchIndued";
import EAAutoCompleteInput from "components/inputs/EAAutoCompleteInput";
import { useFormContext } from "react-hook-form";
import {
  PrivilegeEnum,
  RoleBasedAccessControlContext,
} from "services/common/RolesUtils";
import { useCallback } from "react";
import { Context } from "states/Store";
import { hasOption } from "services/common/Utils";
import useStyles from "../ThirdpartiesListSearchFormStyle";
import { getAccountCalculations } from "services/edge/CalculatorService";
import { ERROR, FieldType } from "services/common/Constants";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";
import { FINANCIAL_STRENGTH_SCORES } from "services/common/DataBlocksUtils";

export function ThirdpartiesAdvancedSearch({
  transparencyOptions,
  induedOptions,
  financialStrengthScoreOptions,
  financialStrengthRatingOptions,
  filters,
}) {
  const { classes } = useStyles();
  const { setValue } = useFormContext();
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const [calculatedAttributes, setCalculatedAttributes] = useState();
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          const res = await getAccountCalculations(state.account.id, dispatch);
          setCalculatedAttributes(
            res.content?.filter(
              (x) => x.nature === FieldType.CALCULATED_ATTRIBUTE
            )
          );
          setIndicators(
            res.content?.filter((x) => x.nature === FieldType.INDICATOR)
          );
        } catch (e) {
          dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
          setCalculatedAttributes([]);
          setIndicators([]);
        }
      })();
    }
  }, [state.account, dispatch]);

  useEffect(() => {
    // Set default values for theses inputs if the user's privilege allows it.
    setValue("transparency", (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE) && filters.transparency) || []);
    setValue("financialStrengthScore", (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE) && filters.financialStrengthScore) || []);
  }, [filters, setValue, hasAnyOfPrivileges]);

  const hasTransparency = useCallback(() => {
    return (
      hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE) &&
      hasOption(state.accounts, state.account.id, "TRANSPARENCY")
    );
  }, [hasAnyOfPrivileges, state.accounts, state.account]);

  const hasIndued = useCallback(() => {
    return (
      hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE) &&
      hasOption(state.accounts, state.account.id, "INDUED")
    );
  }, [hasAnyOfPrivileges, state.accounts, state.account]);

  const hasFinancialStrength = useCallback(() => {
    return (
      hasAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE
      ) && hasOption(state.accounts, state.account.id, "FINANCIALSTRENGTH")
    );
  }, [hasAnyOfPrivileges, state.accounts, state.account]);

  return (
    <Grid item container spacing={2} padding={"32px"}>
      {hasIndued() && (
        <Grid item container spacing={2}>
          {/* indued */}
          <ThirdpartiesSearchIndued
            induedOptions={induedOptions}
            filters={filters}
          />
        </Grid>
      )}
      {hasTransparency() && (
        <Grid item container spacing={2}>
          {/* Transparency */}
          <Grid item xs={4} sm={3} md={2}>
            <SearchFormInputBloc label={i18next.t("transparency.score")}>
              <EAAutoCompleteInput
                fieldName="transparency"
                options={transparencyOptions}
                placeholder={"Tous"}
                variant="outlined"
                multiple
                limitTags={2}
              />
            </SearchFormInputBloc>
          </Grid>
        </Grid>
      )}
      {hasFinancialStrength() && (
        <>
          <Grid item container spacing={2}>
            {/* Financial Strength risk segment*/}
            <Grid item xs={4} sm={3} md={2}>
              <SearchFormInputBloc
                label={i18next.t("datablocks.financialStrength.scoreLabel")}
              >
                <EAAutoCompleteInput
                  fieldName="financialStrengthScore"
                  options={financialStrengthScoreOptions?.filter(x => x.value !== FINANCIAL_STRENGTH_SCORES.VERY_HIGH)} // Remove very high from options
                  placeholder={"Tous"}
                  variant="outlined"
                  multiple
                  limitTags={2}
                />
              </SearchFormInputBloc>
            </Grid>
            {/* Financial Strength rating */}
            <Grid item xs={4} sm={3} md={2}>
              <SearchFormInputBloc
                label={i18next.t("datablocks.financialStrength.rating")}
              >
                <EAAutoCompleteInput
                  fieldName="financialStrengthRating"
                  options={financialStrengthRatingOptions}
                  placeholder={"Tous"}
                  variant="outlined"
                  multiple
                  limitTags={2}
                />
              </SearchFormInputBloc>
            </Grid>
          </Grid>
        </>
      )}
      {(hasIndued() || hasTransparency() || hasFinancialStrength()) && (
        <Divider className={classes.advancedSearchDivider} />
      )}
      <Grid item container spacing={2} style={{ paddingTop: 0 }}>
        {/* Attributes */}
        <ThirdpartiesSearchAttributes
          calculatedAttributes={calculatedAttributes}
        />
        {/* Calculations */}
        <ThirdpartiesSearchIndicators indicators={indicators} />
      </Grid>
    </Grid>
  );
}
