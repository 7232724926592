import React, { useCallback, useState, useContext, useEffect } from "react";
import i18next from "i18next";
import _ from "lodash";
import countries from "i18n-iso-countries";

import { Context } from "states/Store";
import { ERROR } from "services/common/Constants";
import MenuAppBar from "components/MenuAppBar";
import useStyles from "style/js-style/containers/administration/roles/RolesStyle";
import EABackground from "components/EABackground";
import EAIcon from "components/common/EAIcon";
import EAButton from "components/common/EAButton";
import { getUserRoles } from "services/edge/UserService";
import { getAccountRoles, isRoleAssigned } from "services/edge/RoleService";
import RoleCreateDialog from "./dialogs/RoleCreateDialog";
import RoleEditDialog from "./dialogs/RoleEditDialog";
import RoleDeleteDialog from "./dialogs/RoleDeleteDialog";
import PrivilegesPanel from "./privileges/PrivilegesPanel";
import {
  PrivilegeEnum,
  RoleBasedAccessControlContext,
  cleanRoleHierarchy,
  findRoleByIdInHierarchyFromList,
  getAllChildRoles,
  getSortedFlatRoles
} from "services/common/RolesUtils";
import RoleMenu from "./menu/RoleMenu";

import { getAccountAttributesByBondType } from "../../../services/edge/AttributesService";
import { getAccountCalculations } from "../../../services/edge/CalculatorService";
import { CalculationNature } from "../../../services/common/CalculatorUtils";
import { getListActivity } from "services/edge/ListService";

function Roles() {
  const { classes } = useStyles();

  const [state, dispatch] = useContext(Context);
  const { isGlobalAdmin, hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const [roles, setRoles] = useState([]);
  const [lastRoleID, setLastRoleID] = useState(null);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);
  const [isSelectedRoleReadOnly, setIsSelectedRoleReadOnly] = useState(false);
  const [isSelectedRoleAssigned, setIsSelectedRoleAssigned] = useState(false);

  const [accountActivities, setAccountActivities] = useState(null)
  const [accountCountries, setAccountCountries] = useState(null)
  const [accountDossierAttributes, setAccountDossierAttributes] = useState(null)
  const [accountThirdpartyAttributes, setAccountThirdpartyAttributes] = useState(null)
  const [accountCalculatedAttributes, setAccountCalculatedAttributes] = useState(null)
  const [accountIndicators, setAccountIndicators] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (
      accountActivities !== null &&
      accountCountries !== null &&
      accountDossierAttributes !== null &&
      accountThirdpartyAttributes !== null
    ) {
      setIsLoaded(true)
    }
  }, [accountActivities, accountCountries, accountThirdpartyAttributes, accountDossierAttributes])

  // Fetch countries & activities
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await getListActivity(undefined, dispatch);
        setAccountActivities(resp.content.map(activity => {
          return {
            value: activity.code,
            label: activity.descriptionComplete.trim(),
            level: activity.level
          }
        }).sort((a, b) => a.label.localeCompare(b.label)));

        // LIST COUNTRIES
        let distinctCountries = [];
        let allCountries = countries.getNames(i18next.language, { select: "official" });
        for (const [key, value] of Object.entries(allCountries)) {
          distinctCountries.push({ "value": key, "label": value });
        }
        const sortedMap = Array.from(distinctCountries).sort((a, b) => a.label.localeCompare(b.label));
        setAccountCountries(sortedMap);

      } catch (e) {
        dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      }
    }

    fetchData();
  }, [dispatch, state.account]);

  // Fetch attributes
  const fetchAttributes = useCallback(async () => {
    try {
      const thirdpartyResults = await getAccountAttributesByBondType(state.account.id, "thirdparty", null);
      const dossierResults = await getAccountAttributesByBondType(state.account.id, "dossier", null);
      const CalculationResults = await getAccountCalculations(state.account.id, null);
      let calculatedAttributes = [];
      let indicators = [];

      CalculationResults.content.forEach(calcul => {
        if (calcul.nature === CalculationNature.INDICATOR) {
          indicators.push({
            value: calcul.id,
            label: calcul.name,
            type: calcul.resultType
          });
        }
        if (calcul.nature === CalculationNature.CALCULATED_ATTRIBUTE) {
          calculatedAttributes.push({
            value: calcul.id,
            label: calcul.name,
            type: calcul.resultType
          });
        }
      })

      setAccountCalculatedAttributes(calculatedAttributes);
      setAccountIndicators(indicators);
      setAccountDossierAttributes(dossierResults.content);
      setAccountThirdpartyAttributes(thirdpartyResults.content);
    } catch (error) {
      setAccountDossierAttributes([]);
      setAccountThirdpartyAttributes([]);
      setAccountCalculatedAttributes([]);
      setAccountIndicators([]);
    }
  }, [state.account]);

  useEffect(() => {
    if (state.account) {
      fetchAttributes();
    }
  }, [state.account, fetchAttributes])

  useEffect(() => {
    if (!_.isEmpty(roles)) {
      let lastElement = roles[roles.length - 1];
      while (lastElement.derivedRoles.length > 0) {
        lastElement = lastElement.derivedRoles[lastElement.derivedRoles.length - 1];
      }

      setLastRoleID(lastElement.id)
    }
  }, [roles])

  const getBaseRoleCandidates = useCallback((role) => {
    if (role !== null) {
      //A descendant role can't become a baseRole of an ascendant role
      const childRoleIds = getAllChildRoles(role).map((roleItem) => roleItem.id);

      //Exlude the role itself and all its descendant roles
      let results = getSortedFlatRoles(roles).filter((roleItem) => {
        return !_.includes(childRoleIds, roleItem.id) && roleItem.id !== role.id;
      }).map((r) => {
        return {
          value: r.id,
          label: r.name
        }
      });

      return results;
    } else {
      let results = getSortedFlatRoles(roles).map((r) => {
        return {
          value: r.id,
          label: r.name
        }
      });

      return results;
    }
  }, [roles]);

  const fetchRoles = useCallback(async () => {
    try {
      let results = null;

      if (isGlobalAdmin) {
        results = await getAccountRoles(state.account.id, dispatch);
      } else {
        results = await getUserRoles(state.account.id, state.user.id, dispatch)
      }

      if (!_.isEmpty(results?.content)) {
        dispatch({ type: "USER_ROLES", roles: results.content });
      }
    } catch (e) {
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: "INTERNAL_ERROR" } });
      setRoles([]);
      setSelectedRole(null);
    }
  }, [state.account, state.user, dispatch, isGlobalAdmin]);

  useEffect(() => {
    if (state.account && state.user && !_.isEmpty(state.roles)) {
      let fetchedRoles = [];

      if (isGlobalAdmin) {//If it's the global admin we start by the default role since all account roles are fetched
        const defaultRole = _.find(state.roles, { default: true });

        fetchedRoles = defaultRole ? [defaultRole] : [];
      } else {
        fetchedRoles = cleanRoleHierarchy(state.roles);
      }

      setRoles(fetchedRoles);

      setSelectedRole((prev) => {
        if (prev && prev.id) {
          let foundRole = findRoleByIdInHierarchyFromList(prev.id, fetchedRoles);

          if (foundRole) {
            return foundRole;
          } else if (prev.baseRoleId) {
            foundRole = findRoleByIdInHierarchyFromList(prev.baseRoleId, fetchedRoles);

            if (foundRole) {
              return foundRole;
            }
          }
        }

        return _.first(fetchedRoles);
      });
    } else {
      setRoles([]);
      setSelectedRole(null);
    }
    // eslint-disable-next-line
  }, [state.account, state.user, state.roles, isGlobalAdmin])

  const isOwnedRole = useCallback((role) => {
    let isOwnedRole = false;

    if (!_.isEmpty(state.roles) && role) {
      isOwnedRole = _.some(state.roles, (item) => item.id === role.id);
    }

    return isOwnedRole;
  }, [state.roles]);

  useEffect(() => {
    setIsSelectedRoleReadOnly(isOwnedRole(selectedRole));
  }, [state.roles, selectedRole, isOwnedRole]);

  const checkRoleAssigned = useCallback(async (role) => {
    try {
      const resp = await isRoleAssigned(state.account.id, role.id, dispatch);

      setIsSelectedRoleAssigned(!!resp.content)
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
      setIsSelectedRoleAssigned(true);
    }
  }, [state.account, dispatch, setIsSelectedRoleAssigned])

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);
  

  return (
    <div className={classes.root}>
      <MenuAppBar variant={"users"} />
      <EABackground />
      <div className={classes.mainWrapper}>
        {/* ------------- */}
        {/* --- ROLES --- */}
        {/* ------------- */}
        <div className={classes.rolesPanel}>
          {/* Header */}
          <div className={classes.rolesPanelHeader}>
            <EAIcon icon="roles/roles-title" className={classes.headerLogo} />
            <h2 className={classes.rolesPanelHeaderTitle}>
              {i18next.t("roles.title")}
            </h2>
          </div>

          {/* Content */}
          <div className={classes.rolesPanelContent}>
            {roles && <RoleMenu
              roles={roles}
              selectedRole={selectedRole}
              lastRoleID={lastRoleID}
              onRoleEdit={(role) => {
                setSelectedRole(role);
                setIsEditDialogOpen(true);
              }}
              onRoleDelete={(role) => {
                checkRoleAssigned(role);
                setSelectedRole(role);
                setIsDeleteDialogOpen(true);
              }}
              onRoleSelected={(role) => {
                setSelectedRole(role);
              }}
            />
            }
          </div>

          {/* Add roles */}
          <div className={classes.rolesPanelButton}>
            <EAButton
              content={
                <>
                  <span className={classes.crossBtn}>+</span>
                  {i18next.t("roles.addRole")}
                </>
              }
              disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_ROLE)}
              customStyle={{
                width: "280px",
                height: "54px",
                fontSize: "18px",
                borderRadius: "12px",
              }}
              onClick={() => setIsCreateDialogOpen(true)}
            />
          </div>
        </div>

        {/* ------------- */}
        {/* --- RULES --- */}
        {/* ------------- */}
        {isLoaded &&
          <PrivilegesPanel
            role={selectedRole}
            isSelectedRoleReadOnly={isSelectedRoleReadOnly}
            onValidateSuccess={() => {
              fetchRoles();
            }}
            accountActivities={accountActivities}
            accountCountries={accountCountries}
            accountThirdpartyAttributes={accountThirdpartyAttributes}
            accountDossierAttributes={accountDossierAttributes}
            accountCalculatedAttributes={accountCalculatedAttributes}
            accountIndicators={accountIndicators}
          />
        }

        {/* PopUp : on create */}
        {isCreateDialogOpen && (
          <RoleCreateDialog
            baseRoleCandidates={getBaseRoleCandidates(null)}
            isOpen={isCreateDialogOpen}
            onClose={() => setIsCreateDialogOpen(false)}
            onValidateSuccess={() => {
              setIsCreateDialogOpen(false);
              fetchRoles();
            }}
          />
        )}

        {/* PopUp : on edit */}
        {isEditDialogOpen && (
          <RoleEditDialog
            role={selectedRole}
            baseRoleCandidates={getBaseRoleCandidates(selectedRole)}
            isOpen={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            onValidateSuccess={() => {
              setIsEditDialogOpen(false);
              fetchRoles();
            }}
          />
        )}

        {/* PopUp : on delete */}
        {isDeleteDialogOpen && (
          <RoleDeleteDialog
            role={selectedRole}
            isRoleAssigned={isSelectedRoleAssigned}
            baseRoleCandidates={getBaseRoleCandidates(selectedRole)}
            isOpen={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onValidateSuccess={() => {
              setIsDeleteDialogOpen(false);
              fetchRoles();
            }}
          />
        )}
      </div>
    </div >
  );
}

export default Roles;
