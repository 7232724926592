import React, {useState} from "react";
import { Alert, IconButton } from "@mui/material";
import EAIcon from "./common/EAIcon";
import { ERROR, INFO, SUCCESS, WARNING } from "../services/common/Constants";
import useStyles from "../style/js-style/components/EAAlertStyle";

/**
 * EAIcon component with severity icon
 * @param severity ERROR | INFO | SUCCESS
 */
const EAAlertIcon = ({ severity }) => {
  switch (severity) {
    case ERROR:
      return <EAIcon icon={"error-rounded"} />;
    case INFO:
      return <EAIcon icon={"info-rounded"} />;
    case SUCCESS:
      return <EAIcon icon={"valid-rounded"} />;
    case WARNING:
      return <EAIcon icon={"warning_yellow"} />;
    default:
      return;
  }
};

/**
 * EAIcon component with close icon
 * @param severity ERROR | INFO | SUCCESS
 */
const EAAlertCloseIcon = ({ severity }) => {
  switch (severity) {
    case ERROR:
      return <EAIcon icon={"close_red"} />;
    case INFO:
      return <EAIcon icon={"arrow/arrow-to-right_large_green"} />;
    case SUCCESS:
      return <EAIcon icon={"close_green"} />;
    case WARNING:
      return <EAIcon icon={"close_yellow"} />;
    default:
      return;
  }
};

const EAAlert = ({ severity, onClose, children }) => {
  const [alertOpen, setAlertOpen] = useState(true);
  const { classes } = useStyles();

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return ( alertOpen ?
    <Alert
      severity={severity}
      icon={<EAAlertIcon severity={severity} />}
      onClose={handleAlertClose}
      action={
        <IconButton
          style={{ marginLeft: "28px" }}
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            handleAlertClose();
            onClose();
          }}
        >
          <EAAlertCloseIcon severity={severity} />
        </IconButton>
      }
      className={classes.alert}
    >
      {children}
    </Alert> : <></>
  );
};

export default EAAlert;
