import i18next from "i18next";
import React, { useContext, useState } from "react";
import EADialog from "../../components/common/EADialog";
import { EDGE_DATE_FORMAT, ERROR } from "../../services/common/Constants";
import {
  addDossierAttribute,
  addThirdpartyAttribute,
} from "../../services/edge/AttributesService";
import { Context } from "../../states/Store";
import AttributeValueForm from "./AttributeValueForm";
import useStyles from "../../style/js-style/containers/attribute-values/AttributeValueEditDialogStyle";
import moment from "moment";

const AttributeValueEditDialog = ({
  bondType,
  id,
  isOpen,
  attributeValue,
  onCancel,
  onValidateSuccess,
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isValidateDisabled, setIsValidateDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const FORM_ID = "attribute-value-update-form-id";

  const handleUpdateAttribute = async (data) => {
    if(!isLoading) {
      setIsLoading(true);
      try {
        if(moment.isMoment(data.value)) {
          data.value = moment(data.value).format(EDGE_DATE_FORMAT);
        }
        const attributeToSave = {
          label: attributeValue.label,
          values: data.values || (data.value ? [data.value] : null),
        };
        switch (bondType) {
          case "thirdparty":
            await addThirdpartyAttribute(
              state.account.id,
              id,
              attributeToSave,
              null
            );
            break;
          case "dossier":
            await addDossierAttribute(
              state.account.id,
              id,
              attributeToSave,
              null
            );
            break;
          default:
            throw new Error("Wrong bond type");
        }
        setIsLoading(false);
        onValidateSuccess();
        onCancel();
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: {
            type: ERROR,
            msg: "attributes.errors.updateAttributeValue",
          },
        });
      }
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      title={i18next.t("attributes.editValueTitle", {type: i18next.t("attributes." + bondType)})}
      onCancel={onCancel}
      formId={FORM_ID}
      maxWidth="popupsm"
      fullWidth
      variant="rightControl"
      isValidateDisabled={isValidateDisabled}
    >
        <>
          <AttributeValueForm
            formId={FORM_ID}
            bondType={bondType}
            attributeValue={attributeValue}
            submitHandler={handleUpdateAttribute}
            onError={(error) => setIsValidateDisabled(!!error)}
          />
          <div className={classes.separate}></div>
        </>
    </EADialog>
  );
};

export default AttributeValueEditDialog;
