import {
  Drawer,
  List,
  ListItemIcon,
  ListItem,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import React, { useCallback, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Context } from "../states/Store";
import ROUTES, { getUrlWithAccountId } from "../config/Routes";
import i18next from "../i18n/i18n";
import { DefaultSelectProps } from "../services/common/Constants";
import { FOOTER_ITEMS, getDrawerOptions } from "../services/common/DrawerUtils";
import useStyles from "../style/js-style/components/EADrawerStyle";
import EAIcon from "./common/EAIcon";
import _ from "lodash";
import { RoleBasedAccessControlContext } from "../services/common/RolesUtils";

// List item nav
const EAListItem = ({
  item,
  disabled = false,
  crumbs,
  menuIsOpen,
  setMenuItems,
}) => {
  const { classes } = useStyles();
  const [state] = useContext(Context);
  let history = useHistory();

  // Verify is route is active using the route value and the breadcrumb.
  const isActiveRoute = useCallback(
    (menuRouteValue) => {
      if (!crumbs) return false;
      if (_.isEmpty(crumbs) && menuRouteValue === ROUTES.DASHBOARD) return true;

      const crumbsLabels = crumbs
        .filter((x) => x.label?.includes("breadcrumb"))
        .map((x) => x.label.substring(11));

      // Archives
      if (crumbsLabels.includes("archives")) {
        // archives label active
        if (menuRouteValue === "archives") return true;

        // last crumb is active for archives ( ex: archives/thirdparties )
        if (menuRouteValue === `archives/${crumbsLabels[crumbsLabels.length - 1]}`) return true;
      } else {
        // Last crumb is the one active
        if (menuRouteValue === crumbsLabels[crumbsLabels.length - 1]) return true;
      }

      return false;
    },
    [crumbs]
  );

  const handleItemClick = (event, option) => {
    event.preventDefault();

    // Shows/Hides the subItems of the clicked Item.
    setMenuItems((prevState) => {
      return prevState.map((item) => ({
        ...item,
        display:
          option.hasChildren && item.parent === option.text
            ? !item.display
            : item.display,
      }));
    });

    // Runs the onClick event for the option.
    if (option.onClick) {
      option.onClick();
    }

    // Redirect to the route if possible.
    if (
      !option.disabled &&
      !option.hasChildren &&
      option.value &&
      state.account?.id
    ) {
      history.push(`/${state.account?.id}/${option.value}`);
    }
  };

  return (
    <ListItem
      button
      className={clsx({
        [classes.active]: isActiveRoute(item.value),
        [classes.activeItem]: isActiveRoute(item.value) && item.type === "item",
        [classes.subItem]: item.type === "subItem",
      })}
      onClick={(event) => handleItemClick(event, item)}
      disabled={item.disabled || disabled}
    >
      {/* Decoration in left of link in extend drawer */}
      {menuIsOpen && isActiveRoute(item.value) && item.type === "item" && (
        <div className={classes.decoActive}> </div>
      )}

      {item.iconType === "custom" && (
        <div className={classes.customMenuIconContainer}>
          <EAIcon
            icon={`/drawer/${item.icon}${
              isActiveRoute(item.value) && item.icon !== "underscore"
                ? "_active"
                : ""
            }`}
            alt={item.icon}
          />
        </div>
      )}
      {item.iconType !== "custom" && (
        <ListItemIcon>
          <Icon className={classes.menuIcon}>{item.icon}</Icon>
        </ListItemIcon>
      )}

      {/* Display item nav name only on large menu */}
      {menuIsOpen && (
        <ListItemText
          primaryTypographyProps={{ variant: "body2" }}
          primary={i18next.t(item.text)}
        />
      )}
    </ListItem>
  );
};

function EADrawer({ crumbs }) {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [menuIsOpen, setMenuIsOpen] = React.useState(true);
  let history = useHistory();
  const [menuItems, setMenuItems] = React.useState([]);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const { isGlobalAdmin, hasAnyOfPrivileges } = React.useContext(
    RoleBasedAccessControlContext
  );

  // Setting menu for account and user profile
  useEffect(() => {
    if (state.user && state.account) {
      setMenuItems(getDrawerOptions(state, dispatch));
    }
  }, [dispatch, state.account, state.user, state]);

  function close() {
    dispatch({ type: "TOGGLE_DRAWER", toggleDrawer: false });
  }

  // toggle for menu reduce/extend
  const handleMenuIconClick = () => {
    setMenuIsOpen(!menuIsOpen);
    dispatch({ type: "OPENED_DRAWER", drawerIsOpened: !menuIsOpen });
  };

  const handleAccountChange = (event) => {
    const accId = event.target.value;
    dispatch({
      type: "SELECTED_ACCOUNT",
      account: state.accounts.find((acc) => acc.id === accId),
    });
    localStorage.setItem("SELECTED_ACCOUNT", accId);
    history.push(getUrlWithAccountId(accId, ROUTES.DASHBOARD));
  };

  return (
    <>
      <Drawer
        open={state.toggleDrawer}
        onClose={() => close()}
        variant={"permanent"}
        className={menuIsOpen ? classes.drawer : classes.drawerClosed}
        classes={
          menuIsOpen
            ? { paper: classes.drawerPaper }
            : { paper: classes.drawerPaperClosed }
        }
        anchor="left"
        sx={state?.isMenuHighlighted ? { zIndex: 1301 } : {}}
      >
        <div role="presentation">
          {menuIsOpen ? (
            // Extended drawer
            <div
              className={`${classes.toolbar} ${classes.logoContainer}`}
              onClick={() => history.push("/")}
            >
              <EAIcon
                icon="logo/logo_small"
                alt="logo"
                className={classes.logo}
              />
              <span className={classes.logoTextPart}>{i18next.t("365")}</span>
              {menuIsOpen ? <EAIcon icon={"separator"} /> : ""}
            </div>
          ) : (
            // reduced drawer
            <div
              className={`${classes.toolbar} ${classes.logoContainerReduced}`}
              onClick={() => history.push("/")}
            >
              <EAIcon icon={"logo/logo_icon"} />
            </div>
          )}

          {/* Reduce menu icon */}
          <div
            className={classes.reduceMenuWrapper}
            onClick={handleMenuIconClick}
          >
            <EAIcon
              icon={menuIsOpen ? "drawer/reduce-menu" : "drawer/extend-menu"}
            />
          </div>

          {/* My account */}
          {(state.account && menuIsOpen) && (
            <div>
              <div className={classes.accountSelectorContainer}>
                  <FormControl fullWidth>
                    <InputLabel id="myAccounts">
                      {i18next.t("myAccounts")}
                    </InputLabel>
                    <Tooltip title={state.account?.name?.toLowerCase()} arrow open={tooltipOpen}>
                      <Select
                        labelId="myAccounts"
                        id="account-select-id"
                        value={state.account?.id}
                        onChange={handleAccountChange}
                        onMouseEnter={() => setTooltipOpen(true)}
                        onMouseLeave={() => setTooltipOpen(false)}
                        onClick={() => setTooltipOpen(false)}
                        variant="outlined"
                        label={i18next.t("myAccounts")}
                        MenuProps={{
                          PaperProps: {
                            onMouseEnter: () => setTooltipOpen(false),
                            onMouseLeave: () => setTooltipOpen(true)
                          },
                          ...DefaultSelectProps.MenuProps
                        }}
                        className={clsx('dt', classes.accountSelectorInnerText)}
                      >
                        {state.accounts.map((acc, index) => (
                          <MenuItem className={"dt"} key={index} value={acc.id}>
                            {acc.name.toLowerCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
              </div>
            </div>
          )}

          {/* Lateral top nav */}
          <div>
            <List
              component="nav"
              aria-label="main menu"
              className={classes.list}
            >
              {menuItems.map((item) =>
                item.value === ROUTES.EVENTS && !isGlobalAdmin ? (
                  "" // Cas particulier de la page event: ne s'affiche pas si non super admin
                ) : item.display &&
                  item.value &&
                  !item.disabled &&
                  (item.neededPrivileges !== null
                    ? hasAnyOfPrivileges(...item.neededPrivileges)
                    : true) ? (
                  <Link
                    to={getUrlWithAccountId(state.account?.id, item.value)}
                    style={{ textDecoration: "none", color: "inherit" }}
                    key={item.value}
                  >
                    <EAListItem
                      item={item}
                      crumbs={crumbs}
                      menuIsOpen={menuIsOpen}
                      setMenuItems={setMenuItems}
                    />
                  </Link>
                ) : (
                  <EAListItem
                    item={item}
                    key={item.value}
                    disabled
                    crumbs={crumbs}
                    menuIsOpen={menuIsOpen}
                    setMenuItems={setMenuItems}
                  />
                )
              )}
            </List>

            {/*Lateral footer nav : display only on large menu*/}
            {menuIsOpen && (
              <List
                component="nav"
                aria-label="usefull links"
                className={classes.footerLinks}
              >
                {FOOTER_ITEMS.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    component="a"
                    href={i18next.t(item.link)}
                    target="_blank"
                  >
                    <ListItemText
                      primaryTypographyProps={{ variant: "body2" }}
                      primary={i18next.t(item.text)}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default EADrawer;
