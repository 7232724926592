import React, { useContext, useState } from "react";
import i18next from "i18next";
import { downloadAllDossierDocs, getDossierSheet } from "../../../services/edge/DossierService";
import { ERROR } from "../../../services/common/Constants";
import { Context } from "../../../states/Store";
import useStyles from "../../../style/js-style/containers/dossiers/dossiers-sheet/DossierDocumentsHeaderButtonsStyle";
import DossierUpdateDocumentDialog from "../update-dossier/DossierUpdateDocumentDialog";
import EAIcon from "../../../components/common/EAIcon";
import EAButton from "../../../components/common/EAButton";

export const DossierDocumentsHeaderButtons = ({ dossier, disabled = false }) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const hasTypeDossier = dossier?.dossierTypeCode !== null;
  const isLockedTypeDossier = dossier.dossierTypeLock === true;
  const isBtnDownloadDisabled =
    dossier.documents.length === 0 ||
    dossier.documents.filter((d) => d.present === true).length === 0;

  const handleDownloadAllDocs = async () => {
    try {
      const result = await downloadAllDossierDocs(
        state.account.id,
        dossier.dossierId,
        dispatch
      );
      window.open(
        result.content?.slice(1, -1),
        "_blank",
        "noopener, noreferrer"
      );
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: "DOWNLOAD_DOSSIER_DOCS_ERROR" },
      });
    }
  };

  const handleUpdateDialogClose = async (isUpdated) => {
    setIsUpdateDialogOpen(false);
    if (isUpdated) {
      try {
        const result = await getDossierSheet(
          state.account.id,
          dossier.dossierId,
          i18next.language,
          dispatch
        );
        dispatch({ type: "DOSSIER", dossier: result.content });
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "DOSSIER_NOT_FOUND" },
        });
      }
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      {dossier.monitored && (!hasTypeDossier || !isLockedTypeDossier) && (
        <EAButton
          className={classes.downloadBtn}
          size="small"
          onClick={() => setIsUpdateDialogOpen(true)}
          content={i18next.t("dossierSheet.manage")}
          customStyle={{ padding: "12px 15px" }}
          disabled={disabled}
        />
      )}
      {(
        <EAButton
          className={classes.downloadBtn}
          size="small"
          disabled={isBtnDownloadDisabled || disabled}
          onClick={handleDownloadAllDocs}
          content={
            <>
              <EAIcon icon={"download"} className={classes.downloadIcon} />
              {i18next.t("dossierSheet.downloadAllFiles")}
            </>
          }
        />
      )}
      {isUpdateDialogOpen && (
        <DossierUpdateDocumentDialog
          dossierId={dossier.dossierId}
          isOpen={isUpdateDialogOpen}
          documents={dossier.documents?.map((d) => d.documentCode)}
          handleClose={handleUpdateDialogClose}
        />
      )}
    </div>
  );
};
