import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: "64px",
  },
  mainWrapper: {
    height: "calc(100vh - 112px)",
    display: "flex",
    background: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.492) 0%, rgba(255, 255, 255, 0.48) 110.84%)",
    border: "1.5px solid #FFFFFF",
    borderRadius: "4px",
    position: "relative",
    },
  rolesPanel: {
    width: "336px",
    background: "white",
    marginBottom: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  // Roles : header
  rolesPanelHeader: {
    display: "flex",
    alignItems: "center",
    height: "119px",
    borderRadius: "4px 0 0 4px",
  },
  headerLogo: {
    position: "relative",
    top: "10px"
  },
  rolesPanelHeaderTitle: {
    fontFamily: 'Metric',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    color: "#373534",
  },

  // Roles : main content
  rolesPanelContent: {
    flexGrow: 1,
    padding: "0 11px 11px 11px",
  },
  rolesPanelContentDefault: {
    backgroundColor: theme.palette.primary.primary100,
    height: "45px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px 0 16px",
    position: "relative"
  },
  rolesPanelContentDefaultContent: {
    display: "flex",
    gap: "8px",
  },
  rolesPanelContentDefaultContentRole: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: "#2D9F40"
  },
  rolesPanelContentDefaultEdit: {
    cursor: "pointer",
  },
  rolesPanelContentDefaultEditRoot: {
    cursor: "pointer",
    marginRight: "23px",
  },
  rolesPanelContentChild: {
    height: "45px",
    padding: "8px 8px 0 16px",
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  rolesPanelChildHierarchy: {
    display: "flex",
    gap: "20px",
    marginRight: "6px",
    alignSelf: "flex-start",
    minHeight: "35px",
  },
  rolesPanelChildRoleName: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#535362"
  },
  rolesPanelChildPanel: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  roleName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
  },
  roleNameSelected: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
    fontWeight: 600,
    color: "#2D9F40"
  },

  // Icons
  deleteIcon: {
    height: "16px",
  },
  editIconDefault: {
    marginRight: "19px",
  },
  arrowMenuIcon: {
    marginRight: "8px",
    cursor: "pointer",
  },
  curveIcon: {
    position: "relative",
    top: "-5px",
  },
  lineIcon: {
    position: "absolute",
    left: "16px",
  },
  // Button : add role
  rolesPanelButton: {
    margin: "0 auto",
    paddingBottom: "32px",
  },
  crossBtn: {
    fontSize: "24px",
    fontWeight: 500,
    marginRight: ".5rem",
  },
}));

export default useStyles;
