import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  deleteAttributeWrapper: {
    position: "absolute",
    bottom: "24px",
    left: "1.5rem",
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC0001",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    cursor: "pointer",
  },
  deleteAttributeImg: {
    width: "9px",
    marginRight: "10px",
  },
  disabledDeleteBtn: {
    opacity: "30%",
    cursor: "default",
  },
  eaAlertContent: {
    color: "#CC7B00"
  }
}));

export default useStyles;
