import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root : {
        background: "#fcfcfe",
        margin: "0 0 0 16px",
    },
    // Edit
    rootEdit: {
        background: "#f8f9fd",
        margin: "11px 0 16px 0px",
        borderRadius: "4px",
        padding: "31px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        gap: "30px",
        // flexWrap: 'wrap'
    },
    uniqueModelWrapper: {
        background: "rgba(239, 251, 241, 1)",
        width: "304px",
        height: "192px",
        padding: "24px 20px 24px 20px",
        borderRadius: "12px",
        boxShadow: "5px 5px 30px " + theme.palette.grey.grey200,
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    uniqueModelTitle: {
        fontWeight: 600,
        fontFamily: "Metric",
        fontSize: "20px",
        margin: "5px 0 15px 0"
    },
    separator: {
        color: "rgba(126, 126, 143, 1)",
        fontWeight: 700,
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: "center",
        gap: "30px",
        height: "280px"
    },
    separatorLine: {
        borderLeft: "1px solid rgba(226, 226, 234, 1)",
        height: "100px"
    },
    multipleModelAndImportWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    multipleModelContentNoticeTitle: {
        fontWeight: 700,
        fontSize: "16px",
        color: "rgba(55, 53, 52, 1)",
        marginBottom: "14px",
    },
    multipleModelContentNoticeItem: {
        display: 'flex',
        alignItems: 'center',
        gap: "12px",
        fontWeight: 700,
        color: "rgba(55, 53, 52, 1)",
        fontSize: "14px",
        marginBottom: "14px"
    },
    multipleModelContentNoticeItemSubTitle: {
        color: "rgba(112, 112, 124, 1)",
        fontSize: "12px",
        fontWeight: 400
    },
    multipleModelWrapper: {
        flex: 1,
        maxWidth: "713px",
        width: "100%",
        marginBottom: "13px",
        display: 'flex',
        flexDirection: 'column',
        border: "1.5px solid white",
        padding: "24px 20px 24px 20px",
        borderRadius: "12px",
        background: "#f3f4f9",
        boxShadow: "5px 5px 30px " + theme.palette.grey.grey200,
    },
    multipleModelTitle: {
        fontWeight: 600,
        fontSize: "20px",
        fontFamily: "Metric",
        marginBottom: "17px"
    },
    multipleModelContent: {
        display: 'flex',
        gap: "37px",
        // flexWrap: 'wrap',
        justifyContent: 'center'
    },
    // Datatable
    rootDatatable: {
        background: "white",
        marginTop: "16px",
        padding: "1rem",
        borderRadius: "12px",
        zIndex: 2,
        boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.07)",
        marginBottom: "16px",
    },
    titleDatatable: {
        paddingLeft: "1.5rem !important",
        "& h3": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }
    }
}));

export default useStyles;