import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import i18next from "i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import EAButton from "../../../components/common/EAButton";
import EATabPanel from "../../../components/common/EATabPanel";
import PanelIndued from "../../../components/thirdparties/PanelIndued";
import { ERROR } from "../../../services/common/Constants";
import {
  DueDiligenceStatus,
  isInduedDone,
} from "../../../services/common/InduedUtils";
import {
  launchThirdpartyNewDueligence,
  refreshThirdpartyIndued,
} from "../../../services/edge/ThirdpartyService";
import { Context } from "../../../states/Store";
import useStyles from "../../../style/js-style/containers/thirdparties/sheet/ThirdpartyRisksStyle";
import EAIcon from "../../../components/common/EAIcon";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "../../../services/common/RolesUtils";
import { theme } from "../../../style/globalStyle";
import useMediaQuery from "@mui/material/useMediaQuery";
import PanelIndicators from "../../../components/thirdparties/PanelIndicators";
import PanelTransparency from "components/thirdparties/PanelTransparency";
import clsx from "clsx";
import { hasOption } from "services/common/Utils";
import PanelFinancialStrength from "components/thirdparties/PanelFinancialStrength";
import { getEvolutionScoreDataBlocks } from "services/edge/DataBlocksService";

function a11yProps(index) {
  return {
    id: `tp-risks-tab-${index}`,
    "aria-controls": `tp-risks-tabpanel-${index}`,
    value: index,
  };
}

const ThirdpartyRisksActions = ({ indued, onInduedChange }) => {
  const [state, dispatch] = useContext(Context);
  const { classes } = useStyles();
  let { thirdpartyId } = useParams();
  const { hasAnyOfPrivileges } = React.useContext(RoleBasedAccessControlContext);
  const mediaQueryXL = useMediaQuery(theme.breakpoints.up("1900"));
  const mediaQueryL = useMediaQuery(theme.breakpoints.up("1480"));

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);

  const refreshIndued = async () => {
    setIsRefreshing(true);
    try {
      await refreshThirdpartyIndued(state.account.id, thirdpartyId, dispatch);
      setIsRefreshing(false);
      onInduedChange && onInduedChange();
    } catch (e) {
      setIsRefreshing(false);
      if (!e?.message.messages?.error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }
  };

  const openFPM = (fpmUrl) => {
    window.open(fpmUrl, "_blank", "noopener, noreferrer")?.focus();
  };

  const launchDueDiligence = async () => {
    setIsLaunching(true);
    try {
      await launchThirdpartyNewDueligence(
        state.account.id,
        thirdpartyId,
        dispatch
      );
      setIsLaunching(false);
      onInduedChange && onInduedChange();
    } catch (e) {
      setIsLaunching(false);
      if (!e?.message.messages?.error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "8px",
        right: "20px",
        gap: "20px",
        display: "flex",
      }}
    >
      {indued?.due_diligence?.status === DueDiligenceStatus.FPM_IN_PROGRESS && (
        <EAButton
          size="small"
          onClick={() => openFPM(indued.due_diligence.fpm_url)}
          colorVariant={"warningLight"}
          disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE)}
          customStyle={{ fontSize: mediaQueryL ? "14px" : mediaQueryXL ? "16px" : "12px" }}
          content={
            <>
              <EAIcon icon="false-positif" className={classes.mr6} />
              <span>{i18next.t("indued.fpm")}</span>
            </>
          }
        />
      )}
      {indued?.created_date && !isInduedDone(indued) && (
        <EAButton
          size="small"
          onClick={() => refreshIndued()}
          disabled={isRefreshing || !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE)}
          customStyle={{ fontSize: mediaQueryL ? "14px" : mediaQueryXL ? "16px" : "12px" }}
          content={
            <>
              <EAIcon icon="refresh" className={classes.mr6} />
              <span>{i18next.t("refresh")}</span>
            </>
          }
        />
      )}
      {isInduedDone(indued) && (
        <EAButton
          size="small"
          onClick={() => launchDueDiligence()}
          disabled={isLaunching || !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE)}
          colorVariant={"primaryLight"}
          customStyle={{ fontSize: mediaQueryL ? "14px" : mediaQueryXL ? "16px" : "12px" }}
          content={
            <>
              <EAIcon icon="newEnrichment" className={classes.mr6} />
              <span>{i18next.t("indued.newDueDiligence")}</span>
            </>
          }
        />
      )}
    </div>
  );
};

function ThirdpartyRisks({ risks, onRiskChange, indicators, transparency, thirdPartyId }) {
  const { classes } = useStyles();
  const [state, ] = useContext(Context);
  const [induedData, setInduedData] = useState();
  const [transparencyData, setTransparencyData] = useState();
  const [financialStrengthData, setFinancialStrengthData] = useState();
  const [tabIndex, setTabIndex] = React.useState(0);
  const { hasAnyOfPrivileges } = React.useContext(RoleBasedAccessControlContext);
  const up1900 = useMediaQuery(theme.breakpoints.up("1900"));

  useEffect(() => {
    setInduedData(risks?.indued);
    if (hasOption(state.accounts, state.account.id, "TRANSPARENCY")) {
      setTransparencyData(risks?.transparency);
    }
    if (hasOption(state.accounts, state.account.id, "FINANCIALSTRENGTH")) {
      setFinancialStrengthData(risks?.datablocks_financial_strength);
    }
  }, [risks, state.accounts, state.account]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const risk = urlParams.get('risk');
    if (risk === "indued") {
      setTabIndex(1);
    }
  }, []);

  const tabClassName = (index) =>
    classes.tab +
    " " +
    (!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE, PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_INDICATORS) ? classes.tabDisabled : tabIndex === index ? classes.selectedTab : classes.unselectedTab)

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

    // Récupération de l'évolution du score de santé financière au cours du temps (en se basant sur le monitoring du score)
    const [evolutionFinancialStrength, setEvolutionFinancialStrength] = useState([]);
    const getFinancialStrengthEvolution = useCallback(async () => {
      try {
        const reponse = await getEvolutionScoreDataBlocks(state.account.id, thirdPartyId, "financialStrength");
        const evolution = reponse.content?.evolutionScore;
        setEvolutionFinancialStrength(evolution ? evolution : []);
      } catch (error) {
        console.log(error);
      }
    }, [state.account.id, thirdPartyId]);

    useEffect(() => {
      if(financialStrengthData && thirdPartyId) {
        getFinancialStrengthEvolution()
      }
    }, [getFinancialStrengthEvolution, financialStrengthData, thirdPartyId])



  return (
    <>
      {/* Tab Titles */}
      <div className={classes.infoPanel}>
        <Box className={classes.tabsWrapper}>
          <div className={classes.lineOnTabs} />
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="dashboard tabs"
          >
            {indicators && (
              <Tab
                label={i18next.t("calculator.indicators")}
                {...a11yProps(0)}
                className={tabClassName(0)}
                style={{ width: "130px", fontSize: up1900 ? "16px" : "14px" }}
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_INDICATORS)}
              />
            )}
            {induedData && (
              <Tab
                label={i18next.t("indued.label")}
                {...a11yProps(1)}
                className={clsx(tabClassName(1), classes.dt)}
                style={{ width: "130px", fontSize: up1900 ? "16px" : "14px" }}
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE)}
              />
            )}

            {transparencyData && (
              <Tab
                label={i18next.t("transparency.label")}
                {...a11yProps(2)}
                className={clsx(tabClassName(2), classes.dt)}
                style={{ width: "130px", fontSize: up1900 ? "16px" : "14px" }}
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE)}
              />
            )}

            {financialStrengthData &&
            <Tab
                label={i18next.t("financialStrength.label")}
                {...a11yProps(3)}
                className={clsx(tabClassName(3), classes.dt)}
                style={{ width: "160px", fontSize: up1900 ? "16px" : "14px" }}
                disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE)}
              />
            }
          </Tabs>

          {tabIndex === 1 &&
            <ThirdpartyRisksActions
              indued={induedData}
              onInduedChange={onRiskChange}
            />
          }
        </Box>

        {/* Panels */}
        <div className={classes.panelWrapper}>

          {/* Indicateur */}
          {indicators && (
            <EATabPanel value={tabIndex} index={0} style={{ height: "100%" }}>
              {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_INDICATORS) &&
                <PanelIndicators indicators={indicators} />
              }

            </EATabPanel>
          )}
          {/* indueD */}
          {induedData && (
            <EATabPanel value={tabIndex} index={1} style={{ height: "100%" }}>
              {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE) &&
                <PanelIndued indued={induedData} />
              }
            </EATabPanel>
          )}

          {/* Transparency Data */}
          {transparencyData && (
            <EATabPanel value={tabIndex} index={2} style={{ height: "100%" }}>
              {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE) &&
                <PanelTransparency transparency={transparencyData} />
              }
            </EATabPanel>
          )}

          {/* Santé financière */}
          {financialStrengthData &&
            <EATabPanel value={tabIndex} index={3} style={{ height: "100%" }}>
              {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE) &&
                <>
                  <PanelFinancialStrength financialStrength={financialStrengthData} thirdPartyId={thirdPartyId}
                    evolutionScore={evolutionFinancialStrength} onRiskChange={onRiskChange}/>
                </>
              }
            </EATabPanel>
}
        </div>
      </div>
    </>
  );
}

export default ThirdpartyRisks;
