import React from "react";
import i18next from "i18next";
import _ from "lodash";
import jsep from "jsep";
import { v4 as uuidv4 } from 'uuid';

import {
  ActivityLevel,
  AttributeType,
  FieldDisplayVariant,
  FieldType,
  Operator,
  statusEngCode
} from "./Constants";
import { InduedAllScores } from "./InduedUtils";

export const CALCULATION_VARIABLE_NAME_MAX_LENGTH = 100;
export const CALCULATION_BRANCH_STRING_RESULT_MAX_LENGTH = 100;

export const CalculatorContext = React.createContext({
  selectedCalculation: null,
  activities: [],
  countries: [],
  thirdpartyAttributes: [],
  dossierAttributes: [],
  dossierTypes: [],
  documentTypes: [],
  calculatedAttributes: [],
  thirdPartyIndicators: [],
  sourceVariables: [],
  calculations: [],
  showInduedScores: false,
  showTransparencyScore: false,
  showFinancialStrengthScores: false,
  enableTransparencyScore: false,
});

export const CalculationDeclarationContext = React.createContext({
  addCallback: () => { },
  removeCallBack: () => { },
  isValidVariable: () => { },
});

export const getFormDefaultValues = (calculation) => {

  return {
    id: calculation?.id || "",
    creationDate: calculation?.creationDate || "",
    name: calculation?.name || "",
    nature: calculation?.nature || "",
    description: calculation?.description || "",
    enabled: calculation?.enabled || false,
    resultType: calculation?.resultType || "",

    // Variables
    variables: calculation?.variables?.map((variable) => {
      return getVariableDefaultValues(variable);
    }) ||
      [
        getVariableModel()
      ],

    // Branches
    branches: calculation?.branches?.map((branch) => {
      return getBranchDefaultValues(branch);
    }).sort((a, b) => {
      return (a.default && b.default) ? parseInt(a.id) - parseInt(b.id) : (a.default) ? -1 : 1;
    }) ||
      [
        getDefaultBranchModel(),
        getBranchModel(),
      ],

    user: calculation?.user || "",
  }
};

const getVariableDefaultValues = (variable) => {
  return {
    id: variable?.id,
    type: variable?.variableType || VariableType.DECLARED,
    name: variable?.name,
    targetField: variable?.targetField,
    calculationId: variable?.calculationId,
  };
};

const getBranchDefaultValues = (branch) => {
  return {
    id: branch?.id,
    default: branch?.default,
    resultValue: branch?.resultValue,
    resultColorCode: branch?.resultColorCode,
    flatCriteria: getBranchFlatCriteriaDefaultValues(branch),
    // connectiveOperator: branch?.connectiveOperator,
    // criteriaGroups: branch?.criteriaGroups?.map((group) => {
    //   return getCriteriaGroupDefaultValues(group);
    // }),
    formula: getBranchFormulaDefaultValue(branch)
  };
};

const getBranchFlatCriteriaDefaultValues = (branch) => {
  let flatCriteria = new Set();

  branch?.criteriaGroups?.forEach(group => {
    getGroupFlatCriteriaDefaultValues(group)?.forEach(criterion => flatCriteria.add(criterion));
  });

  return Array.from(flatCriteria);
};

const getGroupFlatCriteriaDefaultValues = (group) => {
  let flatCriteria = new Set();

  group?.criteria?.forEach(criterion => flatCriteria.add(getCriterionDefaultValues(criterion)));
  group?.childGroups?.forEach(group => {
    getGroupFlatCriteriaDefaultValues(group)?.forEach(criterion => flatCriteria.add(criterion));
  });

  return Array.from(flatCriteria);
};

/**Currently not used but keep the code*/
// const getCriteriaGroupDefaultValues = (group) => {
//   return {
//     id: group?.id,
//     connectiveOperator: group?.connectiveOperator,
//     criteria: group?.criteria?.map((criterion) => {
//       return getCriterionDefaultValues(criterion);
//     }),
//     childGroups: group?.childGroups?.map((group) => {
//       return getCriteriaGroupDefaultValues(group);
//     }),
//     parentGroupId: group?.parentGroupId,
//     branchId: group?.branchId,
//   };
// };

const getCriterionDefaultValues = (criterion) => {
  return {
    id: criterion?.id,
    variableId: criterion?.variableId,
    variableType: criterion?.variableType,
    variableName: criterion?.variableName,
    operator: criterion?.operator,
    values: criterion?.values,
    groupId: criterion?.groupId,
  };
};

const getBranchFormulaDefaultValue = (branch) => {
  const flatCriteria = getBranchFlatCriteriaDefaultValues(branch);

  return `${branch.criteriaGroups.map(group => getGroupFormulaDefaultValue(group, flatCriteria)).join(` ${branch.connectiveOperator} `)}`;
}

const getGroupFormulaDefaultValue = (group, flatCriteria) => {
  let combinedParts = [];

  const criteriaPart = group.criteria
    .map(criterion => {
      return _.findIndex(flatCriteria, (entry) => entry.id === criterion.id) + 1;
    })
    .join(` ${group.connectiveOperator} `);

  if (criteriaPart) {
    combinedParts.push(criteriaPart);
  }

  const childGroupsPart = group.childGroups
    .map(childGroup => {
      return getGroupFormulaDefaultValue(childGroup, flatCriteria);
    })
    .join(` ${group.connectiveOperator} `);

  if (childGroupsPart) {
    combinedParts.push(childGroupsPart);
  }

  if (!_.isEmpty(group.childGroups) || (_.size(group.criteria) > 1)) {
    return `(${combinedParts.join(` ${group.connectiveOperator} `)})`;
  } else {
    return `${combinedParts.join(` ${group.connectiveOperator} `)}`;
  }
};

export const CalculatorFormSteps = {
  INITIAL: "INITIAL",
  DECLARATION: "DECLARATION",
  FINAL: "FINAL",
};

export const CalculationResultType = {
  STRING: "STRING",
  NUMERIC: "NUMERIC",
  BOOLEAN: "BOOLEAN"
};

export const CalculationNature = {
  CALCULATED_ATTRIBUTE: "CALCULATED_ATTRIBUTE",
  INDICATOR: "INDICATOR",
  SOURCE_VARIABLE: "SOURCE_VARIABLE"
};

export const ConnectiveOperatorType = {
  AND: "AND",
  OR: "OR",
};

export const CalculationBranchFormulaNodeType = {
  CRITERION: "CRITERION",
  GROUP: "GROUP"
}

export const getCalculationNatureOptions = () => {
  return [
    {
      value: CalculationNature.CALCULATED_ATTRIBUTE,
      label: i18next.t(`calculator.dialog.create.natureChoice.${CalculationNature.CALCULATED_ATTRIBUTE}`)
    },
    {
      value: CalculationNature.INDICATOR,
      label: i18next.t(`calculator.dialog.create.natureChoice.${CalculationNature.INDICATOR}`)
    },
    {
      value: CalculationNature.SOURCE_VARIABLE,
      label: i18next.t(`calculator.dialog.create.natureChoice.${CalculationNature.SOURCE_VARIABLE}`)
    }
  ]
};

export const getCalculationResultTypeOptions = () => {
  return [
    {
      value: CalculationResultType.STRING,
      label: i18next.t(`calculator.dialog.create.resultTypeOption.${CalculationResultType.STRING}`)
    },
    {
      value: CalculationResultType.NUMERIC,
      label: i18next.t(`calculator.dialog.create.resultTypeOption.${CalculationResultType.NUMERIC}`)
    },
  ]
};

export const getDefaultBranchModel = () => {
  return {
    id: "",
    default: true,
    resultValue: "",
    resultColorCode: "",
    criteria: [],
  }
};

// Modèle de données d'une branche
export const getBranchModel = () => {
  let uuid = uuidv4();

  return {
    id: `tmp-${uuid}`,
    default: false,
    resultValue: "",
    resultColorCode: "",
    flatCriteria: [
      getCriterionModel()
    ],
    criteriaGroups: [
      getCriteriaGroupModel()
    ],
    formula: "",
  }
};

export const VariableType = {
  DECLARED: "DECLARED",
  CALCULATED: "CALCULATED",
};

export const getVariableModel = () => {
  let uuid = uuidv4();

  return {
    id: `tmp-${uuid}`,
    type: VariableType.DECLARED,
    name: "",
    targetField: "",
    calculationId: ""
  }
};

export const getCriteriaGroupModel = () => {
  let uuid = uuidv4();

  return {
    id: `tmp-${uuid}`,
    connectiveOperator: "",
    criteria: [
      getCriterionModel()
    ],
    childGroups: [],
    parentGroupId: "",
    branchId: "",
  }
};

export const getCriterionModel = () => {
  let uuid = uuidv4();

  return {
    id: `tmp-${uuid}`,
    variableId: "",
    variableType: "",
    variableName: "",
    operator: "",
    values: []
  }
};

export const TargetFieldCategory = {
  THIRD_PARTY: "THIRD_PARTY",
  DOSSIER: "DOSSIER",
  DOCUMENT: "DOCUMENT",
  SOURCE_VARIABLE: "SOURCE_VARIABLE"
};

export const TargetFieldNature = {
  ENTITY_FIELD: "ENTITY_FIELD",
  ATTRIBUTE: "ATTRIBUTE",
  INDICATOR: "INDICATOR",
  STATUS: "STATUS",
  SCORE: "SCORE"
};

export const getTargetFieldCategoryOptions = () => {
  return [
    {
      value: TargetFieldCategory.THIRD_PARTY,
      label: i18next.t(`calculator.dialog.create.variableTargetFieldCategory.${TargetFieldCategory.THIRD_PARTY}`)
    },
    {
      value: TargetFieldCategory.DOSSIER,
      label: i18next.t(`calculator.dialog.create.variableTargetFieldCategory.${TargetFieldCategory.DOSSIER}`)
    },
    {
      value: TargetFieldCategory.DOCUMENT,
      label: i18next.t(`calculator.dialog.create.variableTargetFieldCategory.${TargetFieldCategory.DOCUMENT}`)
    },
    {
      value: TargetFieldCategory.SOURCE_VARIABLE,
      label: i18next.t(`calculator.dialog.create.variableTargetFieldCategory.${TargetFieldCategory.SOURCE_VARIABLE}`)
    }
  ]
};

export const getTargetFieldNatureOptions = () => {
  return {
    THIRD_PARTY: [
      {
        value: TargetFieldNature.ENTITY_FIELD,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.THIRD_PARTY}.${TargetFieldNature.ENTITY_FIELD}`)
      },
      {
        value: TargetFieldNature.ATTRIBUTE,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.THIRD_PARTY}.${TargetFieldNature.ATTRIBUTE}`)
      },
      {
        value: TargetFieldNature.INDICATOR,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.THIRD_PARTY}.${TargetFieldNature.INDICATOR}`)
      },
    ],

    DOSSIER: [
      {
        value: TargetFieldNature.ENTITY_FIELD,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.DOSSIER}.${TargetFieldNature.ENTITY_FIELD}`)
      },
      {
        value: TargetFieldNature.ATTRIBUTE,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.DOSSIER}.${TargetFieldNature.ATTRIBUTE}`)
      },
      {
        value: TargetFieldNature.STATUS,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.DOSSIER}.${TargetFieldNature.STATUS}`)
      }
    ],

    DOCUMENT: [
      {
        value: TargetFieldNature.ENTITY_FIELD,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.DOCUMENT}.${TargetFieldNature.ENTITY_FIELD}`)
      },
      {
        value: TargetFieldNature.STATUS,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.DOCUMENT}.${TargetFieldNature.STATUS}`)
      },
      {
        value: TargetFieldNature.SCORE,
        label: i18next.t(`calculator.dialog.create.variableTargetFieldNature.${TargetFieldCategory.DOCUMENT}.${TargetFieldNature.SCORE}`)
      }
    ],

    SOURCE_VARIABLE: []
  }
};

export const getAvailableTargetFieldNatureOptions = (targetFieldCategory) => {
  const targetFieldCategoryOptions = getTargetFieldNatureOptions()[targetFieldCategory];

  return targetFieldCategoryOptions || [];
};

export const FREE_DOSSIER_TYPE_ID_VALUE = 'null';

export const DocumentTypePropertyType = {
  CHAINE: "CHAINE",
  MULTILIGNES: "MULTILIGNES",
  EMAIL: "EMAIL",
  TELEPHONE: "TELEPHONE",
  IBAN: "IBAN",
  MONTANT: "MONTANT",
  NOMBRE: "NOMBRE",
  DEVISE: "DEVISE",
  DATE: "DATE",
  RADIO_BUTTON: "RADIO_BUTTON",
  LIST_ITEM: "LIST_ITEM",
  CASE_A_COCHER: "CASE_A_COCHER",
  LISTE_CHOIX: "LISTE_CHOIX",

  //Not used
  SIGNATURE: "SIGNATURE",
  TEXTAREA: "TEXTAREA",
  QUESTION_TEXTAREA: "QUESTION_TEXTAREA",
}

export const getTargetFieldOptionsForThirdParty = (
  countries = [],
  activities = [],
  attributes = [],
  calculatedAttributes = [],
  indicators = [],
  financialStrengthScores = [],
  financialStrengthRatings = [],
  withInduedScores = false,
  withTransparencyScore = false,
  withFinancialStrengthScores = false,
  enableTransparencyScore = false,
) => {
  attributes = attributes.sort((a, b) => a.label.localeCompare(b.label));
  indicators = indicators.sort((a, b) => a.label.localeCompare(b.label));

  return [
    {
      value: 'name',
      label: 'Tiers',
      type: FieldType.TEXT,
      valueOptions: [],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'thirdpartyCode',
      label: 'Code tiers',
      type: FieldType.TEXT,
      valueOptions: [],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'etab_activity_sic_major',
      label: 'Activité SIC major',
      type: FieldType.ACTIVITY,
      valueOptions: activities.filter(activity => activity.level === ActivityLevel.SIC_MAJOR),
      fieldNature: TargetFieldNature.ENTITY_FIELD,
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_2',
      label: 'Activité SIC 2',
      type: FieldType.ACTIVITY,
      valueOptions: activities.filter(activity => activity.level === ActivityLevel.SIC_2_DIGITS),
      fieldNature: TargetFieldNature.ENTITY_FIELD,
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_3',
      label: 'Activité SIC 3',
      type: FieldType.ACTIVITY,
      valueOptions: activities.filter(activity => activity.level === ActivityLevel.SIC_3_DIGITS),
      fieldNature: TargetFieldNature.ENTITY_FIELD,
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_4',
      label: 'Activité SIC 4',
      type: FieldType.ACTIVITY,
      valueOptions: activities.filter(activity => activity.level === ActivityLevel.SIC_4_DIGITS),
      fieldNature: TargetFieldNature.ENTITY_FIELD,
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_6',
      label: 'Activité SIC 6',
      type: FieldType.ACTIVITY,
      valueOptions: activities.filter(activity => activity.level === ActivityLevel.SIC_6_DIGITS),
      fieldNature: TargetFieldNature.ENTITY_FIELD,
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_8',
      label: 'Activité SIC 8',
      type: FieldType.ACTIVITY,
      valueOptions: activities.filter(activity => activity.level === ActivityLevel.SIC_8_DIGITS),
      fieldNature: TargetFieldNature.ENTITY_FIELD,
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'headquarter',
      label: 'Siège',
      type: FieldType.BOOLEAN,
      valueOptions: [
        {
          value: 'true',
          label: i18next.t("headquarter")
        },
        {
          value: 'false',
          label: i18next.t("secondaryEstablishment")
        },
      ],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'addressPostCode',
      label: 'Code postal',
      type: FieldType.TEXT,
      valueOptions: [],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'addressCity',
      label: 'Ville',
      type: FieldType.TEXT,
      valueOptions: [],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'addressCountryCode',
      label: 'Pays',
      type: FieldType.TEXT,
      valueOptions: countries,
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    ...attributes?.filter((attr) => attr.type !== "string").map((attr) => {
      return {
        value: `${attr.id}`,
        label: attr.label,
        type: FieldType.ATTRIBUTE,
        subType: attr.type,
        dt: true,
        /*
          A boolean attribute doesn't have predefined value options on the backend side,
          but it's a primitive type that has limited options (true and false), so process
          it differently
        */
        valueOptions: (AttributeType.BOOLEAN === attr.type) ? [
          {
            value: "true",
            label: i18next.t("yes")
          },
          {
            value: "false",
            label: i18next.t("no")
          },
        ] : attr.values?.map((valueEntry) => {
          return {
            value: valueEntry.value,
            label: valueEntry.value
          }
        }) || [],
        fieldNature: TargetFieldNature.ATTRIBUTE,
      };
    }),
    ...calculatedAttributes?.map((calculatedAttr) => {
      return {
        value: `${calculatedAttr.value}`,
        label: calculatedAttr.label,
        type: FieldType.CALCULATED_ATTRIBUTE,
        subType: calculatedAttr.type,
        valueOptions: [],
        fieldNature: TargetFieldNature.ATTRIBUTE,
        dt: true,
      };
    }),
    ...indicators?.map((ind) => {
      return {
        value: `${ind.value}`,
        label: ind.label,
        type: FieldType.INDICATOR,
        subType: ind.type,
        valueOptions: [],
        fieldNature: TargetFieldNature.INDICATOR,
        dt: true,
      };
    }),
    ...(withInduedScores ?
      InduedAllScores.map(score => {
        return {
          value: score,
          label: i18next.t(`indued.scoresFull.${score}`),
          type: FieldType.RISK,
          valueOptions: [
            {
              value: "0",
              label: i18next.t("indued.severity.unknown")
            },
            {
              value: "1",
              label: i18next.t("indued.severity.low")
            },
            {
              value: "2",
              label: i18next.t("indued.severity.medium")
            },
            {
              value: "3",
              label: i18next.t("indued.severity.high")
            },
          ],
          fieldNature: TargetFieldNature.INDICATOR,
        };
      })
      :
      []
    ),
    ...(withTransparencyScore) ?
      [
        {
          value: "transparencyScore",
          label: i18next.t("transparency.criterionLabel"),
          type: FieldType.NUMERIC,
          valueOptions: [],
          fieldNature: TargetFieldNature.INDICATOR,
          disabled: !enableTransparencyScore
        }
      ]
      :
      [],

    ...(withFinancialStrengthScores) ?
      [
        {
          value: "financial_strength_score",
          label: i18next.t("financialStrength.labelRuleScore"),
          type: FieldType.RISK,
          valueOptions: financialStrengthScores,
          fieldNature: TargetFieldNature.INDICATOR,
        },
        {
          value: "financial_strength_rating",
          label: i18next.t("financialStrength.labelRuleRating"),
          type: FieldType.RISK,
          valueOptions: financialStrengthRatings,
          fieldNature: TargetFieldNature.INDICATOR,
        }
      ]
      :
      []
  ];
};

export const getTargetFieldOptionsForDossier = (attributes = []) => {
  attributes = attributes.sort((a, b) => a.label.localeCompare(b.label));

  return [
    {
      value: 'reference',
      label: 'Référence',
      type: FieldType.TEXT,
      valueOptions: [],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'startDate',
      label: 'Date d\'ajout',
      type: FieldType.DATE,
      valueOptions: [],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'endDate',
      label: 'Date de fin',
      type: FieldType.DATE,
      valueOptions: [],
      fieldNature: TargetFieldNature.ENTITY_FIELD,
    },
    {
      value: 'status',
      label: 'Statut 2.0',
      type: FieldType.TEXT,
      valueOptions: [
        {
          value: "IN_PROGRESS",
          label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.IN_PROGRESS}`)
        },
        {
          value: "ACTION_REQUIRED",
          label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.ACTION_REQUIRED}`)
        },
        {
          value: "NOT_VALID",
          label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.NOT_VALID}`)
        },
        {
          value: "VALID",
          label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.VALID}`)
        },
        {
          value: "MISSING",
          label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.MISSING}`)
        }
      ],
      fieldNature: TargetFieldNature.STATUS,
    },
    ...attributes?.map((attr) => {
      return {
        value: `${attr.id}`,
        label: attr.label,
        type: FieldType.ATTRIBUTE,
        subType: attr.type,
        dt: true,
        /*
          A boolean attribute doesn't have predefined value options on the backend side,
          but it's a primitive type that has limited options (true and false), so process
          it differently
        */
        valueOptions: (AttributeType.BOOLEAN === attr.type) ? [
          {
            value: "true",
            label: i18next.t("yes")
          },
          {
            value: "false",
            label: i18next.t("no")
          },
        ] : attr.values?.map((valueEntry) => {
          return {
            value: valueEntry.value,
            label: valueEntry.value
          }
        }) || [],
        fieldNature: TargetFieldNature.ATTRIBUTE,
      };
    }),
  ];
};

export const getTargetFieldOptionsForDocument = (properties = []) => {
  return [
    {
      value: 'status',
      label: 'Statut 2.0',
      type: FieldType.TEXT,
      valueOptions: [
        {
          value: "IN_PROGRESS",
          label: i18next.t(`viewer.status.statusLabel.${statusEngCode.IN_PROGRESS}`)
        },
        {
          value: "ACTION_REQUIRED",
          label: i18next.t(`viewer.status.statusLabel.${statusEngCode.ACTION_REQUIRED}`)
        },
        {
          value: "BELOW_EXPECTATION",
          label: i18next.t(`viewer.status.statusLabel.${statusEngCode.BELOW_EXPECTATION}`)
        },
        {
          value: "NOT_VALID",
          label: i18next.t(`viewer.status.statusLabel.${statusEngCode.NOT_VALID}`)
        },
        {
          value: "VALID",
          label: i18next.t(`viewer.status.statusLabel.${statusEngCode.VALID}`)
        },
        {
          value: "MISSING",
          label: i18next.t(`viewer.status.statusLabel.${statusEngCode.MISSING}`)
        }
      ],
      fieldNature: TargetFieldNature.STATUS,
    },
    {
      value: 'sisIdScore',
      label: 'Scores Sis ID',
      type: FieldType.TEXT,
      valueOptions: [
        {
          value: "NO",
          label: i18next.t("sisid.classification.NO")
        },
        {
          value: "LOW",
          label: i18next.t("sisid.classification.LOW")
        },
        {
          value: "MEDIUM",
          label: i18next.t("sisid.classification.MEDIUM")
        },
        {
          value: "HIGH",
          label: i18next.t("sisid.classification.HIGH")
        },
      ],
      fieldNature: TargetFieldNature.SCORE,
    },
    {
      value: 'trustPairScore',
      label: 'Scores Trustpair',
      type: FieldType.TEXT,
      valueOptions: [
        {
          value: "favorable",
          label: i18next.t("favorable")
        },
        {
          value: "pending",
          label: i18next.t("pending")
        },
        {
          value: "unfavorable",
          label: i18next.t("unfavorable")
        },
        {
          value: "anomaly",
          label: i18next.t("anomaly")
        },
        {
          value: "unevaluated",
          label: i18next.t("unevaluated")
        },
      ],
      fieldNature: TargetFieldNature.SCORE,
    },
    ...properties?.filter((property) => {
      // Supporting a limited list of field types
      return [
        DocumentTypePropertyType.CHAINE,
        DocumentTypePropertyType.MULTILIGNES,
        DocumentTypePropertyType.EMAIL,
        DocumentTypePropertyType.TELEPHONE,
        DocumentTypePropertyType.IBAN,
        DocumentTypePropertyType.MONTANT,
        DocumentTypePropertyType.NOMBRE,
        DocumentTypePropertyType.DEVISE,
        DocumentTypePropertyType.DATE,
        DocumentTypePropertyType.RADIO_BUTTON,
        DocumentTypePropertyType.LIST_ITEM,
        DocumentTypePropertyType.CASE_A_COCHER,
        //TODO: implement this
        //DocumentTypePropertyType.LISTE_CHOIX,

        //Not managed
        //DocumentTypePropertyType.QUESTION_TEXTAREA,
        //DocumentTypePropertyType.SIGNATURE,
        //DocumentTypePropertyType.TEXTAREA
      ].includes(property.type);
    }).map((property) => {
      return {
        value: `${property?.id}`,
        label: `${property?.label}`,
        type: FieldType.FORM_FIELD,
        subType: property?.type,
        valueOptions: property?.valueOptions || [],
        fieldNature: TargetFieldNature.ENTITY_FIELD,
      };
    }),
  ];
};

export const getTargetFieldOptionsForSourceVariable = (sourceVariables = []) => {
  return [
    ...sourceVariables?.map((sourceVariable) => {
      return {
        value: `${sourceVariable.value}`,
        label: sourceVariable.label,
        type: FieldType.SOURCE_VARIABLE,
        subType: sourceVariable.type,
        valueOptions: [],
        fieldNature: ``,
        dt: true,
      };
    }),
  ];
};

export const getCriterionOperatorOptions = () => {
  return {
    TEXT: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    NUMERIC: [
      {
        value: Operator.EQUAL,
        label: "="
      },
      {
        value: Operator.NOT_EQUAL,
        label: "!="
      },
      {
        value: Operator.GREATER_THAN,
        label: ">"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: ">="
      },
      {
        value: Operator.LESS_THAN,
        label: "<"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "<="
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      },
    ],

    DATE: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Postérieure",
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Egal ou postérieure",
      },
      {
        value: Operator.LESS_THAN,
        label: "Antérieur",
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Egal ou antérieure",
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
    ],

    BOOLEAN: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      },
    ],

    CUSTOM: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      },
    ],

    ATTRIBUTE: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    RISK: [
      {
        value: Operator.EQUAL, // "IS"
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL, //"NOT"
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET, //"IS_ANY_OF"
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET, //"IS_NOT_ANY_OF"
      //     label: "Ne fait pas partie de"
      // }
      {
        value: Operator.IS_NULL, //"IS_EMPTY"
        label: "Est vide",
      },
      // {
      //   value: Operator.NOT_NULL, //"IS_NOT_EMPTY"
      //   label: "N'est pas vide",
      // },
    ],

    CALCULATED_ATTRIBUTE: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    INDICATOR: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    SOURCE_VARIABLE: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    FORM_FIELD: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    ACTIVITY: [
      {
        value: Operator.EQUAL,
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL,
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de"
      }
    ]

  };
};

export const getAvailableOperatorOptions = (type, subType) => {
  let operatorOptions = getCriterionOperatorOptions()[type];

  switch (type) {
    case FieldType.ATTRIBUTE:
      switch (subType) {
        case AttributeType.STRING:
        case AttributeType.LIST_UNIQUE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
          ].includes(entry.value));

          break;

        case AttributeType.LIST_MULTIPLE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value)).map((entry) => {
            switch (entry.value) {
              //This is ugly but let's change the label of Operator.CONTAINS operator here
              case Operator.CONTAINS:
                return {
                  value: entry.value,
                  label: 'Fait partie de',
                  // label: 'Contient une valeur parmi',
                }

              default:
                return entry;
            }
          });

          break;

        case AttributeType.DECIMAL:
        case AttributeType.INTEGER:
        case AttributeType.PERCENTAGE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
          ].includes(entry.value));

          break;

        case AttributeType.BOOLEAN:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value));

          break;

        case AttributeType.DATE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.BETWEEN, //TODO: Add these 3 later if asked for
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value)).map((entry) => formatDateOperatorLabel(entry));

          break;

        default:
          break;
      }
      break;

    case FieldType.CALCULATED_ATTRIBUTE:
    case FieldType.INDICATOR:
    case FieldType.SOURCE_VARIABLE:
      switch (subType) {
        case CalculationResultType.STRING:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
          ].includes(entry.value));

          break;

        case CalculationResultType.NUMERIC:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
          ].includes(entry.value));
          break;

        case CalculationResultType.BOOLEAN:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value));

          break;

        default:
          break;
      }
      break;

    case FieldType.FORM_FIELD:
      switch (subType) {
        case DocumentTypePropertyType.CHAINE:
        case DocumentTypePropertyType.MULTILIGNES:
        case DocumentTypePropertyType.EMAIL:
        case DocumentTypePropertyType.TELEPHONE:
        case DocumentTypePropertyType.IBAN:
        case DocumentTypePropertyType.RADIO_BUTTON:
        case DocumentTypePropertyType.LIST_ITEM:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
          ].includes(entry.value));

          break;

        case DocumentTypePropertyType.MONTANT:
        case DocumentTypePropertyType.NOMBRE:
        case DocumentTypePropertyType.DEVISE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
          ].includes(entry.value));
          break;

        case DocumentTypePropertyType.DATE:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.CONTAINS,
            Operator.NOT_CONTAINS,
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.BETWEEN, //TODO: Add these 3 later if asked for
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value)).map((entry) => formatDateOperatorLabel(entry));

          break;

        case DocumentTypePropertyType.CASE_A_COCHER:
          operatorOptions = operatorOptions.filter((entry) => ![
            Operator.STARTS_WITH,
            Operator.NOT_STARTS_WITH,
            Operator.ENDS_WITH,
            Operator.NOT_ENDS_WITH,
            Operator.GREATER_THAN,
            Operator.GREATER_OR_EQUAL,
            Operator.GREATER_OR_EQUAL,
            Operator.LESS_THAN,
            Operator.LESS_OR_EQUAL,
            Operator.BETWEEN,
            Operator.IN_SET,
            Operator.NOT_IN_SET,
          ].includes(entry.value)).map((entry) => {
            switch (entry.value) {
              //This is ugly but let's change the label of Operator.CONTAINS operator here
              case Operator.CONTAINS:
                return {
                  value: entry.value,
                  label: 'Fait partie de', // ==> 'Contient une valeur parmi'
                }

              default:
                return entry;
            }
          });
          break;

        case DocumentTypePropertyType.LISTE_CHOIX:
          operatorOptions = [];
          break;

        default:
          break;
      }
      break;

    default:
      break;
  }

  return operatorOptions;
};

const formatDateOperatorLabel = (attributeOp) => {
  let operator = {
    value: attributeOp.value
  }
  switch (attributeOp.value) {
    case Operator.EQUAL:
      operator.label = "Est";
      break;
    case Operator.NOT_EQUAL:
      operator.label = "N'est pas";
      break;
    case Operator.GREATER_THAN:
      operator.label = "Postérieure";
      break;
    case Operator.GREATER_OR_EQUAL:
      operator.label = "Egal ou postérieure";
      break;
    case Operator.LESS_THAN:
      operator.label = "Antérieur";
      break;
    case Operator.LESS_OR_EQUAL:
      operator.label = "Egal ou antérieure";
      break;
    case Operator.IS_NULL:
      operator.label = "Est vide";
      break;
    case Operator.NOT_NULL:
      operator.label = "N'est pas vide";
      break;
    default:
      operator.label = attributeOp.label;
      break;
  }

  return operator;
}

export const parseTargetFieldCategoryFromVariable = (variable) => {
  return variable?.targetField?.target_field_category;
}

export const parseDossierTypeIdFromVariable = (variable) => {
  const targetFieldCategory = variable?.targetField?.target_field_category;
  let dossierTypeId = undefined;

  switch (targetFieldCategory) {
    case TargetFieldCategory.DOSSIER:
      dossierTypeId = variable?.targetField?.target_field_data?.data_for_dossier?.dossier_type_id;
      break;

    default:
      break;
  }

  return dossierTypeId;
}

export const parseDocumentTypeIdFromVariable = (variable) => {
  const targetFieldCategory = variable?.targetField?.target_field_category;
  let documentTypeId = undefined;

  switch (targetFieldCategory) {
    case TargetFieldCategory.DOCUMENT:
      documentTypeId = variable?.targetField?.target_field_data?.data_for_document?.document_type_id;
      break;

    default:
      break;
  }

  return documentTypeId;
}

export const parseTargetFieldTypeFromVariable = (variable) => {
  const targetFieldCategory = variable?.targetField?.target_field_category;
  let targetFieldType = undefined;

  switch (targetFieldCategory) {
    case TargetFieldCategory.THIRD_PARTY:
      targetFieldType = variable?.targetField?.target_field_data?.data_for_third_party?.field_type;
      break;

    case TargetFieldCategory.DOSSIER:
      targetFieldType = variable?.targetField?.target_field_data?.data_for_dossier?.field_type;
      break;

    case TargetFieldCategory.DOCUMENT:
      targetFieldType = variable?.targetField?.target_field_data?.data_for_document?.field_type;
      break;

    case TargetFieldCategory.SOURCE_VARIABLE:
      targetFieldType = variable?.targetField?.target_field_data?.data_for_source_variable?.field_type;
      break;

    default:
      break;
  }

  return targetFieldType;
}

export const parseTargetFieldFromVariable = (variable) => {
  const targetFieldCategory = variable?.targetField?.target_field_category;
  let targetField = undefined;

  switch (targetFieldCategory) {
    case TargetFieldCategory.THIRD_PARTY:
      targetField = variable?.targetField?.target_field_data?.data_for_third_party?.field;
      break;

    case TargetFieldCategory.DOSSIER:
      targetField = variable?.targetField?.target_field_data?.data_for_dossier?.field;
      break;

    case TargetFieldCategory.DOCUMENT:
      targetField = variable?.targetField?.target_field_data?.data_for_document?.field;
      break;

    case TargetFieldCategory.SOURCE_VARIABLE:
      targetField = variable?.targetField?.target_field_data?.data_for_source_variable?.field;
      break;

    default:
      break;
  }

  return targetField;
}

export const isValidBranch = (branch) => {
  let bValid = false;

  if (branch?.default) {
    bValid = branch?.resultValue !== "";
  } else {
    bValid = (branch?.resultValue !== "") &&
      !_.isEmpty(branch?.flatCriteria) &&
      _.every(branch?.flatCriteria, isValidCriterion) &&
      checkExpression(branch?.formula, branch?.flatCriteria) === ``;
  }

  return bValid;
};

export const isValidCriteriaGroup = (group) => {
  return (!_.isEmpty(group?.criteria) && _.every(group?.criteria, isValidCriterion)) ||
    (!_.isEmpty(group?.childGroups) && _.every(group?.criteriaGroups, isValidCriteriaGroup));
};

export const isValidCriterion = (criterion) => {
  return criterion &&
    (criterion.variableName !== "") &&
    (criterion.operator !== "") &&
    ([Operator.IS_NULL, Operator.NOT_NULL].includes(criterion.operator) || !_.isEmpty(criterion.values));
};

export const cleanFormData = (data) => {
  let cleanData = JSON.parse(JSON.stringify(data));

  cleanData.variables.forEach(variable => cleanVariableData(variable));
  cleanData.branches?.forEach(branch => cleanBranchData(branch));

  return cleanData;
}

export const cleanVariableData = (variable) => {
  if (`${variable.id}`.indexOf("tmp") !== -1) {
    variable.id = null;
  }
}

export const cleanBranchData = (branch) => {
  if (`${branch.id}`.indexOf("tmp") !== -1) {
    branch.id = null;
  }

  branch.connectiveOperator = ConnectiveOperatorType.AND;

  if (branch?.default === false) {
    const data = parseExpression(branch.formula, branch.flatCriteria);
    branch.criteriaGroups = [data];
    branch.flatCriteria = [];
  } else {
    branch.criteriaGroups = [];
    branch.flatCriteria = [];
  }

  branch.criteriaGroups?.forEach(group => cleanCriteriaGroupData(group));
}

export const cleanCriteriaGroupData = (group) => {
  if (`${group.id}`.indexOf("tmp") !== -1) {
    group.id = null;
  }

  group.criteria?.forEach(criterion => cleanCriterionData(criterion));
  group.childGroups?.forEach(childGroup => cleanCriteriaGroupData(childGroup));
}

export const cleanCriterionData = (criterion) => {
  // if (`${criterion.id}`.indexOf("tmp") !== -1) {
  criterion.id = null;
  criterion.groupId = null;
  // }

  if (`${criterion.variableId}`.indexOf("tmp") !== -1) {
    criterion.variableId = null;
  }
}

export const parseExpression = (expr, criteria) => {
  jsep.addBinaryOp(ConnectiveOperatorType.OR, 1);
  jsep.addBinaryOp(ConnectiveOperatorType.OR.toLowerCase(), 1);
  jsep.addBinaryOp(ConnectiveOperatorType.AND, 2);
  jsep.addBinaryOp(ConnectiveOperatorType.AND.toLowerCase(), 2);

  const tree = jsep(expr);
  return parseTree(tree, criteria);
}

export const parseTree = (tree, criteria) => {
  switch (getNodeType(tree)) {
    case CalculationBranchFormulaNodeType.CRITERION:
      return {
        id: null,
        connectiveOperator: ConnectiveOperatorType.AND.toUpperCase(),
        criteria: [{
          ...criteria[tree.value - 1],
          id: null,
          groupId: null,
        }],
        childGroups: [],
        parentGroupId: null,
        branchId: null,
      };

    case CalculationBranchFormulaNodeType.GROUP:
      let group = {
        id: null,
        connectiveOperator: tree.operator.toUpperCase(),
        criteria: [],
        childGroups: [],
        parentGroupId: null,
        branchId: null,
      };

      const left = parseTree(tree.left, criteria);
      const right = parseTree(tree.right, criteria);

      if ((_.size(left?.criteria) === 1) && (_.isEmpty(left.childGroups))) {
        group.criteria.push(...left?.criteria);
      } else if ((_.size(left?.childGroups) === 1) && (_.isEmpty(left.criteria))) {
        group.childGroups.push(...left?.childGroups);
      } else {
        group.childGroups.push(left);
      }

      if ((_.size(right?.criteria) === 1) && (_.isEmpty(right.childGroups))) {
        group.criteria.push(...right?.criteria);
      } else if ((_.size(right?.childGroups) === 1) && (_.isEmpty(right.criteria))) {
        group.childGroups.push(...right?.childGroups);
      } else {
        group.childGroups.push(right);
      }

      return group;

    default:
      throw new Error("Unsupported node type");
  }
}

const getNodeType = (node) => {
  switch (node.type) {
    case 'Literal':
      return CalculationBranchFormulaNodeType.CRITERION;

    case 'BinaryExpression':
      return CalculationBranchFormulaNodeType.GROUP;

    case 'Identifier':
    case 'CallExpression':
    case 'UnaryExpression':
    case 'Compound':
    default:
      throw new Error("Unsupported node type");
  }
}

export const checkExpression = (expr, criteria) => {
  let message = ``;

  jsep.addBinaryOp(ConnectiveOperatorType.OR, 1);
  jsep.addBinaryOp(ConnectiveOperatorType.OR.toLowerCase(), 1);
  jsep.addBinaryOp(ConnectiveOperatorType.AND, 2);
  jsep.addBinaryOp(ConnectiveOperatorType.AND.toLowerCase(), 2);

  try {
    const involvedLiterals = exctractTreeLiterals(jsep(expr));

    if (!_.isEmpty(criteria)) {
      if (_.isEmpty(involvedLiterals)) {
        message = `missingLiteralInFormula`;
      } else {
        const expectedLiterals = _.range(1, _.size(criteria) + 1);

        if (_.size(_.difference(involvedLiterals, expectedLiterals)) > 0) {
          message = `unexpectedLiteralInFormula`;
        } else if (_.size(_.difference(expectedLiterals, involvedLiterals)) > 0) {
          message = `missingLiteralInFormula`;
        } else if (!_.isEqual(expectedLiterals, involvedLiterals)) {
          return `wrongLiteralsOrderInFormula`;
        }
      }
    } else if (!_.isEmpty(involvedLiterals)) {
      message = `unexpectedLiteralInFormula`;
    }
  } catch (error) {
    const errorMessage = error?.message;

    if (errorMessage !== undefined && errorMessage !== "") {
      if (errorMessage.startsWith("Unclosed")) {
        message = `unclosedParenthese`;
      } else if (errorMessage.startsWith("Unexpected")) {
        message = `unexpectedParenthese`;
      } else if (errorMessage.startsWith("Unsupported")) {
        message = `unsupportedOperator`;
      } else if (errorMessage.startsWith("Expected")) {
        message = `missingLiteralInFormula`;
      }
    } else {
      message = `badFormula`;
    }
  }

  return message;
}

export const checkTree = (tree) => {
  let bAccept = false;

  switch (tree.type) {
    case 'Literal':
      bAccept = true;
      break;

    case 'BinaryExpression':
      if ([
        ConnectiveOperatorType.AND,
        ConnectiveOperatorType.AND.toLocaleLowerCase(),
        ConnectiveOperatorType.OR,
        ConnectiveOperatorType.OR.toLocaleLowerCase(),
      ].includes(tree.operator)) {
        bAccept = checkTree(tree.left) && checkTree(tree.right);
      } else {
        bAccept = false;
      }
      break;

    case 'Identifier':
    case 'CallExpression':
    case 'UnaryExpression':
    case 'Compound':
    default:
      bAccept = false;
      break;
  }

  return bAccept;
}


export const exctractTreeLiterals = (tree) => {
  let literals = [];

  switch (tree.type) {
    case 'Literal':
      literals.push(tree.value);
      break;

    case 'BinaryExpression':
      if (![
        ConnectiveOperatorType.AND,
        ConnectiveOperatorType.AND.toLocaleLowerCase(),
        ConnectiveOperatorType.OR,
        ConnectiveOperatorType.OR.toLocaleLowerCase(),
      ].includes(tree.operator)) {
        throw new Error("Unsupported node type");
      }
      exctractTreeLiterals(tree.left)?.forEach(literal => literals.push(literal));
      exctractTreeLiterals(tree.right)?.forEach(literal => literals.push(literal));
      break;

    case 'Identifier':
    case 'CallExpression':
    case 'UnaryExpression':
    case 'Compound':
    default:
      throw new Error("Unsupported node type");
  }

  return literals;
}

export const getFlatCriteriaFromBranch = (branch) => {
  let flatCriteria = new Set();

  branch?.criteriaGroups?.forEach(group => {
    getFlatCriteriaFromCriteriaGroup(group)?.forEach(criterion => flatCriteria.add(criterion));
  });

  return Array.from(flatCriteria);
}

export const getFlatCriteriaFromCriteriaGroup = (group) => {
  let flatCriteria = new Set();

  group?.criteria?.forEach(criterion => flatCriteria.add(criterion));
  group?.childGroups?.forEach(group => {
    getFlatCriteriaFromCriteriaGroup(group)?.forEach(criterion => flatCriteria.add(criterion));
  });

  return Array.from(flatCriteria);
}

export const isCalculationReferencedByTargetCalculation = (calculation, targetCalculation, otherCalculations) => {
  let bReferenced = false;

  if (calculation && targetCalculation) {
    if (calculation.id === targetCalculation.id) {
      bReferenced = true;
    } else {
      for (const variable of targetCalculation.variables) {
        let bTargetted = false;
        const targetFieldType = parseTargetFieldTypeFromVariable(variable);
        const targetField = parseTargetFieldFromVariable(variable);

        if ((`${targetFieldType}` === `${calculation.nature}`) && (parseInt(targetField) === calculation.id)) {
          bTargetted = true;
        } else if ([
          CalculationNature.CALCULATED_ATTRIBUTE,
          CalculationNature.INDICATOR,
          CalculationNature.SOURCE_VARIABLE
        ].includes(targetFieldType)) {
          if (!_.isEmpty(otherCalculations)) {
            const newTargetCalculation = _.find(otherCalculations, { id: parseInt(targetField) });

            if (newTargetCalculation) {
              const newOtherCalculations = otherCalculations?.filter(entry => (entry.id !== calculation?.id) && (entry.id !== parseInt(targetField)));

              bTargetted = isCalculationReferencedByTargetCalculation(calculation, newTargetCalculation, newOtherCalculations);
            }
          }
        }

        if (bTargetted) {
          bReferenced = true;
          break;
        }
      }
    }
  }

  return bReferenced;
};

export const getGridColumnTypeForCalculationResult = (resultType) => {
  switch (resultType) {
    case CalculationResultType.STRING:
      return "string";

    case CalculationResultType.NUMERIC:
      return "string";

    case CalculationResultType.BOOLEAN:
      return "singleSelect";

    default:
      return "string";
  }
}

export const buildGridColumnsForCalculations = (rows) => {
  const result = new Map();

  rows.flat().forEach(obj => {
    const { calculationId, name, resultType, resultValue } = obj;
    const transformedResultValue = Array.isArray(resultValue) ? resultValue : (resultValue !== null ? [resultValue] : []);

    if (calculationId) {
      if (!result.has(calculationId)) {
        result.set(calculationId, { calculationId, name, resultType, resultValue: new Set(transformedResultValue) });
      } else {
        const entry = result.get(calculationId);
        new Set(transformedResultValue).forEach(value => entry.resultValue.add(value));
      }
    }
  });

  const columns = Array.from(result.values())
    .map(({ calculationId, name, resultType, resultValue }) => ({
      calculationId: calculationId,
      label: name,
      type: resultType,
      gridColumnType: getGridColumnTypeForCalculationResult(resultType),
      values: Array.from(resultValue).sort()
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return columns;
}

/**
 * Return formated string for calculated attributes or indicator
 * @param item (indicator or calculated attribute)
 * @returns {string|*}
 */
export const formatCalculationResult = (item) => {
  switch (item.resultType) {
    case CalculationResultType.STRING:
      return item.resultValue;

    case CalculationResultType.NUMERIC:
      return item.resultValue;

    case CalculationResultType.BOOLEAN:
      return item.resultValue === 'true' ? true : false;

    default:
      return;
  }
}