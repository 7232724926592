import React, { useState, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { ERROR, WARNING } from "../../services/common/Constants";
import i18next from "i18next";
import useStyles from "../../style/js-style/containers/administration/rules/RuleFormsStyle";
import EAAlert from "../EAAlert";

const RuleActivationErrors = ({
  actionsListEmpty,
  noDossierSelected,
  hasEmptyRefs,
  multipleSameActionNotAllowed,
  onActionsListEmptyClose,
  onNoDossierSelectedClose,
  onEmptyRefsClose,
  onMultipleSameActionNotAllowedClose
}) => {
  const { classes } = useStyles();
  const [noCriteriaSelected, setNoCriteriaSelected] = useState(false);

  const criteria = useWatch({ name: 'criteria' });

  useEffect(() => {
    const hasAtLeastACriteria = !!criteria.find(x => x.field !== "")
    if (hasAtLeastACriteria) {
      setNoCriteriaSelected(false)
    } else {
      setNoCriteriaSelected(true)
    }
  }, [criteria])

  return (
    <>
      {noCriteriaSelected && (
        <EAAlert severity={WARNING} onClose={() => setNoCriteriaSelected(false)}>
          <div className={classes.finalizeAlertBloc + " " + classes.finalizeWarningColor}>
            <div>{i18next.t("rules.finalization.warningText1")}</div>
            <div className={classes.finalizeAlertWarning}>{i18next.t("rules.finalization.warningText2")}</div>
          </div>
        </EAAlert>
      )}
      {actionsListEmpty && (
        <EAAlert severity={ERROR} onClose={() => onActionsListEmptyClose(false)}>
          <div className={classes.finalizeAlertBloc + " " + classes.finalizeErrorColor}>
            <div>{i18next.t("rules.finalization.actionsListEmpty")}</div>
          </div>
        </EAAlert>
      )}
      {noDossierSelected && (
        <EAAlert severity={ERROR} onClose={() => onNoDossierSelectedClose(false)}>
          <div className={classes.finalizeAlertBloc + " " + classes.finalizeErrorColor}>
            <div>{i18next.t("rules.finalization.noDossierSelected")}</div>
          </div>
        </EAAlert>
      )}
      {hasEmptyRefs && (
        <EAAlert severity={ERROR} onClose={() => onEmptyRefsClose(false)}>
          <div className={classes.finalizeAlertBloc + " " + classes.finalizeErrorColor}>
            <div>{i18next.t("rules.finalization.hasEmptyRefs")}</div>
          </div>
        </EAAlert>
      )}
      {multipleSameActionNotAllowed && (
        <EAAlert severity={ERROR} onClose={() => onMultipleSameActionNotAllowedClose(false)}>
          <div className={classes.finalizeAlertBloc + " " + classes.finalizeErrorColor}>
            <div>{i18next.t("rules.finalization.multipleSameActionNotAllowed")}</div>
          </div>
        </EAAlert>
      )}
    </>
  )

}

export default RuleActivationErrors