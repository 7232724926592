import {
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  Divider, Box, Tabs, Tab
} from "@mui/material";
import useStyles from "../../../style/js-style/containers/users/user-profile/UserProfileStyle";
import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  ERROR,
  REGEXP_EMAIL,
  REGEXP_NAME,
  SUCCESS,
  preferredCountries,
} from "../../../services/common/Constants";
import { isEmpty } from "../../../services/common/Utils";
import {
  getUserByAccountAndId,
  updateAccountUser,
} from "../../../services/edge/UserService";
import { Context } from "../../../states/Store";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import UserChangePasswordDialog from "./UserChangePasswordDialog";
import UserUnsubscribeDialog from "./UserUnsubscribeDialog";
import MenuAppBar from "../../../components/MenuAppBar";
import EAIcon from "../../../components/common/EAIcon";
import EAButton from "../../../components/common/EAButton";
import { theme } from "../../../style/globalStyle";
import _ from "lodash";
import {RoleBasedAccessControlContext} from "services/common/RolesUtils";
import clsx from "clsx";
import UserNotifications from "./UserNotifications";
import EATabPanel from "../../../components/common/EATabPanel";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

const InputBloc = ({ label, children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.formContainer}>
      <Grid item xs={12} style={{ marginBottom: "15px" }}>
        <FormLabel className={classes.formLabel}>{label}</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>{children}</FormGroup>
      </Grid>
    </div>
  );
};

function UserProfile() {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const { isGlobalAdmin } = React.useContext(RoleBasedAccessControlContext);
  const [user, setUser] = useState();
  const [isUnsubscribeUserDialogOpen, setIsUnsubscribeUserDialogOpen] = useState(false);
  const [isEqualUser, setIsEqualUser] = useState(true);
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [value, setValue] = useState(0);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange" });

  const values = useWatch({ control });

  useEffect(() => {
    if (state.user && state.account) {
      (async function fetchData() {
        try {
          const resp = await getUserByAccountAndId(state.account.id, state.user.id);
          const phone = resp.content.phone?.startsWith("+")
            ? resp.content.phone.substring(1)
            : resp.content.phone;
          const userResp = {
            ...resp.content,
            phone: phone?.replace(/\s/g, ""),
          };
          setUser(userResp);
        } catch (e) {
          dispatch({
            type: "ALERT",
            alert: {
              type: ERROR,
              msg: e?.message?.error
                ? e?.message?.error[0]?.message
                : "INTERNAL_ERROR",
            },
          });
        }
      })();
    }
  }, [state.user, state.account, dispatch]);

  useEffect(() => {
    if (user) {
      const equalUser = _.isEqual(
        values,
        _.pick(user, "firstname", "name", "function", "email", "phone")
      );
      setIsEqualUser(
        _.isEmpty(values) || equalUser
      );
    }
  }, [values, user]);

  const handleUpdate = async (data) => {
    if (isEmpty(errors)) {
      const userToUpdate = {
        email: data.email,
        name: data.name,
        firstName: data.firstname,
        userFunction: data.function,
        phone: "+" + data.phone,
      };
      try {
        startLoading();
        await updateAccountUser(
          state.account.id,
          state.user.id,
          userToUpdate,
          dispatch
        );

        dispatch({
          type: "UPDATE_USER_INFOS",
          user: {
            email: data.email,
            preferred_username: data.email,
            family_name: data.name,
            given_name: data.firstname,
            name: `${data.firstname} ${data.name}`,
          },
        });
        dispatch({
          type: "ALERT",
          alert: { type: SUCCESS, msg: "userProfile.updateSuccessInfo" },
        });
        if (data.email !== user.email) {
          window.location.reload()
        }
      } catch (error) {
        if (error?.message?.error?.[0]?.reason?.includes("a user with the same email already exists")) {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "USER_UPDATE_EMAIL_EXISTS_ERROR" },
          });
        } else {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "USER_UPDATE_ERROR" },
          });
        }
      }finally {
        stopLoading();
      }
    }
  };

  const onCancelHandler = () => {
    reset(
      {
        firstname: user.firstname,
        name: user.name,
        function: user.function,
        email: user.email,
        phone: user.phone,
      },
      {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // if notifications=true is true, set tab to 1 (notifications tab)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const notifications = urlParams.get('notifications');
    if (notifications === "true") {
      setValue(1);
    }
  }, []);

  // Set tab value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* Wrapper */}
      <div className={classes.mainWrapper}>
        {/*  Decoration */}
        <div className={classes.circleLeft + " " + classes.circle}></div>
        <div className={classes.circleRight + " " + classes.circle}></div>

        {/* Header */}
        <div className={classes.header}>
          <div className={classes.headerBG}>
            <div className={classes.appBarWrapper}>
              <MenuAppBar variant={"admin"} />
            </div>
          </div>
          <div className={classes.headerInfo}>
            <div className={classes.headerInfoCircle}>
              <EAIcon icon={"user-rounded"} />
            </div>
            <div>
              {user && (
                <>
                  <Typography className='dt' variant={"h1"}>
                    {user?.firstname} {user?.name}
                  </Typography>
                  <span className={classes.headerInfoRole}>
                    {isGlobalAdmin
                      ? "Super Admin"
                      : (
                        user.roles.map((v, index) => (
                          <span className='dt' key={index}>
                            {v}
                            {index !== user.roles.length - 1 && ', '}
                          </span>
                        ))
                      )
                    }
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Main content*/}
        <Box className={classes.tabsWrapper}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="dashboard tabs"
          >
            <Tab
              label={i18next.t("userProfile.tabs.profil")}
              {...a11yProps(0)}
              className={ clsx(classes.tab, value === 0 ? classes.selectedTab : classes.unselectedTab) }
            />
            <Tab
              label={i18next.t("userProfile.tabs.notifications")}
              {...a11yProps(1)}
              className={ clsx(classes.tab, value === 1 ? classes.selectedTab : classes.unselectedTab) }
            />
          </Tabs>
        </Box>

        {/*---------------------*/}
        {/*------ Profil -------*/}
        {/*---------------------*/}
        <EATabPanel index={0} value={value} className={classes.mainContentWrapper}>
          {/* Form */}
          <div className={classes.form}>
            {user && (
              <form onSubmit={handleSubmit(handleUpdate)}>
                {/* Title */}
                <Typography variant="h3">
                  {`${i18next.t("userProfile.title")}`}
                </Typography>

                <Grid container>
                  {/* User Firstname */}
                  <Grid item xs={6} style={{ padding: "22px 0 0 0px" }}>
                    <InputBloc
                      label={`${i18next.t("userProfile.firstnameInputLabel")}`}
                    >
                      <Controller
                        control={control}
                        name="firstname"
                        defaultValue={user.firstname}
                        rules={{
                          required: i18next.t("infos.required"),
                          pattern: {
                            value: REGEXP_NAME,
                            message: i18next.t("infos.USER_NAME_ERROR"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={
                              !!errors.firstname
                                ? classes.inputError
                                : classes.input
                            }
                            placeholder={i18next.t("userProfile.placeholder.firstName")}
                            id="firstname"
                            variant="outlined"
                            error={!!errors.firstname}
                            fullWidth
                            helperText={
                              !!errors.firstname ? errors.firstname.message : ""
                            }
                            disabled={state.provisioning}
                          />
                        )}
                      />
                    </InputBloc>
                  </Grid>

                  {/* User Name */}
                  <Grid item xs={6} style={{ padding: "22px 0 0 15px" }}>
                    <InputBloc
                      label={`${i18next.t("userProfile.nameInputLabel")}`}
                    >
                      <Controller
                        control={control}
                        name="name"
                        defaultValue={user.name.toUpperCase()}
                        rules={{
                          required: i18next.t("infos.required"),
                          pattern: {
                            value: REGEXP_NAME,
                            message: i18next.t("infos.USER_NAME_ERROR"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={
                              !!errors.name ? classes.inputError : classes.input
                            }
                            placeholder={i18next.t("userProfile.placeholder.lastName")}
                            id="name"
                            variant="outlined"
                            error={!!errors.name}
                            fullWidth
                            helperText={
                              !!errors.name ? errors.name.message : ""
                            }
                            disabled={state.provisioning}
                          />
                        )}
                      />
                    </InputBloc>
                  </Grid>

                  {/* User Function */}
                  <Grid item xs={6} style={{ padding: "30px 0 0 0" }}>
                    <InputBloc
                      label={`${i18next.t("userProfile.functionInputLabel")}`}
                    >
                      <Controller
                        control={control}
                        name="function"
                        defaultValue={user.function}
                        rules={{
                          required: i18next.t("infos.required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={
                              !!errors.function
                                ? classes.inputError
                                : classes.input
                            }
                            placeholder={i18next.t("userProfile.placeholder.function")}
                            id="function"
                            variant="outlined"
                            error={!!errors.function}
                            fullWidth
                            helperText={
                              !!errors.function ? errors.function.message : ""
                            }
                          />
                        )}
                      />
                    </InputBloc>
                  </Grid>
                  <Grid item xs={6}></Grid>

                  {/* Email */}
                  <Grid item xs={6} style={{ padding: "30px 0 0 0" }}>
                    <InputBloc
                      label={`${i18next.t("userProfile.emailInputLabel")}`}
                    >
                      <Controller
                        control={control}
                        name="email"
                        defaultValue={user.email}
                        rules={{
                          required: i18next.t("infos.required"),
                          pattern: {
                            value: REGEXP_EMAIL,
                            message: i18next.t("infos.USER_EMAIL_ERROR"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={
                              !!errors.email
                                ? classes.inputError
                                : classes.input
                            }
                            placeholder={i18next.t("userProfile.placeholder.email")}
                            id="email"
                            variant="outlined"
                            error={!!errors.email}
                            fullWidth
                            helperText={
                              !!errors.email ? errors.email.message : ""
                            }
                            disabled={state.provisioning}
                          />
                        )}
                      />
                    </InputBloc>
                  </Grid>

                  {/* Phone */}
                  <Grid item xs={6} style={state.provisioning ?{ padding: "30px 0 0 0"} : { padding: "30px 0 0 15px"} }>
                    <InputBloc
                      label={`${i18next.t("userProfile.phoneInputLabel")}`}
                    >
                      <Controller
                        name="phone"
                        rules={{
                          required: i18next.t("infos.required"),
                          validate: (value) => isValidPhoneNumber("+" + value),
                        }}
                        defaultValue={user.phone}
                        onChange={(e) => {
                          return e[0];
                        }}
                        control={control}
                        render={({field: {name, value, onChange, onBlur}}) => (
                          <>
                            <PhoneInput
                              inputClass={errors.phone ? "phone-error" : ""}
                              buttonClass={errors.phone ? "phone-error" : ""}
                              inputStyle={{
                                borderRadius: "16px",
                                border: errors.phone
                                  ? "1px solid #FFCCCC"
                                  : "1px solid #E2E2EA",
                                paddingLeft: "60px",
                                height: "40px",
                                width: "70%",
                              }}
                              buttonStyle={{
                                backgroundColor: "transparent",
                                border: 0,
                                paddingLeft: "13px",
                              }}
                              placeholder={"000000000"}
                              country={preferredCountries[0]}
                              preferredCountries={preferredCountries}
                              preserveOrder={["preferredCountries"]}
                              countryCodeEditable={false}
                              inputProps={{
                                autoFocus: false,
                                name,
                                required: true,
                              }}
                              name
                              enableSearch
                              disableSearchIcon
                              specialLabel={false}
                              fullWidth
                              localization={i18next.t("countries", {
                                returnObjects: true,
                              })}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                            <FormHelperText
                              className={errors.phone ? classes.phoneError : ""}
                            >
                              {errors.phone
                                ? i18next.t("userProfile.errorPhoneFormat")
                                : ""}
                            </FormHelperText>
                          </>
                        )}
                      />
                    </InputBloc>
                  </Grid>

                  <Divider className={classes.divider} />

                  {/* Buttons bloc */}
                  <Grid container justifyContent="flex-end">
                    <EAButton
                      content={i18next.t("cancel")}
                      onClick={onCancelHandler}
                      disabled={isEqualUser}
                      colorVariant={"secondary"}
                      customStyle={{ marginRight: "14px" }}
                      type="reset"
                    />
                    <EAButton
                      content={i18next.t("saving")}
                      disabled={isEqualUser || !_.isEmpty(errors) || isRequestLoading}
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </div>

          {/* Cards Wrapper*/}
          <div className={classes.cardsWrapper}>
            {/* Password modify */}
            {!state.provisioning &&
            <div className={classes.password}>
              <div>
                <Typography variant="h3" className={classes.cardsTitle}>
                  {`${i18next.t("userProfile.modifyPassword")}`}
                </Typography>
                <span className={classes.cardsText}>
                  {`${i18next.t("userProfile.modifyPasswordContent")}`}
                </span>
              </div>
              <EAButton
                content={i18next.t("modify")}
                size={"small"}
                outFilled={true}
                onClick={() => setIsChangePasswordDialogOpen(true)}
                colorVariant={"success"}
                customStyle={{
                  marginTop: "10px",
                  minWidth: "104px",
                  padding: "6px 10px 6px 10px",
                }}
              />
            </div>}

            {/* Unregistered */}
            {!state.provisioning &&
            <div className={classes.unregistered}>

              <div>
                <Typography variant="h3" className={classes.cardsTitle}>
                  {`${i18next.t("userProfile.unregistered")}`}
                </Typography>
                <span className={classes.cardsText}>
                  {`${i18next.t("userProfile.unregisteredContent")}`}
                </span>
                <span className={classes.cardsBoldText}>
                  {state.account?.name?.toLowerCase()}
                </span>
                <br/>
                <span className={classes.cardsText}>
                  {`${i18next.t("userProfile.unregisteredContent3")}`}
                </span>
              </div>
              <EAButton
                content={i18next.t("unregister")}
                size={"small"}
                outFilled={true}
                onClick={() => setIsUnsubscribeUserDialogOpen(true)}
                colorVariant={"success"}
                customStyle={{
                  color: theme.palette.grey.grey400,
                  border: "1px solid " + theme.palette.grey.grey400,
                  marginTop: "10px",
                  minWidth: "138px",
                  padding: "6px 12px 6px 12px",
                  backgroundColor: "white",
                }}
              />
            </div>
              }
          </div>
        </EATabPanel>

        {/*---------------------*/}
        {/*--- Notifications ---*/}
        {/*---------------------*/}
        <EATabPanel value={value} index={1} variant="withoutBackground">
          <UserNotifications />
        </EATabPanel>
      </div>

      {/* Unregisterd */}
      {isUnsubscribeUserDialogOpen && (
        <UserUnsubscribeDialog
          isOpen={isUnsubscribeUserDialogOpen}
          handleClose={() => setIsUnsubscribeUserDialogOpen(false)}
        />
      )}

      {/* Change password */}
      {isChangePasswordDialogOpen && (
        <UserChangePasswordDialog
          isOpen={isChangePasswordDialogOpen}
          handleClose={() => setIsChangePasswordDialogOpen(false)}
        />
      )}
    </>
  );
}

export default UserProfile;
