import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  rootNoData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7F8FC",
    color: "#9A9AAF",
    height: "224px",
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.03)",
    fontWeight: 400,
    fontSize: "14px",
  },
  noDataText: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#9A9AAF",
    marginTop: "16px",
  },
  rootData: {
    height: "196px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "25px",
    padding: "24px 5px",
    paddingTop: "76px"
  },
  shortRiskPanel: {
    background: "rgba(242, 243, 247, 0.7)",
    borderRadius: "4px",
    minWidth: "155px",
    minHeight: "68px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    top: "5px",
    paddingTop: "10px",
  },
  detailRiskPanel: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "64px",
    padding: "10px 0",
    background: "#FFFFFF",
    boxShadow:
      "0px 100px 309px rgba(210, 208, 225, 0.24), 0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 0px 22.3363px 69.0192px rgba(210, 208, 225, 0.143066), 0px 12.5216px 38.6916px rgba(210, 208, 225, 0.12), 0px 6.6501px 20.5488px rgba(210, 208, 225, 0.0969343), 0px 2.76726px 8.55082px rgba(210, 208, 225, 0.0674749)",
    borderRadius: "12px",
    position: "relative",
    paddingLeft: "15px",
  },
  detailRiskPanelXL: {
    maxWidth: "960px",
    minWidth: "500px",
  },
  detailRiskPanelL: {
    maxWidth: "835px",
    minWidth: "500px",
    width: "100%",
  },
  detailRiskPanelS: {
    width: "100%",
    maxWidth: "516px",
    minHeight: "100px",
  },
  detailRiskPanelMin:{
    flexWrap: "wrap",
  },
  detailRiskPanelFirstBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "337px",
  },
  detailRiskPanelBloc: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "202px",
  },
  detailRiskPanelBlocS: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "202px",
  },
  inProgressText: {
    fontWeight: 400,
    color: "#7E7E8F",
    marginTop: "8px",
  },
  scoreText: {
    fontWeight: 700,
    color: "#373534",
    marginTop: "16px",
  },
  wrapperIconHourglass: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    background: "#3949AB",
    boxShadow: "0px 8px 24px rgba(57, 73, 171, 0.32)",
    borderRadius: "8px"
  },
  hourglassIcon: {
    filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(267deg) brightness(108%) contrast(101%)"
  },
  complianceLabel: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    color: "#373534"
  },
  provisionalWrapper: {
    background: "#FFFFFF",
    border: "1px solid #F2F3F7",
    borderRadius: "16px",
    minWidth: "71px",
    height: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 7px",
    gap: "5px",
    marginTop: "3px",
  },
  provisionalBullet: {
    width: "7px",
    height: "7px",
    background: "#FF9A00",
    borderRadius: "50%"
  },
  provisionalLabel: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    color: "#7E7E8F"
  },
  riskLabel: {
    borderRadius: "4px",
    minWidth: "110px",
    height: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px 8px",
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "#FFFFFF",
    position: "absolute",
    top: "-15px"
  },
  riskLabellow: {
    backgroundColor: "#2D9F40",
    border: "3px solid #B0E8B9",
  },
  riskLabelmedium: {
    backgroundColor: "#FF9A00",
    border: "3px solid #FFBB54",
  },
  riskLabelhigh: {
    backgroundColor: "#CC0001",
    border: "3px solid #FF999A",
  },
  riskLabelunknown: {
    backgroundColor: "#535362",
    border: "3px solid #C6CBD9",
  },
  lineOnDetail: {
    position: "absolute",
    top: "50%",
    left: "15px",
    width: "calc(100% - 100px)",
    border : "1px solid #E2E2EA",
  },
  lineOnDetailMin: {
    position: "absolute",
    top: "36px",
    left: "15px",
    width: "calc(100% - 30px)",
    border : "1px solid #E2E2EA",
  },
  lineOnDetailSeparator: {
    border : "1px solid #E2E2EA",
    width: "70px",
    height: "0px",
    position: "relative",
    top: "14px",
  },
  lineOnDetailSeparatorFirst: {
    border : "1px solid #E2E2EA",
    width: "calc(100% - 20px)",
    height: "0px",
    position: "absolute",
    top: "34px",
  },
  lineOnDetailSeparatorSecond: {
    border : "1px solid #E2E2EA",
    width: "calc(100% - 50px)",
    height: "0px",
    position: "absolute",
    top: "73px",
  },
  lineOnDetailSeparatorXS: {
    border : "1px solid #E2E2EA",
    width: "70px",
    height: "0px",
    position: "relative",
    top: "22px",
  },
  induedBulletRoot: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "0 10px",
    background: "white",
    zIndex: 1,
    marginRight: "10px",
  },
  induedBulletRootMin: {
    marginTop: "10px"
  },
  induedBulletWrapper: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "white",
  },
  induedBulletWrapperlow: {
    filter: "drop-shadow(0px 11.28px 24.231px rgba(45, 159, 64, 0.11)) drop-shadow(0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772)) drop-shadow(0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08)) drop-shadow(0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228)) drop-shadow(0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833))"
  },
  induedBulletWrappermedium: {
    filter: "drop-shadow(0px 11.28px 24.231px rgba(255, 154, 0, 0.11)) drop-shadow(0px 6.0308px 12.9551px rgba(255, 154, 0, 0.0953772)) drop-shadow(0px 3.38082px 7.2625px rgba(255, 154, 0, 0.08)) drop-shadow(0px 1.79553px 3.85706px rgba(255, 154, 0, 0.0646228)) drop-shadow(0px 0.747159px 1.60501px rgba(255, 154, 0, 0.0449833))"
  },
  induedBulletWrapperhigh: {
    filter: "drop-shadow(0px 11.28px 24.231px rgba(204, 0, 1, 0.11)) drop-shadow(0px 6.0308px 12.9551px rgba(204, 0, 1, 0.0953772)) drop-shadow(0px 3.38082px 7.2625px rgba(204, 0, 1, 0.08)) drop-shadow(0px 1.79553px 3.85706px rgba(204, 0, 1, 0.0646228)) drop-shadow(0px 0.747159px 1.60501px rgba(204, 0, 1, 0.0449833))"
  },
  induedBulletWrapperunknown: {
    filter: "drop-shadow(0px 11.28px 24.231px rgba(45, 159, 64, 0.11)) drop-shadow(0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772)) drop-shadow(0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08)) drop-shadow(0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228)) drop-shadow(0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833))",
  },
  induedBullet: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },
  induedBulletlow: {
    background: "#2D9F40"
  },
  induedBulletmedium: {
    background: "#FF9A00"
  },
  induedBullethigh: {
    background: "#CC0001"
  },
  induedBulletunknown: {
    background: "#535362"
  },
  induedBulletLabel: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#373534"
  },
}));

export default useStyles;
