import {
  Grid,
  Typography,
  Paper,
  MenuItem,
  InputLabel,
  TextField,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  Divider,
} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import countries from "i18n-iso-countries";
import i18next from "i18next";
import _ from "lodash";
import {
  formatIdentifier,
  getDividedCountries,
  hasOption,
  verifyLuhn,
} from "../../../services/common/Utils";
import useStyles from "../../../style/js-style/containers/thirdparties/TPSearchZoneStyle";
import { Controller, useForm } from "react-hook-form";
import TpSearchResultTable from "./TPSearchResultTable";
import { Context } from "../../../states/Store";
import {
  DefaultSelectProps,
  SEARCH_TYPE_PATTERNS,
} from "../../../services/common/Constants";
import { searchThirdparty } from "../../../services/edge/ThirdpartyService";
import EAButton from "../../../components/common/EAButton";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

const isValidSiret = (value) => {
  return (
    SEARCH_TYPE_PATTERNS["SIRET"].pattern.test(value) && verifyLuhn(value, 14)
  );
};

const isValidSiren = (value) => {
  return (
    SEARCH_TYPE_PATTERNS["SIREN"].pattern.test(value) && verifyLuhn(value, 9)
  );
};

const searchByValueValidator = (value, searchBy, selectedCountry) => {
  switch (searchBy) {
    case "LOCAL_ID":
      let isLocalIdValid;
      if (
        selectedCountry === "FR" &&
        !isValidSiren(value) &&
        !isValidSiret(value)
      ) {
        isLocalIdValid = i18next.t(SEARCH_TYPE_PATTERNS["SIRET"].msg);
      }
      return isLocalIdValid;
    case "DUNS":
      return !SEARCH_TYPE_PATTERNS["DUNS"].pattern.test(value)
        ? i18next.t(SEARCH_TYPE_PATTERNS["DUNS"].msg)
        : undefined;
    case "VAT":
      if (selectedCountry === "FR") {
        return !SEARCH_TYPE_PATTERNS["VAT_FR"].pattern.test(value) &&
          !isValidSiren(value.substring(4))
          ? i18next.t(SEARCH_TYPE_PATTERNS["VAT"].msg)
          : undefined;
      } else {
        return !SEARCH_TYPE_PATTERNS["VAT"].pattern.test(value)
          ? i18next.t(SEARCH_TYPE_PATTERNS["VAT"].msg)
          : undefined;
      }
    default:
      return undefined;
  }
};

const SearchRadioButton = ({value, register}) => (
  <FormControlLabel
    value={value}
    control={<Radio size="small" color="primary" />}
    {...register("searchBy", { required: true })}
    label={i18next.t(`thirdpartySearch.${value}`)}
  />
);

function TPSearchZone() {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [results, setResults] = React.useState([]);
  const [favoriteCounties, setFavoriteCounties] = React.useState();
  const [otherCountries, setOtherCountries] = React.useState();
  const [isDnbOrInternationalOn, setIsDnbOrInternationalOn] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [firstClick, setFirstClick] = React.useState(false);
  const [disabledValidate, setDisabledValidate] = useState(true);

  const {
    setValue,
    getValues,
    register,
    formState: { errors },
    formState,
    handleSubmit,
    control,
    clearErrors
  } = useForm();

  useEffect(() => {
    setDisabledValidate( !_.isEmpty(formState.errors.searchByValue) )
  }, [formState]);


  useEffect(() => {
    setIsDnbOrInternationalOn(
      hasOption(state.accounts, state.account.id, "DNB") ||
      hasOption(state.accounts, state.account.id, "INTERNATIONAL")
    )
  }, [state.accounts, state.account])

  const searchResultsFromApi = useCallback(
    async (data, country) => {
      try {
        startLoading();
        const searchBody = {
          countryCode: country,
          searchBy: data.searchBy,
          value: data.searchByValue,
        };
        let results = await searchThirdparty(
          state.account.id,
          searchBody,
          dispatch
        );
        results = results?.map((r) => {
          return {
            ...r,
            identifier: formatIdentifier(
              r,
              country,
              data.searchBy,
              data.searchByValue,
            )
          };
        });
        setResults(results);
        setLoader(false);
      } catch (error) {
        setResults([]);
        setLoader(false);
      }finally {
        stopLoading();
      }
    },
    [state.account, dispatch, startLoading, stopLoading]
  );

  useEffect(() => {
    let allCountries = countries.getNames(i18next.t("lang"));
    const [favs, others] = getDividedCountries(allCountries);
    setFavoriteCounties(favs);
    setOtherCountries(others);
  }, []);

  const handleCountryChange = (event) => {
    setValue("selectedCountry", event.target.value);
  };

  const tpSearchHandler = async (data) => {
    setResults([]);
    setLoader(true);
    setFirstClick(true);
    let dataSearchBy = data.searchBy;
    if (data.selectedCountry === "FR" && data.searchBy === "LOCAL_ID") {
      if (isValidSiret(data.searchByValue)) {
        dataSearchBy = "SIRET";
      } else if (isValidSiren(data.searchByValue)) {
        dataSearchBy = "SIREN";
      }
    }
    data = {
      ...data,
      searchBy: dataSearchBy,
    };
    searchResultsFromApi(data, data.selectedCountry);
  };

  const handleRadioChange = () => {
    clearErrors("searchByValue");
  }

  return (
    <>
      <Paper elevation={0} className={classes.tpSearch}>
        <Grid container alignItems="flex-start" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">
              {i18next.t("thirdpartySearch.tpIdentification")}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} sm={6} md={4}>
              <div className={classes.tpSearchContainer}>
                <InputLabel id="countries-select-label">
                  {i18next.t("thirdpartySearch.countrySelect")}
                </InputLabel>
                {favoriteCounties && otherCountries && (
                  <Controller
                    control={control}
                    name="selectedCountry"
                    defaultValue="FR"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        id="countries-select"
                        onChange={handleCountryChange}
                        disabled={!isDnbOrInternationalOn}
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {
                            ...DefaultSelectProps.MenuProps,
                            classes: { paper: classes.countriesPaper },
                          },
                        }}
                      >
                        {favoriteCounties?.map((country) => (
                          <MenuItem key={country.key} value={country.key}>
                            {country.label}
                          </MenuItem>
                        ))}
                        <Divider />
                        {otherCountries?.map((country) => (
                          <MenuItem key={country.key} value={country.key}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} container>
              <div className={classes.tpSearchContainer}>
                <form onSubmit={handleSubmit(tpSearchHandler)}>
                  <FormControl
                    component="fieldset"
                    className={classes.searchByRadios}
                  >
                    <div className="searchRadiosContainer">
                      <Typography component="span">
                        {i18next.t("thirdpartySearch.searchBy")}
                      </Typography>

                      <RadioGroup
                        aria-label="search by"
                        name="searchBy"
                        defaultValue="NAME"
                        row
                        onChange={handleRadioChange}
                      >
                        <SearchRadioButton value="NAME" register={register}/>
                        <SearchRadioButton value="LOCAL_ID" register={register}/>
                        <SearchRadioButton value="VAT" register={register}/>
                        { isDnbOrInternationalOn && <SearchRadioButton value="DUNS" register={register}/>}
                      </RadioGroup>
                    </div>
                  </FormControl>

                  <Controller
                    control={control}
                    name="searchByValue"
                    defaultValue=""
                    rules={{
                      required: i18next.t("infos.required"),
                      validate: (value) =>
                        searchByValueValidator(
                          value,
                          getValues("searchBy"),
                          getValues("selectedCountry")
                        ),
                    }}
                    render={({ field }) => (
                      <Grid item xs={12}>
                        <TextField
                          id="tp-search-input"
                          variant="outlined"
                          error={errors && !!errors.searchByValue}
                          fullWidth
                          {...field}
                          helperText={
                            !!errors.searchByValue
                              ? errors.searchByValue.message
                              : ""
                          }
                        />
                      </Grid>
                    )}
                  />
                  <EAButton
                    content={i18next.t("tpSearch")}
                    className={classes.tpSearchBtn}
                    disabled={disabledValidate || isRequestLoading}
                    type="submit"
                  />
                </form>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {firstClick && (
          <>
            <Divider className={classes.divider} />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {i18next.t("thirdpartySearch.searchResults")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.resultWarning}>
                  {i18next.t("thirdpartySearch.resultWarning")}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.tableContainer}>
                <TpSearchResultTable loader={loader} results={results} />
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </>
  );
}

export default TPSearchZone;
