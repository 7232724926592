import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    tab: {
        fontWeight: 600,
        borderRadius: '18px 18px 0 0',
        minWidth: "123px",
        height: "39px",
        marginRight: "5px",
        backgroundColor: "white",
        zIndex: 1,
        fontSize: "18px",
        textTransform: "none",
        fontFamily: "Metric",
    },
    selectedTab: {
        color: "black !important",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -10px, rgba(0, 0, 0, 0.3) 0px 8px 13px -9px",
    },
    unselectedTab: {
        color: theme.palette.grey.grey400,
        opacity: 0.75,

        "& MuiTabs-scroller": {
            position: "absolute"
        },
    },
    tabsWrapper: {
        border: "none",
        borderBottom: 1,
        borderColor: 'divider',
    },
    wrapper: {
        zIndex: 1,
        overflow: "hidden",
    },
    pieContainer: {
        height: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
    },
    pieNoDataText: {
        color: "#9A9AAF",
        textAlign: "center",
        fontSize: "18px",
        fontFamily: "Metric",
    },
    bolder: {
        fontWeight: 600,
    },
}));

export default useStyles;
