import { Grid } from "@mui/material";
import React from "react";
import UsersDataTable from "./UsersDataTable";

function Users({active}) {
  return <>
    <Grid container justifyContent="space-evenly" spacing={0}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UsersDataTable active={active}/>
        </Grid>
      </Grid>
    </Grid>
  </>;
}

export default Users;
