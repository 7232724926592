import {
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import i18next from "i18next";
import React from 'react';
import useStyles from "../../../style/js-style/containers/dashboard/TabStyle";
import TpMapChart from "./TpMapChart";
import TpActivitiesChart from "./TpActivitiesChart";
import TpStatesChart from "./TpStatesChart";
import SkeletonDashboard, {SKELETON_TYPE} from "../../../components/common/skeleton/SkeletonDashboard";

function TabThirdparties({ isLoading }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const down1446 = useMediaQuery(theme.breakpoints.down("1446"));
  const mapGraphTitle = i18next.t("dashboard.thirdparties.geographic");

  return (
    !isLoading ? (
        <Paper  elevation={0} className={classes.paperContainer}>
            <Grid style={{height : "600px"}} container spacing={2}>
              {/* Maps */}
              <Grid container item xs={12} lg={4}>
                <Paper className={classes.graphPaper}>
                  <Grid container item xs={12} lg={8}>
                    <Tooltip title={down1446 ? mapGraphTitle : ""}>
                      <Typography
                        variant="h3"
                        className={classes.graphAreaTitle}
                      >
                        {mapGraphTitle}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid style={{height: '100%'}} container item xs={12}>
                    <TpMapChart/>
                  </Grid>
                </Paper>
              </Grid>

              {/* Hierarchy */}
              <Grid item xs={12} lg={4}>
                <Paper style={{height: '100%'}} className={classes.graphPaper}>
                  <Typography variant="h3" className={classes.graphAreaTitle}>
                    {i18next.t("dashboard.thirdparties.majorActivity")}
                  </Typography>
                  <Grid style={{height: '100%'}} container item xs={12}>
                     <TpActivitiesChart/>
                  </Grid>
                </Paper>
              </Grid>

              {/* Pie */}
              <Grid item xs={12} lg={4}>
                <Paper style={{height: '100%'}} className={classes.graphPaper}>
                  <Typography variant="h3" className={classes.graphAreaTitle}>
                    {i18next.t("dashboard.thirdparties.tpState")}
                  </Typography>
                  <Grid style={{height: '100%'}} container item xs={12}>
                    <TpStatesChart/>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
        </Paper>
    ) : (
        <SkeletonDashboard
          sections={[
            { title : i18next.t("dashboard.thirdparties.geographic"),
              type : SKELETON_TYPE.MAP
            },
            { title : i18next.t("dashboard.thirdparties.majorActivity"),
              type : SKELETON_TYPE.BLOCK
            },
            { title : i18next.t("dashboard.thirdparties.tpState"),
              type : SKELETON_TYPE.CIRCULAR
            },
          ]}
        />
      )
  );
}

export default TabThirdparties;
