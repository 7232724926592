import { InputLabel, Grid, TextField, Paper, MenuItem } from "@mui/material";
import i18next from "i18next";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DefaultSelectProps, FREE } from "../../services/common/Constants";
import DossierChoiceDocuments from "../dossiers/create-dossier/DossierChoiceDocuments";
import DossierTypeListDocuments from "../dossiers/create-dossier/DossierTypeListDocuments";
import useStyles from "../../style/js-style/containers/dossiers/create-dossier/CreationFormDossierInfosBlocStyle";

import moment from "moment";
import EADatePicker from "../../components/common/EADatePicker";

const maxLengthRefDos = 100;

const CreationFormDossierInfosBloc = ({
  documents,
  dossierTypes,
  documentsSelection,
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const [selectedTypeDossier, setSelectedTypeDossier] = useState(FREE);

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.card}>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item xs={12} md={3}>
            <InputLabel className={classes.inputLabel}>
              {i18next.t("dossierType")}
            </InputLabel>

            <Controller
              control={formMethods.control}
              name="dossierType"
              defaultValue={selectedTypeDossier}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="dossier-type-input-id"
                  className={classes.select}
                  SelectProps={DefaultSelectProps}
                  select
                  fullWidth
                  onChange={(e) => {
                    formMethods.setValue("dossierType", e.target.value);
                    setSelectedTypeDossier(e.target.value);
                  }}
                >
                  <MenuItem
                    key="empty"
                    value={FREE}
                    style={{ minHeight: "24px" }}
                  >
                    {i18next.t("free")}
                  </MenuItem>
                  {dossierTypes?.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.libelle}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputLabel className={classes.inputLabel}>
              {`${i18next.t("reference")} *`}
            </InputLabel>

            <Controller
              control={formMethods.control}
              name="dossierReference"
              defaultValue=""
              rules={{
                required: i18next.t("infos.required"),
                maxLength: {
                  value: maxLengthRefDos,
                  message: i18next.t("infos.E011_VAR", {
                    input: i18next.t("theReference"),
                    maxLength: maxLengthRefDos,
                  }),
                },
              }}
              render={({ field }) => (
                <TextField
                  id="reference-input-id"
                  error={!!formMethods.formState.errors.dossierReference}
                  fullWidth
                  {...field}
                  helperText={
                    !!formMethods.formState.errors.dossierReference
                      ? formMethods.formState.errors.dossierReference.message
                      : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputLabel className={classes.inputLabel}>
              {i18next.t("expirationDate")}
            </InputLabel>
            <Controller
              control={formMethods.control}
              name="expirationDate"
              defaultValue={null}
              render={({ field }) => (
                <EADatePicker
                  name={field.name}
                  minDate={moment().add(1, "days")}
                  formMethods={formMethods}
                  disableHighlightToday
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} style={{marginTop: "16px"}}>
          {/* Input : Description */}
          <InputLabel className={classes.inputLabel}>
            {`${i18next.t("description")} `}
          </InputLabel>

          <Controller
            control={formMethods.control}
            name="description"
            rules={{
              maxLength: {
                value: 300,
                message: i18next.t("infos.E011_VAR", {
                  input: i18next.t("TheDescription"),
                  maxLength: 300,
                }),
              },
            }}
            render={({ field }) => (
              <TextField
                id="description-input-id"
                fullWidth
                multiline
                rows={3}
                {...field}
                error={!!formMethods.formState.errors.description}
                helperText={
                  !!formMethods.formState.errors.description
                    ? formMethods.formState.errors.description.message
                    : ""
                }
              />
            )}
          />
        </Grid>
      </Paper>

      {selectedTypeDossier === FREE ? (
        <DossierChoiceDocuments
          selection={documentsSelection}
          documents={documents}
          hasCustomPagination
        />
      ) : (
        <DossierTypeListDocuments
          documents={
            dossierTypes.filter((d) => d.code === selectedTypeDossier)[0]
              ?.requirements
          }
        />
      )}
    </div>
  );
};
export default CreationFormDossierInfosBloc;
