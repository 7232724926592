import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogText: {
    padding: "0",
    textAlign: "center",
    width: "100%",
    whiteSpace: "pre-wrap",
  },
  dialogTextSingle: {
    padding: "0 0 8px 0",
    textAlign: "center",
    width: "100%",
    whiteSpace: "pre-wrap",
  },
  mailInfoWrapper:{
    backgroundColor: theme.palette.grey.grey100,
    color: theme.palette.grey.grey700 + " !important",
    minWidth: "428px !important",
    fontWeight: 400,
    padding: "1rem 5rem",
    marginTop: "8px",
    borderRadius: "4px",
  },
  deleteInfo:{
    color: theme.palette.grey.grey700 + " !important",
    fontWeight: 600
  },
  deleteAllWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imgWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.error.error100,
    borderRadius: "50%",
    margin: "0 0 1rem 0",
  },
  imgWrapperMulti:{
    width: "80px",
    height: "80px",
  },
  imgWrapperSingle:{
    width: "55px",
    height: "55px",
  },
  imgContentMulti:{
    width: "26px",
    height: "33px",
  },
  imgContent:{
    width: "21px",
    height: "27px",
  },
  title: {
    color: theme.palette.grey.grey700,
    fontWeight: 600,
    fontFamily: "Metric",
    fontSize: "20px",
  },
}));

export default useStyles;
