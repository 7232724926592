import React from "react";
import { Box } from "@mui/material"
import i18next from "i18next";
import EAChip from "./EAChip";
import EAFlag from "./EAFlag";

const EAHqAndFlag = ({isHQ, countryCode}) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
            <EAChip label={isHQ ? i18next.t("headquarter") : i18next.t("secondaryEstablishment")}/>
            <EAFlag countryCode={countryCode}/>
        </Box>
    )
}

export default EAHqAndFlag;