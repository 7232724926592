import { theme } from "../../globalStyle";
import { darken } from "@mui/material/styles";

// Configuration
const darkenCoef = 0.08;

// Button's colors
const buttonColor = {
  primary: {
    main: theme.palette.primary.primary600,
    textColor: "white",
    shadow: "0px 11.28px 24.231px rgba(45, 159, 64, 0.115017), 0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772), 0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08), 0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228), 0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833)",
    filledBackground: theme.palette.primary.primary100,
    filledBorder: "",
    hover: darken(theme.palette.primary.primary600, darkenCoef),
    filledHover: darken(theme.palette.primary.primary100, darkenCoef),
  },
  primaryLight: {
    main: theme.palette.primary.primary200,
    textColor: theme.palette.primary.primary600,
    shadow: "0px 11.28px 24.231px rgba(45, 159, 64, 0.115017), 0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772), 0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08), 0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228), 0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833)",
    filledBackground: theme.palette.primary.primary100,
    filledBorder: "",
    hover: darken(theme.palette.primary.primary200, darkenCoef),
    filledHover: darken(theme.palette.primary.primary100, darkenCoef),
  },
  error: {
    main: theme.palette.error.error600,
    textColor: "white",
    shadow: "0px 11.28px 24.231px rgba(255, 0, 1, 0.115017), 0px 6.0308px 12.9551px rgba(255, 0, 1, 0.0953772), 0px 3.38082px 7.2625px rgba(255, 0, 1, 0.08), 0px 1.79553px 3.85706px rgba(255, 0, 1, 0.0646228), 0px 0.747159px 1.60501px rgba(255, 0, 1, 0.0449833)",
    filledBackground: "#FFF",
    filledBorder: "",
    hover: darken(theme.palette.error.error500, darkenCoef),
    filledHover: darken("#FFF", darkenCoef),
  },
  errorLight: {
    main: theme.palette.error.error100,
    textColor: theme.palette.error.error600,
    shadow: "0px 11.28px 24.231px rgba(255, 0, 1, 0.115017), 0px 6.0308px 12.9551px rgba(255, 0, 1, 0.0953772), 0px 3.38082px 7.2625px rgba(255, 0, 1, 0.08), 0px 1.79553px 3.85706px rgba(255, 0, 1, 0.0646228), 0px 0.747159px 1.60501px rgba(255, 0, 1, 0.0449833)",
    filledBackground: theme.palette.error.error100,
    filledBorder: "",
    hover: darken(theme.palette.error.error100, darkenCoef),
    filledHover: darken(theme.palette.error.error100, darkenCoef),
  },
  secondary: {
    main: theme.palette.grey.grey100,
    textColor: theme.palette.grey.grey600,
    shadow: "",
    filledBackground: "#FFF",
    filledBorder: theme.palette.grey.grey300,
    hover: darken(theme.palette.grey.grey200, darkenCoef),
    filledHover: darken(theme.palette.grey.grey500, darkenCoef),
  },
  secondary_dark: {
    main: theme.palette.grey.grey200,
    textColor: theme.palette.grey.grey600,
    shadow: "",
    filledBackground: "#FFF",
    filledBorder: theme.palette.grey.grey300,
    hover: darken(theme.palette.grey.grey200, darkenCoef),
    filledHover: darken(theme.palette.grey.grey500, darkenCoef),
  },
  success: {
    main: theme.palette.grey.grey600,
    textColor: "#FFF",
    shadow: "0px 2px 5px rgba(0, 0, 0, 0.07)",
    filledBackground: theme.palette.grey.grey100,
    filledBorder: theme.palette.grey.grey600,
    hover: darken(theme.palette.grey.grey600, darkenCoef),
    filledHover: darken(theme.palette.grey.grey100, darkenCoef),
  },
  warning: {
    main: theme.palette.warning.warning500,
    textColor: "white",
    shadow: "",
    filledBackground: theme.palette.grey.grey100,
    filledBorder: theme.palette.warning.warning500,
    hover: darken(theme.palette.warning.warning500, darkenCoef),
    filledHover: darken(theme.palette.warning.warning500, darkenCoef),
  },
  warningLight: {
    main: theme.palette.warning.warning200,
    textColor: theme.palette.warning.warning600,
    shadow: "none",
    filledBackground: theme.palette.warning.warning600,
    filledBorder: theme.palette.warning.warning600,
    hover: darken(theme.palette.warning.warning200, darkenCoef),
    filledHover: darken(theme.palette.warning.warning600, darkenCoef),
  },
  warningDark: {
    main: theme.palette.warning.warning600,
    textColor: "white",
    shadow: "none",
    filledBackground: theme.palette.warning.warning600,
    filledBorder: theme.palette.warning.warning600,
    hover: darken(theme.palette.warning.warning600, darkenCoef),
    filledHover: darken(theme.palette.warning.warning600, darkenCoef),
  },
  blue: {
    main: "#3949AB99",
    textColor: "white",
    shadow: "none",
    filledBackground: "#3949AB99",
    filledBorder: "#3949AB99",
    hover: darken("#3949AB99", darkenCoef),
    filledHover: darken("#3949AB99", darkenCoef),
  },
};

// Button's sizing
const buttonSize = {
  base: {
    textTransform: "none",
    fontWeight: 600,
    fontFamily: "Metric",
    cursor: "pointer",
  },
  xs: {
    fontSize: "12px",
    padding: "6px 10px",
    minWidth: "76px",
    minHeight: "28px",
    borderRadius: "10px",
    lineHeight: "12px",
  },
  small: {
    fontSize: "12px",
    padding: "8px 12px",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "12px",
    lineHeight: "12px",
  },
  regular: {
    fontSize: "14px",
    padding: "11px 16px",
    minWidth: "97px",
    minHeight: "40px",
    borderRadius: "16px",
    lineHeight: "14px",
  },
  large: {
    fontSize: "18px",
    padding: "12.5px 21px",
    minWidth: "126px",
    minHeight: "50px",
    borderRadius: "18px",
    lineHeight: "18px",
  },
};

// Specific to large screen
export const largeButtons = {
  xs: { fontSize: "14px" },
  small: { fontSize: "14px" },
  regular: { fontSize: "16px" },
  large: { fontSize: "20px" },
}

// Hovered style
const hovered = ( color, outFilled ) => {
  return {
    hover: {
      backgroundColor: outFilled
        ? color.filledHover
        : color.hover
    }
  }
};

// Disabled style
const disabledStyle = {
  opacity: "30%",
  cursor: "default",
  lineHeight: "17px",
};

export const getButtonStyle = ( size, colorVariant, outFilled, disabled, borderless ) => {
  const color = buttonColor[colorVariant];

  if (!borderless){
    return {
      ...buttonSize.base,
      ...buttonSize[size],
      ...hovered(color, outFilled),
      ...(disabled && disabledStyle),
      backgroundColor: outFilled
        ? color.filledBackground
        : color.main,
      color : outFilled
        ? color.main
        : color.textColor,
      border : outFilled
        ? "1px solid " + color.main
        : "none",
      boxShadow: color.shadow,
    }
  }else {
    return {
      ...buttonSize.base,
      ...buttonSize[size],
      color : color.main,
      padding: 0,
      backgroundColor: "transparent",
      border: "none",
    }
  }
};
