import React from 'react';
import {Skeleton} from "@mui/material";
import useStyles from "../../../style/js-style/components/common/skeleton/SkeletonDashboardStyle";
import {Paper, Tooltip, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../style/globalStyle";

export const SKELETON_TYPE = {
  BLOCK: 'block',
  LINES: 'lines',
  MAP: 'map',
  CIRCULAR: 'circular',
}

export const SkeletonType = ({type}) => {
  switch (type) {
    case SKELETON_TYPE.BLOCK : return <BlocSkeletonType />
    case SKELETON_TYPE.LINES : return <LinesSkeletonType />
    case SKELETON_TYPE.MAP : return <MapSkeletonType />
    case SKELETON_TYPE.CIRCULAR : return <CircularSkeletonType />
    default : return <BlocSkeletonType />
  }
}

export const BlocSkeletonType = () => {
  const { classes } = useStyles();
  return (
    <Skeleton
      variant="rect"
      width={"100%"}
      height={"90%"}
      className={classes.cell}
    />
  )
}

export const ThirdPartyBlocSkeleton = () => {
  return (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <SkeletonType type="block" />
  </div>
  )
}

export const TransparencyBlocSkeleton = () => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.lineTypeWrapperRow}>
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
        <Skeleton variant="rect" className={classes.lineTypeColumn} />
      </div>
      <Skeleton variant="rect" className={classes.legendVariante} />
    </>
  )
}

const MapSkeletonType = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.lineTypeWrapper}>
      <Skeleton
        variant="rect"
        className={classes.mapToolBar}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={"90%"}
      />
    </div>

  )
}

const LinesSkeletonType = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.skeletonRoot}>
      <div className={classes.lineTypeWrapper}>
        <Skeleton variant="rect" className={classes.lineType} />
        <Skeleton variant="rect" className={classes.lineType} />
        <Skeleton variant="rect" className={classes.lineType} />
        <Skeleton variant="rect" className={classes.lineType} />
        <Skeleton variant="rect" className={classes.lineType} />
        <Skeleton variant="rect" className={classes.lineType} />
      </div>
      <Skeleton variant="rect" className={classes.legend} />
    </div>
  )
}

export const CircularSkeletonType = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.skeletonRoot}>
      <div className={classes.lineTypeWrapper}>
        <Skeleton variant="circular" className={classes.circularType} />
        <div className={classes.emptyInCircle}/>
      </div>
      <Skeleton variant="rect" className={classes.legend} />
    </div>
  )
}

const Element = ({title, size, type, smallScreen}) => {
  const { classes } = useStyles();
  return (
    <Paper className={classes.elementWrapper} style={{width: smallScreen ? "100%" : size + "%"}}>
      <Title title={title} />
      <div className={classes.typeWrapper}>
        <SkeletonType type={type}/>
      </div>
    </Paper>
  )
}

const Title = ({title}) => {
  const { classes } = useStyles();
  return (
    <Tooltip title={title}>
      <Typography variant="h3" className={classes.graphAreaTitle} > {title} </Typography>
    </Tooltip>
  )
}

/**
 * Returns a skeleton for dashboard. if we only provide 2 titles the layout will be in 2/3-1/3
 *
 * @param sections array of object {title : string, type : SKELETON_TYPE}
 * @returns {JSX.Element}
 * @constructor
 */
const SkeletonDashboard = (sections) => {
  const { classes } = useStyles();
  const elementSizing = sections.sections.length === 2 ? [185,130] : [100,100,100]
  const down1200 = useMediaQuery(theme.breakpoints.down("1200"));

  return (
    <div className={down1200 ? classes.rootColumn : classes.rootRow}>
      { sections.sections.map((section, index) => (
        <Element
          title={section['title']}
          type={section['type']}
          size={elementSizing[index]}
          key={index}
          smallScreen={down1200}
        />
      ))}
    </div>
  );
}
export default SkeletonDashboard;
