import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  rootDefault: {
    backgroundColor: "white",
    flexDirection: "row",
    height: "54px",
    borderRadius: "4px",
    marginBottom: "10px",
    padding: "0 17px",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.07))",
    width: "calc(100% + 16px)",
  },
  rootSubItem: {
    backgroundColor: "#F2F3F780",
    minHeight: "54px",
    borderRadius: "4px",
    margin: "7px 17px 5px 17px",
    fontSize: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rootDropActions: {
    backgroundColor: "#D7F4DC80",
    minHeight: "54px",
    borderRadius: "4px",
    margin: "7px 17px 5px 17px",
    fontSize: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rootColumn: {
    flexDirection: "column",
    height: "auto",
    paddingBottom: "13px !important",
  },
  rootRow: {
    // display: "flex",:
    // flexDirection: "row",
    // height: "auto",
    // paddingBottom: "13px !important",
  },
  headerWrapper: {
    height: "54px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
  },
  title: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#16161E",
    textTransform: "uppercase",
    width: "384px",
  },
  titleLower: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#16161E",
    width: "384px",
  },
  titleFull: {
    marginTop: "12px",
    marginBottom: "12px",
    width: "100% !important",
  },
  titleSubItem: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#16161E",
    width: "384px",
    flexGrow: 1,
  },
  titleSubItemAction: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#16161E",
    // width: "384px",
    flexGrow: 1,
  },
  titleWithIconWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "384px",
  },
  titleWithIconWrapperAction: {
    display: "flex",
    flexDirection: "row",
    minWidth: "380px",
  },
  titleWithIconWrapperMin: {
    display: "flex",
    flexDirection: "row",
    width: "600px",
    marginRight: "16px",
  },
  blocWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    width: "100%"
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    minWidth: "350px",
    gap: "10px",
  },
  buttonWrapperFull: {
    display: "flex",
    justifyContent: "flex-start",
    minWidth: "100%",
    gap: "10px",
    paddingLeft: "16px",
  },
  arrowIcon: {
    margin: "0 10px 0 18px"
  },
  actionsIcon: {
    margin: "0 6px 0 18px"
  },
  caretIcon: {
    position: "absolute",
    right: "38px",

  },
  caretIconReverse: {
    position: "absolute",
    right: "38px",
    transform: "rotate(180deg)",
  },
  rootDropActionsWithList: {
    backgroundColor: "#D7F4DC80",
    minHeight: "54px",
    borderRadius: "4px",
    margin: "7px 17px 5px 17px",
    fontSize: "40px",
  },
  blocListWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 18px",
    marginBottom: "18px",
  },
  documentRoles: {
    backgroundColor: "#ffffff7a",
    height: "45px",
    width: "100%",
    borderBottom: "1px solid #00000017",
    display: "flex",
    alignItems: "center",
    transition: "all 0.3s ease",
    gap: "10px",
    "&:first-of-type": {
      borderTop: "1px solid #00000017",
    },
    "&:hover": {
      backgroundColor: "#ffffff",
      transition: "all 0.3s ease",
    },
  },
  documentIcon: {
    marginLeft: "10px",
    opacity: "0.6",
  },
  documentName: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#373534",
    width: "45%",
    marginRight: "2%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  documentButtonStyle: {
    marginRight: "5px",
    "&:last-of-type": {
      marginRight: "0",
    },
  },

}));

export default useStyles;
