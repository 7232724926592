import useStyles from "../../../style/js-style/containers/thirdparties/sheet/ThirdDocumentsInfoStyle.js"
import React, {useEffect, useState} from "react";
import i18next from "i18next";
import {formatDate} from "../../../services/common/Utils";
import {Typography} from "@mui/material";
import EAIcon from "../../../components/common/EAIcon";

function ThirdDocumentsInfo({thirdparty, documents}) {
  const { classes } = useStyles();
  const PRESENT = 0, MISSING = 1;
  const [docsCount, setDocsCount] = useState([0,0])

  useEffect(() => {
    const presentDocsCount = documents?.filter(doc => doc.present)?.length;
    setDocsCount([presentDocsCount, documents?.length - presentDocsCount]);
  }, [documents]);

  return (
  <div className={thirdparty.onboarded ? classes.thirdDocumentsInfo : classes.thirdDocumentsInfoArchived }>
    {thirdparty.onboarded
      ? (
        // Not archived
        <>
          <EAIcon icon={"document_grey2"} className={classes.documentIcon} />
          <Typography
            variant={"h2"}
            className={classes.documentCounter}>
            <span className="dt">{documents?.length}</span> {i18next.t("dashboard.thirdparties.documents", {count: documents?.length})}
          </Typography>
          <div className={classes.separation}></div>
          <div>
            <div className={classes.documentDetailMissing}>
              <EAIcon icon={"help"} extension={"png"} />
              <span>{i18next.t("dashboard.thirdparties.missingDocument", {count : docsCount[MISSING]})} (<span className="dt">{docsCount[MISSING]}</span>)</span>
            </div>
            <div className={classes.documentDetail}>
              <EAIcon icon={"checked"} extension={"png"} />
              <span className={classes.presentTypo}> {i18next.t("dashboard.thirdparties.presentDocument", {count: docsCount[PRESENT]})} (<span className="dt">{docsCount[PRESENT]}</span>) </span>
            </div>
          </div>
        </>
      ) : (
        // Archived
        <div className={thirdparty.stoppingReason ? classes.innerRootArchived : classes.innerRootArchivedNoComment}>
          <EAIcon icon={"thirdparty-archive"} className={classes.documentIconArchived} />
          <p className={classes.archivedText}>{i18next.t("dashboard.thirdparties.archived")}</p>
          <div className={classes.archivedInfo}>
            <EAIcon icon={"stop"} className={classes.archiveStopedAtIcon} />
            <span className={classes.archiveStopedAt}>
              {i18next.t("dashboard.thirdparties.stopAt")}
              { thirdparty.stoppingDate && ( <span className='dt'>{formatDate(thirdparty.stoppingDate)}</span>) }
            </span>
          </div>
          {/* Comment */}
          { thirdparty.stoppingReason &&
            <div className={classes.commentArchivedWrapper}>
              <p className={classes.commentArchived}>
                <span className={classes.commentArchivedBold}>
                  { i18next.t("dashboard.thirdparties.comment") }
                </span> :&nbsp;
                  <span className='dt'>{thirdparty.stoppingReason}</span>
              </p>
            </div>
          }
        </div>
        )}
  </div>
  )
}

export default ThirdDocumentsInfo;
