import React from "react";
import DataTableActions from "components/common/datatable/DataTableActions";
import i18next from "i18next";
import {
  EADataGridCell,
  cellWithTooltip
} from "components/common/datatable/EADataGrid";
import MainHeaderCell from "components/common/datatable/MainHeaderCell";
import StatusChip from "components/common/StatusChip";
import { formatDate } from "services/common/Utils";

const dossierModelTableActions = (
  dossierModel,
  lock,
  active,
  openEditDialog,
  openDeleteDialog,
  changeNature,
  changeActive
) => {
  return [
    {
      label: i18next.t("modify"),
      icon: "edit_grey",
      action: () => openEditDialog(dossierModel[0]),
      isDisabled: false
    },
    {
      label: lock === true ? i18next.t("demandes.dossierModel.lock.actions.unlock") : i18next.t("demandes.dossierModel.lock.actions.lock"),
      icon: lock === true ? "demandes/unlock_gray" : "demandes/lock_gray",
      action: () => changeNature(dossierModel, lock === false),
      isDisabled: false
    },
    {
      label: active === true ? i18next.t("demandes.dossierModel.status.actions.disable") : i18next.t("demandes.dossierModel.status.actions.enable"),
      icon: active === true ? "pause_circle_filled" : "play_circle_filled",
      action: () => changeActive(dossierModel, active === false),
      isDisabled: false
    },
    //For now, we can't delete a model
    /* {
      label: i18next.t("delete"),
      icon: "delete_grey",
      action: () => openDeleteDialog(dossierModel),
      isDisabled: false
    }, */
  ];
};

export const dossierModelListColumns = (openEditDialog, openDeleteDialog, changeNature, changeActive) => {
  return [
    {
      field: "libelle",
      headerName: i18next.t("demandes.dossierModel.libelle"),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("demandes.dossierModel.libelle")} />
      ),
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "code",
      headerName: i18next.t("demandes.dossierModel.code"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "nbDocuments",
      headerName: i18next.t("demandes.dossierModel.nbDocuments"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "creationDate",
      headerName: i18next.t("demandes.dossierModel.creationDate"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
        >
          {formatDate(params.value)}
        </EADataGridCell>
      )
    },
    {
      field: "creatorName",
      headerName: i18next.t("demandes.dossierModel.creatorName"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "updateDate",
      headerName: i18next.t("demandes.dossierModel.updateDate"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
        >
          {formatDate(params.value)}
        </EADataGridCell>
      )
    },
    {
      field: "updateActorName",
      headerName: i18next.t("demandes.dossierModel.updateActorName"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "lock",
      headerName: i18next.t("demandes.dossierModel.lock.title"),
      flex: 0.1,
      type: "singleSelect",
      valueOptions: [
        { value: true, label: i18next.t("demandes.dossierModel.lock.options.locked") },
        { value: false, label: i18next.t("demandes.dossierModel.lock.options.unlocked") },
      ],
      valueFormatter: (params) =>
        params.value === true
          ?
          i18next.t("demandes.dossierModel.lock.options.locked")
          :
          i18next.t("demandes.dossierModel.lock.options.unlocked"),
      renderCell: (params) => (
        <EADataGridCell
        >
          {i18next.t(`demandes.dossierModel.lock.options.${params.value ? "locked" : "unlocked"}`)}
        </EADataGridCell>
      )
    },
    {
      field: "active",
      headerName: i18next.t("demandes.dossierModel.status.title"),
      flex: 0.1,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: [
        { value: true, label: i18next.t("demandes.dossierModel.status.options.active") },
        { value: false, label: i18next.t("demandes.dossierModel.status.options.inactive") },
      ],
      valueFormatter: (params) =>
        params.value === true
          ?
          i18next.t("demandes.dossierModel.status.options.active")
          :
          i18next.t("demandes.dossierModel.status.options.inactive"),
      renderCell: (params) => {
        return (
          <EADataGridCell
            tooltip={false}
          >
            <StatusChip status={params.value ? "active" : "inactive"} label={"demandes.dossierModel.status.options"} />
          </EADataGridCell>
        );
      }
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.1,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="dt" style={{ textAlign: "center" }}>
            <DataTableActions
              actions={dossierModelTableActions(
                [params.row.id],
                params.row.lock,
                params.row.active,
                openEditDialog,
                openDeleteDialog,
                changeNature,
                changeActive
              )}
            />
          </div>
        );
      },
    },
  ];
};
