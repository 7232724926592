import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  circleLeft:{
    top: "-30%",
    left: "15%",
    background: "radial-gradient(67.22% 50% at 50% 50%, rgba(51, 231, 255, 0.105) 0%, rgba(245, 228, 255, 0) 100%)",
    opacity: 0.9,
  },
  circleRight:{
    top: "-10%",
    left: "60%",
    background: "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.105) 0%, rgba(245, 228, 255, 0) 100%)",
  },
  circle:{
    position: "absolute",
    borderRadius: "50%",
    height: "90vh",
    width: "40%",
    zIndex: 0,
  },
}));

export default useStyles;
