import React from "react";
import useStyles from "../../style/js-style/containers/thirdparties/sheet/PanelIndicatorStyle";
import EAIcon from "../common/EAIcon";
import moment from "moment";
import i18next from "i18next";
import clsx from "clsx";
import { Tooltip } from "@mui/material";
import _ from "lodash";

const IndicatorNoData = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.rootNoData}>
      <EAIcon icon="indicator-search" />
      <span className={classes.noDataText}>{i18next.t('calculator.indicator.noIndicators')}</span>
    </div>
  );
}

const ColoredIcon = (color) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12734_45944)">
        <path d="M36.75 11.025C36.75 9.6425 35.9275 8.3825 34.6675 7.8225L22.4175 2.38C21.5075 1.9775 20.475 1.9775 19.5825 2.38L7.3325 7.8225C6.0725 8.3825 5.25 9.6425 5.25 11.025V19.25C5.25 28.9625 11.97 38.045 21 40.25C25.025 39.27 28.5775 36.925 31.29 33.7575L25.83 28.2975C22.435 30.555 17.815 30.17 14.8225 27.1775C11.41 23.765 11.41 18.2175 14.8225 14.805C18.235 11.3925 23.7825 11.3925 27.195 14.805C30.1875 17.7975 30.555 22.4175 28.315 25.8125L33.39 30.8875C35.5075 27.4575 36.75 23.415 36.75 19.25V11.025Z" fill={color.color}/>
        <path d="M21 26.25C23.8995 26.25 26.25 23.8995 26.25 21C26.25 18.1005 23.8995 15.75 21 15.75C18.1005 15.75 15.75 18.1005 15.75 21C15.75 23.8995 18.1005 26.25 21 26.25Z" fill={color.color}/>
      </g>
      <defs>
        <clipPath id="clip0_12734_45944">
          <rect width="42" height="42" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

const IndicatorItem = ({itemData}) => {
  const { classes } = useStyles();
  const color = itemData.resultColorCode ? itemData.resultColorCode : "#E2E2EA"

  return (
    <div className={classes.rootItem}>
      <div className={classes.itemIcon} style={{backgroundColor: color + "20"}}>
        <ColoredIcon color={color} />
      </div>
      <div className={classes.itemContent}>
        <Tooltip title={itemData.name}>
          <div className={clsx(classes.itemName, 'dt')}>
            {itemData.name}
          </div>
        </Tooltip>
        <Tooltip title={itemData.resultValue}>
          <div className={clsx(classes.itemResult, 'dt')} style={{color: color}}>
            {itemData.resultValue}
          </div>
        </Tooltip>
      </div>
      <div className={classes.itemUpdate}>
        {
          <>
            <span>{itemData.resultLastUpdateDate && i18next.t('calculator.indicator.updateAt')}</span>
            <span className="dt">{moment(itemData.resultLastUpdateDate).format(i18next.t('date.dateFormat'))}</span>
          </>
        }
      </div>
    </div>
  );
}

function PanelIndicators({ indicators }) {
  const { classes } = useStyles();

  if(_.isEmpty(indicators)) return <IndicatorNoData />
  else{
   return (
     <div className={classes.rootData}>
       {
         indicators.map((indicator) => (
           <IndicatorItem itemData={indicator} />
         ))
       }
     </div>
   )
  }
}

export default PanelIndicators;
