import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root:{
    background: "linear-gradient(180deg, #227730D9 0%, rgba(45,159,64,1) 75%, rgba(249,249,251,1) 76%)",
    padding: "1rem 0 0 0",
    borderRadius: "16px",
  },
  title: {
    color: "white",
    marginLeft: "20px",
    position: "relative",
    top: "5px",
    fontSize: "28px !important",
    fontWeight: 600,
  },
  adminIconWrapper: {
    borderRadius: "50%",
    border: "2px solid white",
    backgroundColor: theme.palette.primary.primary200,
    maxWidth: "60px !important",
    width: "60px",
    minWidth: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "32px",
  },
  headerWrapper: {
    backgroundImage: "url('/img/background/bg-valid.svg')",
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100%",
    height: "207px",
    position: "relative",
    top: "-1rem",
    borderRadius: "12px",
    paddingTop: ".5rem",
  },
  headerContent: {
    position: "relative",
    bottom: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  mainContentWrapper:{
    position: "relative",
    top: "-3.5rem",
  },
  // Tab
  tab: {
    fontWeight: 600,
    borderRadius: '18px 18px 0 0',
    minWidth: "38px",
    height: "25px",
    marginRight: "2px",
    backgroundColor: "white",
    zIndex: 1,
    position: "relative",
    bottom: "-10px",
    textTransform: "none",
  },
  selectedTab: {
    color: "black !important",
  },
  unselectedTab: {
    opacity: .75,
  },
  tabsWrapper: {
    border: "none",
    borderBottom: 1,
    borderColor: 'divider',
  },
  tabContentWrapper: {
    backgroundColor: theme.palette.background.white,
    borderRadius: "0 12px 12px 12px",
  },
  // appBar
  appBar:{
    height: "64px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    position: "relative",
    top: "-1rem",
    left: "10px",
  },
  backToHome: {
    cursor: "pointer",
    color: theme.palette.primary.primary100,
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: ".5rem",
    textDecoration: "none",
    marginLeft: ".5rem",
  },
  tabDisabled: {
    opacity: 0.75,
    background: "#E2E2EABF",
  },
}));

export default useStyles;
