import React, { useContext, useEffect, useState } from "react";
import ListLayout from "../../components/common/ListLayout";
import i18next from "i18next";
import EADataGrid from "../../components/common/datatable/EADataGrid";
import {
  getDocumentEvent,
  getDossierEvent,
} from "../../services/edge/EventsService";
import { Context } from "../../states/Store";
import { ERROR } from "../../services/common/Constants";
import { Tooltip } from "@mui/material";
import EventDialog from "./EventDialog";
import { getEventsDataTablesColumns } from "./EventsDataTableUtils";
import { useCallback } from "react";
import { buildEventPageRow } from "services/common/EventsUtils";

/** Rendu d'une ligne */
export const OneLineRenderCell = ({ value, isDate = false }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Tooltip title={value || ""}>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: !isDate ? "nowrap" : "",
            maxWidth: "100%",
            padding: "14px 0",
          }}
        >
          {value}
        </div>
      </Tooltip>
    </div>
  );
};

function Events() {
  const [state, dispatch] = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [sortModel, setSortModel] = React.useState([]);
  const [eventPopUpIsOpen, setEventPopUpIsOpen] = React.useState(false);
  const [event, setEvent] = React.useState({});

  const fetchDossierEvents = useCallback(async () => {
    try {
      const dossiersResult = await getDossierEvent(state.account.id, dispatch);
      return dossiersResult.content?.map(buildEventPageRow) || [];
    } catch (e) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: "INTERNAL_ERROR" },
      });
      return [];
    }
  }, [dispatch, state.account.id]);

  const fetchDocumentEvents = useCallback(async () => {
    try {
      const documentsResult = await getDocumentEvent(state.account.id, dispatch);
      return documentsResult.content?.map(buildEventPageRow) || [];
    } catch (e) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: "INTERNAL_ERROR" },
      });
      return [];
    }
  }, [dispatch, state.account.id]);

  useEffect(() => {
    async function fetchEvents () {
      setIsLoading(true);
      const dossiers = await fetchDossierEvents();
      const documents = await fetchDocumentEvents();
      setRows([
        ...dossiers,
        ...documents
      ].sort((a, b) => new Date(b.date) - new Date(a.date)))
      setIsLoading(false);
    }
    fetchEvents();
  }, [fetchDossierEvents, fetchDocumentEvents]);

  // Masque temporairement le bouton localize
  const hideLocalizeBtn = useCallback(() => {
    const localizeBtn = document.getElementById("localize-active-lang");
    if (localizeBtn) {
      localizeBtn.style.display = !eventPopUpIsOpen ? "none" : "block";
    }
  }, [eventPopUpIsOpen]);

  const handleAction = useCallback(
    (event) => {
      setEventPopUpIsOpen(true);
      setEvent(event.row.all);
      hideLocalizeBtn();
    },
    [hideLocalizeBtn]
  );

  // Initialise toutes les valeurs au montage du composant
  useEffect(() => {
    setColumns(getEventsDataTablesColumns(handleAction));
    setSortModel([{ field: "id", sort: "desc" }]);
  }, [handleAction]);

  const handleClose = () => {
    setEventPopUpIsOpen(false);
    hideLocalizeBtn();
  };

  return (
    <div className="dt">
      <ListLayout
        count={state.pagination?.count}
        totalCount={rows.length}
        icon="drawer/events_active"
        title={i18next.t(`events.events`)}
        isLoading={isLoading}
        customFilers={false}
      >
        <EADataGrid
          // rows & columns
          inputRows={rows}
          columns={columns}
          initialVisibilityModel={{ id: false }}
          // sort
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => {
            if (newSortModel[0] !== sortModel[0]) {
              setSortModel(newSortModel);
            }
          }}
          // pagination
          pageSize={50}
          hasCustomPagination
          // cellule de la taille du contenu
          getRowHeight={() => "auto"}
        />
      </ListLayout>

      {/* Pop-up Evenement */}
      {eventPopUpIsOpen && (
        <EventDialog
          isOpen={eventPopUpIsOpen}
          handleClose={() => handleClose()}
          event={event}
        />
      )}
    </div>
  );
}

export default Events;
