import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    [`${theme.breakpoints.up("sm")}`]: {
      display: "flex",
    },
  },
  newLabel: {
    backgroundColor: "rgba(242, 243, 247, 0.5)",
    color: theme.palette.grey.grey500,
    fontWeight: 700,
    border: 0,
    padding: "8px 16px",
    borderRadius: "16px",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
    lineHeight: "200%",
  },
}));

export default useStyles;
