import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  popupHeader:{
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  topHeaderIcon:{
    backgroundColor: theme.palette.grey.grey100,
    padding: "9px",
    borderRadius: "50%",
    marginBottom: "1rem",
    width: "55px",
    overflow: "visible",
  },
  popUpTitle: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Metric",
    color: theme.palette.grey.grey700,
    marginBottom: "16px",
  },
  infoSubInput: {
    color: theme.palette.grey.grey500,
    fontWeight: 400,
    fontSize: "10px",
    marginTop: "8px",
    marginBottom: "16px",
  },
  btnWrapper:{
    justifyContent: "center",
    gap: "2rem",
  },
  cancelBtn:{
    backgroundColor: theme.palette.grey.grey100,
    color: theme.palette.grey.grey600,
    "&:hover": { backgroundColor: theme.palette.grey.grey200, },
  },
  validBtn:{
    backgroundColor: theme.palette.primary.primary600,
    "&:hover": { backgroundColor: theme.palette.primary.primary700, },
    boxShadow: "0px 11.28px 24.231px rgba(45, 159, 64, 0.115017), 0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772), 0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08), 0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228), 0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833)"
  },
}));

export default useStyles;
