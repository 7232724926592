import React from "react";
import Tooltip from "@mui/material/Tooltip";

const EATooltip = ({children, title, color = "#535362"}) => {

  return (
    <Tooltip
      title={title}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: color
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}
export default EATooltip;
