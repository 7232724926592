import { makeStyles } from 'tss-react/mui';
import {errorColor} from "../../../../globalStyle";

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    [`${theme.breakpoints.up("sm")}`]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  listValuesContainer: {
    marginTop: "1rem",
    [`${theme.breakpoints.up("sm")}`]: {
      flexDirection: "column",
    },
    "& .valuesLabel": {
      marginBottom: "0.5rem",
    },
  },
  valuesLabel: {
    fontWeight: 700,
    color: theme.palette.grey.grey500,
    fontSize: "14px",
  },
  listValueLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "5px 0",

    "&:hover": {
      backgroundColor: theme.palette.grey.grey100,
    },
  },
  listValueLineFromGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0 68.5px",
    justifyContent: "center",
    position: "relative",
  },
  disableInput: {
    backgroundColor: "#F2F3F750",
    borderRadius: "16px",
    minHeight: "37px",

    "& .MuiOutlinedInput-root":{
      color: theme.palette.grey.grey500,
      fontWeight: 700,
      border: "1px solid #F2F3F750",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F2F3F750",
      },
    },
  },
  enableInput: {
    "& .MuiOutlinedInput-root":{
      color: theme.palette.grey.grey500,
      fontWeight: 700,
    },
  },
  inputTextValues: {
    width: "352px",
  },
  inputTextValuesWrapper: {
    backgroundColor: "white",
    borderRadius: "12px",
  },
  valuesWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "0 !important",
  },
  valuesIconBtn: {
    padding: 0,
    marginLeft: "0.5rem",
    alignSelf: "center",
    "& svg": {
      fontSize: "1.3rem",
    },
  },
  valuesIconBtnClosed:{
    marginLeft: "0.5rem",
    alignSelf: "center",
    "& svg": {
      fontSize: "1.3rem",
    },
  },
  checkIconBtn: {
    "& svg": {
      fontSize: "1.5rem",
    },
  },
  divider: {
    marginTop: "1rem",
  },
  inputBlocContainer: {
    width: "100%",
    "& .MuiFormGroup-root": {
      width: "100%",
    },
  },
  labelTypeBloc: {
    display: "flex",
    alignItems: "baseline",
  },
  addChoiceBtn: {
    padding: 0,
    marginTop: "1rem",
  },
  deleteAttributeCardWrapper:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  deleteAttributeCard: {
    display: "flex",
    alignItems: "center",
    minHeight: 37,
    padding: "0.4rem",
    lineHeight: "0.8rem",
    fontSize: "0.7rem",
    "& >span": {
      marginRight: "auto",
    },
  },
  cancelDeleteBtn: {
    padding: 0,
    marginLeft: "0.3rem",
    marginRight: "0.3rem",
    textTransform: "none",
    minWidth: "fit-content",
    "& .MuiButton-label": {
      fontSize: "0.6rem",
    },
  },
  confirmDeleteBtn: {
    padding: 0,
    margin: 0,
    marginLeft: "0.2rem",
    "& svg": {
      fontSize: "1rem",
    },
  },
  confirmBeforeValidationMsg: {
    marginBottom: 0,
    color: errorColor,
    textAlign: "center",
  },
  placeHolder: {
    color: "#9A9AAF",
    fontSize: "14px",
    fontWeight: 400
  },
}));

export default useStyles;
