import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "25px",
  },
  formWrapperFocused: {
    width: "100%",
    height: "150px",
    position: "relative",
    border: "1px solid #E2E2EA !important",
    borderRadius: "12px",
    transition: "all 0.3s",
  },
  formWrapper: {
    width: "100%",
    height: "40px",
    position: "relative",
    border: "1px solid #E2E2EA !important",
    borderRadius: "12px",
    transition: "all 0.3s",
  },
  mentionRoot: {
    "& .p-mention": {
      width: "100%",
      "& .p-inputtext:enabled:focus": {
        boxShadow: "none",
      },
      "& .p-inputtext": {
        border: "none",
      },
      "& .p-inputtextarea": {
        height: "40px !important",
        background: "transparent",
        lineHeight: "1",
        fontSize: "14px",
      },
      "& .p-mention-panel": {
        minWidth: "100px",
        "& .p-mention-items": {
          "& :hover":{
            backgroundColor: "#EFFBF1 !important",
            color: "#3FC855 !important",
          },
          "& .p-mention-item": {

          },
          "& .p-highlight":{
            backgroundColor: "transparent",
            color: "#656575",
            "& :hover":{
              backgroundColor: "#EFFBF1",
              color: "#3FC855",
            },
          },
        },
      },
    },
  },
  inputText: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "3px",
    minHeight: "30px",
    overflow: "hidden",
    border: "none",
    transition: "all 0.3s",
  },
  inputTextFocused: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "3px",
    minHeight: "100px",
    border: "none",
    transition: "all 0.3s",
  },
  error: {
    marginLeft: "50px"
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    gap: "15px",
    paddingRight: "10px",
    paddingBottom: "10px"
  },
  validButton: {
    width: "97px",
  },
  cancelButton: {
    width: "97px",
  },
  suggestionItemWrapper: {
    display: "flex",
    gap: "10px",
  },
  suggestionItemName: {
    color: "#656575",
    fontSize: "14px",
    fontWeight: 400,
  },
  // cancel note
  cancelNoteWrapper: {
      fontSize: "10px",
      marginTop: "5px"
  },
  cancelNoteText: {
    color: theme.palette.grey.grey400,
  },
  cancelNoteLink: {
    color: theme.palette.primary.primary600,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.primary700,
    }
  },
}));

export default useStyles;
