import { datadogRum } from '@datadog/browser-rum';
import { getEnv } from "config/Config";

let initOptions = {
  env: getEnv("REACT_APP_DD_ENV"),
  service: getEnv("REACT_APP_DD_SERVICE"),
  version: getEnv("REACT_APP_APP_VERSION"),
  applicationId: getEnv("REACT_APP_DD_APPLICATION_ID"),
  clientToken: getEnv("REACT_APP_DD_CLIENT_TOKEN"),
  sessionSampleRate: parseInt(getEnv("REACT_APP_DD_SESSION_SAMPLE_RATE")) || 100,
  sessionReplaySampleRate: parseInt(getEnv("REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE")) || 20,
}

export const initRum = () => {
  if(!initOptions.applicationId){
    return;
  }
  datadogRum.init({
      applicationId: initOptions.applicationId,
      clientToken: initOptions.clientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: initOptions.service,
      env: initOptions.env,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: initOptions.version, 
      sessionSampleRate: initOptions.sessionSampleRate ,
      sessionReplaySampleRate: initOptions.sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [getEnv("REACT_APP_EDGE")]
  });
}