import {Box, Grid, Paper, Tab, Tabs} from "@mui/material";
import React, {useCallback, useContext, useState} from "react";
import i18next from "i18next";
import EATabPanel from "../../../components/common/EATabPanel";
import useStyles from "../../../style/js-style/containers/administration/attributes/AttributesStyle";
import DossierModelTab from "./dossierModel/DossierModelTab";
import DocumentManagementTab from "./documentManagement/DocumentManagementTab";
import { useQuery } from "config/Config";
import ROUTES from "config/Routes";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Context } from "states/Store";

function Demandes() {
  const { classes } = useStyles();
  const [state] = useContext(Context);
  const [selectedTab, setSelectedTab] = useState(
    parseInt(useQuery().get("config")) || 0
  );
  const { accountId } = useParams();
  const history = useHistory();

  const getAccountId = useCallback(() => {
    return accountId || state.account?.id;
  }, [accountId, state.account]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    history.push(
      `/${getAccountId()}/${ROUTES.ADMIN}?tab=demandes&config=${newValue}`
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container justifyContent="space-evenly" spacing={0}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ marginTop: "17px" }}>

            {/* Tabs */}
            <Box className={classes.tabsWrapper} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="demandes tabs"
              >
                < Tab
                  label={i18next.t("adminSettings.demandesDossiers")}
                  {...a11yProps(0)}
                  className={classes.tab}
                  style={{position: "relative", top: "12px"}}
                  //disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE)}
                />
                <Tab
                  label={i18next.t("adminSettings.demandesDocuments")}
                  {...a11yProps(1)}
                  className={classes.tab}
                  style={{position: "relative", top: "12px"}}
                  //disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE)}
                />
              </Tabs>
            </Box>

            {/* Dossier Model tab */}
            <EATabPanel value={selectedTab} index={0} variant={"withoutBackground"}>
              <DossierModelTab/>
            </EATabPanel>

            {/* Documents tab */}
              <EATabPanel value={selectedTab} index={1} variant={"withoutBackground"}>
                <DocumentManagementTab/>
              </EATabPanel>

          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Demandes;
