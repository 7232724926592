import React from 'react';
import {Skeleton} from "@mui/material";
import useStyles from "../../../style/js-style/components/common/skeleton/SkeletonAttributesStyle";

const SkeletonAttributes = ({type}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root} style={{marginRight: type === "dossier" ? "0" : "0"}}>
      <div className={classes.contentWrapper}>
        <Skeleton variant="rect" width={"100%"} className={classes.cell} animation="wave"/>
        <Skeleton variant="rect" width={"100%"} className={classes.cell} animation="wave"/>
        <Skeleton variant="rect" width={"100%"} className={classes.cell} animation="wave"/>
        <Skeleton variant="rect" width={"100%"} className={classes.cell} animation="wave"/>
      </div>
    </div>
  );
}
export default SkeletonAttributes;
