import React from "react";
import clsx from "clsx";
import { Avatar } from "@mui/material";
import useStyles from "../../style/js-style/components/common/EAAvatarStyle";

function EAAvatar({ userName, variant = "default", size = 35, fontSize = "0.875rem", disabled = false, ...other}) {
  const { classes } = useStyles();

  function stringAvatar() {
    let name = userName;
    let initials = "";
    if (name.split(' ').length > 1) {
      initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } else {
      initials = `${name.split(' ')[0][0]}`;
    }
    return {
      sx: {
        width: size,
        height: size,
        fontSize: fontSize,
        fontWeight: 700,
      },
      children: initials,
    };
  }

  function chooseClassName(variant) {
    let resClass;
    if (variant === "admin") {
      resClass = clsx(classes.circleShapeAdmin, classes.userInitialsAdmin);
    } else if (variant === "display-note") {
      resClass = clsx(classes.circleShapeGrey, classes.userInitialsGrey);
    } else {
      resClass = clsx(classes.circleShape, classes.userInitials);
    }
    if(disabled) {
      resClass = clsx(resClass, classes.disabledAvatar)
    }
    return resClass;
  }

  return (
    <>
      {userName && (
        <div className="dt">
          <Avatar
            {...stringAvatar()}
            className={chooseClassName(variant)}
            style={{
              backgroundColor: variant === "default" ? "#B0E8B9" : variant === "admin" ? "white" : "",
              color: variant === "default" ? "#227730" : variant === "admin" ? "#227730" : "",
              opacity: variant === "default" ? 0.6 : "",
              cursor: variant === "default" || variant === "admin" ? "pointer" : ""
            }}
            {...other}
          />
        </div>
      )}
    </>
  );
}

export default EAAvatar;
