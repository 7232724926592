import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  FormLabel,
  MenuItem,
  FormGroup, Select
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import { isEmpty } from "../../../services/common/Utils";
import { ERROR } from "../../../services/common/Constants";
import { updateUserRole } from "../../../services/edge/UserService";
import useStyles from "../../../style/js-style/containers/users/users-list/UserEditDialogStyle";
import EADialog from "../../../components/common/EADialog";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import EAIcon from "../../../components/common/EAIcon";
import clsx from "clsx";
import AttributeMultipleSelectChips from "../../../components/AttributeMultipleSelectChips";

const InputBloc = ({ label, children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.formContainer}>
      <Grid item xs={12} md={12} className={classes.flexCenter}>
        <FormLabel>{label}</FormLabel>
        <FormGroup>{children}</FormGroup>
      </Grid>
    </div>
  );
};

const UserEditDialog = ({ isOpen, handleClose, handleOnEdit, user, shownRoles = [] }) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isEditOK, setIsEditOK] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const id = isOpen ? "filters-dialog" : undefined;
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: "onChange", delayError: 500 });

  useEffect(() => {
    if (user && shownRoles) {
      const values = shownRoles
        .filter(role => user.roles.includes(role.label))
        .map(role => role.value);

      setDefaultValue(values);
    }
  }, [user, shownRoles]);

  const handleEdition = async (data) => {
    if (isEmpty(errors)) {
      const userToEdit = {
        roleIds: data.roleIds,
      };
      try {
        await updateUserRole(state.account.id, user?.id, userToEdit, dispatch);
        setIsEditOK(true);
        handleOnEdit();
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "USER_CREATION_ERROR" },
        });
      }
    }
  };

  const handleDialogClose = () => {
    handleClose();
  };

  return (
    <EADialog
      id={id}
      isOpen={isOpen}
      onCancel={handleDialogClose}
      onValidate={handleSubmit(handleEdition)}
      hasActions={!isEditOK}
      maxWidth="popupxs"
      fullWidth
      isFeedBack={isEditOK}
    >
      <form>
        {/* After confirm */}
        {isEditOK && (
          <FeedBackPopUp
            variant="grey"
            onClick={handleDialogClose}
            title={i18next.t("usersTable.editionTitle")}
            imgSrc={"/img/icons/modify-role_grey_light.svg"}
            content={i18next.t("usersTable.editedInfo")}
          />
        )}

        {/* Before confirm */}
        {!isEditOK && shownRoles && defaultValue && (
          <>
            {/* Header Pop-up */}
            <Grid item xs={12} className={classes.flexCenter}>
              <div className={clsx(classes.mainContentHeaderPopUp, classes.flexCenter)}>
                <div className={classes.imgWrapper}>
                  <EAIcon icon={"modify-role_grey_light"} className={classes.imgContent} />
                </div>
                <div>
                  <span className={classes.userName}>
                    {
                      <span className="dt">{user.firstname && user.name}</span>
                        ? <span className="dt">{user.firstname} {user.name}</span>
                        : <>{i18next.t("invited")}</>
                    }
                  </span>
                  <br />
                  <span className={clsx('dt', classes.emailInfo)}>
                    {user.email}
                  </span>
                </div>
              </div>
            </Grid>

            {/* Main content Pop-up */}
            <Grid item xs={12}>
              <Typography
                variant="h5"
                className={classes.subTitle}
              >
                Modifier le rôle de l’utilisateur
              </Typography>
              <InputBloc className={classes.inputWrapper} >
                <Controller
                  control={control}
                  name="roleIds"
                  defaultValue={defaultValue}
                  rules={{
                    required: i18next.t("infos.required"),
                  }}
                  render={({ field }) => (
                    <Select
                      className={clsx('dt', classes.labelInputRole)}
                      id="user-role-id"
                      variant="outlined"
                      multiple
                      {...field}
                      error={!!errors.roleIds}
                      renderValue={(selected) => (
                        <AttributeMultipleSelectChips
                          key={selected}
                          selected={selected}
                          setValue={setValue}
                          shownRoles={shownRoles}
                        />
                      )}
                      SelectProps={{
                        className: classes.reasonSelect,
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          style: { zIndex: 1301 },
                        },
                      }}
                    >
                      {shownRoles.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          className={classes.menuItem}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <span className={classes.errorText}>
                  {!!errors.roleIds ? errors.roleIds.message : ""}
                </span>
              </InputBloc>
            </Grid>
          </>
        )}
      </form>
    </EADialog>
  );
};

export default UserEditDialog;
