import { FormGroup, FormHelperText, FormLabel, Tooltip, Typography } from "@mui/material";
import useStyles from "./SearchFormInputBlocStyle.js";

export const SearchFormInputBloc = ({
  label,
  error,
  children,
  hasTooltip = false,
  disableTranslation = false,
  ...others
}) => {
  const { classes } = useStyles();
  const labelNode = <FormLabel className={classes.label}>{label}</FormLabel>;
  return (
    <div {...others}>
      <div className={classes.inputBlocContainer}>
        {hasTooltip ? (
          <>
            <Tooltip title={label || ""}>
              <Typography
                className={disableTranslation ? "dt" : ""}
                style={{ fontSize: "0.875rem" }}
                noWrap
                component={"span"}
              >
                {labelNode}
              </Typography>
            </Tooltip>
          </>
        ) : (
          <>{labelNode}</>
        )}
        <FormGroup>
          {children}
          {error && (
            <FormHelperText className="Mui-error">
              {error.message}
            </FormHelperText>
          )}
        </FormGroup>
      </div>
    </div>
  );
};
