
import React, { useContext } from "react";
import i18next from "i18next";
import { useFormContext } from "react-hook-form";
import {
  Grid,
  Checkbox,
  FormControlLabel
} from "@mui/material";

import RuleSelectInput from "components/rules/RuleSelectInput";
import RuleStandardInput from "components/rules/RuleStandardInput";
import { RuleActionParameterNames, RulesContext } from "services/common/RulesUtils";


const DossierCreationParams = ({ fieldName }) => {
  const {
    dossierTypes,
  } = useContext(RulesContext);

  const formMethods = useFormContext();
  const {
    setValue,
    getValues
  } = formMethods;

  const MAX_LENGTH = 100;

  const validateDossierTypeCode = (value) => {
  }

  const isReferenceUnique = (reference) => {
    return !(getValues("actions")
      .filter((entry) => {
        return entry.parameters[`${RuleActionParameterNames.DOSSIER_REFERENCE}`]?.trim()?.toLowerCase() === reference?.trim()?.toLowerCase()
      })
      ?.length > 1);
  }

  const validateDossierReference = (value) => {
    let isValid = false;
    let typeErrMsg = "";

    if (value.length === 0) {
      typeErrMsg = i18next.t("infos.required");
    } else if (value.length > MAX_LENGTH) {
      typeErrMsg = i18next.t(
        "rules.configuration.ruleActions.fields.parameters.dossierReference.errors.maxLengthExceeded",
        {
          length: MAX_LENGTH
        }
      );
    } else if (!isReferenceUnique(value)) {
      typeErrMsg = i18next.t("rules.configuration.ruleActions.fields.parameters.dossierReference.errors.notUnique");
    } else {
      isValid = true;
    }

    if (isValid) {
      return null;
    } else {
      return typeErrMsg;
    }
  }

  return (
    <Grid container spacing={2} style={{ alignItems: "center", justifyContent: "center" }}>
      <Grid item xs={12} md={4}>
        <RuleSelectInput
          fieldName={`${fieldName}.${RuleActionParameterNames.DOSSIER_TYPE_CODE}`}
          label={`${i18next.t("rules.configuration.ruleActions.fields.parameters.dossierTypeCode.label")}`}
          placeholder={i18next.t(`rules.configuration.ruleActions.fields.parameters.dossierTypeCode.placeholder`)}
          options={dossierTypes}
          onChange={() => { }}
          isRequired
          validate={validateDossierTypeCode}
          labelVariant='chip'
        />
      </Grid >

      <Grid item xs={12} md={4}>
        <RuleStandardInput
          fieldName={`${fieldName}.${RuleActionParameterNames.DOSSIER_REFERENCE}`}
          label={`${i18next.t("rules.configuration.ruleActions.fields.parameters.dossierReference.label")}`}
          placeholder={i18next.t(`rules.configuration.ruleActions.fields.parameters.dossierReference.placeholder`)}
          onChange={() => { }}
          isRequired
          variant={"outlined"}
          validate={validateDossierReference}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControlLabel
          sx={{
            position: "relative",
            bottom: -14,
            '.MuiFormControlLabel-label': {
              fontSize: '14px',
              position: "relative",
              bottom: -1, left: -5
            }
          }}
          control={
            <Checkbox
              checked={`true` === getValues(`${fieldName}.${RuleActionParameterNames.DOSSIER_REACTIVATE_ARCHIVED}`)}
              onChange={(e) => {
                setValue(`${fieldName}.${RuleActionParameterNames.DOSSIER_REACTIVATE_ARCHIVED}`, `${e.target.checked}`)
              }}
            />
          }
          label={i18next.t(`rules.configuration.ruleActions.fields.parameters.reactivateDossier.label`)}
          onClick={(e) => e.stopPropagation()}
        />
      </Grid>
    </Grid >
  );
}

export default DossierCreationParams;
