import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formSectionSubTitle: {
    color: theme.palette.grey.grey400,
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
  },
  formSectionTitle: {
    color: "#373534",
    fontFamily: "Metric",
    fontWeight: 600,
    fontSize: "14px",
  },
  formConfigRuleName: {
    marginBottom: "36px"
  },
  feedBackButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "16px",
    marginTop: "43px",
  },
  configurationButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    gap: "20px",
  },
  recapButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "16px",
  },
  recapButtonWrapperEdit: {
    justifyContent: "space-between",
  },
  summaryEditLeftBtns: {
    marginLeft: "15px"
  },
  deleteRuleImg: {
    width: "12px",
    marginRight: "12px"
  },
  disabledDeleteBtn: {
    opacity: "30%",
    cursor: "default",
  },
  summaryEditRightBtns: {
    display: "flex",
    gap: "16px"
  },
  recapWrapper: {
    backgroundColor: theme.palette.grey.grey100,
    padding: "15px",
    margin: "0 0 23px 15px",
  },
  placeHolder: {
    color: theme.palette.grey.grey400,
    fontSize: "14px",
    fontWeight: 400,
    position: "relative",
    right: "12px",
  },
  inputText: {
    position: "relative",
    top: "3px",
    "& .MuiInputBase-root": {
      color: "#2E2E3A",
      fontSize: "14px",
    },
  },
  chipCrossIcon: {
    width: "7px",
    height: "7px",
  },
  valuesIconBtnClosed: {
    position: "relative",
    height: "20px",
    top: "12px"
  },
  select: {
    "& .MuiInput-input": {
      fontSize: "14px"
    },
  },
  summaryTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    display: "flex"
  },
  summaryListContainer: {
    marginLeft: "20px"
  },
  summaryListTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    margin: "6px 0",
    color: theme.palette.grey.grey500
  },
  summaryListItem: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    margin: "2px 0",
    color: theme.palette.grey.grey700
  },
  summaryBullet: {
    marginRight: "8px",
  },
  summaryRefItem: {
    color: "#FFF",
    background: theme.palette.grey.grey700,
    border: "1px solid",
    borderColor: theme.palette.grey.grey700,
    borderRadius: "4px",
    padding: "2px 5px",
    marginLeft: "5px"
  },
  summaryRefItemValue: {
    fontWeight: 600,
  },
  summaryListOperator: {
    color: theme.palette.grey.grey400
  },
  finalizeAlertBloc: {
    marginLeft: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "14px",
  },
  finalizeWarningColor: {
    color: theme.palette.warning.warning600
  },
  finalizeErrorColor: {
    color: theme.palette.error.error700
  },
  finalizeAlertWarning: {
    fontWeight: 700
  },
  blocOverlay: {
    position: "absolute",
    top: "54px",
    bottom: 0,
    left: 0,
    right: "-16px",
    zIndex: 2
  },
}));

export default useStyles;
