import React from "react";
import i18next from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormHelperText,
  TextField,
} from "@mui/material";
import { get as lodashGet } from "lodash";
import EAInputBloc from "../common/EAInputBloc";
import useStyles from "../../style/js-style/containers/administration/rules/RuleFormsStyle";

//variant: mui text field variant
const RuleStandardInput = ({
  fieldName,
  isRequired = false,
  placeholder = '',
  label = '',
  variant = 'standard',
  onChange = () => { },
  validate = () => { return null }
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
  } = formMethods;

  return (
    <EAInputBloc label={label} customLabelStyle={{ fontSize: "14px" }}>
      <Controller
        control={control}
        name={fieldName}
        rules={{
          validate: (value) => {
            if (isRequired) {
              const isEmpty = !value || value.length === 0;
              if (isEmpty) {
                return i18next.t("infos.required");
              } else {
                clearErrors(fieldName);
              }
            }

            return validate(value);
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            id={`${fieldName}-input-id`}
            variant={variant}
            fullWidth
            className={classes.inputText}
            error={!!lodashGet(errors, `${fieldName}`)}
            onChange={(e) => {
              formMethods.setValue(fieldName, e.target.value);
              onChange(e.target.value);
              trigger(fieldName);
            }}
            value={formMethods.getValues(fieldName)}
            placeholder={placeholder}
          />
        )}
      />

      {
        !!lodashGet(errors, `${fieldName}`) && (
          <FormHelperText className="Mui-error">
            {lodashGet(errors, `${fieldName}.message`)}
          </FormHelperText>
        )
      }
    </EAInputBloc>
  );
}

export default RuleStandardInput;
