import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useLayoutEffect } from "react";
import _ from "lodash";
import i18next from "i18next";
import { theme } from "style/globalStyle";
import EAIcon from "../EAIcon";

const EAFinancialStrengthLineChart = ({id, data}) => {

    useLayoutEffect(() => {
        const root = am5.Root.new(id);
        if (data && !_.isEmpty(data)) {

            let mostRecentData = data[data.length - 1];
            let firstData = data[0];
            const isOnlyOneYearData = mostRecentData.year - firstData.year === 0;

            root.setThemes([
                am5themes_Animated.new(root)
              ]);

            // Create chart
            let chart = root.container.children.push(am5xy.XYChart.new(root, {}));

             // X AXIS 

            let xRenderer = am5xy.AxisRendererX.new(root, { });
            //Custom CSS for axis X labels : 
            xRenderer.labels.template.setAll({
                visible: true,
                fontSize: 12,
                fill: "#7E7E8F"
            });

            let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "month", count: 1 },
                gridIntervals: [
                  isOnlyOneYearData ? { timeUnit: "month", count: 1 } : { timeUnit: "month", count: 12 },
                 // { timeUnit: "year", count: 1 }
                ],
                groupData: isOnlyOneYearData ? false : true,
                groupIntervals: [
                  { timeUnit: "day", count: 40 },
                ],
                markUnitChange: false,
                valueField: "date",
                renderer: xRenderer,
              }));

              //Only show year when interval is month :
              if(isOnlyOneYearData) {
                xAxis.get("dateFormats")["month"] = "MM/yyyy";
              } else {
                xAxis.get("dateFormats")["month"] = "yyyy";
              }
              
             // Y AXIS 

            let yRenderer = am5xy.AxisRendererY.new(root, {
              inversed: true,
            })

            yRenderer.grid.template.setAll({
            visible: true, 
            strokeWidth: 0.5, 
            strokeDasharray: [5]
            });
            yRenderer.labels.template.set('visible', false);

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
               
                maxDeviation: 1,
                min: 5, 
                max: 0, 
                dy: -10,
                renderer: yRenderer
              }));


            //On active le curseur sur le graphique, indispensable pour avoir les tooltips au survol
            chart.set("cursor", am5xy.XYCursor.new(root, {
            }));

            //Personnalisation du curseur
            let cursor = chart.get("cursor");

            cursor.lineY.setAll({
              visible: false
            });
            cursor.lineX.setAll({
              visible: false
            });
            //Personnalisation tooltip
            let tooltip = am5.Tooltip.new(root, {
              labelHTML: "<div style=\"font-weight: bold; text-align : center;color: white; font-size: 14px\">{text}</div><div style=\"color: white;text-align : center; font-size: 14px\">{date.formatDate('dd/MM/YYYY')}</div>", 
              getFillFromSprite: true, 
            });

            tooltip.get("background").setAll({
              fillOpacity: 1, 
            });

            // Add series
            let series = chart.series.push(am5xy.LineSeries.new(root, {
                name: "evolutionFinancialStrength",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "score",
                valueXField: "date",
                seriesTooltipTarget: "bullet",
                valueYGrouped: "low",
                sequencedInterpolation: true,
                stroke: am5.color("#7E7E8F"),
                maskBullets: false, 
                tooltip: tooltip, 
            })); 

            series.strokes.template.setAll({
              strokeWidth: 2, 
              shadowColor: am5.color("#7E7E8F"),
              shadowBlur: 10,
              shadowOffsetY: 4,
              shadowOpacity: 0.5
            });
            

            // Display bullet with a circle if it's the last dataItem
            // Empty external circle
             series.bullets.push(function (root, series, dataItem) {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                    strokeWidth: 3,
                    radius: 6,
                    stroke : dataItem?.dataContext?.color,
                    fill: am5.color(dataItem?.dataContext?.color),
                    shadowColor: am5.color("#7E7E8F"),
                    shadowBlur: 10,
                    shadowOffsetY: 4,
                    shadowOpacity: 0.3,
                    })
                });
                
            });

            // Set data
            xAxis.data.setAll(data);
            series.data.setAll(data);

            // Make stuff animate on load
            series.appear(1000);
            chart.appear(1000, 100);

            return () => root?.dispose();
        
        }

    }, [data, id]);


    return(
        <div
        style={{
          width: "100%",        
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: data?.length > 8 ? "flex-start" : "center",
            width: "100%",
            position: 'relative',
            padding: "10px",
            border: "1.5px solid #FFF",
            borderRadius: "12px",
            background: "linear-gradient(113deg, rgba(255, 255, 255, 0.57) 0%, rgba(255, 255, 255, 0.56) 110.84%)",
            boxShadow: "0px 12.522px 10.017px 0px rgba(212, 153, 102, 0.04), 0px 6.65px 5.32px 0px rgba(212, 153, 102, 0.03), 0px 2.767px 2.214px 0px rgba(212, 153, 102, 0.02)",
          }} 
        >
          <div style={{
            position:'absolute',
            top: "8px", 
            left: "11px",
            color: theme.palette.grey.grey500

          }}>
            {i18next.t("financialStrength.fiveYears")}
          </div>
          <div style={{ position:'absolute',  bottom: "8px", left: "11px" }}>
              <EAIcon icon={"amcharts5"} />
          </div>

          <div
            id={id}
            style={{ 
              width: "100%", 
              height: "100%",
            }}
            onWheel={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    )

}
export default EAFinancialStrengthLineChart;