import React, { useState } from "react";
import EADataGrid from "../../../../components/common/datatable/EADataGrid";
import { documentManagementListColumns } from "./DocumentManagementDataTableUtils";
import useStyles from "../../../../style/js-style/containers/administration/attributes/AttributesDataTableStyle";
import { CountSelectedRow } from "../../../../components/common/datatable/CountSelectedRow";
import DocumentManagementEditDialog from "./dialogs/DocumentManagementEditDialog";
import DocumentManagementDeleteDialog from "./dialogs/DocumentManagementDeleteDialog";
import { DocumentManagementActionMenu } from "./DocumentManagementActionMenu";
import i18next from "i18next";
import DocumentManagementAddDialog from "./dialogs/DocumentManagementAddDialog";
import TitleWithIcon from "components/common/TitleWithIcon";
import { Paper } from "@mui/material";
import EAButton from "components/common/EAButton";

function DocumentManagementDataTable({ authorizedDocumentsList, fetchAuthorizedDocumentsList }) {
  const [documentsSelectedIds, setDocumentsSelectedIds] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [filterRowCount, setFilterRowCount] = useState(0);
  const { classes } = useStyles();

  const openAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const openEditDialog = (docIdsList) => {
    setIsEditDialogOpen(true);
    setSelectedDocuments(
      authorizedDocumentsList.filter(
        (doc) => docIdsList.indexOf(doc.id) !== -1
      )
    );
  };

  const openDeleteDialog = (docIdsList) => {
    setIsDeleteDialogOpen(true);
    setSelectedDocuments(
      authorizedDocumentsList.filter(
        (doc) => docIdsList.indexOf(doc.id) !== -1
      )
    );
  };

  return (
    <>
      <div className={classes.headerWrapper}>
      <TitleWithIcon
        icon={"demandes/doc_management_green"}
        title={i18next.t("demandes.documentManagement.tableTitle")}
        resultCount={filterRowCount}
        totalCount={authorizedDocumentsList?.length}
        style={{marginLeft: "18px"}}
      />
      <EAButton
        content={i18next.t("demandes.documentManagement.addButton")}
        onClick={openAddDialog}
      />
      </div>
      <Paper elevation={0} className={classes.dataGridWrapper}>
        {/* DataGrid */}
        <EADataGrid
          loading={false}
          inputRows={authorizedDocumentsList}
          columns={
            documentManagementListColumns(
              openEditDialog,
              openDeleteDialog)
          }
          selection={{
            items: documentsSelectedIds,
            setItems: setDocumentsSelectedIds,
          }}
          onStateChange={(e) => {
            setFilterRowCount(e.pagination.rowCount);
          }}
          additionnalsLeftComponents={
            <>
              <CountSelectedRow data={documentsSelectedIds} />
              <DocumentManagementActionMenu
                documentsSelected={documentsSelectedIds}
                onOpenDeleteDialog={openDeleteDialog}
                onOpenEditDialog={openEditDialog} />
            </>
          }
          pageSize={10}
        />
      </Paper>

      {/* PopUp : manage documents */}
      {isAddDialogOpen && (
        <DocumentManagementAddDialog
          docList={authorizedDocumentsList}
          isOpen={isAddDialogOpen}
          onClose={() => setIsAddDialogOpen(false)}
          onValidateSuccess={() => {
            setIsAddDialogOpen(false);
            fetchAuthorizedDocumentsList();
          }}
        />
      )}

      {/* PopUp : on edit */}
      {isEditDialogOpen && (
        <DocumentManagementEditDialog
          docList={selectedDocuments}
          isOpen={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onValidateSuccess={() => {
            setIsEditDialogOpen(false);
            fetchAuthorizedDocumentsList();
          }}
        />
      )}

      {/* PopUp : on delete */}
      {isDeleteDialogOpen && (
        <DocumentManagementDeleteDialog
          docList={selectedDocuments}
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onValidateSuccess={() => {
            setIsDeleteDialogOpen(false);
            fetchAuthorizedDocumentsList();
          }}
        />
      )}
    </>
  );
}
export default DocumentManagementDataTable;
