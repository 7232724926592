import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
    position: 'relative',
  },
  title: {
    marginBottom: "22px",
  },
  card: {
    padding: "1.5rem",
    paddingRight: 0,
  },
  documents: {
    marginLeft: "-1rem",

    "& .MuiToolbar-root.MuiToolbar-regular": {
      "& > div:nth-child(3)": {
        display: "none",
      },
      "& > div:nth-child(4)": {
        paddingLeft: 0,
      },
    },
    "& .datatable > div > div:nth-child(2)": {
      "& .MuiTableRow-root.MuiTableRow-head > :nth-child(1)": {
        padding: "6px 8px",
      },
    },
  },
  divider: {
    borderTop: "1px solid #e2e2eb",
    position: 'absolute',
    width: "calc(100% + 48px)",
    left: "-24px"
  },
}));

export default useStyles;
