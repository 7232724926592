import React from "react";
import {Grid} from "@mui/material";
import ThirdpartiesDataTable from "./ThirdpartiesDataTable";

function Thirdparties({active}) {
  return <>
    <Grid container justifyContent="space-evenly" spacing={0}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ThirdpartiesDataTable active={active}/>
        </Grid>
      </Grid>
    </Grid>
  </>;
}

export default Thirdparties;
