import { Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import EAIcon from "../common/EAIcon";
import useStyles from "../../style/js-style/components/attributes/RecapBlocStyle";

const RecapBloc = ({ icon, extension, text, values, isMultiValue = false }) => {
  const { classes } = useStyles();

  return (
    <div style={{ display: "flex", maxWidth: "300px" }}>
      <div className={classes.blocIcon}>
        <EAIcon icon={icon} extension={extension} className={classes.icon}/>
      </div>
      <div>
        <Typography className={classes.blocTitle}>
          {text}
        </Typography>
        <Typography
          component="div"
          className={classes.blocValues}
        >
          {!isMultiValue
            ? (
              <>
                {values?.map((val, index) => (
                  <React.Fragment key={val.value}>
                    {val.value}
                    {values.length > index + 1 && ", "}
                  </React.Fragment>
                ))}
              </>
            )
            : (
              <>
                {values?.map((val) => (
                  <div key={val.id}>
                    <span className={classes.userValueDisplay}>{val.oldValue}</span>
                    {" "}
                    <EAIcon icon="arrow/arrow-to-right_large" />
                    {" "}
                    <span className={classes.userValueDisplay}>{val.newValue}</span>
                    {val.id !== "label" &&
                      <span className={classes.modified}>
                        {` (${i18next.t("modified")})`}
                      </span>
                    }
                  </div>
                ))}
              </>
            )
          }
        </Typography>
      </div>
    </div>
  );
};

export default RecapBloc;
