import {Chip, MenuItem, Select, Tooltip} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DefaultSelectProps } from "../../services/common/Constants";
import i18next from "i18next";
import useStyles from "../../style/js-style/components/attributes/AttributeMultipleSelectionInputStyle";
import clsx from "clsx";

const AttributeMultipleSelectChips = ({ selected, formMethods }) => {
  const { classes } = useStyles();
  if (!selected || selected.length === 0) {
    return <em>{i18next.t("notSpecified")}</em>;
  }

  const getChipClass = (index) => {
    return (
      selected.length === (index + 1)
        && selected.length % 2 !== 0
          ? classes.largeChip
          : classes.chip
    )
  }

  return (
    <div className={classes.chipsContainer}>
      {selected.map((value,index) => (
        <Tooltip title={value}>
          <Chip
            className={clsx('dt',getChipClass(index))}
            key={value}
            label={value}
            variant="outlined"
            size="small"
            deleteIcon={
              <img
                src={process.env.PUBLIC_URL + "/img/icons/cross.svg"}
                alt="close"
                onMouseDown={(event) => event.stopPropagation()}
                className={classes.deleteItemIcon}
              />
            }
            onDelete={(event) => {
              event.preventDefault();
              formMethods.setValue(
                "values",
                selected?.filter((x) => x !== value)
              );
            }}
          />
        </Tooltip>
      ))}
    </div>
  );
};

const AttributeMultipleSelectInput = ({ listItems }) => {
  const formMethods = useFormContext();
  return (
    <Controller
      control={formMethods.control}
      name="values"
      render={({ field }) => (
        <Select
          {...field}
          id="tp-attribute-multiselect-id"
          multiple
          displayEmpty
          onChange={(event) =>
            formMethods.setValue("values", event.target.value)
          }
          variant="outlined"
          MenuProps={DefaultSelectProps.MenuProps}
          renderValue={(selected) => (
            <AttributeMultipleSelectChips
              key={selected}
              selected={selected}
              formMethods={formMethods}
            />
          )}
        >
          {listItems?.map((item) => (
            <MenuItem className="dt" key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};
export default AttributeMultipleSelectInput;
