import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    boxStartImport: {
      borderRadius : "16px",
      background : "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%)," +
                     "linear-gradient(0deg, #EFFBF1 0%, #EFFBF1 100%)",
      margin: "18px 64px",
      padding: "14px",
      display: "flex",
      gap: "32px",
      "& > *": {
        minWidth: 0
      }
    },
    titleImportThirdParties : {
      fontSize:  "28px",
      fontWeight:  "600",
      fontFamily: "Metric",
      lineHeight: "38.40px,",
      wordWrap: "break-word",
    },
    subTitleImportThirdParties : {
      fontFamily: "Roboto",
      fontSize: "18",
      fontWeight: "400",
      lineHeight: "3",
      letterSpacing: "0.39998000860214233",
      color: theme.palette.grey.grey400
    },
    dialogSubTitleImportThirdParties : {
      color: theme.palette.grey.grey500,
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "8px"
    },
    importExplication: {
      display: "grid",
      gridTemplateColumns: "25px 1fr",
      rowGap: "8px",
      columnGap: "12px",
      fontSize: "12px",
      color: theme.palette.grey.grey500,
      marginTop: "16px"
    },
    iconExplication: {
      borderRadius: "8px",
      padding: "14px",
      backgroundColor: theme.palette.grey.grey200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "24px",
      height: "24px",
    },
    iconCustom : {
      padding: "2px",
      scale: 1.2
    },
    link: {
      fontSize: "12px",
      fontWeight: 500,
      textDecorationLine: "underline",
      color: theme.palette.primary.main
    },
    bold: {
      fontWeight: "bold",
      fontSize: "14px",
      color: theme.palette.black.dark
    },
    dropZoneText : {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    drapDrop : {
        color: theme.palette.primary.main
      },
    dropzoneSubText : {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 400,
    },
    iconDropzone : {
      display: "flex",
      width: "40px",
      height: "40px",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "28px",
      backgroundColor : theme.palette.grey.grey200,
    },
}));

export default useStyles;
