import React, { useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import i18next from "i18next";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { isEmpty } from "lodash";

import { Context } from "states/Store";
import { ROLE_NAME_MAX_LENGTH } from "services/common/RolesUtils";
import { ERROR } from "services/common/Constants";
import useStyles from "style/js-style/containers/administration/roles/dialogsStyle";
import RoleSelectInput from "components/roles//RoleSelectInput";
import RoleStandardInput from "components/roles/RoleStandardInput"
import EAButton from "components/common/EAButton";
import { isRoleNameAuthorized } from "services/edge/RoleService";


const getFormDefaultValues = (role) => {
  return {
    id: role?.id || "",
    name: role?.name || "",
    default: role?.default || false,
    contextKey: role?.contextKey || "",
    baseRoleId: role?.baseRoleId || null
  };
};

const RoleForm = ({ role, formId, submitHandler, onCancel, baseRoleCandidates = [] }) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: getFormDefaultValues(role),
  });

  const {
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods;

  const submitForm = (data) => {
    if (isEmpty(errors)) {
      submitHandler(data);
    }
  };

  const isValueAuthorized = async (value) => {
    try {
      const resp = await isRoleNameAuthorized(state.account.id, value, dispatch);

      return !!resp.content;
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
    return true;
  };

  const validateRoleName = AwesomeDebouncePromise(
    async (value, roleName) => {
      let isValid = false;
      let typeErrMsg = "";

      if (roleName && roleName === value) {
        isValid = true;
      } else {
        if (value.length === 0) {
          typeErrMsg = i18next.t("infos.required");
        } else if (value.length > ROLE_NAME_MAX_LENGTH) {
          typeErrMsg = i18next.t(
            "roles.popup.fields.roleName.errors.maxLengthExceeded",
            {
              length: ROLE_NAME_MAX_LENGTH,
            }
          );
        } else {
          let isRoleNameUnique = await isValueAuthorized(value);

          if (!isRoleNameUnique) {
            typeErrMsg = i18next.t(
              "roles.popup.fields.roleName.errors.notUnique"
            );
          } else {
            isValid = true;
          }
        }
      }
      // unauthorized substrings
      const unauthorizedRegex = /-->\s*\|/;
      const unauthorizedSubstring = "||";
      if(unauthorizedRegex.test(value) || value.includes(unauthorizedSubstring)){
        isValid = false;
        typeErrMsg = i18next.t(
          "roles.popup.fields.roleName.errors.unauthorizedSubstrings"
        );
      }

      if (isValid) {
        clearErrors(`name`);
      } else {
        setError(`name`, { type: "manual", message: typeErrMsg });
        return typeErrMsg;
      }
    },
    [500]
  );

  return (
    <FormProvider {...formMethods}>
      <form
        id={formId}
        onSubmit={formMethods.handleSubmit(submitForm)}
      >
        <div className={classes.formWrapper}>
          <RoleStandardInput
            fieldName={`name`}
            isRequired={true}
            label={i18next.t("roles.popup.label")}
            placeholder={i18next.t("roles.popup.labelRolePlaceholder")}
            validate={(value) => {
              /*TODO: custom validation if any*/
              return validateRoleName(value, (role !== undefined) ? role.name : null);
            }}
          />
        </div>

        {(role === undefined || !role.default) && (
          <div className={classes.formWrapper}>
            <RoleSelectInput
              fieldName={`baseRoleId`}
              label={i18next.t("roles.popup.parent")}
              placeholder={i18next.t("roles.popup.parentPlaceholder")}
              options={baseRoleCandidates}
              isRequired
              validate={() => {
                /*TODO: custom validation if any*/
                return null;
              }}
              labelVariant='standard'
            />
          </div>
        )}

        <div className={classes.btnWrapper}>
          <EAButton
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
            content={i18next.t("roles.popup.actions.cancel")}
            colorVariant="secondary"
          />

          <EAButton
            type={"submit"}
            disabled={!isEmpty(errors)}
            content={i18next.t("roles.popup.actions.validate")}
            colorVariant={"primary"}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default RoleForm;
