import React, { useContext, useState } from 'react';
import i18next from "i18next";
import EADialog from "components/common/EADialog";
import DossierModelDialogDocStep from "./DossierModelDialogDocStep";
import DossierModelDialogConfigStep from "./DossierModelDialogConfigStep";
import { updateDossierModel } from "services/edge/DossierModelService";
import { ERROR, SUCCESS, WARNING } from "services/common/Constants";
import { Context } from "states/Store";
import { FormProvider, useForm } from "react-hook-form";
import DossierModelDialogConfirmUpdate from './DossierModelDialogConfirmUpdate';
import { Trans } from 'react-i18next';

const DossierModelEditDialog = ({
  isOpen,
  dossierModel,
  onClose,
  onDeleteOpen,
  onValidateSuccess
}) => {
  const [state, dispatch] = useContext(Context);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [currentStep, setCurrentStep] = useState("INITIAL"); // INITIAL, DOCUMENTS
  const [data, setData] = useState(null);

  const cancelDialogHandler = () => {
    if (isFormValidated) {
      setIsFormValidated(false);
    } else {
      onClose();
    }
  };

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: {
      code: dossierModel.code,
      libelle: dossierModel.libelle,
      lock: "" + dossierModel.lock,
      active: dossierModel.active,
      id: "" + dossierModel.id
    }
  });

  const handleSubmit = async (data) => {
    try {
      await updateDossierModel(
        state.account.id,
        dossierModel.id,
        data,
        null
      );
      const hasChangedActive = dossierModel.active !== data.active;
      const label = data.libelle;
      if (hasChangedActive) {
        if (data.active === true) {
          const message = <Trans i18nKey="demandes.dossierModel.notifications.activateSuccess">Le modèle "<span className='dt' style={{ fontWeight: "bold" }}>{{ label }}</span>" a bien été activé.</Trans>;
          dispatch({
            type: "ALERT",
            alert: { type: SUCCESS, msg: message },
          });
        } else {
          const message = <Trans i18nKey="demandes.dossierModel.notifications.deactivateSuccess">Le modèle "<span className='dt' style={{ fontWeight: "bold" }}>{{ label }}</span>" a bien été désactivé.</Trans>;
          dispatch({
            type: "ALERT",
            alert: { type: WARNING, msg: message },
          });
        }
      } else {
        const message = <Trans i18nKey="demandes.dossierModel.notifications.saveSuccess">Le modèle "<span className='dt' style={{ fontWeight: "bold" }}>{{ label }}</span>" a bien été enregistré.</Trans>;
        dispatch({
          type: "ALERT",
          alert: { type: SUCCESS, msg: message },
        });
      }
      onValidateSuccess();
    } catch (error) {
      const err = error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      title={
        (currentStep === "INITIAL" && i18next.t("demandes.dossierModel.editDialog.title")) ||
        (currentStep === "DOCUMENTS" && i18next.t("demandes.dossierModel.editDialog.titleBis"))
      }
      onCancel={cancelDialogHandler}
      onClose={onClose}
      maxWidth={currentStep === "CONFIRM" ? "popupxs" : (currentStep === "DOCUMENTS" ? "popupxl" : "md")}
      variant="default"
      fullWidth
      hasActions={false}
    >
      <FormProvider {...formMethods}>
        <form>
          {/* Update model information */}
          {["INITIAL"].includes(currentStep) && dossierModel && (
            <DossierModelDialogConfigStep
              isCreationMode={false}
              onContinue={() => { setCurrentStep("DOCUMENTS"); }}
              onCancel={() => { cancelDialogHandler(); }}
            />
          )}

          {/* Documents selection */}
          {["DOCUMENTS"].includes(currentStep) && dossierModel && (
            <DossierModelDialogDocStep
              isCreationMode={false}
              dossierModel={dossierModel}
              onCancel={() => {
                setCurrentStep("INITIAL");
                formMethods.clearErrors();
              }}
              onDelete={onDeleteOpen}
              setData={setData}
              onContinue={() => { setCurrentStep("CONFIRM"); }}
            />
          )}

          {/* Confirm */}
          {["CONFIRM"].includes(currentStep) && dossierModel && (
            <DossierModelDialogConfirmUpdate
              onCancel={() => {
                setCurrentStep("DOCUMENTS");
                formMethods.clearErrors();
              }}
              onContinue={() => handleSubmit(data)}
              data={data}
            />
          )}
        </form>
      </FormProvider>
    </EADialog>
  );
};

export default DossierModelEditDialog;
