import React from "react";
import { Divider, Grid } from "@mui/material";
import { DossiersSearchThirdparty } from "./DossiersSearchThirdparty";
import { DossiersSearchAttributes } from "./DossiersSearchAttributes";
import useStyles from "../DossiersListSearchFormStyle";

export function DossiersAdvancedSearch({ attributes, lineIndex }) {
  const { classes } = useStyles();

  return (
    <Grid item container spacing={2} padding={"32px"}>
      <Grid item container spacing={2}>
        <DossiersSearchThirdparty lineIndex={lineIndex} />
      </Grid>
      {attributes?.length > 0 && (
        <>
          <Divider className={classes.advancedSearchDivider} />
          <Grid item container spacing={2} style={{ paddingTop: 0 }}>
            <DossiersSearchAttributes
              attributes={attributes}
              lineIndex={lineIndex}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
