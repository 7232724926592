export const formatFilterQuery = (query) => {
  const columnField = query?.get("columnField");
  const operatorValue = query?.get("operatorValue");
  const value = query?.get("value");

  let items = [];
  if (columnField && operatorValue && value && value !== "all") {
    items = [{ columnField, operatorValue, value }];
  }
  return {items};
};

export const sheetStyle = {
  "& .MuiDataGrid-main": {
    borderRadius: "0",
    boxShadow: "none",
  },
  "& .MuiDataGrid-columnHeadersInner": {
    background: "#F2F3F7",
    color: "#9A9AAF",
    fontSize: "12px",
    borderRadius: "0 !important",
  },
}
