import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: "5px",
        borderRadius: "12px !important",
        zIndex: 2,
        boxShadow: "rgba(17, 12, 31, 0.05) 0px 7px 38px 0px",
    },
    rightBlock: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    leftBlock: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        paddingLeft: "1.5rem !important",
        cursor: "pointer",
        "& h3": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }
    },
    details:{
        borderTop: "1px solid #8b8b8b1f",
        width: "100%",
        padding: "0 0 8px 0",
    },
    countDetail:{
        color: theme.palette.grey.grey300,
        fontSize: "0.8rem",
        fontWeight: 700,
        fontFamily: "Roboto",
    },
    separation: {
        color: theme.palette.grey.grey300,
        fontWeight: 700,
        margin: "0 0.5rem",
        fontFamily: "Roboto",
        position: "relative",
        top: "2px",
    },
    icon: {
      backgroundColor: theme.palette.primary.primary200,
      padding: "0.3rem",
      borderRadius: "6px",
      marginRight: "1rem",
    },
    divider: {
        marginTop: "16px",
        borderTop: ".5px solid " + theme.palette.grey.grey200,
        width: "calc(100% + 2.6rem)",
        position: "relative",
        left: "7px",
    },
}));

export default useStyles;
