import React, {useContext, useState} from "react";
import {
  Typography,
  Grid,
} from "@mui/material";
import i18next from "i18next";
import {Context} from "../../../states/Store";
import {ERROR} from "../../../services/common/Constants";
import {deleteAccountUsers} from "../../../services/edge/UserService";
import useStyles from "../../../style/js-style/containers/users/users-list/UserDeleteConfirmDialogStyle";
import EADialog from "../../../components/common/EADialog";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import {deleteTPContacts} from "../../../services/edge/ThirdpartyService";
import clsx from "clsx";
import EAIcon from "../../../components/common/EAIcon";
import useKeycloak from "components/common/hooks/useKeycloak";

const UserDeleteConfirmDialog = ({
     isOpen,
     usersIds,
     userEmail,
     handleClose,
     handleOnDelete,
     tpContact = false
  }) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isDeleteSend, setIsDeleteSend] = useState(false);
  const isManyUsersSelected = usersIds?.length > 1 || userEmail === undefined;
  const id = isOpen ? "filters-dialog" : undefined;
  const {logoutKeycloak} = useKeycloak();

  const handleDelete = async () => {
    if (tpContact){
      // Delete from thirdparties page
      try {
        await deleteTPContacts(state.account.id, usersIds, dispatch);
        setIsDeleteSend(true);
        handleOnDelete();
      } catch (error) {
        console.error(error);
        dispatch({ type: "ALERT", alert: { type: ERROR, msg: "USER_DELETE_ERROR" } });
      }
      // Delete from users page
    } else {
      try {
        await deleteAccountUsers(state.account.id, usersIds, dispatch);
        setIsDeleteSend(true);
        if (usersIds.includes(state.user.id)) {
          logoutKeycloak();
        } else {
          handleOnDelete();
        }
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: {type: ERROR, msg: "USER_DELETE_ERROR"},
        });
      }
    }

  };

  const handleDialogClose = () => {
    handleClose();
  };

  return (
    <EADialog
      id={id}
      isOpen={isOpen}
      onCancel={handleDialogClose}
      onValidate={handleDelete}
      hasActions={!isDeleteSend}
      maxWidth="popupxs"
      fullWidth
      variant={"redValid"}
      btnSize="large"
      isFeedBack={isDeleteSend}
      validateBtnLabel={tpContact ? "valid" : "validate"}
    >
        {/* Confirm delete : feedBack */}
        {isDeleteSend && (
          <FeedBackPopUp
            onClick={handleDialogClose}
            title={
              tpContact
                ? i18next.t("usersTable.deleteSingleTPTitle")
                : isManyUsersSelected
                  ? i18next.t("usersTable.deleteMultiTitle")
                  : i18next.t("usersTable.deleteSingleTitle")
            }
            imgSrc={"/img/icons/delete_red.svg"}
            imgSize={"small"}
            variant={"warning"}
            content={
              tpContact
                ? i18next.t("contactsTable.deletedSingleTPInfo")
                : isManyUsersSelected
                  ? i18next.t("usersTable.deletedMultiInfo")
                  : i18next.t("usersTable.deletedSingleInfo")
            }
          />
        )}

        {/* Before confirm delete */}
        {!isDeleteSend && (
            <Grid item xs={12}>
              <Typography variant="body1" className={isManyUsersSelected ? classes.dialogText : classes.dialogTextSingle}>

                  <div className={classes.deleteAllWrapper}>
                    <span className={isManyUsersSelected ? clsx(classes.imgWrapperMulti, classes.imgWrapper) : clsx(classes.imgWrapper, classes.imgWrapperSingle)}>
                      <EAIcon
                        icon={"delete_red"}
                        className={isManyUsersSelected
                          ? classes.imgContentMulti
                          : classes.imgContent}
                      />
                    </span>
                    <span className={classes.deleteInfo}>
                      {isManyUsersSelected ?
                        <span className={classes.title}>
                          {i18next.t("usersTable.deleteMultiConfirm")}
                        </span>
                        :
                        <>
                          <span className={classes.title}>
                            {
                              tpContact
                                ? i18next.t("contactsTable.deleteSingleConfirm")
                                : i18next.t("usersTable.deleteSingleConfirm")
                            }
                          </span>
                          <div className={clsx('dt', classes.mailInfoWrapper)}>{userEmail}</div>
                        </>
                      }
                    </span>
                  </div>
              </Typography>
            </Grid>
        )}
    </EADialog>
  );
};

export default UserDeleteConfirmDialog;
