import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  rulesPanel: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.492) 0%, rgba(255, 255, 255, 0.48) 110.84%)",
    paddingLeft: "24px",
    paddingRight: "17px",
    width: "200px",
  },
  rulesPanelHeader: {
    height: "79px",
    marginBottom: "10px",
  },
  rulesPanelMainContent: {
    flexGrow: 1,
  },

  //tab
  tabsWrapper: {
    "& .MuiTabs-root": {
      "& .MuiTabs-scroller": {
        "& .MuiTabs-indicator": {
          backgroundColor: "#2D9F40",
        },
      },
    },
  },
  tab: {
    textTransform: "none",
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",

    "& .MuiTab-iconWrapper": {
      marginRight: "3px",
      position: "relative",
      top: "-1px"
    },
  },
  saveButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 12px",
    width: "32px",
    height: "32px",
    background: "#2D9F40",
    boxShadow:
      "0px 11.28px 24.231px rgba(45, 159, 64, 0.115017), 0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772), 0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08), 0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228), 0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833)",
    borderRadius: "12px",
    position: "absolute",
    top: "24px",
    right: "24px",
    cursor: "pointer",
    zIndex: 2,

    "&:hover": {
      background: "#118c28",
    }
  },
  scopeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    marginBottom: "10px",
  },
}));

export default useStyles;
