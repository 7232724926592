import { FormGroup, FormLabel } from "@mui/material";
import React from "react";
import useStyles from "../../style/js-style/components/attributes/AttributeFormInputBlocStyle";

const AttributeFormInputBloc = ({ label, children, ...others }) => {
  const { classes } = useStyles();
  return (
    <div {...others}>
      <div className={classes.inputBlocContainer}>
        <FormLabel className={classes.label}>
          {label}
        </FormLabel>
        <FormGroup>{children}</FormGroup>
      </div>
    </div>
  );
};

export default AttributeFormInputBloc;
