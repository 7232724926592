import React from "react";
import { Box, Chip, Tooltip } from "@mui/material";
import i18next from "i18next";
import EAIcon from "./EAIcon";

const InscriptionStatusChip = ({ registrationStatus }) => {
    return (
        <Box display="flex">
            {registrationStatus === "INSCRIT" && (
                <Tooltip title={i18next.t(`thirdpartiesTable.status.${registrationStatus}.tooltip`)} arrow>
                    <Chip
                        icon={<EAIcon icon="registered_green" />}
                        size="small"
                        label={i18next.t(`thirdpartiesTable.status.${registrationStatus}.label`)}
                        color="primary"
                        variant="outlined"
                        sx={{
                          padding: "4px 8px",
                          backgroundColor : "#D7F4DC",
                          color : "#2D9F40",
                          fontWeight: 600,
                          fontSize: "10px",
                          borderRadius: "6px",
                          border: "none",
                        }}
                    />
                </Tooltip>
            )}
            {(registrationStatus === "INVIATION EXPIREE") && (
                <Tooltip title={i18next.t(`thirdpartiesTable.status.${registrationStatus}.tooltip`)} arrow>
                    <Chip
                        className="errorChip"
                        icon={<EAIcon icon="warning" />}
                        size="small"
                        label={i18next.t(`thirdpartiesTable.status.${registrationStatus}.label`)}
                        variant="outlined"
                        sx={{
                            padding: "4px 8px",
                            backgroundColor : "#FFEBEB",
                            color : "#CC0001",
                            fontWeight: 600,
                            fontSize: "10px",
                            borderRadius: "6px",
                            border: "none",
                        }}
                    />
                </Tooltip>
            )}
            {(registrationStatus === "INVITE") && (

                <Tooltip title={i18next.t(`thirdpartiesTable.status.${registrationStatus}.tooltip`)} arrow>
                    <Chip
                        className="infoChip"
                        icon={<EAIcon icon="registered_blue" />}
                        size="small"
                        label={i18next.t(`thirdpartiesTable.status.${registrationStatus}.label`)}
                        variant="outlined"
                        sx={{
                            padding: "4px 8px",
                            backgroundColor : "#CCDEFF",
                            color : "#0047CC",
                            fontWeight: 600,
                            fontSize: "10px",
                            borderRadius: "6px",
                            border: "none",
                        }}
                    />
                </Tooltip>
            )}
            {(registrationStatus === "SANS CONTACT") && (
                <Tooltip title={i18next.t(`thirdpartiesTable.status.${registrationStatus}.tooltip`)} arrow>
                    <Chip
                        className="greyChip"
                        icon={<EAIcon icon="help-rounded" />}
                        size="small"
                        label={i18next.t(`thirdpartiesTable.status.${registrationStatus}.label`)}
                        variant="outlined"
                        sx={{
                            padding: "4px 8px",
                            backgroundColor : "#F2F3F7",
                            color : "#656575",
                            fontWeight: 600,
                            fontSize: "10px",
                            borderRadius: "6px",
                            border: "none",
                        }}
                    />
                </Tooltip>
            )}
        </Box>
    )
}
export default InscriptionStatusChip;
