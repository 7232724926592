import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ContactsDefaultVisibilityColumns,
  contactsTableColumns,
  getInitialContactsTableSort,
} from "./ContactsTableUtils";
import EADataGrid from "../../../components/common/datatable/EADataGrid";
import UserDeleteConfirmDialog from "../../users/users-list/UserDeleteConfirmDialog";
import UserReinviteConfirmDialog from "../../users/users-list/UserReinviteConfirmDialog";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";


function ContactsDataTable({
  contacts,
  loader,
  onContactsDeleted,
  pageSize,
  multiDataGridPage,
  sheetVariant= false,
  onVisibleRowsCountChange = () => {},
}) {
  const [isDeleteContactDialogOpen, setIsDeleteContactDialogOpen] = useState(
    false
  );
  const [
    isReinviteContactDialogOpen,
    setIsReinviteContactDialogOpen,
  ] = useState(false);
  const [selectedContactEmail, setSelectedContactEmail] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contactsColumns, setContactsColumns] = useState();
  const [columnsVisibility, setColumnsVisibility] = useState();
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  // const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [sortModel, setSortModel] = useState([]);

  const setContact = useCallback(
    (contactsIds, email) => {
      setSelectedContactEmail(email);
      // setSelectedContactsIds(contactsIds);
      setSelectedContacts(
        contacts
          .filter((contact) => contactsIds.includes(contact.id))
          .map((contact) => {
            return { userId: contact.userId, tpAccountId: contact.tpaccountid };
          })
      );
    },
    [contacts]
  );

  const openDeleteContactsPopup = useCallback(
    (contactsIds, email) => {
      setIsDeleteContactDialogOpen(true);
      setContact(contactsIds, email);
    },
    [setContact]
  );

  const openReinviteContactsPopup = useCallback(
    (contactsIds, email) => {
      setIsReinviteContactDialogOpen(true);
      setContact(contactsIds, email);
    },
    [setContact]
  );

  useEffect(() => {
    setContactsColumns(
      contactsTableColumns(
        openDeleteContactsPopup,
        openReinviteContactsPopup,
        hasAnyOfPrivileges
      )
    );
  }, [
    openDeleteContactsPopup,
    openReinviteContactsPopup,
    hasAnyOfPrivileges
  ]);

  useEffect(() => {
    setColumnsVisibility(ContactsDefaultVisibilityColumns());
    setSortModel(getInitialContactsTableSort());
  }, []);

  return (
    <>
      {contactsColumns && (
        <EADataGrid
          loading={loader}
          customSx={{
            "& .MuiDataGrid-columnHeader:nth-child(1), & .MuiDataGrid-columnHeader:nth-child(2)": {
              color: "#7E7E8F",
            },
          }}
          hasCustomPagination={!multiDataGridPage}
          inputRows={contacts}
          columns={contactsColumns}
          initialVisibilityModel={columnsVisibility}
          selection={false}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => {
            if (newSortModel[0] !== sortModel[0]) {
              setSortModel(newSortModel);
            }
          }}
          isRowSelectableMethod={(params) => params.row.status !== "INSCRIT"}
          clickable={true}
          pageSize={pageSize}
          variant={sheetVariant ? "sheet" : "default"}
          onVisibleRowsCountChange={onVisibleRowsCountChange}
        />
      )}

      {isDeleteContactDialogOpen && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_CONTACT) && (
        <UserDeleteConfirmDialog
          usersIds={selectedContacts}
          userEmail={selectedContactEmail}
          isOpen={selectedContactEmail}
          handleClose={() => setIsDeleteContactDialogOpen(false)}
          handleOnDelete={onContactsDeleted}
          tpContact={true}
        />
      )}

      {isReinviteContactDialogOpen && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_CONTACT) && (
        <UserReinviteConfirmDialog
          userId={selectedContacts}
          userEmail={selectedContactEmail}
          isOpen={isReinviteContactDialogOpen}
          handleClose={() => setIsReinviteContactDialogOpen(false)}
          tpContact={true}
        />
      )}
    </>
  );
}
export default ContactsDataTable;
