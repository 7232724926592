import React from 'react';
import {Skeleton} from "@mui/material";
import useStyles from "../../../style/js-style/components/common/skeleton/SkeletonDataGridStyle";
const CellSkeleton = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.headerCell}>
      <Skeleton
        variant="rect"
        width={"100%"}
        className={classes.cell}
      />
    </div>
  )
}

const HeaderSkeleton = ({column}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.headerWrapper}>
      {[...Array(column)].map((el, i) => <CellSkeleton key={i}/>)}
    </div>
  )
}
const LineSkeleton = ({column}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.mainLine}>
      {[...Array(column)].map((el, i) => <CellSkeleton key={i}/>)}
    </div>
  )
}
const BodySkeleton = ({column, row}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.mainContentWrapper}>
      {[...Array(row)].map((el, i) => <LineSkeleton key={i} column={column}/>)}
    </div>
  )
}
const GridSkeleton = ({mainStyle, column, row}) => {
  const { classes } = useStyles();
  return (
    <div
      className={classes.gridContentWrapper}
      style={mainStyle}
    >
      <HeaderSkeleton column={column}/>
      <BodySkeleton column={column} row={row}/>
    </div>
  )
}
const SkeletonDataGrid = ({mainStyle, column = 5, row = 30}) => {
  const { classes } = useStyles();
  return (
    <div style={{overflow: "hidden"}}>
      <div className={classes.mt} />
      <div className={classes.toolBarWrapper} >
        <Skeleton
          variant="rect"
          className={classes.toolBarItem}
          animation="wave"
        />
      </div>
      <div className={classes.mt} />
      <GridSkeleton mainStyle={mainStyle} column={column} row={row}/>
    </div>
  );
}
export default SkeletonDataGrid;
