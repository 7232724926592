import React, { useCallback, useContext } from "react";
import i18next from "i18next";
import { Typography } from "@mui/material";

import EADialog from "components/common/EADialog";
import { ERROR } from "services/common/Constants";
import EAIcon from "components/common/EAIcon";
import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import { Context } from "states/Store";
import { deleteCalculation } from "services/edge/CalculatorService";
import {useRequestLoading} from "../../../../components/common/hooks/useRequestLoading";

function CalculatorDeleteDialog({
  calculation,
  isOpen,
  onClose,
  onValidateSuccess,
}) {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);

  const handleDelete = useCallback(async () => {
    try {
      startLoading();
      await deleteCalculation(state.account.id, calculation.id, dispatch);

      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: i18next.t("calculator.notifications.deleteConfirm") },
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DELETE_ERROR" } });
    }finally {
      stopLoading();
    }
  }, [state.account, calculation, dispatch, onValidateSuccess, startLoading, stopLoading]);

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={handleDelete}
      hasActions={true}
      maxWidth="popupxs"
      fullWidth
      variant="redValid"
      btnSize="large"
      isValidateDisabled={isRequestLoading}
    >
      <div className={classes.deleteRoot}>

        {/* Title */}
        <div className={classes.imgWrapper}>
          <EAIcon icon={"delete_red"} className={classes.imgContent} />
        </div>
        <span className={classes.bolder}>
          {i18next.t("calculator.dialog.delete.title", { status: i18next.t(`calculator.table.status.${calculation.enabled}`) })}
        </span>

        {(calculation?.enabled) && (
          <>
            < span className={classes.subtitle}>
              {i18next.t("calculator.dialog.delete.subTitle")}
            </span>
          </>
        )}
      </div>

      {/* Content */}
      <div key={calculation.id}>
        <div className={classes.calculInfoBlocWrapper}>
          <Typography className={classes.calculInfoBlocContent}>
            {calculation.name}
          </Typography>
        </div>
      </div>
    </EADialog>
  )
}

export default CalculatorDeleteDialog;
