import {
  Autocomplete,
  Chip,
  FormHelperText,
  TextField,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import i18next from "i18next";
import { get as lodashGet } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import EAIcon from "components/common/EAIcon";
import useStyles from "../../style/js-style/containers/administration/rules/RuleFormsStyle";
import { useEffect } from "react";
import { useState } from "react";
import { theme } from "style/globalStyle";

const filterOptions = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: "any",
  limit: 300,
});

const EAAutoCompleteInput = ({
  fieldName,
  options,
  placeholder,
  isRequired,
  multiple,
  variant = "standard",
  limitTags,
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();

  const getValueFromField = (values) => {
    if (multiple) {
      if (!values) return [];
      if (!Array.isArray(values)) return [];
      return values.map((value) => {
        const result = options.find((val) => val.value === value);
        return result === undefined ? null : result;
      });
    } else {
      if (values === null) return null;
      const result = options.find((val) => val.value === values);
      return result === undefined ? null : result;
    }
  };

  return (
    <div>
      {options && (
        <Controller
          control={formMethods.control}
          name={fieldName}
          defaultValue={multiple ? [] : null}
          rules={{
            validate: (value) => {
              if (isRequired) {
                const isEmpty =
                  value === null || value === undefined || value.length === 0;
                if (isEmpty) {
                  return i18next.t("infos.required");
                } else {
                  formMethods.clearErrors(fieldName);
                }
              }
            },
          }}
          render={({ field }) => (
            <Autocomplete
              id={`${fieldName}-autocomplete-id`}
              {...field}
              value={getValueFromField(field.value)}
              multiple={multiple}
              autoComplete
              options={options}
              clearOnBlur={true}
              noOptionsText={i18next.t(`tableLabels.noMatch`)}
              openText={i18next.t(`open`)}
              clearText={i18next.t(`tableLabels.clear`)}
              clearIcon={multiple ? null : undefined}
              disableClearable={multiple}
              closeText={i18next.t(`cancel`)}
              filterOptions={filterOptions}
              autoHighlight
              className={classes.autocompleteWrapper}
              sx={{
                "& .MuiFormControl-root": {
                  "& .MuiAutocomplete-inputRoot": {
                    flexWrap: "no-wrap !important",
                  },
                },
              }}
              isOptionEqualToValue={(option, value) => {
                return value ? option.value === value.value : false;
              }}
              onChange={(event, target) => {
                const value = !target
                  ? target
                  : multiple
                  ? target.map((obj) => {
                      return obj.value;
                    })
                  : target.value;
                formMethods.setValue(fieldName, value);
                formMethods.trigger(fieldName);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={variant}
                  error={
                    !!lodashGet(formMethods.formState.errors, `${fieldName}`)
                  }
                  placeholder={placeholder}
                  className={classes.textfield}
                  sx={{
                    input: {
                      "&::placeholder": {
                        color: theme.palette.grey.grey400 + " !important",
                        fontSize: "14px !important",
                        fontWeight: 400 + " !important",
                        fontStyle: "normal !important"
                      }
                    }
                  }}
                />
              )}
              renderTags={(values, getTagProps) => (
                <EAAutoCompleteTags
                  values={values}
                  onDelete={(index) => getTagProps({ index }).onDelete()}
                  limitTags={limitTags}
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.label, matches);
                return (
                  <li {...props}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
            />
          )}
        />
      )}
      {!!lodashGet(formMethods.formState.errors, `${fieldName}`) && (
        <FormHelperText className="Mui-error">
          {lodashGet(formMethods.formState.errors, `${fieldName}.message`)}
        </FormHelperText>
      )}
    </div>
  );
};

const EAAutoCompleteTags = ({
  values = [],
  onDelete = () => {},
  limitTags,
  // add multiple ?
}) => {
  const { classes } = useStyles();
  const [displayedTags, setDisplayedTags] = useState();
  const [hiddenTags, setHiddenTags] = useState();
  const [hiddenCounter, setHiddenCounter] = useState();

  useEffect(() => {
    if (limitTags) {
      const tagsToDisplay = values?.slice(0, limitTags);
      setDisplayedTags(tagsToDisplay);
      setHiddenTags(values?.slice(limitTags, values.length));
      setHiddenCounter(values?.length - tagsToDisplay?.length);
    } else {
      setDisplayedTags(values);
    }
  }, [values, limitTags]);

  return (
    <div className={classes.tagsWrapper}>
      {hiddenCounter > 0 && (
        <Tooltip
          title={
            <ul style={{paddingLeft: "12px", margin: "4px"}}>
              {hiddenTags?.map((tag) => (
                <li key={tag.label}>{tag.label}</li>
              ))}
            </ul>
          }
        >
          <div className={classes.tagsCounter}>+{hiddenCounter}</div>
        </Tooltip>
      )}
      {displayedTags?.map((objValue, index) => {
        return (
          // multiple &&
          objValue && (
            <Tooltip key={index} title={objValue.label}>
              <Chip
                key={"autocomplete-" + index}
                label={objValue.label}
                value={objValue.value}
                onDelete={() => onDelete(index)}
                deleteIcon={
                  <EAIcon icon="cross" className={classes.chipCrossIcon} />
                }
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#F2F3F7",
                  color: "#535362",
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: 500,
                  height: "auto",
                  border: 0,
                  maxWidth: "200px",
                  margin: "7px 4px",
                  "& .MuiChip-label": {
                    paddingLeft: "4px",
                    paddingRight: "12px",
                  },
                }}
              />
            </Tooltip>
          )
        );
      })}
    </div>
  );
};

export default EAAutoCompleteInput;
