import { Tooltip } from "@mui/material";
import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AttributeBooleanInput from "../../components/attributes/AttributeBooleanInput";
import AttributeFormInputBloc from "../../components/attributes/AttributeFormInputBloc";
import AttributeMultipleSelectInput from "../../components/attributes/AttributeMultipleSelectInput";
import AttributeTextFieldInput from "../../components/attributes/AttributeTextFieldInput";
import AttributeUniqueSelectInput from "../../components/attributes/AttributeUniqueSelectInput";
import { AttributeType, ERROR } from "../../services/common/Constants";
import { hasAttributeListItems } from "../../services/common/AttributeUtils";
import { getAccountAttributeByLabelAndBondType } from "../../services/edge/AttributesService";
import { Context } from "../../states/Store";
import useStyles from "../../style/js-style/containers/attribute-values/AttributeValueFormStyle";
import clsx from "clsx";
import AttributeDateInput from "components/attributes/AttributeDateInput";

const getFormDefaultValues = (attributeValue) => {
  const result = {
    label: attributeValue.label,
  };
  if (attributeValue.type === AttributeType.LIST_MULTIPLE) {
    result.values = attributeValue.values || [];
  } else {
    result.value = attributeValue.values?.length > 0 ? attributeValue.values[0] : null;
  }
  return result;
};

const AttributeValueForm = ({ formId, bondType, attributeValue, submitHandler, onError = () => {} }) => {
  const [state, dispatch] = useContext(Context);
  const [attributeListItems, setAttributeListItems] = useState(null);
  const { classes } = useStyles();

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: getFormDefaultValues(attributeValue),
  });

  useEffect(() => {
    async function fetchAttributeByLabel() {
      try {
        const result = await getAccountAttributeByLabelAndBondType(
          state.account.id,
          attributeValue.label,
          bondType,
          null
        );
        const listItems = result.content?.values?.map(x => x.value)?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        setAttributeListItems(listItems);
      } catch (e) {
        dispatch({
          type: "ALERT",
          alert: {
            type: ERROR,
            msg:
              e?.message?.error && e?.message?.error[0].code === "NOT_FOUND"
                ? "ATTRIBUTE_NOT_FOUND"
                : "INTERNAL_ERROR",
          },
        });
      }
    }
    if (hasAttributeListItems(attributeValue.type)) {
      fetchAttributeByLabel();
    } else {
      setAttributeListItems(null);
    }
  }, [state.account, attributeValue, bondType, dispatch]);

  const renderAttributeValueInput = (attributeValue) => {
    switch (attributeValue.type) {
      case AttributeType.LIST_UNIQUE:
        return <AttributeUniqueSelectInput listItems={attributeListItems} />;
      case AttributeType.LIST_MULTIPLE:
        return <AttributeMultipleSelectInput listItems={attributeListItems} />;
      case AttributeType.BOOLEAN:
        return <AttributeBooleanInput />;
      case AttributeType.DATE:
        return <AttributeDateInput onError={onError}/>;
      default:
        return <AttributeTextFieldInput type={attributeValue.type} />;
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id={formId}
        onSubmit={formMethods.handleSubmit(submitHandler)}
        className={classes.formContainer}
      >
        <AttributeFormInputBloc label={`${i18next.t("label")}`} >
          <Tooltip title={attributeValue.label}>
            <div className={clsx('dt', classes.newLabel)}>
              {attributeValue.label}
            </div>
          </Tooltip>
        </AttributeFormInputBloc>

        <AttributeFormInputBloc label={`${i18next.t("values")}`}>
          {renderAttributeValueInput(attributeValue)}
        </AttributeFormInputBloc>
      </form>
    </FormProvider>
  );
};

export default AttributeValueForm;
