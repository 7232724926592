import React, { useEffect, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import i18next from "i18next";
import { formatDate } from "../../services/common/Utils";
import { darkGrey, errorColor, infoColor, orangeColor, primary } from "../../style/globalStyle";
import DocViewerCard from "../../components/doc-viewer/DocViewerCard";
import DocViewerTable from "../../components/doc-viewer/DocViewerTable";
import { INDUED, SIS_ID, TRUSTPAIR } from "../../services/common/Constants";
import {EXCLUDED_TEXTS} from '../../i18n/LocalizeUtils'
import useStyles from "../../style/js-style/containers/doc-viewer/PanelAdditionalChecksStyle";
import {induedScoreSeverity} from "../../services/common/InduedUtils"

const SisIdScoreLabel = ({score}) => {
    let label;
    let color;
    if (score === 0) {
        label = "atRisk";
        color = errorColor;
    } else if (score <= 20) {
        label = "low";
        color = errorColor;
    } else if (score >= 90) {
        label = "important";
        color = primary;
    } else {
        label = "medium";
        color = orangeColor;
    }
    return (
        <Tooltip title={i18next.t(label)} arrow>
            <Typography variant="body2" component="span" style={{color, fontWeight: 600, fontSize: "12px"}}>
                {i18next.t(label)}
            </Typography>
        </Tooltip>
    )
}

const TrustpairState = ({state}) => {
    let color;
    switch(state) {
        case 'favorable':
            color = primary;
            break;
        case 'pending':
            color = infoColor;
            break;
        case 'unfavorable':
        case 'anomaly':
            state = "unfavorable";
            color = errorColor;
            break;
        case 'unevaluated':
            color = darkGrey;
            break;
        default :
            color = orangeColor;
            break;
    }
    return (
        <Tooltip title={i18next.t(state)} arrow>
            <Typography variant="body2" component="span" sx={{color}}>
                    {i18next.t(state)}
            </Typography>
        </Tooltip>
    )
}

export const InduedScoreLabel = ({score}) => {
    const { classes } = useStyles();
    const severity = induedScoreSeverity(score);
    const label = i18next.t(`indued.severity.${severity.label}`);
    return (
        <Tooltip title={label} arrow>
            <Typography variant="body2" component="span"
                className={classes.scoreLabel + " " + classes[`scoreLabel${severity.label}`]}
            >
                {label}
            </Typography>
        </Tooltip>
    )
}

const buildSisIdBody = (data) => {
    return [
        [i18next.t("viewer.confidenceIndex"), <SisIdScoreLabel score={data.score} noTooltip/>],
        [i18next.t("viewer.reasons"),
            <>
                {mapSisIdReasons(data?.reasonLabels).map((label, index) => <Reason key={index} label={label} />)}
            </>
        ]
    ];
}

const buildTrustpairBody = (data) => {
    return [
        [i18next.t("viewer.evaluation"), <TrustpairState state={data.state} noTooltip/>],
        [i18next.t("viewer.reasons"),
            <>
                {mapTrustpairReasons(data?.state_reasons).map((label, index) => <Reason key={index} label={label} />)}
            </>
        ]
    ];
}

const buildInduedBody = (data) => {
    return [
        [i18next.t("indued.complianceScore"), <InduedScoreLabel score={data.due_diligence.risk_level} noTooltip/>],
    ];
}

const Reason = ({label}) => {
    const { classes } = useStyles();
    return (
        <li className={classes.reasonListItem}>
            <span>
                {label}
            </span>
        </li>
    )
}

const RealizedByBloc = ({label, date}) => {
    const { classes } = useStyles();

    return (
        <>
            <span>
                {`${i18next.t("viewer.realizedBy")} ${label} ${i18next.t(i18next.language === "fr" ? 'the' : 'on')} `}
            </span>
            <span className={classes.realizedByBlocDate}>
                {formatDate(date)}
            </span>
        </>
    )
}

const hasControl = (checks, control) => checks?.[control];
const mapSisIdReasons = (reasons) => reasons && Object.values(reasons);
const mapTrustpairReasons = (reasons) => reasons && Object.values(reasons)?.map(x => x.state_reasons)?.flat(1);

function PanelAdditionalChecks({checks}) {
    const [cardTitle, setCardTitle] = useState();
    const [sisIdBody, setSisIdBody] = useState();
    const [trustpairBody, setTrustpairBody] = useState();
    const [induedBody, setInduedBody] = useState();

    useEffect(() => {
        const title = i18next.t('viewer.additionalChecks');
        if(hasControl(checks, SIS_ID) || hasControl(checks, TRUSTPAIR) || hasControl(checks, INDUED)) {
            if(hasControl(checks, SIS_ID)) {
                setSisIdBody(buildSisIdBody(checks[SIS_ID]));
                setCardTitle([
                    title,
                    <RealizedByBloc label={i18next.t(SIS_ID)} date={checks[SIS_ID].lastModifiedDate}/>
                ])
            }
            if(hasControl(checks, TRUSTPAIR)) {
                setTrustpairBody(buildTrustpairBody(checks[TRUSTPAIR]));
                setCardTitle([
                    title,
                    <RealizedByBloc label={i18next.t(TRUSTPAIR)} date={checks[TRUSTPAIR].updated_at}/>
                ])
            }
            if(hasControl(checks, INDUED)) {
                setInduedBody(buildInduedBody(checks[INDUED]));
                setCardTitle([
                    title,
                    <RealizedByBloc label={i18next.t("indued.label")} date={checks[INDUED].last_modified_date}/>
                ])
            }
        } else {
            setCardTitle([
                title,
                i18next.t("viewer.noControl")
            ])
        }
    }, [checks]);

    return (
        <>
            {hasControl(checks, SIS_ID) || hasControl(checks, TRUSTPAIR) || hasControl(checks, INDUED) ?
                <>
                    {hasControl(checks, SIS_ID) && (
                        <DocViewerCard
                            title={cardTitle}
                            body={<DocViewerTable noTranslationTexts={EXCLUDED_TEXTS} header={i18next.t('viewer.paymentVerification')} body={sisIdBody}/>}
                        />
                    )}
                    {hasControl(checks, TRUSTPAIR) && (
                        <DocViewerCard
                            title={cardTitle}
                            body={<DocViewerTable noTranslationTexts={EXCLUDED_TEXTS} header={i18next.t('viewer.paymentVerification')} body={trustpairBody}/>}
                        />
                    )}
                    {hasControl(checks, INDUED) && (
                        <DocViewerCard
                            title={cardTitle}
                            body={<DocViewerTable noTranslationTexts={EXCLUDED_TEXTS} header={i18next.t("indued.dueDiligence")} body={induedBody}/>}
                        />
                    )}
                </>
                :
                <DocViewerCard
                    title={cardTitle}
                    body={<DocViewerTable noTranslationTexts={EXCLUDED_TEXTS} />}
                />
            }
        </>
    );
}

export default PanelAdditionalChecks;
