import React from "react";

function EAIcon({ icon, extension = "svg", alt = "icon", className, wrapperClass = false, ...othersProps }) {
  return (
    wrapperClass
      ? (
        <div className={wrapperClass}>
          <img
            src={process.env.PUBLIC_URL + "/img/icons/" + icon + "." + extension}
            alt={alt}
            className={className}
            {...othersProps}
          />
        </div>
      )
      : (
        <img
          src={process.env.PUBLIC_URL + "/img/icons/" + icon + "." + extension}
          alt={alt}
          className={className}
          {...othersProps}
        />
      )
  )
}

export default EAIcon;
