import { getDossierModel } from "services/edge/DossierModelService";


export const fetchDossierModelList = async (account) => {
    try {
      const results = await getDossierModel(account.id, null);
      // Order results by Creation Date
      let resultsOrdered = results.content.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      );
      return resultsOrdered;
    } catch (error) {
      return [];
    }
  };
