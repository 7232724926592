import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "13px",
  },
  historicItemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "27px",
    position: "relative"
  },
  historicItemData: {
    display: "flex",
    flexDirection: "row",
  },
  historicItemLink: {
    cursor: "pointer",
    position: "absolute",
    right: "16px",
  },
  historicItemGraphic:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "17px",
  },
  historicItemGraphicBubble: {
    width: "15px",
    height: "15px",
    backgroundColor: theme.palette.grey.grey200,
    borderRadius: "50%",
    marginTop: "3px",
  },
  historicItemGraphicLine: {
    height: "18px",
    borderLeft: "2px solid " + theme.palette.grey.grey200,
    marginTop: "5px",
  },
  historicItemDataDate: {
    color: "#373534",
    fontSize: "14px",
    fontWeight: 300,
    fontFamily: "Helvetica"
  },
  historicItemDataDateBold: {
    fontWeight: 700,
  },
  historicItemDataDeposit: {
    color: theme.palette.grey.grey500,
    fontWeight: 700,
    fontSize: "10px",
    marginLeft: "10px",
    position: "relative",
    top: "3px",
    display: "flex",
    "& img": {
      marginRight: "2px"
    }
  },
  smallIcon: {
    width: "12px",
    height: "12px"
  },
  historicItemDetail: {
    display: "flex",
    flexDirection: "row",
  },
  historicItemStatus: {
    display: "flex",
    flexDirection: "row",
  },
  historicItem: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
