import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  separate: {
    border: ".5px solid " + theme.palette.grey.grey100,
    height: "1px",
    marginTop: "13px",
  },
}));

export default useStyles;
