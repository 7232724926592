import useStyles from "../style/js-style/common/AttributeMultipleSelectChipsStyle";
import i18next from "i18next";
import {Chip, Tooltip} from "@mui/material";
import React from "react";
import _ from "lodash";

const AttributeMultipleSelectChips = ({ selected, setValue, shownRoles }) => {
  const { classes } = useStyles();

  const getChipClass = (index) => {
    return (
      selected.length === (index + 1)
      && selected.length % 2 !== 0
        ? classes.largeChip
        : classes.chip
    )
  }

  if (!selected || selected.length === 0) {
    return <em>{i18next.t("notSpecified")}</em>;
  }

  return (
    <div className={classes.chipsContainer}>
      {selected.map((value,index) => (
        <Tooltip key={index} title={_.find(shownRoles, {value: value}).label}>
          <Chip
            className={getChipClass(index)}
            key={value}
            label={_.find(shownRoles, {value: value}).label}
            variant="outlined"
            size="small"
            deleteIcon={
              <img
                src={process.env.PUBLIC_URL + "/img/icons/cross.svg"}
                alt="close"
                onMouseDown={(event) => event.stopPropagation()}
                className={classes.deleteItemIcon}
              />
            }
            onDelete={(event) => {
              event.preventDefault();
              setValue(
                "roleIds",
                selected?.filter((x) => x !== value)
              );
            }}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default AttributeMultipleSelectChips;
