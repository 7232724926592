import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { MenuItem } from "@mui/material";
import useStyles from "../../../style/js-style/containers/users/users-list/UsersDataTableStyle";
import { Context } from "../../../states/Store";
import EAIcon from "../../../components/common/EAIcon";

export const UsersMultiSelectionMenu = ({
  users,
  usersSelected,
  openDeleteUsersPopup
}) => {
  const { classes } = useStyles();
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState(false);
  const [isMultiActionsDisabled, setIsMultiActionsDisabled] = useState(true);
  const [state] = useContext(Context);

  useEffect(() => {
    const disabledUsers = users.filter(
      //TODO: Update this condition
      // (user) => usersSelected.indexOf(user.id) !== -1 && ((user.role === "Admin & user" && !state.roles.isAdmin) || !state.roles.isUser)
      (user) => usersSelected.indexOf(user.id) !== -1 && false

    );
    setIsMultiActionsDisabled(disabledUsers?.length > 0);
  }, [users, usersSelected, state.roles]);

  const handleClick = () => {
    setActionMenuIsOpen(!actionMenuIsOpen);
  };

  return (
    <div
      className={usersSelected.length < 1 ? classes.dnone : classes.actionMenuWrapper}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick} >
      <div className={classes.actionMenuTitle}>
        {i18next.t("usersTable.action")}
        <EAIcon icon={!actionMenuIsOpen ? "arrow/arrow-to-down_small" : "arrow/arrow-to-up_small"} className={classes.titleArrow} />
      </div>

      <div className={actionMenuIsOpen ? "" : classes.dnone}>
        <div className={classes.actionMenuSeparate} />
        {/*<MenuItem*/}
        {/*  className={classes.actionMenuItem}*/}
        {/*  // TODO : changer la conditions apres mise en place de la route Edge vers multi reinvitation*/}
        {/*  disabled={true}*/}
        {/*  onClick={() => openReinviteUsersPopup(usersSelected, "")}>*/}
        {/*  {i18next.t("usersTable.reinvitAction")}*/}
        {/*</MenuItem>*/}
        <MenuItem
          className={classes.actionMenuItem}
          disabled={isMultiActionsDisabled}
          onClick={() => openDeleteUsersPopup(usersSelected, "")}>
          {i18next.t("usersTable.deleteAction")}
        </MenuItem>
      </div>
    </div>
  );
};
