import { makeStyles } from 'tss-react/mui';
import {darkGrey} from "../../../../globalStyle";

const useStyles = makeStyles()((theme) => ({
    root: {
        marginBottom: "40px",
        "& .dossierInfoPaper": {
            padding: "1rem"
        },
        "& .completenessPaper": {
            padding: "1rem"
        },
        "& .dossierBtns": {
            display: "flex",
            justifyContent: "flex-end"
        },
        "& .deactivateBtn": {
            backgroundColor: darkGrey,
            color: "white",
            marginRight: "0.5rem"
        }
    },

    // Top header
    topHeaderIcon: {
        marginRight: "5px",

        "&:nth-child(2)":{
            marginLeft: "16px",
        }
    },
    smallIcon: {
        width: "11px",
        height: "10px"
    },
    marginLeft: {
        marginLeft: "15px"
    },
    topHeaderLabelActivity: {
      fontWeight: 700,
      color: theme.palette.primary.primary400,
    },
    topHeaderLabelActivityArchived: {
        fontWeight: 700,
        color: theme.palette.grey.grey300,
    },
    archiveButton: {
        marginRight: "0.5rem",
        color: theme.palette.grey.grey600,
        backgroundColor: theme.palette.grey.grey200,
        borderRadius: "16px",
        padding: "11px 16px",
        textTransform: "none",
        fontWeight: 600,
        fontFamily: "Metric",
        fontSize: "14px",
        boxShadow: "0px 11.28px 24.231px rgba(154, 154, 175, 0.115017), 0px 6.0308px 12.9551px rgba(154, 154, 175, 0.0953772), 0px 3.38082px 7.2625px rgba(154, 154, 175, 0.08), 0px 1.79553px 3.85706px rgba(154, 154, 175, 0.0646228), 0px 0.747159px 1.60501px rgba(154, 154, 175, 0.0449833)",

        "&:hover": {
            backgroundColor: theme.palette.grey.grey300,
        },
    },
    archiveButtonIon: {
      marginRight: "0.5rem",
    },
    archivedThemeHeader: {
      color: "white",
    },
    archivedBtnLabel:{
        textTransform: "none",
        position: "relative",
    },
    addDate: {
        fontSize: "12px",
    },

    // Header Tab
    tab: {
        fontWeight: 600,
        borderRadius: '18px 18px 0 0',
        minWidth: "146px",
        height: "39px",
        marginRight: "2px",
        backgroundColor: "white",
        zIndex: 1,
        textTransform: "none",
        fontSize: "18px",
        fontFamily: "Metric",
        padding: "10px 7px",
    },
    selectedTab: {
        color: "black !important",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -10px, rgba(0, 0, 0, 0.3) 0px 8px 13px -9px",
    },
    unselectedTab: {
        color: theme.palette.grey.grey400,
        opacity: 0.75,

        "& MuiTabs-scroller": {
            position: "absolute"
        },
    },
    tabsWrapper: {
        border: "none",
        borderBottom: 1,
        borderColor: 'divider',
    },

    // Header background
    headerBackground: {
        width: "100%",
        height: "538px",
        position: "absolute",
        background: "no-repeat right -260% url('../../../../img/background/bg-domain-onboarded.png')",
        backgroundColor: theme.palette.primary.primary100,
        top: 0,
        left: 0,
        right: 0,
    },
    headerBackgroundArchived: {
        width: "100%",
        height: "537px",
        position: "absolute",
        background: "no-repeat right -260% url('../../../../img/background/bg-domain-archived.png')",
        backgroundColor: theme.palette.grey.grey600,
        top: 0,
        left: 0,
        right: 0,
    },

    // Info of document (missing/ok)
    documentsInfo: {
        marginTop: "4rem",
        zIndex: 2,
        padding: "0rem !important",
    },

    // Utils
    backToFront: {
        zIndex: 2,
    },
    marginTab: {
        marginRight: "1rem",
        backgroundColor: "white",
        borderRadius: "0 12px 12px 12px",
    },
    tabDisabled: {
        opacity: 0.75,
        background: "#E2E2EABF",
    },
}));

export default useStyles;
