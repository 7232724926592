import { Box } from "@mui/system";
import React, {useContext} from "react";
import {
  induedScoreSeverity,
  InduedScores,
  DueDiligenceStatus,
  isInduedDone,
} from "../../services/common/InduedUtils";
import EAIcon from "../common/EAIcon";
import i18next from "i18next";
import useStyles from "../../style/js-style/components/thirdparties/PanelInduedStyle";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../style/globalStyle";
import clsx from "clsx";
import {Context} from "../../states/Store";

const InduedPendingBloc = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.rootNoData}>
      <EAIcon
        icon="hourglass_top"
        className={classes.hourglassIcon}
        wrapperClass={classes.wrapperIconHourglass}
      />
      <div className={classes.scoreText}>
        {i18next.t("indued.complianceScore")}
      </div>
      <div className={classes.inProgressText}>
        {i18next.t("indued.EAStatus.pending")}
      </div>
    </Box>
  );
};

const InduedNotStartedBloc = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.rootNoData}>
      <EAIcon icon="notification_important" />
      <div className={classes.noDataText}>
        {i18next.t("indued.notStartedText")}
      </div>
    </Box>
  );
};

const InduedBullet = ({ severity, label }) => {
  const { classes } = useStyles();
  const [state] = useContext(Context);

  // Responsive break points
  const breakPoints = {
    drawerOpened: { xxl: 1900, m: 1170 },
    drawerClosed: { xxl: 1700, m: 970 }
  };
  const mediaMenuOpenM = useMediaQuery(theme.breakpoints.down(breakPoints.drawerOpened.m));
  const mediaMenuOpenXXL = useMediaQuery(theme.breakpoints.up(breakPoints.drawerOpened.xxl));
  const mediaMenuClosedM = useMediaQuery(theme.breakpoints.down(breakPoints.drawerClosed.m));
  const mediaMenuClosedXXL = useMediaQuery(theme.breakpoints.up(breakPoints.drawerClosed.xxl));

  return (
    <div className={ clsx(classes.induedBulletRoot, state.drawerIsOpened ? mediaMenuOpenM && classes.induedBulletRootMin : mediaMenuClosedM && classes.induedBulletRootMin )}>
      <div className={classes.induedBulletWrapper + " " + classes[`induedBulletWrapper${severity.label}`]}>
        <div className={classes.induedBullet + " " + classes[`induedBullet${severity.label}`]}/>
      </div>

      <span
        className={classes.induedBulletLabel}
        style={state.drawerIsOpened ? {fontSize: mediaMenuOpenXXL ? "18px" : ""} : {fontSize: mediaMenuClosedXXL ? "18px" : ""}}
      >
        {label}
      </span>
    </div>
  );
};

const InduedDataBloc = ({ indued }) => {
  const { classes } = useStyles();
  const [state] = useContext(Context);
  const induedRiskLevel = induedScoreSeverity(
    indued?.due_diligence?.risk_level
  );

  // Responsive break points
  const breakPoints = {
    drawerOpened: { xxl: 1900, l: 1480, m: 1160 },
    drawerClosed: { xxl: 1700, l: 1280, m: 960 }
  };
  const mediaMenuOpenM = useMediaQuery(theme.breakpoints.down(breakPoints.drawerOpened.m));
  const mediaMenuOpenL = useMediaQuery(theme.breakpoints.down(breakPoints.drawerOpened.l));
  const mediaMenuOpenXXL = useMediaQuery(theme.breakpoints.up(breakPoints.drawerOpened.xxl));
  const mediaMenuClosedM = useMediaQuery(theme.breakpoints.down(breakPoints.drawerClosed.m));
  const mediaMenuClosedL = useMediaQuery(theme.breakpoints.down(breakPoints.drawerClosed.l));
  const mediaMenuClosedXXL = useMediaQuery(theme.breakpoints.up(breakPoints.drawerClosed.xxl));

  return (
    <div
      className={classes.rootData}
      style={ state.drawerIsOpened ? {flexDirection: mediaMenuOpenL ? "column" : "row"} : {flexDirection: mediaMenuClosedL ? "column" : "row"} }
    >

      {/* Resume */}
      <div
        className={classes.shortRiskPanel}
        style={ state.drawerIsOpened ? { minWidth: mediaMenuOpenXXL ? "170px" : "" } : { minWidth: mediaMenuClosedXXL ? "170px" : "" } }
      >
        {/* Label */}
          <div
            className={classes.riskLabel + " " + classes[`riskLabel${induedRiskLevel.label}`]}
            style={ state.drawerIsOpened ? { fontSize: mediaMenuOpenXXL ? "20px" : "" } : { fontSize: mediaMenuClosedXXL ? "20px" : "" } }
          >
            {i18next.t(`indued.severity.${induedRiskLevel.label}`)}
        </div>
        {/* Compliance */}
        <div
          className={classes.complianceLabel}
          style={ state.drawerIsOpened ? { fontSize: mediaMenuOpenXXL ? "16px" : ""  } : { fontSize: mediaMenuClosedXXL ? "16px" : ""  } }
        >
          {i18next.t("indued.complianceScore")}
        </div>
        {!isInduedDone(indued) && (
          <div className={classes.provisionalWrapper}>
            <span className={classes.provisionalBullet}/>
            <span
              className={classes.provisionalLabel}
              style={ state.drawerIsOpened ? {fontSize: mediaMenuOpenXXL ? "14px" : ""} : {fontSize: mediaMenuClosedXXL ? "14px" : ""} }
            >
              {indued?.due_diligence?.status === DueDiligenceStatus.FPM_DONE
                ? i18next.t("indued.reportInProgress")
                : i18next.t("provisional")}
            </span>
          </div>
        )}
      </div>

      {/* Detail */}
      <div
        className={clsx(classes.detailRiskPanel,
          state.drawerIsOpened
            ? mediaMenuOpenM ? classes.detailRiskPanelS : mediaMenuOpenL ? classes.detailRiskPanelL : classes.detailRiskPanelXL
            : mediaMenuClosedM ? classes.detailRiskPanelS : mediaMenuClosedL ? classes.detailRiskPanelL : classes.detailRiskPanelXL
        )}
      >
        <div
          className={
            state.drawerIsOpened
              ? mediaMenuOpenM ? classes.detailRiskPanelBlocS : classes.detailRiskPanelBloc
              : mediaMenuClosedM ? classes.detailRiskPanelBlocS : classes.detailRiskPanelBloc
          }
          style={state.drawerIsOpened ? {flexWrap: mediaMenuOpenM ? "wrap" : "nowrap"} : {flexWrap: mediaMenuClosedM ? "wrap" : "nowrap"} }
        >
          {InduedScores.map((score, index) => (
            <>
              { index === 0 && ((state.drawerIsOpened && !mediaMenuOpenM) || (!state.drawerIsOpened && !mediaMenuClosedM )) &&
                <div className={classes.lineOnDetailSeparator}/>
              }
              { ((state.drawerIsOpened && mediaMenuOpenM) || (!state.drawerIsOpened && mediaMenuClosedM)) &&
                <>
                  <div className={classes.lineOnDetailSeparatorFirst}/>
                  <div className={classes.lineOnDetailSeparatorSecond}/>
                </>
              }
              <InduedBullet
                key={score}
                severity={induedScoreSeverity(
                  indued?.due_diligence?.[score]
                )}
                label={i18next.t(`indued.scores.${score}`)}
              />
              {InduedScores.length - 1 !== index && ((state.drawerIsOpened && !mediaMenuOpenM) || (!state.drawerIsOpened && !mediaMenuClosedM)) &&
                <div className={classes.lineOnDetailSeparator}/>
              }
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

const PanelIndued = ({ indued }) => {
  if(!indued.created_date) return <InduedNotStartedBloc />;
  if(!indued.due_diligence) return <InduedPendingBloc />;
  return <InduedDataBloc indued={indued} />;
};
export default PanelIndued;
