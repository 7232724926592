import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  alert: {
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: "14px",
    borderRadius: "16px",
    marginBottom: "12px",

    "& .MuiAlert-icon": {
      padding: 0,
      height: "16px",
    },
  },
}));

export default useStyles;
