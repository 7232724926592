import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import useStyles from "../style/js-style/components/AdminSettingsStyle";
import React, { useContext, useState } from 'react';
import i18next from "i18next";
import EATabPanel from "./common/EATabPanel";
import Attributes from "../containers/administration/attributes/Attributes";
import Rules from "../containers/administration/rules/Rules";
import { useQuery } from "../config/Config";
import MenuAppBar from "./MenuAppBar";
import EAIcon from "./common/EAIcon";
import ROUTES from "../config/Routes";
import clsx from "clsx";
import { Context } from "../states/Store";
import { useHistory } from "react-router";
import EAPagination from "./common/datatable/EAPagination";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "../services/common/RolesUtils";
import Calculator from "../containers/administration/calculator/Calculator";
import Imports from "containers/administration/imports/Imports/Imports";
import Demandes from "containers/administration/demandes/Demandes";

const a11yProps = (tabId) => {
  return {
    id: `${tabId}-tab`,
    "aria-controls": `${tabId}-tabpanel`,
  };
};

function AdminSettings() {
  let tabQueryParam = useQuery().get("tab");
  const { isGlobalAdmin, hasAnyOfPrivileges } = React.useContext(RoleBasedAccessControlContext);
  const [value, setValue] = useState("attributes");
  const [state] = useContext(Context);
  const { classes } = useStyles();
  const history = useHistory();

  const adminTabs = [
    isGlobalAdmin ? {
      id: "demandes",
      index: 0,
      label: i18next.t(`adminSettings.demandes`),
      neededPrivilege: [PrivilegeEnum.PRIVILEGE_ALL_DOSSIER]
    } : null,
    {
      id: "attributes",
      index: 1,
      label: i18next.t(`adminSettings.attributes`),
      neededPrivilege: PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE || PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE,
    },
    {
      id: "rules",
      index: 2,
      label: i18next.t(`adminSettings.rules`),
      neededPrivilege: PrivilegeEnum.PRIVILEGE_VIEW_RULE,
    },
    {
      id: "calculator",
      index: 3,
      label: i18next.t(`adminSettings.calculator`),
      neededPrivilege: PrivilegeEnum.PRIVILEGE_VIEW_CALCULATION,
    },
    isGlobalAdmin ? {
      id: "import",
      index: 4,
      label: i18next.t(`adminSettings.import`),
      neededPrivilege: [PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY]
    } : null
  ].filter(tab => tab !== null);

  const handleChange = (event, newValue) => {
    const newId = event.target.id.substring(0, event.target.id.length - 4);
    setValue(newId);
    history.push(`/${state.account.id}/${ROUTES.ADMIN}?tab=${newId}`);
  };

  React.useEffect(() => {
    if (tabQueryParam) {
      const filteredTab = adminTabs.filter((tab) => tab.id === tabQueryParam);
      if (hasAnyOfPrivileges(filteredTab?.[0]?.neededPrivilege)) {
        setValue(tabQueryParam);
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }, [tabQueryParam, adminTabs, hasAnyOfPrivileges, history]);

  const renderTabPanelContent = (tabId) => {
    switch (tabId) {
      case 'demandes':
        return <Demandes />;

      case 'attributes':
        return <Attributes />;

      case 'rules':
        return <Rules />;

      case 'calculator':
        return <Calculator />;

      case 'import':
        return <Imports />;

      default:
        return <></>
    }
  }

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <div className={classes.headerWrapper}>
          {/* AppBar Custom */}
          <div className={classes.appBar}>
            <MenuAppBar variant="admin" />
          </div>

          {/* Header */}
          <Grid container className={classes.headerContent}>
            <Grid item xs={2} className={classes.adminIconWrapper}>
              <EAIcon icon={"parameters_green"} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant={"h2"} className={classes.title}>
                {i18next.t("adminSettings.configurationMessage")}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Grid
          item
          xs={12}
          style={{ padding: "0" }}
          className={classes.mainContentWrapper}
        >
          {/* Tabs */}
          <Box className={classes.tabsWrapper}>
            <Tabs
              aria-label="dashboard tabs"
              value={value}
              onChange={handleChange}
            >
              {adminTabs.map((tab, index) => (
                <Tab
                  key={tab.id}
                  label={tab.label}
                  {...a11yProps(tab.id)}
                  disabled={!hasAnyOfPrivileges(tab.neededPrivilege)}
                  className={clsx(
                    classes.tab,
                    !hasAnyOfPrivileges(tab.neededPrivilege) ? classes.tabDisabled
                      : value === tab.id
                        ? classes.selectedTab
                        : classes.unselectedTab
                  )}
                />
              ))}
            </Tabs>
          </Box>

          {adminTabs.map((tab, index) => (
            <EATabPanel
              key={tab.id}
              value={value}
              index={tab.id}
              className={classes.tabContentWrapper}
            >
              {renderTabPanelContent(tab.id)}
            </EATabPanel>
          ))}
        </Grid>
      </Grid>
      {tabQueryParam !== "demandes" &&
        <EAPagination />
      }
    </>
  );
}

export default AdminSettings;
