import { Box } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import countries from "i18n-iso-countries";
import {
  BANK_DATA_KEYS,
  DocumentFormDataType,
  INDUED,
} from "../../services/common/Constants";
import DocViewerTable from "../../components/doc-viewer/DocViewerTable";
import {
  formatDate,
  formatIssuer,
  formatNumberCurrency,
} from "../../services/common/Utils";
import { InduedScoreLabel } from "./PanelAdditionalChecks";
import { InduedScores } from "../../services/common/InduedUtils";
import { EXCLUDED_TEXTS } from "i18n/LocalizeUtils";

const isIbanEvidence = (evidence) => {
  return evidence?.formdata?.find(
    (fd) => fd.type === DocumentFormDataType.IBAN
  );
};

const getBlockValue = (metadata, metadataKey) => {
  if (metadataKey === "bank_data_country_iso") {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <span style={{ marginRight: "5px" }}>
          {countries.getName(metadata[metadataKey], i18next.language)}
        </span>
      </Box>
    );
  } else if (metadataKey.startsWith("sepa_data")) {
    return getSEPAValue(metadata[metadataKey] === "YES");
  } else {
    return metadata[metadataKey];
  }
};

const getSEPAValue = (isOk) => {
  return (
    <Box display="flex" justifyContent="space-between">
      {i18next.t(isOk ? "yes" : "no")}
    </Box>
  );
};

const buildBody = (evidence, thirdpartyName, documentType, statusInfo) => {
  let result = [];

  // Add Evidence Generic Infos
  if (evidence) {
    if (evidence.expirationDate && evidence.expirationDate != null && (statusInfo == null || statusInfo?.statusReason !== "REFUS_CLIENT")) {
      result.push([i18next.t("validity"), formatDate(evidence.expirationDate)]);
    }
    if (evidence.format && evidence.format !== "NA") {
      result.push([
        i18next.t("format"),
        i18next.t(`viewer.format.${evidence.format}`),
      ]);
    }
    if (evidence.issuer && evidence.issuer !== "NA") {
      result.push([
        i18next.t("issuedBy"),
        formatIssuer(
          evidence.issuer,
          thirdpartyName,
          evidence.metadata?.fiscale_mere_fille
        ),
      ]);
    }
    if (evidence.metadata?.effectif_urssaf) {
      result.push([i18next.t("workforce"), evidence.metadata?.effectif_urssaf]);
    }
    if (evidence.metadata?.masse_salariale_urssaf) {
      result.push([
        i18next.t("payroll"),
        formatNumberCurrency(evidence.metadata?.masse_salariale_urssaf, "EUR"),
      ]);
    }
    if (evidence.metadata?.derniere_periode_analysee_urssaf) {
      result.push([
        i18next.t("forThe"),
        evidence.metadata?.derniere_periode_analysee_urssaf,
      ]);
    }
  }

  // Add Specific Data Infos
  if (evidence.specificData && evidence.specificData != null && documentType) {
    result.push([
      i18next.t("specificDataType" + documentType),
      evidence.specificData["nameFr"],
    ]);
  }

  // Add Indued Data Infos
  if (evidence?.metadata?.[INDUED]?.due_diligence) {
    const induedData = evidence?.metadata?.[INDUED]?.due_diligence;
    result.push([
      i18next.t("indued.complianceScore"),
      <InduedScoreLabel score={induedData.risk_level} noTooltip />,
    ]);
    InduedScores?.forEach((scoreLabel) => {
      result.push([
        i18next.t(`indued.scoresFull.${scoreLabel}`),
        <InduedScoreLabel score={induedData[scoreLabel]} noTooltip />,
      ]);
    });
  }

  // Add IBAN Bank Data Infos
  if (isIbanEvidence(evidence)) {
    BANK_DATA_KEYS.forEach((key) => {
      if (evidence.metadata?.[key]) {
        result.push([
          i18next.t(`viewer.${key}`),
          getBlockValue(evidence.metadata, key),
        ]);
      }
    });
  }
  return result;
};

function PanelInformations({ evidence, thirdpartyName, documentType, statusInfo }) {
  const [cardBody, setCardBody] = useState();

  useEffect(() => {
    setCardBody(buildBody(evidence, thirdpartyName, documentType, statusInfo));
  }, [evidence, thirdpartyName, documentType, statusInfo]);

  return (
    <div>
      <DocViewerTable noTranslationTexts={EXCLUDED_TEXTS} body={cardBody} />
    </div>
  );
}

export default PanelInformations;
