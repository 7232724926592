import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  header: {
    border: "1.5px solid #FFFFFF",
    borderRadius: "4px",
    background: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.492) 0%, rgba(255, 255, 255, 0.48) 110.84%)",
    marginBottom: "9px",
    height: "136px",
    padding: "0 30px",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    gap: "30px",
  },
  mainContent: {
    display: "flex",
    gap: "16px 25px",
    width: "100%",
    padding: "16px",
  },
  docViewer:{
    flex: 1,
    zIndex: 2,
  },
  infoPanel: {
    backgroundColor: "white",
    borderRadius: "6px",
    flex: 1,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
  },
  circleLeft:{
    top: "-30%",
    left: "15%",
    background: "radial-gradient(67.22% 50% at 50% 50%, rgba(51, 231, 255, 0.105) 0%, rgba(245, 228, 255, 0) 100%)",
    opacity: 0.9,
  },
  circleRight:{
    top: "-10%",
    left: "60%",
    background: "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.105) 0%, rgba(245, 228, 255, 0) 100%)",
  },
  circle:{
    position: "absolute",
    borderRadius: "50%",
    height: "100vh",
    width: "100vh",
  },
  vDivider: {
    height: "89px",
    width: "1px",
    border: "1px solid " + theme.palette.grey.grey200,
  },
  hDivider: {
    width: "calc(100% - 45px)",
    margin: "16px 22.5px 0 22.5px",
    borderTop: "0.5px solid " + theme.palette.grey.grey200,
  },
  tab: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 700,
    minWidth: "110px",
  },
  unselectedTab: {
    color: theme.palette.grey.grey600,
    borderBottom: "2px solid " + theme.palette.grey.grey200,
  },
  selectedTab: {
    color: theme.palette.primary.primary600,
    borderBottom: "2px solid " + theme.palette.primary.primary600,
  },
  tabsWrapper: {
    paddingLeft: "20px",
    position: "relative",
    height: "53px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.primary600 + " !important",
    },
  },
  tabDisabled: {
    opacity: 0.75,
    background: "#E2E2EABF",
  },
  panelWrapper: {
    padding: "0 14px 12px 14px",
    flexGrow: 1
  },
  block_de_droite: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "1"
  },
  mr6: {
    marginRight: "6px",
  },
  lineOnTabs: {
    position: "absolute",
    bottom: "0",
    width: "calc(100% - 40px)",
    borderTop : "1px solid #E2E2EA",
  },
}));

export default useStyles;
