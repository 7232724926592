import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    thirdDocumentsInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        backgroundColor: "white",
    },
    thirdDocumentsInfoArchived:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.grey.grey500,
        borderRadius: "12px",
        color: "white",
        transition: "all 0.3s",
        "&:hover": {
            boxShadow: "rgb(0 0 0 / 9%) 0px 25px 20px -20px",
            transition: "all 0.3s",
        },

    },
    innerRootArchived:{
        position: "relative",
        top: "-5px",
        display: "flex",
        flexDirection : "column",
        justifyContent: "center",
        alignItems : "center"
    },
    innerRootArchivedNoComment:{
        position: "relative",
        top: "-22px",
        display: "flex",
        flexDirection : "column",
        justifyContent: "center",
        alignItems : "center"
    },
    documentIcon: {
        width: "50px",
        filter: "drop-shadow(0px 11.28px 23.231px rgba(101, 101, 117, 0.115017))",
    },
    separation: {
        borderTop: "1px solid " + theme.palette.grey.grey200,
        height: "1px",
        width: "175px",
        margin: "4px 0",
    },
    documentCounter: {
        fontWeight: 600,
        fontSize: "25px",
        marginBottom: "0.5rem",
        marginTop: "5px",
        color: theme.palette.grey.grey600,
        fontFamily: "Metric",
    },
    documentDetail: {
        display: "flex",
        alignItems: "center",
        marginTop: "0.5rem",
        gap: "0.7rem",
        fontSize: "14px",
    },
    documentDetailMissing:{
        display: "flex",
        alignItems: "center",
        marginTop: "0.5rem",
        gap: "0.7rem",
        color: theme.palette.grey.grey600,
        fontSize: "14px",
    },
    presentTypo: {
        color: theme.palette.primary.primary600,
    },

    // Archived theme
    documentIconArchived:{
        width: "90px",
        position: "relative",
        bottom: "-22px",
    },
    archivedText: {
        fontWeight: 600,
        fontSize: "25px",
        margin: "0",
        fontFamily: "Metric",
    },
    archiveStopedAt: {
        fontSize: "12px",
        marginLeft: "5px",
    },
    commentArchivedWrapper: {
        border: "1px solid " + theme.palette.error.error200,
        borderRadius: "16px",
        margin: "1rem",
        padding: "0.5rem",
        fontSize: "12px",
    },
    commentArchived: {
        margin: "0rem",
    },
    commentArchivedBold: {
        fontWeight: 600,
    },
}));

export default useStyles;
