import React, { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import EASelectInput from "../../../../../components/inputs/EASelectInput";
import {
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { isEmpty, find, get as lodashGet } from "lodash";
import i18next from "i18next";
import {
  ATTRIBUTE_MAX_TEXT_VALUE_LENGTH,
  AttributeType,
} from "services/common/Constants";
import EADatePicker from "components/common/EADatePicker";
import {
  getAttributeMaxLengthRule,
  validateAttributeInputForType,
} from "services/common/AttributeUtils";
import { SearchFormInputBloc } from 'components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc';

const getAttributeOptions = (attribute) => {
  const options = [];
  if (attribute.type === AttributeType.BOOLEAN) {
    options.push(
      { value: "true", label: i18next.t("yes") },
      { value: "false", label: i18next.t("no") }
    );
  }
  if (
    [AttributeType.LIST_MULTIPLE, AttributeType.LIST_UNIQUE].includes(
      attribute.type
    )
  ) {
    options.push(
      ...attribute?.values?.map((x) => ({ label: x.value, value: x.value }))
    );
  }
  options.sort((a,b) => a.label.localeCompare(b.label))
  options.push({ value: "notSpecified", label: i18next.t("notSpecified") })
  return options;
};

export function DossiersSearchAttributes({attributes, lineIndex}) {
  const formMethods = useFormContext();

  const { fields, append } = useFieldArray({
    control: formMethods.control,
    name: `dossiers.${lineIndex}.attributes`,
  });

  useEffect(() => {
    if (isEmpty(fields)) {
      attributes?.sort((a, b) => a.label.localeCompare(b.label))?.forEach((attr) => {
        append({
          id: attr.label,
          values: "",
          name: attr.label,
        });
      });
    }
  }, [attributes, fields, append]);

  return (
    <>
      {/* Attributes */}
      {
        fields?.map((field, index) => {
          // Render dossier attribute
          const attribute = find(attributes, { label: field.name });
          return (
            <Grid item xs={3} key={field.id}>
              <SearchFormInputBloc
                label={attribute.label}
                hasTooltip
                disableTranslation
              >
                {(() => {
                  const fieldName = `dossiers.${lineIndex}.attributes.${index}.values`;
                  switch (attribute.type) {
                    case AttributeType.BOOLEAN:
                    case AttributeType.LIST_MULTIPLE:
                    case AttributeType.LIST_UNIQUE:
                      return (
                        <EASelectInput
                          fieldName={fieldName}
                          options={getAttributeOptions(attribute)}
                          translateOptions={false}
                          defaultOption={"Tous"}
                        />
                      );
                    case AttributeType.DATE:
                      return (
                        <Controller
                          control={formMethods.control}
                          name={fieldName}
                          render={({ field }) => (
                            <EADatePicker
                              {...field}
                              formMethods={formMethods}
                              onlyDate
                              ref={null}
                              sxClass={{
                                "& input::placeholder": {
                                  color: "#9A9AAF !important",
                                  opacity: 1,
                                }
                              }}
                            />
                          )}
                        />
                      );
                    default:
                      return (
                        <Controller
                          control={formMethods.control}
                          name={fieldName}
                          rules={{
                            maxLength: getAttributeMaxLengthRule(
                              "theValue",
                              ATTRIBUTE_MAX_TEXT_VALUE_LENGTH
                            ),
                            validate: (value) =>
                              validateAttributeInputForType(
                                attribute.type,
                                value
                              ),
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="attribute-value-id"
                              variant="outlined"
                              fullWidth
                              placeholder="Rechercher"
                              error={
                                !!lodashGet(
                                  formMethods.formState.errors,
                                  fieldName
                                )
                              }
                              helperText={
                                !!lodashGet(
                                  formMethods.formState.errors,
                                  fieldName
                                )
                                  ? lodashGet(
                                      formMethods.formState.errors,
                                      fieldName
                                    ).message
                                  : ""
                              }
                            />
                          )}
                        />
                      );
                  }
                })()}
              </SearchFormInputBloc>
            </Grid>
          );
        })
      }
    </>
  );
}
