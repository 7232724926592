import React from "react";
import EAIcon from "./EAIcon";
import useStyles from "../../style/js-style/components/common/EANoDataStyle";

export const EANoData = ({ label, icon, style }) => {
  const { classes } = useStyles();
  return (
    <div style={style} className={classes.root}>
      <EAIcon icon={icon} className={classes.icon} />
      <span className={classes.label}>{label}</span>
    </div>
  );
};
