import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    linkCell: {
        textDecoration: "none",
        color: "inherit",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        height: "100%",
        justifyContent: "inherit",
        cursor: "pointer"
    },
    toolBarContainer:{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "22px",
      flexWrap: "nowrap"
    },
    filterContainer: {
        display: "flex",
        justifyContent: "center",
    },
    toolBarFilterButton : {
      fontFamily: "Metric",
      fontSize: "12px",
      fontWeight: 600,
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary600,
      border: "0.5px solid " + theme.palette.primary.primary600,
      borderRadius: "12px",
      padding: "0.2rem 0.5rem",
      marginRight: "1rem",
      textTransform: "none",

        "&:hover": {
            backgroundColor: theme.palette.primary.primary200,
        },
    },
    countBadge: {
        marginLeft: "-19px",
        marginRight: "15px",
        height: "85%",
        "& .MuiBadge-badge": {
            backgroundColor: theme.palette.warning.warning600 + " !important",
        },
    },
    toolBarFilterAnswerButton : {
        boxShadow: "none !important",
        marginLeft: "-9px",
        border: "0.5px solid " + theme.palette.primary.primary600 + " !important",
        transition: "0.2s ease-in-out",
    },
    toolBarFilterAnswerButtonActive : {
        backgroundColor: theme.palette.primary.main + " !important",
        color: "white !important",
            transition: "0.2s ease-in-out",
        "&:hover": {
            transition: "0.2s ease-in-out",
            backgroundColor: theme.palette.primary.primary700 + " !important",
            borderColor: theme.palette.primary.primary700 + " !important",
        },

    },
    toolBarFilterAnswerButtonInactive : {
        backgroundColor: theme.palette.primary.primary100 + " !important",
        color: theme.palette.primary.primary600 + " !important",
        "&:hover": {
            backgroundColor: theme.palette.primary.primary200 + " !important",
        },
    },
    toolBarColumnsButton: {
        backgroundColor: theme.palette.grey.grey300,
        color: "white",
        borderRadius: "12px",
        padding: "0.3rem 0.5rem",
        marginRight: "1rem",
        minWidth: "30px",

        "&:hover": {
            backgroundColor: theme.palette.grey.grey400,
        },

        "& .MuiButton-startIcon": {
            margin: "0 !important",
        }
    },
    toolBarFilterAnswerButtonContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
    },
    toolBarDensitySelector: {
        backgroundColor: theme.palette.grey.grey300,
        color: "white",
        borderRadius: "12px",
        padding: "0.3rem 0",
        marginRight: "1rem",
        minWidth: "30px",

        "&:hover": {
            backgroundColor: theme.palette.grey.grey400,
        },

        "& .MuiButton-startIcon": {
            margin: "0 !important",
        }
    },
    toolBarExport: {
        fontFamily: "Metric",
        fontSize: "12px",
        color: theme.palette.grey.grey400 + " !important",
        textTransform: "none",
        fontWeight: 600,
    },
    additionalLeft: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
    },
}));

export default useStyles;
