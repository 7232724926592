import { Box, Tooltip } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import DocViewerCard from "../../components/doc-viewer/DocViewerCard";
import DocViewerTable from "../../components/doc-viewer/DocViewerTable";

const greenReason = ["VALIDATION_CLIENT", "CONTROLES_VALIDES", "AUTOVALIDATION", "EXTRACTOR_VALIDATION"];
const purpleReason = ["BELOW_EXPECTATION_CLIENT_REFUS", "BELOW_EXPECTATION_EXTRACTOR_REFUS"];

const getBlockValue = (statusReason) => {
  const label = i18next.t(`viewer.status.validation.${statusReason}`);
  let color;
  let fontWeight;
  switch (true) {
    case greenReason.includes(statusReason):
      color = "#3FC855";
      fontWeight = 400;
      break;
    case purpleReason.includes(statusReason):
      color = "#6D00CC";
      fontWeight = 600;
      break;
    default:
      color = "#FF0001";
      fontWeight = 600;
  }

  return (
    <Box display="flex" justifyContent="space-between" noTooltip>
      <Tooltip title={label} arrow>
        <span
          style={{
            fontWeight: fontWeight,
            fontSize: "12px",
            lineHeight: "130%",
            color: color,
          }}
        >
          {label}
        </span>
      </Tooltip>
    </Box>
  );
}

const buildBody = (statusInfo) => {
  let body = [];
  const labelAction = i18next.t("viewer.status.panel.action");
  body.push([labelAction, statusInfo.statusReason ? getBlockValue(statusInfo.statusReason) : ""]);
  if (statusInfo.statusDetail) {
    const labelComment = i18next.t("viewer.status.panel.comment");
    body.push([labelComment, statusInfo.statusDetail]);
  }
  return body;
}

function PanelValidation({ statusInfo }) {
  const [cardTitle, setCardTitle] = useState();
  const [cardBody, setCardBody] = useState();

  useEffect(() => {
    let cardTitle = i18next.t('viewer.status.panel.title');
    setCardTitle([cardTitle])
    setCardBody(buildBody(statusInfo));

  }, [statusInfo]);

  return (
    <DocViewerCard
      title={cardTitle}
      body={
        <DocViewerTable
          {...(statusInfo ?
            {
              body: cardBody
            }
            : {})
          }
        />
      }
    />
  );
}

export default PanelValidation;
