import i18next from "i18next";
import moment from "moment";
import {
  formatAttributeValues,
  getAttributeValueDisplay,
} from "./AttributeUtils";
import _ from "lodash";

export const buildEventValues = (event) => {
  return {
    general: {
      name: i18next.t(`eventNames.${event.eventType}`),
      source: i18next.t(`events.eventSource.${event.eventSource}`),
    },
    //TODO: This should be the user that has been changed in case of User Event Later
    // users: [
    //   {label: "events.popUp.users.userID", values: event?.metadonneesUser?.userOid || ""},
    //   {label: "events.popUp.users.email", values: event?.metadonneesUser?.email || ""},
    //   {label: "events.popUp.users.firstName", values: event?.metadonneesUser?.prenom || ""},
    //   {label: "events.popUp.users.lastName", values: event?.metadonneesUser?.nom || ""},
    //   {label: "events.popUp.users.jobTitle", values: event?.metadonneesUser?.fonction || ""},
    //   {label: "events.popUp.users.phoneNumber", values: event?.metadonneesUser?.telephone || ""},
    //   {label: "events.popUp.users.roles", values: event?.metadonneesUser?.rolesUser || ""}
    // ],
    document: buildMetadonneesDocument(event),
    dossier: buildMetadonneesDossier(event),
    thirdParty: buildMetadonneesThirdparty(event),
    account: buildMetadonneesAccount(event),
  };
};

const buildMetadonneesAccount = (event) => {
  return [
    {
      label: "events.popUp.account.accountId",
      values: event?.metadonneesDO?.oid || "",
    },
    {
      label: "events.popUp.account.cptdo",
      values: event?.metadonneesDO?.cptDO || "",
    },
    {
      label: "events.popUp.account.accountName",
      values: event?.metadonneesDO?.nom || "",
    },
    {
      label: "events.popUp.account.socialName",
      values: event?.metadonneesDO?.denominationSociale || "",
    },
    {
      label: "events.popUp.account.localIdentifiers",
      values: event?.metadonneesDO?.identifiantLocal
        ? `${event?.metadonneesDO?.identifiantLocal} (Siren)`
        : "",
    },
  ]
}

const buildMetadonneesThirdparty = (event) => {
  const thirdparty = event.metadonneesFO;
  const identifiers = [];
  if(thirdparty?.identifiantLocal?.length === 14) identifiers.push(`${thirdparty?.identifiantLocal} (Siret)`);
  if(thirdparty?.siren) identifiers.push(`${thirdparty?.siren} (Siren)`)
  if(!_.isEmpty(thirdparty?.registrationNumbers)) {
    Object.keys(thirdparty?.registrationNumbers)?.forEach(regNbrLabel => identifiers.push(`${thirdparty?.registrationNumbers[regNbrLabel]} (${regNbrLabel})`))
  } 
          
  return [
    { label: "events.popUp.thirdParty.thirdPartyId", values: thirdparty?.oid || "" },
    { label: "events.popUp.thirdParty.cptfo", values: thirdparty?.cptFO || "" },
    { label: "events.popUp.thirdParty.socialName", values: thirdparty?.denominationSociale || "" },
    { label: "events.popUp.thirdParty.collectiveProcedure", values: "" },
    { label: "events.popUp.thirdParty.thirdPartyCode", values: thirdparty?.codeTiers || "" },
    { label: "events.popUp.thirdParty.duns", values: thirdparty?.duns || "" },
    { label: "tva", values: thirdparty?.tva || "" },
    {
      label: "events.popUp.thirdParty.localIdentifiers",
      values: identifiers
    },
    { 
      label: "events.popUp.thirdParty.completeness",
      values: thirdparty?.completude ? i18next.t("complete") : thirdparty?.completude === false ? i18next.t("incomplete") : ""
    },
    {
      label: "events.popUp.thirdParty.attributes",
      values: thirdparty?.attributeValues?.map(attr => {
        return (
          <div key={"attr-" + attr.label}>
            <span style={{fontWeight: "500"}}>{attr.label}</span>, {i18next.t(`attributeType.${attr.type}`)},{" "}
            <span style={{fontWeight: "500"}}>
              {getAttributeValueDisplay(attr.type, formatAttributeValues(attr))}
            </span>
          </div>
        );
      }) || ""
    },
    {
      label: "events.popUp.thirdParty.calculatedAttributes",
      values: (!_.isEmpty(thirdparty?.computedAttributesValue) && Object.keys(thirdparty?.computedAttributesValue)?.map(
        attrLabel => (`${attrLabel}, ${thirdparty?.computedAttributesValue[attrLabel]}`)
      )) || ""
    },
    {
      label: "events.popUp.thirdParty.indicators",
      values: (!_.isEmpty(thirdparty?.indicateurs) && Object.keys(thirdparty?.indicateurs)?.map(
        indicatorLabel => (`${indicatorLabel}, ${thirdparty?.indicateurs[indicatorLabel]}`)
      )) || ""
    },
  ]
}

const buildMetadonneesDossier = (event) => {
  return [
    {
      label: "events.popUp.dossier.dossierId",
      values: event?.metadonneesDossier?.dossierOid || "",
    },
    {
      label: "events.popUp.dossier.reference",
      values: event?.metadonneesDossier?.reference || "",
    },
    {
      label: "events.popUp.dossier.model",
      values: event?.metadonneesDossier?.modeleDossier || "",
    },
    {
      label: "events.popUp.dossier.lockedDossier",
      values: event?.metadonneesDossier?.locked ? "Oui" : "Non",
    },
    {
      label: "events.popUp.dossier.dossierStatus",
      values: i18next.t(
        `viewer.status.statusLabelDossier.${event?.metadonneesDossier?.statutDossier}`
      ),
    },
    {
      label: "events.popUp.dossier.endDate",
      values: event?.metadonneesDossier?.dateFinDossier
        ? moment(event?.metadonneesDossier?.dateFinDossier).format(
            i18next.t("date.fullFormatWithSecond")
          )
        : "",
    },
    {
      label: "events.popUp.dossier.description",
      values: event?.metadonneesDossier?.description || "",
    },
    {
      label: "events.popUp.dossier.attributes",
      values: event?.metadonneesDossier?.attributeValues?.map((attr) => {
        return (
          <div key={"attr-" + attr.label}>
            <span style={{fontWeight: "500"}}>{attr.label}</span>, {i18next.t(`attributeType.${attr.type}`)},{" "}
            <span style={{fontWeight: "500"}}>
              {getAttributeValueDisplay(attr.type, formatAttributeValues(attr))}
            </span>
          </div>
        );
      }),
    },
  ]
}

const buildMetadonneesDocument = (event) => {
  return event.metadonneesDocument ? [
    { label: "events.popUp.document.documentId", values: event.metadonneesDocument.documentOid },
    { label: "events.popUp.document.documentCode", values: event.metadonneesDocument.codeDocument },
    { label: "events.popUp.document.label", values: event.metadonneesDocument.typeDocument },
    { label: "events.popUp.document.status", values: i18next.t(`viewer.status.statusLabel.${event.metadonneesDocument.statut}`) },
    { label: "events.popUp.document.scope", values: event.metadonneesDocument.portee },
    { label: "events.popUp.document.metadata", values: Object.keys(event.metadonneesDocument.metadonnees)?.map((metadataKey) => {
      return (
        <div key={"metadataKey-" + metadataKey}>
          <div dangerouslySetInnerHTML={{__html: metadataKey}} /> : {event.metadonneesDocument.metadonnees[metadataKey]}
        </div>
      );
      })
    }
  ] : []
}

export const buildEventChanges = (event) => {
  const previousValues = event?.previousValue?.previousValues;
  let changes = [];
  if(previousValues) {
    const eventChangesKeys = Object.keys(previousValues);
    for (const changeKey of eventChangesKeys) {
      const changeValue = event?.previousValue?.previousValues?.[changeKey];
      switch (changeKey) {
        case "note":
          const notesDiffsIds = getArrayDifference(
            changeValue.before,
            changeValue.after,
            "noteOid",
            "contenu"
          )?.map(note => note.noteOid);
          notesDiffsIds?.forEach((noteOid) => {
            const noteBefore = changeValue.before?.find(x => x.noteOid === noteOid);
            const noteAfter = changeValue.after?.find(x => x.noteOid === noteOid);
            // Comment content change
            changes.push({
              label: `Notes`,
              before: noteBefore?.contenu,
              after: noteAfter?.contenu,
            });
            // All mentions 
            if(!_.isEmpty(noteBefore?.utilisateursMentionnes)) {
              changes.push({
                label: `Anciens utilisateurs`,
                before: noteBefore?.utilisateursMentionnes?.map(user => `${user.prenom} ${user.nom}`),
              });
            }
            if(!_.isEmpty(noteAfter?.utilisateursMentionnes)) {
              changes.push({
                label: `Nouveaux utilisateurs`,
                after: noteAfter?.utilisateursMentionnes?.map(user => `${user.prenom} ${user.nom}`),
              });
            }
          });
          break;
  
        case "attributs":
          const attributeDiffsByLabel = getArrayDifference(
            changeValue.before,
            changeValue.after,
            "label",
            "values"
          );
          attributeDiffsByLabel?.forEach((diff) => {
            changes.push({
              label: `Attribut ( ${diff.label} )`,
              before: getAttributeValueDisplay(diff.type, formatAttributeValues({values: diff.before, type: diff.type})),
              after: getAttributeValueDisplay(diff.type, formatAttributeValues({values: diff.after, type: diff.type})),
            });
          });
          break;
  
        case "set_documentaire":
          if(!_.isEmpty(changeValue.before)) {
            changes.push({
              label: `Ancien set documentaire`,
              before: changeValue.before?.map(doc => `${doc.documentCode} - ${doc.documentLibelle}`),
            });
          }
          if(!_.isEmpty(changeValue.after)) {
            changes.push({
              label: `Nouveau set documentaire`,
              after: changeValue.after?.map(doc => `${doc.documentCode} - ${doc.documentLibelle}`),
            });
          }
          break;
  
        default:
          changes.push({
            label: i18next.t(`events.movement.${changeKey}`),
            before: formatChangeValue(changeKey, changeValue.before),
            after: formatChangeValue(changeKey, changeValue.after),
          });
          break;
      }
    }
  }
  //The changes for theses two events are stored in action instead of previousValues
  if(["DOCUMENT_ANSWER_ACCEPTED", "DOCUMENT_ANSWER_DENIED"].includes(event?.eventType)) {
    changes.push({
      label: "Action",
      after: event.metadonneesDocument?.action?.action,
      withoutArrow: true,
    })
    changes.push({
      label: "Motif",
      after: event.metadonneesDocument?.action?.motifDetail,
      withoutArrow: true,
    })
  }
  return changes;
};

// Get the difference between two values arrays
const getArrayDifference = (arr1, arr2, id, targetValues) => {
  const mergedArray = _.unionBy(arr1, arr2, id);
  const res =  _.map(mergedArray, (item) => ({
    ...item,
    before: getDiffValue(arr1, item, id, targetValues),
    after: getDiffValue(arr2, item, id, targetValues),
  }))
  .filter((x) => !_.isEqual(x.before, x.after));
  return res;
};

const getDiffValue = (arr, item, id, targetValues) => {
  const res = _.find(arr, { [id]: item[id] })?.[targetValues];
  // eslint-disable-next-line
  return typeof res === 'array' ? res?.sort() : res;
}

const formatChangeValue = (key, value) => {
  if(key === 'statut_dossier') {
    return i18next.t(`viewer.status.statusLabelDossier.${value}`)
  }
  if(key === 'statut') {
    return i18next.t(`viewer.status.statusLabel.${value}`)
  }
  if (isDateDiff(key)) {
    return value !== "JAMAIS" ? moment(value, "DD-MM-YYYY HH:mm:ss").format(i18next.t("date.dateTimeFormat")) : value;
  }

  if(typeof value === 'object') {
    JSON.stringify(value);
  }

  return value;
};

// Verify if the diff key is for a date to correctly parse it.
const isDateDiff = (diffKey) => {
  return ["date_de_fin", "date_expiration"].includes(diffKey);
};

export const buildEventPageRow = (event) => ({
  date: event.dateEvent,
  eventName: i18next.t(`eventNames.${event.eventType}`),
  eventType: event.eventType,
  table: i18next.t(`events.eventTable.${event.eventCategory}`),
  eventSource: i18next.t(`events.eventSource.${event.eventSource}`),
  userId: event.metadonneesUser?.userOid,
  userMail: event.metadonneesUser?.email,
  roles: event.metadonneesUser?.rolesUser,
  id: event.eventId,
  eventId: event.eventId,
  all: event,
});