import { Context } from "../../../states/Store";
import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ERROR } from "../../../services/common/Constants";
import {
  connectToAccount,
  getAccountDossierTypes,
  getAccountsFromToken,
} from "../../../services/edge/AccountService";
import { useRoles } from "./useRoles";
import { partition as lodashPartition } from "lodash";

export function useAccounts() {
  const [state, dispatch] = useContext(Context);
  const { accountId } = useParams();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const history = useHistory();
  useRoles();

  // Fetch User Accounts
  useEffect(() => {

    async function getAccounts() {
      try {
        const response = await getAccountsFromToken(dispatch);

        if (response?.content) {
          const fetchedAccounts = response.content;
          const errMsgCode = response.messages?.error && response.messages?.error[0]?.code;
          if (fetchedAccounts === null || fetchedAccounts.length <= 0 || errMsgCode === '1000') {
            history.push("/unauthorized");
          }
          const sortedAccounts = fetchedAccounts.sort((a, b) => a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase()));
          dispatch({ type: "ACCOUNTS", accounts: sortedAccounts });
          setAccounts(sortedAccounts);
        }
      } catch (e) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }
    
    if (state.isAuthDone) {
      getAccounts();
    }
  }, [dispatch, state.isAuthDone, history]);

  useEffect(() => {
    async function connectToSelectedAccount(accId) {
      try {
        const response = await connectToAccount(accId, dispatch)
        if (response?.content) {
          const errMsgCode = response.messages?.error && response.messages?.error[0]?.code;
          if (errMsgCode === '1000') {
            history.push("/unauthorized");
          }
        }
      } catch (e) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }
    if (accounts && accounts.length > 0) {
      if (
        accountId &&
        accounts.findIndex((acc) => acc.id.toString() === accountId) === -1
      ) {
        history.push("/");
        dispatch({
          type: "ALERT",
          alert: {
            type: ERROR,
            msg: ["INVALID_ACCOUNT", "INVALID_ACCOUNT_REDIRECT"],
          },
        });
      } else {
        let selectedAcc;
        // Account is in Url
        if (accountId) {
          localStorage.setItem("SELECTED_ACCOUNT", accountId);
          selectedAcc = accounts.find((x) => x.id === parseInt(accountId));
          // Else we get the account in storage
        } else if (localStorage.getItem("SELECTED_ACCOUNT")) {
          selectedAcc = accounts.find(
            (x) => x.id === parseInt(localStorage.getItem("SELECTED_ACCOUNT"))
          );
        }
        // If none of them we get the first account of the list
        if (!selectedAcc) {
          selectedAcc = accounts[0];
        }
        setSelectedAccount(selectedAcc);
        connectToSelectedAccount(selectedAcc.id);
        dispatch({ type: "SELECTED_ACCOUNT", account: selectedAcc });
      }
    }
  }, [accounts, accountId, dispatch, history]);

  useEffect(() => {
    async function getTypesDossiers() {
      const results = await getAccountDossierTypes(state.account.id, dispatch);
      const sortedDossiersTypes = results.content?.sort((a, b) => a.libelle?.toLowerCase()?.localeCompare(b.libelle?.toLowerCase()));
      const [activeTypes, inactiveTypes] = lodashPartition(sortedDossiersTypes, 'active');
      dispatch({ type: "DOSSIER_TYPES", dossierTypes: activeTypes });
      dispatch({ type: "INACTIVE_DOSSIER_TYPES", dossierTypes: inactiveTypes });
    }
    if(state.account) {
      getTypesDossiers();
    }
  }, [state.account, dispatch])

  return {
    selectedAccount,
    accounts
  };
}
