import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import EAIcon from "../EAIcon";

const DataTableActions = ({ actions }) => {
  return (
    <>
      {actions.map((action, index) => (
          <Tooltip title={action.label} key={index} arrow>
          <span style={action.keepWidth ? { width: 28 } : null} className="dt" >
            {!action.isHidden ? (
              <IconButton
                size="small"
                onClick={action.action}
                disabled={action.isDisabled}
                style={action.isDisabled ? { opacity: .5} : null}
              >
                <EAIcon icon={action.icon} alt={action.label} />
              </IconButton>
            ) : ( "" )}
          </span>
        </Tooltip>
      ))}
    </>
  );
};

export default DataTableActions;
