import {DialogContentText, Typography} from "@mui/material";
import i18next from "i18next";
import React from "react";
import useStyles from "../style/js-style/components/FeedBackPopUpStyle";
import clsx from "clsx";

function FeedBackPopUp({
   onClick,
   title = false,
   imgSrc,
   isMultipleAttributesSelected,
   variant= "grey",
   content = false,
   imgSize = "regular",
   ...othersProps
}) {
  const { classes } = useStyles();

  const getIconClass = (variant) => {
    return variant === "valid"
      ? classes.feedBackContentValid
      : variant === "grey"
        ? classes.feedBackContentGrey
        : classes.feedBackContent
  }

  return (
    <DialogContentText
      component={"div"}
      className={classes.feedBackWrapper}
      {...othersProps}
    >
      {/* Title + cross to close */}
      {title ? (
        <>
          <img
            src={process.env.PUBLIC_URL +  "/img/icons/cross.svg"}
            alt="cross"
            onClick={onClick}
            className={classes.feedBackCross}
          />
          <Typography variant={"h2"} className={classes.feedBackTitle}> {title} </Typography>
        </>
      ) : "" }

      {/* Main content */}
      <div className={classes.feedBackMessageWrapper}>
        <div>
          {/* Icon */}
          <span className={ clsx(classes.feedBackContentGeneral, getIconClass(variant))}>
            <img src={process.env.PUBLIC_URL +  imgSrc}
                 alt="icon"
                 className={
                  imgSize === "regular"
                    ? classes.feedBackImg
                    : classes.feedBackImgSmall
                }
            />
          </span>
        </div>
        <div>

        {/* Message */}
          <span className={classes.contentMessage}>
            {content
              ? content
              : isMultipleAttributesSelected
              ? `${i18next.t("attributes.deletedMultiInfo")} `
              : `${i18next.t("attributes.deletedSingleInfo")}`
            }
        </span>
        </div>
      </div>
    </DialogContentText>
  );
}

export default FeedBackPopUp;
