import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  appBarToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  appBarToolbarUser: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    top: "0",
    right: "0",
    zIndex: 5,
  },
  adminIcon: {
    cursor: "pointer",
  },

  // Circle with initial
  appBarBloc: {
    display: "flex",
    alignItems: "center",
  },
  profilMenuName: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#1A1919",
    margin: "20px"
  },
  profilMenuRole: {
    fontWeight: 400,
    fontSize: "12px",
    color: theme.palette.grey.grey500,
    margin: "20px",
  },
  // MenuProfilItem
  profilMenuWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  profilMenuItemText: {
    fontSize: "14px",
    margin: "0px 4px",
  },
  profilMenuDivider: {
    margin: "13px 0",
  },
  profilMenuImgWrapper:{
    margin: "0 12px 0 0",
    width: "20px",
    height: "20px",
  },
  navItem:{
    marginRight: "1rem",
  },
  logo: {
    width: "160px",
  },
  arrowDown: {
    position: "absolute",
    right: "26px",
  },
  menuItems: {
    margin: 0,
    cursor: "pointer",

    "& :active": {
      backgroundColor: theme.palette.primary.primary200 + " !important",
    },
    "& :hover": {
      backgroundColor: theme.palette.grey.grey100,
    },
  },
  menuItemsDisabled: {
    opacity: 0.5
  },
  menuItemsRed: {
    "& :active": {
      backgroundColor: theme.palette.error.error100 + " !important",
    },
  }
}));

export default useStyles;
