import NotesList from "containers/notes/NotesList";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { createNoteDocument, deleteNoteDocument, getAllNoteDocument, updateNoteDocument } from "services/edge/NoteService";
import { Context } from "states/Store";
import { useQuery } from "config/Config";

function PanelNotes({ evidence }) {
  const [state] = useContext(Context);
  const [documentNotes, setDocumentNotes] = useState();
  const { thirdpartyId } = useParams();
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const [selectedThirdpartyId, setSelectedThirdpartyId] = useState();
  const queryThirdpartyId = useQuery().get("thirdpartyId");

  useEffect(() => {
    setSelectedThirdpartyId(thirdpartyId || queryThirdpartyId);
  }, [thirdpartyId, queryThirdpartyId])

  const fetchDocumentNotes = useCallback(() => {
    async function getDocumentNotes() {
      try {
        const result = await getAllNoteDocument(
          state.account.id,
          selectedThirdpartyId,
          evidence.responseId,
          null
        );
        setDocumentNotes(result.content);
      } catch (error) {
        setDocumentNotes(null);
      }
    }

    if (state.account && evidence && selectedThirdpartyId && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_NOTES)) {
      getDocumentNotes();
    } else {
      setDocumentNotes(null);
    }
  }, [state.account, evidence, selectedThirdpartyId, hasAnyOfPrivileges]);

  useEffect(() => {
    fetchDocumentNotes();
  }, [fetchDocumentNotes]);

  const createNoteDocumentCallback = useCallback(
    async (noteContent) => {
      await createNoteDocument(
        state.account.id,
        selectedThirdpartyId,
        evidence.responseId,
        noteContent
      );
    },
    [state.account, selectedThirdpartyId, evidence]
  );

  const updateNoteDocumentCallback = useCallback(
    async (noteOId, noteContent) => {
      await updateNoteDocument(
        state.account.id,
        selectedThirdpartyId,
        evidence.responseId,
        noteOId,
        noteContent
      );
    },
    [state.account, selectedThirdpartyId, evidence]
  );

  const deleteNoteDocumentCallback = useCallback(async (noteOId) => {
    await deleteNoteDocument(
      state.account.id,
      selectedThirdpartyId,
      evidence.responseId,
      noteOId
    );
  }, [state.account, selectedThirdpartyId, evidence]);

  return documentNotes && (
    <NotesList
      notes={documentNotes}
      fetchNotes={() => fetchDocumentNotes()}
      createNote={createNoteDocumentCallback}
      updateNote={updateNoteDocumentCallback}
      deleteNote={deleteNoteDocumentCallback}
      type="DOCUMENT"
    />
  );
}

export default PanelNotes;
