import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  FormHelperText,
  TextField,
  Chip,
} from "@mui/material";
import { get as lodashGet } from 'lodash';

import EAInputBloc from '../common/EAInputBloc';
import useStyles from "../../style/js-style/containers/administration/rules/RuleFormsStyle";


const MultipleValueInputChips = ({ values, onDelete }) => {
  const { classes } = useStyles();

  if (!values || values.length === 0) {
    return (
      <></>
    );
  }

  return (
    <div className={classes.chipsContainer}>
      {values.map((value, index) => {
        return (
          <Chip
            className={values.length === (index + 1) && values.length % 2 !== 0 ? classes.largeChip : classes.chip}
            key={value}
            label={value}
            style={{marginBottom: "5px", marginRight: "5px"}}
            deleteIcon={
              <img
                src={process.env.PUBLIC_URL + "/img/icons/cross.svg"}
                alt="close"
                onMouseDown={(event) => event.stopPropagation()}
                className={classes.deleteItemIcon}
              />
            }
            sx={{
              borderRadius: 0,
              backgroundColor: "#F2F3F7",
              color: "#535362",
              padding: 0,
              fontSize: "12px",
              fontWeight: 500,
              height: "auto",
              border: 0,
              maxWidth: "125px",
              "& .MuiChip-label": {
                paddingLeft: "4px",
                paddingRight: "12px",
              },
            }}
            onDelete={(e) => {
              e.preventDefault();
              onDelete(value);
            }}
          />
        )
      })}
    </div>
  );
};


const CalculatorMultipleValueInput = ({
  fieldName,
  isRequired = false,
  placeholder = '',
  label = '',
  validate = () => { return true },
  variant = 'standard',
  uxVariant = "standard"
}) => {
  const formMethods = useFormContext();
  const { classes } = useStyles();
  const [selectedValues, setSelectedValues] = useState([]);
  //isValid not really used as we do a double check
  // const [isValid, setIsValid] = useState(true);

  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    clearErrors,
  } = formMethods;

  const onDelete = (value) => {
    let newValues = selectedValues.filter((entry) => entry !== value);
    setValue(fieldName, newValues);
  }

  let fieldValue = useWatch({ name: `${fieldName}` });

  useEffect(() => {
    setSelectedValues(fieldValue);
  }, [fieldValue])

  return (
    <EAInputBloc label={label} customLabelStyle={{ fontSize: "14px", fontWeight: 700 }}>
      <Controller
        control={control}
        name={fieldName}
        rules={{
          validate: (value) => {
            if (isRequired) {
              const isEmpty = value.length === 0;
              if (isEmpty) {
                return i18next.t("infos.required");
              } else {
                clearErrors(fieldName);
              }
            }
          }
        }}
        render={() => (
          <TextField
            id={`${fieldName}-input-id`}
            variant={variant}
            fullWidth
            className={classes.inputText}
            error={!!lodashGet(errors, `${fieldName}`)}
            placeholder={placeholder}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                if (validate(e.target.value)) {
                  let newValues = [...selectedValues.filter((entry) => entry !== e.target.value), e.target.value]
                  setValue(fieldName, newValues);
                  trigger(fieldName)
                  e.target.value = '';
                }
              }
            }}
            InputProps={{
              startAdornment: <MultipleValueInputChips values={selectedValues} onDelete={onDelete} placeholder={placeholder} />
            }}
            style={{position: "relative", top: uxVariant === "criteriaInput" ? "0px" : ""}}
          />
        )}
      />

      {
        !!lodashGet(errors, `${fieldName}`) && (
          <FormHelperText className="Mui-error">
            {lodashGet(errors, `${fieldName}.message`)}
          </FormHelperText>
        )
      }
    </EAInputBloc>
  );
}

export default CalculatorMultipleValueInput;
