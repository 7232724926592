import React, { useContext, useState } from 'react';
import {
	Grid,
	TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import i18next from 'i18next';
import { Context } from '../../../states/Store';
import useStyles from "../../../style/js-style/containers/thirdparties/sheet/ThirdpartyStopDialogStyle";
import { isEmpty } from '../../../services/common/Utils';
import { useHistory } from 'react-router';
import { stopThirdparty } from '../../../services/edge/ThirdpartyService';
import { ERROR } from '../../../services/common/Constants';
import EADialog from "../../../components/common/EADialog";
import EAInputBloc from "../../../components/common/EAInputBloc";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import EAIcon from "../../../components/common/EAIcon";
import EAButton from "../../../components/common/EAButton";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

const ThirdpartyStopDialog = ({ thirdpartyId, isOpen, handleClose }) => {
	const { classes } = useStyles();
	const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
	const history = useHistory();
	const [state, dispatch] = useContext(Context);
	const [isStopped, setIsStopped] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const id = isOpen ? 'stop-thirdparty-dialog' : undefined;
	const commentMaxLength = 100;
	const {
		control,
		formState: { errors },
		handleSubmit
	} = useForm({ mode: "onChange" });

	const stopThirdpartyHandler = async (data) => {
		if(isEmpty(errors) && !isSubmitting) {
			startLoading();
			const deactivationReason = data.deactivationReason;
			try {
				setIsSubmitting(true);
				await stopThirdparty(state.account.id, thirdpartyId, deactivationReason, dispatch);
				setIsStopped(true);
				setIsSubmitting(false);
			} catch (error) {
				dispatch({ type: "ALERT", alert: { type: ERROR, msg: "STOP_THIRDPARTY_ERROR" } });
				setIsSubmitting(false);
			}finally {
				stopLoading();
			}
		}
	}

	const handleDialogClose = () => {
		handleClose();
		if(isStopped) {
			history.push(`/${state.account.id}/thirdparties`);
		}
	}

	return (
    <EADialog
			id={id}
			isOpen={isOpen}
			onCancel={handleDialogClose}
			style={{ zIndex: 1301 }}
			hasActions={!isStopped}
			maxWidth="sm"
			fullWidth
			displayBtn={false}
			isFeedBack={isStopped}
			validateBtnLabel={"valid"}
			isValidateDisabled={isRequestLoading}
		>

				{/* Pop-up Archive */}
				{!isStopped && (
					<form onSubmit={handleSubmit(stopThirdpartyHandler)}>
						<Grid container spacing={2} alignItems="center">

							{/* Header : icon + title */}
							<Grid item xs={12} className={classes.popupHeader}>
								<EAIcon icon={"inventory"} className={classes.topHeaderIcon} />
								<span className={classes.popUpTitle}>{i18next.t("dossierSheet.popUpGoToArchiveThird")}</span>
							</Grid>

							{/* Main content : comment */}
							<Grid item xs={12}>
								<EAInputBloc label={`${i18next.t('comment')} : `}>
									<Controller
										control={control}
										name="deactivationReason"
										rules={{
											maxLength: {
												value: commentMaxLength,
												message: i18next.t("infos.E011_VAR", {
													input: i18next.t("TheComment"),
													maxLength: commentMaxLength,
												}),
											},
									}}
										render={({ field }) => (
											<TextField
												id="deactivation-reason"
												variant="outlined"
												error={!!errors.deactivationReason}
												fullWidth
												helperText={
													!!errors.deactivationReason ? errors.deactivationReason.message : ""
												}
												{...field}
												multiline
												rows={2}
											/>
										)}
									/>
								</EAInputBloc>
							</Grid>

							{/* Submit & cancel */}
							<Grid container item xs={12} className={classes.btnWrapper}>
								<EAButton
									onClick={handleClose}
									content={i18next.t('cancel')}
									colorVariant="secondary"
								/>
								<EAButton
									type="submit"
									content={i18next.t('valid')}
									disabled={isRequestLoading}
								/>
							</Grid>
						</Grid>
					</form>
				)}

				{/*Popup Feed back*/}
				{isStopped && (
					<FeedBackPopUp
					onClick={handleDialogClose}
					title={i18next.t('thirdpartySheet.thirdpartyStoppedTitle')}
					imgSrc={"/img/icons/inventory.svg"}
					content={
						<>
							{i18next.t('thirdpartySheet.thirdpartyStopped')} <br/>
							{i18next.t('thirdpartySheet.thirdpartyStoppedTip')}
						</>
					}
					/>
				)}
			</EADialog>
    );
};

export default ThirdpartyStopDialog;
