import React from "react";
import DataTableActions from "components/common/datatable/DataTableActions";
import i18next from "i18next";
import {
  EADataGridCell,
  cellWithTooltip
} from "components/common/datatable/EADataGrid";
import MainHeaderCell from "components/common/datatable/MainHeaderCell";
import { statusEngCode } from "services/common/Constants";
import DocStatusChip from "components/common/DocStatusChip";
import { formatDate } from "services/common/Utils";

const dossierModelTableActions = (
  dossierModel,
  openEditDialog,
  openDeleteDialog
) => {
  return [
    {
      label: i18next.t("modify"),
      icon: "edit_grey",
      action: () => openEditDialog(dossierModel),
      isDisabled: false
    },
    {
      label: i18next.t("delete"),
      icon: "delete_grey",
      action: () => openDeleteDialog(dossierModel),
      isDisabled: false
    },
  ];
};

export const documentManagementListColumns = (openEditDialog, openDeleteDialog) => {
  return [
    {
      field: "name",
      headerName: i18next.t("demandes.documentManagement.name"),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("demandes.documentManagement.name")} />
      ),
      renderCell: (params) => cellWithTooltip(params.value, false),
    },
    {
      field: "code",
      headerName: i18next.t("demandes.documentManagement.code"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "presentInActiveModel",
      headerName: i18next.t("demandes.documentManagement.presentInActiveModel"),
      flex: 0.1,
      type: "singleSelect",
      valueOptions: [
        { value: true, label: i18next.t("demandes.documentManagement.yes") },
        { value: false, label: i18next.t("demandes.documentManagement.no") },
      ],
      valueFormatter: (params) =>
        params.value === true
          ?
          i18next.t("demandes.documentManagement.yes")
          :
          i18next.t("demandes.documentManagement.no"),
      renderCell: (params) => (
        <EADataGridCell
        >
          {i18next.t(`demandes.documentManagement.${params.value ? "yes" : "no"}`)}
        </EADataGridCell>
      )
    },
    {
      field: "category",
      headerName: i18next.t("demandes.documentManagement.category"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, false),
    },
    {
      field: "addedOn",
      headerName: i18next.t("demandes.documentManagement.modifiedOn"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
        >
          {formatDate(params.value)}
        </EADataGridCell>
      )
    },
    {
      field: "addedBy",
      headerName: i18next.t("demandes.documentManagement.modifiedBy"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value, true),
    },
    {
      field: "defaultStatus",
      headerName: i18next.t("demandes.documentManagement.defaultStatus"),
      flex: 0.1,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: [
        { value: "ACTION_REQUIRED", label: i18next.t(`viewer.status.statusLabel.${statusEngCode.ACTION_REQUIRED}`) },
        { value: "VALID", label: i18next.t(`viewer.status.statusLabel.${statusEngCode.VALID}`) }
      ],
      renderCell: (params) => (
        <EADataGridCell
          tooltipLabel={i18next.t(`viewer.status.statusLabel.${params.row.statusCode}`)}
        >
          <DocStatusChip statusCode={params.value} />
        </EADataGridCell>
      ),
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.1,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="dt" style={{ textAlign: "center" }}>
            <DataTableActions
              actions={dossierModelTableActions(
                [params.row.id],
                openEditDialog,
                openDeleteDialog
              )}
            />
          </div>
        );
      },
    },
  ];
};
