import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        alignItems: "center"
    },
    icon: {
        padding: "18px",
        borderRadius: "150px",
        backgroundColor: theme.palette.primary.primary100
    },
    title : {
        fontSize: "20px",
        fontWeight:  600,
        color: theme.palette.grey.grey700
    },
    description: {
        fontSize: "14px",
        fontWeight:  400,
        color: theme.palette.grey.grey500,
        padding: "0 62px",
        textAlign: "center"
    }
}));

export default useStyles;
