import {Box, Grid, Paper, Tab, Tabs} from "@mui/material";
import React, {useContext, useState} from "react";
import AttributesDataTable from "./AttributesDataTable";
import i18next from "i18next";
import EAIcon from "../../../components/common/EAIcon";
import EATabPanel from "../../../components/common/EATabPanel";
import useStyles from "../../../style/js-style/containers/administration/attributes/AttributesStyle";
import {PrivilegeEnum, RoleBasedAccessControlContext} from "../../../services/common/RolesUtils";

function Attributes() {
  const { classes } = useStyles();
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const [selectedTab, setSelectedTab] = useState(
    hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE) ? 0
      : hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE) && 1
  );

  const handleTabChange = (event, newValue) => setSelectedTab(newValue);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container justifyContent="space-evenly" spacing={0}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ marginTop: "17px" }}>

            {/* Tabs */}
            <Box className={classes.tabsWrapper} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="attributs tabs"
              >
                < Tab
                  label={i18next.t("adminSettings.thirdpartyAttributes")}
                  icon={<EAIcon icon="thirdparty-label_grey" style={{filter: selectedTab === 0 && "brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(336%) hue-rotate(79deg) brightness(96%) contrast(97%)"}} />}
                  iconPosition="start"
                  {...a11yProps(0)}
                  className={classes.tab}
                  disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE)}
                />
                <Tab
                  label={i18next.t("adminSettings.dossierAttributes")}
                  icon={<EAIcon icon="dossier-label_grey"
                  style={{filter: selectedTab === 1 && "brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(336%) hue-rotate(79deg) brightness(96%) contrast(97%)"}}/>}
                  iconPosition="start"
                  {...a11yProps(1)}
                  className={classes.tab}
                  disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE)}
                />
              </Tabs>
            </Box>

            {/* ThirdParty tab */}
            {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE) &&
              <EATabPanel value={selectedTab} index={0} variant={"withoutBackground"}>
                <AttributesDataTable bondType={"thirdparty"}/>
              </EATabPanel>
            }

            {/* Dossier tab */}
            {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE) &&
              <EATabPanel value={selectedTab} index={1} variant={"withoutBackground"}>
                <AttributesDataTable bondType={"dossier"}/>
              </EATabPanel>
            }

          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Attributes;
