import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: "10px",
    filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.07))",
    width: "calc(100% + 16px)",
  },
  headerWrapper: {
    backgroundColor: "white",
    height: "54px",
    borderRadius: "4px",
    padding: "0 17px",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  title: {
    fontFamily: "'Metric'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#16161E",
    textTransform: "uppercase",
    flexGrow: 1,
  },
  titleWithIconWrapper: {
    display: "flex",
    flexDirection: "row",
    minWidth: "384px",
  },
  titleWithIconWrapperMin: {
    display: "flex",
    flexDirection: "row",
    width: "384px",
    marginRight: "16px",
  },
  blocWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    minWidth: "350px",
    gap: "10px",
  },
  subItemsWrapper: {
    backgroundColor: "white",
    paddingBottom: "21px"
  },
  arrowIcon: {
    position: "absolute",
    top: "23px",
    right: "23px",
  },
  arrowIconReverse: {
    position: "absolute",
    top: "23px",
    right: "23px",
    transform: "rotate(180deg)",
  }
}));

export default useStyles;
