import React, {useContext} from "react";
import {Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import i18next from "i18next";
import useStyles from "../../style/js-style/components/common/EADialogStyle";
import EAButton from "./EAButton";
import {Context} from "../../states/Store";

const EADialog = ({
  isOpen,
  title,
  subTitle,
  hasActions = true,
  cancelBtnLabel = "cancel",
  onCancel,
  onClose, // If not defined we use onCancel
  validateBtnLabel = "validate",
  onValidate,
  isValidateDisabled = false,
  fullWidth = false,
  maxWidth = "sm",
  formId,
  children,
  variant = "default",
  btnSize = "regular",
  displayBtn = true,
  customBtnStyle,
  customDialogStyle,
  isFeedBack = false,
  isFixed = false,
  positionSetting = false,
  crossVisible = true,
  ...otherProps
}) => {
  const { classes } = useStyles();
  const [state] = useContext(Context)

  return (
    <Dialog
      className={classes.root}
      id={`${title} dialog`}
      open={isOpen}
      onClose={onClose || onCancel}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      style={{borderRadius: "0 !important"}}
      {...(isFixed
        ? {
          PaperProps:
            {
              sx : state?.drawerIsOpened
                ? { position: "fixed", top: positionSetting ? positionSetting.top : 32, right: -32, width: "calc(100% - 250px)", borderRadius: 0, maxHeight: "100vh !important" }
                : { position: "fixed", top: positionSetting ? positionSetting.top : 32, right: -32, width: "calc(100% - 55px)", borderRadius: 0, maxHeight: "100vh !important" }
            },
        }
        : {})}
      {...otherProps}
    >

      {/* Main content */}
      <DialogContent
        className={isFeedBack ? classes.rootFeed : ""}
        sx={customDialogStyle}
      >
        {title ? (
          <>
            {crossVisible &&
              <img src={process.env.PUBLIC_URL +  "/img/icons/cross.svg"} alt="cross"
                 onClick={onClose || onCancel}
                 className={classes.cross}
              />
            }
            <Typography
              variant={"h2"}
              className={classes.title}
            >
              <span style={{wordBreak: "break-word"}}>
                {title}
                <span style={{fontWeight: 400}}>
                  {subTitle}
                </span>
              </span>
            </Typography>
          </>
        ) : "" }
        {children}
      </DialogContent>

      {/* Cancel & confirm */}
      {(hasActions && displayBtn) && (
        <DialogActions
          className={
            variant === "rightControl"
              ? classes.btnRightWrapper
              : classes.btnWrapper
          }
          sx={customBtnStyle}
        >

          {/* Cancel */}
          <EAButton
            onClick={onCancel}
            content={i18next.t(cancelBtnLabel)}
            size={btnSize}
            colorVariant={"secondary"}
          />

          {/* Confirm */}
          <EAButton
            onClick={onValidate}
            content={i18next.t(validateBtnLabel)}
            size={btnSize}
            colorVariant={
              variant === "redValid"
                ? "error"
                : variant === "warning"
                  ? "warning"
                  : "primary"
             }
            disabled={isValidateDisabled}
            type="submit"
            form={formId}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EADialog;
