import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "5px 14px",
    zIndex: 2,
    backgroundColor: "white",
  },
  rootDisabled: {
    padding: "5px 18px",
    zIndex: 2,
    backgroundColor: theme.palette.grey.grey200,
  },
  rightBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leftBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    paddingLeft: "1.5rem !important",
    "& h3": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }
  },
  countDetail: {
    color: theme.palette.grey.grey300,
    fontSize: "0.8rem",
    fontWeight: 700,
    fontFamily: "Roboto",
  },
  separation: {
    color: theme.palette.grey.grey300,
    fontWeight: 700,
    margin: "0 0.5rem",
    fontFamily: "Roboto",
  },
  icon: {
    backgroundColor: theme.palette.primary.primary100,
    padding: "2px",
    borderRadius: "50%",
    marginRight: "11px",
    width: "24px",
  },
  divider: {
    border: "1px solid " + theme.palette.grey.grey100,
    position: "relative",
    width: "100%",
    top: "-12px",
  },
  sectionTitle: {
    fontSize: "16px",
    fontFamily: "Metric",
    fontWeight: 600,
  },
  concernWrapper: {
    position: "absolute",
    top: "-42px",
    left: "170px",
    color: theme.palette.grey.grey500,
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  concernCount: {
    fontSize: "28px",
    color: "#373534",
    margin: "0 3px",
  },
  concernType: {
    color: "#373534",
  },
  concernIcon: {
    marginRight: "7px"
  },
  deleteItemIcon: {
    width: "7px",
    height: "7px",
  },
  select: {
    "& .MuiInput-input": {
      fontSize: "14px"
    },
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
  },
  placeHolder: {
    color: theme.palette.grey.grey400,
    fontSize: "14px",
    fontWeight: 400,
    position: "relative",
    right: "12px",
  },
}));

export default useStyles;
