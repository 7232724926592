import React, { useEffect, useState } from "react";

import EATransparencyLineChart from "components/common/charts/EATransparencyLineChart";
import useStyles from "../../style/js-style/components/thirdparties/PanelTransparencyStyle";
import { Box } from "@mui/material";
import i18next from "i18next";
import EAIcon from "components/common/EAIcon";
import _ from "lodash";
import TransparencyMention from "./TransparencyMention";
import clsx from "clsx";
import EAFlag from "components/EAFlag";
import countries from "i18n-iso-countries";


const PanelTransparency = ({ transparency }) => {
  const [previousScore, setPreviousScore] = useState();
  const [lastScore, setLastScore] = useState();
  const [evolution, setEvolution] = useState(0);
  const [countryName, setCountryName] = useState("");
  const { classes } = useStyles();

  useEffect(() => {
    if (transparency && !_.isEmpty(transparency)) {
      setPreviousScore(transparency[transparency.length - 2]);
      setLastScore(transparency[transparency.length - 1]);
    }
  }, [transparency]);

  useEffect(() => {
    if (lastScore && previousScore) {
        setEvolution(lastScore.countryScore - previousScore.countryScore);
    }
  }, [lastScore, previousScore]);

  useEffect(() => {
    if (lastScore) {
      setCountryName(countries.getName( lastScore?.countryIso2, i18next.language));
    }
  }, [lastScore]);

  const hex2rgba = (hex, alpha = 1) => {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    } else {
      return `rgba(0,0,0,)`;
    }
  };

  return (
    <>
      {(transparency && _.isEmpty(transparency))
        // Transparency without Data
        ? (
          <Box className={classes.rootNoData}>
            <EAIcon icon="search_off" />
            <div className={classes.noDataText}>
              {i18next.t("transparency.scoreUnpublished")}
            </div>
            <div className={classes.transparencyLogoWrapper}>
              <TransparencyMention year={lastScore?.cpiYear} />
            </div>
          </Box>
        )
        // Transparency with Data
        : (
          <div className={classes.panelWithDataRoot}>

            {/* Score */}
            <div className={classes.scoreRoot} style={{ background: `linear-gradient(145deg, ${hex2rgba(lastScore?.color, 0.2)} 0%, rgba(255,255,255,1) 100%)` }}>
              <div>
                <div className={classes.title}>{i18next.t("transparency.scoreCPI")}</div>
                <span className={clsx(classes.dt, classes.subTitle)}>{lastScore?.cpiYear}</span>
              </div>

              <div className={clsx(classes.dt, classes.score)} style={{color: lastScore?.color}}> {lastScore?.countryScore} </div>
            </div>

            {/* Country */}
            <div className={classes.CountryRoot}>
              <div>
                <div className={classes.title}> {i18next.t("transparency.country")} </div>
                <div className={classes.subTitle}> { countryName } </div>
              </div>

              <div className={classes.countryFlagWrapper}>
                <EAFlag countryCode={lastScore?.countryIso2} flagWidth={countryName?.length > 17 ? "50px" : "65px"} flagHeight={countryName?.length > 17 ? "50px" : "65px"}/>
              </div>
            </div>

            {/* Chart */}
            <div className={classes.GraphRoot} style={{ boxShadow: `0px 2.76726px 2.21381px 0px ${hex2rgba(lastScore?.color, 0.02)}, 0px 6.6501px 5.32008px 0px ${hex2rgba(lastScore?.color, 0.03)}, 0px 12.52155px 10.01724px 0px ${hex2rgba(lastScore?.color, 0.04)}, 0px 22.33631px 17.86905px 0px ${hex2rgba(lastScore?.color, 0.04)}, 0px 41.77761px 33.42209px 0px ${hex2rgba(lastScore?.color, 0.05)}, 0px 100px 80px 0px ${hex2rgba(lastScore?.color, 0.07)}` }}>
              <div className={classes.graphTitleWrapper}>
                <div className={classes.title}>{i18next.t("transparency.evolution")}</div>
                <span className={clsx(classes.dt, classes.subTitle)}>{i18next.t("transparency.fiveYears")}</span>
              </div>
              <div className={classes.graphYearWrapper}>
                <div className={classes.graphYearItem}>{lastScore?.cpiYear - 5}</div>
                <div className={classes.graphYearItem}>{lastScore?.cpiYear - 4}</div>
                <div className={classes.graphYearItem}>{lastScore?.cpiYear - 3}</div>
                <div className={classes.graphYearItem}>{lastScore?.cpiYear - 2}</div>
                <div className={classes.graphYearItem}>{lastScore?.cpiYear - 1}</div>
                <div className={classes.graphYearItem}>{lastScore?.cpiYear}</div>
              </div>
              <EATransparencyLineChart id={"transparencyLineChart"} data={transparency}></EATransparencyLineChart>
              <div className={classes.evolutionInfo}>
              {evolution > 0 && (
                <>
                  <div className={classes.evolutionNegative}>
                    <EAIcon style={{ width: "18px" }} icon="arrow/arrow_upward_white" />
                  </div>
                  <div>
                    <span className={classes.greenBadge}>{`+ ${evolution}`}</span> <span style={{color: "#656575", fontSize: "12px"}}>{i18next.t("transparency.since", { year: previousScore?.cpiYear })}</span>
                  </div>
                  </>
                )}
                {evolution === 0  && (
                  <>
                  <div className={classes.evolutionStable}>
                    <EAIcon style={{ width: "18px" }} icon="equal_white" />
                  </div>
                  <div >
                  <span style={{color: "#656575", fontSize: "12px"}}>{i18next.t("transparency.since", { year: previousScore?.cpiYear })}</span>
                </div>
                </>
                )}
                {evolution < 0  && (
                  <>
                  <div className={classes.evolutionPositive}>
                    <EAIcon style={{ width: "18px" }} icon="arrow/arrow_downward_white" />
                  </div>
                  <div>
                  <span className={classes.redBadge}>{evolution}</span> <span style={{color: "#656575", fontSize: "12px"}}>{i18next.t("transparency.since", { year: previousScore?.cpiYear })}</span>
                </div>
                </>
                )}
              </div>
            </div>

            {/* Mention */}
            <div className={classes.transparencyInfo}>
              <TransparencyMention year={lastScore?.cpiYear} />
            </div>
          </div>
        )
      }
    </>
  )
};
export default PanelTransparency;
