import React from "react";
import i18next from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
    FormHelperText,
    MenuItem,
    Select
} from "@mui/material";
import { find, get as lodashGet } from "lodash";
import EAInputBloc from "../common/EAInputBloc";
import { EASelectChip } from "./EASelectChip";
import useStyles from "../../style/js-style/containers/administration/rules/RuleFormsStyle";

const RuleSelectInput = ({
    fieldName,
    isRequired = false,
    label = '',
    placeholder = '',
    options,
    onChange = () => { },
    validate = () => { return null },
    variant = 'standard', //mui variant
    labelVariant = 'standard', //standard, chip
    index,
}) => {
    const { classes } = useStyles();
    const formMethods = useFormContext();
    const {
        control,
        formState: { errors },
        setValue,
        trigger,
        clearErrors,
    } = formMethods;

    return (
        <EAInputBloc label={label} customLabelStyle={{ fontSize: "14px" }} customChildStyle={{ marginTop: "0px" }} index={index}>
            <Controller
                control={control}
                name={fieldName}
                rules={{
                    validate: (value) => {
                        if (isRequired) {
                            const isEmpty = !value || value.length === 0;
                            if (isEmpty) {
                                return i18next.t("infos.required");
                            } else {
                                clearErrors(fieldName);
                            }
                        }

                        return validate(value);
                    }
                }}
                render={({ field }) => (
                    <Select
                        {...field}
                        value={formMethods.getValues(fieldName) || ""}
                        id={`${fieldName}-id`}
                        className={classes.select}
                        variant={variant}
                        fullWidth
                        renderValue={(selected) => {
                            if (labelVariant === 'chip') {
                                return (
                                    <EASelectChip
                                        selected={selected}
                                        options={options}
                                        placeholder={placeholder}
                                        onDelete={() => setValue(fieldName, '')}
                                    />
                                );
                            } else {
                                if (selected === "") {
                                    return (
                                        <span className={classes.placeHolder}>
                                            {placeholder}
                                        </span>
                                    );
                                } else {
                                    let entry = find(options, { value: selected });

                                    return (
                                        <span style={{ color: "#2E2E3A" }}>
                                            {(entry === undefined) ? selected : entry.label}
                                        </span>
                                    );
                                }
                            }
                        }}
                        error={!!lodashGet(errors, `${fieldName}`)}
                        onChange={(e) => {
                            setValue(fieldName, e.target.value);
                            onChange(e.target.value);
                            trigger(fieldName);
                        }}
                        displayEmpty
                    >
                        <MenuItem disabled value="">
                            <span className={classes.placeHolder}>
                                {placeholder}
                            </span>
                        </MenuItem>

                        {options?.map((option, index) => (
                            <MenuItem key={`${option.value}-${index}`} value={option.value} disabled={option.isDisabled}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />

            {
                !!lodashGet(errors, `${fieldName}`) && (
                    <FormHelperText className="Mui-error">
                        {lodashGet(errors, `${fieldName}.message`)}
                    </FormHelperText>
                )
            }
        </EAInputBloc>
    );
}

export default RuleSelectInput;
