import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formSectionSubTitle: {
    color: theme.palette.grey.grey400,
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
  },
  formSectionTitle: {
    color: "#373534",
    fontFamily: "Metric",
    fontWeight: 600,
    fontSize: "14px",
    display: 'flex',
    alignItems: "center"
  },
  formSectionTitleDeco: {
    width: "4px",
    height: "4px",
    backgroundColor: "#2D9F40",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
  },
  formConfigRuleName: {
    marginBottom: "36px"
  },
  formSectionRulesCategoriePlaceHolder: {
    color: "#2D9F40",
    fontWeight: 700,
    fontSize: 14,
    marginLeft: 16,
    marginTop: 3
  },
  feedBackButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "16px",
    marginTop: "43px",
  },
  configurationButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    gap: "20px",
  },
  recapButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "16px",
  },
  recapButtonWrapperEdit: {
    justifyContent: "space-between",
  },
  summaryEditLeftBtns: {
    marginLeft: "15px"
  },
  deleteRuleImg: {
    width: "12px",
    marginRight: "12px"
  },
  disabledDeleteBtn: {
    opacity: "30%",
    cursor: "default",
  },
  summaryEditRightBtns: {
    display: "flex",
    gap: "16px"
  },
  recapWrapper: {
    backgroundColor: theme.palette.grey.grey100,
    padding: "15px",
    margin: "0 0 23px 15px",
  },
  placeHolder: {
    color: theme.palette.grey.grey400,
    fontSize: "14px",
    fontWeight: 400,
    position: "relative",
    right: "12px",
  },
  inputText: {
    position: "relative",
    top: "-2px",
    "& .MuiInputBase-root": {
      color: "#2E2E3A",
      fontSize: "14px",
      backgroundColor: "white",
      border: 0
    },
  },
  inputTextVariant: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",
      border: "1px solid #E2E2EA",
      marginTop: "8px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #E2E2EA !important",
      },
    }
  },
  chipCrossIcon: {
    width: "7px",
    height: "7px",
  },
  valuesIconBtnClosed: {
    position: "relative",
    height: "20px",
    top: "12px"
  },
  settingicon: {
    marginRight: 4
  },
  select: {
    "& .MuiInput-input": {
      fontSize: "14px"
    },
  },
  summaryTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    display: "flex"
  },
  summaryListContainer: {
    marginLeft: "20px"
  },
  summaryListTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    margin: "6px 0",
    color: theme.palette.grey.grey500,
    display: "flex"
  },
  summaryListCategory: {
    backgroundColor: "#EFFBF1",
    color: "#2D9F40",
    fontWeight: 400,
    padding: "4px 6px",
    borderRadius: "16px",
    position: 'relative',
    top: -4, left: 5
  },
  summaryBlocLabel: {
    position: "absolute",
    top: -17,
    left: 0,
    backgroundColor: "white",
    borderTopLeftRadius: 16, borderTopRightRadius: 16,
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Metric",
    padding: "0px 20px",
    color: "#535362"
  },
  summaryListItem: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    margin: "2px 0",
    color: theme.palette.grey.grey700
  },
  summaryBullet: {
    marginRight: "8px",
  },
  summaryRefItem: {
    color: "#FFF",
    background: theme.palette.grey.grey700,
    border: "1px solid",
    borderColor: theme.palette.grey.grey700,
    borderRadius: "4px",
    padding: "2px 5px",
    marginLeft: "5px"
  },
  summaryRefItemValue: {
    fontWeight: 600,
  },
  summaryListOperator: {
    color: theme.palette.grey.grey400
  },
  finalizeAlertBloc: {
    marginLeft: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "14px",
  },
  finalizeWarningColor: {
    color: theme.palette.warning.warning600
  },
  finalizeErrorColor: {
    color: theme.palette.error.error700
  },
  finalizeAlertWarning: {
    fontWeight: 700
  },
  deleteItemIcon: {
    width: "10px"
  },
  autocompleteWrapper:{
    "& .MuiFormControl-root": {
      "& .MuiAutocomplete-input": {
        minWidth: 0
      },
    },

  },
  tagsWrapper: {
    display: "flex",
    flexDirection:"row-reverse",
  },
  textfield: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#7E7E8F !important",
      fontStyle: "italic",
      opacity: 1,
      display: "flex"
    },
    "& .MuiInputBase-root": {
      paddingRight: "30px !important",
    },
  },
  tagsCounter: {
    color: "#9A9AAF",
    fontSize: "12px",
    fontWeight: 500,
    width:"16px",
    padding: "3px",
    marginTop: "1px",
    display:"flex",
    placeItems:"center",
    justifyContent:"center"
  },
  ruleCriteriaDeco: {
    height: 80,
    width: 1,
    backgroundColor: "#D7F4DC",
    marginRight: 10,
  },
  addChoiceWrapper: {
    width: "100%",
    marginBottom: 39,
  },
  addChoiceBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    background: "#F9FEFA",
    width: "100%",
    borderRadius: 10
  },
  actionLine: {
    position: 'relative',
    bottom: 40
  },
}));

export default useStyles;
