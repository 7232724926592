import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

export const initialContextState = {
  toggleDrawer: false,
  isMenuHighlighted: false,
  drawerIsOpened: true,
  pagination: {
    rowPerPage: 50,
    page: 0,
    count: 0,
  },
  errors: null,
  accounts: null,
  account: null,
  lang: null,
  roles : [],
  user: null,
  dossierGraphTableLink: [],
  thirdpartiesGraphTableLink: [],
  dossierToolbarFilters: [],
  thirdpartiesToolbarFilters: [],
  usersToolbarFilters: [],
  dossierTypes: [],
  inactiveDossierTypes: [],
  contactsToolbarFilters: [],
  crumbsData: null,
  graphFilters: {},
  isAuthDone : false,
  provisioning : false,
  referenceData: {}
};

export const Context = createContext(initialContextState);

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialContextState);
  return (
    <Context.Provider
      value={[state, dispatch]}
      displayName="365 Context"
    >
      {children}
    </Context.Provider>
  );
};

export default Store;
