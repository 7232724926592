import { Box } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { DocumentFormDataType } from "../../services/common/Constants";
import DocViewerCard from "../../components/doc-viewer/DocViewerCard";
import DocViewerTable from "../../components/doc-viewer/DocViewerTable";
import { formatNumberCurrency,formatNumber, formatChoices } from "../../services/common/Utils";

const getBlockValue = (formData) => {
    switch(formData.type) {
        case DocumentFormDataType.IBAN: 
            return (
                <Box display="flex"  justifyContent="space-between">
                    {formData.value}
                </Box>
            )
        case DocumentFormDataType.AMOUNT: 
            return formatNumberCurrency(formData.value, "EUR");
        case DocumentFormDataType.DECIMAL: 
            return formatNumber(formData.value, true);
        case DocumentFormDataType.NUMBER:
            return formatNumber(formData.value, false);
        case DocumentFormDataType.CHOICES:
            return formatChoices(formData.value);
        default:
            return formData.value?.replace(/<[^>]+>/g, '');
    }
}

const buildBody = (filteredData) => {
    let body = [];
    filteredData.forEach(fd => {
        if(fd.value) {
            body.push([fd.title, getBlockValue(fd)])
        }
    })
    return body;
}

function PanelFormData({data}) {
    const [cardTitle, setCardTitle] = useState();
    const [cardBody, setCardBody] = useState();

    useEffect(() => {
        setCardTitle([i18next.t('viewer.formDataInfos')]);
        if(data?.length > 0) {
            const filteredData = data?.filter(d => d.title !== "BIC")?.sort((a,b) => a.order - b.order); 
            setCardBody(buildBody(filteredData));
        }
    }, [data]);

    return (
        <DocViewerCard
            title={cardTitle}
            body={
                <DocViewerTable 
                    {...(data?.length > 0 ?
                        {
                            body: cardBody,
                            noTranslation: true
                        }
                        : {})
                    }
                />
            }
        />
    );
}

export default PanelFormData;
