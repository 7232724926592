import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";

export async function getEvidenceDetail(
  accountId,
  reponseId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/reponse/${reponseId}/details`,
    null,
    null,
    false
  );
}

export async function getEvidenceExtractionDetail(
  accountId,
  reponseId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/reponse/${reponseId}/extractor`,
    null,
    null,
    false
  );
}

export async function getEvidenceMultiple(
  accountId,
  reponseId,
  dossierId,
  typedocId,
  dispatch,
  signal
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/reponse/${reponseId}/multiple?dossierId=${dossierId}&typedocId=${typedocId}`,
    null,
    null,
    false,
    signal
  );
}


export async function getEvidenceFile(
  accountId,
  fileId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/evidences/${fileId}`,
    null,
    null,
    true
  );
}

export async function getFileStatus(accountId, reponseId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/reponse/${reponseId}/status`
  );
}