import { getEnv } from "../../config/Config";
import { eaFetch, objToQueryString } from "../common/ApiUtils";
import { EDGE_DATE_FORMAT, Exports } from "services/common/Constants";
import { isEmpty } from "lodash";
import moment from "moment";

export async function getAccountsFromToken(dispatch) {
  return eaFetch(dispatch, getEnv("REACT_APP_EDGE"), "GET", "365/account");
}

export async function getAccountDossierTypes(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossierTypes`
  );
}

export async function getAccountRequirements(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/requirements`
  );
}

export async function getAccountInformation(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}`
  );
}

export async function getAccountEnterpriseCategory(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/category`
  );
}

export async function getCountries(accountId, filters, dispatch, signal) {
  return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "GET",
      `365/account/${accountId}/thirdparties/countries?${objToQueryString(filters)}`,
      null, null, null, signal
  );
}

export async function getDepartments(accountId, filters, dispatch, signal) {
  return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "GET",
      `365/account/${accountId}/thirdparties/departments?${objToQueryString(filters)}`,
      null, null, null, signal
  );
}

export async function getAccountMajorActivitiesCount(accountId, filters, dispatch, signal) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/thirdparties/major-activities?${objToQueryString(filters)}`,
    null, null, null, signal
  );
}

export async function getAccountThirdpartyStates(accountId, filters, dispatch, signal) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/thirdparties/registration-status?${objToQueryString(filters)}`,
    null, null, null, signal
  );
}

export async function getAccountActivities(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/activities`
  );
}

export async function connectToAccount(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/connect`
  );
}

export async function getAccountDocuments(accountId, typeDocId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/documents/${typeDocId}`
  );
}

const buildDocumentsFilters = (filters, isAnswerBtnActive) => {

  let result = {
    documents: Array.from({ length: filters.documents?.length || 0 }, () => ({}))
  };
  
  filters.documents?.forEach((line, index) => {
    Object.keys(line)?.forEach(key => {
      let value = line[key];

      // Format date to iso before append
      if (value && (moment.isMoment(value) || moment(value).isValid())) {
        value = moment(value).format(EDGE_DATE_FORMAT);
      }

      result.documents[index][key] = value;
    })
  })

  if (isAnswerBtnActive) {
    result.acquisition = ["DO", "DO_FO"];
  }
  
  return result;
}

const buildDocumentsSort = (sortModel) => {
  let sort = {}
  if (!isEmpty(sortModel)) {
    sortModel?.forEach((x) => {
      if(x.field === "depositorOrTransmitter") {
        sort.depositor = x.sort;
        sort.transmitter = x.sort;
      } else {
        sort[x.field] = x.sort;
      }
    });
  }
  return sort;
}

export async function getAccountDocumentsSearch(
  accountId,
  pagination,
  filters,
  isAnswerBtnActive,
  sortModel,
  dispatch,
  signal
) {


  const body = {
    filters: buildDocumentsFilters(filters, isAnswerBtnActive),
    sort: buildDocumentsSort(sortModel)
  };

  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/documents/advanced-search?&${objToQueryString(pagination)}`,
    body,
    null,
    null,
    signal
  );
}

export async function getExportFileFromAccountDocuments(
  accountId,
  exportType = Exports.CSV,
  filters,
  isAnswerBtnActive,
  sortModel,
  exportDto,
  dispatch,
  signal
) {
  const body = {
    filters: buildDocumentsFilters(filters, isAnswerBtnActive),
    sort: buildDocumentsSort(sortModel),
    ...exportDto
  };

  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/documents/advanced-search/${exportType.toLowerCase()}-export`,
    body,
    null,
    true,
    signal
  );
}

export async function getDocCountByType(accountId, dispatch, signal) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/documents/count-type`,
    null, null, null, signal
  );
}

export async function getDocCountByStatus(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/documents/count-status`
  );
}
