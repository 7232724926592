import React, {useContext} from "react";
import { Grid } from "@mui/material";
import i18next from "i18next";
import { useFormContext } from "react-hook-form";

import EAMultipleSelectInput from "components/rules/EAMultipleSelectInput";
import EAInputBloc from "components/common/EAInputBloc";
import {PrivilegeEnum, RoleBasedAccessControlContext} from "../../../../../services/common/RolesUtils";

const AuthorizedDocumentsFormSelect = ({ documentTypes, fieldName }) => {

  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const formMethods = useFormContext();
  const {
    setValue,
  } = formMethods;

  return (
    <Grid container spacing={2}>
      {documentTypes &&
        <EAInputBloc
          label={`${i18next.t("rules.configuration.criteria.fields.value.label")}`}
          customLabelStyle={{ fontSize: "14px", padding: "16px" }}
        >
          {!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_ROLE) &&
            <div
              style={{
                position: "absolute",
                top: "54px",
                bottom: 0,
                left: 0,
                right: "-16px",
                zIndex: 2
              }}
            />
          }
          <EAMultipleSelectInput
            fieldName={`${fieldName}`}
            options={documentTypes}
            onChange={(e) => { setValue(`${fieldName}.values`, e.target.value); }}
            placeholder={i18next.t(`rules.configuration.criteria.fields.value.placeholder`)}
            variant={"fullSize"}
          />
        </EAInputBloc>
      }
    </Grid>
  );
};

export default AuthorizedDocumentsFormSelect;
