import React, {useEffect, useState} from "react";
import { Paper } from "@mui/material";
import EAPagination from "./datatable/EAPagination";
import useStyles from "../../style/js-style/components/common/ListLayoutStyle";
import TitleWithIcon from "./TitleWithIcon";
import SkeletonDataGrid from "./skeleton/SkeletonDataGrid";
/**
 * Main content wrapper for thirdparties / dossiers / documents list page
 *
 * @param children
 * @param title
 * @param icon
 * @param count
 * @param totalCount
 * @param addingComponent for adding element in right part of header
 */
function ListLayout({children, title, icon, count, totalCount, addingComponent, isLoading, filterField, customFilers = true}) {
  const { classes } = useStyles();
  const [showSkeleton, setShowSkeleton] = useState(false);

  // Delay before displaying the skeleton to avoid a flash effect when no result
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSkeleton(true);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div style={{maxHeight: "calc(100vh - 190px)"}}>
      <div className={classes.headerWrapper}>
        <TitleWithIcon
          icon={icon}
          title={title}
          resultCount={count || 0}
          totalCount={totalCount}
          isLoading={isLoading}
          style={{marginLeft: "18px"}}
        />
        {addingComponent}
      </div>
      {filterField && (
        <div>
          {filterField}
        </div>
      )}
      <Paper elevation={0} className={classes.dataGridWrapper}>
        {
          isLoading && showSkeleton
            ? <SkeletonDataGrid />
            : children
        }
      </Paper>
      <EAPagination isLoading={isLoading}/>
    </div>
  );
}
export default ListLayout;
