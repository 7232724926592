import React, { useState } from "react";
import {
  getButtonStyle,
  largeButtons,
} from "../../style/js-style/common/buttonStyle.js";
import { theme } from "../../style/globalStyle";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 *  Return generic button with style
 *
 * @param onClick action
 * @param content text
 * @param size xs | small | regular | large
 * @param colorVariant primary | error | secondary | success | warning
 * @param outFilled boolean
 * @param disabled boolean
 * @param customStyle style
 * @param borderless button like link style
 * @param otherProps override props
 * @returns {JSX.Element}
 * @constructor
 */
function EAButton({
  onClick,
  content,
  size = "regular",
  colorVariant = "primary",
  outFilled,
  disabled,
  customStyle,
  borderless,
  ...otherProps
}) {
  const [isHovered, setIsHovered] = useState(false);
  const style = getButtonStyle(size, colorVariant, outFilled, disabled, borderless);
  const matches = useMediaQuery(theme.breakpoints.up("inch16"));
  const toggleHovered = () => setIsHovered(!isHovered);

  return (
    <button
      style={{
        ...style,
        ...(isHovered && style.hover),
        ...(matches && largeButtons[size]),
        ...(borderless && style.borderless),
        ...(customStyle !== null && customStyle),
      }}
      disabled={disabled}
      {...(!disabled && {
        onMouseEnter: toggleHovered,
        onMouseLeave: toggleHovered,
        onClick: onClick,
      })}
      {...otherProps}
    >
      <span style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        }}
      >
        {content}
      </span>
    </button>
  );
}

export default EAButton;
