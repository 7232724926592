import {
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import * as am5 from "@amcharts/amcharts5";
import React, { useEffect, useCallback, useContext, useState }  from "react";
import useStyles from "style/js-style/containers/dashboard/TabStyle";
import { InduedAllScores } from "services/common/InduedUtils";
import { hidePieSliceLabel } from "services/common/ChartUtils";

import { Context } from "states/Store";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { getInduedScoresCount } from "services/edge/InduedService";
import { ERROR } from "services/common/Constants";
import { formatNumber } from "services/common/Utils";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { useRef } from "react";
import RiskScorePieChart from "../RiskScorePieChart";
import { isAbortError } from "services/common/ApiUtils";

const severityPieData = {
  "-1": {
    color: "#9A9AAF",
    order: 5,
  },
  0: {
    color: "#535362",
    order: 4,
  },
  1: {
    color: "#60D273",
    order: 1,
  },
  2: {
    color: "#FF9A00",
    order: 3,
  },
  3: {
    color: "#FF3F40",
    order: 2,
  },
};

function TabIndued() {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [mappedScoreCount, setMappedScoreCount] = useState({});
  const accountId = useSelectedAccountId();

  const abortCtrlRef = useRef();

  const fetchScoreCount = useCallback(
    (query) => {

      if(abortCtrlRef.current) {
        abortCtrlRef.current.abort();
      }
  
      abortCtrlRef.current = new AbortController();
      const signal = abortCtrlRef.current.signal;

      if (accountId && state.account) {
        (async () => {
          try {
            const res = await getInduedScoresCount(
              accountId,
              query,
              null,
              signal
            );

            let mappedScoresCountsObj = {};
            for(const scoreSeveritiesCounts of res.content) {
              const total = scoreSeveritiesCounts.severityCounts
              ?.map((d) => d.count)
              .reduce((partialSum, a) => partialSum + a, 0);

              mappedScoresCountsObj[scoreSeveritiesCounts.score] = {
                data: scoreSeveritiesCounts.severityCounts
                  .map((severity, index) => ({
                    id: severity.severityValue,
                    itemLabel: localizeTranslate(
                      i18next.t(`indued.severity.${severity.severityLabel}`)
                    ),
                    type: scoreSeveritiesCounts.score,
                    value: severity.count,
                    formattedValue: formatNumber(severity.count),
                    settings: {
                      fill: am5.color(
                        severityPieData[severity.severityValue].color
                      ),
                    },
                    labelSettings: {
                      forceHidden: hidePieSliceLabel(severity.count, total),
                    },
                  }))
                  .sort((a, b) =>
                    severityPieData[a.id].order > severityPieData[b.id].order
                      ? 1
                      : -1
                  ),
                total: formatNumber(total),
              }
            }
            setMappedScoreCount(mappedScoresCountsObj);
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
            } 
          }
        })();
      }
    },
    [accountId, dispatch, state.account]
  );

  // Build filters query from graph filters
  useEffect(() => {
    let queryObj = {};

    if (state.graphFilters?.pie?.length > 0) {
      queryObj = state.graphFilters.pie.reduce((result, item) => {
        const { id, type } = item;
        if (!result[type]) {
          result[type] = [];
        }
        result[type].push(id);
        return result;
      }, {});
    }
    fetchScoreCount(queryObj);

    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [fetchScoreCount, state.graphFilters?.pie]);

  return (
    <Paper elevation={0} className={classes.paperContainer}>
      <Grid container spacing={2}>
        {InduedAllScores.map((scoreLabel) => (
          <Grid item xs={12} lg={4} key={scoreLabel}>
            <Paper className={classes.graphPaper}>
              <Typography variant="h3" className={classes.graphAreaTitle}>
                {i18next.t(`dashboard.filters.${scoreLabel}`)}
              </Typography>
              <RiskScorePieChart subTab="indued" scoreLabel={scoreLabel} scoreCount={mappedScoreCount?.[scoreLabel]}/>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default TabIndued;
