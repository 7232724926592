import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dataTableActions: {
    marginLeft: "auto"
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px",
  },
  headerMetaInfo: {
    fontWeight: 400,
    color: theme.palette.grey.grey400,
    fontSize: "14px",
    textTransform: "lowercase",
  },
  headerMetaInfoBold: {
    fontWeight: 600,
  },
  headerIcon: {
    margin: "0 5px",
    width: "16px",
    filter: "invert(53%) sepia(10%) saturate(468%) hue-rotate(201deg) brightness(92%) contrast(89%)", // target grey500
},
  separate: {
    borderTop: "1px solid " + theme.palette.grey.grey200,
    width: "97%",
    margin: "auto"
  },
  deleteAllBtn: {
    backgroundColor: theme.palette.grey.grey200,
    color: theme.palette.grey.grey600,
    fontWeight: 600,
    fontSize: "12px",
    borderRadius: "12px",
    display: "flex",
    gap: ".5rem"
  },
  deleteAllBtnDisabled: {
    display: "none",
  },
  dataGridWrapper: {
    backgroundColor: "transparent",
    maxHeight: "calc(100vh - 420px)",
    overflowY: "auto",
  },
  dataGridTitle: {
    color: "#373534",
    fontFamily: "Metric",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
  },
}));

export default useStyles;
