import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    appBar: {
        backgroundColor: "white",
        color: "white",
        alignItems: "flex-end",
        boxShadow: "rgb(0 0 0 / 17%) 0px 30px 17px -31px",
    },
    backToFront: {
        zIndex: 1101,
    },
}));

export default useStyles;
