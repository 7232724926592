import { makeStyles } from 'tss-react/mui';
import {darkGrey} from "../../../globalStyle";

const useStyles = makeStyles()((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: darkGrey,
    color: "white",
    textAlign: "center",
  },
  dialogText: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: "center",
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputLabel: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey.grey500,
  },
  inputLabelWrapper: {
    marginBottom: "15px",
  }
}));

export default useStyles;
