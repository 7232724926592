import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  blocIcon:{
    marginRight: "8px",
  },
  blocTitle: {
    color: theme.palette.grey.grey700,
    fontSize: "14px",
    fontWeight: 700,
  },
  blocValues: {
    color: theme.palette.grey.grey700,
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "8px",
  },
  icon: {
    width: "14px"
  },
  modified: {
    color: theme.palette.grey.grey400,
  },
  userValueDisplay: {
    wordBreak: "break-word",
  },
}));

export default useStyles;
