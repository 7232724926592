import React, { useEffect, useState } from "react";
import useStyles from "../../style/js-style/containers/doc-viewer/PanelStatusStyle";
import EAIcon from "../../components/common/EAIcon";
import clsx from "clsx";
import { getStatusFromCode } from "../../services/common/DocumentUtils";
import i18next from "i18next";
import EAButton from "../../components/common/EAButton";
import StatusApproveDialog from "./StatusApproveDialog";
import StatusRejectDialog from "./StatusRejectDialog";

const BELOW_EXPECTATION_REASONS = [ "BELOW_EXPECTATION_CLIENT_REFUS", "BELOW_EXPECTATION_EXTRACTOR_REFUS" ];

const PanelStatus = ({ fileStatus, fileStatusReason, responseId, enableBelowExpectationStatus, handleUpdateStatus, isDO = false, hasRejectPrivilege, hasApprovePrivilege }) => {
  const { classes } = useStyles();
  const [currentStatus, setCurrentStatus] = useState();
  const [isStatusRejectDialogOpen, setIsStatusRejectDialogOpen] = useState(false);
  const [isStatusApproveDialogOpen, setIsStatusApproveDialogOpen] = useState(false);
  const [showRequiredActionPanel, setShowRequiredActionPanel] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(true);


  useEffect(() => {
    if (fileStatus) {
      setLoadingStatus(true);
      setCurrentStatus(null);
      const showPanel = fileStatus === "BELOW_EXPECTATION" && !BELOW_EXPECTATION_REASONS.includes(fileStatusReason);
      setShowRequiredActionPanel(showPanel);
      if (showPanel) {
        const status = getStatusFromCode("ACTION_REQUIRED", true);
        setCurrentStatus(status);
        setTimeout(() => {
          setLoadingStatus(false);
        }, 900);
      } else {
        const status = getStatusFromCode(fileStatus, true);
        setCurrentStatus(status);
        setLoadingStatus(false);
      }
    } 
  }, [fileStatus, fileStatusReason]);
  

  return (
    <>
      {!loadingStatus && currentStatus && (
        <div className={clsx(isDO ? classes.rootDO : classes.root , classes[currentStatus.class])}>

          <div className={clsx(classes.displayRow, isDO && classes.displayRowTitle)}>
            <span className={clsx(classes.label, isDO && classes.labelDO)}>
              {currentStatus.label}
            </span>
            <EAIcon icon={currentStatus.icon} className={clsx(classes.icon , isDO && classes.iconDO)} />
          </div>

          {(fileStatus === "ACTION_REQUIRED"  || showRequiredActionPanel || fileStatus === "INCONNU") && (
            <div className={clsx(classes.displayRow, isDO ? classes.btnContainerDO : classes.btnContainer)}>
              <EAButton
                className={isDO ? classes.statusBtn : ""}
                onClick={() => setIsStatusRejectDialogOpen(true)}
                content={i18next.t("viewer.status.rejectBtn")}
                size={"regular"}
                colorVariant={"secondary"}
                disabled={!hasRejectPrivilege}
                customStyle={{marginRight: "15px", fontSize: "12px", minHeight: "28px !important", height: "28px", padding: 0, position: "relative", top: "-3.5px"}}
              />
              <EAButton
                className={isDO ? classes.statusBtn : ""}
                onClick={() => setIsStatusApproveDialogOpen(true)}
                content={i18next.t("viewer.status.approveBtn")}
                size={"regular"}
                colorVariant={isDO ? "primaryLight" : "warning"}
                disabled={!hasApprovePrivilege}
                customStyle={{width: "157px", fontSize: "12px", minHeight: "28px !important", height: "28px", padding: 0, position: "relative", top: "-3.5px", boxShadow: "none"}}
                />
            </div>
          )}
          </div>
      )}
      {isStatusRejectDialogOpen && (
        <StatusRejectDialog
          isOpen={isStatusRejectDialogOpen}
          handleClose={() => setIsStatusRejectDialogOpen(false)}
          handleOnReject={() => {
            setIsStatusRejectDialogOpen(false);
            handleUpdateStatus();
          }}
          responseId={responseId}
          enableBelowExpectationStatus={enableBelowExpectationStatus}
        />
      )}

      {isStatusApproveDialogOpen && (
        <StatusApproveDialog
          isOpen={isStatusApproveDialogOpen}
          handleClose={() => setIsStatusApproveDialogOpen(false)}
          handleOnApprove={() => {
            setIsStatusApproveDialogOpen(false);
            handleUpdateStatus();
          }}
          responseId={responseId}
        />
      )}
    </>
  );
}

export default PanelStatus;
