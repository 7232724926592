import React, { useCallback, useContext, useEffect } from "react";
import { Context } from "states/Store";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import EABarXChart from "components/common/charts/EABarXChart";
import _ from "lodash";
import TransparencyMention from "components/thirdparties/TransparencyMention";

function TransparencyBarChart({ transparencyScoreCount, year }) {
  const [state, dispatch] = useContext(Context);
  const [transparencyCount, setTransparencyCount] = React.useState();
  const [seriesItems, setSeriesItems] = React.useState();
  const { accountId } = useParams();

  const getAccountId = useCallback(() => {
    return accountId || state.account?.id;
  }, [accountId, state.account]);

  useEffect(() => {
    if(transparencyScoreCount && !_.isEmpty(transparencyScoreCount)) {
          const total = transparencyScoreCount.reduce((partialSum, a) => partialSum + a.value, 0)
          const data = transparencyScoreCount.map(s => ({
            ...s,
            totalCount: total,
            totalPercent: Math.round((s.value / total) * 100),
            type: "transparency_score"
          }));
          setSeriesItems([
            {
              label: "Nombre de tiers",
              value: "interval",
            }
          ]);
          setTransparencyCount(data);
        }
  }, [getAccountId, dispatch, state.account, transparencyScoreCount]);

  return (
    <>
    <EABarXChart
      id="transparencyScore-chart"
      data={transparencyCount}
      seriesItems={seriesItems}
      tab={"risks"}
      subTab={"transparency"}
    />
    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <TransparencyMention year={year} />
    </div>
    </>
  );
}

export default TransparencyBarChart;
