import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    headerWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }
}));

export default useStyles;
