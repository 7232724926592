import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  mt: {
    marginTop: "20px",
  },
  toolBarWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  toolBarItem: {
    height: "30px",
    width: "280px",
    margin: "0 16px",
  },
  mainGrid: {
    height: "calc(100vh - 320px)",
    width: "calc(100% - 32px)",
    margin: "0 16px",
  },
  gridContentWrapper: {
    height: "calc(100vh - 320px)",
    width: "calc(100% - 32px)",
    margin: "0 16px",
    borderRadius: "12px 12px 0 0",
    boxShadow: "0px 2px 5px rgb(0 0 0 / 7%)",
  },
  headerWrapper: {
    backgroundColor: "#F2F3F7",
    height: "39px",
    display: "flex",
  },
  headerCell: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  cell: {
    height: "12px",
  },
  mainLine: {
    borderBottom: "1px solid #D5D5D5",
    height: "39px",
    display: "flex",
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F6",
    },
  },
}));

export default useStyles;
