import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "24px",
    borderRadius: "0 12px 12px 12px",
      transition: "all 0.3s",
    "&:hover": {
      boxShadow: "rgb(0 0 0 / 9%) 0px 25px 20px -20px",
      transition: "all 0.3s",
    },
  },
  folderInfosLabel: {
    display: "block",
    color: theme.palette.grey.grey500,
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  folderInfosContent: {
    display: "block",
    marginRight: "25px",
    wordBreak: "break-all",
    fontSize: "12px",
    fontWeight: 400,
    paddingRight: "16px"
  },
  folderInfoDescription: {
    marginRight: "15px",
    wordBreak: "break-all",
  },
  divider: {
    height: "100%",
    border: "1px solid " + theme.palette.primary.primary200,
    width: "1px",
    position: "absolute",
    right: "20px",
    top: 0,
  },
  folderInfosWrapper: {
    position: "relative",
  },
  thirdLabel:{
    fontSize: "14px",
  },
  thirdIcon:{
    marginRight: "5px",
  },
  infoThirdItem: {
    color: "black",
    fontSize: "12px !important",
    fontWeight: 400,
    textTransform: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
  },
  thirdpartyTitle: {
    overflowWrap: "break-word",
    marginRight: "auto",
    fontWeight: 600,
    fontSize: "20px",
    cursor: "pointer",
  },
  thirdpartyHeader:{
    overflowWrap: "break-word",
    marginRight: "auto",
    fontWeight: 600,
    marginBottom: "1.5rem",
  },
  thirdpartySubTitle: {
    fontWeight: "700",
    color: theme.palette.primary.primary400,
    fontSize: "10px",
    position: "relative",
    top: "-5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    display: "-webkit-box",
    wordBreak: "break-all",
    lineHeight: "200%",
  },
  thirdpartyIdentifiers: {
    [theme.breakpoints.up("sm")]: {
      display: 'inline',
    },
  },
  openThirdIcon: {
    marginLeft: "10px",
  },
  overflowEllipsis: {
    maxHeight: "146px",
    overflow: "auto",
  },
}));

export default useStyles;
