import React, { useContext } from "react";
import {
  Typography,
} from "@mui/material";
import i18next from "i18next";

import { Context } from "../../../../states/Store";
import { ERROR } from "../../../../services/common/Constants";
import EADialog from "../../../../components/common/EADialog";
import EAIcon from "../../../../components/common/EAIcon";
import { deleteRule } from "../../../../services/edge/RuleService";
import useStyles from "../../../../style/js-style/containers/administration/rules/RuleDeleteDialogStyle";
import {useRequestLoading} from "../../../../components/common/hooks/useRequestLoading";

const RuleDeleteDialog = ({
  isOpen,
  rule,
  onClose,
  onValidateSuccess,
}) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);

  const handleDelete = async () => {
    try {
      startLoading();
      await deleteRule(state.account.id, rule.id, null);

      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: i18next.t("rules.notifications.delete") }
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DELETE_ERROR" } });
    }finally {
      stopLoading();
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={handleDelete}
      hasActions={true}
      maxWidth="popupxs"
      fullWidth
      variant="redValid"
      btnSize="large"
      isValidateDisabled={isRequestLoading}
    >
      <div className={classes.root}>
        <>
          <div className={classes.imgWrapper}>
            <EAIcon icon={"delete_red"} className={classes.imgContent} />
          </div>
          <span className={classes.bolder}>
            {i18next.t("rules.deleteDialog.title", { status: i18next.t(`${rule.status}`).toLocaleLowerCase() })}
          </span>
          {rule.status === 'active' && (
            <span>
              {i18next.t("rules.deleteDialog.caption")}
            </span>
          )}
        </>
      </div>

      <div key={rule.id} component="div">
        <RuleInfoBloc rule={rule} />
      </div>
    </EADialog>
  );
}

const RuleInfoBloc = ({ rule }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.ruleInfoBlocWrapper}>
      <Typography className={classes.ruleInfoBlocContent}>
        {rule.name}
      </Typography>
    </div>
  );
};

export default RuleDeleteDialog;
