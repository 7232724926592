import React from "react";
import i18next from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormHelperText,
  TextField,
} from "@mui/material";
import _ from "lodash";
import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";

const CalculatorStandardInput = ({
  fieldName,
  isRequired = false,
  placeholder = '',
  label = '',
  variant = 'standard', //mui text field variant
  uxVariant = "standard",
  errorVariant = "relative",
  onChange = () => { },
  validate = () => { return null },
  ...others
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
  } = formMethods;

  return (
    <>
      { variant !== "innerLabel" &&
        <span className={classes.formLabel}>
          {label && `${label}`}
        </span>
      }
      <div className={classes.formGroup}>
        <Controller
          control={control}
          name={fieldName}
          rules={{
            validate: async (value) => {
              if (isRequired) {
                const isEmpty = !value || value.length === 0;

                if (isEmpty) {
                  return i18next.t("infos.required");
                } else {
                  clearErrors(fieldName);
                }
              }

              return await validate(value);
            }
          }}
          render={({ field }) => (
            <TextField
              {...field}
              id={`${fieldName}-input-id`}
              type={"text"}
              variant={uxVariant === "criteriaInput" ? "standard" : "outlined"}
              error={!!_.get(errors, `${fieldName}`)}
              fullWidth
              label={variant === "innerLabel" ? label : ""}
              className={classes.inputText}
              onChange={(e) => {
                formMethods.setValue(fieldName, e.target.value);
                onChange(e.target.value);
                trigger(fieldName);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              value={formMethods.getValues(fieldName)}
              placeholder={placeholder}
              {...others}
            />
          )}
        />

        {!!_.get(errors, `${fieldName}`) && (
          <FormHelperText className="Mui-error" style={{ position: errorVariant === "relative" ? "relative" : "absolute" }}>
            {_.get(errors, `${fieldName}.message`)}
          </FormHelperText>
        )}
      </div>
    </>
  );
}

export default CalculatorStandardInput;
