import {Box, Grid, Paper, Tooltip, Typography} from "@mui/material";
import React, {useContext} from "react";
import countries from "i18n-iso-countries";
import i18next from "i18next";
import EAHqAndFlag from "../../../components/EAHQFlag";
import { errorColor } from "../../../style/globalStyle";
import EAChip from "../../../components/EAChip";
import moment from "moment";
import useStyles from "../../../style/js-style/containers/dossiers/dossiers-sheet/PanelDossierThirdpartyStyle";
import ROUTES from "../../../config/Routes";
import { useHistory } from "react-router";
import {Context} from "../../../states/Store";
import EAIcon from "../../../components/common/EAIcon";
import clsx from "clsx";

const ThirdpartyIdentifiers = ({thirdparty}) => {
    const { classes } = useStyles();
    if(thirdparty.addressCountryCode?.toUpperCase() === 'FR') {
        return (
            <>
                {thirdparty.thirdpartyCode && (<Tooltip title={`${thirdparty.thirdpartyCode} (Code tiers)`}><Typography variant="subtitle2" component="div" className={classes.infoThirdItem}><span className="dt">{thirdparty.thirdpartyCode}</span>{` (Code tiers)`}</Typography></Tooltip>)}
                {thirdparty.siret && (<Typography variant="subtitle2" component="div" className={clsx('dt', classes.infoThirdItem)}>{`${thirdparty.siret} (Siret)`}</Typography>)}
                {thirdparty.siren && (<Typography variant="subtitle2" component="div" className={clsx('dt', classes.infoThirdItem)}>{`${thirdparty.siren} (Siren)`}</Typography>)}
                {thirdparty.tva && (<Typography variant="subtitle2" component="div" className={clsx('dt', classes.infoThirdItem)}>{`${thirdparty.tva} (${i18next.t('VAT')})`}</Typography>)}
            </>
        )
    } else {
        const hasDuns = thirdparty.duns && !thirdparty.dunsEncrypted;
        return (
            <>
                {thirdparty.thirdpartyCode && (<Typography variant="subtitle2" component="div" className={classes.infoThirdItem}>{`${thirdparty.thirdpartyCode} (Code tiers)`}</Typography>)}
                {hasDuns && (
                    <Typography variant="subtitle2" component="div" className={classes.infoThirdItem}>{`DUNS : ${thirdparty.duns}`}</Typography>
                )}
                {thirdparty.listRegistrationNumber?.map((regNbr,index) => (
                    <Typography key={index} variant="subtitle2" component="div" className={classes.infoThirdItem}>
                        <div className={clsx('dt', classes.thirdpartyIdentifiers)}>{`${regNbr.number} `}</div>
                        <div className={clsx('dt', classes.thirdpartyIdentifiers)}>{`(${regNbr.libelle})`}</div>
                    </Typography>
                ))}
            </>
        )
    }
}

function PanelDossierThirdparty({thirdparty, dossier}) {
    let history = useHistory();
    const [state,] = useContext(Context);
    const { classes } = useStyles();

    const goToThirdpartySheet = () => {
        const tpBaseRoute = thirdparty.onboarded ? ROUTES : ROUTES.ARCHIVES;
        history.push(`/${state.account.id}/${tpBaseRoute.THIRDPARTIES}/${thirdparty.thirdpartyId}`)
    }

    return (
        <>
            <Paper elevation={0} className={classes.root} >
                <Grid container spacing={0} style={{minHeight: "225px"}}>

                    {/* Folder info : ref, end date & description */}
                    <Grid container item xs={6} className={classes.folderInfosWrapper}>
                        <Grid item xs={6}>
                            <span className={classes.folderInfosLabel}>{i18next.t('reference')}</span>
                            <span className={clsx(classes.folderInfosContent, "dt")}>{dossier.dossierReference && (dossier.dossierReference)}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.folderInfosLabel}>{i18next.t('expirationDate')}</span>
                            <span className={clsx(classes.folderInfosContent, "dt")}>{dossier.expirationDate ? moment(dossier.expirationDate).format(i18next.t('date.dateFormat')) : ""}</span>
                        </Grid>
                        <Grid item xs={12} className={classes.folderInfoDescription}>
                            <span className={classes.folderInfosLabel}>{i18next.t('description')}</span>
                            <span className={clsx(classes.folderInfosContent, classes.overflowEllipsis, "dt")}>{dossier.description && dossier.description}</span>
                        </Grid>
                        <div className={classes.divider}></div>
                    </Grid>

                    {/* Dossier info : thirdparty info */}
                    <Grid container item xs={6}>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Typography className={classes.thirdpartyHeader}>
                                    <EAIcon icon={"thirdparty-icon-grey"} className={classes.thirdIcon} />
                                    <span className={classes.thirdLabel}>{i18next.t("thirdParty")}</span>
                                </Typography>
                                {thirdparty.thirdpartyAlerts && thirdparty.thirdpartyAlerts !== "NEANT" && (
                                    <EAChip label={i18next.t("collectiveProceedings")} color={errorColor}/>
                                )}
                                {thirdparty.category && (
                                    <EAChip label={i18next.t(`thirdpartySheet.category.${thirdparty.category}`)}/>
                                )}
                                <EAHqAndFlag isHQ={thirdparty.headquarter} countryCode={thirdparty.addressCountryCode}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              className={classes.thirdpartyTitle}
                              onClick={goToThirdpartySheet}
                            >
                                <span className="dt">{thirdparty.name}</span>
                                <EAIcon icon={"open-page_green"} className={classes.openThirdIcon} />

                                {/* Activity */}
                                <Tooltip  title={thirdparty.etabActivityLabel} >
                                    <Typography variant="span" className={classes.thirdpartySubTitle}>{thirdparty.etabActivityLabel}</Typography>
                                </Tooltip>
                            </Typography>
                        </Grid>

                        {/* Coordonate */}
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" component="div" className={clsx('dt', classes.infoThirdItem)}> {thirdparty.addressStreet} </Typography>
                            <Typography variant="subtitle2" component="div" className={clsx('dt', classes.infoThirdItem)}> {thirdparty.addressPostCode} {thirdparty.addressCity} </Typography>
                            <Typography variant="subtitle2" component="div" className={classes.infoThirdItem}> {countries.getName(thirdparty.addressCountryCode, i18next.language)?.toUpperCase()} </Typography>
                        </Grid>

                        {/* Identifiers */}
                        <Grid item xs={12} sm={6}>
                            <ThirdpartyIdentifiers thirdparty={thirdparty} />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default PanelDossierThirdparty;
