import React, {useContext} from "react";
import ROUTES from "../../config/Routes";
import i18next from "i18next";
import useStyles from "../../style/js-style/containers/doc-viewer/PanelThirdpartyStyle";
import EAIcon from "../../components/common/EAIcon";
import {Link} from "react-router-dom";
import {Context} from "../../states/Store";
import {useParams} from "react-router";
import {Tooltip} from "@mui/material";
import clsx from "clsx";

function PanelThirdparty({ thirdparty, nameOnly = false }) {
  const { classes } = useStyles();
  const state = useContext(Context);
  const { accountId } = useParams();
  const redirectLink = () => `/${accountId || state.account?.id}/${ROUTES.THIRDPARTY_DETAIL.replace(":thirdpartyId", thirdparty.thirdpartyId)}`;

  return (
    <div className={ nameOnly ? classes.rootDO : classes.root}>
        {/* Thirdparty title */}
        {!nameOnly && <span className={classes.thirdTitle}>
          <EAIcon icon="thirdparty-icon-grey" className={classes.mr}/>
          <span className={classes.thirdTitlePart}>{i18next.t("thirdparty")}</span>
        </span>}
        {/* Third name*/}
        <div style={{display: "flex", alignItems: "baseline"}}>
          <Tooltip title={thirdparty.thirdpartyName} arrow>
            <span className={clsx("dt", classes.thirdName, classes.spreadTool)}>
              {thirdparty.thirdpartyName}
            </span>
          </Tooltip>
          <Link to={redirectLink}>
            <EAIcon icon={"open-page_green"} alt={i18next.t("open")} className={classes.ml}/>
          </Link>
        </div>
        {/* Third activity */}
        {!nameOnly && <Tooltip title={thirdparty.thirdpartyActivityLabel} arrow>
          <span className={clsx(classes.thirdActivity, classes.spreadTool)}>
            {thirdparty?.thirdpartyActivityLabel}
          </span>
        </Tooltip>}
    </div>
  );
}

export default PanelThirdparty;
