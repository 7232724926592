import { Divider, Menu, MenuItem, Tooltip, Typography, } from "@mui/material";
import clsx from "clsx";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext, useEffect } from "react";
import { Context } from "../states/Store";
import i18next from "i18next";
import _ from "lodash";
import { useHistory } from "react-router";
import ROUTES from "../config/Routes";
import useStyles from "../style/js-style/components/MenuAppBarStyle"
import EAIcon from "./common/EAIcon";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "../services/common/RolesUtils";
import useKeycloak from "./common/hooks/useKeycloak";
import EAAvatar from "./common/EAAvatar";

const MenuProfilItem = ({ value, isMouseDown, color = "#7E7E8F" }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.profilMenuWrapper} style={{ padding: "6px 22px" }}>
      <div className={classes.profilMenuImgWrapper}>
        <EAIcon icon={isMouseDown ? (value + "_onClick") : value} />
      </div>
      <Typography
        variant="inherit"
        className={classes.profilMenuItemText}
        sx={
          { color: color }
        }
      >
        {i18next.t(value)}
      </Typography>
    </div>
  )
}

const getProfilMenuItem = (value, isMouseDown) => {
  switch (value) {
    case "profile": return <MenuProfilItem value={value} isMouseDown={isMouseDown} />
    case "users": return <MenuProfilItem value={value} isMouseDown={isMouseDown} />
    case "logout": return <MenuProfilItem value={value} color={"#E84343"} isMouseDown={isMouseDown} />
    default: return null
  }
}

const menuOptions = [
  {
    key: "profile",
    text: "profile",
    authorizedPrivileges: null
  },
  {
    key: "disconnect",
    text: "logout",
    authorizedPrivileges: null
  },
];

function MenuAppBar({ variant = "default" }) {
  const [state, dispatch] = useContext(Context);
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const adminMenuOpen = Boolean(adminAnchorEl);
  let history = useHistory();
  const {logoutKeycloak} = useKeycloak()
  const {isGlobalAdmin, hasAnyOfPrivileges } = React.useContext(RoleBasedAccessControlContext);


const adminOptions = [
  isGlobalAdmin ? {
    key: "demandes",
    text: "adminSettings.demandes",
    authorizedPrivileges: [PrivilegeEnum.PRIVILEGE_ALL_DOSSIER]
  }: null,
  {
    key: "attributes",
    text: "attributesLabel",
    authorizedPrivileges: [PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE,PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE]
  },
  {
    key: "dossierRules",
    text: "dossierRules",
    authorizedPrivileges: [PrivilegeEnum.PRIVILEGE_VIEW_RULE]
  },
  {
    key: "calculator",
    text: "adminSettings.calculator",
    authorizedPrivileges: [PrivilegeEnum.PRIVILEGE_VIEW_CALCULATION]
  },
  {
    key: "roles",
    text: "Rôles",
    authorizedPrivileges: [PrivilegeEnum.PRIVILEGE_VIEW_ROLE]
  },
  {
    key: "users",
    text: "users",
    authorizedPrivileges: [PrivilegeEnum.PRIVILEGE_VIEW_USER]
  },
  isGlobalAdmin ? {
    key: "importThirdParties",
    text: "importThirdParties",
    authorizedPrivileges: [PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY]
  } : null,
].filter(item => item !== null);


  const [itemMouseDown, setItemMouseDown] = React.useState({
    profile: false,
    users: false,
    logout: false
  });

  const handleMouseDown = (item) => {
    setItemMouseDown((prev) => {
      return { ...prev, [item.text]: !prev[item.text] }
    })
  }

  const handleMouseLeave = () => {
    setItemMouseDown({
      profile: false,
      users: false,
      logout: false
    })
  }

  useEffect(() => {
    dispatch({ type: "USER_INFOS", user: state.keycloak.idTokenParsed });
  }, [dispatch, state.keycloak]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAdminMenuClick = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAdminAnchorEl(null);
  };

  const handleItemClick = (item) => {
    if (item.key === "disconnect") {
      logoutKeycloak()

    } else if (item.key === "profile") {
      history.push(`/${state.account.id}/${ROUTES.PROFILE}`)
      setAnchorEl(null);
    }
  };

  const handleAdminItemClick = (item) => {
    switch (item.key) {
      case "demandes":
        history.push(`/${state.account.id}/${ROUTES.ADMIN}?tab=demandes`);
        break;
      case "attributes":
        history.push(`/${state.account.id}/${ROUTES.ADMIN}?tab=attributes`);
        break;
      case "dossierRules":
        history.push(`/${state.account.id}/${ROUTES.ADMIN}?tab=rules`);
        break;
      case "calculator":
        history.push(`/${state.account.id}/${ROUTES.ADMIN}?tab=calculator`);
        break;
      case "importThirdParties":
        history.push(`/${state.account.id}/${ROUTES.ADMIN}?tab=import`);
        break;
      case "users":
        history.push(`/${state.account.id}/${ROUTES.USERS}`);
        break;
      case "roles":
        history.push(`/${state.account.id}/${ROUTES.ROLES}`);
        break;
      default:
        break;
    }
  }

  return (
    <Toolbar className={variant === "users" ? classes.appBarToolbarUser : classes.appBarToolbar}>

      {/* Items nav appbar */}
      <div className={classes.appBarBloc}>

        {/* Admin Menu */}
        {!_.isEmpty(adminOptions) ? (
          <>
            <img
              src={process.env.PUBLIC_URL + (variant === "admin" ? "/img/icons/parameters_white.svg" : "/img/icons/parameters_grey.svg")}
              alt="Parameters"
              className={clsx(classes.navItem, classes.adminIcon)}
              onClick={handleAdminMenuClick}
            />

            <Menu
              id="adminMenu"
              anchorEl={adminAnchorEl}
              keepMounted
              open={adminMenuOpen}
              onClose={handleAdminMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {adminOptions.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => handleAdminItemClick(item)}
                    disabled={!hasAnyOfPrivileges(...item.authorizedPrivileges)}
                  >
                    <Typography variant="inherit">
                      {i18next.t(item.text)}
                    </Typography>
                  </MenuItem>
                )
              })}
            </Menu>
          </>
        ) : ""}

        {/* Circle with initial */}
        <Tooltip title={state.user.given_name + " " + state.user.family_name}>
          <>
            <EAAvatar
              onClick={handleMenuClick}
              userName={`${state.user.given_name} ${state.user.family_name}`}
              variant={variant}
            />
          </>
        </Tooltip>

        {/* Menu when deploy account circle */}
        <Menu
          id="appBarMenu"
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >

          {/* Header menu info */}
          <span className={classes.profilMenuName}>
            <span className="dt">{state.user?.given_name + " " + state.user?.family_name}</span>
          </span><br />
          <Divider className={classes.profilMenuDivider} />

          {/* Menu items */}
          {menuOptions.map((item, index) => (
            item.authorizedPrivileges === null || hasAnyOfPrivileges(...item.authorizedPrivileges)
              ? (
                <div
                  key={index}
                  onMouseUp={() => handleItemClick(item)}
                  onMouseDown={() => handleMouseDown(item)}
                  onMouseLeave={() => handleMouseLeave()}
                  className={
                    item.text === "logout"
                      ? classes.menuItems + " " + classes.menuItemsRed
                      : classes.menuItems
                  }
                >
                  {getProfilMenuItem(item.text, itemMouseDown[item.text])}
                </div>
              )
              : (
                <div
                  key={index}
                  className={classes.menuItemsDisabled}
                >
                  {getProfilMenuItem(item.text, itemMouseDown[item.text])}
                </div>
              )
          ))}
        </Menu>
      </div>
    </Toolbar>
  )
}

export default MenuAppBar;
