import { Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import EABack from "../../../components/common/EABack";
import DossierFillInfos from "./DossierFillInfos";
import { useParams } from "react-router";
import { getThirdparty } from "../../../services/edge/ThirdpartyService";
import { getAccountRequirements } from "../../../services/edge/AccountService";
import { ERROR } from "../../../services/common/Constants";
import ROUTES from "../../../config/Routes";
import { localizeTranslate } from "i18n/LocalizeUtils";

function DossierCreation() {
  const [state, dispatch] = useContext(Context);
  const [thirdparty, setThirdparty] = React.useState();
  const [documents, setDocuments] = React.useState([]);
  let { thirdpartyId } = useParams();

  useEffect(() => {
    if (state.account && thirdpartyId) {
      (async function fetchData() {
        try {
          const result = await getThirdparty(
            state.account.id,
            thirdpartyId,
            dispatch
          );
          setThirdparty(result?.content);
          dispatch({ type: "CRUMBS", crumbsData: {
            lastCrumbs: [
              {
                label: result?.content?.name,
                path: ROUTES.THIRDPARTY_DETAIL.replace(":thirdpartyId", thirdpartyId)
              },
              { label: "breadcrumb.addDossier" }
            ]
          }})
        } catch (error) {
          setThirdparty(null);
          if (
            error?.message?.error &&
            error?.message?.error[0]?.code === "NOT_FOUND"
          ) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "THIRDPARTY_NOT_FOUND" },
            });
          } else {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "INTERNAL_ERROR_DOSSIER_CREATION" },
            });
          }
        }
      })();
    }
  }, [state.account, thirdpartyId, dispatch]);

  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          const results = await getAccountRequirements(state.account.id, dispatch);
          setDocuments(
            results.content.map((x) => {
              return {
                id: x.code,
                ...x,
                descriptionFr : localizeTranslate(x.descriptionFr)
              };
            })
          );
        } catch (error) {}
      })();
    }
  }, [state.account, dispatch]);

  return (
    <div style={{maxHeight: "calc(100vh - 200px)"}}>
      <Typography variant="h2" sx={{ marginBottom: "1rem" }}>
        {i18next.t("addDossier.title")}
      </Typography>
      <EABack label={i18next.t("back")} />

      {thirdparty && (
        <DossierFillInfos
          thirdparty={thirdparty}
          documents={documents}
          dossierTypes={state.dossierTypes}
        />
      )}
    </div>
  );
}

export default DossierCreation;
