import { EDGE_DATE_FORMAT, ERROR } from "./Constants";
import moment from "moment";

export async function eaFetch(dispatch, api, verb, url, data, lang, isFile, signal) {

  const token = localStorage.getItem("365token");
  let isFetchDone = false;
  if(!token) {
    window.location.reload();
    return false;
  }

  let requestOptions = {
    method: verb,
    headers: {
      "client": "365",
      Authorization: "Bearer " + localStorage.getItem("365token"),
      ...(lang && {"Accept-Language": lang}),
    },
    signal
  };

  requestOptions = { 
      ...requestOptions, 
      headers: Object.assign(requestOptions.headers,  verb === "MULTIPART" ? {}: {"Content-Type": "application/json"}),};


  if(verb === "MULTIPART") {
    requestOptions = { ...requestOptions, 
                      body: data,
                      method: "POST",
                        };
  }

  if (verb !== "GET" && verb !== "MULTIPART") {
    requestOptions = { body: JSON.stringify(data), ...requestOptions };
  }
  try {
    const response = await fetch(api + url, requestOptions);
    isFetchDone = true;
    const headerFilename = response.headers.get('content-disposition')?.split('filename=')[1]?.split(';')[0].replaceAll("\"", "");
    if (response.ok) {
      if(isFile) {
        return response.arrayBuffer().then(function(buff) {
          return {filename: headerFilename, arrayBuffer : buff} || {}
        });
      } else {
        return response.text().then(function(text) {
          return text ? JSON.parse(text) : {};
        });
      }
    } else {
      let errorMsg = await response.json();
      let error = new Error();
  
      if (errorMsg) {
  
        if(errorMsg.error && errorMsg.error[0]?.code !== "NOT_FOUND"){
          /* eslint-disable no-throw-literal */
          dispatch && dispatch({ type: "ALERT", alert: {type: ERROR, msg : errorMsg?.error[0]} });
        }
  
        if(errorMsg?.messages?.error && errorMsg?.messages?.error[0]){
          /* eslint-disable no-throw-literal */
          dispatch && dispatch({ type: "ALERT", alert: {type: ERROR, msg : errorMsg?.messages?.error[0]} });
        }

        throw { ...error, message: errorMsg };
      } else {
        dispatch && dispatch({ type: "ALERT", alert: {type: ERROR, msg : "INTERNAL_ERROR"} });
        throw { ...error, code: "INTERNAL_ERROR" };
      }
    }
  } catch (e) {
    if(isFetchDone || isAbortError(e)) {
      throw e;
    } else {
      dispatch && dispatch({ type: "ALERT", alert: {type: ERROR, msg : "INTERNAL_ERROR"} });
      throw { ...e, code: "INTERNAL_ERROR", message: "INTERNAL_ERROR" };
    }
  }
}

export const objToQueryString = obj => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    );
  }
  return keyValuePairs.join("&");
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isAbortError(e) {
  return e instanceof DOMException && e.name === "AbortError";
}
// Helper function to recursively append key/value pairs to URLSearchParams
export const appendParams = (urlSearchParams, obj, prefix = "") => {
  for (let [key, value] of Object.entries(obj)) {
    const paramName = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(value)) {
      // If the value is an array, process each item
      value.forEach((item, index) => {
        appendParams(urlSearchParams, item, `${paramName}[${index}]`);
      });
    } else if (value && typeof value === "object" && !moment.isMoment(value)) {
      // Recursively call appendParams for nested objects
      appendParams(urlSearchParams, value, paramName);
    } else {
      // Format date to iso before append
      if (value && (moment.isMoment(value) || moment(value).isValid())) {
        value = moment(value).format(EDGE_DATE_FORMAT);
      }
      // Append the key/value pair
      if (value) {
        urlSearchParams.append(paramName, value);
      }
    }
  }
};