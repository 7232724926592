import React, { useContext, useEffect, useState, useCallback } from "react";
import i18next from "i18next";
import _ from "lodash";
import { Trans } from "react-i18next";
import countries from "i18n-iso-countries";
import { convert } from 'html-to-text';

import useStyles from "style/js-style/containers/administration/calculator/CalculatorStyle";
import DataTableButton from "components/common/datatable/DataTableButtons";
import CalculatorCreateDialog from "./dialogs/CalculatorCreateDialog";
import CalculatorEditDialog from "./dialogs/CalculatorEditDialog";
import CalculatorDeleteDialog from "./dialogs/CalculatorDeleteDialog";
import EAIcon from "components/common/EAIcon";
import { calculationListColumns, FORM_ID } from "./CalculatorDataTableUtils";
import { getAccountCalculations, toggleCalculationStatus } from "services/edge/CalculatorService";
import { Context } from "states/Store";
import { SUCCESS, WARNING, ERROR, Subscription } from "services/common/Constants";
import EADataGrid from "components/common/datatable/EADataGrid";
import { getAccountAttributesByBondType } from "services/edge/AttributesService";
import { getAccountRequirements } from "services/edge/AccountService";
import {
  CalculationNature,
  CalculatorContext,
  FREE_DOSSIER_TYPE_ID_VALUE
} from "services/common/CalculatorUtils";
import { hasOption } from "../../../services/common/Utils";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { getListActivity } from "services/edge/ListService";
import { FINANCIAL_STRENGTH_SCORES } from "services/common/DataBlocksUtils";

function CalculatorDataTable() {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const [activities, setActivities] = useState([]);
  const [allCountries, setAllCountries] = useState([]);

  const [thirdpartyAttributes, setThirdpartyAttributes] = useState([]);

  const [dossierAttributes, setDossierAttributes] = useState([]);
  const [dossierTypes, setDossierTypes] = useState([]);

  const [documentTypes, setDocumentTypes] = useState([]);

  const [calculatedAttributes, setCalculatedAttributes] = useState([]);
  const [thirdPartyIndicators, setThirdPartyIndicators] = useState([]);

  const [financialStrengthRatings, setFinancialStrengthRatings] = useState([]);
  const [financialStrengthScores, setFinancialStrengthScores] = useState([]);

  const [sourceVariables, setSourceVariables] = useState([]);
  const [showInduedScores, setShowInduedScores] = useState(false);

  // 'Show' has priority here
  const [showTransparencyScore, setShowTransparencyScore] = useState(false);
  const [enableTransparencyScore, setEnableTransparencyScore] = useState(false);

  const [showFinancialStrengthScores, setShowFinancialStrengthScores] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [calculations, setCalculations] = useState([]);
  const [displayableCalculations, setDisplayableCalculations] = useState([]);
  const [actifCalculationCounter, setActifCalculationCounter] = useState([]);
  const [selectedCalculation, setSelectedCalculation] = useState(undefined);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // Fonction de tri par ordre alphabétique des labels
  const sortByLabel = (values) => {
    return values.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }

  const fetchActivities = useCallback(async () => {
    try {
      setIsLoading(true);

      const resp = await getListActivity(undefined, dispatch);
      setActivities(resp.content.map(activity => {
        return {
          value: activity.code,
          label: activity.descriptionComplete.trim(),
          level: activity.level
        }
      }).sort((a, b) => a.label.localeCompare(b.label)));

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      setActivities([]);
    }
  }, [dispatch]);

  const fetchCountries = useCallback(async () => {
    try {
      setIsLoading(true);

      const isoCountries = countries.getNames(i18next.language);

      setAllCountries(Object.keys(isoCountries).map((code) => {
        return {
          value: code,
          label: isoCountries[code]
        }
      }));

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      setAllCountries([]);
    }
  }, [dispatch]);

  const fetchThirdPartyAttributes = useCallback(async () => {
    try {
      setIsLoading(true);

      const results = await getAccountAttributesByBondType(state.account.id, "thirdparty", null);
      setThirdpartyAttributes(results.content);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      setThirdpartyAttributes([]);
    }
  }, [state.account, dispatch]);

  const fetchDossierAttributes = useCallback(async () => {
    try {
      setIsLoading(true);

      const results = await getAccountAttributesByBondType(state.account.id, "dossier", null);
      setDossierAttributes(results.content);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      setDossierAttributes([]);
    }
  }, [state.account, dispatch]);

  const fetchDocumentTypes = useCallback(async () => {
    try {
      setIsLoading(true);

      const results = await getAccountRequirements(state.account.id, dispatch);
      setDocumentTypes(
        results.content.map((x) => {
          return {
            value: `${x?.documentId}`,
            label: localizeTranslate(x?.descriptionFr),
            properties: x?.properties?.map((property) => {
              return {
                id: property?.id,
                label: convert(property?.name),
                type: property?.type,
                valueOptions: property?.options?.map((option) => {
                  return {
                    value: option,
                    label: option
                  };
                }) || []
              };
            }) || []
          }
        })
      );

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      setDocumentTypes([]);
    }
  }, [state.account, dispatch]);

  const fetchCalculations = useCallback(async () => {
    try {
      setIsLoading(true);

      const results = await getAccountCalculations(state.account.id, null);

      // Order list by calculation name
      const orderedResults = results.content.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      setCalculations(orderedResults);

      setDisplayableCalculations(orderedResults.map((entry) => {
        return {
          ...entry,
          nature: i18next.t(`calculator.dialog.create.natureChoice.${entry.nature}`),
        };
      }));

      setCalculatedAttributes(_.filter(orderedResults, { nature: CalculationNature.CALCULATED_ATTRIBUTE, enabled: true }).map((entry) => {
        return {
          ...entry,
          value: `${entry.id}`,
          label: entry.name,
          type: entry.resultType,
        };
      }));

      setThirdPartyIndicators(_.filter(orderedResults, { nature: CalculationNature.INDICATOR, enabled: true }).map((entry) => {
        return {
          ...entry,
          value: `${entry.id}`,
          label: entry.name,
          type: entry.resultType,
        };
      }));

      setSourceVariables(_.filter(orderedResults, { nature: CalculationNature.SOURCE_VARIABLE, enabled: true }).map((entry) => {
        return {
          ...entry,
          value: `${entry.id}`,
          label: entry.name,
          type: entry.resultType,
        };
      }));

      setActifCalculationCounter(orderedResults.filter(entry => entry.enabled).length)

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
      setCalculations([]);
    }
  }, [state.account, dispatch]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    fetchThirdPartyAttributes();
  }, [fetchThirdPartyAttributes]);

  useEffect(() => {
    fetchDossierAttributes();
  }, [fetchDossierAttributes]);

  useEffect(() => {
    const mappedDossierTypes = [
      {
        value: `${FREE_DOSSIER_TYPE_ID_VALUE}`,
        label: localizeTranslate(i18next.t("free"))
      }
    ];

    if (state.dossierTypes && !_.isEmpty(state.dossierTypes)) {
      state.dossierTypes.forEach((dt) => {
        mappedDossierTypes.push({
          value: `${dt.id}`,
          label: dt.libelle
        });
      });
    }

    setDossierTypes(mappedDossierTypes);
  }, [state.dossierTypes]);

  useEffect(() => {
    fetchDocumentTypes();
  }, [fetchDocumentTypes]);

  useEffect(() => {
    fetchCalculations();
  }, [fetchCalculations]);

  useEffect(() => {
    if (!_.isEmpty(state.referenceData)) {
      setFinancialStrengthRatings(
        state.referenceData.financialStrengthRating
          ?.map((x) => ({
            label: x.rating,
            value: x.rating,
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label))
      );
      setFinancialStrengthScores(
        state.referenceData.financialStrengthScore
          ?.filter(x => x.code !== FINANCIAL_STRENGTH_SCORES.VERY_HIGH) // We don't show 'very high' as for now, and it's treated as 'high'
          ?.map(x => ({
            label: i18next.t(`datablocks.financialStrength.severity.${x.code?.toLowerCase()}`),
            value: x.code,
          }))
      );
    }
  }, [state.referenceData])

  useEffect(() => {
    setShowInduedScores(
      hasOption(state.accounts, state.account.id, Subscription.INDUED) &&
      hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE)
    )
  }, [state.accounts, state.account.id, hasAnyOfPrivileges]);

  useEffect(() => {
    const bTransparencyOptionActive = hasOption(state.accounts, state.account.id, Subscription.TRANSPARENCY);
    setShowTransparencyScore(bTransparencyOptionActive);

    if (bTransparencyOptionActive) {
      setEnableTransparencyScore(hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE))
    } else {
      setEnableTransparencyScore(true);
    }
  }, [state.accounts, state.account.id, hasAnyOfPrivileges]);

  useEffect(() => {
    setShowFinancialStrengthScores(
      hasOption(state.accounts, state.account.id, Subscription.DATABLOCKS_FINANCIAL_STRENGTH) &&
      hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE)
    )
  }, [hasAnyOfPrivileges, state.account.id, state.accounts])

  const context = React.useMemo(
    () => ({
      selectedCalculation: selectedCalculation,
      activities: sortByLabel(activities),
      countries: sortByLabel(allCountries),
      thirdpartyAttributes: sortByLabel(thirdpartyAttributes),
      dossierAttributes: sortByLabel(dossierAttributes),
      dossierTypes: sortByLabel(dossierTypes),
      documentTypes: sortByLabel(documentTypes),
      calculatedAttributes: sortByLabel(calculatedAttributes),
      thirdPartyIndicators: sortByLabel(thirdPartyIndicators),
      financialStrengthScores: financialStrengthScores,
      financialStrengthRatings: financialStrengthRatings,
      sourceVariables: sortByLabel(sourceVariables),
      calculations: calculations,
      showInduedScores: showInduedScores,
      showTransparencyScore: showTransparencyScore,
      enableTransparencyScore: enableTransparencyScore,
      showFinancialStrengthScores: showFinancialStrengthScores,
    }),
    [
      selectedCalculation,
      activities,
      allCountries,
      thirdpartyAttributes,
      dossierAttributes,
      dossierTypes,
      documentTypes,
      calculatedAttributes,
      thirdPartyIndicators,
      financialStrengthScores,
      financialStrengthRatings,
      sourceVariables,
      calculations,
      showInduedScores,
      showTransparencyScore,
      showFinancialStrengthScores,
      enableTransparencyScore
    ]
  );

  /** Recherche et sélectionne le calcul actif */
  const setSelectedById = useCallback((id) => {
    setSelectedCalculation(_.find(calculations, { id: id }));
  }, [calculations]);

  const openCreateCalculation = useCallback(() => {
    setSelectedCalculation(undefined);
    setIsCreateDialogOpen(true);
  }, []);

  const openDeleteDialog = useCallback((id) => {
    setSelectedById(id);
    setIsDeleteDialogOpen(true);
  }, [setSelectedById]);

  const openEditDialog = useCallback((id) => {
    setSelectedById(id);
    setIsEditDialogOpen(true);
  }, [setSelectedById]);

  /** Activation / Désactivation du calcul */
  const onToggleCalculationStatus = useCallback(async (calculationId) => {
    try {
      let calculationToUpdate = _.find(calculations, { id: calculationId });
      await toggleCalculationStatus(state.account.id, calculationId, dispatch);

      // Notification
      const name = calculationToUpdate.name;
      const type = calculationToUpdate.enabled ? WARNING : SUCCESS;
      const message = calculationToUpdate.enabled
        ? <Trans i18nKey="calculator.notifications.toggleDeactivation"> Le calcul <span style={{ color: "#0B2810", fontWeight: 600 }}>"{{ name }}"</span> a bien été activé.</Trans>
        : <Trans i18nKey="calculator.notifications.toggleActivation"> Le calcul <span style={{ color: "#0B2810", fontWeight: 600 }}>"{{ name }}"</span> a bien été désactivé.</Trans>

      dispatch({
        type: "ALERT",
        alert: { type: type, msg: message },
      });

      fetchCalculations();
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  }, [state.account, calculations, fetchCalculations, dispatch]);

  return (
    displayableCalculations &&
    <CalculatorContext.Provider value={context}>
      {/* Header */}
      <div className={classes.headerWrapper}>
        <div className={classes.headerMetaWrapper}>
          <EAIcon icon={"calculate"} className={classes.metaLabelIcon} />
          <span className={classes.headerMetaInfo}>{actifCalculationCounter} {actifCalculationCounter > 1 ? i18next.t("calculator.actifCalculate_other") : i18next.t("calculator.actifCalculate_one")}</span>
        </div>
        <DataTableButton
          onClickHandler={() => openCreateCalculation()}
          label={i18next.t("calculator.createCalcul")}
          disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_CALCULATION)}
        />
      </div>
      <div className={classes.separate} />
      {/* DataGrid */}
      <div className={classes.dataGridWrapper} >
        <EADataGrid
          isLoading={isLoading}
          columns={calculationListColumns(openEditDialog, openDeleteDialog, onToggleCalculationStatus, hasAnyOfPrivileges)}
          inputRows={displayableCalculations}
          pageSize={10}
          hasCustomPagination
          additionnalsLeftComponents={<div className={classes.dataGridTitle}>{i18next.t("calculator.datagridTitle")}</div>}
        />
      </div>

      {/* PopUp : on create */}
      {isCreateDialogOpen && (
        <CalculatorCreateDialog
          isOpen={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onValidateSuccess={() => {
            setIsCreateDialogOpen(false);
            fetchCalculations();
          }}
          formId={FORM_ID.create}
        />
      )}

      {/* PopUp : on edit */}
      {isEditDialogOpen && (
        <CalculatorEditDialog
          calculation={selectedCalculation}
          isOpen={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onDelete={() => {
            setIsEditDialogOpen(false);
            openDeleteDialog(selectedCalculation.id);
          }}
          onValidateSuccess={() => {
            setIsEditDialogOpen(false);
            fetchCalculations();
          }}
          formId={FORM_ID.edit}
        />
      )}

      {/* PopUp : on delete */}
      {isDeleteDialogOpen && (
        <CalculatorDeleteDialog
          calculation={selectedCalculation}
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onValidateSuccess={() => {
            setIsDeleteDialogOpen(false);
            setIsEditDialogOpen(false);
            fetchCalculations();
          }}
        />
      )}
    </CalculatorContext.Provider>
  )
}

export default CalculatorDataTable;
