import React from "react";
import {Typography} from "@mui/material";
import i18next from "i18next";
import { localizeTranslate } from "i18n/LocalizeUtils";


const DossierUpdateDocumentRecap = ({selectedDocuments, removedDocuments} ) => {

  if(selectedDocuments?.length === 0) {
    return (
    <>
      <Typography sx={{fontWeight: "bold", mb:"1.5rem"}} align="center">
        {i18next.t("updateDocument.emptyTitle")}
      </Typography>
      <Typography variant="body1" sx={{mb:"0.5rem"}} align="center">
        {i18next.t("updateDocument.emptyBody")}
      </Typography>

    </>
    );
  }
  else {
    return (
        <>
          { removedDocuments?.length > 0 && (
              <>
                <Typography sx={{fontWeight: "bold", mb:"1.5rem"}} align="center">
                  {i18next.t("updateDocument.stopDocuments")}
                </Typography>
                {
                  removedDocuments.map( d => (
                      <Typography key={d.id} variant="body1" sx={{mb:"0.5rem"}} align="left" marginLeft={"50px"}>
                        {localizeTranslate(d.descriptionFr)}
                      </Typography>
                  ))
                }
              </>
              )

          }
          {(
              <>
                <Typography sx={{fontWeight: "bold", mb:"1.5rem"}} align="center">
                  {i18next.t("updateDocument.addDocuments")}
                </Typography >
                {
                  selectedDocuments.map( d => (
                      <Typography key={d.id} variant="body1" sx={{mb:"0.5rem"}} align="left" marginLeft={"50px"}>
                        {localizeTranslate(d.descriptionFr)}
                      </Typography>
                  ))
                }
              </>
          )

          }

        </>
  );
  }

};

export default DossierUpdateDocumentRecap;
