import React from "react";
import { Chip } from "@mui/material";
import useStyles from "../style/js-style/components/EAChipStyle"

const EAChip = ({label, color}) => {
    const { classes } = useStyles();
    return (
        <Chip
            className={classes.root}
            style={{color, borderColor: color}}
            label={label}
            variant="outlined"
            size="small"
            color="default"
        />
    )
}
export default EAChip;
