import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../../states/Store";
import EADataGrid from "../../../components/common/datatable/EADataGrid";
import { getAccountAttributesByBondType } from "../../../services/edge/AttributesService";
import { attributesListColumns } from "./AttributesDataTableUtils";
import DataTableButton from "../../../components/common/datatable/DataTableButtons";
import i18next from "i18next";
import AttributeCreateDialog from "./AttributeCreateDialog";
import AttributeEditDialog from "./AttributeEditDialog";
import AttributeDeleteDialog from "./AttributeDeleteDialog";
import useStyles from "../../../style/js-style/containers/administration/attributes/AttributesDataTableStyle";
import {MenuItem} from "@mui/material";
import { CountSelectedRow } from "../../../components/common/datatable/CountSelectedRow";
import EAIcon from "../../../components/common/EAIcon";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "../../../services/common/RolesUtils";

const AttributesCounter = ({bondType, attributesCount}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerMetaWrapper}>
        <img src={process.env.PUBLIC_URL + (bondType === "dossier" ? "/img/icons/dossier-label_grey.svg" : "/img/icons/thirdparty-label_grey.svg")} alt="" className={classes.metaLabelIcon} />
        <span className={classes.headerMetaInfo}>{attributesCount} {i18next.t("attribute")}{attributesCount > 1 && "s"}</span>
      </div>
    </div>
  )
}

function AttributesDataTable({ bondType }) {
  const [loader, setLoader] = useState(true);
  const [attributesList, setAttributesList] = useState([]);
  const [attributesSelectedIds, setAttributesSelectedIds] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [state] = useContext(Context);
  const { classes } = useStyles();
  const { hasAnyOfPrivileges } = React.useContext(RoleBasedAccessControlContext);

  const fetchAttributesList = useCallback(async () => {
    try {
      setLoader(true);
      const results = await getAccountAttributesByBondType(state.account.id, bondType, null);
      // Order results by Creation Date
      let resultsOrdered = results.content.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      );
      setAttributesList(
        resultsOrdered.map((x) => {
          return {
            ...x,
            typeLabel: i18next.t(`attributeType.${x.type}`),
          };
        })
      );
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setAttributesList([]);
    }
  }, [state.account, bondType]);

  useEffect(() => {
    if (state.account) {
      fetchAttributesList();
    }
  }, [state.account, fetchAttributesList]);

  const openDeleteDialog = (attributesIdsList) => {
    setIsDeleteDialogOpen(true);
    setSelectedAttributes(
      attributesList.filter(
        (attribute) => attributesIdsList.indexOf(attribute.id) !== -1
      )
    );
  };

  const openEditDialog = (attributeId) => {
    setIsEditDialogOpen(true);
    setSelectedAttributes([
      attributesList.find((attribute) => attribute.id === attributeId),
    ]);
  };

  const AttributesTableButtons = () => {
    return (
      <div>
        <div className={classes.dataTableActions}>
          <MenuItem
            onClick={() => openDeleteDialog(attributesSelectedIds, "")}
            disabled={attributesSelectedIds.length < 1}
            className={attributesSelectedIds.length < 1 ? classes.deleteAllBtnDisabled : classes.deleteAllBtn}
          >
            <EAIcon icon={"delete_grey"} />
            Supprimer
          </MenuItem>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Add attribute */}
      <div
        style={{
          position: "absolute",
          top: "80px",
          right: "36px",
        }}
      >
        <DataTableButton
          onClickHandler={() => setIsCreateDialogOpen(true)}
          label={i18next.t("attributes.createLabel", {type: i18next.t(bondType).toLowerCase()})}
          disabled={
            bondType === "dossier"
              ? !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE)
              : !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE)
          }
        />
      </div>

      {/* DataGrid */}
      <div className={classes.dataGridWrapper}>
        <EADataGrid
          loading={loader}
          inputRows={attributesList}
          columns={
            attributesListColumns(
              openEditDialog,
              openDeleteDialog,
              hasAnyOfPrivileges,
              bondType)
          }
          hasCustomPagination
          customSx={{
            "& .MuiDataGrid-columnHeader:nth-child(2)": { color: "#7E7E8F" },
          }}
          selection={{
            items: attributesSelectedIds,
            setItems: setAttributesSelectedIds,
          }}
          additionnalsLeftComponents={
            <>
              <AttributesCounter bondType={bondType} attributesCount={attributesList.length}/>
              <CountSelectedRow data={attributesSelectedIds} />
              <AttributesTableButtons />
            </>
          }
          pageSize={10}
        />
      </div>

      {/* PopUp : on create */}
      {isCreateDialogOpen && (
        <AttributeCreateDialog
          isOpen={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onValidateSuccess={fetchAttributesList}
          bondType={bondType}
        />
      )}

      {/* PopUp : on edit */}
      {isEditDialogOpen && (
        <AttributeEditDialog
          attribute={selectedAttributes[0]}
          isOpen={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onValidateSuccess={fetchAttributesList}
          onDeleteOpen={() => setIsDeleteDialogOpen(true)}
          bondType={bondType}
        />
      )}

      {/* PopUp : on delete */}
      {isDeleteDialogOpen && (
        <AttributeDeleteDialog
          attributesList={selectedAttributes}
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onValidateSuccess={fetchAttributesList}
          bondType={bondType}
        />
      )}
    </>
  );
}
export default AttributesDataTable;
