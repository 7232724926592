import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { IconButton, Tooltip } from "@mui/material";
import EAAvatar from "components/common/EAAvatar";
import { formatDateAndTime } from "services/common/Utils";
import EAIcon from "components/common/EAIcon";
import NoteDeleteDialog from "containers/notes/NoteDeleteDialog";
import useStyles from "../../style/js-style/containers/notes/NotesDisplayStyle";
import clsx from "clsx";
import {Context} from "../../states/Store";
import { getAccountUsers } from "services/edge/UserService";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import NoteInput from "./NoteInput";

const NoteDisplay = ({ noteToDisplay, fetchNotes, isLastElement, setNoteToEdit, noteToEdit, lastEdited, deleteNote, type, updateNote }) => {
  const { classes } = useStyles();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [hoveredLine, setHoveredLine] = useState(false);
  const [usersMentions, setUsersMentions] = useState([]);
  const [state] = useContext(Context);
  const [isOwnNote, setIsOwnNote] = useState(false);
  const [isEditedNote, setIsEditedNote] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  // eslint-disable-next-line
  const [isLastEditedNote, setIsLastEditedNote] = useState(false); // TODO : à utiliser pour le ticket pour voir dernière édition en gras
  const { isGlobalAdmin, hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  // Récupère la liste des utilisateurs du compte sélectionné
  useEffect(() => {
    async function getUsers() {
      try {
        const result = await getAccountUsers( state.account.id );
        setUsersMentions(
          result.content
            .filter(user => user.status === "INSCRIT")
            .map(user => user.firstname+user.name)
        );
      } catch (error) {
        setUsersMentions([]);
      }
    }
    if (state.account){
      getUsers();
    }
  }, [state.account]);

  // Determine si la note actuelle est la dernière qui as été modifié
  useEffect(()=>{
    if(lastEdited !== null){
      setIsLastEditedNote(lastEdited?.noteOId === noteToDisplay?.noteOId)
    }
  }, [lastEdited, noteToDisplay, setIsLastEditedNote])

  // Determine si la note actuelle est une note de l'utilisateur connecté
  useEffect(() => {
    if (state.user && noteToDisplay) {
      setIsOwnNote(state.user?.id === noteToDisplay?.userOid)
    }
  }, [state.user, noteToDisplay])

  // Determine si la note actuelle est en cours d'édition
  useEffect(() => {
    setIsEditedNote(noteToDisplay?.noteOId === noteToEdit?.noteOId)
  }, [noteToDisplay, noteToEdit])

  // Formate le texte pour styliser les mentions dans les notes
  const formatTextWithMentions = (text) => {
    if(text !== null){
      const words = text.split(' ');
      const formattedWords = words.map((word) => {
        if (word.startsWith('@') && usersMentions?.includes(word.substring(1))) {
          return `<span style="color: #227730; background-color: #EFFBF1; padding: 4px 6px; font-weight: 700; border-radius: 16px">${word}</span>`;
        }else{
          return word;
        }
      });
      return formattedWords.join(' ');
    }else{
      return null;
    }
  };

  return (
    <div
      style={{
        backgroundColor: isEditedNote
          ? "rgba(239,251,241,0.61)"
          : hoveredLine
            ? "rgba(242,243,247,0.5)"
            : "",
        transition: "all 0.3s",
      }}
      >
        <div
          className={ clsx(classes.root, !isLastElement && classes.separate) }
          onMouseEnter={ () => setHoveredLine(true) }
          onMouseLeave={ () => setHoveredLine(false) }
        >
            {/* Avatar */}
            <EAAvatar
              userName={noteToDisplay.prenomNom}
              variant={"display-note"}
              style={{ position: "absolute", top: "5px", left: 0 }}
            />

            {/* Content */}
            { isEditOpen
              // Edition d'une note
              ? (
                <div className={classes.content}>
                  <NoteInput
                    fieldName={`note`}
                    isRequired={true}
                    placeholder={i18next.t("notes.input.placeholder")+"..."}
                    noteToEdit={noteToDisplay}
                    setLastEdited={setIsLastEditedNote}
                    setNoteToEdit={setNoteToEdit}
                    onCancel={() => setIsEditOpen(false)}
                    disabled={!hasAnyOfPrivileges(PrivilegeEnum[`PRIVILEGE_CREATE_${type}_NOTES`])}
                    fetchNotes={fetchNotes}
                    updateNote={updateNote}
                  />
                </div>
              )
              // Affichage de la note
              : (
                <>
                  <div className={classes.noteHeader}>
                    <span className={clsx('dt', classes.name)}>{noteToDisplay.prenomNom}</span>
                    <span className={clsx('dt', classes.date)}>{formatDateAndTime(noteToDisplay.dateCreation)} {noteToDisplay.dateUpdate !== null ? "(note modifiée)" : ""}</span>
                  </div>

                  <div className={classes.content}>
                  <span
                    style={{
                      // fontWeight: isLastEditedNote ? 700 : "",
                      whiteSpace: "pre-line"
                    }}
                    className={"dt"}
                    dangerouslySetInnerHTML={{__html: formatTextWithMentions(noteToDisplay.contenu)}}
                  />
                  </div>
                </>
              )
            }

            {/* Controls */}
            { !isEditOpen && hoveredLine && (isGlobalAdmin || (isOwnNote && hasAnyOfPrivileges(PrivilegeEnum[`PRIVILEGE_EDIT_${type}_NOTES`]))) &&
                <div className={classes.controlWrapper}>
                    {/*Modifier*/}
                    <Tooltip title={i18next.t("modify")} arrow>
                        <span>
                            <IconButton
                                size="small"
                                onClick={() => setIsEditOpen(true)}
                                disabled={false}
                            >
                                <EAIcon icon={"edit_filled"} alt={i18next.t("modify")} />
                            </IconButton>
                        </span>
                    </Tooltip>

                    {/*Supprimer*/}
                    <Tooltip title={i18next.t("delete")} arrow>
                        <span>
                            <IconButton
                                size="small"
                                onClick={() => { setIsDeleteDialogOpen(true) }}
                                disabled={false}
                            >
                                <EAIcon icon={"delete_filled"} alt={i18next.t("delete")} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            }
        </div>

        {/* Pop-up suppression de note */}
        {isDeleteDialogOpen && (
            <NoteDeleteDialog
                note={noteToDisplay}
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                onValidateSuccess={() => {
                    setIsDeleteDialogOpen(false);
                    fetchNotes();
                    setNoteToEdit(null);
                }}
                deleteNote={deleteNote}
            />
        )}
    </div>
  );
}

export default NoteDisplay;
