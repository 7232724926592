import EAButton from "components/common/EAButton";
import EAIcon from "components/common/EAIcon";
import i18next from "i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DossierModelDataTable from "./DossierModelDataTable";
import { getDossierModel, getDossierModelTemplate } from "services/edge/DossierModelService";
import { Context } from "states/Store";
import { ERROR } from "services/common/Constants";
import DragAndDropComponent from "./DragAndDropComponent";
import useStyles from "./DossierModelTabStyle";
import moment from "moment";
import DossierModelCreateDialog from "./dialogs/DossierModelCreateDialog";
import { getAccountDossierTypes } from "services/edge/AccountService";
import { partition as lodashPartition} from "lodash";

function DossierModelTab() {
  const { classes } = useStyles();

  const [state, dispatch] = useContext(Context);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [dossierModelList, setDossierModelList] = useState([]);

  const downloadTemplate = useCallback(() => {
    if (state.account) {

      (async function fetchData() {
        try {

          const resp = await getDossierModelTemplate(
            state.account.id,
            dispatch
          );
          let blob = new Blob([resp?.arrayBuffer]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          let dateStr = moment().format('YYYYMMDD');
          link.download = `${dateStr}_${state.account.name}_TEMPLATE_MODELES_DOSSIERS.xlsx`;
          link.click();
          link.remove();

        } catch (e) {
          if (!e?.message.messages?.error) {
            console.error(e);
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "INTERNAL_ERROR" },
            });
          }
        }
      })();
    }
  }, [dispatch, state.account]);

  const fetchDossierModelList = useCallback(async () => {
    try {
      const results = await getDossierModel(state.account.id, null);
      // Order results by Creation Date
      let resultsOrdered = results.content.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      );
      setDossierModelList(
        resultsOrdered.map((x) => {
          return {
            ...x,
            nbDocuments: x.askedDocs.length,
          };
        })
      );
      const dossierTypes = await getAccountDossierTypes(state.account.id, dispatch);
      const sortedDossiersTypes = dossierTypes.content?.sort((a, b) => a.libelle?.toLowerCase()?.localeCompare(b.libelle?.toLowerCase()));
      const [activeTypes, inactiveTypes] = lodashPartition(sortedDossiersTypes, 'active');
      dispatch({ type: "DOSSIER_TYPES", dossierTypes: activeTypes });
      dispatch({ type: "INACTIVE_DOSSIER_TYPES", dossierTypes: inactiveTypes });
    } catch (error) {
      setDossierModelList([]);
    }
  }, [state.account, dispatch]);

  useEffect(() => {
    if (state.account) {
      fetchDossierModelList();
    }
  }, [state.account, fetchDossierModelList]);

  return (
    <div className={classes.root}>
      {/*--------------------*/}
      {/* Création de modèle */}
      {/*--------------------*/}
      <div className={classes.rootEdit}>

        {/* Modèle unitaire */}
        <div className={classes.uniqueModelWrapper}>
          <EAIcon style={{ width: "60px" }} icon="demandes/add_dossier_model_green" />
          <div className={classes.uniqueModelTitle}> {i18next.t("demandes.dossierModel.createModel")} </div>
          <EAButton
            content={"Continuer"}
            onClick={() => setIsCreateDialogOpen(true)}
            size="large"
            customStyle={{ width: "250px", fontSize: "16px" }}
          />
        </div>

        {/* Séparation */}
        <div className={classes.separator}>
          <div className={classes.separatorLine}/>
          <div>OU</div>
          <div className={classes.separatorLine}/>
        </div>

        {/* Modèle en masse */}
        <div className={classes.multipleModelAndImportWrapper}>
          <div className={classes.multipleModelWrapper}>
            <div className={classes.multipleModelTitle}> {i18next.t("demandes.dossierModel.createModelMulti.title")}</div>
            <div className={classes.multipleModelContent}>
              <DragAndDropComponent fetchDossierModelList={fetchDossierModelList}/>
              <div>
                <div className={classes.multipleModelContentNoticeTitle}> {i18next.t("demandes.dossierModel.createModelMulti.requiredData")} </div>
                <div className={classes.multipleModelContentNoticeItem}>
                  <EAIcon style={{ width: "30px" }} icon="demandes/doc_green" />
                  <div>{i18next.t("demandes.dossierModel.createModelMulti.labelAndCodeDoc")}</div>
                </div>
                <div className={classes.multipleModelContentNoticeItem}>
                  <EAIcon style={{ width: "30px" }} icon="demandes/dossier_green" />
                  <div>{i18next.t("demandes.dossierModel.createModelMulti.labelAndCodeModel")}</div>
                </div>
                <div className={classes.multipleModelContentNoticeItem}>
                  <EAIcon style={{ width: "30px" }} icon="demandes/corresponding_green" />
                  <div style={{position: 'relative', top: "10px"}}>
                    <div>{i18next.t("demandes.dossierModel.createModelMulti.matchingDocModel")}</div>
                    <div className={classes.multipleModelContentNoticeItemSubTitle}>
                    <span>{i18next.t("demandes.dossierModel.createModelMulti.matchingDocModelCaption")}</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modèle d'import à télécharger */}
          <EAButton
            colorVariant="secondary_dark"
            onClick={() => downloadTemplate()}
            customStyle={{
              backgroundColor: "transparent",
              minHeight: 0,
              padding: 0,
              color: "rgba(83, 83, 98, 1)"
            }}
            content={
              <>
                <EAIcon icon={"download_grey_darker"} />
                <span style={{textDecoration: "underline"}}>{i18next.t("demandes.dossierModel.downloadModel")}</span>
              </>
            }
          />
        </div>
      </div>

      {/*---------------------*/}
      {/* Tableau des modèles */}
      {/*---------------------*/}
      <div className={classes.rootDatatable}>
        {dossierModelList &&
          <DossierModelDataTable
            dossierModelList={dossierModelList}
            fetchDossierModelList={fetchDossierModelList}
          />
        }
      </div>

      {/* PopUp : on create */}
      {isCreateDialogOpen && (
        <DossierModelCreateDialog
          isOpen={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onValidateSuccess={() => {
            setIsCreateDialogOpen(false);
            fetchDossierModelList();
          }}
        />
      )}
    </div>
  )
}

export default DossierModelTab;
