import React, { useEffect } from "react";
import i18next from "i18next";
import { Box, Fade, Grid, TextField } from "@mui/material";
import useStyles from "./DossiersListSearchFormStyle.js";
import EAIcon from "components/common/EAIcon";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import _ from "lodash";
import EAAutoCompleteInput from "components/inputs/EAAutoCompleteInput";
import EAButton from "components/common/EAButton";
import EADateRangePicker from "components/common/EADateRangePicker";
import { ERROR, statusEngCode } from "services/common/Constants";
import { AdvancedSearchButtons } from "components/lists-advanced-search/AdvancedSearchButtons";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";
import { DossiersAdvancedSearch } from "./DossiersAdvancedSearch/DossiersAdvancedSearch";
import { useContext } from "react";
import { Context } from "states/Store";
import { useState } from "react";
import { DossiersSearchLineActions } from "./DossiersSearchLineActions";
import { getAccountAttributesByBondType } from "services/edge/AttributesService";

const defaultValue = {
  //dossier
  dossierType: "",
  reference: "",
  start: null,
  end: null,
  status: "",
  //thirdparty
  name: "",
  code: "",
  companyIdType: "CIT003",
  identifier: "",

  // utility
  isAdvancedSearchShown: false,
};

export const DossiersListSearchForm = ({
  filters,
  submitHandler = () => {},
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [dossiersTypesOptions, setDossiersTypesOptions] = useState([]);
  const [attributes, setAttributes] = useState();

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: {
      dossiers: [defaultValue],
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    watch,
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "dossiers",
  });

  useEffect(() => {
    setValue("dossiers", filters.dossiers || [defaultValue]);
  }, [setValue, filters]);

  useEffect(() => {
    if (state.dossierTypes && state.inactiveDossierTypes) {
      let options = [...state.dossierTypes, ...state.inactiveDossierTypes].map((x) => ({
        label: x.libelle,
        value: x.code,
      }));
      options.push({ label: i18next.t("free"), value: "FREE" });
      setDossiersTypesOptions(
        options.sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [state.dossierTypes, state.inactiveDossierTypes]);

  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          const res = await getAccountAttributesByBondType(
            state.account.id,
            "dossier",
            null,
          );
          setAttributes(res.content);
        } catch (e) {
          dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
          setAttributes([]);
        }
      })();
    }
  }, [state.account, dispatch]);

  const submitForm = (data) => {
    if (_.isEmpty(errors)) {
      submitHandler(data);
    }
  };

  const getStatusOptions = () => {
    return Object.keys(statusEngCode)
      .filter((x) => x !== "INCONNU")
      .map((x) => ({
        label: i18next.t(`viewer.status.statusLabel.${x}`),
        value: x,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleReset = () => {
    const formDefaultValues = { dossiers: [defaultValue] };
    formMethods.reset(formDefaultValues);
    submitHandler(formDefaultValues);
  };

  const handleLineDelete = (index) => {
    remove(index);
  };

  const advancedSearchClickHandler = (index) => {
    const fieldName = `dossiers.${index}.isAdvancedSearchShown`;
    const isAdvancedSearchShown = getValues(fieldName);
    setValue(fieldName, !isAdvancedSearchShown);
  };

  const isAdvancedSearchShown = (index) => {
    return watch(`dossiers.${index}.isAdvancedSearchShown`);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id="thirdparty-search-form"
        onSubmit={handleSubmit(submitForm)}
        className={classes.formContainer}
      >
        <Grid container spacing={2} padding={"0 22px"}>
          {dossiersTypesOptions?.length > 0 &&
            fields?.map((field, index) => {
              return (
                <Grid item container key={field.id}>
                  <Grid item container spacing={2}>
                    <Grid item xs={2}>
                      <SearchFormInputBloc label={"Dossier"}>
                        <EAAutoCompleteInput
                          fieldName={`dossiers.${index}.dossierType`}
                          options={dossiersTypesOptions}
                          placeholder={"Tous"}
                          variant="outlined"
                          limitTags={1}
                        />
                      </SearchFormInputBloc>
                    </Grid>
                    <Grid item xs={2}>
                      <SearchFormInputBloc label={"Référence"}>
                        <Controller
                          control={control}
                          name={`dossiers.${index}.reference`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="identifier-id"
                              variant="outlined"
                              error={!!errors.id}
                              fullWidth
                            />
                          )}
                        />
                      </SearchFormInputBloc>
                    </Grid>
                    <Grid item xs={4}>
                      <SearchFormInputBloc label={"Ajouté entre"}>
                        <EADateRangePicker
                          formMethods={formMethods}
                          startName={`dossiers.${index}.start`}
                          endName={`dossiers.${index}.end`}
                          ref={null}
                        />
                      </SearchFormInputBloc>
                    </Grid>
                    <Grid item xs={2}>
                      <SearchFormInputBloc label={"Statut"}>
                        <EAAutoCompleteInput
                          fieldName={`dossiers.${index}.status`}
                          options={getStatusOptions()}
                          placeholder={"Tous"}
                          variant="outlined"
                          limitTags={1}
                        />
                      </SearchFormInputBloc>
                    </Grid>
                    <Grid item xs={2} display="flex">
                      <DossiersSearchLineActions
                        isAdvancedSearchShown={isAdvancedSearchShown(index)}
                        isDeleteBtnShown={formMethods.getValues("dossiers")?.length > 1}
                        onLineDeleteClick={() => handleLineDelete(index)}
                        onAdvancedSearchClick={() => advancedSearchClickHandler(index)}
                      />
                    </Grid>
                  </Grid>

                  <div
                    className={classes.advancedSearchContainer}
                    style={{
                      display: isAdvancedSearchShown(index) ? "block" : "none",
                    }}
                  >
                    <Fade in={isAdvancedSearchShown(index)} timeout={500}>
                      <div>
                        <DossiersAdvancedSearch attributes={attributes} lineIndex={index} />
                      </div>
                    </Fade>
                  </div>
                </Grid>
              );
            })}
          <Box display="flex" justifyContent="space-between" width="100%" pt={2} pl={2}>
            <EAButton
              borderless
              onClick={() => append(defaultValue)}
              type="button"
              content={
                <div className={classes.addDossBtn}>
                  <EAIcon
                    icon={"cross_green"}
                    extension="png"
                    style={{ width: "10px", marginRight: "6px" }}
                  />
                  <span>{i18next.t("addFilter")}</span>
                </div>
              }
            />
            <Box display="flex" mt="auto">
              <AdvancedSearchButtons handleReset={handleReset} />
            </Box>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};
