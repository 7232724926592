import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  input: {
    "& .MuiOutlinedInput-root":{
      paddingRight: "1rem !important",
      color: theme.palette.grey.grey500,
      fontWeight: 600,
      border: "none",
    },
  },
}));

export default useStyles;
