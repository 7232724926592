import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import EADialog from "components/common/EADialog";
import { ERROR, SUCCESS } from "services/common/Constants";
import { Context } from "states/Store";

import useStyles from "./DocumentManagementDialogStyle";
import DocumentManagementRecapBloc from "./DocumentManagementRecapBloc";
import EAButton from "components/common/EAButton";
import { getAllActiveTypeDoc } from "services/edge/DossierModelService";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { addAuthorizedDocType } from "services/edge/DocumentManagementService";
import DossierChoiceDocuments from "containers/dossiers/create-dossier/DossierChoiceDocuments";
import EAIcon from "components/common/EAIcon";
import { fetchDossierModelList } from "../DocumentManagementUtils";

const DocumentManagementAddDialog = ({
  isOpen,
  docList,
  onClose,
  onValidateSuccess
}) => {
  const [state, dispatch] = useContext(Context);
  const [currentStep, setCurrentStep] = useState("DOCUMENTS"); // DOCUMENTS, CONFIRM
  const [allDocuments, setAllDocuments] = useState();
  const [documentsSelected, setDocumentsSelected] = useState(docList?.map((d) => d.code) || []);
  const [addedDocs, setAddedDocs] = useState([]);
  const [removedDocs, setRemovedDocs] = useState([]);
  const [concernedDossierModels, setConcernedDossierModels] = useState([]);
  const [sortedDocuments, setSortedDocuments] = useState();
  const { classes } = useStyles();


  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          const results = await getAllActiveTypeDoc(state.account.id, dispatch);
          setAllDocuments(
            results.content.map((x) => {
              return {
                id: x.code,
                descriptionFr: localizeTranslate(x.name),
                code: x.code,
                category: x.category,
                name: x.name,
                index: x.documentId,
                selected: false
              };
            })
          );
        }
        catch (error) {
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "INTERNAL_ERROR" },
          });
        }
      })();
    }
  }, [state.account, dispatch]);

  useEffect(() => {
    const initialDocSelected = docList?.map((d) => d.code) || [];
    if (documentsSelected && initialDocSelected && allDocuments) {
      let toAdd = documentsSelected.filter(d => !initialDocSelected.includes(d));
      setAddedDocs(toAdd?.map(c => allDocuments.find(d => d.code === c)));

      let toRemove = initialDocSelected.filter(d => !documentsSelected.includes(d));
      setRemovedDocs(toRemove?.map(c => allDocuments.find(d => d.code === c)));
    }
  }, [documentsSelected, docList, allDocuments]);

  useEffect(() => {
    setSortedDocuments(
      allDocuments?.map(d => (
        {
          ...d,
          selected: documentsSelected?.includes(d.code)
        }
      )).sort((a, b) => (a.selected ? -1 : 1))
    );
  }, [documentsSelected, allDocuments]);

  useEffect(() => {
    const getConcernedDossierModels = async () => {
      if (state.account && removedDocs) {
        let dossierModelList = await fetchDossierModelList(state.account);
        let concernedModels = [];
        removedDocs.forEach(doc => {
          dossierModelList?.forEach(m => {
            if (m.askedDocs.map(d => d.code).includes(doc) && !concernedModels.includes(m)) {
              concernedModels.push(m);
            }
          })
        })
        setConcernedDossierModels(concernedModels)
      }
    }
    getConcernedDossierModels();
  }, [state.account, removedDocs]);

  const onValidate = async () => {
    if (state.account.id && documentsSelected) {
      try {
        await addAuthorizedDocType(
          state.account.id,
          { docCode: documentsSelected},
          null
        );

        dispatch({
          type: "ALERT",
          alert: { type: SUCCESS, msg: i18next.t("demandes.documentManagement.notifications.addSuccess") },
        });
        onValidateSuccess();

      } catch (error) {
        console.error(error);
        const err =
          error?.message?.messages?.error && error?.message?.messages?.error[0];
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
        });
      }
    }
  };

  const cancelDialogHandler = () => {
    onClose();
  };

  return (
    <EADialog
      isOpen={isOpen}
      title={i18next.t("demandes.dossierModel.editDialog.titleBis")}
      onCancel={cancelDialogHandler}
      onClose={onClose}
      maxWidth="md"
      variant="default"
      fullWidth
      hasActions={false}
    >

      {["DOCUMENTS"].includes(currentStep) && (
        <>
          {sortedDocuments ? (
            <DossierChoiceDocuments
              documents={sortedDocuments}
              selection={{
                items: documentsSelected,
                setItems: setDocumentsSelected,
              }}
              context="dossier-model"
            />
          ) : (
            <>
              <EAIcon icon={"loop"} />
              <span style={{ fontWeight: "bold", textAlign: "center" }}>{i18next.t("loading")}</span>
            </>
          )}

          {/* --- Actions --- */}
          {/* Annuler / Valider */}
          <div className={classes.declarationControlsRight}>
            <EAButton
              onClick={(e) => {
                e.preventDefault();
                cancelDialogHandler();
              }}
              content={i18next.t("cancel")}
              customStyle={{ width: "80px" }}
              colorVariant={"secondary"}
            />

            <EAButton
              onClick={(e) => {
                e.preventDefault();
                setCurrentStep("CONFIRM");
              }}
              content={i18next.t("valid")}
              colorVariant={"primary"}
              customStyle={{ width: "117px" }}
            />
          </div>
        </>
      )}

      {["CONFIRM"].includes(currentStep) && (
        <>
          <DocumentManagementRecapBloc
            docToAddList={addedDocs}
            docToRemoveList={removedDocs}
            dossierModelList={concernedDossierModels}
          />
          {/* --- Actions --- */}
          {/* Annuler / Valider */}
          <div className={classes.declarationControlsRight}>
            <EAButton
              onClick={(e) => {
                e.preventDefault();
                setCurrentStep("DOCUMENTS");
              }}
              content={i18next.t("cancel")}
              customStyle={{ width: "80px" }}
              colorVariant={"secondary"}
            />

            <EAButton
              onClick={(e) => {
                e.preventDefault();
                onValidate();
              }}
              content={i18next.t("validate")}
              colorVariant={"primary"}
              customStyle={{ width: "117px" }}
            />
          </div>

        </>
      )}
    </EADialog>
  );
};

export default DocumentManagementAddDialog;
