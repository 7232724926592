// Structures des colonnes pour le datagrid
import moment from "moment";
import i18next from "i18next";
import DataTableActions from "../../components/common/datatable/DataTableActions";
import React from "react";
import {OneLineRenderCell} from "./Events";

export const getEventsDataTablesColumns = (actionEvent) => {
  return [
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.7,
      renderCell: (params) => <OneLineRenderCell value={moment(params.value).format(i18next.t("date.fullFormatWithSecond"))} isDate/>
    },
    {
      field: 'eventName',
      headerName: 'Évènement',
      flex: 1,
      renderCell: (params) => <OneLineRenderCell value={params.row.eventName} />
    },
    {
      field: 'eventType',
      headerName: 'EventName',
      flex: 1,
      renderCell: (params) => <OneLineRenderCell value={params.row.eventType} />
    },
    {
      field: 'table',
      headerName: 'Table',
      flex: 0.6,
      renderCell: (params) => <OneLineRenderCell value={params.row.table} />
    },
    {
      field: 'eventSource',
      headerName: 'Source',
      flex: 0.8,
      renderCell: (params) => <OneLineRenderCell value={params.row.eventSource} />
    },
    {
      field: 'Id utilisateur',
      headerName: 'userId',
      flex: 0.8,
      renderCell: (params) => <OneLineRenderCell value={params.row.userId} />
    },
    {
      field: 'Utilisateur',
      headerName: 'userMail',
      flex: 0.8,
      renderCell: (params) => <OneLineRenderCell value={params.row.userMail} />
    },
    {
      field: 'Rôles',
      headerName: 'roles',
      flex: 0.8,
      renderCell: (params) => <OneLineRenderCell value={params.row.roles} />
    },
    {
      field: 'eventId',
      headerName: 'ID event',
      flex: 0.8,
      renderCell: (params) => <OneLineRenderCell value={params.row.eventId} />
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.08,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <DataTableActions
            actions={[{
              action: () => actionEvent(params),
              icon: "modify-action",
              isDisabled: false,
              label: "Voir"
            }]}
          />
        );
      },
    },
  ]
}
