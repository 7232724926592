import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Context } from "states/Store";
import { getUserRoles } from "services/edge/UserService";
import { ERROR } from "services/common/Constants";
import { getAccountRoles } from "../../../services/edge/RoleService";
import { RoleBasedAccessControlContext } from "services/common/RolesUtils";

export function useRoles() {
  const [state, dispatch] = useContext(Context);
  const { isGlobalAdmin } = useContext(RoleBasedAccessControlContext);

  const history = useHistory();
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    async function getAllUserRoles() {
      try {
        let results = null;

        if (isGlobalAdmin) {
          results = await getAccountRoles(state.account.id, dispatch);
        } else {
          results = await getUserRoles(state.account.id, state.user.id, dispatch);
        }

        if (results?.content) {
          const fetchedRoles = results.content;

          if (!isGlobalAdmin && (fetchedRoles === null || fetchedRoles.length <= 0)) {


            if(state?.user?.idp){
              history.push("/unauthorized?ssoMode=true");
            }else{
              history.push("/unauthorized");
            }
          }

          setUserRoles(fetchedRoles ? fetchedRoles : []);
          dispatch({ type: "USER_ROLES", roles: fetchedRoles ? fetchedRoles : [] });
        }
      } catch (e) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }

    if (state.user && state.account) {
      getAllUserRoles();
    }
  }, [state.account, state.user, isGlobalAdmin, history, dispatch]);

  return userRoles;
}
