import React, { useContext, useEffect, useState } from "react";
import i18next from "i18next";

import { Context } from "../../../../../states/Store";
import { ERROR } from "../../../../../services/common/Constants";
import EADialog from "../../../../../components/common/EADialog";
import EAIcon from "../../../../../components/common/EAIcon";
import useStyles from "./DocumentManagementDialogStyle";
import { useRequestLoading } from "../../../../../components/common/hooks/useRequestLoading";
import DocumentManagementRecapBloc from "./DocumentManagementRecapBloc";
import { removeAuthorizedDocType } from "services/edge/DocumentManagementService";
import { Trans } from "react-i18next";
import clsx from "clsx";
import { fetchDossierModelList } from "../DocumentManagementUtils";
import { isEmpty } from 'lodash';

const DocumentManagementDeleteDialog = ({
  isOpen,
  docList,
  onClose,
  onValidateSuccess,
}) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [concernedDossierModels, setConcernedDossierModels] = useState([]);
  const [state, dispatch] = useContext(Context);

  const handleDelete = async () => {
    try {
      startLoading();
      const label = docList[0]?.name;
      const message = docList?.length > 1
        ? i18next.t(`demandes.documentManagement.notifications.deletedMulti`)
        : <Trans i18nKey="demandes.documentManagement.notifications.deleted">Le document "<b>{{label}}</b>" a bien été supprimé.</Trans>
      await removeAuthorizedDocType(state.account.id, docList.map(d => d.id), null);

      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: message }
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DELETE_ERROR" } });
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    const getConcernedDossierModels = async () => {
    if (state.account && docList) {
      let dossierModelList = await fetchDossierModelList(state.account);
      let concernedModels= [];
      docList.forEach(doc => {
        dossierModelList?.forEach(m => {
          if (m.askedDocs.map(doc => doc.code).includes(doc.code) && !concernedModels.includes(m)) {
            concernedModels.push(m);
          }
        })
      })
      setConcernedDossierModels(concernedModels)
    }
  }
  getConcernedDossierModels();
  }, [state.account, docList]);

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={handleDelete}
      hasActions={true}
      maxWidth={!isEmpty(concernedDossierModels) ? "popupsm" : "popupxs"}
      fullWidth
      variant="redValid"
      btnSize="large"
      isValidateDisabled={isRequestLoading}
    >
      {docList && (
        <>
          <div className={classes.root}>
            <div className={clsx(classes.imgWrapper, classes.imgWrapperRed)}>
              <EAIcon icon={"delete_red"} className={classes.imgContent} />
            </div>
            {docList?.length === 1 ? (
              <span className={classes.bolder}>
                {i18next.t("demandes.documentManagement.deleteDialog.title")}
              </span>
            ) : (
              <span className={classes.bolder}>
                {i18next.t(
                  "demandes.documentManagement.deleteDialog.titleMulti"
                )}
              </span>
            )}
          </div>

          <div>
            <DocumentManagementRecapBloc
              docToRemoveList={docList}
              dossierModelList={concernedDossierModels}
            />
          </div>
        </>
      )}
    </EADialog>
  );
}

export default DocumentManagementDeleteDialog;
