import { Grid, Paper } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "states/Store";
import useStyles from "../dossierModel/DossierModelTabStyle";
import { getAllAuthorizedTypeDoc } from "services/edge/DocumentManagementService";
import DocumentManagementDataTable from "./DocumentManagementDataTable";

function DocumentManagementTab() {
  const { classes } = useStyles();
  const [state, ] = useContext(Context);
  const [authorizedDocumentsList, setAuthorizedDocumentsList] = useState([]);

  const fetchAuthorizedDocumentsList = useCallback(async () => {
    try {
      const results = await getAllAuthorizedTypeDoc(state.account.id, null);
      // Order results by Creation Date
      let resultsOrdered = results.content.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      );
      setAuthorizedDocumentsList(resultsOrdered);
    } catch (error) {
      setAuthorizedDocumentsList([]);
    }
  }, [state.account]);

  useEffect(() => {
    if (state.account) {
      fetchAuthorizedDocumentsList();
    }
  }, [state.account, fetchAuthorizedDocumentsList]);

  return (
      <Paper component={Grid} container item xs={12} elevation={0} className={classes.root}>
        <Grid item xs={12}>
          {authorizedDocumentsList &&
            <DocumentManagementDataTable
              authorizedDocumentsList={authorizedDocumentsList}
              fetchAuthorizedDocumentsList={fetchAuthorizedDocumentsList}
            />
          }
        </Grid>
      </Paper>
  )
}

export default DocumentManagementTab;
