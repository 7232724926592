import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { find } from 'lodash';

import EAIcon from "../common/EAIcon";
import useStyles from "../../style/js-style/containers/administration/rules/RuleFormsStyle";


/**
 * Chip or placeholder on select component
 *
 * @param selected "" return placeholder | other string return chip
 * @param options all possibe options used for the wrapping select
 * @returns {JSX.Element} chip element or placeholder
 */
export const EASelectChip = ({ selected, options, placeholder = "", onDelete = () => { }, dt = false }) => {
  const { classes } = useStyles();

  const getEntryLabel = (value) => {
    let entry = find(options, { value: value });

    return (entry === undefined) ? value : entry.label;
  }

  // placeholder for Mui Select input
  if (selected === "") {
    return (
      <span className={classes.placeHolder}>
        {placeholder}
      </span>
    )
  }

  return (
    <Tooltip title={getEntryLabel(selected)} >
      <Chip
        key={selected}
        className={dt ? 'dt' : ""}
        label={getEntryLabel(selected)}
        onDelete={(event) => {
          event.preventDefault();
          onDelete();
        }}
        sx={{
          borderRadius: 0,
          backgroundColor: "#F2F3F7",
          color: "#535362",
          padding: 0,
          fontSize: "12px",
          fontWeight: 500,
          height: "auto",
          "& .MuiChip-label": {
            maxWidth: "350px",
            paddingLeft: "4px",
            paddingRight: "12px",
          },
        }}
        deleteIcon={
          <EAIcon
            icon="cross"
            className={classes.chipCrossIcon}
          />
        }
      />
    </Tooltip>
  )
}
