import i18next from "i18next";
import ROUTES from "../../config/Routes";
import { EADataGridCell } from "../common/datatable/EADataGrid";
import { formatDate } from "../../services/common/Utils";
import DataTableActions from "../common/datatable/DataTableActions";
import MainHeaderCell from "../common/datatable/MainHeaderCell";
import React from "react";
import { getAttributeValueDisplay, getAttributeValueOptions, shouldTranslateAttribute } from "../../services/common/AttributeUtils";
import { PrivilegeEnum } from "services/common/RolesUtils";
import { statusEngCode } from 'services/common/Constants';
import DossierStatusChip from "components/common/DossierStatusChip";

const getDossierUrl = (accountId, dossierId, monitored, component, thirdpartyId) => {
  let route = monitored ? ROUTES : ROUTES.ARCHIVES;
  if(component === "thirdparty") {
    route = route.THIRDPARTY_DOSSIER_DETAIL.replace(":thirdpartyId", thirdpartyId).replace(":dossierId", dossierId)
  } else {
    route = route.DOSSIER_DETAIL.replace(":dossierId", dossierId);
  }
  return `/${accountId}/${route}`;
}

export const initialDossierTableSort = (component) => [
  {
    field:
      component === "dossiers-list" ? "thirdpartyName" : "dossierReference",
    sort: "asc",
  },
];

export const dossiersDefaultVisibilityColumns = (
  attributesColumns,
  monitored
) => {
  return {
    //dossiers list
    thirdpartyCode: false,
    thirdpartyIdentifier: false,

    //Common
    creationDate: false,
    endDate: !monitored,
    statusCode: monitored,

    ...attributesColumns?.reduce(
      (res, attr) => ({
        ...res,
        [`attribute-${attr.label}`]: false,
      }),
      {}
    ),
  };
};

const dossiersDataTableActions = (
  accountId,
  dossier,
  history,
  monitored,
  onStopDossier,
  onReactivateDossier,
  hasAnyOfPrivilegesOnScopedResourceImpl,
  component,
  thirdpartyId,
  dossierTypeLibelles
) => {
  const actions = [
    {
      label: i18next.t("show"),
      icon: "modify-action",
      isDisabled: false,
      action: () => history.push(getDossierUrl(accountId, dossier.id, monitored, component, thirdpartyId)),
    },
  ];
  if (monitored) {
    actions.push({
      label: i18next.t("stop"),
      icon: "stop-action",
      isDisabled: !hasAnyOfPrivilegesOnScopedResourceImpl(dossier, PrivilegeEnum.PRIVILEGE_ARCHIVE_DOSSIER),
      action: () => onStopDossier(dossier.id),
    });
  }
  if (!monitored) {
    actions.push(    {
      label: i18next.t("reactivate"),
      icon: "play_circle_filled_green",
      isDisabled: ![...dossierTypeLibelles, 'Libre'].includes(dossier.dossierTypeLibelle) || !dossier.thirdpartyActive,
      action: () => onReactivateDossier(dossier.id, dossier),
    },);
  }
  return actions;
};

export const getDossiersDataTableColumns = (
  component,
  accountId,
  monitored,
  dossiersTypesLabels,
  attributesColumns,
  onStopDossier,
  onReactivateDossier,
  history,
  hasAnyOfPrivilegesOnScopedResourceImpl,
  dossierTypeLibelles
) => {
  const isDossiersList = component === "dossiers-list";
  return [
    {
      field: "dossierTypeLibelle",
      headerName: i18next.t("dossierTable.columns.dossierType"),
      filterable: !isDossiersList,
      flex: isDossiersList ? 0.1 : 0.17,
      type: "singleSelect",
      valueOptions: dossiersTypesLabels,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t('dossierTable.columns.dossierType')}/>
      ),
      renderCell: (params) => (
        <EADataGridCell link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}>
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "dossierReference",
      headerName: i18next.t("dossierTable.columns.dossierReference"),
      filterable: !isDossiersList,
      flex: isDossiersList ? 0.08 : 0.17,
      renderCell: (params) => (
        <EADataGridCell disableTranslation link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}>
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "creationDate",
      headerName: i18next.t("addedOn"),
      filterable: !isDossiersList,
      flex: isDossiersList ? 0.07 : 0.17,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell
          link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}
        >
          {formatDate(params.value)}
        </EADataGridCell>
      )
    },
    {
      field: "endDate",
      headerName: i18next.t(monitored ? "expirationDate" : "stoppedOn"),
      filterable: !isDossiersList,
      flex: isDossiersList ? 0.07 : 0.17,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
          link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}
        >
          {formatDate(params.value)}
        </EADataGridCell>
      )
    },
    ...(isDossiersList
      ? [
          {
            field: "thirdpartyName",
            headerName: i18next.t("thirdparty"),
            filterable: !isDossiersList,
            flex: 0.17,
            renderCell: (params) => (
              <EADataGridCell disableTranslation link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}>
                {params.value}
              </EADataGridCell>
            ),
          },
          {
            field: "thirdpartyIdentifier",
            headerName: i18next.t("dossierTable.columns.thirdpartyIdentifier"),
            filterable: !isDossiersList,
            flex: 0.09,
            renderCell: (params) => (
              <EADataGridCell disableTranslation link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}>
                {params.value}
              </EADataGridCell>
            ),
          },
          {
            field: "thirdpartyCode",
            headerName: i18next.t("dossierTable.columns.thirdpartyCode"),
            filterable: !isDossiersList,
            flex: 0.08,
            renderCell: (params) => (
              <EADataGridCell disableTranslation link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}>
                {params.value}
              </EADataGridCell>
            ),
          },
        ]
      : []),
    ...(monitored
      ? [
          {
            field: "statusCode",
            headerName: i18next.t("myDocuments.status"),
            filterable: !isDossiersList,
            flex: 0.1,
            headerAlign: "center",
            align: "center",
            type: "singleSelect",
            valueOptions: [
              { value: "IN_PROGRESS", label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.IN_PROGRESS}`) },
              { value: "ACTION_REQUIRED", label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.ACTION_REQUIRED}`) },
              { value: "NOT_VALID", label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.NOT_VALID}`) },
              { value: "VALID", label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.VALID}`) },
              { value: "MISSING", label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.MISSING}`) }
            ],
            renderCell: (params) => (
              <EADataGridCell
                disableTranslation
                link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}
                tooltipLabel={i18next.t(`viewer.status.statusLabelDossier.${params.row.statusCode}`)}
              >
                <DossierStatusChip statusCode={params.value} />
              </EADataGridCell>
            ),
          }
        ]
      : []),
    ...attributesColumns?.map((attrCol) => {
      return {
        field: `attribute-${attrCol.label}`,
        headerName: attrCol.label,
        filterable: !isDossiersList,
        renderHeader: () => <span className="dt">{attrCol.label}</span>,
        flex: 0.08,
        type: attrCol.gridColumnType,
        headerAlign: "center",
        ...(attrCol.gridColumnType === "singleSelect" && { valueOptions: getAttributeValueOptions(attrCol) }),
        renderCell: (params) => (
          <EADataGridCell
            link={getDossierUrl(accountId, params.row.id, monitored, component, params.row.thirdpartyId)}
            disableTranslation={!shouldTranslateAttribute(params.value)}
          >
            <span>
              {getAttributeValueDisplay(attrCol.type, params.value)}
            </span>
          </EADataGridCell>
        ),
      };
    }),
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="dt" style={{ textAlign: "center" }}>
            <DataTableActions
              actions={dossiersDataTableActions(
                accountId,
                params.row,
                history,
                monitored,
                onStopDossier,
                onReactivateDossier,
                hasAnyOfPrivilegesOnScopedResourceImpl,
                component,
                params.row.thirdpartyId,
                dossierTypeLibelles
              )}
            />
          </div>
        );
      },
    },
  ];
};
