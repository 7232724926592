import React, { useEffect, useState } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import i18next from "i18next";
import { get as lodashGet } from "lodash";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import EventIcon from "@mui/icons-material/Event";

const EADateRangePickerTextfield = ({
  formMethods,
  sxClass,
  isDisabled,
  name,
  value,
  ...otherProps
}) => (
  <TextField
    {...otherProps}
    error={!!lodashGet(formMethods.formState.errors, name)}
    sx={sxClass}
    disabled={isDisabled}
    fullWidth
    inputProps={{
      ...otherProps.inputProps,
      placeholder: i18next.t("date.placeholder"),
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <EventIcon />
        </InputAdornment>
      ),
    }}
    helperText={
      !!lodashGet(formMethods.formState.errors, name)
        ? i18next.t(
            `date.${lodashGet(formMethods.formState.errors, name).message}`
          )
        : ""
    }
  />
);

function EADateRangePicker({
  formMethods,
  startName,
  endName,
  isDisabled,
  sxClass,
  onError = () => {},
  ...otherProps
}) {
  const theme = useTheme();
  const [value, setValue] = useState([
    formMethods.getValues(startName),
    formMethods.getValues(endName),
  ]);

  useEffect(() => {
    formMethods.setValue(startName, value[0])
    formMethods.setValue(endName, value[1])
  }, [value, formMethods, startName, endName]);

  const onDatePickerError = (errors) => {
    onError(errors);

    const startError = errors[0];
    if (!startError) {
      formMethods.clearErrors(startName);
    } else {
      formMethods.setError(startName, {
        type: "datepicker",
        message: startError,
      });
    }

    const endError = errors[1];
    if (!endError) {
      formMethods.clearErrors(endName);
    } else {
      formMethods.setError(endName, {
        type: "datepicker",
        message: endError,
      });
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      dateFormats={{ keyboardDate: i18next.t("date.dateFormat") }}
      localeText={{
        start: "",
        end: "",
      }}
    >
      <DateRangePicker
        {...otherProps}
        sx={{ m: 0 }}
        toolbarTitle={i18next.t("date.toolbarTitle")}
        value={value}
        onChange={setValue} 
        onError={onDatePickerError}
        renderInput={(startProps, endProps) => (
          <>
            <EADateRangePickerTextfield
              formMethods={formMethods}
              name={startName}
              sxClass={sxClass}
              isDisabled={isDisabled}
              value={value[0]}
              {...startProps}
              ref={null}
            />
            <Box
              sx={{
                mx: 2,
                fontWeight: 700,
                color: theme.palette.grey.grey500,
                fontSize: "14px",
                alignSelf: "center"
              }}
            >
              {i18next.t("and")}
            </Box>

            <EADateRangePickerTextfield
              formMethods={formMethods}
              name={endName}
              sxClass={sxClass}
              isDisabled={isDisabled}
              value={value[1]}
              {...endProps}
              ref={null}
            />
          </>
        )}
      />
    </LocalizationProvider>
  );
}

export default EADateRangePicker;
