import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  // Edit confirm Pop-up
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  imgWrapper: {
    backgroundColor: theme.palette.warning.warning100,
    padding: "1rem",
    borderRadius: "50%",
    width: "55px",
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  imgContent: {
    width: "33px",
    height: "30px"
  },
  bolder: {
    fontWeight: 700,
    fontFamily: "Metric",
    fontSize: "20px",
    color: theme.palette.grey.grey700,
    maxWidth: "370px",
    textAlign: "center",
  },
  ruleInfoBlocWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: theme.palette.grey.grey700,

  },
  ruleInfoBlocContent : {
    backgroundColor: "#F2F3F7",
    padding: "13px 1.5rem",
    textAlign: "center",
    minWidth: "370px",
  },
}));

export default useStyles;
