import { useContext } from "react";
import Keycloak from "keycloak-js";
import { getEnv } from "config/Config";
import { Context } from "states/Store";
import { ERROR } from "services/common/Constants";
import { manageRolesFromIdp } from "services/edge/UserService";
import {
  useHistory,
} from "react-router-dom";

let initOptions = {
  url: getEnv("REACT_APP_KEYCLOAK_URL"),
  realm: getEnv("REACT_APP_KEYCLOAK_REALM"),
  clientId: getEnv("REACT_APP_KEYCLOAK_CLIENT"),
  onLoad: 'login-required',
  KeycloakResponseType: 'code'
}

export default function useKeycloak() {

  const [state, dispatch] = useContext(Context);
  const history = useHistory();

  function loginKeycloak(){

    const queryParameters = new URLSearchParams(window.location.search)
    const client = queryParameters.get("accountName")

    const keycloak = new Keycloak(initOptions);

    let kcLogin = keycloak.login;
    keycloak.login = (options) => {

      if(client){
        options.idpHint = client;
      }

      kcLogin(options);
    };

    keycloak.init({ onLoad: initOptions.onLoad }).then(async (auth) => {

      if (!auth) {
          window.location.reload();
      } else {
          if (!state.token) {
            localStorage.setItem("365token", keycloak.token);
            dispatch({ type: "KEYCLOAK", keycloak: keycloak });
            dispatch({ type: "TOKEN", token: keycloak.token });
            dispatch({ type: "USER_INFOS", user: keycloak.idTokenParsed });
          }

          if(!client){
            dispatch({ type: "AUTH_DONE", isAuthDone: true });
          }

          if(keycloak?.idTokenParsed?.idp){
            try {
              const response = await manageRolesFromIdp(dispatch)

              if(response){
                window.location.reload();
              }else{
                dispatch({ type: "AUTH_DONE", isAuthDone: true });
              }

              if (response?.content) {
                const errMsgCode = response.messages?.error && response.messages?.error[0]?.code;
                if (errMsgCode === '1000') {
                  history.push("/unauthorized");
                }
              }
            } catch (e) {
              dispatch({
                type: "ALERT",
                alert: { type: ERROR, msg: "INTERNAL_ERROR" },
              });
            }
          }else{
            dispatch({ type: "AUTH_DONE", isAuthDone: true });
          }
          if (keycloak?.idTokenParsed?.idp) {
            dispatch({ type: "PROVISIONING_ACCOUNT", provisioning: true });
          }

      }
    }).catch(() => {});
  }

  function logoutKeycloak(){
    sessionStorage.clear();
    localStorage.removeItem("365token");
    dispatch({ type: "KEYCLOAK", keycloak: null });
    dispatch({ type: "TOKEN", token: null });
    dispatch({ type: "USER_INFOS", user: null });
    if(state?.user?.idp){
      window.location.href = `${initOptions.url}/realms/${initOptions.realm}/protocol/openid-connect/logout?initiating_idp=${state.user.idp}&redirect_uri=https%3A%2F%2Fwww.e-attestations.com`
    }else{
      state.keycloak.logout()
    }
  }

  return {
    logoutKeycloak,
    loginKeycloak
  }
};
