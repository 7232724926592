import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  //tab
  tabsWrapper: {
    marginLeft: "16px",
    "& .MuiTabs-root": {
      height: "65px",
      "& .MuiTabs-scroller": {
        "& .MuiTabs-indicator": {
          backgroundColor: "#2D9F40",
        },
      },
    },
  },
  tab: {
    textTransform: "none",
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    marginRight: "16px",
    padding: "0 !important",

    "& .MuiTab-iconWrapper": {
      marginRight: "3px",
      position: "relative",
      top: "-1px"
    },
  },
}));

export default useStyles;
