import React, {  useContext, useEffect, useState } from "react";
import i18next from "i18next";
import EADialog from "components/common/EADialog";
import DossierModelDialogDocStep from "./DossierModelDialogDocStep";
import DossierModelDialogConfigStep from "./DossierModelDialogConfigStep";
import { createDossierModel } from "services/edge/DossierModelService";
import { ERROR, SUCCESS } from "services/common/Constants";
import { Context } from "states/Store";
import { FormProvider, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import DocumentManagementRecapBloc from "../../documentManagement/dialogs/DocumentManagementRecapBloc";
import EAButton from "components/common/EAButton";
import useStyles from "./DossierModelDialogStyle";

const DossierModelCreateDialog = ({
  isOpen,
  onClose,
  onValidateSuccess
}) => {
  const [state, dispatch] = useContext(Context);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [currentStep, setCurrentStep] = useState("INITIAL"); // INITIAL, DOCUMENTS
  const [data, setData] = useState(null);
  const [addedDocs, setAddedDocs] = useState([]);
  const { classes } = useStyles();

  const cancelDialogHandler = () => {
    if (isFormValidated) {
      setIsFormValidated(false);
    } else {
      onClose();
    }
  };

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: {
      code: "",
      libelle: "",
      lock: "",
      active: false
    }
  });

  const handleSubmit = async (dataToSave) => {
    try {
      await createDossierModel(
        state.account.id,
        dataToSave,
        null
      );
      const label = dataToSave.libelle;
      if (dataToSave.active === true) {
        const message = <Trans i18nKey="demandes.dossierModel.notifications.activateSuccess">Le modèle "<span className='dt' style={{ fontWeight: "bold" }}>{{ label }}</span>" a bien été activé.</Trans>;
        dispatch({
          type: "ALERT",
          alert: { type: SUCCESS, msg: message },
        });
      } else {
        const message = <Trans i18nKey="demandes.dossierModel.notifications.saveSuccess">Le modèle "<span className='dt' style={{ fontWeight: "bold" }}>{{ label }}</span>" a bien été enregistré.</Trans>;
        dispatch({
          type: "ALERT",
          alert: { type: SUCCESS, msg: message },
        });
      }
      onValidateSuccess();
    } catch (error) {
      const err = error?.message?.messages?.error?.[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  };

  useEffect(() => {
    setAddedDocs(data?.docToAdd?.map(d => {
      return {
        index: d.id,
        code: d.code,
        name: d.descriptionFr
      }
    }));
  }, [data]);

  return (
    <EADialog
      isOpen={isOpen}
      title={currentStep === "INITIAL" ? i18next.t("demandes.dossierModel.createDialog.title") : i18next.t("demandes.dossierModel.editDialog.titleBis")}
      onCancel={cancelDialogHandler}
      onClose={onClose}
      maxWidth={currentStep === "INITIAL" ? "popupmd" : "popupxl"}
      variant="default"
      fullWidth
      hasActions={false}
    >
      <FormProvider {...formMethods}>
      <form>
        {/* Update model information */}
        {["INITIAL"].includes(currentStep) && (
          <DossierModelDialogConfigStep
            isCreationMode={true}
            onContinue={() => { setCurrentStep("DOCUMENTS"); }}
            onCancel={() => { cancelDialogHandler(); }}
          />
        )}

        {/* Documents selection */}
        {["DOCUMENTS"].includes(currentStep) && (
          <DossierModelDialogDocStep
            isCreationMode={true}
            onCancel={() => {
              setCurrentStep("INITIAL");
              formMethods.clearErrors();
            }}
            setData={setData}
            onContinue={() => { setCurrentStep("CONFIRM"); }}
          />
        )}

        {/* Confirm */}
          {["CONFIRM"].includes(currentStep) && (
            <>
              <DocumentManagementRecapBloc
                docToAddList={addedDocs}
              />
              {/* --- Actions --- */}
              {/* Annuler / Valider */}
              <div className={classes.declarationControlsRight}>
                <EAButton
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentStep("DOCUMENTS");
                  }}
                  content={i18next.t("cancel")}
                  customStyle={{ width: "80px" }}
                  colorVariant={"secondary"}
                />

                <EAButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(data);
                  }}
                  content={i18next.t("validate")}
                  colorVariant={"primary"}
                  customStyle={{ width: "117px" }}
                />
              </div>

            </>
          )}
      </form>
      </FormProvider>
    </EADialog>
  );
};

export default DossierModelCreateDialog;
