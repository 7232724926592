import React, { useState, useContext, useEffect, useCallback } from "react";
import i18next from "i18next";
import Tooltip from '@mui/material/Tooltip';
import _ from "lodash";

import { Context } from "states/Store";
import useStyles from "style/js-style/containers/administration/roles/RolesStyle";
import EAIcon from "components/common/EAIcon";
import { Collapse } from "@mui/material";
import EATooltip from "components/common/EATooltip";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import clsx from "clsx";

const RoleHierarchyContext = React.createContext({
  onRoleEdit: () => { },
  onRoleDelete: () => { },
  onRoleSelected: () => { },
  selectedRole: undefined,
});

const RoleMenu = ({ roles, onRoleEdit, onRoleDelete, onRoleSelected, selectedRole, lastRoleID }) => {
  const [orderedRoles, setOrderedRoles] = useState([]);

  const context = React.useMemo(
    () => ({
      onRoleEdit: onRoleEdit,
      onRoleDelete: onRoleDelete,
      onRoleSelected: onRoleSelected,
      selectedRole: selectedRole,
      lastRoleID: lastRoleID,
    }),
    [onRoleEdit, onRoleDelete, onRoleSelected, selectedRole, lastRoleID]
  );

  // Tri des roles par ordre alphabétique par récursivité sur les derivatedRoles
  useEffect(() => {
    const sortRoles = (roles) => {
      const newOrderedRoles = _.sortBy([...roles], role => role.name.toUpperCase());

      _.forEach(newOrderedRoles, role => {
        role.derivedRoles && (role.derivedRoles = sortRoles(role.derivedRoles));
      });

      return newOrderedRoles;
    };

    setOrderedRoles(sortRoles(roles));
  }, [roles]);

  return _.isEmpty(orderedRoles) ? (
    <></>
  ) : (
    <RoleHierarchyContext.Provider value={context}>
      {orderedRoles && orderedRoles.map((role, index) => {
        return <RoleItem
          key={role.id}
          role={role}
          isRoot={true}
          isLast={index === _.size(roles) - 1}
        />
      })}
    </RoleHierarchyContext.Provider>
  )
}

const RoleItem = ({ role, isRoot, isLast, paddingLevel }) => {
  return (
    _.isEmpty(role.derivedRoles) ?
      <RoleSimpleItem role={role} isRoot={isRoot} isLast={isLast} paddingLevel={paddingLevel} />
      :
      <RoleSubMenu role={role} isRoot={isRoot} isLast={isLast} paddingLevel={paddingLevel} />
  )
}

const RoleSimpleItem = ({ role, isRoot, isLast, paddingLevel }) => {
  return (
    <RoleLabel role={role} isRoot={isRoot} isLast={isLast} isLeaf={true} paddingLevel={paddingLevel} />
  )
}

const RoleSubMenu = ({ role, isRoot, isLast, paddingLevel = 0 }) => {
  const [open, setOpen] = useState(true);

  const handleArrowClick = () => {
    setOpen((prev) => !prev);
  }
  return (
    <div>
      <RoleLabel role={role} isRoot={isRoot} isLast={isLast} isLeaf={false} isOpen={open} handleArrowClick={handleArrowClick} paddingLevel={paddingLevel} />
      <Collapse
        in={open}
        timeout="auto"
      >
        {role.derivedRoles.map((derivedRole, index) => {
          return <RoleItem
            paddingLevel={paddingLevel + 1}
            key={derivedRole.id}
            role={derivedRole}
            isRoot={false}
            isLast={index === _.size(role.derivedRoles) - 1}
          />
        })}
      </Collapse>
    </div>
  )
}

const RoleLabel = ({ role, isRoot, isLast, isLeaf, isOpen = false, paddingLevel, handleArrowClick = () => { } }) => {
  const { classes } = useStyles();
  const { onRoleEdit, onRoleDelete, onRoleSelected, selectedRole, lastRoleID } = React.useContext(RoleHierarchyContext)
  const { isGlobalAdmin, hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const [state] = useContext(Context);
  const [isSelectedRoleEditable, setIsSelectedRoleEditable] = useState(false);
  const [isSelectedRoleDeletable, setIsSelectedRoleDeletable] = useState(false);

  const isOwnedRole = useCallback((role) => {
      let isOwnedRole = false;

      if (!_.isEmpty(state.roles) && role) {
        isOwnedRole = _.some(state.roles, (item) => item.id === role?.id);
      }

      return isOwnedRole;
  }, [state.roles]);

  useEffect(() => {
    if (isGlobalAdmin) {
      setIsSelectedRoleEditable(true);
      setIsSelectedRoleDeletable(true);
    } else {
      setIsSelectedRoleEditable(!isOwnedRole(role) && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_ROLE));
      setIsSelectedRoleDeletable(!isOwnedRole(role) &&  hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_ROLE));
    }
  }, [isGlobalAdmin, state.roles, role, isOwnedRole, hasAnyOfPrivileges])

  return (
    <div
      className={
        selectedRole?.id === role?.id
          ? classes.rolesPanelContentDefault
          : classes.rolesPanelContentChild
      }
      style={{
        paddingLeft: !isRoot ? paddingLevel * 16 + "px" : "16px"
      }}
      onClick={() => {
        onRoleSelected(role);
      }}
    >
      {!isRoot && role.id !== lastRoleID &&
        <EAIcon icon="roles/hierarchy-line" className={classes.lineIcon} />
      }
      {!isRoot && (
        <div className={classes.rolesPanelChildHierarchy}>
          {isLast ?
            <EAIcon icon="roles/hierarchy-curve" className={classes.curveIcon} />
            :
            <EAIcon icon="roles/hierarchy-double" />
          }
        </div>
      )}
      <div className={classes.rolesPanelChildRoleName}>
        {!isLeaf &&
          <>
            {isOpen ?
              <EAIcon icon="arrow/arrow-to-down_small" className={classes.arrowMenuIcon} onClick={handleArrowClick} /> :
              <EAIcon icon="arrow/arrow-to-right_small" className={classes.arrowMenuIcon} onClick={handleArrowClick} />
            }
          </>
        }
        <EATooltip title={role.name}>
          <span
            className={clsx('dt', selectedRole?.id === role?.id ? classes.roleNameSelected : classes.roleName)}
            style={{ width: "calc(230px - " + paddingLevel * 16 + "px)" }}
          >
            {role.name}
          </span>
        </EATooltip>
      </div>
      <div className={classes.rolesPanelChildPanel}>
        <Tooltip title={i18next.t("roles.tooltip.editRole")} >
          <div
            className={role.baseRoleId ? classes.rolesPanelContentDefaultEdit : classes.rolesPanelContentDefaultEditRoot}
            style={{
              opacity: isSelectedRoleEditable ? 1 : 0.5,
              cursor: isSelectedRoleEditable ? "pointer" : "auto",
            }}
            onClick={() => {
              if (isSelectedRoleEditable) {
                onRoleEdit(role);
              }
            }}
          >
            <EAIcon icon={"edit_grey_full"} />
          </div>
        </Tooltip>
        {role.baseRoleId &&
          <Tooltip title={i18next.t("roles.tooltip.deleteRole")} arrow>
            <div
              className={classes.rolesPanelContentDefaultEdit}
              style={{
                opacity: isSelectedRoleDeletable ? 1 : 0.5,
                cursor: isSelectedRoleDeletable ? "pointer" : "auto",
              }}
              onClick={() => {
                if (isSelectedRoleDeletable) {
                  onRoleDelete(role);
                }
              }}
            >
              <EAIcon icon={"delete_grey2"} className={classes.deleteIcon} />
            </div>
          </Tooltip>
        }
      </div>
    </div>
  )
}

export default RoleMenu;
