import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material";
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import AddIcon from '@mui/icons-material/Add';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import i18next from "i18next";
import { Controller, useForm } from "react-hook-form";
import { isEmpty } from "services/common/Utils";
import useStyles from "./OptionsImportDialogStyle";
import EADialog from "components/common/EADialog";
import clsx from "clsx";
import { useState } from "react";
import CancelImportThirdParties from "../CancelImportThirdParties/CancelImportThirdParties";


const OptionsImportDialog = ({isOpen, onClose, validationHandler}) => {

    const { classes } = useStyles();

    const defaultValues =  {
        keyUpdate: "duns",
        updateOn: false,
        overrideEmptyColumn: false,
        addOn :false,
        archiveOn: false
    };

    const { control,  handleSubmit, formState: { errors }, watch, reset } = useForm({
        defaultValues: defaultValues,
      })

    const submitForm = () => {
       handleSubmit((data) => {
            if(isEmpty(errors)) {
                validationHandler(data);
            }
        })();
        reset(defaultValues);

    }

    //---------- Confirm close import--------------
    const [isOpenCloseConfirm, setIsOpenCloseConfirm] = useState(false)

    return  <EADialog
                isOpen={isOpen}
                title={i18next.t("imports.importDialogName.optionsImportStep")}
                onCancel={(event, reason) => {
                    if(reason && reason === "backdropClick") return;
                    setIsOpenCloseConfirm(true);
                }}
                validateBtnLabel="continue"
                fullWidth={true}
                maxWidth="md"
                isValidateDisabled={!watch("updateOn") && !watch("addOn") && !watch("archiveOn")}
                onValidate={submitForm}>

                    <div className={classes.flex}>
                        <LoopRoundedIcon className={classes.iconAction}/>
                        <Typography variant="h4">{i18next.t("imports.importDialogName.optionTitleUpdate")}</Typography>
                    </div>
                    <Box className={classes.boxContainer}>
                        <div className={classes.flexBetween}>
                            <div>{i18next.t("imports.importDialogName.optionUpdateKey")}</div>
                            <div className={classes.flexBetween}>
                                <FormControl>
                                    <Controller
                                        name="keyUpdate"
                                        control={control}
                                        rules={{
                                            required: i18next.t("infos.required"),
                                        }}
                                        render={({ field }) =>
                                            <>
                                            <InputLabel>{i18next.t("imports.importDialogName.optionKeyLabel")}</InputLabel>
                                                <Select {...field} label={i18next.t("imports.importDialogName.optionKeyLabel")}
                                                    className={classes.inputId}>
                                                    <MenuItem value="duns">{i18next.t("imports.importDialogName.duns")}</MenuItem>
                                                    <MenuItem value="thirdpartyId">{i18next.t("imports.importDialogName.thirdpartyId")}</MenuItem>
                                                    <MenuItem value="idLocal">{i18next.t("imports.importDialogName.id_local")}</MenuItem>
                                                    <MenuItem value="tva">{i18next.t("imports.importDialogName.tva")}</MenuItem>
                                                </Select>
                                            </>
                                        }/>
                                </FormControl>
                                <Controller name="updateOn" control={control}
                                    render={({field: { onChange, value } }) =>
                                    <span className={classes.switchLabel}><FormControlLabel control={<Switch checked={value} onChange={onChange}  />}
                                    label={i18next.t("imports.importDialogName.activateLabel")}/></span>
                                } />
                            </div>
                        </div>
                        <Divider className={classes.divider}/>
                        <Controller
                            control={control}
                            name="overrideEmptyColumn"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                label={i18next.t("imports.importDialogName.updateEmptyColumn")}
                                control={
                                    <Checkbox checked={value} onChange={onChange} />
                                }
                                />
                            )} />
                    </Box>
                    <span className={classes.updateComment}>{i18next.t("imports.importDialogName.optionUpdateKeyComment")}</span>

                    <div className={classes.flex}>
                        <AddIcon className={classes.iconAction}/>
                        <Typography variant="h4">{i18next.t("imports.importDialogName.optionTitleAdd")}</Typography>
                    </div>
                    <Box className={classes.boxContainer}>
                        <div className={classes.flexBetween}>
                            <div>{i18next.t("imports.importDialogName.addColumnDescription")}</div>
                            <Controller name="addOn" control={control}
                                render={({field: { onChange, value } }) =>
                                <span className={classes.switchLabel}>
                                    <FormControlLabel control={<Switch checked={value} onChange={onChange} />} label={i18next.t("imports.importDialogName.activateLabel")} />
                                </span>
                            } />

                        </div>
                    </Box>

                    <div className={classes.flex}>
                        <Inventory2OutlinedIcon className={clsx(classes.iconAction, classes.archive) }/>
                        <Typography variant="h4">{i18next.t("imports.importDialogName.optionTitleRemove")}</Typography>
                    </div>
                    <Box className={classes.boxContainer}>
                        <div className={classes.flexBetween}>
                            <div>{i18next.t("imports.importDialogName.removeColumDescription")}</div>
                            <Controller name="archiveOn" control={control}
                                render={({field: { onChange, value } }) =>
                                <span className={classes.switchLabel}>
                                    <FormControlLabel control={<Switch checked={value} onChange={onChange}  />} label={i18next.t("imports.importDialogName.activateLabel")} />
                                </span>
                            } />

                        </div>
                    </Box>

                    <CancelImportThirdParties isOpen={isOpenCloseConfirm} onClose={() => setIsOpenCloseConfirm(false)} onValidate={onClose}/>
            </EADialog>

}
export default OptionsImportDialog
