import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    downloadBtn: {
        marginLeft: "0.5rem",
    },
    downloadIcon: {
        width: "20px",
    },
}));

export default useStyles;
