import React, { useEffect, useState } from "react";
import { Box, Chip } from "@mui/material";
import EAIcon from "./EAIcon";
import clsx from "clsx";
import useStyles from "../../style/js-style/components/common/DocStatusChipStyle";
import { getStatusFromCode } from "../../services/common/DocumentUtils";

const DocStatusChip = ({ statusCode }) => {
    const { classes } = useStyles();
    const [docStatus, setDocStatus] = useState();

    useEffect(() => {
        setDocStatus(getStatusFromCode(statusCode));
    }, [statusCode])
    return (
        <Box display="flex">
            {docStatus && (
                <Chip
                    icon={<EAIcon icon={docStatus.icon} />}
                    size="small"
                    label={docStatus.label}
                    color="primary"
                    variant="outlined"
                    sx={{
                        padding: "4px 8px",
                        backgroundColor: "#D7F4DC",
                        color: "#2D9F40",
                        fontWeight: 600,
                        fontSize: "10px",
                        borderRadius: "6px",
                        border: "none",
                    }}
                    className={clsx(classes.root, classes[docStatus.class])}
                />
            )}
        </Box>
    )
}
export default DocStatusChip;
