import React from "react";
import DataTableActions from "../../../components/common/datatable/DataTableActions";
import i18next from "i18next";
import { EADataGridCell } from "../../../components/common/datatable/EADataGrid";
import ROUTES from "../../../config/Routes";
import { formatDate, hasOption } from "../../../services/common/Utils";
import InscriptionStatusChip from "../../../components/common/InscriptionStatusChip";
import EAIcon from "../../../components/common/EAIcon";
import MainHeaderCell from "../../../components/common/datatable/MainHeaderCell";
import {
  getAttributeValueDisplay,
  getAttributeValueOptions,
  shouldTranslateAttribute,
} from "../../../services/common/AttributeUtils";
import { PrivilegeEnum } from "services/common/RolesUtils";
import { DATABLOCKS_SUBSCRIPTIONS } from "services/common/DataBlocksUtils";

export const InitialThirdpartiesTableSort = [
  {
    field: "name",
    filterable: false,
    sort: "asc",
  },
];

const getThirdpartySheetUrl = (accountId, thirdpartyId, active) => {
  const route = active
    ? ROUTES.THIRDPARTY_DETAIL
    : ROUTES.ARCHIVES.THIRDPARTY_DETAIL;
  return `/${accountId}/${route.replace(":thirdpartyId", thirdpartyId)}`;
};

const getTransparencyScoreDisplay = (value) => {
  if (value) {
    return <span className="dt">{value}</span>;
  }
  return <span>{i18next.t("transparency.unpublished")}</span>;
};

const thirdpartiesTableActions = (accountId, thirdpartyId, history, active) => {
  return [
    {
      label: i18next.t(active ? "showModify" : "show"),
      icon: "modify-action",
      isDisabled: false,
      action: () => {
        history.push(getThirdpartySheetUrl(accountId, thirdpartyId, active));
      },
    },
  ];
};

export const thirdpartiesDefaultVisibilityColumns = (
  attributesColumns,
  calculatedAttributesColumns,
  indicatorsColumns,
  active
) => {
  return {
    identifier: false,
    thirdpartyCode: false,
    headquarter: false,
    addressPostCode: false,
    addressCity: false,
    addressCountry: false,
    registered: false,
    indued: false,
    financialStrengthScore: false,
    financialStrengthRating: false,
    transparencyScore: false,
    complete: active,
    majorFr: false,
    stoppingDate: true,
    ...attributesColumns?.reduce(
      (res, attr) => ({
        ...res,
        [`attribute-${attr.label}`]: false,
      }),
      {}
    ),
    ...indicatorsColumns?.reduce(
      (res, indic) => ({
        ...res,
        [`indicator-${indic.label}`]: false,
      }),
      {}
    ),
    ...calculatedAttributesColumns?.reduce(
      (res, attr) => ({
        ...res,
        [`calculatedAttribute-${attr.label}`]: false,
      }),
      {}
    ),
  };
};

export const getThirdpartiesTableColumns = (
  attributesColumns,
  calculatedAttributesColumns,
  indicatorsColumns,
  active,
  history,
  accountId,
  showTransparency,
  hasAnyOfPrivileges, 
  accounts
) => {
  return [
    {
      field: "name",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.name"),
      flex: 0.17,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("thirdpartiesTable.columns.name")} />
      ),
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
          disableTranslation
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "identifier",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.identifier"),
      flex: 0.1,
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "thirdpartyCode",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.thirdpartyCode"),
      flex: 0.08,
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "activity",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.activity"),
      flex: 0.1,
      type: "singleSelect",
      hide: !active,
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "majorFr",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.major"),
      flex: 0.1,
      type: "singleSelect",
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "headquarter",
      filterable: false,
      headerName: i18next.t("headquarter"),
      flex: 0.07,
      type: "singleSelect",
      valueOptions: [
        { value: "headquarter", label: i18next.t("headquarter") },
        {
          value: "secondaryEstablishment",
          label: i18next.t("secondaryEstablishment"),
        },
      ],
      valueFormatter: (params) => i18next.t(params.value),
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {i18next.t(params.value)}
        </EADataGridCell>
      ),
    },
    {
      field: "addressPostCode",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.addressPostCode"),
      flex: 0.06,
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "addressCity",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.addressCity"),
      flex: 0.1,
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "addressCountry",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.addressCountry"),
      flex: 0.06,
      type: "singleSelect",
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "followingDate",
      filterable: false,
      headerName: i18next.t("thirdpartiesTable.columns.followingDate"),
      flex: 0.08,
      type: "date",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => formatDate(params.value),
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
          disableTranslation
        >
          {formatDate(params.value)}
        </EADataGridCell>
      ),
    },
    ...(!active
      ? [
          {
            field: "stoppingDate",
            filterable: false,
            headerName: i18next.t("thirdpartiesTable.columns.stoppingDate"),
            flex: 0.08,
            type: "date",
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => formatDate(params.value),
            renderCell: (params) => (
              <EADataGridCell
                link={getThirdpartySheetUrl(accountId, params.row.id, active)}
              >
                {formatDate(params.value)}
              </EADataGridCell>
            ),
          },
        ]
      : []),
    {
      field: "registered",
      filterable: false,
      headerName: i18next.t("dossierTable.columns.contact"),
      flex: 0.08,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: [
        {
          value: "INSCRIT",
          label: i18next.t(`thirdpartiesTable.status.INSCRIT.label`),
        },
        {
          value: "INVITE",
          label: i18next.t(`thirdpartiesTable.status.INVITE.label`),
        },
        {
          value: "INVIATION EXPIREE",
          label: i18next.t(`thirdpartiesTable.status.INVIATION EXPIREE.label`),
        },
        {
          value: "SANS CONTACT",
          label: i18next.t(`thirdpartiesTable.status.SANS CONTACT.label`),
        },
      ],
      valueFormatter: (params) =>
        i18next.t(`thirdpartiesTable.status.${params.value}.label`),
      renderCell: (params) => (
        <EADataGridCell
          link={getThirdpartySheetUrl(accountId, params.row.id, active)}
          tooltip={false}
        >
          <InscriptionStatusChip registrationStatus={params.value} />
        </EADataGridCell>
      ),
    },
    ...(active
      ? [
          ...(hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE)
            ? [
                {
                  field: "indued",
                  filterable: false,
                  headerName: i18next.t("indued.score"),
                  flex: 0.1,
                  headerAlign: "center",
                  align: "center",
                  type: "singleSelect",
                  valueOptions: [
                    {
                      value: "not_started",
                      label: i18next.t(`indued.severity.not_started`),
                    },
                    { value: "low", label: i18next.t(`indued.severity.low`) },
                    {
                      value: "medium",
                      label: i18next.t(`indued.severity.medium`),
                    },
                    { value: "high", label: i18next.t(`indued.severity.high`) },
                    {
                      value: "unknown",
                      label: i18next.t(`indued.severity.unknown`),
                    },
                  ],
                  valueFormatter: (params) =>
                    i18next.t(`indued.severity.${params.value}`),
                  renderCell: (params) => (
                    <EADataGridCell
                      link={getThirdpartySheetUrl(
                        accountId,
                        params.row.id,
                        active
                      )}
                    >
                      {i18next.t(`indued.severity.${params.value}`)}
                    </EADataGridCell>
                  ),
                },
              ]
            : []),
          ...(showTransparency &&
          hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE)
            ? [
                {
                  field: "transparencyScore",
                  filterable: false,
                  headerName: i18next.t("transparency.score"),
                  flex: 0.08,
                  headerAlign: "center",
                  align: "center",
                  type: "number",
                  renderCell: (params) => (
                    <EADataGridCell
                      link={getThirdpartySheetUrl(
                        accountId,
                        params.row.id,
                        active
                      )}
                    >
                      {getTransparencyScoreDisplay(params.value)}
                    </EADataGridCell>
                  ),
                },
              ]
            : []),
          ...(hasOption(accounts, accountId, DATABLOCKS_SUBSCRIPTIONS.FINANCIALSTRENGTH) 
                && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE)
            ? [
                {
                  field: "financialStrengthScore",
                  filterable: false,
                  headerName: i18next.t("financialStrength.labelLong"),
                  flex: 0.1,
                  headerAlign: "center",
                  align: "center",
                  type: "text",
                  renderCell: (params) => (
                    <EADataGridCell
                      link={getThirdpartySheetUrl(
                        accountId,
                        params.row.id,
                        active
                      )}
                    >
                      {params.value}
                    </EADataGridCell>
                  ),
                },
                {
                  field: "financialStrengthRating",
                  filterable: false,
                  headerName: i18next.t("datablocks.financialStrength.rating"),
                  flex: 0.1,
                  headerAlign: "center",
                  align: "center",
                  type: "text",
                  renderCell: (params) => (
                    <EADataGridCell
                      link={getThirdpartySheetUrl(
                        accountId,
                        params.row.id,
                        active
                      )}
                    >
                      {params.value}
                    </EADataGridCell>
                  ),
                },
              ]
            : []),
          {
            field: "complete",
            filterable: false,
            headerName: i18next.t("completeness"),
            flex: 0.08,
            align: "center",
            headerAlign: "center",
            type: "singleSelect",
            valueOptions: [
              { value: "complete", label: i18next.t("complete") },
              { value: "incomplete", label: i18next.t("incomplete") },
            ],
            valueFormatter: (params) => i18next.t(params.value),
            renderCell: (params) => (
              <EADataGridCell
                link={getThirdpartySheetUrl(accountId, params.row.id, active)}
                tooltipLabel={i18next.t(params.value)}
              >
                {params.value === "complete" ? (
                  <EAIcon icon={"thirdparty-completeness_ok"} />
                ) : (
                  <EAIcon icon={"thirdparty-completeness_ko"} />
                )}
              </EADataGridCell>
            ),
          },
        ]
      : []),
    ...attributesColumns?.map((attrCol) => {
      return {
        field: `attribute-${attrCol.label}`,
        filterable: false,
        headerName: attrCol.label,
        renderHeader: () => <span className="dt">{attrCol.label}</span>,
        flex: 0.08,
        type: attrCol.gridColumnType,
        headerAlign: "center",
        ...(attrCol.gridColumnType === "singleSelect" && {
          valueOptions: getAttributeValueOptions(attrCol),
        }),
        ...(attrCol.gridColumnType === "number" && {
          valueFormatter: (params) =>
            getAttributeValueDisplay(attrCol.type, params.value),
        }),
        renderCell: (params) => (
          <EADataGridCell
            link={getThirdpartySheetUrl(accountId, params.row.id, active)}
            disableTranslation={!shouldTranslateAttribute(params.value)}
          >
            <span>{getAttributeValueDisplay(attrCol.type, params.value)}</span>
          </EADataGridCell>
        ),
      };
    }),
    ...calculatedAttributesColumns?.map((attrCol) => {
      return {
        field: `calculatedAttribute-${attrCol.label}`,
        filterable: false,
        headerName: attrCol.label,
        renderHeader: () => <span className="dt">{attrCol.label}</span>,
        flex: 0.08,
        headerAlign: "center",
        type: attrCol.gridColumnType,
        renderCell: (params) => (
          <EADataGridCell
            link={getThirdpartySheetUrl(accountId, params.row.id, active)}
            disableTranslation={!shouldTranslateAttribute(params.value)}
          >
            {params.value}
          </EADataGridCell>
        ),
      };
    }),
    ...indicatorsColumns?.map((indicCol) => {
      return {
        field: `indicator-${indicCol.label}`,
        filterable: false,
        headerName: indicCol.label,
        renderHeader: () => <span className="dt">{indicCol.label}</span>,
        flex: 0.08,
        headerAlign: "center",
        type: indicCol.gridColumnType,
        renderCell: (params) => (
          <EADataGridCell
            link={getThirdpartySheetUrl(accountId, params.row.id, active)}
            disableTranslation={!shouldTranslateAttribute(params.value)}
          >
            {params.value}
          </EADataGridCell>
        ),
      };
    }),
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <DataTableActions
            actions={thirdpartiesTableActions(
              accountId,
              params.row.id,
              history,
              active
            )}
          />
        );
      },
    },
  ];
};
