

export const FINANCIAL_STRENGTH_SCORES = {
    VERY_LOW: 'VERY_LOW', 
    LOW: 'LOW', 
    MEDIUM: 'MEDIUM', 
    HIGH: "HIGH", 
    VERY_HIGH: "VERY_HIGH"
}

export const FINANCIAL_STRENGTH_SCORES_VALUE = Object.freeze({
    UNKNOWN: "-",
    VERY_LOW: "1",
    LOW: "2",
    MEDIUM: "3",
    HIGH: "4",
    VERY_HIGH: "0"
});

export const DATABLOCKS_SUBSCRIPTIONS = {
    FINANCIALSTRENGTH : "financialStrength"
}