import React, { useContext, useState } from "react";
import i18next from "i18next";
import {
  Grid,
  Box,
} from "@mui/material";
import { Context } from "../../../../states/Store";
import EADialog from "../../../../components/common/EADialog";
import { SUCCESS, ERROR } from "../../../../services/common/Constants";
import { createRule } from "../../../../services/edge/RuleService";
import RuleForm from "../form/RuleForm";
import RuleConfiguration from "../steps/RuleConfiguration";
import RuleSummary from "../steps/RuleSummary";
import RuleFinalization from "../steps/RuleFinalization";
import { useRequestLoading } from "../../../../components/common/hooks/useRequestLoading";
const FORM_ID = "rule-creation-form-id";

/* const ConcernCounter = ({ count, type }) => {
  const { classes } = useStyles();

  return (
    <span className={classes.concernWrapper}>
      <EAIcon icon="loop" className={classes.concernIcon} />
      <span>{i18next.t("concern")}</span>
      <span className={classes.concernCount}>{count} </span>
      <span className={classes.concernType}>{type}</span>
    </span>
  )
} */

const RuleCreateDialog = ({ rule, isOpen, onClose, onValidateSuccess, criterionFieldOptions }) => {
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [currentStep, setCurrentStep] = useState("CONFIGURATION"); // CONFIGURATION, SUMMARY, FINALISATION

  const handleSubmit = async (data) => {
    try {
      startLoading();
      await createRule(state.account.id, data, null);

      dispatch({
        type: "ALERT",
        alert: data.enabled ?
          { type: SUCCESS, msg: i18next.t("rules.notifications.activate", { name: `${data.name}`, interpolation: { escapeValue: false } }) }
          :
          { type: SUCCESS, msg: i18next.t("rules.notifications.register", { name: `${data.name}`, interpolation: { escapeValue: false } }) }
      });

      onValidateSuccess();
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    } finally {
      stopLoading();
    }
  };

  const getCustomDialogStyle = (step) => {
    const title = {
      marginBottom: "17px !important",
      fontSize: "25px",
      color: "#16161E",
      fontWeight: 600
    }

    return (
      step !== "FINALIZATION"
        ? {
          backgroundColor: "#F2F3F7 !important",
          padding: "42px !important",
          "& h2": title
        }
        : {
          padding: "16px 28px !important",
          "& h2": title
        }
    )
  }

  return (
    <EADialog
      isOpen={isOpen}
      title={
        ["CONFIGURATION", "SUMMARY"].includes(currentStep)
          ? i18next.t("rules.createDialog.configurationStepTitle")
          : i18next.t("rules.createDialog.finalizationStepTitle")
      }
      onCancel={() => {
        onClose();
      }}
      formId={FORM_ID}
      hasActions={false}
      fullWidth={true}
      maxWidth={["CONFIGURATION", "SUMMARY"].includes(currentStep) ? "popuplg" : "popupsm"}
      customDialogStyle={getCustomDialogStyle(currentStep)}
      crossVisible={!["FINALIZATION"].includes(currentStep)}
    >
      <RuleForm
        rule={rule}
        formId={FORM_ID}
        submitHandler={() => {/*Do nothing, the submit handler is passed to the final step*/ }}
      >
        <Box sx={{ display: ["CONFIGURATION", "SUMMARY"].includes(currentStep) ? 'block' : 'none', position: "relative" }}>
          {/* <ConcernCounter count={5} type={"Tiers"} /> */}
          <Grid container spacing={"10px"}>
            <Grid item xs={12}>
              <RuleConfiguration
                open={currentStep === "CONFIGURATION"}
                criterionFieldOptions={criterionFieldOptions}
                onCancel={() => {
                  onClose()
                }}
                onContinue={() => {
                  setCurrentStep("SUMMARY")
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RuleSummary
                open={currentStep === "SUMMARY"}
                criterionFieldOptions={criterionFieldOptions}
                onCancel={() => {
                  setCurrentStep("CONFIGURATION")
                }}
                onContinue={() => {
                  setCurrentStep("FINALIZATION")
                }}
                disabled={currentStep === "CONFIGURATION"}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: ["FINALIZATION"].includes(currentStep) ? 'block' : 'none' }}>
          <RuleFinalization
            open={currentStep === "FINALIZATION"}
            onCancel={() => {
              setCurrentStep("SUMMARY")
            }}
            onContinue={handleSubmit}
            isRequestLoading={isRequestLoading}
          />
        </Box>
      </RuleForm>
    </EADialog>
  );
};

export default RuleCreateDialog;
