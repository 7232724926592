import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { Context } from "../../../states/Store";
import DossierActions from "./DossierActions";
import DossierTpInfos from "./DossierTpInfos";
import { getDossierSheet } from "../../../services/edge/DossierService";
import { createNoteDossier, deleteNoteDossier, getAllNoteDossier, updateNoteDossier } from "../../../services/edge/NoteService";
import DossierDocumentsList from "./DossierDocumentsList";
import { ERROR } from "../../../services/common/Constants";
import i18next from "i18next";
import useStyles from "../../../style/js-style/containers/dossiers/dossiers-sheet/DossierSheetStyle";
import { formatDate } from "../../../services/common/Utils";
import EATabPanel from "../../../components/common/EATabPanel";
import EAAccordion from "../../../components/EAAccordion";
import AttributeValuesDataTable from "../../attribute-values/AttributeValuesDataTable";
import { DossierDocumentsHeaderButtons } from "./DossierDocumentsHeaderButtons";
import EAIcon from "../../../components/common/EAIcon";
import clsx from "clsx";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import DossierArchivedCard from "./DossierArchivedCard";
import DossierCardStatus from "./DossierSheetStatus";
import { getDossierStatusFromCode } from "services/common/DocumentUtils"
import NotesList from "containers/notes/NotesList";
import ROUTES from "config/Routes";
import { getAuditTrail } from "services/edge/EventsService";

function DossierSheet() {
  const { classes } = useStyles();
  let { dossierId, thirdpartyId } = useParams();
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges, hasAnyOfPrivilegesOnScopedResourceImpl } = React.useContext(RoleBasedAccessControlContext);
  const [dossierNotes, setDossierNotes] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [visibleDocsCount, setVisibleDocsCount] = useState(0);
  const [downloadingAudit, setDownloadingAudit] = useState(false);

  const [loader, setLoader] = useState(true);

  const dispatchCrumbsData = useCallback(
    (dossier) => {
      const lastCrumbs = [
        {
          label: "breadcrumb.dossiers",
          hidden: true,
        },
        {
          label: dossier.dossierTypeLibelle || i18next.t("free"),
          disableTranslation: true
        },
      ];
      if (thirdpartyId) {
        const tpBaseRoute = dossier.thirdpartyOnboarded ? ROUTES : ROUTES.ARCHIVES;
        lastCrumbs.unshift({
          label: "breadcrumb.thirdparties",
          path: tpBaseRoute.THIRDPARTIES,
        }, {
          label: dossier.thirdpartyName,
          path: tpBaseRoute.THIRDPARTY_DETAIL.replace(":thirdpartyId", thirdpartyId),
          disableTranslation: true
        });
      }
      dispatch({
        type: "CRUMBS",
        crumbsData: { lastCrumbs },
      });
    },
    [dispatch, thirdpartyId]
  );

  const updateNoteDossierCallback = useCallback(async (noteOId, noteContent) => {
    await updateNoteDossier(state.account.id, dossierId, noteOId, noteContent);
  }, [state.account, dossierId]);

  const createNoteDossierCallback = useCallback(async (noteContent) => {
    await createNoteDossier(state.account.id, dossierId, noteContent);
  }, [state.account, dossierId]);

  const deleteNoteDossierCallback = useCallback(async (noteOId) => {
    await deleteNoteDossier(state.account.id, dossierId, noteOId);
  }, [state.account, dossierId]);

  useEffect(() => {
    async function getDossierInfos() {
      try {
        setLoader(true)
        const result = await getDossierSheet(
          state.account.id,
          dossierId,
          i18next.language,
          dispatch
        );

        if (thirdpartyId && parseInt(thirdpartyId) !== result.content.thirdpartyId) {
          throw new Error("Invalid thirdparty/dossier");
        }

        dispatch({ type: "DOSSIER", dossier: result.content });
        dispatchCrumbsData(result.content);

        setLoader(false)
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "DOSSIER_NOT_FOUND" },
        });
        setLoader(false)
      }
    }

    if (state.account) {
      getDossierInfos();
    }
  }, [dispatch, dossierId, state.account, thirdpartyId, dispatchCrumbsData]);

  useEffect(() => {
    if (state.dossier?.statusCode) {
      const status = getDossierStatusFromCode(state.dossier.statusCode);
      setCurrentStatus(status);
    }
  }, [state.dossier])

  const hasAnyOfPrivilegesOnScopedResource = useCallback((...privileges) => {
    let result = false;

    if (state.dossier) {
      result = hasAnyOfPrivilegesOnScopedResourceImpl(state.dossier, ...privileges);
    }

    return result;
  }, [state.dossier, hasAnyOfPrivilegesOnScopedResourceImpl]);

  const [value, setValue] = useState(
    hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION) ? 0
      : hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES) ? 1
        : 0
  );

  const fetchDossierNotes = useCallback(() => {
    async function getDossierNotes() {
      try {
        const result = await getAllNoteDossier(
          state.account.id,
          state.dossier?.dossierId,
          null
        );
        setDossierNotes(result.content);
      } catch (error) {
        setDossierNotes(null);
      }
    }

    if (state.account && state.dossier && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_NOTES)) {
      getDossierNotes();
    } else {
      setDossierNotes(null);
    }
  }, [state.dossier, state.account, hasAnyOfPrivilegesOnScopedResource]);

  useEffect(() => {
    fetchDossierNotes()
  }, [fetchDossierNotes]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadAuditTrail = useCallback(() => {

    if (state.account && state.dossier?.dossierId) {
      let tPId;
      if (thirdpartyId === undefined) {
        tPId = state.dossier.thirdpartyId;
      } else {
        tPId = thirdpartyId;
      }

      (async function fetchData() {
        try {
          setDownloadingAudit(true);
          const resp = await getAuditTrail(
            state.account.id,
            tPId,
            state.dossier?.dossierId,
            null,
            dispatch
          );

          // download the file from the returned link
          const link = document.createElement("a");
          link.href = resp.content?.slice(1, -1);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloadingAudit(false);

        } catch (e) {
          if (!e?.message.messages?.error) {
            dispatch({
              type: "ALERT",
              alert: { type: ERROR, msg: "INTERNAL_ERROR" },
            });
          }
          setDownloadingAudit(false);
        }
      })();
    }
  }, [thirdpartyId, dispatch, state.account, state.dossier?.dossierId, state.dossier?.thirdpartyId]);

  return (
    <>
      {!loader && state.dossier && (
        <Grid
          container
          justifyContent="space-evenly"
          spacing={2}
          className={classes.root}
          style={{ zIndex: 2 }}
        >
          {/* Header : date & folder name */}
          <Grid item xs={12} style={{ zIndex: 2 }}>
            <Typography
              variant="subtitle2"
              className={
                state.dossier.stoppingDate
                  ? classes.archivedFontHeaderTheme
                  : ""
              }
            >
              <EAIcon icon={"valid"} className={classes.topHeaderIcon} />
              <span className={classes.addDate}>
                {i18next.t("dashboard.thirdparties.addAt")}{" "}
                {state.dossier.creationDate &&
                  <span className='dt'>{formatDate(state.dossier.creationDate)}</span>
                }
              </span>
            </Typography>
            <Typography
              variant="h1"
              className={
                state.dossier.stoppingDate
                  ? classes.archivedFontHeaderTheme
                  : ""
              }
            >
              {/* Folder "name" */}
              {state.dossier.dossierTypeLibelle !== null
                ? state.dossier.dossierTypeLibelle
                : i18next.t("free")
              }
            </Typography>
          </Grid>
          {/* Header background */}
          <div
            className={
              state.dossier.stoppingDate
                ? clsx(classes.headerBackgroundArchived, classes.headerBackground)
                : clsx(classes[currentStatus?.headerClass], classes.headerBackground)
            }
          ></div>

          {/* Button control */}
          <div className={classes.btnControlWrapper}>
            <DossierActions dossier={state.dossier} callbackDownloadAuditTrail={downloadAuditTrail} downloadingAudit={downloadingAudit} />
          </div>

          {/* Folder info tab */}
          <Grid item xs={9} style={{ zIndex: 2 }}>
            <Box className={classes.tabsWrapper}>
              <Tabs
                aria-label="dashboard tabs"
                value={value}
                onChange={handleChange}
              >
                <Tab
                  label={i18next.t("dossier")}
                  {...a11yProps(0)}
                  disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION)}
                  className={
                    classes.tab +
                    " " +
                    (!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION) ? classes.tabDisabled : value === 0 ? classes.selectedTab : classes.unselectedTab)
                  }
                  style={{ borderRadius: "0 12px 12px 12px !important" }}
                />
                <Tab
                  label={i18next.t("attributesLabel")}
                  {...a11yProps(1)}
                  disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES)}
                  className={
                    classes.tab +
                    " " +
                    (!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES) ? classes.tabDisabled : value === 1 ? classes.selectedTab : classes.unselectedTab)
                  }
                />
              </Tabs>
            </Box>

            {/* Thirdparties info */}
            <EATabPanel value={value} index={0} variant="withoutBackgroundAndMargin">
              {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION) &&
                <DossierTpInfos dossier={state.dossier} />
              }
            </EATabPanel>

            {/* Dossier Attributes */}
            <EATabPanel value={value} index={1} variant="withoutBackgroundAndMargin">
              {hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES) &&
                <AttributeValuesDataTable
                  id={state.dossier.dossierId}
                  bondType="dossier"
                  hasAnyOfPrivileges={hasAnyOfPrivileges}
                  hasAnyOfPrivilegesOnScopedResource={hasAnyOfPrivilegesOnScopedResource}
                />
              }
            </EATabPanel>
          </Grid>

          {!state.dossier.stoppingDate ? (
            // Folder complete / incomplete
            <Grid item xs={3} style={{ zIndex: 2 }}>
              <DossierCardStatus status={currentStatus} />
            </Grid>
          ) : (
            // Folder archived info
            <Grid item xs={3} style={{ zIndex: 2 }}>
              <DossierArchivedCard dossier={state.dossier} />
            </Grid>
          )}

          <Grid item xs={12} style={{ zIndex: 2, position: "relative", height: "15px" }}> </Grid>

          {/* Documents list */}
          {hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT) &&
            <Grid item xs={12} style={{ zIndex: 2, position: "relative" }}>
              <EAAccordion
                buttons={[
                  <DossierDocumentsHeaderButtons
                    dossier={state.dossier}
                    disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER)}
                  />,
                ]}
                title={i18next.t("documents(s)AskedFor")}
                logo="document"
                counter={visibleDocsCount}
                totalCount={state.dossier.documents.length}
                opened={true}
              >
                <DossierDocumentsList dossier={state.dossier} sheetVariant={true} onVisibleRowsCountChange={count => setVisibleDocsCount(count)} />
              </EAAccordion>
            </Grid>

          }
          {/* Notes list */}
          <Grid item xs={12} style={{ zIndex: 2, position: "relative" }}>
            <EAAccordion
              title={i18next.t("notes.title")}
              logo="note_green"
              counter={dossierNotes?.length}
              opened={false}
              notificationCounter={0} // TODO change when notifications counter is available
              disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_NOTES)}
            >
              {dossierNotes && (
                <NotesList notes={dossierNotes} fetchNotes={() => fetchDossierNotes()}
                  createNote={createNoteDossierCallback}
                  updateNote={updateNoteDossierCallback}
                  deleteNote={deleteNoteDossierCallback}
                  type="DOSSIER"
                />
              )}
            </EAAccordion>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DossierSheet;
