import React from "react";
import DataTableActions from "components/common/datatable/DataTableActions";
import i18next from "i18next";
import {
  cellWithTooltip, EADataGridCell,
} from "components/common/datatable/EADataGrid";
import StatusChip from "components/common/StatusChip";
import MainHeaderCell from "components/common/datatable/MainHeaderCell";
import { PrivilegeEnum } from "services/common/RolesUtils";

const rulesTableActions = (
  ruleId,
  status,
  openEditDialog,
  openDeleteDialog,
  onToggleRuleStatus,
  hasAnyOfPrivileges
) => {
  return [
    {
      label: i18next.t("modify"),
      icon: "edit_grey",
      action: () => openEditDialog(ruleId),
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_PARAMETERS),
    },
    {
      label: status === 'active' ? i18next.t("rules.table.columns.actions.disable") : i18next.t("rules.table.columns.actions.enable"),
      icon: status === 'active' ? "pause_circle_filled" : "play_circle_filled",
      action: () => { onToggleRuleStatus(ruleId) },
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS),
    },
    {
      label: i18next.t("delete"),
      icon: "delete_grey",
      action: () => openDeleteDialog(ruleId),
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_RULE),
    }
  ];
};

export const rulesListColumns = (openEditDialog, openDeleteDialog, toggleStatus, hasAnyOfPrivileges) => {
  return [
    {
      field: "name",
      headerName: i18next.t("rules.table.columns.label"),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("rules.table.columns.label")} />
      ),
      renderCell: (params) => cellWithTooltip(params.value),
    },
    /* {
      field: "thirdParties",
      headerName: i18next.t("rules.table.columns.thirdParties"),
      flex: 0.1,
      renderCell: (params) => cellWithTooltip(params.value),
    }, */
    {
      field: "status",
      headerName: i18next.t("rules.table.columns.status.title"),
      flex: 0.1,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: [
        { value: "active", label: i18next.t("rules.table.columns.status.options.active") },
        { value: "inactive", label: i18next.t("rules.table.columns.status.options.inactive") },
      ],
      valueFormatter: (params) =>
        params.value === "active"
          ?
          i18next.t("rules.table.columns.status.options.active")
          :
          i18next.t("rules.table.columns.status.options.inactive"),
      renderCell: (params) => (
        <EADataGridCell
          tooltip={false}
        >
          <StatusChip status={params.value} label={"rules.table.columns.status.options"} />
        </EADataGridCell>
      ),
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="dt" style={{ textAlign: "center" }}>
            <DataTableActions
              actions={rulesTableActions(
                params.row.id,
                params.row.status,
                openEditDialog,
                openDeleteDialog,
                toggleStatus,
                hasAnyOfPrivileges
              )}
            />
          </div>
        );
      },
    },
  ];
};
