import React from "react";
import i18next from "i18next";
import _ from "lodash";

import { darkGrey, errorColor, infoColor, orangeColor, primary, purpleColor } from "style/globalStyle";
import ColoredPrivilegeName from "containers/administration/roles/privileges/common/ColoredPrivilegeName";
import { CalculationResultType } from "./CalculatorUtils";
import { ActivityLevel, AttributeType, FieldDisplayVariant, FieldType, Operator } from "./Constants";
import { formatDateOperatorLabel } from "./RulesUtils";

export const RoleBasedAccessControlContext = React.createContext({
  hasAnyOfPrivileges: () => { return false },
  hasAnyOfPrivilegesOnScopedResourceImpl: () => { return false },
  isGlobalAdmin: false,
});

export const RolePrivilegesEditorContext = React.createContext({
  isCurrentReadOnly: false,
});

export const isEventPrivilege = (privilegeName) => {
  return privilegeName.startsWith("PRIVILEGE_EVENT") && !privilegeName.includes("TASK");
}
export const isEventTaskPrivilege = (privilegeName) => {
  return privilegeName.startsWith("PRIVILEGE_EVENT") && privilegeName.includes("TASK");
}

export const ROLE_NAME_MAX_LENGTH = 100;

export const ScopedResourceType = {
  THIRD_PARTY: 'THIRD_PARTY',
  DOSSIER: 'DOSSIER',
  DOCUMENT: 'DOCUMENT'
}

const PrivilegeType = {
  CREATE: 'CREATE',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  OTHER: 'OTHER',
  ALL: 'ALL'
}

//!\\ DO NOT MODIFY
function createPrivilegeEnum() {

  const privileges = {
    //Personalization
    PRIVILEGE_PERSONALIZE_THIRD_PARTY_LABEL: {
      name: "PRIVILEGE_PERSONALIZE_THIRD_PARTY_LABEL",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_PERSONALIZE: {
      name: "PRIVILEGE_PERSONALIZE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },


    //Configuration
    PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE: {
      name: "PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE: {
      name: "PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE: {
      name: "PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE: {
      name: "PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_ALL_THIRD_PARTY_ATTRIBUTE: {
      name: "PRIVILEGE_ALL_THIRD_PARTY_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },

    PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE: {
      name: "PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE: {
      name: "PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE: {
      name: "PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE: {
      name: "PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE: {
      name: "PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_DOSSIER_ATTRIBUTE: {
      name: "PRIVILEGE_ALL_DOSSIER_ATTRIBUTE",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },


    PRIVILEGE_VIEW_RULE: {
      name: "PRIVILEGE_VIEW_RULE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_RULE_PARAMETERS: {
      name: "PRIVILEGE_EDIT_RULE_PARAMETERS",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_RULE_STATUS: {
      name: "PRIVILEGE_EDIT_RULE_STATUS",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_RULE: {
      name: "PRIVILEGE_EDIT_RULE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_RULE: {
      name: "PRIVILEGE_CREATE_RULE",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_RULE: {
      name: "PRIVILEGE_DELETE_RULE",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_RULE: {
      name: "PRIVILEGE_ALL_RULE",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },

    PRIVILEGE_VIEW_CALCULATION: {
      name: "PRIVILEGE_VIEW_CALCULATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_CALCULATION_PARAMETERS: {
      name: "PRIVILEGE_EDIT_CALCULATION_PARAMETERS",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_CALCULATION_STATUS: {
      name: "PRIVILEGE_EDIT_CALCULATION_STATUS",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_CALCULATION: {
      name: "PRIVILEGE_EDIT_CALCULATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_CALCULATION: {
      name: "PRIVILEGE_CREATE_CALCULATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_CALCULATION: {
      name: "PRIVILEGE_DELETE_CALCULATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_CALCULATION: {
      name: "PRIVILEGE_ALL_CALCULATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },


    PRIVILEGE_VIEW_ROLE: {
      name: "PRIVILEGE_VIEW_ROLE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_ROLE: {
      name: "PRIVILEGE_EDIT_ROLE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_ROLE: {
      name: "PRIVILEGE_CREATE_ROLE",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_ROLE: {
      name: "PRIVILEGE_DELETE_ROLE",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_ROLE: {
      name: "PRIVILEGE_ALL_ROLE",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },


    PRIVILEGE_VIEW_USER: {
      name: "PRIVILEGE_VIEW_USER",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_USER: {
      name: "PRIVILEGE_EDIT_USER",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_USER: {
      name: "PRIVILEGE_CREATE_USER",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_USER: {
      name: "PRIVILEGE_DELETE_USER",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_USER: {
      name: "PRIVILEGE_ALL_USER",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },


    //Features
    PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_THIRD_PARTY_RISKS: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_RISKS",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_THIRD_PARTY_INDICATORS: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_INDICATORS",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_THIRD_PARTY_NOTES: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_CREATE_THIRD_PARTY_NOTES: {
      name: "PRIVILEGE_CREATE_THIRD_PARTY_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_EDIT_THIRD_PARTY_NOTES: {
      name: "PRIVILEGE_EDIT_THIRD_PARTY_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_VIEW_THIRD_PARTY_STATUS: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_STATUS",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_THIRD_PARTY: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_THIRD_PARTY: {
      name: "PRIVILEGE_EDIT_THIRD_PARTY",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_THIRD_PARTY: {
      name: "PRIVILEGE_CREATE_THIRD_PARTY",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_ARCHIVE_THIRD_PARTY: {
      name: "PRIVILEGE_ARCHIVE_THIRD_PARTY",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_THIRD_PARTY: {
      name: "PRIVILEGE_ALL_THIRD_PARTY",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },


    PRIVILEGE_VIEW_DOSSIER_INFORMATION: {
      name: "PRIVILEGE_VIEW_DOSSIER_INFORMATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES: {
      name: "PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_DOSSIER_STATUS: {
      name: "PRIVILEGE_VIEW_DOSSIER_STATUS",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_DOSSIER: {
      name: "PRIVILEGE_VIEW_DOSSIER",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_DOSSIER_STATUS: {
      name: "PRIVILEGE_EDIT_DOSSIER_STATUS",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_VIEW_DOSSIER_NOTES: {
      name: "PRIVILEGE_VIEW_DOSSIER_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_CREATE_DOSSIER_NOTES: {
      name: "PRIVILEGE_CREATE_DOSSIER_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_EDIT_DOSSIER_NOTES: {
      name: "PRIVILEGE_EDIT_DOSSIER_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_DOSSIER: {
      name: "PRIVILEGE_EDIT_DOSSIER",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_DOSSIER: {
      name: "PRIVILEGE_CREATE_DOSSIER",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_ARCHIVE_DOSSIER: {
      name: "PRIVILEGE_ARCHIVE_DOSSIER",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_DOSSIER: {
      name: "PRIVILEGE_ALL_DOSSIER",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },
    PRIVILEGE_VIEW_DOCUMENT: {
      name: "PRIVILEGE_VIEW_DOCUMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_DOCUMENT_ANSWER: {
      name: "PRIVILEGE_EDIT_DOCUMENT_ANSWER",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_DOCUMENT_STATUS: {
      name: "PRIVILEGE_EDIT_DOCUMENT_STATUS",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_DOCUMENT_APPROVE: {
      name: "PRIVILEGE_EDIT_DOCUMENT_APPROVE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_DOCUMENT_REJECT: {
      name: "PRIVILEGE_EDIT_DOCUMENT_REJECT",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_EDIT_DOCUMENT: {
      name: "PRIVILEGE_EDIT_DOCUMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_DOCUMENT: {
      name: "PRIVILEGE_CREATE_DOCUMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_DOCUMENT: {
      name: "PRIVILEGE_DELETE_DOCUMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_DOCUMENT: {
      name: "PRIVILEGE_ALL_DOCUMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },
    PRIVILEGE_VIEW_DOCUMENT_NOTES: {
      name: "PRIVILEGE_VIEW_DOCUMENT_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_CREATE_DOCUMENT_NOTES: {
      name: "PRIVILEGE_CREATE_DOCUMENT_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_EDIT_DOCUMENT_NOTES: {
      name: "PRIVILEGE_EDIT_DOCUMENT_NOTES",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },


    PRIVILEGE_VIEW_CONTACT: {
      name: "PRIVILEGE_VIEW_CONTACT",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_CONTACT: {
      name: "PRIVILEGE_EDIT_CONTACT",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_CREATE_CONTACT: {
      name: "PRIVILEGE_CREATE_CONTACT",
      childPrivileges: [],
      privilegeType: PrivilegeType.CREATE
    },
    PRIVILEGE_DELETE_CONTACT: {
      name: "PRIVILEGE_DELETE_CONTACT",
      childPrivileges: [],
      privilegeType: PrivilegeType.DELETE
    },
    PRIVILEGE_ALL_CONTACT: {
      name: "PRIVILEGE_ALL_CONTACT",
      childPrivileges: [],
      privilegeType: PrivilegeType.ALL
    },


    //Dashboard
    PRIVILEGE_VIEW_DASHBOARD: {
      name: "PRIVILEGE_VIEW_DASHBOARD",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },


    // EVENTS
    // Events Dossiers
    PRIVILEGE_EVENT_DOSSIER: {
      name: "PRIVILEGE_EVENT_DOSSIER",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_ADDED: {
      name: "PRIVILEGE_EVENT_DOSSIER_ADDED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_ARCHIVED: {
      name: "PRIVILEGE_EVENT_DOSSIER_ARCHIVED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_UPDATED: {
      name: "PRIVILEGE_EVENT_DOSSIER_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_ATTRIBUTE_UPDATED: {
      name: "PRIVILEGE_EVENT_DOSSIER_ATTRIBUTE_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    // PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED: {
    //   name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED",
    //   childPrivileges: [],
    //   privilegeType: PrivilegeType.VIEW
    // },
    PRIVILEGE_EVENT_DOSSIER_COMMENT: {
      name: "PRIVILEGE_EVENT_DOSSIER_COMMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_COMMENT_ADDED: {
      name: "PRIVILEGE_EVENT_DOSSIER_COMMENT_ADDED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_COMMENT_UPDATED: {
      name: "PRIVILEGE_EVENT_DOSSIER_COMMENT_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_COMMENT_DELETED: {
      name: "PRIVILEGE_EVENT_DOSSIER_COMMENT_DELETED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_ADDED: {
      name: "PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_ADDED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_UPDATED: {
      name: "PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_DELETED: {
      name: "PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_DELETED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ABSENT: {
      name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ABSENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ONGOING: {
      name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ONGOING",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ACTION_REQUIRED: {
      name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ACTION_REQUIRED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_VALID: {
      name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_VALID",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_BELOW_EXPECTATION: {
      name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_BELOW_EXPECTATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_INVALID: {
      name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_INVALID",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED: {
      name: "PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },

    // Event Document
    PRIVILEGE_EVENT_DOCUMENT: {
      name: "PRIVILEGE_EVENT_DOCUMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ADDED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ADDED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_STOPPED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_STOPPED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_ADDED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_ADDED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_UPDATED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_INTERRUPTED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_INTERRUPTED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_SCOPE_UPDATED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_SCOPE_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_EXPIRED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_EXPIRED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_ACCEPTED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_ACCEPTED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_DENIED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_DENIED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ABSENT: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ABSENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ONGOING: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ONGOING",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ACTION_REQUIRED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ACTION_REQUIRED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_VALID: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_VALID",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_BELOW_EXPECTATION: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_BELOW_EXPECTATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_INVALID: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_INVALID",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE: {
      name: "PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_COMMENT: {
      name: "PRIVILEGE_EVENT_DOCUMENT_COMMENT",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_COMMENT_ADDED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_COMMENT_ADDED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_COMMENT_UPDATED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_COMMENT_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_COMMENT_DELETED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_COMMENT_DELETED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_ADDED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_ADDED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_UPDATED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_UPDATED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_DELETED: {
      name: "PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_DELETED",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_TASKS: {
      name: "PRIVILEGE_EVENT_DOCUMENT_TASKS",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION: {
      name: "PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE: {
      name: "PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },

    PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE: {
      name: "PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    // PRIVILEGE_EVENT_DOCUMENT_INDUED: {
    //   name: "PRIVILEGE_EVENT_DOCUMENT_INDUED",
    //   childPrivileges: [],
    //   privilegeType: PrivilegeType.VIEW
    // },
    // PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_GENERATED: {
    //   name: "PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_GENERATED",
    //   childPrivileges: [],
    //   privilegeType: PrivilegeType.VIEW
    // },
    // PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_RECEIVED: {
    //   name: "PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_RECEIVED",
    //   childPrivileges: [],
    //   privilegeType: PrivilegeType.VIEW
    // },


    //Data sources
    PRIVILEGE_VIEW_INDUED_SOURCE: {
      name: "PRIVILEGE_VIEW_INDUED_SOURCE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_REFRESH_INDUED_SOURCE: {
      name: "PRIVILEGE_REFRESH_INDUED_SOURCE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },
    PRIVILEGE_VIEW_TRANSPARENCY_SOURCE: {
      name: "PRIVILEGE_VIEW_TRANSPARENCY_SOURCE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE: {
      name: "PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE: {
      name: "PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE",
      childPrivileges: [],
      privilegeType: PrivilegeType.EDIT
    },

    //AUDIT TRAIL
    PRIVILEGE_VIEW_THIRD_PARTY_AUDIT_TRAIL: {
      name: "PRIVILEGE_VIEW_THIRD_PARTY_AUDIT_TRAIL",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_DOSSIER_AUDIT_TRAIL: {
      name: "PRIVILEGE_VIEW_DOSSIER_AUDIT_TRAIL",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    },
    PRIVILEGE_VIEW_DOCUMENT_AUDIT_TRAIL: {
      name: "PRIVILEGE_VIEW_DOCUMENT_AUDIT_TRAIL",
      childPrivileges: [],
      privilegeType: PrivilegeType.VIEW
    }
  };

  //Personalization
  privileges.PRIVILEGE_PERSONALIZE.childPrivileges = [
    privileges.PRIVILEGE_PERSONALIZE_THIRD_PARTY_LABEL
  ];

  //Configuration
  privileges.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE
  ];
  privileges.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE,
    privileges.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE
  ];
  privileges.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE
  ];
  privileges.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE,
  ];
  privileges.PRIVILEGE_ALL_THIRD_PARTY_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE,
    privileges.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE,
    privileges.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE,
    privileges.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE
  ];


  privileges.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE
  ];
  privileges.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE,
    privileges.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE
  ];
  privileges.PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE
  ];
  privileges.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE,
  ];
  privileges.PRIVILEGE_ALL_DOSSIER_ATTRIBUTE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE,
    privileges.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE,
    privileges.PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE,
    privileges.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE
  ];


  privileges.PRIVILEGE_EDIT_RULE_PARAMETERS.childPrivileges = [
    privileges.PRIVILEGE_VIEW_RULE
  ];
  privileges.PRIVILEGE_EDIT_RULE_STATUS.childPrivileges = [
    privileges.PRIVILEGE_VIEW_RULE,
  ];
  privileges.PRIVILEGE_EDIT_RULE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_RULE,
    privileges.PRIVILEGE_EDIT_RULE_PARAMETERS,
    privileges.PRIVILEGE_EDIT_RULE_STATUS,
  ];
  privileges.PRIVILEGE_CREATE_RULE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_RULE
  ];
  privileges.PRIVILEGE_DELETE_RULE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_RULE,
  ];
  privileges.PRIVILEGE_ALL_RULE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_RULE,
    privileges.PRIVILEGE_EDIT_RULE,
    privileges.PRIVILEGE_CREATE_RULE,
    privileges.PRIVILEGE_DELETE_RULE
  ];

  privileges.PRIVILEGE_EDIT_CALCULATION_PARAMETERS.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CALCULATION
  ];
  privileges.PRIVILEGE_EDIT_CALCULATION_STATUS.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CALCULATION,
  ];
  privileges.PRIVILEGE_EDIT_CALCULATION.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CALCULATION,
    privileges.PRIVILEGE_EDIT_CALCULATION_PARAMETERS,
    privileges.PRIVILEGE_EDIT_CALCULATION_STATUS,
  ];
  privileges.PRIVILEGE_CREATE_CALCULATION.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CALCULATION
  ];
  privileges.PRIVILEGE_DELETE_CALCULATION.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CALCULATION,
  ];
  privileges.PRIVILEGE_ALL_CALCULATION.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CALCULATION,
    privileges.PRIVILEGE_EDIT_CALCULATION,
    privileges.PRIVILEGE_CREATE_CALCULATION,
    privileges.PRIVILEGE_DELETE_CALCULATION
  ];


  privileges.PRIVILEGE_EDIT_ROLE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_ROLE,
  ];
  privileges.PRIVILEGE_CREATE_ROLE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_ROLE
  ];
  privileges.PRIVILEGE_DELETE_ROLE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_ROLE,
  ];
  privileges.PRIVILEGE_ALL_ROLE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_ROLE,
    privileges.PRIVILEGE_EDIT_ROLE,
    privileges.PRIVILEGE_CREATE_ROLE,
    privileges.PRIVILEGE_DELETE_ROLE
  ];


  privileges.PRIVILEGE_EDIT_USER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_USER,
  ];
  privileges.PRIVILEGE_CREATE_USER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_USER
  ];
  privileges.PRIVILEGE_DELETE_USER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_USER,
  ];
  privileges.PRIVILEGE_ALL_USER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_USER,
    privileges.PRIVILEGE_EDIT_USER,
    privileges.PRIVILEGE_CREATE_USER,
    privileges.PRIVILEGE_DELETE_USER
  ];


  //Features
  privileges.PRIVILEGE_VIEW_THIRD_PARTY.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD,
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES,
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS,
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET,
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_RISKS,
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_INDICATORS,
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_STATUS,
  ];
  privileges.PRIVILEGE_EDIT_THIRD_PARTY.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY,
  ];
  privileges.PRIVILEGE_CREATE_THIRD_PARTY.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY
  ];
  privileges.PRIVILEGE_ARCHIVE_THIRD_PARTY.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY,
  ];
  privileges.PRIVILEGE_ALL_THIRD_PARTY.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY,
    privileges.PRIVILEGE_EDIT_THIRD_PARTY,
    privileges.PRIVILEGE_CREATE_THIRD_PARTY,
    privileges.PRIVILEGE_ARCHIVE_THIRD_PARTY
  ];
  privileges.PRIVILEGE_CREATE_THIRD_PARTY_NOTES.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_NOTES,
  ];
  privileges.PRIVILEGE_EDIT_THIRD_PARTY_NOTES.childPrivileges = [
    privileges.PRIVILEGE_VIEW_THIRD_PARTY_NOTES,
    privileges.PRIVILEGE_CREATE_THIRD_PARTY_NOTES,
  ];


  privileges.PRIVILEGE_VIEW_DOSSIER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_INFORMATION,
    privileges.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES,
    privileges.PRIVILEGE_VIEW_DOSSIER_STATUS
  ];
  privileges.PRIVILEGE_EDIT_DOSSIER_STATUS.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER,
  ];
  privileges.PRIVILEGE_EDIT_DOSSIER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER,
    privileges.PRIVILEGE_EDIT_DOSSIER_STATUS
  ];
  privileges.PRIVILEGE_CREATE_DOSSIER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER
  ];
  privileges.PRIVILEGE_ARCHIVE_DOSSIER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER,
  ];
  privileges.PRIVILEGE_CREATE_DOSSIER_NOTES.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_NOTES,
  ];
  privileges.PRIVILEGE_EDIT_DOSSIER_NOTES.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER_NOTES,
    privileges.PRIVILEGE_CREATE_DOSSIER_NOTES,
  ];
  privileges.PRIVILEGE_ALL_DOSSIER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOSSIER,
    privileges.PRIVILEGE_EDIT_DOSSIER,
    privileges.PRIVILEGE_CREATE_DOSSIER,
    privileges.PRIVILEGE_ARCHIVE_DOSSIER
  ];
  privileges.PRIVILEGE_EDIT_DOCUMENT_STATUS.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT,
  ];
  privileges.PRIVILEGE_EDIT_DOCUMENT_ANSWER.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT,
  ];
  privileges.PRIVILEGE_EDIT_DOCUMENT_APPROVE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT,
  ];
  privileges.PRIVILEGE_EDIT_DOCUMENT_REJECT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT,
  ];
  privileges.PRIVILEGE_EDIT_DOCUMENT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT,
    privileges.PRIVILEGE_EDIT_DOCUMENT_ANSWER,
    privileges.PRIVILEGE_EDIT_DOCUMENT_STATUS,
    privileges.PRIVILEGE_EDIT_DOCUMENT_APPROVE,
    privileges.PRIVILEGE_EDIT_DOCUMENT_REJECT,
  ];
  privileges.PRIVILEGE_CREATE_DOCUMENT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT
  ];
  privileges.PRIVILEGE_DELETE_DOCUMENT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT,
  ];
  privileges.PRIVILEGE_CREATE_DOCUMENT_NOTES.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT_NOTES,
  ];
  privileges.PRIVILEGE_EDIT_DOCUMENT_NOTES.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT_NOTES,
    privileges.PRIVILEGE_CREATE_DOCUMENT_NOTES,
  ];
  privileges.PRIVILEGE_ALL_DOCUMENT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_DOCUMENT,
    privileges.PRIVILEGE_EDIT_DOCUMENT,
    privileges.PRIVILEGE_CREATE_DOCUMENT,
    privileges.PRIVILEGE_DELETE_DOCUMENT
  ];


  privileges.PRIVILEGE_EDIT_CONTACT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CONTACT,
  ];
  privileges.PRIVILEGE_CREATE_CONTACT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CONTACT
  ];
  privileges.PRIVILEGE_DELETE_CONTACT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CONTACT,
  ];
  privileges.PRIVILEGE_ALL_CONTACT.childPrivileges = [
    privileges.PRIVILEGE_VIEW_CONTACT,
    privileges.PRIVILEGE_EDIT_CONTACT,
    privileges.PRIVILEGE_CREATE_CONTACT,
    privileges.PRIVILEGE_DELETE_CONTACT
  ];


  // Events
  // Events Dossier
  privileges.PRIVILEGE_EVENT_DOSSIER.childPrivileges = [
    privileges.PRIVILEGE_EVENT_DOSSIER_ADDED,
    privileges.PRIVILEGE_EVENT_DOSSIER_ARCHIVED,
    privileges.PRIVILEGE_EVENT_DOSSIER_UPDATED,
    privileges.PRIVILEGE_EVENT_DOSSIER_ATTRIBUTE_UPDATED,
    privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED,
    privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT,
  ];
  privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT.childPrivileges = [
    privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_ADDED,
    privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_UPDATED,
    privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_DELETED,
    privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_ADDED,
    privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_UPDATED,
    privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_DELETED,
  ];
  privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED.childPrivileges = [
    privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ABSENT,
    privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ONGOING,
    privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ACTION_REQUIRED,
    privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_VALID,
    privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_BELOW_EXPECTATION,
    privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_INVALID,
  ];

  // Events Document
  privileges.PRIVILEGE_EVENT_DOCUMENT.childPrivileges = [
    privileges.PRIVILEGE_EVENT_DOCUMENT_ADDED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_STOPPED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ADDED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_UPDATED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_INTERRUPTED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_SCOPE_UPDATED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_EXPIRED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ACCEPTED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_DENIED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE,
    privileges.PRIVILEGE_EVENT_DOCUMENT_TASKS,
    // privileges.PRIVILEGE_EVENT_DOCUMENT_INDUED,
  ];
  privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT.childPrivileges = [
    privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_ADDED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_UPDATED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_DELETED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_ADDED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_UPDATED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_DELETED,
  ];
  privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE.childPrivileges = [
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ABSENT,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ONGOING,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ACTION_REQUIRED,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_VALID,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_BELOW_EXPECTATION,
    privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_INVALID,
  ];
  privileges.PRIVILEGE_EVENT_DOCUMENT_TASKS.childPrivileges = [
    privileges.PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION,
    privileges.PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE,
    privileges.PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE,
  ];
  // privileges.PRIVILEGE_EVENT_DOCUMENT_INDUED.childPrivileges = [
  //   privileges.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_GENERATED,
  //   privileges.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_RECEIVED,
  // ];


  //Data sources
  privileges.PRIVILEGE_REFRESH_INDUED_SOURCE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_INDUED_SOURCE
  ];
  privileges.PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE.childPrivileges = [
    privileges.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE
  ];


  return privileges;
}

export const PrivilegeEnum = createPrivilegeEnum();

export const getPrivilegeAscendants = (privilege) => {
  let ascendants = new Set();

  for (let key in PrivilegeEnum) {
    const currentPrivilege = PrivilegeEnum[key];

    if (currentPrivilege.childPrivileges.includes(privilege)) {
      ascendants.add(key);

      const ascendantsOfCurrent = getPrivilegeAscendants(currentPrivilege);
      ascendantsOfCurrent.forEach(ascendant => ascendants.add(ascendant));
    }
  }

  return ascendants;
}

export const getPrivilegeDescendants = (privilege) => {
  let descendants = new Set();

  for (let key in PrivilegeEnum) {
    const currentPrivilege = PrivilegeEnum[key];

    if (privilege.childPrivileges.includes(currentPrivilege)) {
      descendants.add(key);

      const descendantsOfCurrent = getPrivilegeDescendants(currentPrivilege);
      descendantsOfCurrent.forEach(descendant => descendants.add(descendant));
    }
  }

  return descendants;
}

//Configuration
export const THIRD_PARTY_ATTRIBUTE_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE); },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE),
    },
    {
      name: i18next.t("roles.switchButton.editValue"),
      action: (isActive) => {
        !isActive ?
          grantPrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE
          )
          :
          revokePrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE,
            PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE,
            PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE
          );
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE,
        PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE_VALUE,
        PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE
      ),
    },
    {
      name: i18next.t("roles.switchButton.editAttribute"),
      action: (isActive) => {
        !isActive ?
          grantPrivileges(PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY_ATTRIBUTE)
          :
          revokePrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE,
            PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE,
            PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE,
          );
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE
      ),
    }
  ],
  items: [],
  actions: [],
});

export const DOSSIER_ATTRIBUTE_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE),
    },
    {
      name: i18next.t("roles.switchButton.editValue"),
      action: (isActive) => {
        !isActive ?
          grantPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE)
          :
          revokePrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE,
            PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE,
            PrivilegeEnum.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE
          );
      },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE_VALUE),
    },
    {
      name: i18next.t("roles.switchButton.editAttribute"),
      action: (isActive) => {
        !isActive ?
          grantPrivileges(PrivilegeEnum.PRIVILEGE_ALL_DOSSIER_ATTRIBUTE)
          :
          revokePrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE,
            PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE,
            PrivilegeEnum.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE
          );
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE,
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_ATTRIBUTE,
        PrivilegeEnum.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE,
      ),
    }
  ],
  items: [],
  actions: [],
});

export const RULE_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_RULE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_RULE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_RULE),
    },
    {
      name: i18next.t("roles.switchButton.edit"),
      action: (isActive) => {
        togglePrivileges(
          PrivilegeEnum.PRIVILEGE_EDIT_RULE_PARAMETERS,
          PrivilegeEnum.PRIVILEGE_CREATE_RULE,
          PrivilegeEnum.PRIVILEGE_DELETE_RULE
        )
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_RULE_PARAMETERS,
        PrivilegeEnum.PRIVILEGE_CREATE_RULE,
        PrivilegeEnum.PRIVILEGE_DELETE_RULE
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_RULE_PARAMETERS,
        PrivilegeEnum.PRIVILEGE_CREATE_RULE,
        PrivilegeEnum.PRIVILEGE_DELETE_RULE
      ),
    },
    {
      name: i18next.t("roles.switchButton.toggleActive"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS,),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS),
    }
  ],
  items: [],
  actions: [],
});

export const CALCULATION_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_CALCULATION) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_CALCULATION),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_CALCULATION),
    },
    {
      name: i18next.t("roles.switchButton.edit"),
      action: (isActive) => {
        togglePrivileges(
          PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_PARAMETERS,
          PrivilegeEnum.PRIVILEGE_CREATE_CALCULATION,
          PrivilegeEnum.PRIVILEGE_DELETE_CALCULATION
        )
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_PARAMETERS,
        PrivilegeEnum.PRIVILEGE_CREATE_CALCULATION,
        PrivilegeEnum.PRIVILEGE_DELETE_CALCULATION
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_PARAMETERS,
        PrivilegeEnum.PRIVILEGE_CREATE_CALCULATION,
        PrivilegeEnum.PRIVILEGE_DELETE_CALCULATION
      ),
    },
    {
      name: i18next.t("roles.switchButton.toggleActive"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS,),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS),
    }
  ],
  items: [],
  actions: [],
});

export const ROLE_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_ROLE),
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_ROLE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_ROLE),
    },
    {
      name: i18next.t("roles.switchButton.editSame"),
      action: (isActive) => {
        !isActive ?
          grantPrivileges(PrivilegeEnum.PRIVILEGE_ALL_ROLE)
          :
          revokePrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_ROLE,
            PrivilegeEnum.PRIVILEGE_CREATE_ROLE,
            PrivilegeEnum.PRIVILEGE_DELETE_ROLE
          )
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_ROLE,
        PrivilegeEnum.PRIVILEGE_CREATE_ROLE,
        PrivilegeEnum.PRIVILEGE_DELETE_ROLE
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_ROLE,
        PrivilegeEnum.PRIVILEGE_CREATE_ROLE,
        PrivilegeEnum.PRIVILEGE_DELETE_ROLE
      ),
    },
  ],
  items: [],
  actions: [],
});

export const USER_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.readAll"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_USER) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_USER),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_USER),
    },
    {
      name: i18next.t("roles.switchButton.invite"),
      action: (isActive) => {
        !isActive ?
          grantPrivileges(PrivilegeEnum.PRIVILEGE_ALL_USER)
          :
          revokePrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_USER,
            PrivilegeEnum.PRIVILEGE_CREATE_USER,
            PrivilegeEnum.PRIVILEGE_DELETE_USER
          )
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_USER,
        PrivilegeEnum.PRIVILEGE_CREATE_USER,
        PrivilegeEnum.PRIVILEGE_DELETE_USER
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_USER,
        PrivilegeEnum.PRIVILEGE_CREATE_USER,
        PrivilegeEnum.PRIVILEGE_DELETE_USER
      ),
    },
  ],
  items: [],
  actions: [],
});

//Events Dossier
export const DOSSIER_EVENT_MANAGEMENT = (togglePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges, hasCurrentAllOfPrivileges, hasBaseAllOfPrivileges, selectAll, unselectAll) => ({
  name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER`),
  action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER) },
  isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER),
  isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER),
  hasSelectActions: true,
  selectAllAction: () => { selectAll(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER) },
  unselectAllAction: () => { unselectAll(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER) },
  variant: "topDrop",
  items: [
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER_ADDED_ARCHIVED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ADDED, PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ARCHIVED) },
      isActive: hasCurrentAllOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ADDED, PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ARCHIVED),
      isGrantable: hasBaseAllOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ADDED, PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ARCHIVED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER_UPDATED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_UPDATED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_UPDATED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_UPDATED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER_ATTRIBUTE_UPDATED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ATTRIBUTE_UPDATED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ATTRIBUTE_UPDATED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_ATTRIBUTE_UPDATED),
      variant: "topItem",
    },
    // {
    //   name: "Changement de statut",
    //   action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED) },
    //   isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED),
    //   isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED),
    //   items: [{
    //     name: "Le statut devient absent",
    //     action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION) },
    //     isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
    //     isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
    //   },{
    //     name: "Le statut devient Réponse en cours",
    //     action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION) },
    //     isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
    //     isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
    //   },{
    //     name: "Le statut devient Réponse terminée",
    //     action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION) },
    //     isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
    //     isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
    //   }]
    // },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT),
      variant: "subDrop",
      items: [{
        name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_ALL`),
        action: () => {
          togglePrivileges(
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_ADDED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_UPDATED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_DELETED
          )
        },
        isActive: hasCurrentAllOfPrivileges(
          PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_ADDED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_UPDATED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_DELETED
        ),
        isGrantable: hasBaseAllOfPrivileges(
          PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_ADDED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_UPDATED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_DELETED
        ),
        variant: "subItem",
        },{
          name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_ALL`),
          action: () => {
            togglePrivileges(
              PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_ADDED,
              PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_UPDATED,
              PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_DELETED
            )
          },
          isActive: hasCurrentAllOfPrivileges(
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_ADDED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_UPDATED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_DELETED
          ),
          isGrantable: hasBaseAllOfPrivileges(
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_ADDED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_UPDATED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_COMMENT_MENTION_DELETED
          ),
          variant: "subItem",
      }]
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED),
      variant: "subDrop",
      items: [
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ABSENT" color={darkGrey}/>,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ABSENT) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ABSENT),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ABSENT),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ONGOING" color={infoColor}/>,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ONGOING) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ONGOING),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ONGOING),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ACTION_REQUIRED" color={orangeColor}/>,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ACTION_REQUIRED) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ACTION_REQUIRED),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_ACTION_REQUIRED),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_VALID" color={primary}/>,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_VALID) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_VALID),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_VALID),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_BELOW_EXPECTATION" color={purpleColor}/>,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_BELOW_EXPECTATION) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_BELOW_EXPECTATION),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_BELOW_EXPECTATION),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_INVALID" color={errorColor}/>,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_INVALID) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_INVALID),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOSSIER_STATUS_UPDATED_INVALID),
          variant: "subItem",
        }]
    },
  ]
});


//Events Document
export const DOCUMENT_EVENT_MANAGEMENT = (togglePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges, hasCurrentAllOfPrivileges, hasBaseAllOfPrivileges, selectAll, unselectAll) => ({
  name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT`),
  action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT) },
  isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT),
  isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT),
  hasSelectActions: true,
  selectAllAction: () => { selectAll(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT) },
  unselectAllAction: () => { unselectAll(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT) },
  variant: "topDrop",
  items: [
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ADDED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ADDED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ADDED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ADDED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_STOPPED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_STOPPED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_STOPPED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_STOPPED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ADDED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ADDED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ADDED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ADDED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_UPDATED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_UPDATED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_UPDATED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_UPDATED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_INTERRUPTED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_INTERRUPTED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_INTERRUPTED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_INTERRUPTED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_SCOPE_UPDATED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_SCOPE_UPDATED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_SCOPE_UPDATED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_SCOPE_UPDATED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_EXPIRED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_EXPIRED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_EXPIRED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_EXPIRED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ACCEPTED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ACCEPTED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ACCEPTED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_ACCEPTED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_DENIED`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_DENIED) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_DENIED),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_DENIED),
      variant: "topItem",
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT),
      variant: "subDrop",
      items: [{
        name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_ALL`),
        action: () => {
          togglePrivileges(
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_ADDED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_UPDATED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_DELETED
          )
        },
        isActive: hasCurrentAllOfPrivileges(
          PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_ADDED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_UPDATED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_DELETED
        ),
        isGrantable: hasBaseAllOfPrivileges(
          PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_ADDED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_UPDATED,
          PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_DELETED
        ),
        variant: "subItem",
        },{
          name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_ALL`),
          action: () => {
            togglePrivileges(
              PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_ADDED,
              PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_UPDATED,
              PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_DELETED
            )
          },
          isActive: hasCurrentAllOfPrivileges(
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_ADDED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_UPDATED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_DELETED
          ),
          isGrantable: hasBaseAllOfPrivileges(
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_ADDED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_UPDATED,
            PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_COMMENT_MENTION_DELETED
          ),
          variant: "subItem",
      }]
    },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE),
      variant: "subDrop",
      items: [
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ABSENT" color={darkGrey} />,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ABSENT) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ABSENT),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ABSENT),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ONGOING" color={infoColor} />,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ONGOING) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ONGOING),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ONGOING),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ACTION_REQUIRED" color={orangeColor} />,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ACTION_REQUIRED) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ACTION_REQUIRED),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_ACTION_REQUIRED),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_VALID" color={primary} />,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_VALID) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_VALID),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_VALID),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_BELOW_EXPECTATION" color={purpleColor} />,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_BELOW_EXPECTATION) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_BELOW_EXPECTATION),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_BELOW_EXPECTATION),
          variant: "subItem",
        },
        {
          name: <ColoredPrivilegeName name="PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_INVALID" color={errorColor} />,
          action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_INVALID) },
          isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_INVALID),
          isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_ANSWER_STATUS_UPDATE_INVALID),
          variant: "subItem",
        }]
    },
    // {
    //   name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_INDUED`),
    //   action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED) },
    //   isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED),
    //   isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED),
    //   variant: "subDrop",
    //   items: [{
    //     name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_GENERATED`),
    //     action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_GENERATED) },
    //     isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_GENERATED),
    //     isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_GENERATED),
    //     variant: "subItem",
    //   }, {
    //     name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_RECEIVED`),
    //     action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_RECEIVED) },
    //     isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_RECEIVED),
    //     isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_INDUED_REPORT_RECEIVED),
    //     variant: "subItem",
    //   }]
    // },
    {
      name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_TASKS`),
      action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASKS) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASKS),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASKS),
      variant: "subDrop",
      backgroundColor: "#FFEBCC",
      items: [{
        name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION`),
        action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION),
        variant: "subItem",
      }, {
        name: i18next.t(`privileges.PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE`),
        action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE),
        variant: "subItem",
      }, {
        name: i18next.t(`privileges.PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE`),
        action: () => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE),
        variant: "subItem",
      }]
    },
  ]
});


//Features
export const THIRD_PARTY_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [{
    name: i18next.t("roles.switchButton.read"),
    action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY) },
    isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY),
    isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY),
  }],
  items: [
    {
      name: i18next.t("roles.switchButton.identity"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD),
      }]
    },
    {
      name: i18next.t("roles.switchButton.attributes"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES),
      }]
    },
    {
      name: i18next.t("roles.switchButton.principalsAndRepresentatives"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS),
      }]
    },
    {
      name: i18next.t("roles.switchButton.balanceSheetData"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET),
      }]
    },
    {
      name: i18next.t("roles.switchButton.risks"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS),
      }]
    },
    {
      name: i18next.t("roles.switchButton.auditTrail"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_AUDIT_TRAIL) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_AUDIT_TRAIL),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_AUDIT_TRAIL),
      }]
    },
    {
      name: i18next.t("roles.switchButton.notes"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_NOTES),
      }, {
        name: i18next.t("roles.switchButton.add"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY_NOTES),
      }, {
        name: i18next.t("roles.switchButton.modify"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY_NOTES),
      }]
    },
    // {
    //   name: i18next.t("roles.switchButton.status"),
    //   actions: [{
    //     name: i18next.t("roles.switchButton.read"),
    //     action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_STATUS) },
    //     isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_STATUS),
    //     isGrantable : hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_STATUS),
    //   }]
    // },
  ],
  actions: [
    {
      name: i18next.t("roles.switchButton.add"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY),
    },
    {
      name: i18next.t("roles.switchButton.archive"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_ARCHIVE_THIRD_PARTY) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ARCHIVE_THIRD_PARTY),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ARCHIVE_THIRD_PARTY),
    },
    {
      name: i18next.t("roles.switchButton.modify"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY),
    },
  ],
});

export const DOSSIER_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [{
    name: i18next.t("roles.switchButton.read"),
    action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER) },
    isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER),
    isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER),
  }],
  items: [
    {
      name: i18next.t("roles.switchButton.dossierInformations"),
      actions: [{
        name: "Lire",
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION),
      }]
    },
    {
      name: i18next.t("roles.switchButton.attributes"),
      actions: [{
        name: "Lire",
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES),
      }]
    },
    {
      name: i18next.t("roles.switchButton.auditTrail"),
      actions: [{
        name: "Lire",
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_AUDIT_TRAIL) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_AUDIT_TRAIL),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_AUDIT_TRAIL),
      }]
    },
    {
      name: i18next.t("roles.switchButton.notes"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_NOTES),
      }, {
        name: i18next.t("roles.switchButton.add"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER_NOTES),
      }, {
        name: i18next.t("roles.switchButton.modify"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER_NOTES),
      }]
    },
  ],
  actions: [
    {
      name: i18next.t("roles.switchButton.add"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER),
    },
    {
      name: i18next.t("roles.switchButton.archive"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_ARCHIVE_DOSSIER) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ARCHIVE_DOSSIER),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ARCHIVE_DOSSIER),
    },
    {
      name: i18next.t("roles.switchButton.modify"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER),
    },
  ],
});

export const DOCUMENT_MANAGEMENT_ACTIONS = (togglePrivileges, toggleSubPrivilege, togglePrivilegesForAllRessources, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasCurrentAnyOfPrivilegeRessources, hasCurrentAllOfPrivilegeRessources, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT),
    },
    // {
    //   name: i18next.t("roles.switchButton.answer"),
    //   action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT) },
    //   isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT),
    //   isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT),
    // },

    //TODO: add other actions
  ],
  items: [
    {
      name: i18next.t("roles.switchButton.auditTrail"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_AUDIT_TRAIL) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_AUDIT_TRAIL),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_AUDIT_TRAIL),
      }]
    },
    {
      name: i18next.t("roles.switchButton.notes"),
      actions: [{
        name: i18next.t("roles.switchButton.read"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_NOTES),
      }, {
        name: i18next.t("roles.switchButton.add"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOCUMENT_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOCUMENT_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_DOCUMENT_NOTES),
      }, {
        name: i18next.t("roles.switchButton.modify"),
        action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_NOTES) },
        isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_NOTES),
        isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_NOTES),
      }]
    }
  ],
  actions: [
    {
      name: i18next.t("roles.switchButton.answer"),
      action: (isActive, ressourceId, ressourcesList) => { togglePrivilegesForAllRessources(ressourcesList, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER) },
      isActive: (ressourcesList) => { return hasCurrentAllOfPrivilegeRessources(ressourcesList, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER) },
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER),
    },
    {
      name: i18next.t("roles.switchButton.validate"),
      action: (isActive, ressourceId, ressourcesList) => { togglePrivilegesForAllRessources(ressourcesList, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE) },
      isActive: (ressourcesList) => { return hasCurrentAllOfPrivilegeRessources(ressourcesList, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE) },
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE),
    },
    {
      name: i18next.t("roles.switchButton.refuse"),
      action: (isActive, ressourceId, ressourcesList) => { togglePrivilegesForAllRessources(ressourcesList, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT) },
      isActive: (ressourcesList) => { return hasCurrentAllOfPrivilegeRessources(ressourcesList, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT) },
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT),
    }
  ],
  subActions: [
    {
      name: i18next.t("roles.switchButton.answer"),
      action: (isActive, ressourceId) => { toggleSubPrivilege(ressourceId, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER) },
      isActive: (ressourceId) => { return hasCurrentAnyOfPrivilegeRessources(ressourceId, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER) },
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER),
    },
    {
      name: i18next.t("roles.switchButton.validate"),
      action: (isActive, ressourceId) => { toggleSubPrivilege(ressourceId, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE) },
      isActive: (ressourceId) => { return hasCurrentAnyOfPrivilegeRessources(ressourceId, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE) },
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE),
    },
    {
      name: i18next.t("roles.switchButton.refuse"),
      action: (isActive, ressourceId) => { toggleSubPrivilege(ressourceId, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT) },
      isActive: (ressourceId) => { return hasCurrentAnyOfPrivilegeRessources(ressourceId, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT) },
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT),
    }
  ]

});

export const CONTACT_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_CONTACT) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_CONTACT),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_CONTACT),
    },
    {
      name: i18next.t("roles.switchButton.invite"),
      action: (isActive) => {
        !isActive ?
          grantPrivileges(PrivilegeEnum.PRIVILEGE_ALL_CONTACT)
          :
          revokePrivileges(
            PrivilegeEnum.PRIVILEGE_EDIT_CONTACT,
            PrivilegeEnum.PRIVILEGE_CREATE_CONTACT,
            PrivilegeEnum.PRIVILEGE_DELETE_CONTACT
          )
      },
      isActive: hasCurrentAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_CONTACT,
        PrivilegeEnum.PRIVILEGE_CREATE_CONTACT,
        PrivilegeEnum.PRIVILEGE_DELETE_CONTACT
      ),
      isGrantable: hasBaseAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_EDIT_CONTACT,
        PrivilegeEnum.PRIVILEGE_CREATE_CONTACT,
        PrivilegeEnum.PRIVILEGE_DELETE_CONTACT
      ),
    },
  ],
  items: [],
  actions: []
});

//Sources
export const INDUED_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE),
    },
    {
      name: i18next.t("roles.switchButton.modify"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE),
    },
  ],
  items: [],
  actions: []
});
export const TRANSPARENCY_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE),
    }
  ],
  items: [],
  actions: []
});
export const FINANCIAL_STRENGTH_MANAGEMENT_ACTIONS = (togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges) => ({
  rootActions: [
    {
      name: i18next.t("roles.switchButton.read"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE),
    },
    {
      name: i18next.t("roles.switchButton.modify"),
      action: (isActive) => { togglePrivileges(PrivilegeEnum.PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE) },
      isActive: hasCurrentAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE),
      isGrantable: hasBaseAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_FINANCIAL_STRENGTH_SOURCE),
    },
  ],
  items: [],
  actions: []
});

// Required privileges to navigate to the resource.
export const NAVIGATION_REQUIRED_PRIVILEGES = {
  thirdparty: [
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY,
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ID_CARD,
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES,
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_OFFICERS_AGENTS,
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_BALANCE_SHEET,
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_RISKS,
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_NOTES,
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_STATUS,
    // PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER, // A voir
  ],
  dossier: [
    PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER,
    PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_INFORMATION,
    PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES,
    PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_NOTES,
  ],
  document: [
    PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT,
    PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT_NOTES,
  ],
  user: [PrivilegeEnum.PRIVILEGE_VIEW_USER],
  role: [PrivilegeEnum.PRIVILEGE_VIEW_ROLE],
  admin: [
    PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTE,
    PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTE,
    PrivilegeEnum.PRIVILEGE_VIEW_RULE,
    PrivilegeEnum.PRIVILEGE_VIEW_CALCULATION,
  ],
}

// --------- //
//   Scope   //
// --------- //

export const getThirdPartyCriterionOptions = (accountCountries = [], accountActivities = [], attributes = [], calculatedAttributes = [], indicators = []) => {
  return [
    {
      value: 'name',
      label: 'Tiers',
      type: FieldType.TEXT,
      valueOptions: [],
    },
    {
      value: 'thirdpartyCode',
      label: 'Code tiers',
      type: FieldType.TEXT,
      valueOptions: [],
    },
    {
      value: 'etab_activity_sic_major',
      label: 'Activité SIC major',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_MAJOR),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_2',
      label: 'Activité SIC 2',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_2_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_3',
      label: 'Activité SIC 3',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_3_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_4',
      label: 'Activité SIC 4',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_4_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_6',
      label: 'Activité SIC 6',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_6_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'etab_activity_sic_8',
      label: 'Activité SIC 8',
      type: FieldType.ACTIVITY,
      valueOptions: accountActivities.filter(activity => activity.level === ActivityLevel.SIC_8_DIGITS),
      optionsDisplayVariant: FieldDisplayVariant.AUTOCOMPLETE
    },
    {
      value: 'headquarter',
      label: 'Siège',
      type: FieldType.BOOLEAN,
      valueOptions: [
        {
          value: 'true',
          label: i18next.t("headquarter")
        },
        {
          value: 'false',
          label: i18next.t("secondaryEstablishment")
        },
      ],
    },
    {
      value: 'addressPostCode',
      label: 'Code postal',
      type: FieldType.TEXT,
      valueOptions: [],
    },
    {
      value: 'addressCity',
      label: 'Ville',
      type: FieldType.TEXT,
      valueOptions: [],
    },
    {
      value: 'addressCountryCode',
      label: 'Pays',
      type: FieldType.CUSTOM,
      valueOptions: accountCountries,
    },
    ...attributes?.map((attr) => {
      return {
        value: `attribute-${attr.id}`,
        label: attr.label,
        type: FieldType.ATTRIBUTE,
        subType: attr.type,
        attributeId: attr.id,
        valueOptions: attr.values?.map((valueEntry) => {
          return {
            value: valueEntry.value,
            label: valueEntry.value
          }
        }) || [],
      };
    }),
    ...calculatedAttributes?.map((calculatedAttr) => {
      return {
        value: `${calculatedAttr.value}`,
        label: calculatedAttr.label,
        type: FieldType.CALCULATED_ATTRIBUTE,
        subType: calculatedAttr.type,
        valueOptions: [],
      };
    }),
    ...indicators?.map((ind) => {
      return {
        value: `${ind.value}`,
        label: ind.label,
        type: FieldType.INDICATOR,
        subType: ind.type,
        valueOptions: [],
      };
    }),
  ];
}

export const getDossierCriterionOptions = (dossierTypes = [], attributes = []) => {
  return [
    {
      value: 'dossierTypeCode',
      label: 'Type du dossier',
      type: FieldType.CUSTOM,
      valueOptions: dossierTypes,
    },
    ...attributes?.map((attr) => {
      return {
        value: `attribute-${attr.id}`,
        label: attr.label,
        type: FieldType.ATTRIBUTE,
        subType: attr.type,
        attributeId: attr.id,
        valueOptions: attr.values?.map((valueEntry) => {
          return {
            value: valueEntry.value,
            label: valueEntry.value
          }
        }) || [],
      };
    }),
  ];
}

export const SPECIFIC_PRIVILEGES = [
  {
    privilege: PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER.name,
    type: ScopedResourceType.DOCUMENT,
  },
  {
    privilege: PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_APPROVE.name,
    type: ScopedResourceType.DOCUMENT,
  },
  {
    privilege: PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_REJECT.name,
    type: ScopedResourceType.DOCUMENT,
  },
];


export const findRoleByIdInHierarchy = (roleId, role) => {
  if (role.id === roleId) {
    return role;
  } else {

    for (const derivedRole of role.derivedRoles) {
      const foundRole = findRoleByIdInHierarchy(roleId, derivedRole);

      if (foundRole) {
        return foundRole;
      }
    }

  }

  return null;
}

export const findRoleByIdInHierarchyFromList = (roleId, roles) => {
  if (!_.isEmpty(roles)) {
    for (const role of roles) {
      const foundRole = findRoleByIdInHierarchy(roleId, role);

      if (foundRole) {
        return foundRole;
      }
    }
  }

  return null;
}

export const cleanRoleHierarchy = (roles) => {
  const idsToRemove = new Set();

  roles.forEach((role) => {
    for (const potentialDescendant of roles) {
      let foundRole = findRoleByIdInHierarchy(potentialDescendant.id, role);

      if (!foundRole || (foundRole.id === role.id)) {
        continue;
      }

      idsToRemove.add(potentialDescendant.id);
    }
  })

  return roles.filter((role) => !idsToRemove.has(role.id));
}

export const getAllChildRoles = (role) => {
  let childRoles = new Set();

  role.derivedRoles.forEach(dr => {
    childRoles.add({ id: dr.id, name: dr.name, baseRoleId: dr.baseRoleId });

    let ccr = getAllChildRoles(dr);

    ccr.forEach(ddr => {
      childRoles.add(ddr);
    })
  })

  return Array.from(childRoles);
}

export const getFlatRoles = (roles) => {
  let flatRoles = new Set();

  roles.forEach(role => {
    flatRoles.add({ id: role.id, name: role.name, baseRoleId: role.baseRoleId })

    getAllChildRoles(role).forEach(childRole => {
      flatRoles.add({ id: childRole.id, name: childRole.name, baseRoleId: childRole.baseRoleId })
    })
  });

  return Array.from(flatRoles);
}

export const getSortedFlatRoles = (roles) => {
  return getFlatRoles(roles).sort(function (a, b) {
    let labelA = a.name.toUpperCase();
    let labelB = b.name.toUpperCase();

    if (labelA < labelB) { return -1; }
    if (labelA > labelB) { return 1; }
    return 0;
  });
}

export const getAvailableValueOptions = (field, criterionFieldOptions) => {
  let criterionFieldEntry = _.find(criterionFieldOptions, { value: field });

  return criterionFieldEntry?.valueOptions || [];
};

export const getCriterionOperatorOptions = () => {
  return {
    TEXT: [
      {
        value: Operator.EQUAL, //EQUALS
        label: "Egal à",
      },
      // {
      //     value: Operator.NOT_EQUAL,
      //     label: "N'est pas égal à",
      // },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      // {
      //     value: Operator.NOT_CONTAINS,
      //     label: "Ne contient pas",
      // },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      // {
      //     value: Operator.NOT_STARTS_WITH,
      //     label: "Ne commence pas par",
      // },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      // {
      //     value: Operator.NOT_ENDS_WITH,
      //     label: "Ne se termine pas par",
      // },
      {
        value: Operator.IS_NULL, //IS_EMPTY
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL, //IS_NOT_EMPTY
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET, //IS_ANY_OF
        label: "Fait partie de",
      },
      // {
      //     value: Operator.NOT_IN_SET, //IS_NOT_ANY_OF
      //     label: "Ne fait pas partie de",
      // },
    ],

    NUMERIC: [
      {
        value: Operator.EQUAL, // "="
        label: "="
      },
      {
        value: Operator.NOT_EQUAL, // "!="
        label: "!="
      },
      {
        value: Operator.GREATER_THAN, // ">"
        label: ">"
      },
      {
        value: Operator.GREATER_OR_EQUAL, // ">="
        label: ">="
      },
      {
        value: Operator.LESS_THAN, // "<"
        label: "<"
      },
      {
        value: Operator.LESS_OR_EQUAL, // "<="
        label: "<="
      },
      // {
      //     value: Operator.BETWEEN,
      //     label: "Est entre",
      // },
      {
        value: Operator.IS_NULL, //"IS_EMPTY"
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL, //"IS_NOT_EMPTY"
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET, //"IS_ANY_OF",
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET, //"IS_NOT_ANY_OF",
      //     label: "Ne fait pas partie de"
      // }
    ],

    DATE: [
      {
        value: Operator.EQUAL, // ""IS
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL, // "NOT"
        label: "N'est pas",
      },
      {
        value: Operator.GREATER_THAN, // "AFTER"
        label: "Postérieure",
      },
      {
        value: Operator.GREATER_OR_EQUAL, // "ON_OR_AFTER"
        label: "Egal ou postérieure",
      },
      {
        value: Operator.LESS_THAN, // "BEFORE",
        label: "Antérieur",
      },
      {
        value: Operator.LESS_OR_EQUAL, // "ON_OR_BEFORE"
        label: "Egal ou antérieure",
      },
      // {
      //     value: Operator.BETWEEN,
      //     label: "Est entre",
      // },
      {
        value: Operator.IS_NULL, // "IS_EMPTY"
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL, //IS_NOT_EMPTY"
        label: "N'est pas vide",
      },
      // {
      //   value: Operator.IN_SET, //"IS_ANY_OF",
      //   label: "Fait partie de",
      // },
      // {
      //     value: Operator.NOT_IN_SET, //"IS_NOT_ANY_OF",
      //     label: "Ne fait pas partie de",
      // }
    ],

    BOOLEAN: [
      {
        value: Operator.EQUAL, // "IS"
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL, //"NOT"
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET, //"IS_ANY_OF"
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET, //"IS_NOT_ANY_OF"
      //     label: "Ne fait pas partie de"
      // }
    ],

    CUSTOM: [
      {
        value: Operator.EQUAL, // "IS"
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL, //"NOT"
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET, //"IS_ANY_OF"
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET, //"IS_NOT_ANY_OF"
      //     label: "Ne fait pas partie de"
      // }
    ],

    ATTRIBUTE: [
      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE, DECIMAL, INTEGER, PERCENTAGE, BOOLEAN*/
      {
        value: Operator.EQUAL, //EQUALS
        label: "Egal à",
      },
      // {
      //     value: Operator.NOT_EQUAL,
      //     label: "N'est pas égal à",
      // },

      /*For LIST_MULTIPLE, LIST_UNIQUE, */
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      // {
      //     value: Operator.NOT_CONTAINS,
      //     label: "Ne contient pas",
      // },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      // {
      //     value: Operator.NOT_STARTS_WITH,
      //     label: "Ne commence pas par",
      // },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      // {
      //     value: Operator.NOT_ENDS_WITH,
      //     label: "Ne se termine pas par",
      // },

      /*For: DECIMAL, INTEGER, PERCENTAGE*/
      {
        value: Operator.GREATER_THAN, // ">"
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL, // ">="
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN, // "<"
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL, // "<="
        label: "Inférieur ou égal"
      },
      // {
      //     value: Operator.BETWEEN,
      //     label: "Est entre",
      // },

      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE, DECIMAL, INTEGER, PERCENTAGE, BOOLEAN*/
      {
        value: Operator.IS_NULL, //IS_EMPTY
        label: "Est vide",
      },

      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE, DECIMAL, INTEGER, PERCENTAGE, BOOLEAN*/
      {
        value: Operator.NOT_NULL, //IS_NOT_EMPTY
        label: "N'est pas vide",
      },
      /*For all attribute types: LIST_MULTIPLE, LIST_UNIQUE*/
      {
        value: Operator.IN_SET, //IS_ANY_OF
        label: "Fait partie de",
      },
      // {
      //     value: Operator.NOT_IN_SET, //IS_NOT_ANY_OF
      //     label: "Ne fait pas partie de",
      // },
    ],

    RISK: [
      {
        value: Operator.EQUAL, // "IS"
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL, //"NOT"
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET, //"IS_ANY_OF"
        label: "Fait partie de"
      },
      // {
      //     value: Operator.NOT_IN_SET, //"IS_NOT_ANY_OF"
      //     label: "Ne fait pas partie de"
      // }
      {
        value: Operator.IS_NULL, //"IS_EMPTY"
        label: "Est vide",
      },
      // {
      //   value: Operator.NOT_NULL, //"IS_NOT_EMPTY"
      //   label: "N'est pas vide",
      // },
    ],

    CALCULATED_ATTRIBUTE: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    INDICATOR: [
      {
        value: Operator.EQUAL,
        label: "Egal à",
      },
      {
        value: Operator.CONTAINS,
        label: "Contient",
      },
      {
        value: Operator.STARTS_WITH,
        label: "Commence par",
      },
      {
        value: Operator.ENDS_WITH,
        label: "Se termine par",
      },
      {
        value: Operator.GREATER_THAN,
        label: "Supérieur"
      },
      {
        value: Operator.GREATER_OR_EQUAL,
        label: "Supérieur ou égal"
      },
      {
        value: Operator.LESS_THAN,
        label: "Inférieur"
      },
      {
        value: Operator.LESS_OR_EQUAL,
        label: "Inférieur ou égal"
      },
      {
        value: Operator.IS_NULL,
        label: "Est vide",
      },
      {
        value: Operator.NOT_NULL,
        label: "N'est pas vide",
      },
      {
        value: Operator.IN_SET,
        label: "Fait partie de",
      },
    ],

    ACTIVITY: [
      {
        value: Operator.EQUAL, // "IS"
        label: "Est",
      },
      {
        value: Operator.NOT_EQUAL, //"NOT"
        label: "N'est pas",
      },
      {
        value: Operator.IN_SET, //"IS_ANY_OF"
        label: "Fait partie de"
      }
    ]

  };
}

export const getAvailableOperatorOptions = (field, criterionFieldOptions) => {
  let operatorOptions = [];
  let criterionFieldEntry = _.find(criterionFieldOptions, { value: field });
  if (criterionFieldEntry !== undefined) {
    operatorOptions = getCriterionOperatorOptions()[criterionFieldEntry.type];

    switch (criterionFieldEntry.type) {
      case FieldType.ATTRIBUTE:
        switch (criterionFieldEntry.subType) {
          case AttributeType.LIST_UNIQUE:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.GREATER_THAN,
              Operator.GREATER_OR_EQUAL,
              Operator.GREATER_OR_EQUAL,
              Operator.LESS_THAN,
              Operator.LESS_OR_EQUAL,
              Operator.BETWEEN,
            ].includes(entry.value));

            break;

          case AttributeType.LIST_MULTIPLE:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.STARTS_WITH,
              Operator.NOT_STARTS_WITH,
              Operator.ENDS_WITH,
              Operator.NOT_ENDS_WITH,
              Operator.GREATER_THAN,
              Operator.GREATER_OR_EQUAL,
              Operator.GREATER_OR_EQUAL,
              Operator.LESS_THAN,
              Operator.LESS_OR_EQUAL,
              Operator.BETWEEN,
              Operator.IN_SET,
              Operator.NOT_IN_SET,
            ].includes(entry.value)).map((entry) => {
              switch (entry.value) {
                //This is ugly but let's change the label of Operator.CONTAINS operator here
                case Operator.CONTAINS:
                  return {
                    value: entry.value,
                    label: 'Fait partie de',
                    // label: 'Contient une valeur parmi',
                  }

                default:
                  return entry;
              }
            });

            break;

          case AttributeType.DECIMAL:
          case AttributeType.INTEGER:
          case AttributeType.PERCENTAGE:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.CONTAINS,
              Operator.NOT_CONTAINS,
              Operator.STARTS_WITH,
              Operator.NOT_STARTS_WITH,
              Operator.ENDS_WITH,
              Operator.NOT_ENDS_WITH,
            ].includes(entry.value));

            break;

          case AttributeType.BOOLEAN:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.CONTAINS,
              Operator.NOT_CONTAINS,
              Operator.STARTS_WITH,
              Operator.NOT_STARTS_WITH,
              Operator.ENDS_WITH,
              Operator.NOT_ENDS_WITH,
              Operator.GREATER_THAN,
              Operator.GREATER_OR_EQUAL,
              Operator.GREATER_OR_EQUAL,
              Operator.LESS_THAN,
              Operator.LESS_OR_EQUAL,
              Operator.BETWEEN,
              Operator.IN_SET,
              Operator.NOT_IN_SET,
            ].includes(entry.value));
            break;

          case AttributeType.DATE:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.CONTAINS,
              Operator.NOT_CONTAINS,
              Operator.STARTS_WITH,
              Operator.NOT_STARTS_WITH,
              Operator.ENDS_WITH,
              Operator.NOT_ENDS_WITH,
              Operator.BETWEEN, //TODO: Add these 3 later if asked for
              Operator.IN_SET,
              Operator.NOT_IN_SET,
            ].includes(entry.value)).map((entry) => formatDateOperatorLabel(entry));
            break;

          default:
            break;
        }
        break;

      case FieldType.CALCULATED_ATTRIBUTE:
      case FieldType.INDICATOR:
        switch (criterionFieldEntry.subType) {
          case CalculationResultType.STRING:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.GREATER_THAN,
              Operator.GREATER_OR_EQUAL,
              Operator.GREATER_OR_EQUAL,
              Operator.LESS_THAN,
              Operator.LESS_OR_EQUAL,
              Operator.BETWEEN,
            ].includes(entry.value));

            break;

          case CalculationResultType.NUMERIC:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.CONTAINS,
              Operator.NOT_CONTAINS,
              Operator.STARTS_WITH,
              Operator.NOT_STARTS_WITH,
              Operator.ENDS_WITH,
              Operator.NOT_ENDS_WITH,
            ].includes(entry.value));
            break;

          case CalculationResultType.BOOLEAN:
            operatorOptions = operatorOptions.filter((entry) => ![
              Operator.CONTAINS,
              Operator.NOT_CONTAINS,
              Operator.STARTS_WITH,
              Operator.NOT_STARTS_WITH,
              Operator.ENDS_WITH,
              Operator.NOT_ENDS_WITH,
              Operator.GREATER_THAN,
              Operator.GREATER_OR_EQUAL,
              Operator.GREATER_OR_EQUAL,
              Operator.LESS_THAN,
              Operator.LESS_OR_EQUAL,
              Operator.BETWEEN,
              Operator.IN_SET,
              Operator.NOT_IN_SET,
            ].includes(entry.value));

            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }

  return operatorOptions;
};

// All event tasks, add new ones when ready.
export const EVENT_TASKS = [
  PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_AWAITING_VALIDATION.name,
  PrivilegeEnum.PRIVILEGE_EVENT_DOCUMENT_TASK_DUE_DILIGENCE.name,
  PrivilegeEnum.PRIVILEGE_EVENT_TASK_INDUED_FALSE_POSITIVE.name,
]
