import { useCallback, useEffect, useState } from 'react';
import useSelectedAccountId from 'components/common/hooks/useSelectedAccountId';

/**
 * Manages the columns visibility state and saves it to session if active
 * @param component thirdparties-list | dossiers-list | documents-list
 * @param shouldSave true | false
 */
export function useColumnsVisibility(
  component,
  shouldSave = true,
) {
  const [initialColumnsVisibility, setInitialColsVisibility] = useState();
  const [columnsVisibility, setColumnsVisibility] = useState();
  const [columnsVisibilityInitialized, setColumnsVisibilityInitialized] = useState(false);
  const accountId = useSelectedAccountId();

  // Set initial columns visibility if not already initialized
  const setInitialColumnsVisibility = useCallback((model) => {
    if(!columnsVisibilityInitialized) {
      setInitialColsVisibility(model);
      setColumnsVisibilityInitialized(true);
    }
  }, [columnsVisibilityInitialized]);

  // Set Columns visibility and save it to session if active
  const saveColumnsVisibility = useCallback((model) => {
    if (shouldSave) {
      sessionStorage.setItem(`${accountId}-${component}-columnsVisibility`, JSON.stringify(model));
    }
    setColumnsVisibility(model);
  }, [accountId, shouldSave, component]);

  // Set Columns visibility from session is active or from initialVisibility otherwise
  useEffect(() => {
    if (columnsVisibilityInitialized) {
      const savedColumnsVisibility = JSON.parse(sessionStorage.getItem(`${accountId}-${component}-columnsVisibility`));
      if(savedColumnsVisibility) {
        setColumnsVisibility(savedColumnsVisibility);
      } else {
        setColumnsVisibility(initialColumnsVisibility);
      }
    }
  }, [accountId, component, initialColumnsVisibility, columnsVisibilityInitialized]);

  return {
    columnsVisibility,
    setColumnsVisibility: saveColumnsVisibility,
    setInitialColumnsVisibility
  };
}
