import {find, findKey} from "lodash";

export const DueDiligenceStatus = Object.freeze({
  PENDING: "pending",
  SCREENING_IN_PROGRESS: "screening_in_progress",
  FPM_IN_PROGRESS: "fpm_in_progress",
  FPM_DONE: "fpm_done",
});

export const InduedReportStatus = Object.freeze({
  IN_PROGRESS: "in_progress",
  SUCCEEDED: "succeeded",
});

export const InduedScores = Object.freeze([
  "country_score",
  "activity_score",
  "pep_score",
  "sanctions_score",
  "adverse_media_score",
]);

export const InduedAllScores = Object.freeze([
  "risk_level",
  ...InduedScores
]);

export const InduedSeverityEnum = Object.freeze({
  NOT_STARTED: "-1",
  UNKNOWN: "0",
  LOW: "1",
  MEDIUM: "2",
  HIGH: "3"
})

export const induedScoreSeverity = (score) => {
  switch (score) {
    case 1:
      return {
        label: "low",
      };
    case 2:
      return {
        label: "medium",
      };
    case 3:
      return {
        label: "high",
      };
    default:
      return {
        label: "unknown",
      };
  }
};

export const isInduedDone = (induedData) => {
  return induedData?.due_diligence?.status === DueDiligenceStatus.FPM_DONE && 
    induedData?.report_data?.status === InduedReportStatus.SUCCEEDED;
}

export const formatInduedRiskLevel = (indued) => {
  if(!indued?.created_date) return "not_started";
  const riskLevel = indued?.due_diligence?.risk_level;
  return induedScoreSeverity(riskLevel).label;
}

export const thirdpartyInduedFilterPredicate = (indued, filter) => {
  // No Screening
  if(filter.type === "risk_level" && filter.id === InduedSeverityEnum.NOT_STARTED && (!indued || !indued.created_date )) {
    return true;
  }
  // tp has indued
  if(indued?.created_date) {
    // Tps with no due_diligence are considered unknown
    if(filter.id === InduedSeverityEnum.UNKNOWN && !indued.due_diligence) {
      return true;
    }
    // Score matches the filter
    if(indued.due_diligence?.[filter.type] === +filter.id) {
      return true;
    }
  }
  return false;
}

export const splitInduedFiltersByScore = (filters) => {
  return filters?.reduce((res, filter) => {
    const { type } = filter;
    const scoreObj = find(res, {score: type});
    const value = findKey(InduedSeverityEnum, (value) => value === filter.id);
    if (!scoreObj) {
      res.push({score: type, values: [value]})
    } else {
      scoreObj.values.push(value)
    }
    return res;
  }, []);
}