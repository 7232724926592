import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  mapChart: {
    width: "100%",
    height: "500px",
  },
  root: {
    position: "absolute",
    top: "25px",
    right: "23px",
    display: "flex",
    gap: "6px",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "26px",
    width: "26px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  resizeWrapper: {
    backgroundColor: "#F2F3F7",
  },
  cropWrapper: {
    border: "1px solid #C6CBD9",
  },
}));

export default useStyles;
