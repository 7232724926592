import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  header: {
    border: "1.5px solid #FFFFFF",
    borderRadius: "10px",
    background: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.492) 0%, rgba(255, 255, 255, 0.48) 110.84%)",
    marginBottom: "9px",
    height: "136px",
    padding: "0 30px",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    gap: "30px",
    boxShadow: "rgba(17, 12, 31, 0.05) 0px 7px 38px 0px",
  },
  mainContent: {
    display: "flex",
    gap: "8px 16px",
    width: "100%",
    paddingTop: "15px",
    maxHeight: "calc(100vh - 248px)",
  },
  docViewer:{
    flex: 1,
    zIndex: 2,
    "& embed": {
      maxHeight: "100%",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 31, 0.05) 0px 7px 38px 0px",
      border: "1px solid #32363917",
    },
  },
  infoPanel: {
    backgroundColor: "white",
    borderRadius: "10px",
    flex: 1.3,
    zIndex: 2,
    boxShadow: "rgba(17, 12, 31, 0.05) 0px 7px 38px 0px",
  },
  infoPanelDocViewer: {
    backgroundImage: "url(/img/icons/document_grey2.svg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "70px",
    backgroundBlendMode: "color-burn",
  },
  docMulti: {
    maxHeight: '300px',
    overflow: 'auto',
    marginBottom: '15px',
  },
  vDivider: {
    height: "89px",
    width: "1px",
    border: "1px solid " + theme.palette.grey.grey200,
  },
  hDivider: {
    width: "calc(100% - 45px)",
    margin: "16px 22.5px 0 22.5px",
    borderTop: "0.5px solid " + theme.palette.grey.grey200,
  },
  tab: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 700,
    minWidth: "110px",
  },
  unselectedTab: {
    color: theme.palette.grey.grey600,
    borderBottom: "2px solid " + theme.palette.grey.grey200,
  },
  selectedTab: {
    color: theme.palette.primary.primary600,
    borderBottom: "2px solid " + theme.palette.primary.primary600,
  },
  tabsWrapper: {
    paddingLeft: "20px",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.primary600 + " !important",
    },
  },
  panelWrapper: {
    padding: "14px 21px",
  },
  block_de_droite: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "1",
    gap: "10px",
  },
  block_de_gauche: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flex: "1"
  },
  answerBtn: {
    marginRight: "15px",
  },
  iconImg: {
      marginRight: "5px",
  },
  auditTrailBtn: {
    textTransform: "none",
    fontWeight: "600",
    fontSize: "12px",
    padding: "6px 10px",
    minWidth: "82px",
    minHeight: "28px",
    lineHeight: "10px",
    backgroundColor: "rgb(242, 243, 247)",
    color: "rgb(101, 101, 117)",
    border: "none",
    borderRadius: "6px",
  },
  auditBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px  15px",
  },

  auditTrailBtnDocViewer: {
    minHeight: "20px !important",
    padding: "6px 12px !important",
    height: "28px !important",
    borderRadius: "10px !important",
    whiteSpace: "nowrap !important",
  },
  auditBtnLoading: {
    cursor: "wait !important",
  },
  docPlaceholder: {
    width: "100%", 
    height: "68vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F7F8FC"
  }
}));

export default useStyles;
