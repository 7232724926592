import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "8px",
    backgroundColor: theme.palette.primary.primary100,
    marginTop: "24px",
    marginBottom: "16px",
    gap: "5px 3px",
  },
  containerWithoutBg: {
    marginTop: "10px",
    marginBottom: "10px",
    gap: "6px 3px",
  },
  chip: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px",
    gap: "5px",
    backgroundColor: "#FFF",
    border: "1px solid #d3d3d34f",
    borderRadius: "16px",
    flex: "none",
    order: 0,
    flexGrow: 0,
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 1px",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "#5353530d",
      borderColor: "#a6a6a61f",
      cursor: "pointer",
      transition: "all 0.3s",
      "& $deleteIcon": {
        opacity: "1 !important",
        transition: "all 0.3s",
      },
    },
    "&:active": {
      boxShadow: "rgb(0 0 0 / 11%) 0px 6px 24px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 1px",
      transition: "all 0.3s",
    },

  },
  chipText: {
    color: theme.palette.grey.grey500,
    fontWeight: 400,
    fontSize: "12px",
    display: "flex",
    flexWrap: "nowrap",
  },
  chipKeyLabel: {
    ...theme.typography.textOverflow,
    fontWeight: 700,
    maxWidth: "100px",
  },
  chipKeyValue: {
    ...theme.typography.textOverflow,
    maxWidth: "100px",
  },
  deleteIcon: {
    display: "flex",
    width: "9px",
    opacity: 0.4,
  },
}));

export default useStyles;
