import React, { useState, useContext, useCallback, useEffect, useMemo } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import i18next from "i18next";
import _ from "lodash";

import useStyles from "style/js-style/containers/administration/roles/privileges/PrivilegesPanelStyle";
import { Context } from "states/Store";
import { SUCCESS, ERROR } from "services/common/Constants"
import EATabPanel from "components/common/EATabPanel";
import EAIcon from "components/common/EAIcon";
import ConfigurationDrop from "containers/administration/roles/privileges/common/ConfigurationDrop";
import ConfigurationLine from "containers/administration/roles/privileges/common/ConfigurationLine";
import {
  getPrivilegeAscendants,
  getPrivilegeDescendants,
  RolePrivilegesEditorContext,
  isEventPrivilege,

  THIRD_PARTY_ATTRIBUTE_MANAGEMENT_ACTIONS,
  DOSSIER_ATTRIBUTE_MANAGEMENT_ACTIONS,
  RULE_MANAGEMENT_ACTIONS,
  ROLE_MANAGEMENT_ACTIONS,
  USER_MANAGEMENT_ACTIONS,
  DOSSIER_EVENT_MANAGEMENT,
  DOCUMENT_EVENT_MANAGEMENT,
  THIRD_PARTY_MANAGEMENT_ACTIONS,
  DOSSIER_MANAGEMENT_ACTIONS,
  DOCUMENT_MANAGEMENT_ACTIONS,
  CONTACT_MANAGEMENT_ACTIONS,
  PrivilegeEnum,
  RoleBasedAccessControlContext,
  INDUED_MANAGEMENT_ACTIONS, 
  CALCULATION_MANAGEMENT_ACTIONS,
  TRANSPARENCY_MANAGEMENT_ACTIONS,
  FINANCIAL_STRENGTH_MANAGEMENT_ACTIONS
} from "services/common/RolesUtils";
import {
  updateRolePrivileges,
  getRolePrivileges,
} from "services/edge/RoleService";
import EAButton from "components/common/EAButton";
import RoleScopes from "./common/RoleScopes";
import EventPrivilegeBlock from "./common/EventPrivilegeBlock";

const PrivilegesPanel = ({ accountActivities, accountCountries, accountThirdpartyAttributes, accountDossierAttributes, role, isSelectedRoleReadOnly, onValidateSuccess = () => { }, accountCalculatedAttributes, accountIndicators }) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [rolePrivileges, setRolePrivileges] = useState(role ? role.flatPrivileges : []);
  const [baseRolePrivileges, setBaseRolePrivileges] = useState([]);
  const [submitHandler, setSubmitHandler] = useState(null);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const fetchBaseRolePrivileges = useCallback(async (accountId, roleId) => {
    try {
      const resp = await getRolePrivileges(accountId, roleId, null);

      setBaseRolePrivileges(resp.content);
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (state.account && role) {
      setRolePrivileges(role.flatPrivileges);

      if (role.baseRoleId !== null) {
        fetchBaseRolePrivileges(state.account.id, role.baseRoleId);
      }
    }
  }, [state.account, role, fetchBaseRolePrivileges]);

  const hasCurrentAnyOfPrivileges = useCallback((...privileges) => {
    if (!_.isEmpty(rolePrivileges)) {
      return privileges.some(priv => rolePrivileges.map(privilege => privilege.name).includes(priv.name) || [...getPrivilegeAscendants(priv)].some(asc => rolePrivileges.map(privilege => privilege.name).includes(asc)));
    }

    return false;
  }, [rolePrivileges])

  const hasCurrentAnyOfPrivilegeRessources = useCallback((ressourceId, ...locPrivileges) => {
    if (!_.isEmpty(rolePrivileges)) {
      return locPrivileges.some(priv => rolePrivileges.map(privilege => privilege.name)?.includes(priv.name) && rolePrivileges.find(privilege => privilege.name === priv.name).ressources?.includes(ressourceId));
    }
    return false;
  }, [rolePrivileges])

  const hasCurrentAllOfPrivilegeRessources = useCallback((ressourcesList, ...locPrivileges) => {
    if (!_.isEmpty(rolePrivileges) && ressourcesList?.length > 0) {
      return locPrivileges.every(priv => rolePrivileges.map(privilege => privilege.name)?.includes(priv.name) && ressourcesList.every(ressource => rolePrivileges.find(privilege => privilege.name === priv.name).ressources?.includes(ressource)));
    }
    return false;
  }, [rolePrivileges])

  const hasCurrentAllOfPrivileges = useCallback((...privileges) => {
    return privileges.every(p => hasCurrentAnyOfPrivileges(p));
  }, [hasCurrentAnyOfPrivileges])

  const hasBaseAnyOfPrivileges = useCallback((...privileges) => {
    if (role.baseRoleId === null) {
      return true;
    }

    if (!_.isEmpty(baseRolePrivileges)) {
      return privileges.some(priv => baseRolePrivileges.map(privilege => privilege.name).includes(priv.name) || [...getPrivilegeAscendants(priv)].some(asc => baseRolePrivileges.map(privilege => privilege.name).includes(asc)));
    }

    return false;
  }, [role, baseRolePrivileges]);

  const hasBaseAllOfPrivileges = useCallback((...privileges) => {
    return privileges.every(p => hasBaseAnyOfPrivileges(p));
  }, [hasBaseAnyOfPrivileges]);

  const togglePrivileges = useCallback((...privileges) => {
    setRolePrivileges(prev => {
      privileges.forEach((privilege) => {
        if (hasCurrentAnyOfPrivileges(privilege)) {
          prev = prev.filter(priv => priv.name !== privilege.name);
          getPrivilegeAscendants(privilege).forEach((asc) => prev = prev.filter(priv => priv.name !== asc));
          if(isEventPrivilege(privilege.name)) { // For Event Privileges, we remove also child privileges for checkbox purposes
            getPrivilegeDescendants(privilege).forEach((desc) => prev = prev.filter(priv => priv.name !== desc));
          }
        } else {
          prev.push(privilege);
          getPrivilegeDescendants(privilege).forEach((desc) => pushIfNotExists(prev, desc));
        }
      });
      return Array.from(prev);
    })
  }, [hasCurrentAnyOfPrivileges]);

  const selectAll = (...privileges) => {
    setRolePrivileges(prev => {
      privileges.forEach((privilege) => {
        if (!hasCurrentAnyOfPrivileges(privilege) && hasBaseAnyOfPrivileges(privilege)) {
          prev.push(privilege);
        }
        getPrivilegeDescendants(privilege).forEach((desc) => {
          hasBaseAnyOfPrivileges(PrivilegeEnum[desc]) && pushIfNotExists(prev, desc)
        });
      });
      return Array.from(prev);
    })
  }

  const unselectAll = (...privileges) => {
    setRolePrivileges(prev => {
      privileges.forEach((privilege) => {
        prev = prev.filter(priv => priv.name !== privilege.name);
        getPrivilegeAscendants(privilege).forEach((asc) => prev = prev.filter(priv => priv.name !== asc));
        if(isEventPrivilege(privilege.name)) { // For Event Privileges, we remove also child privileges for checkbox purposes
          getPrivilegeDescendants(privilege).forEach((desc) => prev = prev.filter(priv => priv.name !== desc));
        }
      });
      return Array.from(prev);
    })
  }

  const pushIfNotExists = (prev, name) => {
    const exists = prev.some((priv) => priv.name === name);
    if(!exists) {
      prev.push({ name, ressources: [] })
    }
  }


  const togglePrivilegesForAllRessources = useCallback((ressourcesList, ...privileges) => {
    setRolePrivileges(prev => {
      privileges.forEach((privilege) => {
        const grantedPrivilege = prev.find(priv => priv.name === privilege.name);
        if (grantedPrivilege) {
          if (!hasCurrentAllOfPrivilegeRessources(ressourcesList, privilege)) {
            grantedPrivilege.ressources = ressourcesList;
          } else {
            grantedPrivilege.ressources = [];
          }
        } else {
          prev.push({ name: privilege.name, ressources: ressourcesList });
        }
      });
      return Array.from(prev);
    })
  }, [hasCurrentAllOfPrivilegeRessources, setRolePrivileges]);

  const toggleSubPrivilege = useCallback((ressourceId, ...privileges) => {
    setRolePrivileges(prev => {
      privileges.forEach((privilege) => {
        const grantedPrivilege = prev.find(priv => priv.name === privilege.name);
        if (grantedPrivilege) {
          if (hasCurrentAnyOfPrivilegeRessources(ressourceId, privilege)) {
            grantedPrivilege.ressources = grantedPrivilege.ressources.filter(ressource => ressource !== ressourceId);
          } else {
            grantedPrivilege.ressources = grantedPrivilege.ressources || [];
            grantedPrivilege.ressources.push(ressourceId);
          }
        } else {
          prev.push({ name: privilege.name, ressources: [ressourceId] });
        }
      });
      return Array.from(prev);
    })
  }, [hasCurrentAnyOfPrivilegeRessources, setRolePrivileges]);

  const grantPrivileges = useCallback((...privileges) => {
    setRolePrivileges(prev => {
      privileges.forEach((privilege) => {
        prev.push(privilege);
        getPrivilegeDescendants(privilege).forEach((desc) => prev.push({ name: desc, ressources: [] }));
      });
      return Array.from(prev);
    });
  }, [setRolePrivileges]);

  const revokePrivileges = useCallback((...privileges) => {
    setRolePrivileges(prev => {
      privileges.forEach((privilege) => {
        prev = prev.filter(priv => priv.name !== privilege.name);
        getPrivilegeAscendants(privilege).forEach((asc) => prev = prev.filter(priv => priv.name !== asc));
      });
      return Array.from(prev);
    });
  }, [setRolePrivileges]);

  const rolePrivilegesEditorContext = useMemo(
    () => ({
      isSelectedRoleReadOnly: isSelectedRoleReadOnly
    }),
    [isSelectedRoleReadOnly]
  );

  const handleTabChange = (event, newValue) => setSelectedTab(newValue);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSave = async () => {
    try {
      if (submitHandler !== null) {
        submitHandler();
      }

      await updateRolePrivileges(state.account.id, role.id, { flatPrivileges: rolePrivileges }, dispatch);

      dispatch({
        type: "ALERT",
        alert: {
          type: SUCCESS,
          msg: "Les modifications ont bien été enregistrées.",
        },
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: {
          type: ERROR,
          msg: "Erreur interne.",
        },
      });
    }
  }

  return (
    !role ? (
      <></>
    ) : (
      <RolePrivilegesEditorContext.Provider value={rolePrivilegesEditorContext}>
        <div className={classes.rulesPanel} >
          <div className={classes.rulesPanelHeader}>

            {/* -------------- */}
            {/* --- HEADER --- */}
            {/* -------------- */}
            <EAButton
              onClick={() => handleSave()}
              content={<EAIcon icon="save" />}
              className={classes.saveButton}
              disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_ROLE)}
              customStyle={{ padding: "0 19px", minWidth: "30px" }}
            />
            <Box className={classes.tabsWrapper} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="roles tabs"
                centered
                style={{ marginRight: "50px" }}
              >
                <Tab
                  label={i18next.t("roles.tabs.configuration")}
                  icon={<EAIcon icon="roles/configuration" style={{ filter: selectedTab === 0 && "brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(336%) hue-rotate(79deg) brightness(96%) contrast(97%)" }} />}
                  iconPosition="start"
                  {...a11yProps(0)}
                  className={classes.tab}
                />
                <Tab
                  label={i18next.t("roles.tabs.accountScopes")}
                  icon={<EAIcon icon="roles/accountScopes" style={{ filter: selectedTab === 1 && "brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(336%) hue-rotate(79deg) brightness(96%) contrast(97%)" }} />}
                  iconPosition="start"
                  {...a11yProps(1)}
                  className={classes.tab}
                />
                <Tab
                  label={i18next.t("roles.tabs.events")}
                  icon={<EAIcon icon="roles/events" style={{filter: selectedTab === 2 && "brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(336%) hue-rotate(79deg) brightness(96%) contrast(97%)"}}/> }
                  iconPosition="start"
                  {...a11yProps(2)}
                  className={ classes.tab }
                />
                <Tab
                  label={i18next.t("roles.tabs.features")}
                  icon={<EAIcon icon="roles/features" style={{ filter: selectedTab === 3 && "brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(336%) hue-rotate(79deg) brightness(96%) contrast(97%)" }} />}
                  iconPosition="start"
                  {...a11yProps(3)}
                  className={classes.tab}
                />
                <Tab
                  label={i18next.t("roles.tabs.sources")}
                  icon={<EAIcon icon="roles/sources" style={{ filter: selectedTab === 4 && "brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(336%) hue-rotate(79deg) brightness(96%) contrast(97%)" }} />}
                  iconPosition="start"
                  {...a11yProps(4)}
                  className={classes.tab}
                />
              </Tabs>
            </Box>
          </div>

          {/* --------------- */}
          {/* --- CONTENT --- */}
          {/* --------------- */}
          <div className={classes.rulesPanelMainContent}>

            {/* Configuration */}
            <EATabPanel value={selectedTab} index={0} variant={"withoutBackground"}>
              <ConfigurationLine
                title={i18next.t("roles.label.thirdpartiesAttributes")}
                actions={THIRD_PARTY_ATTRIBUTE_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
              <ConfigurationLine
                title={i18next.t("roles.label.dossierAttributes")}
                actions={DOSSIER_ATTRIBUTE_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
              <ConfigurationLine
                title={i18next.t("roles.label.smartFlow")}
                actions={RULE_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
              <ConfigurationLine
                title={i18next.t("roles.label.smartPilot")}
                actions={CALCULATION_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
              <ConfigurationLine
                title={i18next.t("roles.label.roles")}
                actions={ROLE_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
              <ConfigurationLine
                title={i18next.t("roles.label.users")}
                actions={USER_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
            </EATabPanel>

            {/* Périmètres du compte */}
            <EATabPanel value={selectedTab} index={1} variant={"withoutBackground"}>
              <RoleScopes
                setSubmitHandler={setSubmitHandler}
                onValidateSuccess={onValidateSuccess}
                role={role}
                accountActivities={accountActivities}
                accountCountries={accountCountries}
                accountThirdpartyAttributes={accountThirdpartyAttributes}
                accountDossierAttributes={accountDossierAttributes}
                accountCalculatedAttributes={accountCalculatedAttributes}
                accountIndicators={accountIndicators}
              />
            </EATabPanel>

            {/* Évènements */}
            <EATabPanel value={selectedTab} index={2} variant={"withoutBackground"}>
              <EventPrivilegeBlock
                variant="roles"
                data={DOSSIER_EVENT_MANAGEMENT(togglePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges, hasCurrentAllOfPrivileges, hasBaseAllOfPrivileges, selectAll, unselectAll)}
              />
              <EventPrivilegeBlock
                variant="roles"
                data={DOCUMENT_EVENT_MANAGEMENT(togglePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges, hasCurrentAllOfPrivileges, hasBaseAllOfPrivileges, selectAll, unselectAll)}
              />
            </EATabPanel>

            {/* Fonctionnalités */}
            <EATabPanel value={selectedTab} index={3} variant={"withoutBackground"}>
              <ConfigurationDrop
                title="TIERS"
                actions={THIRD_PARTY_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges)}
                type="dropAction"
              />
              <ConfigurationDrop
                title="DOSSIERS"
                actions={DOSSIER_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges)}
                type="dropAction"
              />
              <ConfigurationDrop
                title="DOCUMENTS"
                actions={DOCUMENT_MANAGEMENT_ACTIONS(togglePrivileges, toggleSubPrivilege, togglePrivilegesForAllRessources, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasCurrentAnyOfPrivilegeRessources, hasCurrentAllOfPrivilegeRessources, hasBaseAnyOfPrivileges)}
                type="dropActionPerDoc"
                authorizedDocumentTypes={role.authorizedDocumentTypes}
              />
              <ConfigurationLine
                title="CONTACTS"
                actions={CONTACT_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
            </EATabPanel>

            {/* Sources */}
            <EATabPanel value={selectedTab} index={4} variant={"withoutBackground"}>
              <ConfigurationLine
                title="indueD"
                variant="caseSensitive"
                translation="false"
                actions={INDUED_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
            </EATabPanel>
            <EATabPanel value={selectedTab} index={4} variant={"withoutBackground"}>
              <ConfigurationLine
                title="Transparency"
                variant="caseSensitive"
                translation="false"
                actions={TRANSPARENCY_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
            </EATabPanel>
            <EATabPanel value={selectedTab} index={4} variant={"withoutBackground"}>
              <ConfigurationLine
                title={i18next.t("financialStrength.label")}
                variant="caseSensitive"
                translation="false"
                actions={FINANCIAL_STRENGTH_MANAGEMENT_ACTIONS(togglePrivileges, grantPrivileges, revokePrivileges, hasCurrentAnyOfPrivileges, hasBaseAnyOfPrivileges).rootActions}
              />
            </EATabPanel>

          </div>
        </div>
      </RolePrivilegesEditorContext.Provider>
    )
  )
}

export default PrivilegesPanel;
