import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef
} from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import _ from "lodash";
import { getTooltip } from "services/common/ChartUtils";
import i18next from "i18next";
import { Context } from "states/Store";
import useSelectedAccountId from "../hooks/useSelectedAccountId";
import { localizeTranslate } from "i18n/LocalizeUtils";

function EABarXChart({ id, data, tab, subTab = null, seriesItems }) {
  const chartRef = useRef();
  const [state, dispatch] = useContext(Context);
  const accountId = useSelectedAccountId();

  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new(id);
    if (data && !_.isEmpty(data)) {

      let maxValue = data.reduce((prev, curr) => prev.value > curr.value ? prev : curr);
      // Set themes
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingRight: 40,
        paddingBottom: 0
      }));


      // Remove the comma to separate hundreds
      root.numberFormatter.setAll({
        numberFormat: "#",
        numericFields: ["valueY"]
      });


      // X Axis
      let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: 0,
        centerY: am5.p50,
        centerX: am5.p50,
        paddingRight: 0,
        fill: am5.color("#656575"),
        fontSize: "15px",
      });

      xRenderer.grid.template.setAll({
        location: 1,
        stroke: "#E2E2EA"
      });

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "interval",
        renderer: xRenderer
      }));

      // X Axis title
      xAxis.children.push(
        am5.Label.new(root, {
          text: localizeTranslate(i18next.t("transparency.scoreCPI")),
          x: am5.p100,
          centerX: am5.p50,
          fill: am5.color("#656575"),
        })
      );

      // Y Axis
      let yRenderer = am5xy.AxisRendererY.new(root, { 
        strokeOpacity: 0.1,
        stroke: "#E2E2EA" 
      });
      yRenderer.labels.template.setAll({
        visible: false
      });

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: maxValue.value + 5,
        renderer: yRenderer
      }));


      // Create series
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "interval"
      }));
      series.columns.template.setAll({
        toggleKey: "active",
        interactive: true,
      });
     
      series.set("tooltip", getTooltip(chart));

      series.columns.template.setAll({
        tooltipText: `[fontFamily: Roboto fontSize: 12px]${localizeTranslate('Nombre')} : {valueY}/{totalCount}\n${localizeTranslate('Pourcentage')} : {totalPercent}%[/]`,
        tooltipX: am5.percent(50), //the X and Y refer to the position of the tooltip, not the axis to which it applies 
        tooltipY: am5.percent(-10),
        strokeOpacity: 0,
        cursorOverStyle: "pointer",
      });

      series.columns.template.states.create("hover", {
        fillOpacity: 0.5,
      });
      series.columns.template.states.create("active", {
        fillOpacity: 1,
        fill: am5.color("#eda097"),
      });
      series.columns.template.events.on("click", (ev) => {
        const rectangle = ev.target;
        const selectedData = rectangle.dataItem.dataContext;
        const active = !rectangle.get("active");
        dispatch({
          type: active ? "ADD_GRAPH_FILTER" : "REMOVE_GRAPH_FILTER",
          filter: {
            type: "bar",
            accountId,
            tab,
            subTab,
            data: {
              id: selectedData.code,
              label: selectedData.interval,
              keyLabel: i18next.t(
                `dashboard.filters.${selectedData.type}`
              ),
              valueMin: selectedData.interval.split(" - ")[0],
              valueMax: selectedData.interval.split(" - ")[1]
            },
          },
        });
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Label.new(root, {
            centerX: am5.p50,
            centerY: am5.percent(75),
            text: "{valueY}",
            fontFamily: "Metric",
            fontWeight: "600",
            fontSize: "20px",
            numberFormat: "#",
            populateText: true
          })
        });
      });

      series.columns.template.setAll({
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        strokeOpacity: 0,
        templateField: "settings" // Get the color from the "settings" field in the data
      });


      series.columns.template.adapters.add("stroke", function (stroke, target) {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
      });

      let label2 = chart.plotContainer.children.push(am5.Label.new(root, {
        text: localizeTranslate(i18next.t("transparency.nb_thirdparties")),
        fontSize: "16px",
        fill: am5.color("#656575"),
        opacity: 1
      }));
      label2.set("dx", -12);
      label2.set("dy", -29);

      xAxis.data.setAll(data);
      series.data.setAll(data);


      // Make stuff animate on load
      series.appear();
      chart.appear(1000, 100);
      chartRef.current = chart;
    }

    return () => root?.dispose();
  }, [id, data, accountId, dispatch, tab, subTab]);

  // Set active selected items.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (data && !_.isEmpty(data)) {

        const series = chartRef.current.series;
        series?.values?.forEach((column) => {
          column.dataItems.forEach((item) => {
            const intervalKey = item.dataContext.code; 
            const rectangle = item.get("graphics");
            if (state.graphFilters.bar?.length > 0) {
                // check if specific bar is in filter => activate/deactivate it
                const isActive = !!state.graphFilters.bar.find(
                  (filter) => filter.id === `${intervalKey}`
                );
                rectangle?.set("active", isActive);
            } else {
              // If no filter is active => deactivate all graph
              rectangle?.set("active", false);
            }
          });
        });
      }
    }, 500);
    return () => clearTimeout(timer);
    
    // chartRef dependency needed to rerun effect when rectangle is set to set Active filters from session storage
  }, [data, chartRef.current?.series, state.graphFilters.bar]);

  return (
      <div
        style={{
          height: "500px",
        }}
      >
        <div
          style={{
            height: "450px",
            overflowY: data?.length > 8 ? "auto" : "hidden",
            overflowX: "hidden",
            display: "flex",
            alignItems: data?.length > 8 ? "flex-start" : "center",
          }}
        >
          <div
            id={id}
            style={{ width: "1400px", height: "450px" }}
            onWheel={(e) => e.stopPropagation()}
          ></div>
        </div>
      </div>
  );
}

export default EABarXChart;
