import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";

export async function getCurrentUser(dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    "365/user/current"
  );
}

export async function getAccountUsers(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/users`
  );
}

export async function getUserByAccountAndId(accountId, userId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/users/${userId}`
  );
}

export async function createAccountUsers(accountId, userToCreate, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/users`,
    userToCreate
  );
}

export async function updateAccountUser(
  accountId,
  userId,
  userToUpdate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `365/account/${accountId}/users/${userId}`,
    userToUpdate
  );
}

export async function getUserRoles(accountId, userId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/users/${userId}/roles`
  );
}

export async function updateUserRole(
  accountId,
  userId,
  userToUpdate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `365/account/${accountId}/users/${userId}/profile`,
    userToUpdate
  );
}

export async function changePassword(
  accountId,
  userId,
  currentPassword,
  newPassword,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `365/account/${accountId}/users/${userId}/password`,
    {
      currentPassword: currentPassword,
      newPassword: newPassword,
    }
  );
}

export async function deleteAccountUsers(accountId, userIds, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `365/account/${accountId}/users`,
    userIds
  );
}

export async function verifyUserPassword(accountId, userId, password, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/users/${userId}/password/verify`,
    { currentPassword: password }
  );
}

export async function deleteAccountUser(accountId, userId, password, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `365/account/${accountId}/users/${userId}`,
    { currentPassword: password }
  );
}

export async function reinviteAccountUsers(accountId, userId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/users/${userId}/reinvite`
  );
}

export async function manageRolesFromIdp(dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/users/manageRoleFromIdp`
  );
}

export async function updateUserLanguage(lang, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/users/language`,
    {newLang: lang}
  );
}