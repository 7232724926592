import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  feedBackWrapper: {
    whiteSpace: "pre-line",
  },
  feedBackCross: {
    position: "absolute",
    top: "23px",
    right: "23px",
    cursor: "pointer",
  },
  feedBackMessageWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  feedBackTitle:{
    marginBottom: "35px",
  },
  feedBackContentGeneral:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "39px",
    height: "39px",
    marginRight: "17px",
    marginLeft: "8px",
  },
  feedBackContent:{
    backgroundColor: "#FFEBEB",
  },
  feedBackContentValid:{
    backgroundColor: "#D7F4DC",
  },
  feedBackContentGrey: {
    backgroundColor: theme.palette.grey.grey100,
  },
  feedBackImg:{
    width: "25px",
  },
  feedBackImgSmall:{
    width: "14px",
  },
  contentMessage: {
    fontSize: "14px",
    color: "#7E7E8F",
  },
}));

export default useStyles;
