import React, { useEffect, useState } from "react";
import useStyles from "../../style/js-style/containers/doc-viewer/IndexStyle";
import EAIcon from "../../components/common/EAIcon";
import clsx from "clsx";
import i18next from "i18next";
import EADataGrid, { EADataGridCell } from "components/common/datatable/EADataGrid";
import DataTableActions from "components/common/datatable/DataTableActions";
import { DATA_TABLE_ACTIONS } from "services/common/Constants";
import { formatDate } from "services/common/Utils";

const onActionClick = (document,
  setRowSelectionModel,
  onSelectMultipleEvidence,
  setShowPanelStatus) => {
  setShowPanelStatus(document?.statusReason === undefined);
  setRowSelectionModel([document.id]);
  onSelectMultipleEvidence(document);
}

const accountDocumentsTableActions = (
  document,
  setRowSelectionModel,
  onSelectMultipleEvidence,
  setShowPanelStatus
) => {

  return [
    {
      label: i18next.t(DATA_TABLE_ACTIONS.VIEW.tooltip),
      isDisabled: false,
      isHidden: false,
      icon: DATA_TABLE_ACTIONS.VIEW.icon,
      keepWidth: true,
      action: () => onActionClick(document,
        setRowSelectionModel,
        onSelectMultipleEvidence,
        setShowPanelStatus)
    }
  ];
};

export const evidenceMultiColumns = (setRowSelectionModel, onSelectMultipleEvidence, setShowPanelStatus) => [
  {
    field: "referenceValue",
    filterable: false,
    headerName: i18next.t("viewer.multiple.reference"),
    flex: 0.17,
    renderCell: (params) => (
      <EADataGridCell
        disableTranslation
      >
        {params.value}
      </EADataGridCell>
    ),
  },
  {
    field: "uploadDate",
    filterable: false,
    headerName: i18next.t("myDocuments.depositDate"),
    flex: 0.15,
    type: "date",
    valueFormatter: (params) => formatDate(params.value),
    renderCell: (params) => (
      <EADataGridCell
        disableTranslation
      >
        {formatDate(params.value)}
      </EADataGridCell>
    ),
  },
  {
    field: "expirationDate",
    filterable: false,
    headerName: i18next.t("viewer.multiple.expirationDate"),
    flex: 0.15,
    type: "date",
    valueFormatter: (params) => formatDate(params.value),
    renderCell: (params) => (
      <EADataGridCell
        disableTranslation
      >
        {formatDate(params.value)}
      </EADataGridCell>
    ),
  },
  {
    field: "statusReason",
    headerName: i18next.t("viewer.multiple.statusReason"),
    flex: 0.17,
    renderCell: (params) => (
      <EADataGridCell
        tooltip={false}
        disableTranslation
      >
        {formatStatusReason(params)}
      </EADataGridCell>
    ),
  },
  {
    field: "action",
    headerName: i18next.t("actions"),
    flex: 0.07,
    sortable: false,
    filterable: false,
    disableExport: true,
    hideSortIcons: true,
    disableReorder: true,
    disableColumnMenu: true,
    disableMultipleSelection: true,
    disableSelectionOnClick: true,
    isRowSelectable: false,
    hideFooterSelectedRowCount: true,
    align: "center",
    renderCell: (params) => {
      return (
        <div className="dt" style={{ textAlign: "center" }}>
          <DataTableActions
            actions={accountDocumentsTableActions(
              params.row,
              setRowSelectionModel,
              onSelectMultipleEvidence,
              setShowPanelStatus
            )}
          />
        </div>
      );
    },
  },
];

function formatStatusReason(params) {
  if (params.value !== undefined) {
    let color;
    if (params.value === 'BELOW_EXPECTATION_CLIENT_REFUS' || params.value === 'BELOW_EXPECTATION_EXTRACTOR_REFUS') {
      color = "#6D00CC";
    } else {
      color = "#2D9F40";
    }
    return <span style={{ fontWeight: 700, color: color }}>{i18next.t(`viewer.status.validation.${params.value}`)}</span>;
  } else {
    return <span style={{ fontWeight: 700, color: "#CC7B00"}}>{i18next.t("viewer.multiple.needAction")}</span>;
  }
}

// Rendu du décompte des doc multiple
const formatNbMulitple = (nbMultiple) => {
  const styles = {
    root:             { display: "flex", alignItems: "center" },
    countDocWrapper:  { display: "flex", alignItems: "center", backgroundColor: "#EFFBF1", padding: "4px 6px", borderRadius: "6px", marginRight: "12px" },
    countDocText:     { color: "#373534", fontSize: "14px", fontWeight: 600, marginLeft: "2px" },
  };

  return (
    <div style={{...styles.root}}>
      { nbMultiple > 1 && (
          <div style={{...styles.countDocWrapper}}>
            <EAIcon icon={"document"} height="15px" width="15px" />
            <span style={{...styles.countDocText}}>{nbMultiple}</span>
          </div>
        )}
    </div>
  );
};

function PanelListMultiple({ listEvicenceMulti, selectedMulti, onSelectMultipleEvidence, setShowPanelStatus }) {
  const { classes } = useStyles();
  const [rowSelectionModel, setRowSelectionModel] = useState([selectedMulti?.id]);


  useEffect(() => {
      setRowSelectionModel([selectedMulti?.id]);
  }, [selectedMulti, setRowSelectionModel]);

  useEffect(() => {
    if (selectedMulti) {
      setShowPanelStatus(selectedMulti?.statusReason === undefined);
    }
  }, [selectedMulti, setShowPanelStatus]);

  return (
    <div className={clsx(classes.infoPanel, classes.docMulti)} >
      <EADataGrid
        inputRows={listEvicenceMulti}
        columns={evidenceMultiColumns(setRowSelectionModel, onSelectMultipleEvidence, setShowPanelStatus)}
        pageSize={10}
        variant={"sheet"}
        additionnalsLeftComponents={<span style={{ color: "#373534", fontSize: "16px", fontWeight: "600" }}>{i18next.t("viewer.multiple.title")}</span>}
        quickFilter
        selection={{
          hideCheckbox: true,
          items: rowSelectionModel,
          setItems: setRowSelectionModel,
        }}
        onRowClick={(row) => onActionClick(row,
          setRowSelectionModel,
          onSelectMultipleEvidence,
          setShowPanelStatus)}
        disableMultipleRowSelection={true}
        pagination={false}
        additionnalsComponents={formatNbMulitple(listEvicenceMulti.length)}
      />
    </div>
  );
}

export default PanelListMultiple;
