import React, { useCallback, useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import i18next from "i18next";
import _ from "lodash";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import EAIcon from "components/common/EAIcon";
import {
  CalculatorContext,
  getAvailableTargetFieldNatureOptions,
  getTargetFieldCategoryOptions,
  getTargetFieldOptionsForDocument,
  getTargetFieldOptionsForDossier,
  getTargetFieldOptionsForSourceVariable,
  getTargetFieldOptionsForThirdParty,
  getVariableModel,
  isCalculationReferencedByTargetCalculation,
  TargetFieldCategory,
} from "services/common/CalculatorUtils";
import CalculatorSelectInput from "components/calculator/CalculatorSelectInput";
import CalculatorStandardInput from "../../../../../components/calculator/CalculatorStandardInput";

export const TargetFieldContext = React.createContext({
  selectedTargetFieldCategory: null
});

export const VariableContext = React.createContext({
  onChange: null
});

const Variables = ({ 
  fieldName,
  onVariableChangeCallBacks, 
  removeDuplicateVariable, 
  addDuplicateVariable 
}) => {
  const { classes } = useStyles();

  const formMethods = useFormContext();
  const {
    setValue,
    setError,
    clearErrors,
    getValues
  } = formMethods;

  let variables = useWatch({ name: `${fieldName}` });

  // Appel toute les callbacks des critères de toutes les branches
  const callCallBacks = useCallback(async (currentVariable, action, value) => {
    for (const callback of Object.values(onVariableChangeCallBacks)) {
      if (typeof callback === 'function') {
        await callback(currentVariable, action, value);
      }
    }
  }, [onVariableChangeCallBacks]);

  // Add a new variable
  const onAddEntry = useCallback(() => {
    setValue("variables", [...variables, getVariableModel()]);
  }, [variables, setValue]);

  // En cas de suppression de la variable, supprime les critères liés
  const deleteDependentCriteria = useCallback((variableId) => {
    let newBranches = JSON.parse(JSON.stringify(getValues("branches")));

    newBranches.forEach((branch, branchIndex) => {
      branch.criteria?.forEach(criterion => {
        if (criterion.variableId === variableId) {
          newBranches[branchIndex].criteria = newBranches[branchIndex].criteria?.filter(item => item.id !== criterion.id) || [];
        }
      })
    })

    setValue("branches", newBranches);
  }, [setValue, getValues])

  // Delete a variable
  const onRemoveEntry = useCallback((index) => {
    const variableToDelete = _.nth(variables, index);
    callCallBacks(variableToDelete, "delete");

    let newVariables = variables;
    deleteDependentCriteria(variables[index].id);
    clearErrors(`variables.${index}`)
    _.pullAt(newVariables, [index]);
    setValue("variables", [...newVariables]);
  }, [variables, setValue, callCallBacks, deleteDependentCriteria, clearErrors]);

  const validateName = AwesomeDebouncePromise(async (value, fieldName) => {
    const CALCULATION_VARIABLE_NAME_MAX_LENGTH = 100;

    let isValid = false;
    let typeErrMsg = "";
    const rootFieldName = fieldName.slice(0, fieldName.indexOf('.name'));
    const variableId = getValues(rootFieldName).id;

    if (value.length === 0) {
      typeErrMsg = i18next.t("infos.required");
    } else if (value.length > CALCULATION_VARIABLE_NAME_MAX_LENGTH) {
      typeErrMsg = i18next.t(
        "calculator.errors.variableNameMaxLengthExceeded",
        {
          length: CALCULATION_VARIABLE_NAME_MAX_LENGTH,
        }
      );

    } else {
      let isNameUnique = _.size(_.filter(getValues("variables"), { name: value })) <= 1;

      if (!isNameUnique) {
        addDuplicateVariable(variableId);
        typeErrMsg = i18next.t("infos.alreadyExistVariable");
      } else {
        removeDuplicateVariable(variableId);
        isValid = true;
      }
    }

    if (isValid) {
      clearErrors(fieldName);
    } else {
      setError(fieldName, { type: "manual", message: typeErrMsg });
      return typeErrMsg;
    }
  }, 500);

  return (
    <>
      {formMethods.getValues("variables").map((item, index) => {
        return (
          <Variable
            key={index}
            fieldName={`variables.${index}`}
            onRemove={() => onRemoveEntry(index)}
            validateName={validateName}
            onVariableChangeCallBacks={callCallBacks}
          />
        )
      })}

      {/* Add a variable */}
      <div
        className={classes.addVariable}
        style={{ padding: "10px", width: "180px", cursor: "pointer" }}
        onClick={() => onAddEntry()}
      >
        <EAIcon icon={"cross_green"} extension="png" style={{ width: "10px" }} />
        <span> {i18next.t("calculator.dialog.create.addVariable")}</span>
      </div>
    </>
  );
}

const Variable = ({
  fieldName,
  onRemove,
  validateName,
  onVariableChangeCallBacks
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();
  const {
    formState: { errors }
  } = formMethods;

  let currentVariable = useWatch({ name: fieldName });

  const variableContext = React.useMemo(
    () => ({
      onVariableChangeCallBacks: onVariableChangeCallBacks,
      currentVariable: currentVariable,
    }),
    [
      onVariableChangeCallBacks,
      currentVariable
    ]
  );

  return (
    <div className={classes.variableLineRoot}>

      {/* Suppression de la variable */}
      <EAIcon
        icon={"highlight_off"}
        className={classes.variableLineCrossIcon}
        onClick={() => onRemove()}
      />

      <VariableContext.Provider value={variableContext}>
        {/* Nom de la variable */}
        <CalculatorStandardInput
          fieldName={`${fieldName}.name`}
          label={i18next.t("calculator.dialog.create.placeholder.variableName")}
          placeholder={i18next.t(`calculator.dialog.create.placeholder.variableName`)}
          validate={(value) => {
            return validateName(value, `${fieldName}.name`);
          }}
          className={
            !!_.get(errors, `${fieldName}.name`)
              ? classes.variableInputError
              : classes.variableInput
          }
          onChange={
            (newValue) => {
              onVariableChangeCallBacks(currentVariable, "name", newValue);
            }
          }
          variant="innerLabel"
          errorVariant="absolute"
        />

        <TargetField fieldName={`${fieldName}.targetField`} />
      </VariableContext.Provider>
    </div>
  );
}

const TargetField = ({ fieldName }) => {
  const { classes } = useStyles();
  const targetFieldCategoryOptions = getTargetFieldCategoryOptions();
  const { onVariableChangeCallBacks, currentVariable } = useContext(VariableContext);

  const formMethods = useFormContext();
  const {
    setValue,
    clearErrors
  } = formMethods;

  const selectedTargetFieldCategory = useWatch({ name: `${fieldName}.target_field_category` });

  const onTargetFieldCategoryChange = useCallback((newValue) => {
    setValue(`${fieldName}.target_field_data`, {});
    clearErrors(`${fieldName}.target_field_data`);
  }, [fieldName, setValue, clearErrors]);

  const targetFieldcontext = React.useMemo(
    () => ({
      selectedTargetFieldCategory: selectedTargetFieldCategory,
    }),
    [
      selectedTargetFieldCategory
    ]
  );

  return (
    <>
      <CalculatorSelectInput
        fieldName={`${fieldName}.target_field_category`}
        label={``}
        isRequired
        placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValue`)}
        options={targetFieldCategoryOptions}
        onChange={(newValue) => {
          onTargetFieldCategoryChange(newValue);
          onVariableChangeCallBacks(currentVariable, "target_field_category");
        }}
        validate={() => {
          return null;
        }}
        variant="standard"
        labelVariant="standard"
        className={classes.inputPathVariableItem}
      />

      <TargetFieldContext.Provider value={targetFieldcontext}>
        <TargetFieldData fieldName={`${fieldName}.target_field_data`} />
      </TargetFieldContext.Provider>
    </>
  )
}

const TargetFieldData = ({ fieldName }) => {
  const {
    selectedTargetFieldCategory
  } = useContext(TargetFieldContext);

  switch (selectedTargetFieldCategory) {
    case TargetFieldCategory.THIRD_PARTY:
      return <TargetFieldDataForThirdParty fieldName={`${fieldName}.data_for_third_party`} />;

    case TargetFieldCategory.DOSSIER:
      return <TargetFieldDataForDossier fieldName={`${fieldName}.data_for_dossier`} />;

    case TargetFieldCategory.DOCUMENT:
      return <TargetFieldDataForDocument fieldName={`${fieldName}.data_for_document`} />;

    case TargetFieldCategory.SOURCE_VARIABLE:
      return <TargetFieldDataForSourceVariable fieldName={`${fieldName}.data_for_source_variable`} />;

    default:
      return (
        <>
        </>
      )
  }
}

const TargetFieldDataForThirdParty = ({ fieldName }) => {

  return (
    <>
      <TargetFieldNatureField fieldName={fieldName} />

      <InnerTargetField fieldName={fieldName} />
    </>
  );
}

const TargetFieldDataForDossier = ({ fieldName }) => {

  return (
    <>
      <TargetFieldDossierTypeField fieldName={fieldName} />

      <TargetFieldNatureField fieldName={fieldName} />

      <InnerTargetField fieldName={fieldName} />
    </>
  );
}

const TargetFieldDataForDocument = ({ fieldName }) => {

  return (
    <>
      <TargetFieldDocumentTypeField fieldName={fieldName} />

      <TargetFieldNatureField fieldName={fieldName} />

      <InnerTargetField fieldName={fieldName} />
    </>
  );
}

const TargetFieldDataForSourceVariable = ({ fieldName }) => {

  return (
    <>
      <InnerTargetField fieldName={fieldName} />
    </>
  );
}

const TargetFieldDossierTypeField = ({ fieldName }) => {
  const { classes } = useStyles();

  const {
    dossierTypes
  } = useContext(CalculatorContext);

  const {
    selectedTargetFieldCategory
  } = useContext(TargetFieldContext);

  const {
    onVariableChangeCallBacks,
    currentVariable
  } = useContext(VariableContext);

  const formMethods = useFormContext();
  const {
    setValue,
    clearErrors
  } = formMethods;

  const onTargetFieldDossierTypeChange = useCallback((newValue) => {
    setValue(`${fieldName}.field`, '');
    clearErrors(`${fieldName}.field`, '');

    setValue(`${fieldName}.field_type`, '');
    clearErrors(`${fieldName}.field_type`, '');
  }, [fieldName, setValue, clearErrors]);

  switch (selectedTargetFieldCategory) {
    case TargetFieldCategory.DOSSIER:
      return (
        <>
          <div className={classes.variableDivider}> / </div>

          <CalculatorSelectInput
            fieldName={`${fieldName}.dossier_type_id`}
            label={``}
            isRequired
            placeholder={i18next.t(
              `calculator.dialog.create.placeholder.selectValue`
            )}
            options={dossierTypes}
            onChange={(newValue) => {
              onTargetFieldDossierTypeChange(newValue);
              onVariableChangeCallBacks(currentVariable, "dossier_type_id")
            }}
            validate={() => {
              return null;
            }}
            variant="standard"
            labelVariant="standard"
            className={classes.inputPathVariableItem}
          />
        </>
      );

    default:
      return (
        <>
        </>
      );
  }
}

const TargetFieldDocumentTypeField = ({ fieldName }) => {
  const { classes } = useStyles();

  const {
    documentTypes
  } = useContext(CalculatorContext);

  const {
    selectedTargetFieldCategory
  } = useContext(TargetFieldContext);

  const {
    onVariableChangeCallBacks,
    currentVariable
  } = useContext(VariableContext);

  const formMethods = useFormContext();
  const {
    setValue,
    clearErrors
  } = formMethods;

  const onTargetFieldDocumentTypeChange = useCallback((newValue) => {
    setValue(`${fieldName}.field`, '');
    clearErrors(`${fieldName}.field`, '');

    setValue(`${fieldName}.field_type`, '');
    clearErrors(`${fieldName}.field_type`, '');
  }, [fieldName, setValue, clearErrors]);

  switch (selectedTargetFieldCategory) {
    case TargetFieldCategory.DOCUMENT:
      return (
        <>
          <div className={classes.variableDivider}> / </div>

          {!_.isEmpty(documentTypes) && (
            <CalculatorSelectInput
              fieldName={`${fieldName}.document_type_id`}
              label={``}
              isRequired
              placeholder={i18next.t(
                `calculator.dialog.create.placeholder.selectValue`
              )}
              options={documentTypes}
              onChange={(newValue) => {
                onTargetFieldDocumentTypeChange(newValue);
                onVariableChangeCallBacks(currentVariable, "document_type_id")
              }}
              validate={() => {
                return null;
              }}
              variant="standard"
              labelVariant="standard"
              className={classes.inputPathVariableItem}
            />
          )}
        </>
      );

    default:
      return (
        <>
        </>
      );
  }
}

const TargetFieldNatureField = ({ fieldName }) => {
  const { classes } = useStyles();

  const {
    selectedTargetFieldCategory
  } = useContext(TargetFieldContext);
  const {
    onVariableChangeCallBacks,
    currentVariable
  } = useContext(VariableContext);

  const formMethods = useFormContext();
  const {
    setValue,
    clearErrors
  } = formMethods;

  const [targetFieldDossierTypeId, setTargetFieldDossierTypeId] = useState();
  const [targetFieldDocumentTypeId, setTargetFieldDocumentTypeId] = useState();
  const [availableTargetFieldNatureOptions, setAvailableTargetFieldNatureOptions] = useState();
  const [show, setShow] = useState(false);

  const targetFieldData = useWatch({ name: `${fieldName}` });

  useEffect(() => {
    if (TargetFieldCategory.DOSSIER === selectedTargetFieldCategory) {
      setTargetFieldDossierTypeId(targetFieldData?.dossier_type_id);
    } else {
      setTargetFieldDossierTypeId(null);
    }

    if (TargetFieldCategory.DOCUMENT === selectedTargetFieldCategory) {
      setTargetFieldDocumentTypeId(targetFieldData?.document_type_id);
    } else {
      setTargetFieldDocumentTypeId(null);
    }
  }, [targetFieldData, selectedTargetFieldCategory]);

  useEffect(() => {
    setAvailableTargetFieldNatureOptions(getAvailableTargetFieldNatureOptions(selectedTargetFieldCategory));
  }, [selectedTargetFieldCategory]);

  useEffect(() => {
    const bShow = !_.isEmpty(availableTargetFieldNatureOptions) &&
      ((TargetFieldCategory.THIRD_PARTY === selectedTargetFieldCategory));

    setShow(bShow);
  }, [selectedTargetFieldCategory, targetFieldDossierTypeId, targetFieldDocumentTypeId, availableTargetFieldNatureOptions])

  const onTargetFieldNatureChange = useCallback((newValue) => {
    setValue(`${fieldName}.field`, '');
    clearErrors(`${fieldName}.field`, '');

    setValue(`${fieldName}.field_type`, '');
    clearErrors(`${fieldName}.field_type`, '');
  }, [fieldName, setValue, clearErrors]);

  if (show) {
    return (
      <>
        <div className={classes.variableDivider}> / </div>

        <CalculatorSelectInput
          fieldName={`${fieldName}.target_field_nature`}
          label={``}
          isRequired
          placeholder={i18next.t(
            `calculator.dialog.create.placeholder.selectValue`
          )}
          options={availableTargetFieldNatureOptions}
          onChange={(newValue) => {
            onTargetFieldNatureChange(newValue);
            onVariableChangeCallBacks(currentVariable, "target_field_nature");
          }}
          validate={() => {
            return null;
          }}
          variant="standard"
          labelVariant="standard"
          className={classes.inputPathVariableItem}
        />
      </>
    );
  } else {
    return (
      <>
      </>
    );
  }
}

const InnerTargetField = ({ fieldName }) => {
  const { classes } = useStyles();

  const {
    selectedCalculation,
    countries,
    activities,
    thirdpartyAttributes,
    dossierAttributes,
    documentTypes,
    calculatedAttributes,
    thirdPartyIndicators,
    financialStrengthScores,
    financialStrengthRatings,
    sourceVariables,
    calculations,
    showInduedScores,
    showTransparencyScore,
    showFinancialStrengthScores,
    enableTransparencyScore
  } = useContext(CalculatorContext);

  const {
    selectedTargetFieldCategory
  } = useContext(TargetFieldContext);

  const getDependencyFreeCalculatedAttributes = useCallback(() => {
    const calculationToCheck = _.find(calculations, { id: selectedCalculation?.id });
    const otherCalculations = calculations?.filter(calculation => calculation.id !== selectedCalculation?.id);

    return calculatedAttributes.filter(
      (entry) => !isCalculationReferencedByTargetCalculation(calculationToCheck, entry, otherCalculations)
    );
  }, [calculations, selectedCalculation, calculatedAttributes]);

  const getDependencyFreeThirdPartyIndicators = useCallback(() => {
    const calculationToCheck = _.find(calculations, { id: selectedCalculation?.id });
    const otherCalculations = calculations?.filter(calculation => calculation.id !== selectedCalculation?.id);

    return thirdPartyIndicators.filter(
      (entry) => !isCalculationReferencedByTargetCalculation(calculationToCheck, entry, otherCalculations)
    );
  }, [calculations, selectedCalculation, thirdPartyIndicators]);

  const getDependencyFreeSourceVariables = useCallback(() => {
    const calculationToCheck = _.find(calculations, { id: selectedCalculation?.id });
    const otherCalculations = calculations?.filter(calculation => calculation.id !== selectedCalculation?.id);

    return sourceVariables.filter(
      (entry) => !isCalculationReferencedByTargetCalculation(calculationToCheck, entry, otherCalculations)
    );
  }, [calculations, selectedCalculation, sourceVariables]);

  const formMethods = useFormContext();
  const {
    setValue,
  } = formMethods;

  const targetFieldData = useWatch({ name: `${fieldName}` });

  const getAvailableTargetFields = useCallback(() => {
    let availableTargetFields = [];

    switch (selectedTargetFieldCategory) {
      case TargetFieldCategory.THIRD_PARTY:
        availableTargetFields = _.filter(getTargetFieldOptionsForThirdParty(
          countries,
          activities,
          thirdpartyAttributes,
          getDependencyFreeCalculatedAttributes(),
          getDependencyFreeThirdPartyIndicators(),
          financialStrengthScores,
          financialStrengthRatings,
          showInduedScores,
          showTransparencyScore,
          showFinancialStrengthScores,
          enableTransparencyScore
        ), { fieldNature: targetFieldData?.target_field_nature });
        break;

      case TargetFieldCategory.DOSSIER:
        availableTargetFields = getTargetFieldOptionsForDossier(
          dossierAttributes
        );
        break;

      case TargetFieldCategory.DOCUMENT:
        availableTargetFields = getTargetFieldOptionsForDocument(
          _.find(documentTypes, { value: targetFieldData?.document_type_id })?.properties || []
        );
        break;

      case TargetFieldCategory.SOURCE_VARIABLE:
        availableTargetFields = getTargetFieldOptionsForSourceVariable(
          getDependencyFreeSourceVariables(),
        );
        break;

      default:
        break;
    }

    return availableTargetFields;
  }, [
    countries,
    activities,
    thirdpartyAttributes,
    dossierAttributes,
    financialStrengthScores,
    financialStrengthRatings,
    documentTypes,
    showInduedScores,
    showTransparencyScore,
    showFinancialStrengthScores,
    enableTransparencyScore,
    getDependencyFreeCalculatedAttributes,
    getDependencyFreeThirdPartyIndicators,
    getDependencyFreeSourceVariables,
    selectedTargetFieldCategory,
    targetFieldData
  ]);

  const [availableFields, setAvailableFields] = useState(getAvailableTargetFields());
  const [show, setShow] = useState(false);

  const {
    onVariableChangeCallBacks,
    currentVariable
  } = useContext(VariableContext);

  useEffect(() => {
    setAvailableFields(getAvailableTargetFields());
  }, [getAvailableTargetFields]);

  const onFieldChange = useCallback((newValue) => {
    let entry = _.find(availableFields, { value: newValue });

    setValue(`${fieldName}.field_type`, entry.type);
    setValue(`${fieldName}.field_sub_type`, entry.subType || ``);
    setValue(`${fieldName}.target_field_nature`, entry.fieldNature);
  }, [fieldName, availableFields, setValue]);


  useEffect(() => {
    let bShow = false;

    switch (selectedTargetFieldCategory) {
      case TargetFieldCategory.THIRD_PARTY:
        bShow = !!targetFieldData?.target_field_nature;
        break;

      case TargetFieldCategory.DOSSIER:
        bShow = !!targetFieldData?.dossier_type_id
        break;

      case TargetFieldCategory.DOCUMENT:
        bShow = !!targetFieldData?.document_type_id;
        break;

      case TargetFieldCategory.SOURCE_VARIABLE:
        bShow = true;
        break;

      default:
        break;
    }

    setShow(bShow);
  }, [selectedTargetFieldCategory, targetFieldData]);


  if (show) {
    return (
      <>
        <div className={classes.variableDivider}> / </div>

        <CalculatorSelectInput
          fieldName={`${fieldName}.field`}
          label={``}
          isRequired
          placeholder={i18next.t(
            `calculator.dialog.create.placeholder.selectValue`
          )}
          options={availableFields}
          onChange={(newValue) => {
            onFieldChange(newValue);
            onVariableChangeCallBacks(currentVariable, "field");
          }}
          validate={() => {
            return null;
          }}
          variant="standard"
          labelVariant="standard"
          className={classes.inputPathVariableItem}
        />
      </>
    );
  } else {
    return (
      <>
      </>
    );
  }
}

export default Variables;
