import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    boxContainer : {
        padding: "18px 26px",
        fontSize: "14px",
        borderRadius: 12,
        backgroundColor: theme.palette.background.greyLight,
        shadowColor: "rgba(0, 0, 0, 0.07)",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowRadius: 5,
        shadowOpacity: 1
    },
    flexContainer: {
        display: "flex",
        gap: "16px",
        marginBottom: "16px"
    },
    primaryColor : {
        color: theme.palette.primary.main
    },
    headerText: {
        marginBottom: "8px",
        fontWeight: 700
    },
    archive: {
        scale: 0.9
    }
}));

export default useStyles;
