import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  imgWrapper: {
    padding: "1rem",
    borderRadius: "50%",
    width: "55px",
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  imgWrapperGreen: {
    backgroundColor: "#EFFBF1"
  },
  imgWrapperRed: {
    backgroundColor: "#FFEBEB"
  },
  imgDeleteContent: {
    width: "21px",
    height: "25px"
  },
  imageWrapper: {
    width: "30px",
    height: "30px",
    paddingRight: "5px"
  },
  imgPlusContent: {
    width: "27px",
    height: "27px"
  },
  imgContentCross: {
    position: 'relative',
    top: "4px",
    width: "20px",
    height: "20px",
  },
  bolder: {
    fontWeight: 700,
    fontFamily: "Metric",
    fontSize: "20px",
    color: theme.palette.grey.grey700,
    maxWidth: "370px",
    textAlign: "center",
  },
  modelInfoBlocWrapper: {
    display: "flex",
    justifyContent: "left",
    fontSize: "18px",
    color: theme.palette.grey.grey700,
    flexDirection: "column",
    backgroundColor: "#F2F3F7",
    padding: "24px 20px",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  modelInfoCaption: {
    display: "flex",
    flexDirection: "row",
  },
  modelInfoBlocTitle: {
    fontWeight: "bold",
    lineHeight: "2em",
    paddingBottom: "5px",
    color: theme.palette.grey.grey700,
    fontSize: "15px",
  },
  modelInfoBlocContent: {
    padding: "0px 1.5rem 5px 2rem",
    minWidth: "370px",
    fontSize: "15px",
    color: theme.palette.grey.grey700,
  },
  declarationControls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "27px",
  },
  declarationControlsLeft: {
    display: "flex",
    gap: "6px",
    marginLeft: "5px",
    padding: "10px 10px",
    cursor: "pointer",
  },
  declarationControlsRight: {
    display: "flex",
    gap: "16px",
    justifyContent: 'flex-end',
    padding: '7px'
  },
  deleteModelImg: {
    width: "12px",
    marginRight: "12px"
  },
  formWrapper: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-around"
  },
  statutLabel: {
    fontWeight: 700,
    color: theme.palette.grey.grey500
  },
  selectInput: {
    color: theme.palette.grey.grey500,
    border: 0,
  },
}));

export default useStyles;