import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import i18next from "i18next";
import EAAutoCompleteInput from "components/inputs/EAAutoCompleteInput";
import { SearchFormInputBloc } from "components/lists-advanced-search/SearchFormInputBloc/SearchFormInputBloc";

export function ThirdpartiesSearchIndued({ induedOptions, filters }) {
  const { control, setValue, getValues } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: "indued",
  });

  useEffect(() => {
    if (filters?.indued) {
      filters.indued?.forEach((filter) => {
        const fieldIndex = fields.findIndex((field) => field.score === filter.score);
        setValue(`indued.${fieldIndex}.values`, filter.values || []);
      });
    }
  }, [fields, setValue, filters]);

  return (
    <>
      {induedOptions?.length > 0 &&
        fields.map((field, index) => {
          const score = getValues(`indued.${index}.score`);
          return (
            <Grid item xs={4} sm={3} md={2} key={field.id}>
              <SearchFormInputBloc label={i18next.t(`indued.scoresFilters.${score}`)} hasTooltip>
                <EAAutoCompleteInput
                  fieldName={`indued.${index}.values`}
                  options={induedOptions}
                  placeholder={"Tous"}
                  variant="outlined"
                  multiple
                  limitTags={1}
                />
              </SearchFormInputBloc>
            </Grid>
          );
        })}
    </>
  );
}
