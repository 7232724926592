import i18next from "i18next";
import {
  AttributeType,
  REGEXP_DECIMAL,
  REGEXP_INTEGER,
  REGEXP_PERCENTAGE,
  REGEXP_SEMICOLON,
} from "./Constants";
import moment from "moment";

export const hasAttributeListItems = (type) =>
  type === AttributeType.LIST_MULTIPLE || type === AttributeType.LIST_UNIQUE;

export const attributeMaxLengthErrorText = (input, length) =>
  i18next.t("attributes.errors.maxLength", {
    input: i18next.t(input),
    length,
  });

export const getAttributeMaxLengthRule = (input, maxlength) => {
  return {
    value: maxlength,
    message: attributeMaxLengthErrorText(input, maxlength),
  };
};

export const shouldTranslateAttribute = (value) => {
  return [i18next.t("notSpecified"), i18next.t("yes"), i18next.t("no")].includes(value);
}

// Attribute validation for types : String, Integer, Decimal and Percentage.
export const validateAttributeInputForType = (type, value) => {
  switch (type) {
    case AttributeType.INTEGER:
      return value && !value.match(REGEXP_INTEGER)
        ? i18next.t("attributes.errors.integer")
        : null;
    case AttributeType.DECIMAL:
      return value && !value.match(REGEXP_DECIMAL)
        ? i18next.t("attributes.errors.decimal")
        : null;
    case AttributeType.PERCENTAGE:
      return value && !value.match(REGEXP_PERCENTAGE)
        ? i18next.t("attributes.errors.percentage")
        : null;
    case AttributeType.STRING:
      return value && value.match(REGEXP_SEMICOLON)
        ? i18next.t("attributes.errors.nosemiclon")
        : null;
    default:
      return null;
  }
};

export const formatAttributeValues = (attributeValue) => {
  let values = attributeValue?.values;
  // Format for empty values
  if (!values || values.length === 0) {
    // Return null for number filter
    if ([AttributeType.DECIMAL, AttributeType.INTEGER, AttributeType.PERCENTAGE].includes(attributeValue?.type)) {
      return null
    }
    return i18next.t("notSpecified");
  }

  // Format boolean values
  if (attributeValue?.type === AttributeType.BOOLEAN) {
    values = values.map(x => x === "true" ? i18next.t("yes") : i18next.t("no"));
  }
  // Format percentage for filter purposes
  if (attributeValue?.type === AttributeType.PERCENTAGE) {
    values = values.map(x => x.slice(0, -1).replace(",", "."));
  }
  // Format decimals for filter purposes
  if (attributeValue?.type === AttributeType.DECIMAL) {
    values = values.map(x => x.replace(",", "."));
  }
  return values.join(", ");
}

/**
 * Builds an array of objects by attribute value label, each having the available values of the datagrid rows.
 * @param {*} rowsAttributeValues
 * @returns ex : [{label: 'Transport', type: 'list_unique', gridColumnType: 'singleSelect', values: ['car', 'bus']}]
 */
export const buildAttributesGridColumns = (rowsAttributeValues) => {
  const result = new Map();

  rowsAttributeValues.flat().forEach(obj => {
    const { attributeId, label, type, values } = obj;

    if (attributeId) {
      if (!result.has(attributeId)) {
        result.set(attributeId, { attributeId, label, type, values: new Set(values) });
      } else {
        const entry = result.get(attributeId);
        if (values !== null && Array.isArray(values)) {
          new Set(values).forEach(value => entry.values.add(value));
        }
      }
    }
  });

  const columns = Array.from(result.values())
    .map(({ attributeId, label, type, values }) => ({
      attributeId,
      label,
      type,
      gridColumnType: getAttributeGridColumnType(type),
      values: Array.from(values).sort()
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return columns;
}

export const getAttributeGridColumnType = (attributeColumnType) => {
  switch (attributeColumnType) {
    case "list_unique":
    case "boolean":
      return "singleSelect";
    case "integer":
    case "decimal":
    case "percentage":
      return "number";
    case "date":
      return "date";
    default:
      return "string";
  }
}

export const getAttributeValueOptions = (attributeColumn) => {
  switch (attributeColumn.type) {
    case "list_unique":
      if (!attributeColumn.values?.includes(i18next.t("notSpecified"))) {
        attributeColumn.values?.unshift(i18next.t("notSpecified"))
      }
      return attributeColumn.values?.map(value => ({ value, label: <span className="dt">{value}</span> }));
    case "boolean":
      return [i18next.t("notSpecified"), i18next.t("yes"), i18next.t("no")]?.map(value => ({ value, label: <span>{value}</span> }));
    default:
      return null;
  }
}

export const getAttributeValueDisplay = (type, value) => {
  if (value && value !== i18next.t("notSpecified")) {
    if (type === AttributeType.PERCENTAGE) {
      value += "%"
    }
    if (type === AttributeType.DATE) {
      value = moment(value).format(i18next.t("date.dateFormat"));
    }
    return value;
  }
  return i18next.t("notSpecified");
}
