import useStyles from "./ImportsStyle";
import SectionContentWrapper from "../../common/SectionContentWrapper";
import {  Stack,  } from "@mui/material";
import ImportFileThirdParties from "../ImportFileThirdParties/ImportFileThirdParties";
import ListImports from "../ListImports/ListImports";
import { useState } from "react";

const Imports = () => {
    const { classes } = useStyles();

    const [lastImportId, setLastImportId] = useState(null);


    return(
    <SectionContentWrapper>
        <div className={classes.headerWrapper}>
            <Stack >
                <ImportFileThirdParties updateLastImportId={setLastImportId}/>
                <ListImports lastImportId={lastImportId}/>
            </Stack>
        </div>
    </SectionContentWrapper>)
}

export default Imports;
