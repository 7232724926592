import React from "react";
import i18next from "../../i18n/i18n";
import DataTableActions from "../common/datatable/DataTableActions";
import {
  formatDate,
  getViewerUrl,
} from "../../services/common/Utils";
import { EADataGridCell } from "../common/datatable/EADataGrid";
import MainHeaderCell from "../common/datatable/MainHeaderCell";
import { PrivilegeEnum } from "services/common/RolesUtils";
import DocStatusChip from "components/common/DocStatusChip";
import { AcquisitionChannel, DATA_TABLE_ACTIONS, statusEngCode } from "services/common/Constants";
import { getEnv } from "../../config/Config";
import EAIcon from "components/common/EAIcon";

const declUrlAPI = getEnv("REACT_APP_API_URL_FORM")

export const InitialDocumentsTableSort = [
  {
    field: "nameFr",
    filterable: false,
    sort: "asc",
  },
];


export const documentsDefaultVisibilityColumns = () => {
  return {
    depositorOrTransmitter: false,
    dossierReference: false,
    thirdpartyIdentifier: false,
    thirdpartyCode: false,
  };
};

const hasDirectAction = (document) => !Array.isArray(document.dossiers);
const gridCellType = (document) => hasDirectAction(document) ? "link" : "action";

const getCellLink = (accountId, params, component, dossierId, thirdpartyId) => params.row.fileId && getViewerUrl(accountId, params.row, component, dossierId, thirdpartyId);

const onActionClick = (action, accountId, document, component, thirdpartyId, history) => {
  localStorage.setItem('lastVisited', window.location.pathname);
  if (action === DATA_TABLE_ACTIONS.VIEW.label) {
    history.push(getViewerUrl(accountId, document, component, document.dossierId, thirdpartyId))
  } else if (action === DATA_TABLE_ACTIONS.ANSWER.label) {
    window.location.href = (declUrlAPI + `/compteDO/${accountId}/document/${document.typeDocumentId}/dossier/${document.dossierId}`)
  }
}

const accountDocumentsTableActions = (
  accountId,
  document,
  history,
  component,
  hasAnyOfPrivilegesOnScopedResourceImpl,
  thirdpartyId
) => {
  const isDO = document.acquisition === AcquisitionChannel.DO;
  const isAnswerButton = [statusEngCode.IN_PROGRESS, statusEngCode.MISSING].includes(document.statusCode);
  const hasViewPrivilege = hasAnyOfPrivilegesOnScopedResourceImpl(document, PrivilegeEnum.PRIVILEGE_VIEW_DOCUMENT);
  const hasAnswerPrivilege = hasAnyOfPrivilegesOnScopedResourceImpl(document, PrivilegeEnum.PRIVILEGE_EDIT_DOCUMENT_ANSWER);

  return [
    {
      label: i18next.t(hasViewPrivilege ? DATA_TABLE_ACTIONS.VIEW.tooltip : DATA_TABLE_ACTIONS.VIEW.tooltipDisabled),
      isDisabled: !hasViewPrivilege,
      isHidden: !document.fileId && !document.refusedToFile,
      icon: DATA_TABLE_ACTIONS.VIEW.icon,
      keepWidth: true,
      action: () => onActionClick(DATA_TABLE_ACTIONS.VIEW.label, accountId, document, component, thirdpartyId, history)
    },
    {
      label: i18next.t(
        hasAnswerPrivilege
          ? isAnswerButton
            ? DATA_TABLE_ACTIONS.ANSWER.tooltip
            : DATA_TABLE_ACTIONS.UPDATE_ANSWER.tooltip
          : isAnswerButton
            ? DATA_TABLE_ACTIONS.ANSWER.tooltipDisabled
            : DATA_TABLE_ACTIONS.UPDATE_ANSWER.tooltipDisabled
      ),
      isDisabled: !hasAnswerPrivilege,
      isHidden: !isDO || document.dossierId === null || !document.isAnswerable,
      keepWidth: true,
      icon: isAnswerButton ? DATA_TABLE_ACTIONS.ANSWER.icon : DATA_TABLE_ACTIONS.UPDATE_ANSWER.icon,
      action: () => onActionClick(DATA_TABLE_ACTIONS.ANSWER.label, accountId, document, component, thirdpartyId, history)
    }
  ];
};

// Rendu de la cellule du nom du document (avec décompte si doc multiple)
const formatName = (row) => {
  const styles = {
    root:             { display: "flex", alignItems: "center" },
    countDocWrapper:  { display: "flex", alignItems: "center", backgroundColor: "#EFFBF1", padding: "4px 6px", borderRadius: "6px", marginRight: "12px" },
    countDocText:     { color: "#373534", fontSize: "14px", fontWeight: 600, marginLeft: "2px" },
  };

  return (
    <div style={{...styles.root}}>
      { row.nbMultiple > 1 && (
          <div style={{...styles.countDocWrapper}}>
            <EAIcon icon={"document"} height="15px" width="15px" />
            <span style={{...styles.countDocText}}>{row.nbMultiple}</span>
          </div>
        )} <span>{row["nameFr"] || ""}</span>
    </div>
  );
};

export const getDocumentsTableColumns = (
  component,
  accountId,
  history,
  hasAnyOfPrivilegesOnScopedResourceImpl,
  thirdpartyId,
  isDossierMonitored
) => {
  return [
    ...(component === "documents-list"
      ? [
          {
            field: "thirdpartyName",
            headerName: i18next.t("thirdparty"),
            flex: 0.1,
            renderCell: (params) => (
              <EADataGridCell
                disableTranslation
                type={gridCellType(params.row)}
                link={getCellLink(
                  accountId,
                  params,
                  component,
                  params.row.dossierId,
                  thirdpartyId
                )}
              >
                {params.value}
              </EADataGridCell>
            ),
          },
          {
            field: "thirdpartyCode",
            headerName: i18next.t("myDocuments.thirdpartyCode"),
            flex: 0.08,
            type: "string",
            renderCell: (params) => (
              <EADataGridCell
                disableTranslation
                type={gridCellType(params.row)}
                link={getCellLink(
                  accountId,
                  params,
                  component,
                  params.row.dossierId,
                  thirdpartyId
                )}
              >
                {params.value}
              </EADataGridCell>
            ),
          },
        ]
      : []),
    ...((component === "documents-list" || component === "thirdparty")
      ? [
          {
            field: "dossierTypeLibelle",
            headerName: i18next.t("myDocuments.dossierType"),
            flex: 0.1,
            renderCell: (params) => (
              <EADataGridCell
                disableTranslation
                type={gridCellType(params.row)}
                link={getCellLink(
                  accountId,
                  params,
                  component,
                  params.row.dossierId,
                  thirdpartyId
                )}
              >
                {params.value}
              </EADataGridCell>
            ),
          },
          {
            field: "dossierReference",
            headerName: i18next.t("myDocuments.dossierReference"),
            flex: 0.1,
            type: "string",
            renderCell: (params) => (
              <EADataGridCell
                disableTranslation
                type={gridCellType(params.row)}
                link={getCellLink(
                  accountId,
                  params,
                  component,
                  params.row.dossierId,
                  thirdpartyId
                )}
              >
                {params.value}
              </EADataGridCell>
            ),
          },
        ]
      : []),
    {
      field: "nameFr",
      headerName: i18next.t("myDocuments.documentName"),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("myDocuments.documentName")} />
      ),
      renderCell: (params) => (
        <EADataGridCell
          type={gridCellType(params.row)}
          link={getCellLink(
            accountId,
            params,
            component,
            params.row.dossierId,
            thirdpartyId
          )}
          tooltipLabel={params.row["nameFr"] || ""}
        >
          {formatName(params.row)}
        </EADataGridCell>
      ),
    },
    {
      field: "depositorOrTransmitter",
      headerName: i18next.t("myDocuments.depositorName"),
      flex: 0.1,
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
          type={gridCellType(params.row)}
          link={getCellLink(
            accountId,
            params,
            component,
            params.row.dossierId,
            thirdpartyId
          )}
        >
          {params.value}
        </EADataGridCell>
      ),
    },
    {
      field: "depositDate",
      headerName: i18next.t("myDocuments.depositDate"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
          type={gridCellType(params.row)}
          link={getCellLink(
            accountId,
            params,
            component,
            params.row.dossierId,
            thirdpartyId
          )}
        >
          {formatDate(params.value)}
        </EADataGridCell>
      ),
    },
    {
      field: "endDate",
      headerName: i18next.t("myDocuments.validity"),
      flex: 0.1,
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell
          disableTranslation
          type={gridCellType(params.row)}
          link={getCellLink(
            accountId,
            params,
            component,
            params.row.dossierId,
            thirdpartyId
          )}
        >
          {formatDate(params.value)}
        </EADataGridCell>
      ),
    },
    ...(isDossierMonitored
      ? [
          {
            field: "statusCode",
            headerName: i18next.t("myDocuments.status"),
            flex: 0.1,
            headerAlign: "center",
            align: "center",
            type: "singleSelect",
            valueOptions: [
              {
                value: "IN_PROGRESS",
                label: i18next.t(
                  `viewer.status.statusLabel.${statusEngCode.IN_PROGRESS}`
                ),
              },
              {
                value: "ACTION_REQUIRED",
                label: i18next.t(
                  `viewer.status.statusLabel.${statusEngCode.ACTION_REQUIRED}`
                ),
              },
              {
                value: "BELOW_EXPECTATION",
                label: i18next.t(
                  `viewer.status.statusLabel.${statusEngCode.BELOW_EXPECTATION}`
                ),
              },
              {
                value: "NOT_VALID",
                label: i18next.t(
                  `viewer.status.statusLabel.${statusEngCode.NOT_VALID}`
                ),
              },
              {
                value: "VALID",
                label: i18next.t(
                  `viewer.status.statusLabel.${statusEngCode.VALID}`
                ),
              },
              {
                value: "MISSING",
                label: i18next.t(
                  `viewer.status.statusLabel.${statusEngCode.MISSING}`
                ),
              },
            ],
            renderCell: (params) => (
              <EADataGridCell
                type={gridCellType(params.row)}
                link={getCellLink(
                  accountId,
                  params,
                  component,
                  params.row.dossierId,
                  thirdpartyId
                )}
                tooltipLabel={i18next.t(
                  `viewer.status.statusLabel.${params.row.statusCode}`
                )}
              >
                <DocStatusChip statusCode={params.value} />
              </EADataGridCell>
            ),
          },
        ]
      : []),
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="dt" style={{ textAlign: "center" }}>
            <DataTableActions
              actions={accountDocumentsTableActions(
                accountId,
                params.row,
                history,
                component,
                hasAnyOfPrivilegesOnScopedResourceImpl,
                thirdpartyId
              )}
            />
          </div>
        );
      },
    },
  ];
};
