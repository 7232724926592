import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";

import useStyles from "style/js-style/containers/administration/roles/privileges/SwitchStyle";
import { PrivilegeEnum, RoleBasedAccessControlContext, RolePrivilegesEditorContext } from "services/common/RolesUtils";

function SwitchButton({ name, action, isActive = false, isDisabled, ressourceId, ressourcesList, ...othersProps }) {
  const { classes } = useStyles();
  const { isGlobalAdmin, hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const { isSelectedRoleReadOnly } = useContext(RolePrivilegesEditorContext)
  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    setIsEditable(!isDisabled && (isGlobalAdmin || (!isSelectedRoleReadOnly && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_ROLE))));
  }, [isGlobalAdmin, isDisabled, isSelectedRoleReadOnly, hasAnyOfPrivileges])

  const handleClick = (e) => {
    e.stopPropagation();
    if (isEditable) {
      action(isActive, ressourceId, ressourcesList);
    }
  };

  return (
    <div
      className={
        clsx(classes.switch,
          isActive
            ? classes.enabledSwitch
            : classes.disabledSwitch)
      }
      onClick={handleClick}
      style={{
        opacity: isEditable ? 1 : 0.75,
        cursor: isEditable ? 'pointer' : 'auto',
        background: isDisabled ? "#E2E2EA" : "",
        color: isDisabled ? "#C6CBD9" : "",
      }}
      {...othersProps}
    >
      {name}
    </div >
  )
}

export default SwitchButton;
