import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";

export async function getAccountRoles(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/roles`
  );
}

export async function getAccountRolesNames(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/roles-names`
  );
}

export async function getDefaultRole(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/roles/default`
  );
}

export async function getRoleById(accountId, roleId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/roles/${roleId}`
  );
}

export async function createAccountRole(accountId, roleDto, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/roles`,
    roleDto
  );
}

export async function updateAccountRole(accountId, roleId, roleDto, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/roles/${roleId}`,
    roleDto
  );
}

export async function isRoleNameAuthorized(accountId, roleName, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/roles/check-role-name`,
    { name: roleName }
  );
}

export async function getRolePrivileges(accountId, roleId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/roles/${roleId}/privileges`
  );
}

export async function updateRolePrivileges(accountId, roleId, roleDto, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PATCH",
    `account/${accountId}/roles/${roleId}/privileges`,
    roleDto
  );
}

export async function updateRoleScopes(accountId, roleId, roleDto, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PATCH",
    `account/${accountId}/roles/${roleId}/scopes`,
    roleDto
  );
}

export async function deleteAccountRole(accountId, roleId, replacementRoleId, dispatch) {
  if (replacementRoleId !== null) {
    return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "DELETE",
      `account/${accountId}/roles/${roleId}`,
      replacementRoleId
    );
  } else {
    return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "DELETE",
      `account/${accountId}/roles/${roleId}`,
    );
  }
}

export async function deleteAccountRoles(accountId, roleIds, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/roles`,
    roleIds
  );
}

export async function isRoleAssigned(accountId, roleId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/roles/${roleId}/check-role-assigned`
  );
}
