import {
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import * as am5 from "@amcharts/amcharts5";
import React, { useEffect, useCallback, useContext, useState }  from "react";
import useStyles from "style/js-style/containers/dashboard/TabStyle";
import { hidePieSliceLabel } from "services/common/ChartUtils";

import { Context } from "states/Store";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { ERROR } from "services/common/Constants";
import { formatNumber } from "services/common/Utils";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { useRef } from "react";
import RiskScorePieChart from "../RiskScorePieChart";
import { getFinancialStrengthCount } from "services/edge/DataBlocksService";
import { isAbortError } from "services/common/ApiUtils";

const severityPieData = {
  unknown: {
    color: "#9A9AAF",
    order: 5,
  },
  very_low: {
    color: "#B0E8B9",
    order: 1,
  },
  low: {
    color: "#2D9F40",
    order: 1,
  },
  medium: {
    color: "#FF9A00",
    order: 3,
  },
  high: {
    color: "#CC0001",
    order: 2,
  },
};

const addVeryHighCountIntoHigh = (result) => {
  
  let veryHighIndex = result.findIndex(entry => entry.scoreLabel === 'very_high');
  let highIndex = result.findIndex(entry => entry.scoreLabel === 'high');

  if (veryHighIndex !== -1 && highIndex !== -1) {
    result[highIndex].count += result[veryHighIndex].count;

    result.splice(veryHighIndex, 1);
  }

  return result;
}

function TabFinancialStrength() {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [mappedScoreCount, setMappedScoreCount] = useState();
  const accountId = useSelectedAccountId();

  const abortCtrlRef = useRef();

  const fetchFinancialStrengthCount = useCallback(
    () => {
      
      if(abortCtrlRef.current) {
        abortCtrlRef.current.abort();
      }
  
      abortCtrlRef.current = new AbortController();
      const signal = abortCtrlRef.current.signal;

      if (accountId && state.account) {
        (async () => {
          try {
            const res = await getFinancialStrengthCount(
              accountId,
              null,
              signal
            );

            const total = res.content
            ?.map((d) => d.count)
            .reduce((partialSum, a) => partialSum + a, 0);

            const result = addVeryHighCountIntoHigh(res.content);

            const mappedResults = {
              data: result
                .map((item) => ({
                  id: item.scoreLabel,
                  label: item.scoreLabel,
                  itemLabel: localizeTranslate(
                    i18next.t(`datablocks.financialStrength.severity.${item.scoreLabel}`)
                  ),
                  type: "financialStrength",
                  value: item.count,
                  formattedValue: formatNumber(item.count),
                  settings: {
                    fill: am5.color(
                      severityPieData[item.scoreLabel].color
                    ),
                  },
                  labelSettings: {
                    forceHidden: hidePieSliceLabel(item.count, total),
                  },
                }))
                .sort((a, b) =>
                  severityPieData[a.label].order > severityPieData[b.label].order
                    ? 1
                    : -1
                ),
              total: formatNumber(total),
            }
            
            setMappedScoreCount(mappedResults);
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
            } 
          }
        })();
      }
    },
    [accountId, dispatch, state.account]
  );

  useEffect(() => {
    fetchFinancialStrengthCount();
    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [fetchFinancialStrengthCount]);

  return (
    <Paper elevation={0} className={classes.paperContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} key="financialStrength">
          <Paper className={classes.graphPaper}>
            <Typography variant="h3" className={classes.graphAreaTitle}>
              {i18next.t(`datablocks.financialStrength.scoreLabel`)}
            </Typography>
            <RiskScorePieChart subTab={"financialStrength"} scoreCount={mappedScoreCount}/>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TabFinancialStrength;
