import { getEnv } from "config/Config";
import { eaFetch } from "services/common/ApiUtils";

export async function getAllNoteTP(accountId, thirdpartyId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdpartyId}/note`
  );
}

export async function createNoteTP(
  accountId,
  thirdpartyId,
  noteToCreate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/note`,
    noteToCreate
  );
}

export async function deleteNoteTP(accountId, thirdpartyId, noteId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/thirdparties/${thirdpartyId}/note/${noteId}`
  );
}

export async function updateNoteTP(
  accountId,
  thirdpartyId,
  noteId,
  noteToUpdate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/thirdparties/${thirdpartyId}/note/${noteId}`,
    noteToUpdate
  );
}

export async function getAllNoteDossier(accountId, dossierId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/${dossierId}/note`
  );
}

export async function createNoteDossier(
  accountId,
  dossierId,
  noteToCreate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers/${dossierId}/note`,
    noteToCreate
  );
}

export async function deleteNoteDossier(
  accountId,
  dossierId,
  noteId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/dossiers/${dossierId}/note/${noteId}`
  );
}

export async function updateNoteDossier(
  accountId,
  dossierId,
  noteId,
  noteToUpdate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/dossiers/${dossierId}/note/${noteId}`,
    noteToUpdate
  );
}

export async function getAllNoteDocument(
  accountId,
  thirdpartyId,
  responseId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdpartyId}/responses/${responseId}/note`
  );
}

export async function createNoteDocument(
  accountId,
  thirdpartyId,
  responseId,
  noteToCreate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/responses/${responseId}/note`,
    noteToCreate
  );
}

export async function deleteNoteDocument(
  accountId,
  thirdpartyId,
  responseId,
  noteId,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/thirdparties/${thirdpartyId}/responses/${responseId}/note/${noteId}`
  );
}

export async function updateNoteDocument(
  accountId,
  thirdpartyId,
  responseId,
  noteId,
  noteToUpdate,
  dispatch
) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/thirdparties/${thirdpartyId}/responses/${responseId}/note/${noteId}`,
    noteToUpdate
  );
}
