import { makeStyles } from 'tss-react/mui';
import {drawerWidth, ReducedDrawerWidth} from "../../globalStyle";

const useStyles = makeStyles()((theme) => ({
    root: {
        overflowX: "hidden",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),

        [theme.breakpoints.down("md")]: {
            marginLeft: ReducedDrawerWidth,
        },
    },
    largeDrawerContent: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),

        [theme.breakpoints.down("md")]: {
            marginLeft: drawerWidth,
        },
    },
    toolbar: theme.mixins.toolbar,
}));

export default useStyles;
