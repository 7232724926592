import { getAllFrenchDepartments } from "services/edge/ExternalService";
import { Context } from "states/Store";
import { useState, useEffect, useContext } from "react";
import i18next from "i18next";
import { ERROR } from "services/common/Constants";
import { isEmpty } from "lodash";
import countries from "i18n-iso-countries";

export const useThirdpartyFormOptions = () => {
  const [state, dispatch] = useContext(Context);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [transparencyOptions, setTransparencyOptions] = useState([]);
  const [financialStrengthScoreOptions, setFinancialStrengthScoreOptions] = useState([]);
  const [financialStrengthRatingOptions, setFinancialStrengthRatingOptions] = useState([]);
  const [induedOptions, setInduedOptions] = useState([]);
  const [dnbMajorOptions, setDnbMajorOptions] = useState([]);
  const [registrationStatusOptions, setRegistrationStatusOptions] = useState([]);

  useEffect(() => {
    (async function fetchData() {
      try {
        const isoCountries = countries.getNames(i18next.language);
        setCountriesOptions(
          Object.keys(isoCountries).map((code) => {
            return {
              value: code,
              label: isoCountries[code],
            };
          })?.sort((a,b) => a.label.localeCompare(b.label))
        );
      } catch (e) {
        dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
        setCountriesOptions([]);
      }
    })();
  }, [dispatch]);

  // TODO Add for v2 with activities
  // useEffect(() => {
  //    (async function fetchData() {
  //     try {
  //       const activities = await getListActivity(4, dispatch);
  //       setActivitiesOptions(activities.content?.map((activity) => ({
  //         value: activity.code,
  //         label: activity.descriptionComplete.trim(),
  //       }));
  //     } catch (e) {
  //       dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
  //     }
  //   })();
  // }, [dispatch]);

  useEffect(() => {
    (async function fetchData() {
      try {
        const departments = await getAllFrenchDepartments(dispatch);
        setDepartmentsOptions(
          departments?.map((x) => ({ value: x.code, label: x.nom }))?.sort((a,b) => a.label.localeCompare(b.label))
        );
      } catch (e) {
        dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
        setDepartmentsOptions([]);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(state.referenceData)) {
      setTransparencyOptions(
        state.referenceData.transparency?.map((x) => ({
          label: x.interval,
          value: x.code,
        }))
      );
      setInduedOptions(
        state.referenceData.indued
          ?.map((x) => ({
            label: i18next.t(`indued.severity.${x.code?.toLowerCase()}`),
            value: x.code,
          }))
      );
      setFinancialStrengthRatingOptions(
        state.referenceData.financialStrengthRating
          ?.map((x) => ({
            label: x.rating,
            value: x.rating,
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label))
      );
      setFinancialStrengthScoreOptions(
        state.referenceData.financialStrengthScore
          ?.map(x => ({
            label: i18next.t(`datablocks.financialStrength.severity.${x.code?.toLowerCase()}`),
            value: x.code,
          }))
      );
      setRegistrationStatusOptions(
        state.referenceData.thirdpartyRegistrationStatus?.map((x) => ({
          label: i18next.t(`thirdpartyRegistrationStatus.${x.code?.toLowerCase()}`),
          value: x.code,
        }))?.sort((a,b) => a.label.localeCompare(b.label))
      );
      setDnbMajorOptions(
        state.referenceData.dnbMajor?.map((x) => ({
          label: x.value,
          value: x.code,
        }))?.sort((a,b) => a.label.localeCompare(b.label))
      );
    }
  }, [state.referenceData]);

  return {
    countriesOptions,
    departmentsOptions,
    transparencyOptions,
    financialStrengthScoreOptions,
    financialStrengthRatingOptions,
    induedOptions,
    // activitiesOptions,
    registrationStatusOptions,
    dnbMajorOptions,
  };
};
