import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        marginTop: "3rem",
        display: "flex",
        flexDirection: "column",
        height: "85%",
        borderRadius: "12px",
    },
    rootStatus: {
        marginTop: "3rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "85%",
        borderRadius: "12px",
        transition: "all 0.3s",
        // hover
        "&:hover": {
            boxShadow: "rgb(0 0 0 / 9%) 0px 25px 20px -20px",
            transition: "all 0.3s",
        },
    },
    rootCompleted: {
        padding: "1rem",
        justifyContent: "space-around",
        backgroundColor: theme.palette.primary.primary100,
    },
    rootUncompleted: {
        padding: "1rem",
        justifyContent: "space-around",
        backgroundColor: theme.palette.grey.grey100,
    },
    rootArchived: {
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: theme.palette.grey.grey500,
        color: "white",
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: "-5px",
    },
    contentWrapperNoComment: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: "-22px",
    },
    completenessIcon: {
        textAlign: "center",
        position: "relative",
        top: "20px",
        "& > img": {
            width: "50%",
            maxWidth: "160px",
        },
    },

    // Archived theme
    documentIconArchived:{
        width: "120px",
        position: "relative",
        bottom: "-22px",
        right: "5px"
    },
    archivedText: {
        fontWeight: 600,
        fontSize: "25px",
        margin: "0",
        fontFamily: "Metric",
    },
    archiveStopedAt: {
        fontSize: "12px",
        marginLeft: "5px",
    },
    commentArchivedWrapper: {
        border: "1px solid " + theme.palette.error.error200,
        borderRadius: "16px",
        margin: "1rem",
        padding: "0.5rem",
        fontSize: "12px",
    },
    commentArchived: {
        margin: "0rem",
    },
    commentArchivedBold: {
        fontWeight: 600,
    },
    completnessTitle: {
        color: theme.palette.grey.grey400,
        position: "relative",
        top: "1rem",
        fontSize: "25px",
    },
}));

export default useStyles;
