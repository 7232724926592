import { Box, Paper, Tab, Tabs } from "@mui/material";
import i18next from "i18next";
import React, { useContext } from "react";
import useStyles from "style/js-style/containers/dashboard/TabStyle";
import { useHistory } from "react-router-dom";
import { Context } from "states/Store";
import { useQuery } from "config/Config";
import ROUTES from "config/Routes";
import clsx from "clsx";
import EATabPanel from "components/common/EATabPanel";
import TabIndued from "./indued/TabIndued";
import { EAChartFilters } from "components/common/charts/EAChartFilters";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { isEmpty } from "lodash";
import { hasOption } from "services/common/Utils";
import TabTransparency from "./transparency/TabTransparency";
import { EANoData } from "components/common/EANoData";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import TabFinancialStrength from "./financial-strength/TabFinancialStrength";

function a11yProps(tab) {
  return {
    id: `${tab}-tab`,
    "aria-controls": `${tab}-tabpanel`,
  };
}

const risksTabs = [
  { key: "indued", sub: "indued", privileges: [PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE], component: <TabIndued /> },
  { key: "transparency", sub: "transparency", privileges: [PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE], component: <TabTransparency /> },
  { key: "financialStrength", sub: "financialStrength", privileges: [PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE], component: <TabFinancialStrength /> },
];

function TabRisks() {
  const { classes } = useStyles();
  const [value, setValue] = React.useState(
    parseInt(useQuery().get("risk")) || 0
  );
  const [risksToDisplay, setRisksToDisplay] = React.useState([]);
  const [state] = useContext(Context);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const history = useHistory();
  const accountId = useSelectedAccountId();

  const handleChange = (event, newValue) => {
    const tabIndex = event.target.tabIndex;
    setValue(tabIndex);
    history.push(`/${accountId}/${ROUTES.DASHBOARD}?tab=3&risk=${tabIndex}`);
  };

  React.useEffect(() => {
    let risks = risksTabs.filter(risk => hasOption(state.accounts, state.account.id, risk.sub))
    .map((risk, i) => {
      return {
        ...risk,
        id: i
        // we add id as index, depending on risks displayed
      }})
    setRisksToDisplay(risks);
  }, [state.accounts, state.account.id]);

  const isRiskDisabled = (privileges) => !isEmpty(privileges) && !hasAnyOfPrivileges(...privileges);

  return (
    <>
      <Paper elevation={0} className={classes.paperContainer}>
        <Box className={classes.tabsWrapper}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="dashboard risks tabs"
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              position: "relative",
              top: "-4px",
            }}
          >
            {risksToDisplay.map((tab) =>
              <Tab
                key={tab.key}
                tabIndex={tab.id}
                label={i18next.t(`dashboard.risks.${tab.key}`)}
                disabled={isRiskDisabled(tab.privileges)}
                {...a11yProps(tab.key)}
                className={clsx(
                  classes.tab,
                  classes.dt,
                  value === tab.id
                    ? classes.selectedTab
                    : classes.unselectedTab
                )}
              />
            )}
          </Tabs>
        </Box>

        {risksToDisplay.map((tab) => {
          if (!isRiskDisabled(tab.privileges)) {
            return (
              <EATabPanel key={tab.key} value={value} index={tab.id}>
                {tab.component}
              </EATabPanel>
            )
          } else {
            return (
              <EATabPanel key={tab.key} value={value} index={tab.id}>
                <EANoData key={tab.key}
                  style={{ width:'100%', height: '100%', minHeight: '400px', marginBottom: '30px', background: 'white' }}
                  label={i18next.t("dashboard.risks.unauthorized")}
                  icon="dangerous_grey_light"
                />
              </EATabPanel>
            )
          }
        }
        )}
      </Paper>

      {/* Show ChartFilters if any of the risk tabs are shown */}
      {hasAnyOfPrivileges(
        PrivilegeEnum.PRIVILEGE_VIEW_INDUED_SOURCE,
        PrivilegeEnum.PRIVILEGE_VIEW_TRANSPARENCY_SOURCE,
        PrivilegeEnum.PRIVILEGE_VIEW_FINANCIAL_STRENGTH_SOURCE
      ) && (
        <EAChartFilters tab="risks" subTab={risksToDisplay[value]?.key} />
      )}
    </>
  );
}

export default TabRisks;
