import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  imgWrapper: {
    backgroundColor: "#FFEBEB",
    padding: "1rem",
    borderRadius: "50%",
    width: "55px",
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  imgContent: {
    width: "21px",
    height: "27px"
  },
  bolder: {
    fontWeight: 700,
    fontFamily: "Metric",
    fontSize: "20px",
    color: theme.palette.grey.grey700,
    maxWidth: "370px",
    textAlign: "center",
  },
  modelInfoBlocWrapper: {
    display: "flex",
    justifyContent: "left",
    fontSize: "18px",
    color: theme.palette.grey.grey700,
    flexDirection: "column",
    backgroundColor: "#F2F3F7",
  },
  modelInfoCaption: {
    display: "flex",
    flexDirection: "row",

  },
  modelInfoBlocContent: {
    padding: "13px 1.5rem",
    minWidth: "370px",
    display: 'flex',
    justifyContent: 'center',
  },
  activeModelSubtitle: {
    color: "rgba(126, 126, 143, 1)",
    fontSize: "14px",
    marginBottom: "5px",
  },
  declarationControls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "27px",
  },
  declarationControlsLeft: {
    display: "flex",
    gap: "6px",
    marginLeft: "5px",
    padding: "10px 10px",
    cursor: "pointer",
  },
  declarationControlsRight: {
    display: "flex",
    gap: "16px",
    width: "100%",
    justifyContent: 'flex-end'
  },
  deleteModelImg: {
    width: "12px",
    marginRight: "12px"
  },
  formWrapper: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-around"
  },
  btnWrapper: {
    marginTop: "23px",
    display: 'flex',
    justifyContent: 'center',
    gap: "14px"
  },
  // input
  formLabel: {
    fontWeight: 700,
    color: "rgba(126, 126, 143, 1)",
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: "15px",
  },
  inputItemWrapper: {
    width: "297px",
  },
  textField: {
    marginTop: "15px",
    "& .MuiOutlinedInput-root": {
      border: 0,
    }
  },
  textFieldError: {
    "& .MuiOutlinedInput-root": {
      color: "red",
    }
  },
  textFieldDisable: {
    marginTop: "15px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #f8f9fb"
    },
    "& .MuiOutlinedInput-root": {
      border: 0,
      color: "rgba(126, 126, 143, 1)",
      fontWeight: 700,
    }
  },
  inputOverlay: {
    position: 'absolute',
    top: 0, left: 0, right: 0, bottom: 0,
    background: "transparent",
    zIndex: 5,
  },
  select: {
    marginTop: "15px",
    border: 0,
  },
  placeHolder: {
    color: "rgba(154, 154, 175, 1)",
    fontSize: "14px"
  },
  // confirm pop up
  confirmRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  imgContentWarning: {
    width: "45px",
    height: "45px"
  },
  imgWrapperAlert: {
    backgroundColor: "#FFF7EB",
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  infoBlocWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: theme.palette.grey.grey700,
  },
  infoBlocContent : {
    backgroundColor: "#F2F3F7",
    padding: "13px 1.5rem",
    textAlign: "center",
    minWidth: "370px",
  },
  finalButtonWrapper: {
    paddingTop: "32px",
    display: "flex",
    justifyContent: 'center',
    gap: "16px",
  }
}));

export default useStyles;