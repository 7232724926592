import { Grid, Paper, Typography } from "@mui/material";
import i18next from "i18next";
import React, { useContext, useEffect } from "react";
import { EANoData } from "../../../components/common/EANoData.js";
import useStyles from "../../../style/js-style/containers/dashboard/TabStyle.js";
import DocumentsGlobalStatesChart from "./DocumentsGlobalStatesChart.js";
import DocumentsStatesChart from "./DocumentsStatesChart.js";
import SkeletonDashboard, {SKELETON_TYPE} from "../../../components/common/skeleton/SkeletonDashboard";
import { getDocCountByType } from "services/edge/AccountService.js";
import { ERROR } from "services/common/Constants.js";
import { Context } from "states/Store.js";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId.js";
import { useRef } from "react";
import { isAbortError } from "services/common/ApiUtils.js";

function TabDocuments() {
  const { classes } = useStyles();

  const [state, dispatch] = useContext(Context);
  const [globalDocumentsCount, setGlobalDocumentsCount] = React.useState({});
  const [documentsTypeCount, setDocumentsTypeCount] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const accountId = useSelectedAccountId();

  const abortCtrlRef = useRef();

  useEffect(() => {
    setIsLoading(true);

    if(abortCtrlRef.current) {
      abortCtrlRef.current.abort();
    }

    abortCtrlRef.current = new AbortController();
    const signal = abortCtrlRef.current.signal;

    if (accountId && state.account) {
      (async function fetchData() {
        try {
          const documentsCountFetched = await getDocCountByType(
            accountId,
            null,
            signal
          );
          setGlobalDocumentsCount({
            nbAbsent: documentsCountFetched.content.globalDocCount.nbAbsent,
            nbCtrlInProgress: documentsCountFetched.content.globalDocCount.nbCtrlInProgress,
            nbInvalid: documentsCountFetched.content.globalDocCount.nbInvalid,
            nbBelowExpectation: documentsCountFetched.content.globalDocCount.nbBelowExpectation,
            nbActionDORequired: documentsCountFetched.content.globalDocCount.nbActionDORequired,
            nbValid: documentsCountFetched.content.globalDocCount.nbValid,
            all: documentsCountFetched.content.globalDocCount.nbTotal,
          });
          setDocumentsTypeCount(documentsCountFetched.content.docCountByType)

          setIsLoading(false)

        } catch (e) {
          if(!isAbortError(e)) {
            dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
          }
        }
      })();
    }

    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [accountId, dispatch, state.account])

  return !isLoading ? (
    <>
      <Paper elevation={0} className={classes.paperContainer}>
        {globalDocumentsCount?.all > 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <Paper className={classes.graphPaper}>
                <Typography variant="h3" className={classes.graphAreaTitle}>
                  {i18next.t("dashboard.documents.documentsStates")}
                </Typography>
                <DocumentsStatesChart documentsCount={documentsTypeCount} />
              </Paper>
            </Grid>

            {/* Circle */}
            <Grid item xs={12} sm={6} md={5}>
              <Paper className={classes.graphPaper}>
                <Typography variant="h3" className={classes.graphAreaTitle}>
                  {i18next.t("dashboard.documents.documentsGlobalStates")}
                </Typography>
                <DocumentsGlobalStatesChart documentsCountByStatus={globalDocumentsCount} />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <EANoData label={i18next.t("dashboard.documents.nodata")} icon="noDocuments"/>
        )}
      </Paper>
    </>
  ) : (
    <SkeletonDashboard
      sections={[
        { title : i18next.t("dashboard.documents.documentsStates"),
          type : SKELETON_TYPE.LINES
        },
        { title : i18next.t("dashboard.documents.documentsGlobalStates"),
          type : SKELETON_TYPE.CIRCULAR
        },
      ]}
    />
  )
}

export default TabDocuments;
