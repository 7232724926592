import { makeStyles } from 'tss-react/mui';
import {darkGrey} from "../../../../globalStyle";

const useStyles = makeStyles()(() => ({
    root: {
        padding: "1rem",
        minHeight: "275px",
        marginRight: "1rem",
        borderRadius: "0 12px 12px 12px"
    },
    thirdpartyTitleContainer: {
        paddingBottom: "1.5rem",
    },
    blocTitle: {
        padding: "12px 0 9px 0",
        wordBreak: "break-word",
        display: "flex",
        alignItems: "flex-start",
    },
    icon: {
      width: "20px",
      marginRight: "0.5rem",
    },
    typoContent: {
        fontSize: "12px",
        color: darkGrey,
        display: "inline",
    },
    typoContentUpper: {
        fontSize: "12px",
        color: "black",
        display: "inline",
        textTransform: "uppercase",
    },
    typoLineWrapper: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        display: "-webkit-box",
        wordBreak: "break-all",
    },
    typoLabel: {
        fontSize: "12px",
        color: "black",
        display: "inline",
    },
}));

export default useStyles;
