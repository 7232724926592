import React, {useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { isEmpty } from "lodash";

import useStyles from "style/js-style/containers/administration/roles/RoleScopeFormStyle";

const getFormDefaultValues = (role) => {
  return {
    id: role.id,
    thirdPartyQualifyingCriteria: (!isEmpty(role?.thirdPartyQualifyingCriteria) && role?.thirdPartyQualifyingCriteria.map((item) => {
      return {
        id: item.id,
        qualifiableResourceType: "THIRD_PARTY",
        field: item.field,
        fieldType: item.fieldType,
        operator: item.operator,
        attributeId: item.attributeId,
        values: item.values || [],
      }
    })) || [
      {
        id: "",
        qualifiableResourceType: "THIRD_PARTY",
        field: "",
        fieldType: "",
        operator: "",
        attributeId: "",
        values: [],
      },
    ],
    dossierQualifyingCriteria: (!isEmpty(role?.dossierQualifyingCriteria) && role?.dossierQualifyingCriteria.map((item) => {
      return {
        id: item.id,
        qualifiableResourceType: "DOSSIER",
        field: item.field,
        fieldType: item.fieldType,
        operator: item.operator,
        attributeId: item.attributeId,
        values: item.values || [],
      }
    })) || [
      {
        id: "",
        qualifiableResourceType: "DOSSIER",
        field: "",
        fieldType: "",
        operator: "",
        attributeId: "",
        values: [],
      },
    ],
    authorizedDocumentTypes: role?.authorizedDocumentTypes || [],
  };
};

const RoleScopeForm = ({ formId, submitHandler, children, role }) => {
  const { classes } = useStyles();

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: getFormDefaultValues(role),
  });

  useEffect(() => {
    formMethods.reset(getFormDefaultValues(role));
  }, [role, formMethods]);

  const {
    formState: { errors },
  } = formMethods;

  const submitForm = (data) => {
    if (isEmpty(errors)) {
      submitHandler(data);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id={formId}
        onSubmit={formMethods.handleSubmit(submitForm)}
        className={classes.formContainer}
      >
        {children}
      </form>
    </FormProvider>
  );
}

export default RoleScopeForm;
