import React, { useContext, useEffect } from "react";
import EAPieChart from "../../../components/common/charts/EAPieChart";
import { Context } from "../../../states/Store";
import { isEmpty } from "lodash";
import {
  buildPieStatusData,
  getShownPieDataByBarFilters,
} from "../../../services/common/ChartUtils";
import ROUTES from "../../../config/Routes";

function DossiersGlobalStatesChart({ dossiersStates }) {
  const [state] = useContext(Context);
  const [mappedStates, setMappedStates] = React.useState();
  const [shownStates, setShownStates] = React.useState();

  useEffect(() => {
    setMappedStates(buildPieStatusData(dossiersStates, "dossiers"));
  }, [dossiersStates]);

  // Filter Data when other graph items are selected
  useEffect(() => {
    if (mappedStates) {
      if (isEmpty(state.graphFilters.bar)) {
        setShownStates(mappedStates);
      } else {
        setShownStates(getShownPieDataByBarFilters(mappedStates.data, state.graphFilters.bar));
      }
    }
  }, [mappedStates, state.graphFilters.bar]);

  return <EAPieChart id="dossiersCompleteness" data={shownStates} tab={ROUTES.DOSSIERS}/>;
}

export default DossiersGlobalStatesChart;
