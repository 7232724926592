import React, { useState } from "react";
import useStyles from "style/js-style/containers/administration/roles/privileges/EventPrivilegeBlockStyle";
import EAIcon from "components/common/EAIcon";
import RoleCheckBox from "./RoleCheckBox";
import {isEmpty} from "lodash";
import i18next from "i18next";

/**
 *
 * @param data
 * @param grantMode disable | hide
 * @param variant roles | profile
 * @returns {JSX.Element}
 * @constructor
 */
function EventPrivilegeBlock({ data, grantMode = "disable", variant }) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  if(isEmpty(data.items)) {
    return (data.isGrantable || grantMode === "disable") && <RoleCheckBox item={data} variant={variant}/>
  }

  const handleSelectAll = (e) => {
    e.stopPropagation();
    data.selectAllAction();
  }

  const getNumberOfActiveItems = (items) => {
    let totalLength = 0;
    items?.forEach(item => {
      if (item.items && Array.isArray(item.items)) {
          totalLength += getNumberOfActiveItems(item.items);
      }
      if(item.isActive && item.isGrantable) {
        totalLength += 1;
      }
    });
    return totalLength;
  }

  const handleUnselectAll = (e) => {
    e.stopPropagation();
    data.unselectAllAction();
  }

  return (data.isGrantable || grantMode === "disable") && (
    <div
      className={classes[`panelRoot-${data.variant}`]}
      style={{backgroundColor: data.backgroundColor ? data.backgroundColor : ""}}
    >
      <div className={classes[`panelHeader-${data.variant}`]}>
        <div>
          <RoleCheckBox item={data} variant={variant}/>
        </div>
        <div>
          { data.hasSelectActions && (
            <>
              <span
                className={classes.dropPanelActionItem}
                onClick={handleSelectAll}
              >
                {i18next.t("userProfile.selectAll")}
              </span>
              <span
                className={classes.dropPanelActionItem}
                onClick={handleUnselectAll}
              >
                {i18next.t("userProfile.unselect")}({getNumberOfActiveItems(data.items)})
              </span>
            </>
          )}
          <span>
            <EAIcon
              icon={"arrow/arrow-to-up_large"}
              style={{rotate: isOpen ? "" : "180deg", cursor: "pointer"}}
              onClick={() => setIsOpen(!isOpen)}
            />
          </span>
        </div>
      </div>

      {/* Content */}
      {isOpen &&
        <div className={classes[`itemsWrapper-${data.variant}`]}>
          {data.items.map((item, index) => (
            <EventPrivilegeBlock
              key={index}
              data={item}
              grantMode={grantMode}
              variant={variant}
            />
          ))}
        </div>
      }
    </div>
  )
}

export default EventPrivilegeBlock;
