import { Box, Tooltip, Typography } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import DocViewerCard from "../../components/doc-viewer/DocViewerCard";
import DocViewerTable from "../../components/doc-viewer/DocViewerTable";
import { formatDate } from "../../services/common/Utils";
import DocViewerFromJson from "components/doc-viewer/DocViewerFromJson";

const getBlockValue = (isMatched) => {
    const label = i18next.t(`viewer.extractedContent.${isMatched ? "ok" : "notOk"}`);
    return (
        <Box display="flex" justifyContent="space-between" noTooltip>
            <Tooltip title={label} arrow>
                <span style={{
                        fontWeight: 400,
                        fonSize: "12px",
                        lineHeight: "130%",
                        backgroundColor: isMatched ? "inherit" : "#FFEBCC"
                    }}
                >
                    {label}
                </span>
            </Tooltip>
        </Box>
    )
}

const buildBody = (contents) => {
    let body = [];
    contents.forEach(content => {
        if (content.matchingValue !== null) {
            const label = content.type.includes('ADRESSE_') || content.type.includes('DENOMINATION_') || content.type.includes('DATE_')
              ? i18next.t(`viewer.extractedContent.${content.type}`)
              : content.type
            body.push([label, getBlockValue(content.found)])
        }
    });
    return body;
}


const AnalyzedOnBloc = ({date}) => {
    return (
        <Typography style={{fontWeight: 700, fontSize: "14px"}}>
            {`${i18next.t("viewer.analyzed")} ${i18next.t(i18next.language === "fr" ? 'the' : 'on')} `}
            <span style={{fontWeight: 400, fontSize: "14px"}}>{formatDate(date)}</span>
        </Typography>
    )
}

function PanelExtractedData({documentCode, contents, extractorResults}) {
    const [cardTitle, setCardTitle] = useState();
    const [cardBody, setCardBody] = useState();

    useEffect(() => {
        let cardTitle = i18next.t('viewer.extractedContent.title');
        if(documentCode !== "RIB_IBAN") {
            cardTitle += " - Bêta"
        }

        let extractDate;
        if(contents) {
            extractDate = new Date(Math.max.apply(null, contents.map(x=>new Date(x.creationDate))));
            setCardTitle([cardTitle, <AnalyzedOnBloc date={extractDate}/>])
            setCardBody(buildBody(contents));
        } else if(extractorResults) {
            setCardTitle([cardTitle, i18next.t("viewer.extractor.title")])
        } else {
            setCardTitle([cardTitle, i18next.t("viewer.notAnalyzed")])
        }
    }, [documentCode, contents, extractorResults]);

    return (
        <DocViewerCard
            title={cardTitle}
            body={
                extractorResults ?
                <DocViewerFromJson
                    body={extractorResults}
                    hideFirstLevelKey={true}
                    hideSecondLevelKey={true}
                    keysToExclude={['decision']}
                    translationPrefix='viewer.extractor'
                    translateValuesForKeys={['insuranceType']}
                    openRowsOnLoad={true}
                    /> 
                    :
                <DocViewerTable
                        {...(cardBody?.length > 0 ?
                        {
                            body: cardBody,
                            noTranslation: true
                        }
                        : {})
                    }
                    />
            }
            
        />
    );
}

export default PanelExtractedData;
