import React from "react";
import DataTableActions from "../../../components/common/datatable/DataTableActions";
import i18next from "i18next";
import {
  dateValueFormatter,
  EADataGridCell,
} from "../../../components/common/datatable/EADataGrid";
import InscriptionStatusChip from "../../../components/common/InscriptionStatusChip";
import MainHeaderCell from "../../../components/common/datatable/MainHeaderCell";
import { PrivilegeEnum } from "services/common/RolesUtils";

export const getInitialContactsTableSort = () => [
  {
    field: "name",
    sort: "asc",
  },
];

const contactsTableActions = (
  contactsIds,
  email,
  openDeleteContactsPopup,
  openReinviteContactsPopup,
  status,
  hasAnyOfPrivileges
) => {
  return [
    {
      label: i18next.t("delete"),
      icon: "delete_grey",
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_CONTACT) || (status === "INSCRIT"),
      action: () => {
        openDeleteContactsPopup(contactsIds, email);
      },
    },
    {
      label: i18next.t("reinvite"),
      icon: "reinvit",
      isHidden: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_CONTACT) || (status === "INSCRIT"),
      action: () => {
        openReinviteContactsPopup(contactsIds, email);
      },
    },
  ];
};

export const ContactsDefaultVisibilityColumns = () => {
  return {
    phone: false,
    lastactivitydate: false
  };
};

export const contactsTableColumns = (
  openDeleteContactsPopup,
  openReinviteContactsPopup,
  hasAnyOfPrivileges
) => {
  return [
    {
      field: "name",
      headerName: i18next.t("contactsTable.columns.name"),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("contactsTable.columns.name")} />
      ),
      renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
    },
    {
      field: "firstname",
      headerName: i18next.t("contactsTable.columns.firstname"),
      flex: 0.1,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("contactsTable.columns.firstname")} />
      ),
      renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
    },
    {
      field: "function",
      headerName: i18next.t("contactsTable.columns.function"),
      flex: 0.08,
      renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
    },
    {
      field: "email",
      headerName: i18next.t("contactsTable.columns.email"),
      flex: 0.15,
      renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
    },
    {
      field: "phone",
      headerName: i18next.t("contactsTable.columns.phone"),
      flex: 0.1,
      renderCell: (params) => <EADataGridCell disableTranslation>{params.value}</EADataGridCell>,
    },
    {
      field: "lastactivitydate",
      headerName: i18next.t("contactsTable.columns.lastactivitydate"),
      headerAlign: "center",
      flex: 0.08,
      type: "date",
      align: "center",
      renderCell: (params) => (
        <EADataGridCell disableTranslation>{dateValueFormatter(params)}</EADataGridCell>
      ),
    },
    {
      field: "status",
      headerName: i18next.t("contactsTable.columns.status"),
      flex: 0.08,
      type: "singleSelect",
      headerAlign: "center",
      align: "center",
      valueOptions: [
        { value: "INSCRIT", label: i18next.t(`contactsTable.status.INSCRIT`) },
        { value: "INVITE", label: i18next.t(`contactsTable.status.INVITE`) },
        {
          value: "INVIATION EXPIREE",
          label: i18next.t(`contactsTable.status.INVIATION EXPIREE`),
        },
      ],
      valueFormatter: (params) =>
        i18next.t(`contactsTable.status.${params.value}`),
      renderCell: (params) => {
        return <InscriptionStatusChip registrationStatus={params.value} />;
      },
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      headerAlign: "center",
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableReorder: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <DataTableActions
                actions={contactsTableActions(
                  [params.row.id],
                  params.row.email,
                  openDeleteContactsPopup,
                  openReinviteContactsPopup,
                  params.row.status,
                  hasAnyOfPrivileges
                )}
              />
            </div>
          </>
        );
      },
    },
  ];
};
