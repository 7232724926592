import React, { useCallback, useContext, useEffect, useState } from "react";
import { formatAttributeValues, getAttributeValueDisplay } from "../../services/common/AttributeUtils";
import AttributeValueEditDialog from "./AttributeValueEditDialog";
import { Context } from "../../states/Store";
import useStyles from "../../style/js-style/containers/thirdparties/sheet/attributes/AttributeValuesDataTableStyle.js";
import {
  getDossierAttributes,
  getThirdpartyAttributes,
} from "../../services/edge/AttributesService";
import { formatDate } from "../../services/common/Utils";
import i18next from "i18next";
import DataTableActions from "../../components/common/datatable/DataTableActions";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
import EAIcon from "../../components/common/EAIcon";
import { EANoData } from "../../components/common/EANoData";
import SkeletonAttributes from "../../components/common/skeleton/SkeletonAttributes";
import { PrivilegeEnum } from "../../services/common/RolesUtils";
import { getThirdpartyCalculatedAttributes } from "../../services/edge/ThirdpartyService";
import { AttributeType } from "services/common/Constants";
import Scrollbars from "react-custom-scrollbars-2";

function AttributeValuesDataTable({
  id,
  bondType,
  hasAnyOfPrivileges,
  hasAnyOfPrivilegesOnScopedResource
}) {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoader] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const canViewAttribute = useCallback((attribute) => {
    let bCanView = false;

    switch (attribute.bondType) {
      case "thirdparty":
        if (attribute.type === "calculated") {
          bCanView = hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES);
        } else {
          bCanView = hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES) ||
            ([AttributeType.LIST_MULTIPLE].includes(attribute.type) &&
              hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES))
        }

        break;

      case "dossier":
        bCanView = hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES) ||
          ([AttributeType.LIST_MULTIPLE].includes(attribute.type) &&
            hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES));

        break;

      default:
        throw new Error("Wrong bond type");
    }

    return bCanView;
  }, [hasAnyOfPrivileges, hasAnyOfPrivilegesOnScopedResource]);

  const canEditAttribute = useCallback((attribute) => {
    let bCanEdit = false;

    switch (attribute.bondType) {
      case "thirdparty":
        if (attribute.type === "calculated") {
          bCanEdit = false;
        } else {
          bCanEdit = hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY) ||
            (
              hasAnyOfPrivileges(
                PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY,
                PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY
              ) &&
              [AttributeType.LIST_MULTIPLE].includes(attribute.type)
            );
        }

        break;

      case "dossier":
        bCanEdit = hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER) ||
          (
            hasAnyOfPrivileges(
              PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER,
              PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER
            ) &&
            [AttributeType.LIST_MULTIPLE].includes(attribute.type)
          );

        break;

      default:
        throw new Error("Wrong bond type");
    }

    return bCanEdit;
  }, [hasAnyOfPrivileges, hasAnyOfPrivilegesOnScopedResource]);

  // Fetch Attributes values
  const fetchAttributeValuesList = useCallback(async () => {
    setLoader(true);
    try {
      let result;

      switch (bondType) {
        case "thirdparty":
          result = await getThirdpartyAttributes(state.account.id, id, null);
          break;

        case "dossier":
          result = await getDossierAttributes(state.account.id, id, null);
          break;

        default:
          throw new Error("Wrong bond type");
      }

      const mappedAttributes = result.content?.map((x) => ({
        ...x,
        id: x.label,
        shownValues: getAttributeValueDisplay(x.type, formatAttributeValues(x)),
      }));

      // Récupère et injecte les attributs calculés
      if (bondType === "thirdparty") {
        const result = await getThirdpartyCalculatedAttributes(
          state.account.id,
          id,
          dispatch
        );

        mappedAttributes.push(...result.content.map((attribute) => {
          return {
            id: attribute.id,
            label: attribute.name,
            bondType: "thirdparty",
            type: "calculated",
            creationDate: null,
            updateDate: attribute.resultLastUpdateDate,
            values: null,
            shownValues: attribute.resultValue,
            color: attribute.resultColorCode
          }
        }))
      }

      const orderedAttributes = mappedAttributes?.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setAttributes(orderedAttributes.filter(attr => canViewAttribute(attr)));
      setLoader(false);
    } catch (error) {
      setAttributes([]);
      setLoader(false);
    }
  }, [id, state.account, bondType, canViewAttribute, dispatch]);

  useEffect(() => {
    if (state.account && id) {
      fetchAttributeValuesList();
    }
  }, [id, state.account, fetchAttributeValuesList]);

  const openEditDialog = (attributeValue) => {
    setIsEditDialogOpen(true);
    setSelectedAttribute(attributeValue);
  };

  const getAttributeDate = (attribute) => {
    return attribute.updateDate !== null
      ? formatDate(attribute.updateDate)
      : attribute.creationDate !== null
        ? formatDate(attribute.creationDate)
        : " ";
  };

  return !loading && attributes.length > 0 ? (
    <div
      className={classes.mainRoot}
    >
      <div className={classes.root} style={{ height : bondType === "dossier" ? "260px" : "288px" }}>
        <Scrollbars  autoHideTimeout={1000} autoHideDuration={500} universal={true}>
          {attributes.map((attribute, index) => {
            return (
              <div className={classes.attributeItemWrapper} key={index}>
                {/* Label */}
                <div
                  className={
                    bondType === "dossier"
                      ? classes.attributeItemLabelDossier
                      : classes.attributeItemLabel
                  }
                >
                  <EAIcon
                    icon={bondType + "-label_green"}
                    className={classes.attributeItemImg}
                  />
                  <Tooltip title={attribute.type === "calculated" ? attribute.label : attribute.id}>
                    <span
                      className={
                        bondType === "dossier"
                          ? clsx(classes.attributeName, classes.breakWord, 'dt')
                          : clsx(classes.attributeNameDossier, classes.breakWord, 'dt')
                      }
                    >
                      {attribute.type === "calculated" ? attribute.label : attribute.id}
                    </span>
                  </Tooltip>
                </div>

                {/* Date */}
                <div
                  className={
                    bondType === "dossier"
                      ? classes.attributeItemDateDossier
                      : classes.attributeItemDate
                  }
                >
                  <div className={classes.attributeItemDateModifyLabel}>
                    {i18next.t("modifiedOn")}
                  </div>
                  <div className={clsx(classes.attributeItemDateModifyDate, 'dt')}>
                    {getAttributeDate(attribute)}
                  </div>
                </div>

                {/* Values */}
                <div className={classes.attributeItemValues}>
                  <Tooltip
                    title={
                      attribute.type !== "calculated"
                        ? attribute.shownValues
                        : i18next.t("calculator.attribute.attributeIsNotEditable")
                    }
                  >
                    <div className={clsx(classes.shownValues, classes.breakWord)}>
                      {attribute.type === "calculated" &&
                        <span className={classes.attributesItemColor} style={{ backgroundColor: attribute.color || "#E2E2EA" }} />
                      }
                      {attribute.shownValues === "Non renseigné"
                        ? (attribute.shownValues)
                        : (<span className='dt'>{attribute.shownValues}</span>)
                      }
                    </div>
                  </Tooltip>
                  <div className={classes.attributeItemValuesEdit}>
                    {/* Edit */}
                    {attribute.type !== "calculated" &&
                      <DataTableActions
                        actions={[
                          {
                            label: i18next.t("modify"),
                            isDisabled: !canEditAttribute(attribute),
                            icon: "edit_grey_full",
                            action: () => openEditDialog(attribute),
                          },
                        ]}
                      />
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </Scrollbars>
        {/* Pop-up edition */}
        {isEditDialogOpen && canEditAttribute(selectedAttribute) && (
          <AttributeValueEditDialog
            bondType={bondType}
            id={id}
            attributeValue={selectedAttribute}
            isOpen={isEditDialogOpen}
            onCancel={() => setIsEditDialogOpen(false)}
            onValidateSuccess={fetchAttributeValuesList}
          />
        )}
      </div>
    </div>
  ) : loading
    ? <SkeletonAttributes type={bondType} />
    : (
      <div
        className={
          bondType === "dossier"
            ? classes.noDataRootDossier
            : classes.noDataRootThird
        }
      >
        <EANoData
          icon={"noAttributes-" + bondType}
          label={i18next.t("attributes.noAttribute", {
            type: i18next.t("attributes." + bondType),
          })}
        />
      </div>
    );
}
export default AttributeValuesDataTable;
