import React from "react";
import {
  Typography,
} from "@mui/material";
import i18next from "i18next";

import EAIcon from "../../../../../components/common/EAIcon";
import useStyles from "./DocumentManagementDialogStyle";
import {isEmpty} from "lodash";


const DocumentManagementRecapBloc = ({
  docToAddList = [],
  docToRemoveList = [],
  dossierModelList = []}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.modelInfoBlocWrapper}>
      {/* Un document est ajouté */}
      {!isEmpty(docToAddList) &&
      <div className={classes.deleteBloc}>
          <div className={classes.modelInfoCaption}>
            <span className={classes.imageWrapper}>
            <EAIcon icon={"add_green"} className={classes.imgPlusContent} />
            </span>
            <Typography className={classes.modelInfoBlocTitle}>{i18next.t("demandes.documentManagement.confirmDialog.add")}</Typography>
          </div>
          {docToAddList?.sort((a, b) => a.name.localeCompare(b.name))?.map((doc) => (
            <Typography key={doc.index} className={classes.modelInfoBlocContent}>
              {doc.name} {`(${doc.code})`}
            </Typography>
          ))}
      </div>
      }

      {/* Au moins un document est supprimé */}
      {!isEmpty(docToRemoveList) &&
      <div className={classes.deleteBloc}>
      {(docToRemoveList.length > 1 || !isEmpty(dossierModelList) || !isEmpty(docToAddList)) ? (
        <>
          <div className={classes.modelInfoCaption} style={{marginTop: !isEmpty(docToAddList) ? "15px" : ""}}>
          <span className={classes.imageWrapper}>
            <EAIcon icon={"delete_red"} className={classes.imgDeleteContent} />
          </span>
            <Typography className={classes.modelInfoBlocTitle}>{i18next.t("demandes.documentManagement.confirmDialog.delete")}</Typography>
          </div>
          {docToRemoveList?.sort((a, b) => a.name.localeCompare(b.name))?.map((doc) => (
            <Typography key={doc.index} className={classes.modelInfoBlocContent}>
              {doc.name} {`(${doc.code})`}
            </Typography>
          ))}
        </>
      ) : (
        <Typography key={docToRemoveList[0].index} className={classes.modelInfoBlocContent} style={{textAlign: 'center'}}>
          {docToRemoveList[0]?.name} {`(${docToRemoveList[0]?.code})`}
        </Typography>
      )}
      </div>
      }

      {/* Au moins un dossier est concerné */}
      {!isEmpty(dossierModelList) &&
      <div className={classes.deleteBloc}>
          <div className={classes.modelInfoCaption} style={{marginTop: "15px"}}>
          <span className={classes.imageWrapper}>
            <EAIcon icon={"circled_cross_black"} className={classes.imgContentCross} />
            </span>
            <Typography className={classes.modelInfoBlocTitle}>
              {i18next.t("demandes.documentManagement.confirmDialog.impactedModels", {
                document: docToRemoveList.length > 1
                  ? i18next.t("demandes.documentManagement.confirmDialog.manyDocument")
                  : i18next.t("demandes.documentManagement.confirmDialog.theDocument"),
                dossierModele: dossierModelList.length > 1
                  ? i18next.t("demandes.documentManagement.confirmDialog.manyDossierModele")
                  : i18next.t("demandes.documentManagement.confirmDialog.theDossierModele")
              })}
            </Typography>
          </div>
          {dossierModelList?.sort((a, b) => a.libelle.localeCompare(b.libelle))?.map((d) => (
            <Typography key={d.id} className={classes.modelInfoBlocContent}>
              {d.libelle}
            </Typography>
          ))}
      </div>
      }
    </div>

  )
};


export default DocumentManagementRecapBloc;
