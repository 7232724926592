import { Chip } from "@mui/material";
import DataTableActions from "components/common/datatable/DataTableActions";
import { EADataGridCell } from "components/common/datatable/EADataGrid";
import MainHeaderCell from "components/common/datatable/MainHeaderCell";
import i18next from "i18next";
import moment from "moment";
import { PrivilegeEnum } from "services/common/RolesUtils";

const STATUT_DONE = "DONE";
const STATUT_INVIT = "INVIT";
const STATUT_ENCOURS = "PROCESS";
const STATUT_ERROR = "ERROR";


const importsTableActions = ( importId, statut, hasAnyOfPrivileges, 
        actionInvitationHandler, uploadFileHandler, uploadRapportImportHandler)  => {
    return [
    {
        label: i18next.t("imports.table.actionFileImport"),
        icon: "file_download",
        action: () =>  uploadFileHandler(importId),
        keepWidth: true,
        isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY)
    },
    statut && statut.toString() !== STATUT_ENCOURS? {
      label: i18next.t("imports.table.actionRapport"),
      icon: "download_grey_darker",
      keepWidth: true,
      action: () => uploadRapportImportHandler(importId),
      isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY)
  }: null,
  statut && statut.toString() === STATUT_INVIT?
  {
    label: i18next.t("imports.table.actionsInvit"),
    icon: "send_invitation",
    keepWidth: true,
    action: () => actionInvitationHandler(importId),
    isDisabled: !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY)
} : null,
    ].filter(action => action !== null);
};

const getStatutFromStatutCode = (statut) => {
  if(statut === STATUT_INVIT) {
      return {
        style: {color: "#995C00", backgroundColor: "#FFF7EB", fontWeight: 700}, 
        label: i18next.t("imports.table.importStatusInviation")
      } 
  } else if(statut === STATUT_DONE) {
    return {
      style: {color: "#227730", backgroundColor: "#EFFBF1", fontWeight: 700}, 
      label:  i18next.t("imports.table.importStatusDone")
    }
  } else if(statut === STATUT_ERROR) {
    return {
      style: {color: "#990001", backgroundColor: "#FFEBEB", fontWeight: 700}, 
      label:  i18next.t("imports.table.importStatusError")
    }
  } else {
    return{ 
      style : {color: "#303F9F", backgroundColor: "#E8EAF6", fontWeight: 700}, 
      label:  i18next.t("imports.table.importStatusProcess")
    }
  }
}

export const importsListColumns = (hasAnyOfPrivileges, actionInvitationHandler, uploadFileHandler, uploadRapportImportHandler) => {
return [
  {
    field: "name",
    headerName: i18next.t("imports.table.label"),
    flex: 0.3,
    renderHeader: () => (
      <MainHeaderCell name={i18next.t("imports.table.label")} />
    ),
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
                            
  },
  {
    field: "filename",
    headerName: i18next.t("imports.table.fileName"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "type",
    headerName: i18next.t("imports.table.type"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "numberAdded",
    headerName: i18next.t("imports.table.addedThirdParties"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "numberUpdated",
    headerName: i18next.t("imports.table.updatedThirdParties"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "numberArchived",
    headerName: i18next.t("imports.table.closedThirdParties"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "numberErrors",
    headerName: i18next.t("imports.table.errors"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "numberWarnings",
    headerName: i18next.t("imports.table.warnings"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "importUserName",
    headerName: i18next.t("imports.table.importBy"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "importDate",
    headerName: i18next.t("imports.table.importDate"),
    flex: 0.1,
    renderCell: (params) =>  <EADataGridCell>{params.value ? moment(params.value).format(i18next.t("date.fullFormatWithSecond")): "" }</EADataGridCell>
  },
  {
    field: "invitationUserName",
    headerName: i18next.t("imports.table.invitedBy"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value}</EADataGridCell>
  },
  {
    field: "invitationDate",
    headerName: i18next.t("imports.table.invitedDate"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell>{params.value ? moment(params.value).format(i18next.t("date.fullFormatWithSecond")): "" }</EADataGridCell>
  },
  {
    field: "statut",
    headerName: i18next.t("imports.table.status"),
    flex: 0.1,
    renderCell: (params) => <EADataGridCell tooltip={false}>
        <Chip label={getStatutFromStatutCode(params.value).label} style={getStatutFromStatutCode(params.value).style}/>
      </EADataGridCell>
  },
  {
    field: "action",
    headerName: i18next.t("imports.table.actions"),
    flex: 0.16,
    sortable: false,
    filterable: false,
    disableExport: true,
    hideSortIcons: true,
    disableReorder: true,
    disableColumnMenu: true,
    disableMultipleSelection: true,
    disableSelectionOnClick: true,
    isRowSelectable: false,
    hideFooterSelectedRowCount: true,
    align: "center",
    renderCell: (params) => {
      return (
        <div className="dt" style={{ textAlign: "center" }}>
          <DataTableActions
            actions={importsTableActions(
              params.row.importId,
              params.row.statut,
              hasAnyOfPrivileges, 
              actionInvitationHandler, 
              uploadFileHandler, 
              uploadRapportImportHandler
            )}
          />
        </div>
      );
    },
  }, 
];
};