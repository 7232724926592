import { Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { scrollToTop } from "../../components/common/EASrollToTop";
import useStyles from "../../style/js-style/containers/doc-viewer/PanelHistoricStyle";
import EAIcon from "../../components/common/EAIcon";
import { getDepositorOrTransmitter } from "../../services/common/ThirdpartyUtils";
import { isInvalidStatusReason, isTransmittedByApi } from "../../services/common/DocumentUtils";
import { formatDate } from "services/common/Utils";
import clsx from "clsx";

const HistoricItem = ({ data, handleClick }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.historicItemRow}>
      <div className={classes.historicItemData}>
        {/* Decoration */}
        <div className={classes.historicItemGraphic}>
          <div className={classes.historicItemGraphicBubble} />
          <div className={classes.historicItemGraphicLine} />
        </div>
        <div className={classes.historicItem}>
          <div className={classes.historicItemDetail}>
            <div className={classes.historicItemDetail}>
              {/* Date */}
              <div className={classes.historicItemDataDate}>
                <span> {i18next.t("from")} </span>
                <span className={classes.historicItemDataDateBold}>
                  {formatDate(data.startDate)}
                </span>
                {data.endDate && (
                  <>
                    <span> {i18next.t("to")} </span>
                    <span className={classes.historicItemDataDateBold}>
                      {formatDate(data.endDate)}
                    </span>
                  </>
                )}
              </div>

              {/* Additional data */}
              {data.type === "deposit" && data.additional && (
                <div className={classes.historicItemDataDeposit}>
                  <EAIcon icon={data.icon} className={classes.smallIcon} />
                  <span>
                    {i18next.t("depositedBy")} <span className="dt">{data.additional}</span>
                  </span>
                </div>
              )}

              {data.type === "request" && (
                <div className={classes.historicItemDataDeposit}>
                  {i18next.t("documentAsked")}
                </div>
              )}
            </div>
            {/* Link */}
            {handleClick && data.type === "deposit" && (
              <div className={classes.historicItemLink}>
                <Link
                  component="button"
                  onClick={() => {
                    handleClick(data);
                    scrollToTop();
                  }}
                >
                  <EAIcon icon={"open-eye"} />
                </Link>
              </div>
            )}
          </div>
          {(isInvalidStatusReason.includes(data?.statusReason)) && (
            <div className={clsx(classes.historicItemStatus, classes.historicItemDataDeposit)}>
              <span>{i18next.t(`viewer.status.validation.${data?.statusReason}`)} {" le "} {formatDate(data?.statusCreationDate)}</span>
              {data?.statusReason === "REFUS_CLIENT" && data?.statusActorEmail && `, par ${data?.statusActorEmail}`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapToHistoricItem = (id, startDate, endDate, additional, type, icon, responseId, statusReason, statusReasonDetail, statusActorEmail, statusCreationDate) => {
  return { id, startDate, endDate, additional, type, icon, responseId, statusReason, statusReasonDetail, statusActorEmail, statusCreationDate };
};

function PanelHistoric({ historic, onSelectHistoricEvidence, setShowPanelStatus }) {
  const { classes } = useStyles();
  const [mappedHistoric, setMappedHistoric] = useState();

  useEffect(() => {
    // Mapped historic responses
    let historicResult = historic.map((data) =>
      mapToHistoricItem(
        data.id,
        data.uploadDate,
        data.expirationDate,
        getDepositorOrTransmitter(data),
        "deposit",
        isTransmittedByApi(data.transmitter) ? "api_icon" : "person-grey-light",
        data.responseId,
        data.statusReason,
        data.statusReasonDetail,
        data.statusActorEmail,
        data.statusCreationDate
      )
    );

    // Mapped historic unique requests
    const requests = historic
      .map((data) =>
        mapToHistoricItem(
          null,
          data.requestDate,
          data.requestEndDate,
          null,
          "request"
        )
      )
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.startDate === value.startDate)
      );

    historicResult = [...historicResult, ...requests];

    // Order results by start Date
    historicResult = historicResult?.sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate)
    );

    setMappedHistoric(historicResult);
  }, [historic]);

  return (
    <div className={classes.root}>
      {mappedHistoric?.map((data, index) => (
        <HistoricItem
          key={index}
          data={data}
          handleClick={() => {
            onSelectHistoricEvidence(data)
            if((index === 0 && data.type === "deposit") || (index === 1 && mappedHistoric[0].type !== 'deposit')) {
              setShowPanelStatus(true)
            }else{
              setShowPanelStatus(false)
            }
          }}
        />
      ))}
    </div>
  );
}

export default PanelHistoric;
