import { getEnv } from "config/Config";
import { eaFetch } from "services/common/ApiUtils";


export async function activateDataBlocksForThirdParty(accountId, thirdpartyId, datablock, dispatch) {
    return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "POST",
      `account/${accountId}/thirdparties/${thirdpartyId}/datablocks/start?datablock=` + datablock,
    );
  }
  export async function deactivateDataBlocksForThirdParty(accountId, thirdpartyId, datablock, dispatch) {
    return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "POST",
      `account/${accountId}/thirdparties/${thirdpartyId}/datablocks/desactivate?datablock=` + datablock,
    );
  }
  export async function getEvolutionScoreDataBlocks(accountId, thirdpartyId, datablock, dispatch) {
    return eaFetch(
      dispatch,
      getEnv("REACT_APP_EDGE"),
      "GET",
      `account/${accountId}/thirdparties/${thirdpartyId}/datablocks/evolution?datablock=` + datablock,
    );
  }

export async function getFinancialStrengthCount(accountId, dispatch, signal) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/risks/financial-strength`,
    null, null, null, signal
  );
}