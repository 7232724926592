import React, { useContext, useEffect, useState } from "react";
import { Collapse, useMediaQuery, Tooltip } from "@mui/material";
import clsx from "clsx";
import { Context } from "states/Store";
import i18next from "i18next";

import useStyles from "style/js-style/containers/administration/roles/privileges/ConfigurationLineStyle";
import SwitchButton from "containers/administration/roles/privileges/common/SwitchButton";
import RessourcePagination from "containers/administration/roles/privileges/common/RessourcePagination";
import EAIcon from "components/common/EAIcon";
import { getAccountRequirements } from "services/edge/AccountService";


/**
 *
 * @param title
 * @param actions
 * @param type subItem | default | dropAction
 * @param variant
 * @returns {JSX.Element}
 * @constructor
 */
function ConfigurationLine({ title, actions, subActions, type = "default", variant = "default", authorizedDocumentTypes = [], translation = true }) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const max1420screen = useMediaQuery("(max-width:1420px)");
  const [state, dispatch] = useContext(Context);
  const [documentTypes, setDocumentTypes] = useState();
  const [documentTypesIds, setDocumentTypesIds] = useState([]);
  const [documentTypesIdsAcquisitionDO, setDocumentTypesIdsAcquisitionDO] = useState([]);


  const [docPage, setDocPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    if (state.account && type === "dropActionPerDoc") {
      (async function fetchData() {
        try {
          const results = await getAccountRequirements(state.account.id, dispatch);

          setDocumentTypesIds(results.content.filter((x) =>
            authorizedDocumentTypes.length > 0 ? authorizedDocumentTypes.includes(x.documentId) : true
          ).map((x) => x.documentId));

          setDocumentTypesIdsAcquisitionDO(results.content.filter((x) =>
            authorizedDocumentTypes.length > 0 ? authorizedDocumentTypes.includes(x.documentId)
              && x.acquisition === "DO" : x.acquisition === "DO"
          ).map((x) => x.documentId));

          // Hide unautorized document types
          setDocumentTypes(
            results.content.filter((x) =>
              authorizedDocumentTypes.length > 0 ? authorizedDocumentTypes.includes(x.documentId) : true
            ).map((x) => {
              return {
                value: x.documentId,
                label: x.descriptionFr,
                disabled: authorizedDocumentTypes.length > 0 && !authorizedDocumentTypes.includes(x.documentId)
              }
            })
          );



        } catch (error) { }
      })();
    }
  }, [state.account, dispatch, authorizedDocumentTypes, type]);

  const returnListByName = (name) => {
    const isAnswerButton = name === i18next.t("roles.switchButton.answer");
    return isAnswerButton ? documentTypesIdsAcquisitionDO : documentTypesIds;
  }

  switch (type) {
    case 'default':
      return (
        <div className={clsx(classes.rootDefault, max1420screen && classes.rootColumn)} >
          <div className={clsx(classes.titleWithIconWrapper, max1420screen && classes.titleFull)}>
            <div className={clsx(variant === "caseSensitive" ? classes.titleLower : classes.title, translation === false ? classes.dt : '')}>
              {title}
            </div>
          </div>
          <div className={classes.blocWrapper}>
            <div className={clsx(classes.buttonWrapper, max1420screen && classes.buttonWrapperFull)}>
              {actions.map((item, index) => (
                <SwitchButton key={index} name={item.name} action={item.action} isActive={item.isActive} isDisabled={!item.isGrantable} />
              ))}
            </div>
          </div>
        </div>
      )
    case 'subItem':
      return (
        <div className={clsx(classes.rootSubItem, max1420screen && classes.rootColumn)}  >
          <div className={clsx(classes.titleWithIconWrapper, max1420screen && classes.titleFull)}>
            <EAIcon icon="arrow/arrow-angle_grey" className={classes.arrowIcon} />
            <div className={classes.titleSubItem}>{title}</div>
          </div>
          <div className={classes.blocWrapper} >
            <div className={clsx(classes.buttonWrapper, max1420screen && classes.buttonWrapperFull)} >
              {actions.map((item, index) => (
                <SwitchButton key={index} name={item.name} action={item.action} isActive={item.isActive} isDisabled={!item.isGrantable} />
              ))}
            </div>
          </div>
        </div>
      )
    case 'dropAction':
      return (
        <div className={clsx(classes.rootDropActions, max1420screen && classes.rootColumn)}>
          <div className={clsx(classes.titleWithIconWrapper, max1420screen && classes.titleFull)}>
            <EAIcon icon="roles/drop-down-actions" className={classes.actionsIcon} />
            <div className={classes.titleSubItem}>{title}</div>
          </div>
          <div className={classes.blocWrapper}>
            <div className={clsx(classes.buttonWrapper, max1420screen && classes.buttonWrapperFull)}>
              {actions.map((item, index) => (
                <SwitchButton key={index} name={item.name} action={item.action} isActive={item.isActive} isDisabled={!item.isGrantable} />
              ))}
            </div>
          </div>
        </div>
      )
    case 'dropActionPerDoc':
      return (
        <div className={clsx(classes.rootDropActionsWithList)}>
          <div className={classes.headerWrapper} onClick={() => setIsOpen(!isOpen)}>
            <EAIcon icon="arrow/arrow-to-up_large_green" className={isOpen ? classes.caretIcon : classes.caretIconReverse} />
            <div className={max1420screen ? classes.titleWithIconWrapperMin : classes.titleWithIconWrapperAction}>
              <EAIcon icon="roles/drop-down-actions" className={classes.actionsIcon} />
              <div className={classes.titleSubItemAction}>{title}</div>
            </div>
            <div className={classes.blocWrapper}>
              <div className={clsx(classes.buttonWrapper)}>
                {actions.map((item, index) => (
                  <SwitchButton
                    key={index}
                    name={item.name}
                    action={item.action}
                    isActive={item.isActive(returnListByName(item.name))}
                    isDisabled={!item.isGrantable || documentTypes?.length === 0}
                    ressourcesList={returnListByName(item.name)}
                  />
                ))}
              </div>
            </div>
          </div>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <div className={classes.blocListWrapper} >
              {documentTypes && documentTypes
                .slice((docPage - 1) * itemsPerPage, docPage * itemsPerPage) // Pagination logic
                .map((item1, index1) => (
                  <div className={clsx(classes.documentRoles)}>
                    <EAIcon icon={"document_grey2"} className={classes.documentIcon} />
                    <Tooltip title={item1.label} placement="top">
                      <span className={classes.documentName}> {item1.label}</span>
                    </Tooltip>
                    {subActions.map((item2, index2) => (
                      <SwitchButton
                        key={`${index1}bt${index2}`}
                        name={item2.name}
                        action={item2.action}
                        isActive={item2.isActive(item1.value)}
                        isDisabled={!item2.isGrantable || item1.disabled || !returnListByName(item2.name).includes(item1.value)}
                        ressourceId={item1.value}
                      />
                    ))}
                  </div>
                ))}
              <RessourcePagination
                totalRecords={documentTypes?.length}
                pageSize={itemsPerPage}
                onPageChange={(page) => {
                  setDocPage(page);
                }}
              />
            </div>
          </Collapse>
        </div >
      )
    default: return;
  }
}

export default ConfigurationLine;
