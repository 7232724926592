import React from "react";
import { Typography } from "@mui/material";
import EAIcon from "./EAIcon";
import i18next from "../../i18n/i18n";
import useStyles from "../../style/js-style/components/common/TitleWithIconStyle";
import { Skeleton } from "@mui/material";
import clsx from "clsx";

const TitleIconAndNotification = ({icon, notificationCounter}) => {
  const { classes } = useStyles();
  return (
    <div
      style={{
        position: "relative",
        marginRight: notificationCounter !== 0 ? "5px" : "",
      }}
    >
      <EAIcon
        icon={icon}
        className={classes.icon}
        wrapperClass={classes.iconWrapper}
      />
      {notificationCounter !== 0 && (
        <div className={classes.notificationCounter}>{notificationCounter}</div>
      )}
    </div>
  );
};

const TitleResult = ({ noResult, isLoading, resultCount, totalCount }) => {
  const { classes } = useStyles();
  return (
    <>
      {!noResult && <div className={classes.separator} />}
      {isLoading ? (
        <Skeleton
          variant="rect"
          width={50}
          height={12}
          className={classes.resultCount}
          animation="wave"
        />
      ) : (
        !noResult && (
          <>
            <span className={classes.resultCount}>
              {resultCount}&nbsp;
            </span>
            <span className={classes.resultCount}>
              {i18next.t("result", { count: resultCount })}&nbsp;
            </span>
            {totalCount !== undefined && resultCount !== totalCount && (
              <span className={clsx(classes.resultCount, classes.totalCount)}>
                sur un total de {totalCount}
              </span>
            )}
          </>
        )
      )}
    </>
  );
};
/**
 * Title block with icon & result's counter for sheet-pages
 *
 * @param title
 * @param icon string : icon's url
 * @param resultCount
 * @param totalCount
 * @param isLoading
 * @param noResult
 * @param otherProps
 */
const TitleWithIcon = ({
  title,
  icon,
  resultCount,
  totalCount,
  isLoading = false,
  noResult = false,
  notificationCounter = 0,
  disabled,
  ...otherProps
}) => {
  const { classes } = useStyles();

  return (
    <Typography
      variant="h2"
      className={clsx({
        [classes.title]: true,
        [classes.disabledTitle]: disabled,
      })}
      {...otherProps}
    >
      <TitleIconAndNotification icon={icon} notificationCounter={notificationCounter} />
      {title}
      <TitleResult
        noResult={noResult}
        isLoading={isLoading}
        resultCount={resultCount}
        totalCount={totalCount}
      />
    </Typography>
  );
};
export default TitleWithIcon;
