import {
  Divider,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import clsx from "clsx";
import i18next from "i18next";
import React, { useEffect, useMemo, useState, useContext } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import AttributeFormInputBloc from "../../../components/attributes/AttributeFormInputBloc";
import {
  AttributeType,
  ATTRIBUTE_MAX_LABEL_LENGTH,
  ATTRIBUTE_MAX_LIST_VALUE_LENGTH,
  DefaultSelectProps,
  REGEXP_SEMICOLON,
} from "../../../services/common/Constants";
import {
  getAttributeMaxLengthRule,
  hasAttributeListItems,
} from "../../../services/common/AttributeUtils";
import { getAccountAttributeByLabelAndBondType } from "../../../services/edge/AttributesService";
import { HighlightOff } from "@mui/icons-material";
import { isEmpty } from "../../../services/common/Utils";
import { Context } from "../../../states/Store";
import useStyles from "../../../style/js-style/containers/administration/attributes/AttributeFormStyle";
import EAButton from "../../../components/common/EAButton";
import {PrivilegeEnum, RoleBasedAccessControlContext} from "../../../services/common/RolesUtils";
import _ from "lodash";

const getFormDefaultValues = (attribute) => {
  return {
    label: attribute?.label,
    type: attribute?.type || "",
    values: attribute?.values?.map((x) => {
      return {
        listItemId: x.id,
        value: x.value,
      };
    }) || [
      { listItemId: "", value: "" },
      { listItemId: "", value: "" },
    ],
  };
};

const AttributeForm = ({
                         formId,
                         attribute,
                         onTypeChange,
                         submitHandler,
                         formType = "create",
                         bondType,
                         onListLengthChange,
                         setIsValidateDisabled,
                       }) => {
  const { classes } = useStyles();
  const [state] = useContext(Context);
  const [isAttributeListItemsShown, setIsAttributeListItemsShown] = useState(
    attribute?.values?.length > 0
  );
  const [isEditForm] = useState(formType === "edit");
  const [hoveredLine, setHoveredLine] = useState(null);
  const attributesTypes = Object.values(AttributeType);
  const { hasAnyOfPrivileges } = React.useContext(RoleBasedAccessControlContext);

  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors
  } = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: useMemo(() => {
      return getFormDefaultValues(attribute);
    }, [attribute]),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "values",
  });

  useEffect(() => {
    if (onListLengthChange) {
      onListLengthChange(fields.length < 2);
    }
  }, [fields, onListLengthChange]);

  useEffect(() => {
    setIsValidateDisabled(!_.isEmpty(formState.errors));
  }, [formState, getValues, setIsValidateDisabled]);

  const verifyLabelUnicity = AwesomeDebouncePromise(async (value) => {
    if (attribute?.label !== value) {
      try {
        const result = await getAccountAttributeByLabelAndBondType(
          state.account.id,
          value,
          bondType,
          null
        );
        return result.content && i18next.t("attributes.errors.labelUnicity");
      } catch (e) {
        return e?.message?.error && e?.message?.error[0].code === "NOT_FOUND"
          ? undefined
          : i18next.t("infos.INTERNAL_ERROR");
      }
    }
  }, 500);

  const verifyListValueUnicity = (value) => {
    return getValues("values").filter(
      (x) => x.value?.trim()?.toLowerCase() === value?.trim()?.toLowerCase()
    )?.length > 1
      ? i18next.t("attributes.errors.valueUnicity")
      : null;
  };

  const verifyNotIncludeSemiColon = (value) => {
    return value && value.match(REGEXP_SEMICOLON)
    ? i18next.t("attributes.errors.nosemiclon")
    : null;
  }

  const validateLabelAttribute = (value) => {
    return verifyNotIncludeSemiColon(value) || verifyLabelUnicity(value);
  }
  const validateListValueAttribute = (value) => {
    return verifyNotIncludeSemiColon(value) || verifyListValueUnicity(value);
}

  const submitForm = (data) => {
    if (isEmpty(errors)) {
      data.values = data.values?.map((x) => {
        return {
          id: x.listItemId,
          value: x.value,
        };
      });
      submitHandler(data);
    }
  };

  const getInputRightBtn = (index) => {
    return (
      <div style={{position: "absolute", right: "0px", top: "-3px"}}>
        <Tooltip
          placement="top"
          title={i18next.t("delete")}
        >
          <IconButton
            aria-label={`remove attribute list field ${index + 1}`}
            onClick={() => remove(index)}
            className={classes.valuesIconBtnClosed}
            size="large"
          >
            <HighlightOff />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(submitForm)}
      className={classes.formContainer}
    >
      {/* Label */}
      <div className={classes.labelTypeBloc} style={{gap: "15px"}}>
        <AttributeFormInputBloc label={`${i18next.t("label")} * `} style={{flex: 1}}>
          <Controller
            control={control}
            name="label"
            rules={{
              required: i18next.t("infos.required"),
              maxLength: getAttributeMaxLengthRule("theLabel", ATTRIBUTE_MAX_LABEL_LENGTH),
              validate: (value) => validateLabelAttribute(value) ,
            }}
            render={({ field }) => (
              <TextField
                id="attribute-label-id"
                variant="outlined"
                error={!!errors.label}
                disabled={
                  bondType === "dossier"
                    ? !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_DOSSIER_ATTRIBUTE)
                    : !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY_ATTRIBUTE)
                }
                placeholder={i18next.t("placeholderLabel")}
                fullWidth
                {...field}
              />
            )}
          />
          {errors.label && (
            <FormHelperText className="Mui-error">
              {errors.label.message}
            </FormHelperText>
          )}
        </AttributeFormInputBloc>

        {/* Type */}
        <AttributeFormInputBloc
          label={`${i18next.t("type")} *`}
          className={classes.label}
          style={{flex: 1}}
        >
          <Controller
            control={control}
            name="type"
            rules={{
              validate: (value) => {
                const isNotValid = value.length === 0;
                if(isNotValid) {
                  const typeErrMsg = i18next.t("infos.required");
                  setError('type', {type: 'required', message: typeErrMsg})
                  return typeErrMsg
                } else {
                  clearErrors('type');
                }
              },
            }}
            render={({ field }) => (
              <TextField
                id="attribute-type-id"
                variant="outlined"
                select
                disabled={isEditForm}
                className={
                  isEditForm ? classes.disableInput : classes.enableInput
                }
                error={!!errors.type}
                {...field}
                onChange={(event) => {
                  setValue("type", event.target.value, { shouldValidate: true });
                  setIsAttributeListItemsShown(
                    hasAttributeListItems(event.target.value)
                  );
                  onTypeChange &&
                  onTypeChange(hasAttributeListItems(event.target.value));
                }}
                SelectProps={{
                  ...DefaultSelectProps,
                  displayEmpty: true,
                  renderValue: (selected) => {
                    if (selected.length === 0) {
                      return (
                        <span className={classes.placeHolder}>
                          {i18next.t(`typeOfValues`)}
                        </span>
                      );
                    }
                    return (
                      <span >
                        {i18next.t(`attributeType.${selected}`)}
                      </span>
                    );
                  }
                }}
              >
                <MenuItem disabled value="">
                  <span className={classes.placeHolder}>
                    {i18next.t(`typeOfValues`)}
                  </span>
                </MenuItem>
                {attributesTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {i18next.t(`attributeType.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {errors.type && (
            <FormHelperText className="Mui-error">
              {errors.type.message}
            </FormHelperText>
          )}
        </AttributeFormInputBloc>
      </div>

      {/* Values */}
      {isAttributeListItemsShown && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.valuesBloc}>
            <div
              className={clsx(
                classes.inputBlocContainer,
                classes.listValuesContainer
              )}
            >
              <div className="valuesLabel">
                <FormLabel className={classes.valuesLabel}>
                  {i18next.t("theValues")} *
                </FormLabel>
              </div>
              <Grid container>
                {fields.map((item, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      key={item.id}
                      className={classes.valuesWrapper}
                      onMouseEnter={() => setHoveredLine(index)}
                      onMouseLeave={() => setHoveredLine(null)}
                    >
                      <div className={classes.listValueLine}>
                        <FormGroup className={classes.listValueLineFromGroup}>
                          <div>
                            <Controller
                              name={`values.${index}.value`}
                              control={control}
                              rules={{
                                required: i18next.t("infos.required"),
                                maxLength: getAttributeMaxLengthRule(
                                  "theValue",
                                  ATTRIBUTE_MAX_LIST_VALUE_LENGTH
                                ),
                                validate: (value) =>
                                  validateListValueAttribute(value),
                              }}
                              render={({ field }) => (
                                <div className={classes.inputTextValuesWrapper}>
                                  <TextField
                                    onClick={(e) => e.stopPropagation()}
                                    className={classes.inputTextValues}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={i18next.t("placeholderValue")}
                                    error={
                                      !!errors.values &&
                                      !!errors.values[index]?.value
                                    }
                                    {...field}
                                    onChange={(event) => {
                                      setValue(`values.${index}`, {
                                        listItemId: item.listItemId,
                                        value: event.target.value,
                                      });
                                      trigger("values");
                                    }}
                                  />
                                </div>
                              )}
                            />

                            {errors?.values?.[index]?.value && (
                              <FormHelperText className="Mui-error">
                                {errors.values[index]?.value?.message}
                              </FormHelperText>
                            )}
                          </div>
                          { hoveredLine === index && fields.length > 2 && getInputRightBtn(index) }
                        </FormGroup>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>

              {/* Add value */}
              <EAButton
                borderless={true}
                className={classes.addChoiceBtn}
                onClick={() => append({ listItemId: "", value: "" })}
                content={
                  <>
                    <AddIcon style={{width: "16px", marginRight: "3px", position: "relative", right: "3px"}}/>
                    {i18next.t("attributes.addListItemBtnLabel")}
                  </>
                }
              />
            </div>
            <Divider className={classes.divider} />
          </div>
        </>
      )}
    </form>
  );
};

export default AttributeForm;
