import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { darkGrey } from "../../style/globalStyle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  root: {
      cursor: 'pointer',
      display: "flex",
      marginBottom: '6px',
      color: darkGrey,
      width: 'fit-content',

      "& p" : {
        position: 'relative',
        top: '2px',
        left: '2px',
      }
  },
}));

const EABack = ({ route, params, label }) => {
  const { classes } = useStyles();
  let history = useHistory();

  return (
    <>
      <div className={classes.root} onClick={() => route ? history.push(route, { ...params } ) : history.goBack()}>
        <ArrowBackIcon />
        <Typography variant="body2">
          {label}
        </Typography>
      </div>
    </>
  );
};

export default EABack;
