import React, { useContext, useEffect } from "react";
import { Context } from "../../../states/Store";
import EABarXYChart from "../../../components/common/charts/EABarXYChart";
import ROUTES from "../../../config/Routes";
import { buildBarStatusSerieItems, filterBarChartByPieStatusType, mapResourceToBarStatusChart } from "services/common/ChartUtils";

function DocumentsStatesChart({documentsCount}) {
  const [state] = useContext(Context);
  const [documentsCountByType, setDocumentsCountByType] = React.useState();
  const [shownDocumentsCountByType, setShownDocumentsCountByType] = React.useState();
  const [seriesItems, setSeriesItems] = React.useState();

  useEffect(() => {
    setSeriesItems(buildBarStatusSerieItems("documents"));
    setDocumentsCountByType(mapResourceToBarStatusChart(documentsCount, "docName", "documentCode", "documents"));
  }, [documentsCount]);

  // Filter Data when other graph items are selected
  useEffect(() => {
    setShownDocumentsCountByType(filterBarChartByPieStatusType(state.graphFilters.pie, documentsCountByType));
  }, [documentsCountByType, state.graphFilters.pie]);

  return (
    <EABarXYChart
      id="documentsStates"
      data={shownDocumentsCountByType}
      tab={ROUTES.DOCUMENTS}
      seriesItems={seriesItems}
    />
  );
}

export default DocumentsStatesChart;
