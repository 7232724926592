import React from "react";

import useStyles from "../../style/js-style/components/thirdparties/PanelTransparencyStyle";
import { Link, Typography } from "@mui/material";
import i18next from "i18next";
import EAIcon from "components/common/EAIcon";

const TransparencyMention = ({ year }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.mentionRoot}>
      <EAIcon icon="logo/logo-transparency-internationale" extension="png" className={classes.mentionLogo} />
      <Link href="https://www.transparency.org/cpi" className={classes.mentionLinkBold} style={{whiteSpace: "nowrap"}}>{i18next.t("transparency.mentionYear", { year: year })}</Link>
      <Typography className={classes.mentionLink}>{i18next.t("transparency.mentionLicense")}</Typography>
    </div>
  )
};
export default TransparencyMention;
