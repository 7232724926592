const ROUTES = {
  // ---- Thirdparties ---- //
  THIRDPARTIES: "thirdparties",
  THIRDPARTY_ADD: "thirdparties/add",
  THIRDPARTY_DETAIL: "thirdparties/:thirdpartyId",
  THIRDPARTY_DOSSIER_DETAIL: "thirdparties/:thirdpartyId/dossiers/:dossierId",
  THIRDPARTY_DOCUMENT_DETAIL: "thirdparties/:thirdpartyId/documents/:reponseId",
  THIRDPARTY_DOSSIER_DOCUMENT_DETAIL: "thirdparties/:thirdpartyId/dossiers/:dossierId/documents/:reponseId",
  TP_ADD_DOSSIER: "thirdparties/:thirdpartyId/dossiers/add",
  // ---- Dossiers ---- //
  DOSSIERS: "dossiers",
  DOSSIER_DETAIL: "dossiers/:dossierId",
  DOSSIER_DOCUMENT_DETAIL: "dossiers/:dossierId/documents/:reponseId",
  // ---- Documents ---- //
  DOCUMENTS: "documents",
  DOCUMENT_DETAIL: "documents/:reponseId",
  // ---- Archives ---- //
  ARCHIVES: {
    DOSSIERS: "archives/dossiers",
    DOSSIER_DETAIL: "archives/dossiers/:dossierId",
    THIRDPARTIES: "archives/thirdparties",
    THIRDPARTY_DETAIL: "archives/thirdparties/:thirdpartyId",
    THIRDPARTY_DOSSIER_DETAIL: "archives/thirdparties/:thirdpartyId/dossiers/:dossierId",
    THIRDPARTY_DOSSIER_DOCUMENT_DETAIL: "archives/thirdparties/:thirdpartyId/dossiers/:dossierId/documents/:reponseId",
    DOSSIER_DOCUMENT_DETAIL: "archives/dossiers/:dossierId/documents/:reponseId",
  },
  // ---- Others ---- //
  DEFAULT: "",
  DASHBOARD: "dashboard",
  ANSWER: "answer",
  ADMIN: "admin",
  USERS: "users",
  PROFILE: "profile",
  NO_ACCOUNT: "noaccount",
  ROLES: "roles",
  EVENTS: "events",
};

export const getUrlWithAccountId = (accountId, path) => `/${accountId}/${path}`

export default ROUTES;
