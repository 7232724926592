import { Context } from "../../../states/Store";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";

const useSelectedAccountId = () => {
  const [state] = useContext(Context);
  const [selectedAccountId, setSelectedAccountId] = useState();
  const { accountId } = useParams();

  useEffect(() => {
    setSelectedAccountId(accountId || state.account?.id);
  }, [accountId, state.account]);

  return selectedAccountId;
};

export default useSelectedAccountId;
