import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../../states/Store";
import {
  getCountries,
  getDepartments,
} from "../../../services/edge/AccountService";
import { ERROR } from "../../../services/common/Constants";
import EAMapChart from "../../../components/common/charts/EAMapChart";
import worldMap from "@amcharts/amcharts5-geodata/worldMoroccoHigh";
import franceMap from "@amcharts/amcharts5-geodata/franceDepartments2High";
import {
  getHighestGeoDataValue,
  getTotalGeoData,
  mapDepartmentData,
  mapWorldData,
} from "../../../services/common/ChartUtils";
import i18next from "i18next";
import ROUTES from "../../../config/Routes";
import { ThirdPartyBlocSkeleton } from "components/common/skeleton/SkeletonDashboard";
import { EANoData } from "components/common/EANoData";
import { isEmpty } from "lodash";
import { useRef } from "react";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { isAbortError } from "services/common/ApiUtils";

const tab = ROUTES.THIRDPARTIES;

function TpMapChart() {
  const [state, dispatch] = useContext(Context);
  const [hasOnlyFranceData, setHasOnlyFranceData] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [selectedMap, setSelectedMap] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [filtersQuery, setFiltersQuery] = useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasTPs, setHasTPs] = React.useState(false);
  const [initialCountries, setInitialCountries] = React.useState();
  const accountId = useSelectedAccountId();

  const initCountriesAbortCtrlRef = useRef();
  const departmentsAbortCtrlRef = useRef();
  const countriesAbortCtrlRef = useRef();

  // First Countries call to set thirdparties data scope ( World or France departments ), and reuse when no filter is active
  useEffect(() => {
    setInitialized(false);

    if(initCountriesAbortCtrlRef.current) {
      initCountriesAbortCtrlRef.current.abort();
    }

    initCountriesAbortCtrlRef.current = new AbortController();
    const signal = initCountriesAbortCtrlRef.current.signal;

    if (accountId && state.account) {
      (async () => {
        try {
          setIsLoading(true);
          const tpCountries = await getCountries(accountId, null, null, signal);
          if (
            tpCountries.content?.length === 1 &&
            tpCountries.content?.[0].countryCode === "FR"
          ) {
            setHasOnlyFranceData(true);
          } else {
            setHasOnlyFranceData(false);
          }
          setInitialized(true);
          setIsLoading(false)
          setHasTPs(tpCountries.content?.length > 0)
          setInitialCountries(tpCountries)
        } catch (e) {
          if(!isAbortError(e)) {
            dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
          } 
        }
      })();
    }

    return () => {
      initCountriesAbortCtrlRef.current.abort();
    };
  }, [accountId, state.account, dispatch]);

  // Build filters query from graph filters
  useEffect(() => {
    let queryObj = {};
    if (state.graphFilters?.treeMap?.length > 0) {
      queryObj.majorCodes = state.graphFilters.treeMap.map((x) => x.id);
    }
    if (state.graphFilters?.pie?.length > 0) {
      queryObj.registrationStatus = state.graphFilters.pie.map((x) => x.id);
    }
    setFiltersQuery(queryObj);
  }, [state.graphFilters?.treeMap, state.graphFilters?.pie]);

  // If momorized graph has obsolete map graph data, we should clear it.
  // Ex: Saved filters for departments because we had only France thirdparties.
  // Then we add a foreign thirdparty => departments are obsolete.
  useEffect(() => {
    if (initialized && state.graphFilters?.map?.length > 0) {
      const graphHasCountries =
        state.graphFilters.map.findIndex(
          (x) => x.keyLabel === i18next.t("dashboard.filters.country")
        ) > -1;
      if (
        (hasOnlyFranceData && graphHasCountries) ||
        (!hasOnlyFranceData && !graphHasCountries)
      ) {
        dispatch({
          type: "CLEAR_GRAPH_FILTERS",
          filter: { accountId, tab },
        });
      }
    }
  }, [
    initialized,
    hasOnlyFranceData,
    accountId,
    state.graphFilters?.map,
    dispatch,
  ]);

  const fetchWorldData = useCallback(
    (query, initialCountries) => {
      if (accountId && state.account) {
        (async () => {
          try {
            let tpCountries = [];
            if(isEmpty(query)) {
              tpCountries = initialCountries;
            } else {
              if(countriesAbortCtrlRef.current) {
                countriesAbortCtrlRef.current.abort();
              }
          
              countriesAbortCtrlRef.current = new AbortController();
              const signal = countriesAbortCtrlRef.current.signal;
          
              tpCountries = await getCountries(accountId, query, null, signal);
            }
            const total = getTotalGeoData(tpCountries.content);
            const highestValue = getHighestGeoDataValue(tpCountries.content);
            const data = mapWorldData(tpCountries.content);
            setMapData({ data, total, highestValue });
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
            } 
          }
        })();
      }
    },
    [accountId, state.account, dispatch]
  );

  const fetchDepartmentsData = useCallback(
    (query) => {

      if(departmentsAbortCtrlRef.current) {
        departmentsAbortCtrlRef.current.abort();
      }
  
      departmentsAbortCtrlRef.current = new AbortController();
      const signal = departmentsAbortCtrlRef.current.signal;

      if (accountId && state.account) {
        (async function fetchData() {
          try {
            const departmentInfos = await getDepartments(
              accountId,
              query,
              null,
              signal
            );
            const total = getTotalGeoData(departmentInfos.content);
            const highestValue = getHighestGeoDataValue(
              departmentInfos.content
            );
            const data = mapDepartmentData(departmentInfos.content);
            setMapData({ data, total, highestValue });
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
            } 
          }
        })();
      }
    },
    [accountId, dispatch, state.account]
  );

  // Show either world map or fetch France departments data and show their map.
  useEffect(() => {
    if (initialized) {
      if (hasOnlyFranceData) {
        setSelectedMap(franceMap);
        setSelectedType("department");
        fetchDepartmentsData(filtersQuery);
      } else {
        setSelectedMap(worldMap);
        setSelectedType("country");
        fetchWorldData(filtersQuery, initialCountries);
      }
    }

    return () => {
      countriesAbortCtrlRef.current?.abort();
      departmentsAbortCtrlRef.current?.abort();
    };
  }, [
    initialized,
    initialCountries,
    hasOnlyFranceData,
    filtersQuery,
    fetchWorldData,
    fetchDepartmentsData,
  ]);

  return (
    !isLoading ? (
        <>
        {hasTPs ? (
          <>
            {selectedMap && mapData ? <EAMapChart
              mapData={mapData}
              map={selectedMap}
              type={selectedType}
              tab={tab}
            /> : <div style={{ height: "500px" }}></div>}
          </>
        ) :
        <>
          <EANoData
            style={{ width:'100%', height: 'calc(100% - 30px)'}}
            label={i18next.t("dashboard.thirdparties.nodata")}
            icon="noThirdparties"
          />
        </>
        }
        </>
      ) : (
        <ThirdPartyBlocSkeleton />
    )
)}

export default TpMapChart;
