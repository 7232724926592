import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
} from "@mui/material";
import i18next from "i18next";

import { Context } from "../../../../../states/Store";
import { ERROR } from "../../../../../services/common/Constants";
import EADialog from "../../../../../components/common/EADialog";
import EAIcon from "../../../../../components/common/EAIcon";
import useStyles from "./DossierModelDialogStyle";
import { useRequestLoading } from "../../../../../components/common/hooks/useRequestLoading";
import { deleteMultipleDossierModel } from "services/edge/DossierModelService";
import { Trans } from 'react-i18next';
import clsx from "clsx";

const DossierModelDeleteDialog = ({
  isOpen,
  modelList,
  onClose,
  onValidateSuccess,
}) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [containActiveModel, setContainActiveModel] = useState(false);
  const [state, dispatch] = useContext(Context);

  const handleDelete = async () => {
    try {
      startLoading();
      const label = modelList[0]?.libelle;
      const message = modelList?.length > 1
        ? i18next.t(`demandes.dossierModel.notifications.deletedMulti`)
        : <Trans i18nKey="demandes.dossierModel.notifications.deleted">Le modèle "<span className='dt' style={{ fontWeight: "bold" }}>{{label}}</span>" a bien été supprimé.</Trans>

      await deleteMultipleDossierModel(state.account.id, modelList.map(m => m.id), null);

      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: message }
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DELETE_ERROR" } });
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (state.account && modelList) {
      modelList.forEach(m => { if (m.active) { setContainActiveModel(true) } })
    }
  }, [state.account, modelList]);

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={handleDelete}
      hasActions={true}
      maxWidth="popupxs"
      fullWidth
      variant="redValid"
      btnSize="large"
      isValidateDisabled={isRequestLoading}
    >
      {modelList && (
        <>
          <div className={classes.root}>
            <div className={classes.imgWrapper}>
              <EAIcon icon={"delete_red"} className={classes.imgContent} />
            </div>
            {modelList?.length === 1 ?
              <span className={classes.bolder}>
                {i18next.t("demandes.dossierModel.deleteDialog.title", { status: modelList[0]?.active ? i18next.t("demandes.dossierModel.status.options.active").toLocaleLowerCase() : i18next.t("demandes.dossierModel.status.options.inactive").toLocaleLowerCase() })}
              </span>
              : <span className={classes.bolder}>
                {i18next.t("demandes.dossierModel.deleteDialog.titleMulti")}
              </span>}
            {containActiveModel === true && (
              <span className={classes.activeModelSubtitle}>
                {i18next.t("demandes.dossierModel.deleteDialog.caption")}
              </span>
            )}
          </div>

          <div>
            <InfoBloc modelList={modelList} />
          </div>
        </>
      )}
    </EADialog>
  );
}

const InfoBloc = ({ modelList }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.modelInfoBlocWrapper}>
      {modelList.length > 1 ? (
        <>
          <div className={classes.modelInfoCaption}>
            <EAIcon icon={"delete_red"} className={classes.imgContent} />
            <Typography style={{ fontWeight: "bold" }}>{i18next.t("demandes.dossierModel.deleteDialog.confirm")}</Typography>
          </div>
          {modelList?.map((modelDossier) => (
            <Typography key={modelDossier.id} className={clsx(classes.dt, classes.modelInfoBlocContent)}>
              {modelDossier.libelle}
            </Typography>
          ))}
        </>
      ) : (
        <Typography key={modelList[0].id} className={clsx(classes.dt, classes.modelInfoBlocContent)}>
          {modelList[0]?.libelle}
        </Typography>
      )}
    </div>

  )
};


export default DossierModelDeleteDialog;
