import React, { useContext, useState, useCallback, useEffect } from "react";
import i18next from "i18next";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Grid, Box, Tooltip, IconButton } from "@mui/material";
import {
  Add as AddIcon,
  HighlightOff
} from "@mui/icons-material";

import useStyles from "style/js-style/containers/administration/rules/RuleFormsStyle";
import EAButton from "components/common/EAButton";
import { RuleActionType, RulesContext, getRuleActionModel } from "services/common/RulesUtils";
import DossierCreationFormFields from "./DossierCreationActionParams";
import RuleSelectInput from "components/rules/RuleSelectInput";
import EAIcon from '../../../../../components/common/EAIcon';

const RuleActions = ({ fieldName }) => {
  const { classes } = useStyles();
  const [hoveredLine, setHoveredLine] = useState(null);

  const formMethods = useFormContext();
  const {
    control,
  } = formMethods;

  const { fields: actions, append, remove } = useFieldArray({
    control,
    name: `${fieldName}`,
  });

  const onAddEntry = useCallback(() => {
    append(getRuleActionModel());
  }, [append]);

  const onRemoveEntry = useCallback((index) => {
    remove(index);
  }, [remove]);

  return (
    <Grid container spacing={2}>
      {actions?.map((item, index) => {
        return (
          <>
            <Grid item xs={1}>
              <EAIcon icon={"rules/actionLine"} className={classes.actionLine}/>
            </Grid>
            <Grid item xs={11}
              key={index}
              onMouseEnter={() => setHoveredLine(index)}
              onMouseLeave={() => setHoveredLine(null)}
                  style={{backgroundColor: "#F9F9FB", marginBottom: 19, paddingBottom: 16}}
            >
              <RuleAction
                fieldName={`${fieldName}.${index}`}
                onRemove={() => onRemoveEntry(index)}
                isHovered={hoveredLine === index}
                index={index}
              />
            </Grid>
          </>
        )
      })}

      <Grid item xs={12} sx={{padding: "0 !important"}}>
        <EAButton
          borderless={true}
          className={classes.addChoiceWrapper}
          onClick={(e) => {
            e.preventDefault();
            onAddEntry();
          }}
          content={
            <div className={classes.addChoiceBtn}>
              <AddIcon style={{ width: "16px", marginRight: "7px" }} />
              <span style={{ fontSize: "12px" }}>{i18next.t("rules.configuration.ruleActions.actions.addAction")}</span>
            </div>
          }
          customStyle={{
            position: "relative",
            left: "18px",
          }}
        />
      </Grid>
    </Grid >
  )
}

const RuleAction = ({
  fieldName,
  onRemove,
  isHovered,
  index
}) => {
  const { classes } = useStyles();
  const {
    actionTypes
  } = useContext(RulesContext);

  const [availableActionTypes, setAvailableActionTypes] = useState([]);

  let data = useWatch({ name: `${fieldName}` });

  const formMethods = useFormContext();
  const {
    setValue,
  } = formMethods;

  const onActionTypeChange = (newValue) => {
    setValue(`${fieldName}.parameters`, {});
  }

  const renderDeleteButton = () => {
    return (
      <Tooltip
        placement="top"
        title={i18next.t("delete")}
        sx={{ position: "relative" }}
      >
        <IconButton
          onClick={onRemove}
          className={classes.valuesIconBtnClosed}
          size="large"
        >
          <HighlightOff />
        </IconButton>
      </Tooltip>
    );
  };

  useEffect(() => {
    setAvailableActionTypes(actionTypes);
  }, [actionTypes])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{}} style={{ alignItems: "center" }}>
        <Grid item xs={11} md={11}>
          <Grid container spacing={2} sx={{}} style={{ alignItems: "center" }}>
            <Grid item xs={12} md={3}>
              <RuleSelectInput
                fieldName={`${fieldName}.type`}
                label={`${i18next.t("rules.configuration.ruleActions.fields.type.label", {indice: index + 1})}`}
                placeholder={i18next.t(`rules.configuration.ruleActions.fields.type.placeholder`)}
                options={availableActionTypes}
                onChange={(newValue) => {
                  onActionTypeChange(newValue);
                }}
                isRequired
                validate={() => { }}
                labelVariant='chip'
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <RuleActionParams
                fieldName={`${fieldName}.parameters`}
                actionType={data?.type}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1} md={1}>
          {isHovered && renderDeleteButton()}
        </Grid>
      </Grid>
    </Box>
  )
}

const RuleActionParams = ({ fieldName, actionType }) => {

  switch (actionType) {
    case RuleActionType.DOSSIER_CREATION:
      return <DossierCreationFormFields fieldName={fieldName}/>;

    default:
      return (<></>);
  }
}

export default RuleActions;
