import { isEmpty } from "lodash";
import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";

export async function getDossierModel(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/dossier-model`
  );
};

export async function checkCodeAndLabel(accountId, id, code, label, dispatch) {
  let params = "";
  if (isEmpty(code) && !isEmpty(label)) {
    params = `label=${label}`;
  } else if (!isEmpty(code) && isEmpty(label)) {
    params= `code=${code}`;
  } else if (!isEmpty(code) && !isEmpty(label)) {
    params= `code=${code}&label=${label}`;
  } else {
    // Dispatch error
  }
  if (!isEmpty(id)) {
    params += `&modelId=${id}`;
  }
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/dossier-model/check-code-label?${params}`
  );
};

export async function getDossierModelTemplate(accountId, dispatch) {
  let url = `365/account/${accountId}/dossier-model/download`;
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    url,
    null,
    null,
    true
  );
};

export const postApiMultipart = async (accountId, file) => {
  const token = localStorage.getItem("365token");
  let path = `365/account/${accountId}/dossier-model/upload`;
  if(!token) {
    window.location.reload();
    return false;
  }

  const urlAPI = getEnv("REACT_APP_EDGE");

  const data = new FormData();
  data.append("file", file);

  return fetch(`${urlAPI}${path}`, {
    headers: {
      "client": "365",
      Authorization: "Bearer " + localStorage.getItem("365token")
    },
    method: 'POST',
    body: data
  });
};



export async function createDossierModel(accountId, modelToUpdate, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/dossier-model`,
    modelToUpdate
  );
};

export async function updateDossierModel(accountId, modelId, modelToUpdate, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `365/account/${accountId}/dossier-model/${modelId}`,
    modelToUpdate
  );
};

export async function updateNatureMultipleDossierModel(accountId, listModels, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/dossier-model/nature`,
    listModels
  );
};

export async function updateActiveMultipleDossierModel(accountId, listModels, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/dossier-model/active`,
    listModels
  );
};

export async function deleteMultipleDossierModel(accountId, listModelIds, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `365/account/${accountId}/dossier-model`,
    listModelIds
  );
};

export async function getAllActiveTypeDoc(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/doc-management/alldocs`
  );
} 