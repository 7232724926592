import EAButton from "components/common/EAButton";
import EAIcon from "components/common/EAIcon";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";

export const AdvancedSearchButtons = ({ handleReset = () => {} }) => {
  const formMethods = useFormContext();

  return (
    <>
      <EAButton
        content={
          <>
            <EAIcon icon="arrow/replay" />
            {"Réinitialiser la recherche"}
          </>
        }
        colorVariant={"secondary"}
        type="reset"
        size="small"
        customStyle={{
          marginRight: "10px",
          backgroundColor: "#EAEAF0",
          color: "#9A9AAF",
        }}
        onClick={handleReset}
      />
      <EAButton
        type="submit"
        disabled={!isEmpty(formMethods.formState.errors)}
        content={"Rechercher"}
        colorVariant="primary"
        size="small"
      />
    </>
  );
};
