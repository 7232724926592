import React from "react";
import i18next from "i18next";
import ReactCountryFlag from "react-country-flag"
import countries from "i18n-iso-countries";
import useStyles from "../style/js-style/components/EAFlagStyle"

const EAFlag = ({ countryCode, flagWidth = "30px", flagHeight = "30px" }) => {
  const { classes } = useStyles();

  const calculateWidth = (width) => {
    let str = width.substring(0, width.length - 2);
    let newWidth = str - 6;
    return newWidth + "px";
  };

  return (
    <div className={classes.flagWrapper} style={{ width: calculateWidth(flagWidth), height: flagHeight }}>
      <div className={classes.flagOverlay} style={{ width: flagWidth, height: flagHeight }} />
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        title={countries.getName(countryCode, i18next.language)}
        className={classes.countryFlag}
        style={{ width: flagWidth, height: flagHeight }}
      />
    </div>
  )
}

export default EAFlag;
