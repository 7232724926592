import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getAccountDocumentsSearch, getAccountRequirements } from "services/edge/AccountService";
import { Context } from "../../states/Store";
import i18next from "i18next";
import DocumentsDataTable from "../../components/documents/DocumentsDataTable";
import ListLayout from "../../components/common/ListLayout";
import { canAnswerDocument } from "services/common/DocumentUtils";
import { DocumentsListSearchForm } from "./DocumentsListSearchForm/DocumentsListSearchForm";
import { ERROR } from "services/common/Constants";
import { isAbortError } from "services/common/ApiUtils";
import { InitialDocumentsTableSort } from "components/documents/DocumentsDataTableUtils";
import { useListFormFilters } from "../../components/common/hooks/useListFormFilters";

function Documents() {
  const [state, dispatch] = useContext(Context);
  const [documents, setDocuments] = useState();
  const [answerCount, setAnswerCount] = useState(0);
  const [isAnswerBtnActive, setIsAnswerBtnActive] = useState(false);
  const [shownDocs, setShownDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const [globalCount, setGlobalCount] = React.useState(0);
  const [sortModel, setSortModel] = React.useState(InitialDocumentsTableSort);
  const [accountDocumentsOptions, setAccountDocumentsOptions] = useState();

  const {
    formFilters,
    saveSessionFormFilters,
  } = useListFormFilters("documents-list");

  const abortControllerRef = useRef();

  useEffect(() => {
    async function getRequirements() {
      const results = await getAccountRequirements(state.account.id, dispatch);
      setAccountDocumentsOptions(
        results.content
          .map((x) => ({
            label: x.descriptionFr,
            value: x.code
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label))
      );
    }
    if (state.account) {
      getRequirements();
    }
  }, [dispatch, state.account]);

  const fetchDocuments = useCallback(
    (page, rowPerPage, filters, isAnswerBtnActive, sortModel) => {

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      setLoader(true);
      (async function fetchData() {
        try {
          const resp = await getAccountDocumentsSearch(
            state.account.id,
            { page, rowPerPage },
            filters,
            isAnswerBtnActive,
            sortModel,
            null,
            signal
          );
          setDocuments(resp.content || []);
          setAnswerCount(resp.answerableElements || 0);
          setGlobalCount(resp.globalCount || resp.totalElements)
          dispatch({
            type: "PAGINATION",
            pagination: {
              page: resp.page,
              rowPerPage: resp.size,
              count: resp.totalElements,
            },
          });
          setIsLoading(false);
          setLoader(false);
        } catch (e) {
          if(!isAbortError(e)) {
            if(e?.message?.error?.[0]?.code === "NOT_FOUND") {
              setDocuments([]);
            } else {
              dispatch({
                type: "ALERT",
                alert: { type: ERROR, msg: "DOCUMENTS_ERROR" },
              });
            }
          }
          setIsLoading(false);
          setLoader(false);
        }
      })();
    },
    [state.account, dispatch]
  );

  // -------- Data ------- //
  useEffect(() => {
    if (state.account && formFilters) {
      fetchDocuments(
        state.pagination.page,
        state.pagination.rowPerPage,
        formFilters,
        isAnswerBtnActive,
        sortModel
      );
    }
    return () => {
      abortControllerRef.current?.abort();
    }
  }, [
    state.account,
    fetchDocuments,
    state.pagination.page,
    state.pagination.rowPerPage,
    formFilters,
    isAnswerBtnActive,
    sortModel
  ]);

  // Map thirdparties
  useEffect(() => {
    let mappedDocs = documents?.map((x) => {
          return {
            documentId: x.documentId,
            reponseId: x.reponseId,
            fileId: x.fileId,
            depositorName: x.depositorName,
            transmitter: x.transmitter,
            nameFr: x.nameFr,
            depositDate: x.depositDate,
            endDate: x.endDate,
            thirdpartyName: x.thirdpartyName,
            thirdpartyCode: x.thirdpartyCode,
            present: x.present,
            thirdpartyId: x.thirdpartyId,
            statusCode: x.statusCode,
            authorizingRoles: x.authorizingRoles,
            acquisition : x.acquisition,
            typeDocumentId : x.typeDocumentId,
            dossierId : x.dossierId,
            dossierReference : x.dossierReference,
            dossierTypeLibelle : x.dossierTypeLibelle,
            isAnswerable: canAnswerDocument(x),
            refusedToFile: x.refusedToFile,
            nbMultiple: x.nbMultiple
          };
        });
    setShownDocs(mappedDocs || []);
  }, [documents]);

  const handleSearch = (data) => {
    saveSessionFormFilters(data);
    dispatch({
      type: "PAGINATION",
      pagination: {
        page: 0,
        rowPerPage: state.pagination.rowPerPage,
      },
    });
  };

  // Reset pagination on component unmount
  useEffect(() => {
    return () => {
      dispatch({
        type: "PAGINATION",
        pagination: {
          page: 0,
          rowPerPage: 50,
          count: 0,
        },
      });
    };
  }, [dispatch]);

  return (
    <ListLayout
      count={state.pagination?.count}
      totalCount={globalCount}
      icon="drawer/documents_active"
      title={i18next.t("myDocuments.title")}
      isLoading={isLoading}
      filterField={
        formFilters && (
          <DocumentsListSearchForm
            filters={formFilters}
            documentsOptions={accountDocumentsOptions}
            submitHandler={handleSearch}
          />
        )
      }
    >
      {shownDocs && (
        <DocumentsDataTable
          documents={shownDocs}
          loading={loader}
          component="documents-list"
          paginationMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          answerCount={answerCount}
          onAnswerFilterChange={setIsAnswerBtnActive}
          filters={formFilters}
        />
      )}
    </ListLayout>
  );
}

export default Documents;
