import { Box} from "@mui/material";
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import AddIcon from '@mui/icons-material/Add';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import EADialog from "components/common/EADialog";
import i18next from "i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./SummaryImportDialogStyle";
import { getSummaryImportThirdParties, importThirdPartiesFromFile, saveFileImportThirdParties } from "services/edge/ThirdpartyService";
import { Context } from "states/Store";
import clsx from "clsx";
import CancelImportThirdParties from "../CancelImportThirdParties/CancelImportThirdParties";
import { AttributeType, ERROR, SUCCESS } from "services/common/Constants";
import moment from "moment";

const SummaryImportDialog = ({isOpen, onClose, importData, attributesList,  validationHandler, updateLastImportId}) => {

    const { classes } = useStyles();
    const [state, dispatch] = useContext(Context);


    //---------- Confirm close import--------------
    const [isOpenCloseConfirm, setIsOpenCloseConfirm] = useState(false)

    //------------ Validation data ------------------------

    const formatValueAttribute = (value, type) => {
        switch (type) {
            case AttributeType.BOOLEAN: 
                if(value === true || value.toLowerCase() === i18next.t("yes")?.toLowerCase()) {
                    return true;
                }
                if(value === false || value.toLowerCase() === i18next.t("no")?.toLowerCase()) {
                    return false;
                }
                return value;
            case AttributeType.DATE:
                const date = moment(value, i18next.t("date.dateFormat"))?.format("YYYY-MM-DD");
                return date !== "Invalid date" ? date : value;
            default: 
                return value;
        }
    }


    const mapDataToSand = useCallback((importData, onlyValidData) => {

        const inputAttributesToValidatedAttributes = (inputAttribute, thirdPartyValues) => {
            const attribute = attributesList.find(att => att.id === Number(inputAttribute.split('_')[1]));
            return {
                label:  attribute?.label,
                values: thirdPartyValues ? thirdPartyValues.toString().split(";").map(value => formatValueAttribute(value, attribute?.type)) : []
            }
        }

        const thirdpartiesToImport = onlyValidData ? importData.thirdParties?.validData : importData.thirdParties?.all

        return {
            addOn: importData.options.addOn, 
            archiveOn: importData.options.archiveOn,
            updateOn: importData.options.updateOn, 
            keyUpdate: importData.options.keyUpdate, 
            overrideEmptyColumn: importData.options.overrideEmptyColumn,
            nameImport: importData.nameImport,
            filename: importData.file.path,
            userId : state.user.id,
            thirdParties: thirdpartiesToImport.map(tp => {
                const inputAttributes = Object.getOwnPropertyNames(tp).filter(property => property.includes("attribute_"));
                const listAttributes = inputAttributes.map(attribute => inputAttributesToValidatedAttributes(attribute,tp[attribute]))
                return {
                    duns: tp.duns, 
                    tva: tp.tva, 
                    idLocal: tp.idLocal, 
                    thirdpartyId: tp.thirdpartyId,
                    country: tp.country, 
                    thirdPartyCode: tp.thirdpartyCode, 
                    contacts: tp.contacts, 
                    attributes : listAttributes, 
                }
            })
        }
    }, [attributesList, state.user.id])

    //-----------  fetch summary  ------------------------
    const [summary, setSummary] = useState(null);

    const onSummaryClose = () => {
        onClose();
        setSummary(null)
    }

    const fetchSummary = useCallback(async () => {
        try {
          const results = await getSummaryImportThirdParties(state.account.id, mapDataToSand(importData, true), dispatch);
          setSummary({
            toAdd : results.content.numberToAdd,
            toUpdate: results.content.numberToUpdate,
            toRemove : results.content.numberToArchive,
        })

        } catch (error) {
            setSummary(null);
        }
      }, [dispatch, importData, mapDataToSand, state.account.id]);

      useEffect(() => {
        // fetch if summary null and one option import is set
        if(!summary && (importData?.options?.addOn ||importData?.options?.updateOn || importData?.options?.archiveOn )) {
            fetchSummary();
        }
      }, [summary, fetchSummary, importData?.options, importData?.thirdParties])





    //------- Submit import -----------------


    const submitForm = async () => {
        const dataToSend = mapDataToSand(importData);

        try {
            validationHandler();
            const result = await importThirdPartiesFromFile(state.account.id, dataToSend, dispatch);
            let formData = new FormData(); 
            formData.append('file', importData.file);
            // save file import
            saveFileImportThirdParties(state.account.id, result.content, formData, dispatch);
            updateLastImportId(result.content);
            dispatch({
                type: "ALERT",
                alert: { type: SUCCESS, msg: i18next.t("imports.confirmationAlertImport") },
              });
            setSummary(null);
        } catch (error) {
            if ( error?.message?.messages?.error && error?.message?.messages?.error[0]?.code) {
                dispatch({ type: "ALERT", alert: {type: ERROR, msg : i18next.t(`infos.${error.message.messages.error[0].code}`)} });
              } 
        }
        
    }

    return <EADialog 
                isOpen={isOpen}
                title={i18next.t("imports.importDialogName.summaryImportStep")}
                onCancel={(event, reason) => {
                    if(reason && reason === "backdropClick") return;
                    setIsOpenCloseConfirm(true);
                }}
                validateBtnLabel="validate"
                fullWidth={true}
                onValidate={submitForm}>

            <Box className={classes.boxContainer}>

                <div className={classes.flexContainer}>
                    <LoopRoundedIcon className={classes.primaryColor}/>
                    <div>
                        <div className={classes.headerText}>{i18next.t("imports.importDialogName.numberThirdPartiesToUpdate")} :</div>
                        <div>{summary?.toUpdate}</div>
                    </div>
                </div>

                <div className={classes.flexContainer}>
                    <AddIcon className={classes.primaryColor}/>
                    <div>
                        <div className={classes.headerText}>{i18next.t("imports.importDialogName.numberThirdPartiesToAdd")} :</div>
                        <div>{summary?.toAdd}</div>
                    </div>
                </div>

                <div className={classes.flexContainer}>
                    <Inventory2OutlinedIcon className={clsx(classes.primaryColor, classes.archive) }/>
                    <div>
                        <div className={classes.headerText}>{i18next.t("imports.importDialogName.numberThirdPartiesToRemove")} :</div>
                        <div>{summary?.toRemove}</div>
                    </div>
                </div>

            </Box>

            <CancelImportThirdParties isOpen={isOpenCloseConfirm} onClose={() => setIsOpenCloseConfirm(false)} onValidate={onSummaryClose}/>

</EADialog>
        
}

export default SummaryImportDialog;