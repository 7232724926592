import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import _ from 'lodash';

import { getFormDefaultValues } from "services/common/CalculatorUtils";

const CalculatorForm = ({ calculation = null, formId, submitHandler, children }) => {
  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: getFormDefaultValues(calculation),
  });

  const { formState: { errors } } = formMethods;

  const submitForm = (data) => {
    if (_.isEmpty(errors)) {
      submitHandler(data);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        id={formId}
        onSubmit={formMethods.handleSubmit(submitForm)}
        style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
      >
        {children}
      </form>
    </FormProvider>
  );
}

export default CalculatorForm;
