import React, { useEffect, useState } from "react";
import EADialog from "../../components/common/EADialog";
import useStyles from "../../style/js-style/components/events/EventDialog.style";
import EAIcon from "../../components/common/EAIcon";
import EABackground from "../../components/EABackground";
import i18next from "i18next";
import {
  buildEventChanges,
  buildEventValues,
} from "services/common/EventsUtils";
import { isEmpty } from "lodash";

const DetailItem = ({ name, icon, values = [], last = false }) => {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={classes.detailItem}>
      {/* Nom de l'item */}
      <div className={classes.detailItemBulletWrapper}>
        <div className={classes.detailItemBullet}>
          <EAIcon icon={"events/" + icon} />
          <span>{name}</span>
        </div>

        {/* Division vertical */}
        {isOpen
          ? !last && (
              <>
                <div
                  className={classes.detailItemVerticalDividerDashed}
                  style={{ marginTop: "10px" }}
                />
                <div className={classes.detailItemVerticalDivider} />
                <div
                  className={classes.detailItemVerticalDividerDashed}
                  style={{ marginBottom: "10px" }}
                />
              </>
            )
          : !last && (
              <div
                className={classes.detailItemVerticalDivider}
                style={{ margin: "10px 0", minHeight: "20px" }}
              />
            )}
      </div>

      {/* Contenu */}
      <div
        className={classes.detailItemContent}
        style={{ height: isOpen ? "auto" : "32px" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <EAIcon
          icon={"arrow/arrow-to-up_large"}
          className={isOpen ? classes.openedArrowUp : classes.openedArrowDown}
        />
        {values.map((item, index) => {
          return (
            <div key={"item-" + index}>
              <span className={classes.detailItemLineLabel}>
                {i18next.t(item.label)} :{" "}
              </span>
              <span className={classes.detailItemLineValue}>
                {typeof item.values === "object" ? (
                  <ul style={{ marginTop: 0, paddingLeft: "20px" }}>
                    {item.values?.map((subItem, subItemIndex) => (
                      <li key={"item-listItem-" + subItemIndex}>{subItem}</li>
                    ))}
                  </ul>
                ) : (
                  item.values
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

function EventDialog({ isOpen, event, handleClose }) {
  const { classes } = useStyles();
  const [eventValues, setEventValues] = useState();
  const [eventChanges, setEventChanges] = useState([]);

  useEffect(() => {
    setEventValues(buildEventValues(event));
    setEventChanges(buildEventChanges(event));
  }, [event]);

  if (!eventValues) {
    return "";
  }

  const renderChange = (change) => {
    // Multiple changes for same label ( ex: Documents list )
    // In this casen we should only have one of the two at a time
    if(Array.isArray(change.before) || Array.isArray(change.after)) {
      return change.before ? (
        change.before.map(item => (
          <div className={classes.mouvementInfoOldValue}>
            {item}
          </div>
        ))
      ) : (
        change.after.map(item => (
          <div className={classes.mouvementInfoNewValue}>
            {item}
          </div>
        ))
      )
    } else {
      return (
        <>
          {change.before && (
            <span className={classes.mouvementInfoOldValue}>
              {change.before}
            </span>
          )}
          {!change.withoutArrow && <EAIcon icon={"arrow/arrow-to-right_large"} className={classes.mouvementInfoArrow} />}
          {change.after && (
            <span className={classes.mouvementInfoNewValue}>
              {change.after}
            </span>
          )}
        </>
      )
    }
  }

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={handleClose}
      maxWidth="popupsm"
      fullWidth
      displayBtn={false}
      isFixed
      positionSetting={{ top: -32 }}
      customDialogStyle={{ height: "100vh", padding: "0 !important" }}
    >
      {/* Header */}
      <div className={classes.dialogTitle}>
        <span className={classes.dialogTitleMain}>
          {eventValues.general.name}
        </span>
        <span className={classes.dialogTitleSub}>
          source : {eventValues.general.source}
        </span>
        <EAIcon
          icon={"cross"}
          className={classes.closeCross}
          onClick={handleClose}
        />
      </div>
      <EABackground />
      <div className={classes.mainContentWrapper}>
        {/* Changes */}
        {!isEmpty(eventChanges) && (
          <div className={classes.mouvementRoot}>
            <div className={classes.sectionTitle}>
              <EAIcon icon={"mouvement"} className={classes.sectionTitleIcon} />
              <span>{i18next.t("events.movement.title")}</span>
            </div>
            <div className={classes.mouvementInfoWrapper}>
              {eventChanges?.map((item) => {
                return (
                  <div
                    className={classes.mouvementInfoItemWrapper}
                    key={"mouvement-" + item.label}
                  >
                    <span className={classes.mouvementInfoTitle}>
                      {item.label} :{" "}
                    </span>
                    {renderChange(item)}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/*  Détails */}
        <div className={classes.detailRoot}>
          <div className={classes.sectionTitle}>
            <EAIcon icon={"detail"} className={classes.sectionTitleIcon} />
            <span>Détails</span>
          </div>
          <div className={classes.detailItemsWrapper}>
            {event.eventCategory === "UTILISATEUR" && (
              <DetailItem
                icon={"users"}
                name={"Utilisateurs"}
                values={eventValues.users}
              />
            )}
            {["DOSSIER", "DOCUMENT"].includes(event.eventCategory) && (
              <DetailItem
                icon={"dossier"}
                name={"Dossier"}
                values={eventValues.dossier}
              />
            )}
            <DetailItem
              icon={"thirdparty"}
              name={"Tiers"}
              values={eventValues.thirdParty}
            />
            {event.eventCategory === "DOCUMENT" && (
              <DetailItem
                icon={"document"}
                name={"Document"}
                values={eventValues.document}
              />
            )}
            <DetailItem
              icon={"account"}
              name={"Compte"}
              values={eventValues.account}
              last
            />
          </div>
        </div>
      </div>
    </EADialog>
  );
}
export default EventDialog;
