import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  deleteItemIcon: {
    width : "7px",
  },
  chipsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: "10px 9px",
    padding: "7px 2px",
  },
  chip: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: 0,
    backgroundColor: theme.palette.grey.grey100,
    gap: "20.5px",
    fontSize: "12px",
    width: "45%",
    color : theme.palette.grey.grey700,
    fontWeight: 500,
    paddingRight: "8.5px",
  },
  largeChip:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: 0,
    backgroundColor: theme.palette.grey.grey100,
    gap: "20.5px",
    fontSize: "12px",
    width: "calc(90% + 8.5px)",
    color : theme.palette.grey.grey700,
    fontWeight: 500,
    paddingRight: "8.5px",
  }
}));

export default useStyles;
