import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    // MuiTableContainer , no shadow
    boxShadow: "none",

    // MuiTableCell
    "& .MuiTableCell-root": {
      borderTop: "1px solid " + theme.palette.grey.grey300,
      borderBottom: "1px solid " + theme.palette.grey.grey300,
      fontSize: "12px",
      fontWeight: 400,
      color: "#1A1919",
      padding: "11px 14px !important",
      width: "50%",
    },
    tableCellDescribe: {
      borderTop: "1px solid " + theme.palette.grey.grey300,
      borderBottom: "1px solid " + theme.palette.grey.grey300,
      fontSize: "12px",
      fontWeight: 700,
      color: theme.palette.grey.grey500,
      padding: "11px 14px !important",
    },
    displayedItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: 1,
      boxOrient: "vertical",
      display: "-webkit-box",
      wordBreak: "break-all",
      width: "fit-content"
    },
    // MuiTableRow
    "& .MuiTableRow-root": {
      background: "linear-gradient(0deg, rgba(245, 245, 245, 0.3), rgba(245, 245, 245, 0.3)), #FFFFFF",

      "& .MuiTableCell-root": {
        padding: "11px 14px !important"
      },
    },
  },
  simpleValue: {
    color: theme.palette.grey.grey500,
    fontWeight: 700,
    fontSize: "12px",
  },
  objectInline: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "5px"
  },
  simpleObjectRow: {
    display: "flex",
    gap: "10px",
    padding: "7px 0px"
  },
  tableCellObject: {
    maxWidth: "50%",
  },
}));

export default useStyles;