import React, { useCallback, useContext, useEffect, useState } from "react";
import { Divider } from "@mui/material";
import useStyles from "style/js-style/containers/administration/roles/privileges/ScopeDropStyle";
import EAIcon from "components/common/EAIcon";
import i18next from "i18next";
import { useFormContext } from "react-hook-form";

import { getDossierCriterionOptions, getThirdPartyCriterionOptions } from "services/common/RolesUtils";
import { updateRoleScopes } from "services/edge/RoleService";
import { getAccountRequirements } from "services/edge/AccountService";
import { ERROR, SUCCESS } from "services/common/Constants";
import { Context } from "states/Store";
import RoleScopeForm from "../../form/RoleScopeForm";
import ScopeCriteriaFormFields from "../../form/criteria/ScopeCriteriaFormFields";
import AuthorizedDocumentsFormSelect from "../../form/criteria/AuthorizedDocumentsFormSelect";

const FullScopeTitle = ({ type }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.titleWrapper}>
      <span className={classes.titleMain}>{i18next.t(`roles.scope.${type}.title`)}</span>
      <span className={classes.titleAllPart}> - Tous </span>
      <span className={classes.titleSubTitle}>&nbsp;({i18next.t(`roles.scope.${type}.subtitle`)})</span>
    </div>
  )
}

const ScopeFormFieldsWrapper = ({ setSubmitHandler, role, onValidateSuccess, type, accountAttributes, accountCountries, accountActivities, accountCalculatedAttributes, accountIndicators }) => {
  const formMethods = useFormContext();
  const { handleSubmit } = formMethods;
  const [state, dispatch] = useContext(Context);

  const [dossierTypes, setDossierTypes] = useState();

  const [thirdPartyCriterionOptions, setThirdPartyCriterionOptions] = useState(null);
  const [dossierCriterionOptions, setDossierCriterionOptions] = useState(null);
  const [documentTypes, setDocumentTypes] = useState();

  // Formate dossierType
  useEffect(() => {
    if (state.account) {
      const mappedDossierTypes = state.dossierTypes.map((dt) => {
        return {
          value: dt.code,
          label: dt.libelle
        }
      })
      setDossierTypes(mappedDossierTypes);
    }
  }, [state.account, dispatch, state.dossierTypes]);

  useEffect(() => {
    if (accountCountries && accountActivities && accountAttributes && accountCalculatedAttributes && accountIndicators) {
      setThirdPartyCriterionOptions(getThirdPartyCriterionOptions(accountCountries, accountActivities,
        accountAttributes, accountCalculatedAttributes, accountIndicators));
    }
  }, [accountAttributes, accountCountries, accountActivities, accountCalculatedAttributes, accountIndicators]);

  useEffect(() => {
    if (dossierTypes && accountAttributes) {
      setDossierCriterionOptions(getDossierCriterionOptions(dossierTypes, accountAttributes));
    }
  }, [dossierTypes, accountAttributes]);

  // Fetch document types
  useEffect(() => {
    if (state.account) {
      (async function fetchData() {
        try {
          const results = await getAccountRequirements(state.account.id, dispatch);

          setDocumentTypes(
            results.content.map((x) => {
              return {
                value: x.documentId,
                label: x.descriptionFr
                // label: localizeTranslate(x.descriptionFr)
              }
            })
          );
        } catch (error) { }
      })();
    }
  }, [state.account, dispatch]);

  // Save
  const submitHandler = useCallback(() => {
    const submitForm = async (data) => {
      try {
        await updateRoleScopes(
          state.account.id,
          role.id,
          data = {
            ...data,
            thirdPartyQualifyingCriteria: data.thirdPartyQualifyingCriteria.filter((x) => x.field !== ""),
            dossierQualifyingCriteria: data.dossierQualifyingCriteria.filter((x) => x.field !== ""),
          },
          dispatch
        );
        dispatch({
          type: "ALERT",
          alert: {
            type: SUCCESS,
            msg: "Les modifications ont bien été enregistrées.",
          },
        });

        onValidateSuccess();
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: {
            type: ERROR,
            msg: "Erreur interne.",
          },
        });
      }
    }
    handleSubmit(submitForm)();
  }, [handleSubmit, dispatch, onValidateSuccess, state.account.id, role]);

  useEffect(() => {
    setSubmitHandler(() => submitHandler);
  }, [submitHandler, setSubmitHandler])


  switch (type) {
    case "thirdparty":
      return (
        <ScopeCriteriaFormFields
          criterionFieldOptions={thirdPartyCriterionOptions}
          fieldName={"thirdPartyQualifyingCriteria"}
        />
      )
    case "dossier":
      return (
        <ScopeCriteriaFormFields
          criterionFieldOptions={dossierCriterionOptions}
          fieldName={"dossierQualifyingCriteria"}
        />
      )
    case "document":
      return (
        <AuthorizedDocumentsFormSelect
          documentTypes={documentTypes}
          fieldName={"authorizedDocumentTypes"}
        />
      )
    default: return;
  }

}

function RoleScopes({ 
  setSubmitHandler, 
  onValidateSuccess, 
  role, 
  accountActivities, 
  accountCountries, 
  accountThirdpartyAttributes, 
  accountDossierAttributes,
  accountCalculatedAttributes,
  accountIndicators,
}) {
  const { classes } = useStyles();

  const [thirdPartyAccordionIsOpen, setThirdPartyAccordionIsOpen] = useState(true);
  const [dossierAccordionIsOpen, setDossierAccordionIsOpen] = useState(true);
  const [documentAccordionIsOpen, setDocumentAccordionIsOpen] = useState(true);

  return (
    <RoleScopeForm submitHandler={() => { }} formId={"scope-form-id"} role={role}>
      {/* ThirdParties */}
      <div className={classes.root}>
        <EAIcon icon="arrow/arrow-to-up_large" className={thirdPartyAccordionIsOpen ? classes.arrowIcon : classes.arrowIconReverse} />
        <div className={classes.headerWrapper} onClick={() => setThirdPartyAccordionIsOpen(!thirdPartyAccordionIsOpen)}>
          <FullScopeTitle type={"thirdparty"} />
        </div>
        {/* Content */}
        {thirdPartyAccordionIsOpen &&
          <div className={classes.subItemsWrapper}>
            <Divider />
            {/* Form */}
            <div className={classes.formWrapper}>
              <div className={classes.formTitleDeco}></div>
              <div className={classes.formTitle}> {i18next.t(`roles.scope.thirdparty.title`)} </div>
              <div className={classes.formLine}>
                <ScopeFormFieldsWrapper
                  setSubmitHandler={setSubmitHandler}
                  role={role}
                  onValidateSuccess={onValidateSuccess}
                  type={"thirdparty"}
                  accountActivities={accountActivities}
                  accountCountries={accountCountries}
                  accountAttributes={accountThirdpartyAttributes}
                  accountIndicators={accountIndicators}
                  accountCalculatedAttributes={accountCalculatedAttributes}
                />
              </div>
            </div>
          </div>
        }
      </div>

      {/* Dossiers */}
      <div className={classes.root}>
        <EAIcon icon="arrow/arrow-to-up_large" className={dossierAccordionIsOpen ? classes.arrowIcon : classes.arrowIconReverse} />
        <div className={classes.headerWrapper} onClick={() => setDossierAccordionIsOpen(!dossierAccordionIsOpen)}>
          <FullScopeTitle type={"dossier"} />
        </div>
        {/* Content */}
        {dossierAccordionIsOpen &&
          <div className={classes.subItemsWrapper}>
            <Divider />
            {/* Form */}
            <div className={classes.formWrapper}>
              <div className={classes.formTitleDeco}></div>
              <div className={classes.formTitle}> {i18next.t(`roles.scope.dossier.title`)} </div>
              <div className={classes.formLine}>
                <ScopeFormFieldsWrapper
                  setSubmitHandler={setSubmitHandler}
                  role={role}
                  onValidateSuccess={onValidateSuccess}
                  type={"dossier"}
                  accountActivities={accountActivities}
                  accountCountries={accountCountries}
                  accountAttributes={accountDossierAttributes}
                  accountIndicators={accountIndicators}
                  accountCalculatedAttributes={accountCalculatedAttributes}
                />
              </div>
            </div>
          </div>
        }
      </div>

      {/* Documents */}
      <div className={classes.root}>
        <EAIcon icon="arrow/arrow-to-up_large" className={documentAccordionIsOpen ? classes.arrowIcon : classes.arrowIconReverse} />
        <div className={classes.headerWrapper} onClick={() => setDocumentAccordionIsOpen(!documentAccordionIsOpen)}>
          <FullScopeTitle type={"document"} />
        </div>
        {/* Content */}
        {documentAccordionIsOpen &&
          <div className={classes.subItemsWrapper}>
            <Divider />
            {/* Form */}
            <div className={classes.formWrapper}>
              <div className={classes.formTitleDeco}></div>
              <div className={classes.formTitle}> {i18next.t(`roles.scope.document.title`)} </div>
              <div className={classes.formLine} style={{ paddingTop: "16px" }}>
                <ScopeFormFieldsWrapper
                  setSubmitHandler={setSubmitHandler}
                  role={role}
                  onValidateSuccess={onValidateSuccess}
                  type={"document"}
                  accountActivities={accountActivities}
                  accountCountries={accountCountries}
                  accountIndicators={accountIndicators}
                  accountCalculatedAttributes={accountCalculatedAttributes}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </RoleScopeForm>
  )
}

export default RoleScopes;
