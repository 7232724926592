import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  addButton: {
    marginRight: "15px",
  },
  addButtonAddIcon: {
    fontSize: "1.5rem",
    fontWeight: 400,
    marginRight: "5px",
  },
}));

export default useStyles;
