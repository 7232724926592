import React from "react";
import SectionContentWrapper from "../common/SectionContentWrapper";
import CalculatorDataTable from "./CalculatorDataTable";


function Calculator() {
  return (
      <SectionContentWrapper>
        <CalculatorDataTable />
      </SectionContentWrapper>
  )
}

export default Calculator;
