import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogText: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    whiteSpace: "pre-wrap",
    color: theme.palette.grey.grey700,
    fontWeight: 600,
    fontFamily: "Metric",
    fontSize: "20px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: "1rem",
  },
  labelInputRole: {
    minWidth: "265px",
    maxWidth: "425px",
    paddingRight: "1rem !important",
    color: theme.palette.grey.grey500,
    fontWeight: 600,
    border: "none",
  },
  labelInputMail: {
    minWidth: "300px",

    "& .MuiOutlinedInput-root":{
      border: "none",
    },
  },
  formInputWrapper :{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  reasonSelect: {
    "& .MuiSelect-root": {
      background: "white",
      padding: "0.25rem 0.75rem 0.375rem 0.625rem",
    },
  },
  menuItem: {
    minHeight: "24px",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  imgWrapper: {
    backgroundColor: theme.palette.primary.primary100,
    padding: "1rem",
    borderRadius: "50%",
    width: "55px",
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  imgContent: {
    width: "33px",
    position: "relative",
    left: "-2px",
  },
  formLabel: {
    fontWeight: 600,
    color: theme.palette.grey.grey500,
    marginBottom: "12px",
  },
  errorText:{
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginBottom: "0",
    marginLeft: "14px",
    color: "#FF0001",
  },
}));

export default useStyles;
