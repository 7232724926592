import React, { useContext, useState } from "react";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import { ERROR } from "../../../services/common/Constants";
import { reinviteAccountUsers } from "../../../services/edge/UserService";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import EADialog from "../../../components/common/EADialog";
import useStyles from "../../../style/js-style/containers/users/users-list/UserReinviteConfirmDialogStyle";
import {reinviteTPContacts} from "../../../services/edge/ThirdpartyService";
import EAIcon from "../../../components/common/EAIcon";

const UserReinviteConfirmDialog = ({
  isOpen,
  userId,
  userEmail,
  handleClose,
  tpContact = false
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isReinviteSend, setIsReinviteSend] = useState(false);
  const id = isOpen ? "filters-dialog" : undefined;
  const isManyUsersSelected = userId?.length > 1 || userEmail === undefined;

  const handleReinvite = async () => {
    if (tpContact){
      const contact = userId[0] !== undefined ? userId[0] : userId;
      try {
        await reinviteTPContacts(state.account.id, contact.tpAccountId, contact.userId, dispatch);
        setIsReinviteSend(true);
      } catch (error) {
        console.error(error);
        dispatch({ type: "ALERT", alert: { type: ERROR, msg: "USER_REINVITE_ERROR" } });
      }
    }else{
      try {
        await reinviteAccountUsers(state.account.id, userId, dispatch);
        setIsReinviteSend(true);
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "USER_REINVITE_ERROR" },
        });
      }
    }

  };

  const handleDialogClose = () => {
    handleClose();
  };

  return (
      <EADialog
        id={id}
        isOpen={isOpen}
        onCancel={handleDialogClose}
        onValidate={handleReinvite}
        hasActions={!isReinviteSend}
        maxWidth="popupxs"
        fullWidth
        btnSize="large"
        isFeedBack={isReinviteSend}
        validateBtnLabel={tpContact ? "valid" : "validate"}
      >
        {/* After confirm */}
        {isReinviteSend && (
          <FeedBackPopUp
            onClick={handleDialogClose}
            variant={"grey"}
            title={
              tpContact
                ? i18next.t("usersTable.reinviteTPTitle")
                : isManyUsersSelected
                  ? i18next.t("usersTable.reinviteAllTitle")
                  : i18next.t("usersTable.reinviteTitle")
            }
            imgSrc={"/img/icons/reinvit.svg"}
            content={ i18next.t("usersTable.reinviteInfo") }
          />
        )}

        {/* Before confirm */}
        {!isReinviteSend && (
          <div variant="body1" className={classes.dialogText}>
            {
              isManyUsersSelected
                ?
                  <div className={classes.deleteAllWrapper}>
                    <div className={classes.imgContentWrapper}>
                      <span className={classes.imgWrapperMulti}>
                        <EAIcon icon={"reinvit"} className={classes.imgContentMulti} />
                      </span>
                    </div>
                    <span className={classes.bold}>
                      {i18next.t("usersTable.reinviteAllConfirm")}
                    </span>
                  </div>
                :
                  <div className={classes.deleteAllWrapper}>
                    <div className={classes.imgContentWrapper}>
                      <span className={classes.imgWrapper}>
                        <EAIcon icon={"reinvit"} className={classes.imgContent} />
                      </span>
                    </div>
                    <span className={classes.bold}>
                      {i18next.t("usersTable.reinviteConfirm")}
                    </span>
                    <div className={classes.mailInfoWrapper}> {userEmail} </div>
                  </div>
            }
          </div>
        )}
    </EADialog>
  );
};

export default UserReinviteConfirmDialog;
