import React, { useCallback, useContext, useEffect } from "react";
import { Context } from "../../../states/Store";
import {
  InitialThirdpartiesTableSort,
  getThirdpartiesTableColumns,
  thirdpartiesDefaultVisibilityColumns,
} from "./ThirdpartiesTableUtils";
import { useHistory } from "react-router";
import { ERROR, Exports, SUCCESS } from "../../../services/common/Constants";
import i18next from "i18next";
import {getAccountThirdparties, getExportFileFromAccountThirdParties} from "../../../services/edge/ThirdpartyService";
import countries from "i18n-iso-countries";
import EADataGrid from "../../../components/common/datatable/EADataGrid";
import {
  formatActivityText,
  formatFinancialStrengthRiskLevel,
  formatRegistrationStatus,
  formatThirdpartyIdentifier,
} from "../../../services/common/Utils";
import {
  buildAttributesGridColumns,
  formatAttributeValues,
} from "../../../services/common/AttributeUtils";
import {
  formatCalculationResult,
  buildGridColumnsForCalculations,
} from "../../../services/common/CalculatorUtils";
import { ThirdpartyAddButton } from "../../../components/common/datatable/DataTableButtons";
import ROUTES from "../../../config/Routes";
import ListLayout from "../../../components/common/ListLayout";
import { CountSelectedRow } from "components/common/datatable/CountSelectedRow";
import { ThirdpartiesSelectionMenu } from "./ThirdpartiesSelectionMenu";
import { formatInduedRiskLevel } from "services/common/InduedUtils";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { ThirdpartiesListSearchForm } from "./ThirdpartiesListSearchForm/ThirdpartiesListSearchForm";
import { useRef } from "react";
import { isAbortError } from "services/common/ApiUtils";
import { useListFormFilters } from "components/common/hooks/useListFormFilters";
import { useColumnsVisibility } from "components/common/hooks/useColumnsVisibility";

function ThirdpartiesDataTable({ active }) {
  const [state, dispatch] = useContext(Context);
  const [thirdparties, setThirdparties] = React.useState();
  const [shownThirdparties, setShownThirdparties] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [attributesColumns, setAttributesColumns] = React.useState();
  const [calculatedAttributesColumns, setCalculatedAttributesColumns] = React.useState();
  const [indicatorsColumns, setIndicatorsColumns] = React.useState();
  const [thirdpartiesColumns, setThirdpartiesColumns] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [thirdpartiesSelected, setThirdpartiesSelected] = React.useState([]);
  const [showTransparency, setShowTransparency] = React.useState(false);
  const [globalCount, setGlobalCount] = React.useState(0);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const abortControllerRef = useRef();

  const history = useHistory();
  const [sortModel, setSortModel] = React.useState(
    InitialThirdpartiesTableSort
  );

  const {
    formFilters,
    saveSessionFormFilters,
  } = useListFormFilters("thirdparties-list");

  const {
    columnsVisibility,
    setColumnsVisibility,
    setInitialColumnsVisibility
  } = useColumnsVisibility("thirdparties-list", true);

  const fetchThirdparties = useCallback((page, rowPerPage, filters, sortModel) => {
    if(abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
    setLoader(true);
    (async function fetchData() {
      try {
        const resp = await getAccountThirdparties(state.account.id, active, { page, rowPerPage }, filters, sortModel, null, signal);
        setThirdparties(resp.content || []);
        dispatch({
          type: "PAGINATION", pagination: {
            page: resp.page,
            rowPerPage: resp.size,
            count: resp.totalElements
          }
        });
        setGlobalCount(resp.globalCount || resp.totalElements)
        setIsLoading(false);
        setLoader(false);
      } catch (e) {
        if (!isAbortError(e)) {
          dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
          setThirdparties([]);
        }
        setLoader(false);
      }
    })();
  }, [state.account, active, dispatch])

  // -------- Data ------- //
  useEffect(() => {
    if (state.account && formFilters) {
      fetchThirdparties(state.pagination.page, state.pagination.rowPerPage, formFilters, sortModel)
    }
    return () => {
      abortControllerRef.current?.abort();
    }
  }, [state.account, fetchThirdparties, state.pagination.page, state.pagination.rowPerPage, formFilters, sortModel]);

  // Map thirdparties
  useEffect(() => {
    let mappedTps = thirdparties?.map((tp) => {
      return {
        ...tp,
        id: tp.thirdpartyId,
        identifier: formatThirdpartyIdentifier(tp),
        activity: localizeTranslate(formatActivityText(
          tp.etabActivityLabel,
          tp.etabActivityCode,
          tp.addressCountryCode
        ),
        ),
        headquarter: tp.headquarter ? "headquarter" : "secondaryEstablishment",
        thirdpartyAlerts: !(
          tp.thirdpartyAlerts === "NEANT" || !tp.thirdpartyAlerts
        ),
        addressCountry: countries.getName(
          tp.addressCountryCode,
          i18next.language
        ),
        registered: formatRegistrationStatus(tp.registrationStatus),
        complete: tp.complete ? "complete" : "incomplete",
        indued: formatInduedRiskLevel(tp.scores?.indued),
        financialStrengthScore: formatFinancialStrengthRiskLevel(tp.scores?.datablocks_financial_strength?.riskLevel),
        financialStrengthRating: tp.scores?.datablocks_financial_strength?.riskLevel ? (tp.scores?.datablocks_financial_strength?.rating || "--") : i18next.t(`financialStrength.scoreNotActivated`),
        ...tp.attributeValues?.reduce(
          (res, attr) => ({
            ...res,
            [`attribute-${attr.label}`]: formatAttributeValues(attr),
          }),
          {}
        ),
        ...tp.indicators?.reduce(
          (res, indic) => ({
            ...res,
            [`indicator-${indic.name}`]: formatCalculationResult(indic),
          }),
          {}
        ),
        ...tp.calculatedAttributes?.reduce(
          (res, attr) => ({
            ...res,
            [`calculatedAttribute-${attr.name}`]: formatCalculationResult(attr),
          }),
          {}
        ),
        authorizingRoles: tp.authorizingRoles,
      };
    });
    setShownThirdparties(mappedTps || []);
  }, [thirdparties, hasAnyOfPrivileges]);


  // Init thirdparties attributes/calculations columns
  useEffect(() => {
    if (thirdparties) {
      if (thirdparties.length > 0 && thirdparties[0].attributeValues != null && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_THIRD_PARTY_ATTRIBUTES)) {
        setAttributesColumns(buildAttributesGridColumns(thirdparties.map(x => x.attributeValues)));
      } else {
        setAttributesColumns([]);
      }

      if (thirdparties.length > 0 && thirdparties[0].calculatedAttributes != null) {
        setCalculatedAttributesColumns(buildGridColumnsForCalculations(thirdparties.map(x=>x.calculatedAttributes)));
      } else {
        setCalculatedAttributesColumns([]);
      }

      if (thirdparties.length > 0 && thirdparties[0].indicators != null) {
        setIndicatorsColumns(buildGridColumnsForCalculations(...thirdparties.map(x => x.indicators)));
      } else {
        setIndicatorsColumns([]);
      }

      if (thirdparties?.[0]?.transparencyScore != null) {
        setShowTransparency(true);
      }
    }
  }, [thirdparties, hasAnyOfPrivileges])

  // Set Columns Default visibility
  useEffect(() => {
    if (attributesColumns || calculatedAttributesColumns || indicatorsColumns) {
      setInitialColumnsVisibility(thirdpartiesDefaultVisibilityColumns(attributesColumns, calculatedAttributesColumns, indicatorsColumns, active));
    }
  }, [active, attributesColumns, calculatedAttributesColumns, indicatorsColumns, setInitialColumnsVisibility]);

  // Set Grid Columns
  useEffect(() => {
    if (attributesColumns && calculatedAttributesColumns && indicatorsColumns) {
      setThirdpartiesColumns(
        getThirdpartiesTableColumns(
          attributesColumns,
          calculatedAttributesColumns,
          indicatorsColumns,
          active,
          history,
          state.account.id,
          showTransparency,
          hasAnyOfPrivileges,
          state.accounts
        )
      );
    }
  }, [
    attributesColumns,
    calculatedAttributesColumns,
    indicatorsColumns,
    active,
    history,
    state.account,
    showTransparency,
    hasAnyOfPrivileges,
    state.accounts
  ]);

  const addThirdparty = () => {
    history.push(`/${state.account.id}/${ROUTES.THIRDPARTY_ADD}`);
  };

  const handleSearch = (data) => {
    saveSessionFormFilters(data);
    dispatch({ type: "PAGINATION", pagination: {
      page: 0,
      rowPerPage: state.pagination.rowPerPage
    }});
    setThirdpartiesSelected([]);
  }

  const handleSortModelChange = (newSortModel) => {
    if (newSortModel[0] !== sortModel[0]) {
      setSortModel(newSortModel);
      dispatch({
        type: "PAGINATION", pagination: {
          page: 0,
          rowPerPage: state.pagination.rowPerPage
        }
      });
    }
  }

  const externalExportHandler = async (exportType) => {
    let objetResult = null;
    dispatch({
      type: "ALERT",
      alert: { type: SUCCESS, msg: i18next.t(`tableLabels.toolbar.downloadLoading`) },
    });
    const exportDto = {
      columnsVisibility: { 
        ...columnsVisibility,
        stoppingDate: !active && columnsVisibility.stoppingDate
      },
      thirdpartiesSelected: thirdpartiesSelected
    }
    switch (exportType) {
      case Exports.CSV :
      case Exports.EXCEL :
        objetResult = await getExportFileFromAccountThirdParties(state.account.id, active, exportType, formFilters, sortModel, exportDto, dispatch)
        break;
      default: console.log("Type d'export tiers non géré");
    }

    if (objetResult !== null) {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(new Blob([objetResult.arrayBuffer]))
      a.download = objetResult.filename
      a.click()
    }
  }

  // Reset pagination on component unmount
  useEffect(() => {
    return () => {
      dispatch({
        type: "PAGINATION",
        pagination: {
          page: 0,
          rowPerPage: 50,
          count: 0,
        }
      });
    };
  }, [dispatch]);

  return (
    <ListLayout
      count={state.pagination?.count}
      totalCount={globalCount}
      icon={active ? "drawer/thirdparty_active" : "drawer/archive_box_active"}
      title={i18next.t(`thirdpartiesTable.title.${active ? "active" : "inactive"}`)}
      addingComponent={active &&
        <ThirdpartyAddButton
          onClickHandler={addThirdparty}
        />
      }
      isLoading={isLoading}
      filterField={formFilters && (<ThirdpartiesListSearchForm filters={formFilters} submitHandler={handleSearch}/>)}
    >

      {/* DataGrid list */}
      {shownThirdparties && thirdpartiesColumns && columnsVisibility &&
        <EADataGrid
          loading={loader}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          additionnalsLeftComponents={
            <>
              <CountSelectedRow data={thirdpartiesSelected} />
              <ThirdpartiesSelectionMenu thirdpartiesSelected={thirdpartiesSelected} />
            </>
          }
          selection={{
            items: thirdpartiesSelected,
            setItems: setThirdpartiesSelected
          }}
          initialVisibilityModel={columnsVisibility}
          updateVisibilityModel={setColumnsVisibility}
          hasFilters={false}
          inputRows={shownThirdparties}
          columns={thirdpartiesColumns}
          hasCustomPagination
          paginationMode="server"
          rowCount={state.pagination?.count || 0}
          externalExportHandler={externalExportHandler}
        />
      }
    </ListLayout>
  );
}
export default ThirdpartiesDataTable;
