import React from "react";
import SectionContentWrapper from "../common/SectionContentWrapper";
import RulesDataTable from "./RulesDataTable";

function Rules() {
  return (
    <>
      <SectionContentWrapper>
        <RulesDataTable />
      </SectionContentWrapper>
    </>
  );
}

export default Rules;
