import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import i18next from "i18n/i18n";
import { Context } from "states/Store";
import DossierDeactivationDialog from "components/dossiers/DossierDeactivationDialog";
import { archiveBtnCustomStyle, useStyles } from "style/js-style/containers/dossiers/dossiers-sheet/DossierActionsStyle";
import DossierModifyDialog from "components/dossiers/DossierModifyDialog";
import EAIcon from "components/common/EAIcon";
import EAButton from "components/common/EAButton";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { reactivateDossier } from '../../../services/edge/DossierService';
import { ERROR } from '../../../services/common/Constants';

function DossierActions({ dossier, callbackDownloadAuditTrail, downloadingAudit=false }) {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isModifyPopUpOpen, setIsModifyPopUpOpen] = useState(false);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const history = useHistory();
  const { hasAnyOfPrivilegesOnScopedResourceImpl } = React.useContext(RoleBasedAccessControlContext);

  const onReactivateDossier = useCallback(async (dossierId, dossier) => {
    try {
      await reactivateDossier(
        state.account.id,
        dossierId,
        dossier,
        dispatch
      );
      history.go(0);
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: "REACTIVATE_DOSSIER_ERROR" },
      });
    }
  },[state.account.id, dispatch, history]);

  const hasAnyOfPrivilegesOnScopedResource = useCallback((...privileges) => {
    let result = false;

    if (dossier) {
      result = hasAnyOfPrivilegesOnScopedResourceImpl(dossier, ...privileges);
    }

    return result;
  }, [dossier, hasAnyOfPrivilegesOnScopedResourceImpl]);

  useEffect(() => {
    dispatch({
      type: "TOGGLE_MENU_HIGHTLIGHT",
      isMenuHighlighted: isModifyPopUpOpen,
    });
  }, [isModifyPopUpOpen, dispatch]);

  const handleDeactivationDialogClose = (isDeactivated) => {
    setIsDeactivateDialogOpen(false);
    if (isDeactivated) {
      history.goBack();
    }
  };

  const handleModifyPopUpToggle = () => {
    setIsModifyPopUpOpen(!isModifyPopUpOpen);
  };

  return (
    <>
      {dossier && !dossier.stoppingDate && (
        <>
          <EAButton
            content={
              <>
                <EAIcon icon={"edit_white"} className={classes.iconImg} />
                <span>{i18next.t("modify")}</span>
              </>
            }
            onClick={() => handleModifyPopUpToggle()}
            disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER)}
            className={classes.modifyBtn}
          />
          <EAButton
            colorVariant="secondary"
            customStyle={archiveBtnCustomStyle}
            disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_ARCHIVE_DOSSIER)}
            content={
              <>
                <EAIcon icon={"archive_grey"} className={classes.iconImg} />
                <span>{i18next.t("archived")}</span>
              </>
            }
            onClick={() => setIsDeactivateDialogOpen(true)}
            className={classes.archiveBtn}
          />

          <EAButton
            colorVariant="secondary"
            customStyle={archiveBtnCustomStyle}
            disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_AUDIT_TRAIL) || downloadingAudit}
            onClick={() => callbackDownloadAuditTrail()}
            className={classes.archiveBtn + " " + (downloadingAudit ? classes.auditBtnLoading : "")}
            content={
              <>
                <EAIcon
                  icon={"audit-trail-dossier_grey"}
                  className={classes.iconImg}
                />
                {i18next.t("downloadAuditTrail")}
              </>
            }
          />
        </>
      )}

      {/* Réactivation de dossier */}
      {dossier && dossier.stoppingDate && (
        <>
          <EAButton
            colorVariant={"primaryLight"}
            customStyle={{color: "#656575", boxShadow: "none"}}
            content={
              <>
                <EAIcon icon={"play_circle_filled_green"} className={classes.iconImg} />
                <span>{i18next.t("reactivate")}</span>
              </>
            }
            onClick={() => onReactivateDossier(dossier.dossierId, dossier)}
            disabled={!hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_CREATE_DOSSIER)}
          />
        </>
      )}

      {/* Pop-up archive */}
      {isDeactivateDialogOpen && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_ARCHIVE_DOSSIER) && (
        <DossierDeactivationDialog
          dossierId={dossier.dossierId}
          isOpen={isDeactivateDialogOpen}
          handleClose={handleDeactivationDialogClose}
        />
      )}

      {/* Pop-up modify */}
      {isModifyPopUpOpen && hasAnyOfPrivilegesOnScopedResource(PrivilegeEnum.PRIVILEGE_EDIT_DOSSIER) && (
        <DossierModifyDialog
          dossier={dossier}
          isOpen={isModifyPopUpOpen}
          handleClose={handleModifyPopUpToggle}
        />
      )}
    </>
  );
}

export default DossierActions;
