import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    formContainer: {
        [`${theme.breakpoints.up("sm")}`]: {
            display: "flex",
            alignItems: "baseline",
        },
    },
    label: {
        fontWeight: 700,
        color: theme.palette.grey.grey500,
    },
    input: {
        marginTop: "0.5rem",
    },
}));

export default useStyles;
