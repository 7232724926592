import React, {useEffect} from 'react';
import i18next from 'i18next';
import { Controller, useFormContext } from "react-hook-form";

import {
  Chip,
  FormHelperText,
  MenuItem,
  Select,
  Tooltip,

} from "@mui/material";
import _ from 'lodash';

import { DefaultSelectProps } from "services/common/Constants";
import clsx from "clsx";
import useStyles from "style/js-style/containers/administration/calculator/CalculatorStyle";

const CalculatorMultipleSelectChips = ({
  fieldName,
  options,
  selected,
  placeholder,
  dt = false,
}) => {
  const { classes } = useStyles();
  const formMethods = useFormContext();

  if (!selected || selected.length === 0 || (selected[0] === undefined)) {
    return <span className={classes.placeHolder}>{placeholder}</span>;
  }

  const getChipClass = (index) => {
    return selected.length === index + 1 && selected.length % 2 !== 0
      ? classes.largeChip
      : classes.chip;
  };

  const getEntryLabel = (value) => {
    let entry = _.find(options, { value: value });

    return entry === undefined ? "" : entry.label;
  };

  return (
    <>
      <div className={classes.chipsContainer}>
        {selected.map((value, index) => {
          return (
            <Tooltip key={index} title={getEntryLabel(value)}>
              <Chip
                className={clsx(getChipClass(index), dt ? 'dt' : "")}
                key={value}
                label={getEntryLabel(value)}
                deleteIcon={
                  <img
                    src={process.env.PUBLIC_URL + "/img/icons/cross.svg"}
                    alt="close"
                    onMouseDown={(event) => event.stopPropagation()}
                    className={classes.deleteItemIcon}
                  />
                }
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#F2F3F7",
                  color: "#535362",
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: 500,
                  height: "auto",
                  border: 0,
                  maxWidth: "125px",
                  "& .MuiChip-label": {
                    paddingLeft: "4px",
                    paddingRight: "12px",
                  },
                }}
                onDelete={(event) => {
                  event.preventDefault();
                  formMethods.setValue(
                    fieldName,
                    selected?.filter((x) => x !== value)
                  );
                }}
              />
            </Tooltip>
          );
        })}
      </div>
    </>
  );
};

const CalculatorMultipleSelectInput = ({
  fieldName,
  options,
  defaultValue,
  onChange = () => { },
  placeholder,
  label,
  isRequired,
  variant = "default",
  uxVariant = "standard",
  dt = false,
  ...other
}) => {
  const formMethods = useFormContext();
  const { classes } = useStyles();

  // Retire les undefined de la liste des values
  useEffect(()=> {
    const values = formMethods.getValues(fieldName);
    const filteredValues = Object.values(values).filter(i => i !== undefined);
    formMethods.setValue(fieldName, filteredValues)
  },[formMethods, fieldName])

  return (
    <div style={{paddingLeft: variant === "fullSize" ? "16px" : "auto"}}>
      <span className={classes.formLabel}>
        {label && `${label}`}
      </span>

      <Controller
        control={formMethods.control}
        name={fieldName}
        defaultValue={defaultValue}
        rules={{
          validate: (value) => {
            if (isRequired) {
              const isEmpty = value.length === 0;
              if (isEmpty) {
                return i18next.t("infos.required");
              } else {
                formMethods.clearErrors(fieldName);
              }
            }
          },
        }}
        render={({ field }) => (
          <Select
            {...field}
            value={formMethods.getValues(fieldName)}
            id="ea-multiselect-id"
            multiple
            displayEmpty
            onChange={(event) => {
              formMethods.setValue(fieldName, event.target.value);
              formMethods.trigger(fieldName);
              onChange(event);
            }}
            variant="standard"
            MenuProps={DefaultSelectProps.MenuProps}
            className={classes.select}
            style={{
              width: variant === "fullSize"
                ? "calc(100vw - 775px)"
                : uxVariant === "criteriaInput" ? "100%" : "auto",
            }}
            error={!!_.get(formMethods.formState.errors, `${fieldName}`)}
            renderValue={(selected) => (
              <CalculatorMultipleSelectChips
                fieldName={fieldName}
                selected={selected}
                options={options}
                formMethods={formMethods}
                placeholder={placeholder}
                dt={dt}
              />
            )}
            {...other}
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}  className={dt || option?.dt ? 'dt' : ""}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      {!!_.get(formMethods.formState.errors, `${fieldName}`) && (
        <FormHelperText className="Mui-error">
          {_.get(formMethods.formState.errors, `${fieldName}.message`)}
        </FormHelperText>
      )}
    </div>
  );
};

export default CalculatorMultipleSelectInput;
