import React, { useContext, useState } from "react";
import i18next from "i18next";
import EADialog from "components/common/EADialog";
import { ERROR, SUCCESS, statusEngCode } from "services/common/Constants";
import { Context } from "states/Store";
import { Controller, FormProvider, useForm } from "react-hook-form";
import EAIcon from "components/common/EAIcon";
import { FormGroup, FormLabel, MenuItem, Select } from "@mui/material";
import { updateDefaultStatus } from "services/edge/DocumentManagementService";
import useStyles from "./DocumentManagementDialogStyle";
import clsx from "clsx";

const DocumentManagementEditDialog = ({
  isOpen,
  docList,
  onClose,
  onValidateSuccess
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [isFormValidated, setIsFormValidated] = useState(false);

  const cancelDialogHandler = () => {
    if (isFormValidated) {
      setIsFormValidated(false);
    } else {
      onClose();
    }
  };

  const FORM_ID = "doc-management-form-id";

  const statusOptions = [{
    value: statusEngCode.ACTION_REQUIRED,
    label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.ACTION_REQUIRED}`)
  },
  {
    value: statusEngCode.VALID,
    label: i18next.t(`viewer.status.statusLabelDossier.${statusEngCode.VALID}`)
  }];

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
    defaultValues: {
      status: statusOptions[0].value
    }
  });

  const {
    control,
    handleSubmit
  } = formMethods;

  const handleUpdate = async (data) => {
    if (state.account.id && docList) {
      try {
        await updateDefaultStatus(
          state.account.id,
          {
            docCode: docList.map(d => d.code),
            defaultStatus: data?.status
          },
          null
        );

        dispatch({
          type: "ALERT",
          alert: { type: SUCCESS, msg: i18next.t("demandes.documentManagement.notifications.updateSuccess") },
        });
        onValidateSuccess();

      } catch (error) {
        console.error(error);
        const err =
          error?.message?.messages?.error && error?.message?.messages?.error[0];
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
        });
      }
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={cancelDialogHandler}
      onClose={onClose}
      maxWidth="popupxs"
      variant="default"
      fullWidth
      hasActions={true}
      formId={FORM_ID}
    >
      <FormProvider {...formMethods}>
        <form id={FORM_ID} onSubmit={handleSubmit(handleUpdate)}
        >
          {/* Title */}
          <div className={classes.root}>
            <div className={clsx(classes.imgWrapper, classes.imgWrapperGreen)}>
              <EAIcon icon="pen" style={{width: "30px"}}/>
            </div>
            <span className={classes.bolder}>
              {i18next.t("demandes.documentManagement.editDialog.title")}
            </span>
          <div style={{width: "80%", marginTop: "10px"}}>
            <FormLabel className={classes.statutLabel}>
              {`${i18next.t("demandes.documentManagement.editDialog.status")} * `}
            </FormLabel>
            <FormGroup style={{ minWidth: "150px", marginTop: "7px" }}>
              <Controller
                control={control}
                name="status"
                rules={{
                  required: i18next.t("infos.required"),
                }}
                render={({ field }) => (
                  <Select
                    id="status"
                    variant="outlined"
                    className={classes.selectInput}
                    {...field}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormGroup>
          </div>
          </div>
        </form>
      </FormProvider>
    </EADialog>
  );
};

export default DocumentManagementEditDialog;
