import React, { useContext, useEffect, useState } from "react";
import EADataGrid from "../common/datatable/EADataGrid";
import { useHistory, useParams } from "react-router";
import { canAnswerDocument, formatDepositorOrTransmitter, isUpdatableDocument } from "../../services/common/DocumentUtils";
import { Context } from "../../states/Store";
import {
  documentsDefaultVisibilityColumns,
  getDocumentsTableColumns,
} from "./DocumentsDataTableUtils";
import { RoleBasedAccessControlContext } from "services/common/RolesUtils";
import i18next from "i18next";
import { localizeTranslate } from "i18n/LocalizeUtils";
import DownloadDialog from "./DownloadDialog";
import { GridLoadingOverlay } from "@mui/x-data-grid";
import { useColumnsVisibility } from "components/common/hooks/useColumnsVisibility";

function DocumentsDataTable({
  documents,
  loading = false,
  answerCount = 0,
  component,
  paginationMode = "client",
  pageSize,
  multiDataGridPage = false,
  sheetVariant = false,
  isDossierMonitored = true,
  onVisibleRowsCountChange = () => { },
  sortModel,
  onSortModelChange = () => {},
  onAnswerFilterChange = () => {},
  filters
}) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const [mappedDocuments, setMappedDocuments] = useState([]);
  const [shownDocuments, setShownDocuments] = useState([]);
  const [documentsColumns, setDocumentsColumns] = useState();
  const { hasAnyOfPrivilegesOnScopedResourceImpl } = useContext(RoleBasedAccessControlContext);
  const [isAnswerFilterActive, setAnswerFilterActive] = React.useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);
  const [exportType, setExportType] = React.useState();

  let { thirdpartyId } = useParams();

  const {
    columnsVisibility,
    setColumnsVisibility,
    setInitialColumnsVisibility
  } = useColumnsVisibility(component, component === "documents-list");

  useEffect(() => {
    setInitialColumnsVisibility(documentsDefaultVisibilityColumns())
  }, [setInitialColumnsVisibility])

  useEffect(() => {
    localStorage.setItem('lastVisited', window.location.pathname);
  }, [])

  useEffect(() => {
    setMappedDocuments(
      documents?.map((x) => {
        return {
          id: Math.random(),
          ...x,
          nameFr: localizeTranslate(x.nameFr),
          depositorOrTransmitter: formatDepositorOrTransmitter(
            x.depositorName,
            x.transmitter
          ),
          statusCode: x.statusCode,
          dossierTypeLibelle: x.dossierTypeLibelle ? localizeTranslate(x.dossierTypeLibelle) : localizeTranslate(i18next.t("free")),
        };
      })
    );
  }, [documents]);

  useEffect(() => {
    setDocumentsColumns(
      getDocumentsTableColumns(
        component,
        state.account.id,
        history,
        hasAnyOfPrivilegesOnScopedResourceImpl,
        thirdpartyId,
        isDossierMonitored
      )
    );
  }, [
    history,
    state.account,
    hasAnyOfPrivilegesOnScopedResourceImpl,
    component,
    thirdpartyId,
    isDossierMonitored
  ]);

  useEffect(() => {
    if(paginationMode === "client" && isAnswerFilterActive) {
      setShownDocuments(mappedDocuments.filter((x) => (canAnswerDocument(x) && !isUpdatableDocument(x))));
    } else {
      setShownDocuments(mappedDocuments);
    }
  }, [isAnswerFilterActive, mappedDocuments, paginationMode]);


  const externalExportHandler = async (type) => {
    setIsExportDialogOpen(true);
    setExportType(type);
  }

  const handleSortModelChange = (newSortModel) => {
    if (newSortModel[0] !== sortModel[0]) {
      onSortModelChange(newSortModel);
      if(paginationMode === "server") {
        dispatch({ type: "PAGINATION", pagination: {
            page: 0,
            rowPerPage: state.pagination.rowPerPage
          }
        });
      }
    }
  }

  return (
    <>
      {documentsColumns && columnsVisibility && (
        <EADataGrid
          inputRows={shownDocuments}
          columns={documentsColumns}
          loading={loading}
          initialVisibilityModel={columnsVisibility}
          updateVisibilityModel={setColumnsVisibility}
          onSortModelChange={handleSortModelChange}
          pageSize={pageSize}
          hasCustomPagination={!multiDataGridPage}
          variant={sheetVariant ? "sheet" : "default"}
          onVisibleRowsCountChange={onVisibleRowsCountChange}
          answerBadgeCount={answerCount}
          isAnswerFilterActive={isAnswerFilterActive}
          showAnswerFilter={isDossierMonitored}
          setAnswerFilterActive={newFilterActive => {
            setAnswerFilterActive(newFilterActive);
            (paginationMode === "server") && onAnswerFilterChange(newFilterActive)
          }}
          hasFilters={paginationMode === "client"}
          paginationMode={paginationMode}
          {...(paginationMode === "server" ? { 
            externalExportHandler,
            LoadingOverlay: GridLoadingOverlay, // TODO: default loading overlay while fixing the LinearProgress not showing
            rowCount: state.pagination?.count || 0
          } : {})}
        />
      )}
      {isExportDialogOpen && (
        <DownloadDialog 
          isOpen={isExportDialogOpen}
          count={state.pagination?.count}
          filters={filters}
          isAnswerFilterActive={isAnswerFilterActive}
          sortModel={sortModel}
          columnsVisibility={columnsVisibility}
          exportType={exportType}
          onCancel={() => setIsExportDialogOpen(false)}
        />
      )}
    </>
  );
}
export default DocumentsDataTable;
