import React from "react";
import { Paper, Typography } from "@mui/material";
import i18next from "i18next";
import useStyles from "../../../style/js-style/containers/dossiers/dossiers-sheet/DossierSheetCompletenessStyle";
import EAIcon from "../../../components/common/EAIcon";
import clsx from "clsx";

function DossierCardStatus({ status }) {
  const { classes } = useStyles();

  return (
    <>
      <Paper
        elevation={0}
        className={clsx(classes[status?.class], classes.rootStatus)}
      >
        <Typography
          variant="h2"
          textAlign="center"
          className={classes.completnessTitle}
        >
          {i18next.t(status?.label)}
        </Typography>
        <div className={classes.completenessIcon}>
          <EAIcon icon={status?.icon} />
        </div>
      </Paper>
    </>
  );
}

export default DossierCardStatus;
