import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogText: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    whiteSpace: "pre-wrap",
    color: theme.palette.grey.grey700,
    fontWeight: 600,
    fontFamily: "Metric",
    fontSize: "20px",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  imgContent: {
    position: "relative",
  },
  inputText: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #E2E2EA !important",
      },
    }
  },
  captionText: {
    fontSize: "12px",
    color: "#7E7E8F"
  }
}));

export default useStyles;
