import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";



export async function getAllAuthorizedTypeDoc(accountId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/account/${accountId}/doc-management/authorized-docs`
  );
} 

export async function updateDefaultStatus(accountId, data, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `365/account/${accountId}/doc-management/status`,
    data
  );
} 

export async function addAuthorizedDocType(accountId, data, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `365/account/${accountId}/doc-management`,
    data
  );
} 

export async function removeAuthorizedDocType(accountId, data, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `365/account/${accountId}/doc-management`,
    data
  );
} 