import React, { useCallback, useContext, useEffect } from "react";
import i18next from "i18next";
import _ from "lodash";
import { useFormContext, useWatch } from "react-hook-form";
import { Tab, Tabs } from "@mui/material";

import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import EAIcon from "components/common/EAIcon";
import EAButton from "components/common/EAButton";
import EATabPanel from "components/common/EATabPanel";
import Variables from "containers/administration/calculator/form/criteria/Variables";
import Branches from "containers/administration/calculator/form/criteria/Branches";
import {
  CalculationDeclarationContext,
  getDefaultBranchModel,
  getBranchModel,
  getVariableModel,
  isValidBranch,
  parseTargetFieldFromVariable,
} from "services/common/CalculatorUtils";
import {
  PrivilegeEnum,
  RoleBasedAccessControlContext
} from "services/common/RolesUtils";
import clsx from "clsx";

const DeclarationStep = ({
  isEditionMode = false,
  isActiveCalculation = false,
  onCancel = () => { },
  onContinue = () => { },
  onDelete = () => { },
}) => {
  const { classes } = useStyles();
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const formMethods = useFormContext();
  const {
    formState,
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
    trigger,
    clearErrors
  } = formMethods;

  const [onVariableChangeCallBacks, setOnVariableChangeCallBacks] = React.useState({});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [duplicateVariablesIds, setDuplicateVariablesIds] = React.useState([]);
  const [isDisabledAction, setIsDisabledAction] = React.useState(true);

  let variables = useWatch({ name: `variables` });
  let branches = useWatch({ name: `branches` });
  let resultType = useWatch({ name: `resultType` });

  // Vérifie que la variable est correctement définie et qu'elle n'est pas un doublon
  const isValidVariable = useCallback((variable) => {
    let bValid = false;

    if (variable && (variable.name !== "") && !duplicateVariablesIds.includes(variable.id)) {
      const field = parseTargetFieldFromVariable(variable);

      if ((field !== undefined) && (field !== "")) {
        bValid = true;
      }
    }

    return bValid;
  }, [duplicateVariablesIds]);

  // Ajoute l'id de la variable si c'est un doublon
  const addDuplicateVariable = (variableId) => {
    if (!duplicateVariablesIds.includes(variableId)) {
      setDuplicateVariablesIds(prev => [...prev, variableId]);
    }
  }

  // Supprime l'id de la variable si elle est contenu dans duplicateVariablesIds
  const removeDuplicateVariable = (variableId) => {
    if (duplicateVariablesIds.includes(variableId)) {
      setDuplicateVariablesIds(prev => [...prev.filter(entry => entry !== variableId)]);
    }
  }

  // Associe une callBack a un id de critère
  const addCallback = (id, callBack) => {
    setOnVariableChangeCallBacks((prevCallbacks) => {
      const updatedCallbacks = { ...prevCallbacks };
      updatedCallbacks[id] = callBack;
      return updatedCallbacks;
    });
  };

  // Suppression de la callBack
  const removeCallBack = (id) => {
    setOnVariableChangeCallBacks((prevCallbacks) => {
      const updatedCallbacks = { ...prevCallbacks };
      if (updatedCallbacks[id]) {
        delete updatedCallbacks[id];
      }
      return updatedCallbacks;
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const submitForm = useCallback((data) => {
    onContinue(data);
  }, [onContinue]);

  const checkVariables = useCallback(() => {
    return !_.isEmpty(variables) || _.every(variables, isValidVariable);
  }, [variables, isValidVariable]);

  const checkBranches = useCallback(() => {
    let bValid = false;
    const defaultBranch = _.find(branches, { default: true });

    if (defaultBranch && isValidBranch(defaultBranch)) {
      const otherBranches = _.filter(branches, { default: false });

      bValid = !_.isEmpty(otherBranches) && _.every(otherBranches, isValidBranch);
    }

    return bValid;
  }, [branches]);

  useEffect(() => {
    setIsDisabledAction(!_.isEmpty(errors) || !checkVariables() || !checkBranches());
  }, [errors, checkVariables, checkBranches]);

  const handleSave = useCallback(async () => {
    setIsDisabledAction(true);
    await trigger();

    if (_.isEmpty(formState.errors)) {
      if (isEditionMode) {
        onContinue();
      } else {
        await handleSubmit(submitForm)();
      }
    }
  }, [handleSubmit, submitForm, isEditionMode, formState, onContinue, trigger]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const context = React.useMemo(
    () => ({
      addCallback: addCallback,
      removeCallBack: removeCallBack,
      isValidVariable: isValidVariable,
    }),
    [
      isValidVariable,
    ]
  );

  return (
    <CalculationDeclarationContext.Provider value={context}>
      <div className={classes.rootDeclaration}>
        <EAIcon icon="cross" onClick={() => onCancel()} className={classes.cross} />
        <div className={classes.declarationHeader}>
          <span className={classes.declarationHeaderCalcul}>
            {i18next.t("calculator.dialog.create.calcul")} :
          </span>
          <span className={clsx(classes.declarationHeaderTitle, 'dt')}>
            &nbsp;{getValues("name")}
          </span>
          <span className={classes.declarationHeaderType}>
            {i18next.t("calculator.dialog.create.natureChoice." + getValues("nature"))}
          </span>
        </div>

        {/* --- Variables --- */}
        <div className={classes.declarationVariables}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="variables tabs"
            sx={{ borderBottom: 1, borderColor: '#D4D4D4', width: "490px", marginLeft: "24px" }}
          >
            <Tab
              label={i18next.t("calculator.dialog.create.label.variablesDeclaration")}
              {...a11yProps(0)}
              className={classes.tab}
            />
          </Tabs>

          {/* Déclarations des variables */}
          <EATabPanel value={selectedTab} index={0} variant={"withoutBackground"} style={{ flex: 1, padding: "24px", }}>
            <Variables
              fieldName={`variables`}
              onVariableChangeCallBacks={onVariableChangeCallBacks}
              removeDuplicateVariable={removeDuplicateVariable}
              addDuplicateVariable={addDuplicateVariable}
            />
          </EATabPanel>
        </div>

        {/* --- Branches --- */}
        <Branches fieldName={`branches`} resultType={resultType} />

        {/* --- Actions --- */}
        <div className={classes.declarationControls}>
          {/* Supprimer */}
          {isEditionMode ?
            <div
              className={clsx(classes.declarationControlsLeft, !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_CALCULATION) ? classes.disabledDeleteBtn : classes.deleteCalc)}
              onClick={() => {
                if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_CALCULATION)) {
                  onDelete();
                }
              }}
            >
              <EAIcon icon="delete_red" style={{ width: "10px" }} />
              <span>{i18next.t("calculator.dialog.create.deleteCalcul")}</span>
            </div> : <div />
          }

          {/* Annuler / Enregistrer / Activer */}
          <div className={classes.declarationControlsRight}>
            <EAButton
              onClick={(e) => {
                e.preventDefault();

                if (isEditionMode) {
                  formMethods.reset();
                } else {
                  setValue("variables", [getVariableModel()]);
                  setValue("branches", [getDefaultBranchModel(), getBranchModel()]);
                  clearErrors();
                }

                onCancel();
              }}
              content={i18next.t("cancel")}
              customStyle={{ width: "80px" }}
              colorVariant={"secondary"}
            />

            <EAButton
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              content={i18next.t("save")}
              colorVariant={"primaryLight"}
              customStyle={{ width: "117px" }}
              disabled={isDisabledAction}
            />

            {!isEditionMode ? (
              <EAButton
                onClick={(e) => {
                  e.preventDefault();
                  if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS)) {
                    setValue(`enabled`, true);
                    handleSave();
                  }
                }}
                content={i18next.t("activate")}
                customStyle={{ width: "117px" }}
                disabled={isDisabledAction || !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS)}
              />
            ) : !isActiveCalculation ? (
              <EAButton
                onClick={(e) => {
                  e.preventDefault();
                  if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS)) {
                    setValue(`enabled`, true);
                    handleSave();
                  }
                }}
                content={i18next.t("activate")}
                customStyle={{ width: "117px" }}
                disabled={isDisabledAction || !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS)}
              />
            ) : (
              <EAButton
                onClick={(e) => {
                  e.preventDefault();
                  if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS)) {
                    setValue(`enabled`, false);
                    handleSave();
                  }
                }}
                content={i18next.t("deactivate")}
                colorVariant={"success"}
                customStyle={{ width: "117px" }}
                disabled={isDisabledAction || !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_CALCULATION_STATUS)}
              />
            )}
          </div>
        </div>
      </div>
    </CalculationDeclarationContext.Provider>
  );
}

export default DeclarationStep;
