import React, { useContext } from "react";
import { Chip } from "@mui/material";
import i18next from "i18next";
import { EADataGridCell } from "../../../components/common/datatable/EADataGrid";
import EASpinner from "../../../components/common/EASpinner";
import ROUTES from "../../../config/Routes";
import { Context } from "../../../states/Store";
import MainHeaderCell from "../../../components/common/datatable/MainHeaderCell";
import { PrivilegeEnum } from "services/common/RolesUtils";

const TpSearchChipStyle = {
  cursor: "pointer",
  width: "100%",
  maxWidth: "75px",
  borderRadius: "2px",
};

const getThirdpartySheetUrl = (accountId, thirdpartyId) =>
  `/${accountId}/${ROUTES.THIRDPARTY_DETAIL.replace(
    ":thirdpartyId",
    thirdpartyId
  )}`;

const TpSearchCell = ({ params, withTooltip = true, allowClick = false, disableTranslation = false, children }) => {
  const [state,] = useContext(Context);
  return (
    <EADataGridCell
      link={
        allowClick &&
        params.row.onboarded &&
        params.row.thirdPartyId &&
        getThirdpartySheetUrl(state.account.id, params.row.thirdPartyId)
      }
      tooltip={withTooltip}
      disableTranslation={disableTranslation}
    >
      {children ?? params.value}
    </EADataGridCell>
  );
};

export const tpSearchResultColumns = (
  handleResultRowClick,
  thirdpartyAddLoader,
  thirdpartyIdLoader,
  hasAnyOfPrivileges
) => {
  return [
    {
      field: "name",
      headerName: i18next.t("corporateName"),
      flex: 0.2,
      renderHeader: () => (
        <MainHeaderCell name={i18next.t("corporateName")} />
      ),
      renderCell: (params) => <TpSearchCell params={params} disableTranslation={true} />,
    },
    {
      field: "identifier",
      headerName: i18next.t("identifier"),
      flex: 0.15,
      renderCell: (params) => <TpSearchCell params={params} disableTranslation={true} />,
    },
    {
      field: "addressStreet",
      headerName: i18next.t("address"),
      flex: 0.15,
      renderCell: (params) => <TpSearchCell params={params} disableTranslation={true} />,
    },
    {
      field: "addressPostCode",
      headerName: i18next.t("postalCode"),
      flex: 0.08,
      renderCell: (params) => <TpSearchCell params={params} disableTranslation={true} />,
    },
    {
      field: "addressCity",
      headerName: i18next.t("city"),
      flex: 0.12,
      renderCell: (params) => <TpSearchCell params={params} disableTranslation={true} />,
    },
    {
      field: "headquarter",
      headerName: i18next.t("thirdpartySearch.establishmentType"),
      flex: 0.12,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: [
        { value: true, label: i18next.t("headquarter") },
        { value: false, label: i18next.t("secondaryEstablishment") },
      ],
      valueFormatter: (params) =>
        i18next.t(params.value ? "headquarter" : "secondaryEstablishment"),
      renderCell: (params) => {
        params.value = params.value
          ? i18next.t("headquarter")
          : i18next.t("secondaryEstablishment");
        return <TpSearchCell params={params} />;
      },
    },
    {
      field: "onboarded",
      headerName: i18next.t("thirdpartySearch.thirdpartyState"),
      flex: 0.07,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: [
        { value: true, label: i18next.t("thirdpartySearch.isFollowed") },
        { value: false, label: i18next.t("thirdpartySearch.isNotFollowed") },
      ],
      valueFormatter: (params) =>
        params.value ? i18next.t("thirdpartySearch.isFollowed") : "",
      renderCell: (params) => {
        return (
          <TpSearchCell params={params} withTooltip={false}>
            {params.value && (
              <Chip
                size="small"
                style={TpSearchChipStyle}
                label={i18next.t("thirdpartySearch.isFollowed")}
                color="primary"
              />
            )}
          </TpSearchCell>
        );
      },
    },
    {
      field: "action",
      headerName: i18next.t("actions"),
      flex: 0.07,
      sortable: false,
      disableReorder: true,
      filterable: false,
      disableExport: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableMultipleSelection: true,
      disableSelectionOnClick: true,
      isRowSelectable: false,
      hideFooterSelectedRowCount: true,
      align: "center",
      renderCell: (params) => {
        return (
          <TpSearchCell params={params} withTooltip={false} allowClick={true}>
            <Chip
              size="small"
              style={TpSearchChipStyle}
              label={
                params.row.onboarded
                  ? i18next.t("showModify")
                  : i18next.t("add")
              }
              disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY) || !params.row.canBeAdded || thirdpartyAddLoader}
              color="primary"
              icon={
                thirdpartyIdLoader === params.row.id && (
                  <EASpinner
                    style={{
                      color: "white",
                      position: "relative",
                      marginLeft: 0,
                    }}
                  />
                )
              }
              onClick={() =>
                !thirdpartyAddLoader &&
                hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_CREATE_THIRD_PARTY) &&
                handleResultRowClick(params.row)
              }
            />
          </TpSearchCell>
        );
      },
    },
  ];
};
