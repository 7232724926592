import i18next from "i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { buildAttributesGridColumns, formatAttributeValues } from "../../services/common/AttributeUtils";
import { filterDossiers } from "../../services/common/ChartUtils";
import { Context } from "../../states/Store";
import EADataGrid from "../common/datatable/EADataGrid";
import DossierDeactivationDialog from "./DossierDeactivationDialog";
import {
  getDossiersDataTableColumns,
  dossiersDefaultVisibilityColumns,
} from './DossiersDataTableUtils';
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { localizeTranslate } from "i18n/LocalizeUtils";
import {
  getExportFileFromAccountDossiers,
  reactivateDossier
} from '../../services/edge/DossierService';
import { ERROR, Exports, SUCCESS } from '../../services/common/Constants';
import ROUTES from '../../config/Routes';
import { GridLoadingOverlay } from "@mui/x-data-grid";
import { useColumnsVisibility } from "components/common/hooks/useColumnsVisibility";

export const getDossierTypeLibelle = (dossierTypeLibelle) => {
  let libelle = !dossierTypeLibelle
    ? i18next.t("free")
    : dossierTypeLibelle
  return localizeTranslate(libelle)
}

function DossiersDataTable({
  dossiers,
  chartFilters,
  monitored = true,
  component = "dossiers-list",
  pageSize,
  sheetVariant = false,
  onDeactivation,
  multiDataGridPage = false,
  fromTp = false,
  sortModel,
  onSortModelChange = () => {},
  onVisibleRowsCountChange = () => {},
  paginationMode = "client",
  filters,
}) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const [mappedDossiers, setMappedDossiers] = useState([]);
  const [shownDossiers, setShownDossiers] = useState();
  const [selectedDossierId, setSelectedDossierId] = useState();
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [dossiersColumns, setDossiersColumns] = useState();
  const [dossiersTypesLabels, setDossiersTypesLabels] = useState();
  const [attributesColumns, setAttributesColumns] = useState();
  const { hasAnyOfPrivileges, hasAnyOfPrivilegesOnScopedResourceImpl } = useContext(RoleBasedAccessControlContext);

  const {
    columnsVisibility,
    setColumnsVisibility,
    setInitialColumnsVisibility
  } = useColumnsVisibility(component, component === "dossiers-list");

  useEffect(() => {
    // Map dossiers
    setMappedDossiers(
      dossiers.map((d) => {
        return {
          ...d,
          id: d.dossierId,
          endDate: d.stoppingDate ?? d.expirationDate,
          dossierTypeLibelle: getDossierTypeLibelle(d.dossierTypeLibelle),
          ...d.attributeValues?.reduce(
            (res, attr) => ({
              ...res,
              [`attribute-${attr.label}`]: formatAttributeValues(attr),
            }),
            {}
          ),
        };
      })
    );
    if (dossiers?.length > 0 && dossiers[0].attributeValues != null && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_VIEW_DOSSIER_ATTRIBUTES)) {
      setAttributesColumns(buildAttributesGridColumns(dossiers.map(x=>x.attributeValues)));
    } else {
      setAttributesColumns([]);
    }
  }, [dossiers, hasAnyOfPrivileges]);

  // Filter Dossiers using charts
  useEffect(() => {
    let filteredDossiers = mappedDossiers;
    if (chartFilters) {
      filteredDossiers = filterDossiers(mappedDossiers, chartFilters);
    }
    setShownDossiers(filteredDossiers);
  }, [chartFilters, mappedDossiers]);

  useEffect(() => {
    if (shownDossiers) {
      setDossiersTypesLabels(
        [
          ...new Set(shownDossiers.map((x) => x.dossierTypeLibelle)),
        ].sort((a, b) => a.localeCompare(b))
      );
    }
  }, [shownDossiers]);

  const onStopDossier = useCallback((dossierId) => {
    setSelectedDossierId(dossierId);
    setIsDeactivateDialogOpen(true);
  }, []);

  const onReactivateDossier = useCallback(async (dossierId, dossier) => {
    try {
      await reactivateDossier(
        state.account.id,
        dossierId,
        dossier,
        dispatch
      );
      history.push(`/${state.account.id}/${ROUTES.DOSSIER_DETAIL.replace(":dossierId", dossierId)}`)
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: "REACTIVATE_DOSSIER_ERROR" },
      });
    }

  },[state.account.id, dispatch, history]);

  useEffect(() => {
    if (dossiersTypesLabels && attributesColumns) {
      setDossiersColumns(
        getDossiersDataTableColumns(
          component,
          state.account.id,
          monitored,
          dossiersTypesLabels,
          attributesColumns,
          onStopDossier,
          onReactivateDossier,
          history,
          hasAnyOfPrivilegesOnScopedResourceImpl,
          state.dossierTypes.map(item => item.libelle)
        )
      );
    }
  }, [
    component,
    state.account,
    monitored,
    dossiersTypesLabels,
    attributesColumns,
    onStopDossier,
    onReactivateDossier,
    history,
    hasAnyOfPrivilegesOnScopedResourceImpl,
    state.dossierTypes
  ]);

  useEffect(() => {
    if(attributesColumns) {
      setInitialColumnsVisibility(dossiersDefaultVisibilityColumns(attributesColumns, monitored))
    }
  }, [attributesColumns, monitored, component, setInitialColumnsVisibility])

  const handleDeactivationDialogClose = (isDeactivated) => {
    setIsDeactivateDialogOpen(false);
    if (isDeactivated) {
      setShownDossiers(
        shownDossiers.filter((doss) => doss.dossierId !== selectedDossierId)
      );
      onDeactivation && onDeactivation();
    }
  };

  const handleSortModelChange = (newSortModel) => {
    if (newSortModel[0] !== sortModel[0]) {
      onSortModelChange(newSortModel);
      if(paginationMode === "server") {
        dispatch({ type: "PAGINATION", pagination: {
            page: 0,
            rowPerPage: state.pagination.rowPerPage
          }
        });
      }
    }
  }

  const externalExportHandler = async (exportType) => {

    let objetResult = null;
    dispatch({
      type: "ALERT",
      alert: { type: SUCCESS, msg: i18next.t(`tableLabels.toolbar.downloadLoading`) },
    });
    const exportDto = {
      columnsVisibility: {
        ...columnsVisibility,
        statusCode : monitored && columnsVisibility.statusCode
      }
    }
    const onboarded = monitored ? true : null;
    switch (exportType) {
      case Exports.CSV :
      case Exports.EXCEL :
        objetResult = await getExportFileFromAccountDossiers(state.account.id, onboarded, monitored, exportType, filters, sortModel, exportDto,  dispatch)
        break;
      default: console.log("Type d'export dossiers non géré");
    }

    if(objetResult !== null) {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(new Blob([objetResult.arrayBuffer]))
      a.download = objetResult.filename
      a.click()
    }
  }

  return (
    <>
      {dossiersColumns && columnsVisibility && (
        <EADataGrid
          inputRows={shownDossiers}
          columns={dossiersColumns}
          initialVisibilityModel={columnsVisibility}
          updateVisibilityModel={setColumnsVisibility}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          pageSize={pageSize}
          hasCustomPagination={!multiDataGridPage}
          variant={sheetVariant ? "sheet" : "default"}
          onVisibleRowsCountChange={onVisibleRowsCountChange}
          hasFilters={paginationMode === "client"}
          paginationMode={paginationMode}
          {...(paginationMode === "server" ? { 
            externalExportHandler,
            LoadingOverlay: GridLoadingOverlay, // TODO: default loading overlay while fixing the LinearProgress not showing
            rowCount: state.pagination?.count || 0
          } : {})}
        />
      )}
      {isDeactivateDialogOpen && hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ARCHIVE_DOSSIER) && (
        <DossierDeactivationDialog
          dossierId={selectedDossierId}
          isOpen={isDeactivateDialogOpen}
          fromTp={fromTp}
          handleClose={(isDeactivated) =>
            handleDeactivationDialogClose(isDeactivated)
          }
        />
      )}

    </>
  );
}
export default DossiersDataTable;
