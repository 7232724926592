import React, { useEffect, useState, useContext } from "react";
import i18next from "i18next";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import EAIcon from "components/common/EAIcon";
import { 
  getCalculationNatureOptions,
  getCalculationResultTypeOptions
} from "services/common/CalculatorUtils";
import { isCalculationNameAuthorized } from "services/edge/CalculatorService";
import { Context } from "states/Store";
import { ERROR } from "services/common/Constants";
import EAButton from "components/common/EAButton";
import CalculatorStandardInput from "components/calculator/CalculatorStandardInput";
import CalculatorSelectInput from "components/calculator/CalculatorSelectInput";

const InitialStep = ({
  calculation = null,
  isEdition = false,
  onCancel,
  onContinue
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [calculationNatureOptions, ] = useState(getCalculationNatureOptions());
  const [calculationResultTypeOptions, ] = useState(getCalculationResultTypeOptions());
  const [isContinueClicked, setIsContinueClicked] = useState(false);

  const [hasErrors, setHasErrors] = useState(false);

  const formMethods = useFormContext();
  const {
    formState,
    trigger,
    setError,
    clearErrors,
  } = formMethods;

  const isValueAuthorized = async (value) => {
    try {
      const resp = await isCalculationNameAuthorized(state.account.id, value, null);

      return !!resp.content;
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }
  };

  const validateCalculationName = AwesomeDebouncePromise(async (value, oldValue, fieldName) => {
    const CALCULATION_NAME_MAX_LENGTH = 100;

    let isValid = false;
    let typeErrMsg = "";

    if (oldValue && oldValue === value) {
      isValid = true;
    } else {
      if (value.length === 0) {
        typeErrMsg = i18next.t("infos.required");
      } else if (value.length > CALCULATION_NAME_MAX_LENGTH) {
        typeErrMsg = i18next.t(
          "calculator.errors.calculationNameMaxLengthExceeded",
          {
            length: CALCULATION_NAME_MAX_LENGTH,
          }
        );
      } else {
        let isCalculationNameUnique = await isValueAuthorized(value);
        if (!isCalculationNameUnique) {
          typeErrMsg = i18next.t(
            "calculator.errors.nameNotUnique"
          );
        } else {
          isValid = true;
        }
      }
    }

    if (isValid) {
      clearErrors(fieldName);
    } else {
      setError(fieldName, { type: "manual", message: typeErrMsg });
      return typeErrMsg;
    }
  }, 500);

  const validateCalculationDescription = AwesomeDebouncePromise(async (value, fieldName) => {
    const CALCULATION_DESC_MAX_LENGTH = 300;

    let isValid = false;
    let typeErrMsg = "";

    if (value.length > CALCULATION_DESC_MAX_LENGTH) {
      typeErrMsg = i18next.t(
        "calculator.errors.descMaxLengthExceeded",
        {
          length: CALCULATION_DESC_MAX_LENGTH,
        }
      );
    } else {
      isValid = true;
    }

    if (isValid) {
      clearErrors(fieldName);
    } else {
      setError(fieldName, { type: "manual", message: typeErrMsg });
      return typeErrMsg;
    }
  }, 500);

  const handleContinue = () => {
    trigger([`name`, `nature`, `resultType`]);
    setTimeout(() => {
      !hasErrors && setIsContinueClicked(true);
    },100)
  };

  useEffect(() => {
    setHasErrors(
      !!_.get(formState.errors, `name`) || 
    !!_.get(formState.errors, `nature`) || 
    !!_.get(formState.errors, `resultType`) || 
    !!_.get(formState.errors, `description`)
  );
  }, [formState]);

  // We continue to next step if the validation is done and no errors are found.
  useEffect(() => {
    if (isContinueClicked && !hasErrors) {
      onContinue();
    }

    setIsContinueClicked(false);
  }, [isContinueClicked, hasErrors, onContinue]);

  return (
    <>
      {/* Title */}
      <EAIcon icon="calculator/create-pop-up" />
      <h2 className={classes.title}>
        {isEdition ? i18next.t("calculator.dialog.edit.title") : i18next.t("calculator.dialog.create.title")}
      </h2>

      {/* Main content */}
      <div className={classes.mainContentWrapper}>
        {/* Name */}
        <div className={classes.formWrapper} style={{gap: "15px"}}>
          <CalculatorStandardInput
            fieldName={`name`}
            label={`${i18next.t("calculator.dialog.create.label.name")} *`}
            placeholder={`${i18next.t("calculator.dialog.create.placeholder.name")}`}
            isRequired={true}
            validate={async (value) => {
              return await validateCalculationName(value, calculation?.name || null, `name`);
            }}
          />
        </div>

        {/* Nature */}
        <div className={classes.formWrapper} style={{gap: "15px"}}>
          <CalculatorSelectInput
            fieldName={`nature`}
            label={`${i18next.t("calculator.dialog.create.label.nature")} *`}
            placeholder={`${i18next.t("calculator.dialog.create.placeholder.nature")}`}
            options={calculationNatureOptions}
            isRequired
            validate={(value) => {
              return null;
            }}
            variant='outlined'
          />
        </div>

        {/* Type de résultat */}
        <div className={classes.formWrapper} style={{gap: "15px"}}>
          <CalculatorSelectInput
            fieldName={`resultType`}
            label={`${i18next.t("calculator.dialog.create.label.resultType")} *`}
            placeholder={`${i18next.t("calculator.dialog.create.placeholder.resultType")}`}
            options={calculationResultTypeOptions}
            isRequired
            validate={(value) => {
              return null;
            }}
            variant='outlined'
          />
        </div>

        {/* Description */}
        <div className={classes.formWrapper} style={{gap: "15px"}}>
          <CalculatorStandardInput
            fieldName={`description`}
            label={`${i18next.t("calculator.dialog.create.label.description")}`}
            placeholder={`${i18next.t("calculator.dialog.create.placeholder.description")}`}
            validate={(value) => {
              return validateCalculationDescription(value, `description`);
            }}
            // propagated to the inner field
            className={classes.multilineInput}
            multiline
            rows={2}
          />
        </div>
      </div>

      <div className={classes.btnWrapper}>
        <EAButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
          content={i18next.t("calculator.actions.cancel")}
          colorVariant="secondary"
        />

        <EAButton
          onClick={(e) => {
            e.preventDefault();
            handleContinue();
          }}
          disabled={hasErrors}
          content={i18next.t("calculator.actions.continue")}
          colorVariant={"primary"}
        />
      </div>
    </>
  );
}

export default InitialStep;
