import React, { useContext } from "react";
import i18next from "i18next";
import { MenuItem } from "@mui/material";
import useStyles from "style/js-style/containers/users/users-list/UsersDataTableStyle";
import { Context } from "states/Store";
import EAIcon from "components/common/EAIcon";
import { launchThirdpartiesNewDueligence, refreshThirdpartiesIndued } from "services/edge/InduedService";
import { ERROR, SUCCESS } from "services/common/Constants";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";

export const ThirdpartiesSelectionMenu = ({
  thirdpartiesSelected
}) => {
  const { classes } = useStyles();
  const [actionMenuIsOpen, setActionMenuIsOpen] = React.useState(false);
  const [state, dispatch] = useContext(Context);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const handleClick = () => {
    setActionMenuIsOpen(!actionMenuIsOpen);
  };

  const launchSelectedTpsIndued = async () => {
    try {
      await launchThirdpartiesNewDueligence(state.account.id, thirdpartiesSelected, dispatch);
      dispatch({
        type: "ALERT",
        alert: { type: SUCCESS, msg: i18next.t("thirdpartiesTable.launchInduedSuccessMsg")}
      });
    } catch (e) {
      if (!e?.message.messages?.error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }
  };

  const refreshSelectedTpsIndued = async () => {
    try {
      await refreshThirdpartiesIndued(state.account.id, thirdpartiesSelected, dispatch);
      dispatch({
        type: "ALERT",
        alert: { type: SUCCESS, msg: i18next.t("thirdpartiesTable.refreshInduedSuccessMsg")}
      });
    } catch (e) {
      if (!e?.message.messages?.error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }
  };

  return (
    <div
      className={thirdpartiesSelected.length < 1 ? classes.dnone : classes.actionMenuWrapper}
      onMouseEnter={handleClick}
      onMouseLeave={handleClick} >
      <div className={classes.actionMenuTitle}>
        {i18next.t("actions")}
        <EAIcon
          icon={!actionMenuIsOpen ? "arrow/arrow-to-down_small" : "arrow/arrow-to-up_small"}
          className={classes.titleArrow}
        />
      </div>

      <div className={actionMenuIsOpen ? "" : classes.dnone}>
        <div className={classes.actionMenuSeparate} />
        <MenuItem
          className={classes.actionMenuItem}
          disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE)}
          onClick={() => launchSelectedTpsIndued()}>
          {i18next.t("thirdpartiesTable.launchInduedBtn")}
        </MenuItem>
        <MenuItem
          className={classes.actionMenuItem}
          disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_REFRESH_INDUED_SOURCE)}
          onClick={() => refreshSelectedTpsIndued()}>
          {i18next.t("thirdpartiesTable.refreshInduedBtn")}
        </MenuItem>
      </div>
    </div>
  );
};
