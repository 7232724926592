import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  FormLabel,
  MenuItem,
  TextField,
  FormGroup, Select
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import { isEmpty } from "../../../services/common/Utils";
import { ERROR, REGEXP_EMAIL } from "../../../services/common/Constants";
import { createAccountUsers } from "../../../services/edge/UserService";
import EADialog from "../../../components/common/EADialog";
import useStyles from "../../../style/js-style/containers/users/users-list/UserCreationDialogStyle";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import { createTPContacts } from "../../../services/edge/ThirdpartyService";
import EAIcon from "../../../components/common/EAIcon";
import _ from "lodash";
import AttributeMultipleSelectChips from "../../../components/AttributeMultipleSelectChips";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";
import EAFlag from "components/EAFlag";

const UserCreationDialog = ({ isOpen, handleClose, handleOnCreate, tpContact = false, tpId, shownRoles = [] }) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [isInvitationSend, setIsInvitationSend] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const id = isOpen ? "filters-dialog" : undefined;
  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    setError,
    setValue,
  } = useForm({ mode: "onChange", delayError: 500 });
  const [disabledValidate, setDisabledValidate] = useState(true);

  useEffect(() => {
    setDisabledValidate(!_.isEmpty(formState.errors.email))
  }, [formState]);

  useEffect(() => {
    window.Localize.getAvailableLanguages(function(err, languages) {
      if (err) return console.log(err);
      if (tpContact) {
        languages.push({code: 'nl', name: 'Nederlands'});
      }
      setAvailableLanguages(languages);
    });
    //For now, the default selected language in the selector is the current language used on 365
    setValue("lang", window.Localize.getLanguage());
  }, [setValue, tpContact])

  const handleCreation = async (data) => {
    if (tpContact && isEmpty(errors)) {
      const contactToCreate = {
        email: data.email,
        lang: data.lang
      }
      try {
        startLoading();
        await createTPContacts(state.account.id, tpId, contactToCreate, null);
        setIsInvitationSend(true);
        handleOnCreate();
      } catch (error) {
        if (error.message.error[0].reason.includes("Blacklisted")) {
          setError('email', { type: 'custom', message: i18next.t("infos.EMAIL_BLACKLISTED_ERROR") });
        } else if (error.message.error[0].reason.includes("Ce tiers ne souhaite pas")) {
          setError('email', { type: 'custom', message: i18next.t("infos.COMPTE_BLACKLISTED_ERROR") });
        } else {
          dispatch({ type: "ALERT", alert: { type: ERROR, msg: "USER_CREATION_ERROR" } });
        }
      }finally {
        stopLoading();
      }
    } else {
      const userToCreate = {
        roleIds: data.roleIds,
        email: data.email,
        lang: data.lang
      };
      try {
        await createAccountUsers(state.account.id, userToCreate, dispatch);
        setIsInvitationSend(true);
        handleOnCreate();
      } catch (error) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "USER_CREATION_ERROR" },
        });
      }
    }
  };

  const handleDialogClose = () => {
    handleClose();
  };


  return (
    <EADialog
      id={id}
      isOpen={isOpen}
      onCancel={handleDialogClose}
      onValidate={handleSubmit(handleCreation)}
      hasActions={!isInvitationSend}
      maxWidth="popupxs"
      fullWidth
      btnSize="xs"
      isFeedBack={isInvitationSend}
      customBtnStyle={{ paddingBottom: "24px !important" }}
      isValidateDisabled={disabledValidate || isRequestLoading}
    >
      {/* After user created : feedback */}
      {isInvitationSend && (
        <FeedBackPopUp
          onClick={handleDialogClose}
          title={tpContact ? i18next.t("thirdpartySheet.invitUserTitle") : i18next.t("usersTable.invitUserTitle")}
          imgSrc={"/img/icons/add-user.svg"}
          content={tpContact ? i18next.t("usersTable.createdTPInfo") : i18next.t("usersTable.createdInfo")}
          variant={"valid"}
        />
      )}

      <form>
        {/* Before confirm : pop up */}
        {!isInvitationSend && (
          <>
            {/* Header */}
            <div className={classes.headerWrapper}>
              <div className={classes.imgWrapper}>
                <EAIcon icon={"add-user"} className={classes.imgContent} />
              </div>
              <div>
                <Typography variant="body1" className={classes.dialogText}>
                  {i18next.t("usersTable.creationInfo")}
                </Typography>
              </div>
            </div>

            <div className={classes.formInputWrapper}>
              {/* Role label + input */}
              {!tpContact && !_.isEmpty(shownRoles) && (
                <div className={classes.formContainer}>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t("usersTable.roleSelectLabel")} * `}
                  </FormLabel>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="roleIds"
                      defaultValue={[shownRoles[0].value]}
                      rules={{
                        required: i18next.t("infos.required"),
                      }}
                      render={({ field }) => (
                        <Select
                          className={classes.labelInputRole}
                          id="user-role-id"
                          variant="outlined"
                          multiple
                          {...field}
                          error={!!errors.roleIds}
                          renderValue={(selected) => (
                            <AttributeMultipleSelectChips
                              key={selected}
                              selected={selected}
                              setValue={setValue}
                              shownRoles={shownRoles}
                            />
                          )}
                        >
                          {shownRoles.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              className={classes.menuItem}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormGroup>
                  <span className={classes.errorText}>
                    {!!errors.roleIds ? errors.roleIds.message : ""}
                  </span>
                </div>
              )}
              {/* Mail label + input */}
              <div className={classes.formContainer}>
                <FormLabel className={classes.formLabel}>
                  {`${i18next.t("usersTable.emailInputLabel")} * `}
                </FormLabel>
                <FormGroup>
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: i18next.t("infos.required"),
                      pattern: {
                        value: REGEXP_EMAIL,
                        message: i18next.t("usersTable.emailInputError"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        className={classes.labelInputMail}
                        id="email"
                        variant="outlined"
                        error={!!errors.email}
                        fullWidth
                        placeholder={"john.doe@e-attestations.com"}
                        helperText={
                          !!errors.email ? errors.email.message : ""
                        }
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </div>
              {/* Lang label + input */}
              {!_.isEmpty(availableLanguages) && (
                <div className={classes.formContainer}>
                  <FormLabel className={classes.formLabel}>
                    {`${i18next.t("usersTable.languageLabel")} * `}
                  </FormLabel>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="lang"
                      defaultValue={[availableLanguages[0].value]}
                      rules={{
                        required: i18next.t("infos.required"),
                      }}
                      render={({ field }) => (
                        <Select
                          className={classes.labelInputMail}
                          id="user-lang"
                          variant="outlined"
                          {...field}
                          error={!!errors.lang}
                          renderValue={(selected) => (
                            <span style={{display: 'flex', alignItems: 'center'}}>
                              <EAFlag countryCode={selected === 'en' ? 'gb' : selected} />
                              {availableLanguages.filter(lang => lang.code === selected)?.[0]?.name}
                              </span>
                          
                          )}
                        >
                          {availableLanguages.map((option) => (
                            <MenuItem
                              key={option.code}
                              value={option.code}
                              className={classes.menuItem}
                            >
                              <>
                              <EAFlag countryCode={option.code === 'en' ? 'gb' : option.code} />
                              {option.name}
                              </>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormGroup>
                  <span className={classes.errorText}>
                    {!!errors.lang ? errors.lang.message : ""}
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </EADialog>
  );
};

export default UserCreationDialog;
