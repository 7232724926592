import React, { useContext } from "react";
import i18next from "i18next";

import { Context } from "../../states/Store";
import { ERROR } from "../../services/common/Constants";
import EADialog from "../../components/common/EADialog";
import EAIcon from "../../components/common/EAIcon";
import useStyles from "../../style/js-style/containers/administration/rules/RuleDeleteDialogStyle";

const NoteDeleteDialog = ({
  isOpen,
  note,
  onClose,
  onValidateSuccess,
  deleteNote
}) => {
  const { classes } = useStyles();
  const [,dispatch] = useContext(Context);

  const handleDelete = async () => {
    try {
      await deleteNote(note.noteOId);

      onValidateSuccess();
    } catch (error) {
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DELETE_ERROR" } });
    }
  };

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={handleDelete}
      hasActions={true}
      maxWidth="popupxs"
      fullWidth
      variant="redValid"
      btnSize="large"
    >
      <div className={classes.root}>
        <>
          <div className={classes.imgWrapper}>
            <EAIcon icon={"delete_red"} className={classes.imgContent} />
          </div>
          <span className={classes.bolder}>
            {i18next.t("notes.deleteDialog.title")}
          </span>
          <span>
            {i18next.t("notes.deleteDialog.caption")}
          </span>
        </>
      </div>
    </EADialog>
  );
}

export default NoteDeleteDialog;
