import * as React from "react";
import { Badge, LinearProgress, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { dataGridSx } from "../../../style/globalStyle";
import _ from "lodash";
import { Context } from "../../../states/Store";
import useStyles from "../../../style/js-style/components/common/datatable/EADataGridStyle";
import EAIcon from "../EAIcon";
import { useContext } from "react";
import { GridToolbarQuickFilter, gridVisibleRowCountSelector, useGridApiContext, useGridApiRef } from "@mui/x-data-grid";
import { sheetStyle } from "../../../services/common/DataGridUtils";
import EAButton from "../EAButton";
import clsx from "clsx";
import { DataGridPremium,  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import EADataGridExport from "./EADataGridExport";

const EAGridLink = ({ link, children, disableTranslation = false }) => {
  const { classes } = useStyles();

  return link ? (
    <Link to={link} className={clsx(classes.linkCell, (disableTranslation ? 'dt' : ""))}>
      {children}
    </Link>
  ) : (
    <span className={clsx((disableTranslation ? 'dt' : ""))}>{children}</span>
  );
};

const EADataGridCellType = ({type, action, children}) => {
  const { classes } = useStyles();
  return type === "link" ? <EAGridLink link={action}>{children}</EAGridLink> : <div className={classes.linkCell} onClick={action}>{children}</div>
}
const isGridCellTypeEqual = (prevProps, nextProps) => {
  if(prevProps.type !== nextProps.type) return false;
  if(prevProps.action?.toString() !== nextProps.action?.toString()) return false;

  if (typeof prevProps.children === 'string') {
    return prevProps.children === nextProps.children
  };

  if (React.isValidElement(prevProps.children) ) {
    return isGridCellTypeEqual(prevProps.children.props, nextProps.children.props);
  }
  return false;
}
const MemoizedEADataGridCellType = React.memo(EADataGridCellType, isGridCellTypeEqual);

export const EADataGridCell = ({
  link,
  type = "link",
  tooltip = true,
  tooltipLabel,
  children,
  disableTranslation,
}) => {
  return (
    <MemoizedEADataGridCellType type={type} action={link}>
      {tooltip ? (
        <Tooltip title={tooltipLabel || children || ""}>
          <Typography
            className={disableTranslation ? 'dt' : ""}
            style={{ fontSize: "0.875rem" }}
            noWrap
            component={"span"}
          >
            {children}
          </Typography>
        </Tooltip>
      ) : (
        children
      )}
    </MemoizedEADataGridCellType>
  );
};

export const cellWithTooltip = (value, disableTranslation, array = false) => {
  // If it is an array, formats the text
  value = array
    ? value.map((v, index) => (
      <React.Fragment key={index}>
        {v}
        {index !== value.length - 1 && ', '}
      </React.Fragment>
    ))
    : value;

  return (
    <Tooltip title={value || ""}>
      <Typography className={disableTranslation ? 'dt' : ""} style={{ fontSize: "0.875rem" }} noWrap component={"span"}>
        {value}
      </Typography>
    </Tooltip>
  );
};

export const dateValueFormatter = (params, link) => {
  return (
    <EAGridLink link={link} disableTranslation={true}>
      {params.value
        ? moment(params.value).format(i18next.t("date.dateFormat"))
        : ""}
    </EAGridLink>
  );
};

function CustomToolbar({
  setColumnsButtonEl,
  setFilterButtonEl,
  setFilterActive,
  setAnswerFilterActive,
  isAnswerFilterActive,
  additionnalsComponents,
  additionnalsLeftComponents,
  answerBadgeCount,
  showAnswerFilter,
  hasFilters, 
  externalExportHandler,
  quickFilter
}) {
  const { classes } = useStyles();
  const apiRef = useGridApiContext();

  React.useEffect(() => {
    return apiRef.current.subscribeEvent(
      "columnHeaderDragStart",
      (params, event) => {
        event.dataTransfer.effectAllowed = "move";
      }
    );
  }, [apiRef]);

  const exportStartIcon = () => {
    return (
      <EAIcon icon={"export"} alt={"export icon"} />
    )
  }

  return (
    <GridToolbarContainer className={classes.toolBarContainer}>
      <div className={classes.additionalLeft}>
        {additionnalsLeftComponents}
      </div>
      <div className={classes.filterContainer}>
      {quickFilter ? (
          <GridToolbarQuickFilter />
        ) : (
          <>
        {hasFilters && (
          <GridToolbarFilterButton
            className={classes.toolBarFilterButton}
            ref={setFilterButtonEl}
            componentsProps={{
              button: {
                onClick: () => {
                  setFilterActive(true);
                }
              }
            }}
          />
        )}
        {/* TODO: Pagination and Filters ServerSide */}
        {showAnswerFilter && <div>
          <EAButton className={clsx(classes.toolBarFilterButton, classes.toolBarFilterAnswerButton, isAnswerFilterActive ? classes.toolBarFilterAnswerButtonActive : classes.toolBarFilterAnswerButtonInactive)}
            size={"small"}
            disabled={answerBadgeCount === 0}
            content={
              <div className={classes.toolBarFilterAnswerButtonContent}>
                <EAIcon icon={isAnswerFilterActive ? "filter_white" : "filter_primary"} />
                {i18next.t("answer")}
              </div>}
            onClick={() => {
              setAnswerFilterActive(!isAnswerFilterActive);
            }}>
          </EAButton>
          <Badge badgeContent={answerBadgeCount} className={classes.countBadge} color="primary" overlap="rectangular" />
        </div>}

        <GridToolbarColumnsButton
          className={classes.toolBarColumnsButton}
          ref={setColumnsButtonEl}
          onClick={() => {
            setFilterActive(false);
          }}
        />
        <GridToolbarDensitySelector className={classes.toolBarDensitySelector} />
        {/*TODO: protect using mass actions privileges*/}
        { externalExportHandler ? <EADataGridExport exportHandler={externalExportHandler}/> 
        :
          <GridToolbarExport
            excelOptions={{
              exceljsPreProcess,
              exceljsPostProcess
            }}
            csvOptions={{ utf8WithBom: true, delimiter: ";" }}
            className={classes.toolBarExport}
            startIcon={exportStartIcon()}
          />
        }
        </>
        )}
        {additionnalsComponents}
      </div>
    </GridToolbarContainer>
  );
}

function defaultLabelDisplayedRows({ from, to, count }) {
  return (
    <span style={{ color: "#656575" }}>
      <b>{from}</b>&nbsp;
      <span>à</span>&nbsp;
      <span>{to}</span>&nbsp;
      <span>sur</span>&nbsp;
      {count !== -1 ? <>
        <b>{count}</b>&nbsp;
      </> : <>
        <b>plus de</b>&nbsp;
        <b>{to}</b>
      </>}
    </span>
  )
}

function exceljsPreProcess({ worksheet }) {

  // Change all dates format
  worksheet.columns?.forEach(col => {
    if(col.style?.numFmt === 'dd.mm.yyyy') {
      col.style.numFmt = 'dd/mm/yyyy'
    }
  });
}

function exceljsPostProcess({ workbook, worksheet }) {
  // Change Options worksheet to remove localize html to display the right labels.
  const optionsWorksheet = _.find(workbook.worksheets, {name: 'Options'});
  optionsWorksheet?.columns?.forEach(column => {
    column.values = column.values?.map(optionValue => {
      if(typeof optionValue === 'object' && optionValue.props?.className === 'dt') {
        return optionValue.props?.children;
      }
      return optionValue;
    })
  })

  // Add NotSpecified text for number cells that are empty.
  const rowCount = worksheet.rowCount;
  const attrColsWithEmptyCells = worksheet.columns?.filter(col => col.key?.startsWith('attribute-') && col.values?.length < rowCount);
  attrColsWithEmptyCells?.forEach(col => {
    const newValues = new Array(rowCount).fill(i18next.t("notSpecified"));
    col.values?.forEach((val, index) => {
      newValues[index-1] = val;
    })
    col.values = newValues;
  })
}

export default function EADataGrid({
  loading,
  inputRows,
  columns,
  additionnalsComponents,
  additionnalsLeftComponents,
  onRowClickMethod,
  onCellClickMethod,
  selection,
  clickable,
  isRowSelectableMethod,
  isAnswerFilterActive,
  setAnswerFilterActive,
  hideHeader,
  sortModel,
  onSortModelChange,
  pageSize = 50,
  answerBadgeCount = 0,
  showAnswerFilter = false,
  initialVisibilityModel,
  hasFilters = true,
  updateVisibilityModel,
  initialFilterModel,
  hasCustomPagination = false,
  variant = "default",
  customSx,
  externalExportHandler, 
  onVisibleRowsCountChange = () => { },
  paginationMode="client",
  LoadingOverlay = LinearProgress,
  quickFilter = false,
  sortingOrder=['desc', 'asc'],
  ...other
}) {
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(initialVisibilityModel);
  const [state, dispatch] = useContext(Context);
  const [pSize, setPSize] = React.useState(pageSize); // used for multi-datagrid
  const [isFilterActive, setFilterActive] = React.useState(false);
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);
  const [visibleRowsCount, setVisibleRowsCount] = React.useState(inputRows?.length);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    if (hasCustomPagination && paginationMode === "client") {
      const pagination = {
        page: 0,
        count: visibleRowsCount,
      }
      dispatch({ type: "PAGINATION", pagination: pagination });
    }
  }, [visibleRowsCount, paginationMode, hasCustomPagination, dispatch]);

  React.useEffect(() => {
    if(paginationMode === "client") {
      apiRef.current.subscribeEvent("stateChange", () => {
        const count = gridVisibleRowCountSelector(apiRef.current.state);
        setVisibleRowsCount(count);
        onVisibleRowsCountChange(count)
      });
    }
  }, [apiRef, paginationMode, onVisibleRowsCountChange]);

  // Update rowPerPage in pagination state or self managment
  const handleChangeMaxPage = (pageSize) => {
    if (hasCustomPagination) {
      dispatch({ type: "PAGINATION", pagination: {...state.pagination, rowPerPage: pageSize} });
    } else {
      setPSize(pageSize) // in case of self managment of data
    }
  }

  // Update page in pagination state
  const handleChangePage = (number) => {
    dispatch({ type: "PAGINATION", pagination: {...state.pagination, page: number} });
  }

  React.useEffect(() => {
    setColumnVisibilityModel((prevState) => {
      if (_.isEqual(prevState, initialVisibilityModel)) {
        return prevState;
      }
      return initialVisibilityModel;
    });
  }, [initialVisibilityModel]);

  return (
      <div style={{ height: "100", width: "100%", overflow: "hidden" }}>
        <DataGridPremium
          apiRef={apiRef}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Lignes par page",
              labelDisplayedRows: defaultLabelDisplayedRows,
            },
            panel: {
              anchorEl: isFilterActive ? filterButtonEl : columnsButtonEl,
              placement: "bottom-end"
            },
            toolbar: {
              setColumnsButtonEl,
              setFilterButtonEl,
              setFilterActive,
              setAnswerFilterActive,
              isAnswerFilterActive,
              additionnalsComponents,
              additionnalsLeftComponents,
              answerBadgeCount,
              showAnswerFilter,
              hasFilters,
              externalExportHandler,
              quickFilter
            },
          }}
          sx={
            variant === "sheet"
              ? { ...dataGridSx, ...customSx, ...sheetStyle }
              : { ...dataGridSx, ...customSx }
          }
          className={"" + (clickable ? "clickable" : "")}
          loading={loading}
          density="compact"
          disableSelectionOnClick
          autoHeight
          pageSize={
            hasCustomPagination
              ? state.pagination.rowPerPage
              : pSize
          }
          rowsPerPageOptions={[10, 50, 100]}
          onPageSizeChange={(pageSize) => handleChangeMaxPage(pageSize)}
          onPageChange={(number) => handleChangePage(number)}
          pagination
          rows={inputRows}
          columns={columns}
          onCellClick={onCellClickMethod}
          onRowClick={onRowClickMethod}
          sortingMode={paginationMode}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          sortingOrder={sortingOrder}
          {...(columnVisibilityModel ? { columnVisibilityModel } : {})}
          onColumnVisibilityModelChange={(newModel, detail) => {
            setColumnVisibilityModel(newModel);
            if(updateVisibilityModel) {
              updateVisibilityModel(newModel);
            }
          }}
          {...(isRowSelectableMethod
            ? {
              isRowSelectable: isRowSelectableMethod,
            }
            : {})}
          {...(selection
            ? {
              checkboxSelection: !selection.hideCheckbox,
              selectionModel: selection.items,
              onSelectionModelChange: (newSelectionModel) => {
                selection.setItems(newSelectionModel);
              },
              keepNonExistentRowsSelected: paginationMode === "server"
            }
            : {})}
          {...(!hideHeader
            ? {
              components: {
                Toolbar: CustomToolbar,
                LoadingOverlay: LoadingOverlay,
              },
            }
            : {})}
          {...(initialFilterModel && hasFilters
            ? {
              initialState: {
                filter: {
                  filterModel: initialFilterModel,
                },
              },
            }
            : {})}
          {...(hasCustomPagination
            ? {
              page: state.pagination.page,
              components: {
                Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress,
                Pagination: "",
              },
            }
            : {
              // components: {
              //   Toolbar: CustomToolbar,
              //   LoadingOverlay: LinearProgress,
              //   Pagination: EAPagination
              // }
            })}
          paginationMode={paginationMode}
          {...other}
        />
      </div>
  );
}
