import { TextField } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getAttributeMaxLengthRule, validateAttributeInputForType } from "../../services/common/AttributeUtils";
import useStyles from "../../style/js-style/components/attributes/AttributeTextFieldInputStyle";
import { ATTRIBUTE_MAX_TEXT_VALUE_LENGTH } from "services/common/Constants";

// Input For Types : String, Integer, Decimal and Percentage.
const AttributeTextFieldInput = ({ type }) => {
  const formMethods = useFormContext();
  const { classes } = useStyles();
  return (
    <Controller
      control={formMethods.control}
      name="value"
      rules={{
        maxLength: getAttributeMaxLengthRule(
          "theValue",
          ATTRIBUTE_MAX_TEXT_VALUE_LENGTH
        ),
        validate: (value) => validateAttributeInputForType(type, value),
      }}
      render={({ field }) => (
        <TextField
          {...field}
          id="attribute-value-id"
          variant="outlined"
          fullWidth
          className={classes.input}
          placeholder={i18next.t("notSpecified")}
          error={!!formMethods.formState.errors.value}
          helperText={
            !!formMethods.formState.errors.value
              ? formMethods.formState.errors.value.message
              : ""
          }
        />
      )}
    />
  );
};
export default AttributeTextFieldInput;
