import React, { useCallback, useContext, useEffect, useState } from "react";
import i18next from "i18next";
import _ from "lodash";
import {
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import RuleActivationErrors from "../../../../components/rules/RuleActivationErrors";
import EAButton from "../../../../components/common/EAButton";
import EARuleAccordion from "../../../../components/rules/EARuleAccordion";
import useStyles from "../../../../style/js-style/containers/administration/rules/RuleFormsStyle";
import { find } from "lodash";
import EAIcon from "../../../../components/common/EAIcon";
import {
  RuleActionParameterNames,
  RuleActionType,
  RulesContext,
  getAvailableOperatorOptions,
  validateRule
} from "services/common/RulesUtils";
import clsx from "clsx";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import moment from "moment";


const RuleSummary = ({
  open = false,
  disabled = false,
  criterionFieldOptions,
  isEditionMode = false,
  isActiveRule = false,
  onCancel = () => { },
  onContinue = () => { },
  onDelete = () => { },
  onDeactivate = () => { },
}) => {

  const [actionsListEmpty, setActionsListEmpty] = useState(false);
  const [noDossierSelected, setNoDossierSelected] = useState(false);
  const [hasEmptyRefs, setHasEmptyRefs] = useState(false);
  const [multipleSameActionNotAllowed, setMultipleSameActionNotAllowed] = useState(false);
  const [canRegisterAndEnable, setCanRegisterAndEnable] = useState(true);

  const { classes } = useStyles();

  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);
  const formMethods = useFormContext();
  const { control, setValue, handleSubmit } = formMethods;

  let criteria = useWatch({ control, name: `criteria` });
  let actions = useWatch({ control, name: `actions` });
  let name = useWatch({ control, name: "name" });

  const handleContinue = async () => {
    onContinue();
  };

  const handleSave = () => {
    handleSubmit(onContinue)();
  };

  const handleSummaryEnable = (shouldEnable) => {
    const activationErrors = validateRule(actions);

    if (!activationErrors) {
      shouldEnable && setValue("enabled", true);
      handleSubmit(onContinue)();
    }

    if (activationErrors === 'no-actions') {
      setActionsListEmpty(true);
      setCanRegisterAndEnable(false);
    }
    if(activationErrors === "multiple-actions-not-allowed") {
      setCanRegisterAndEnable(false);
      setMultipleSameActionNotAllowed(true);
    }
  };

  return (
    <EARuleAccordion
      buttons={[]}
      title={i18next.t("rules.summary.title")}
      icon="assignment_green"
      opened={open}
      disabled={disabled}
    >
      <div>
        <div className={classes.recapWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                <div className={classes.summaryTitle}>
                  <EAIcon
                    icon={"settings_applications_green"}
                    className={classes.settingicon}
                  />
                  <span className={classes.sectionTitle}>
                    {i18next.t("rules.summary.ruleNameLabel") + `${name ? " : " + name : ""}`}
                  </span>
                </div>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.summaryListContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {!_.isEmpty(criteria) &&
                      <CriteriaSummary
                        criteria={criteria}
                        criterionFieldOptions={criterionFieldOptions}
                      />
                    }
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {!_.isEmpty(actions) &&
                      <ActionsSummary
                        actions={actions}
                        criterionFieldOptions={criterionFieldOptions}
                      />
                    }
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        {isEditionMode && (
          <RuleActivationErrors
            actionsListEmpty={actionsListEmpty}
            noDossierSelected={noDossierSelected}
            hasEmptyRefs={hasEmptyRefs}
            onActionsListEmptyClose={setActionsListEmpty}
            onNoDossierSelectedClose={setNoDossierSelected}
            onEmptyRefsClose={setHasEmptyRefs}
            multipleSameActionNotAllowed={multipleSameActionNotAllowed}
            onMultipleSameActionNotAllowedClose={setMultipleSameActionNotAllowed}
          />
        )}

        <div
          className={clsx(
            classes.recapButtonWrapper,
            isEditionMode && classes.recapButtonWrapperEdit
          )}
        >
          {isEditionMode ? (
            <>
              <div className={classes.summaryEditLeftBtns}>
                {/* Delete Rule */}
                <EAButton
                  borderless={true}
                  colorVariant="error"
                  onClick={() => {
                    if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_RULE)) {
                      onDelete();
                    }
                  }}
                  disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_RULE)}
                  content={
                    <div
                      className={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_RULE) ? classes.disabledDeleteBtn : ""}
                    >
                      <EAIcon
                        icon={"delete_red"}
                        className={classes.deleteRuleImg}
                      />
                      {i18next.t("rules.summary.actions.delete")}
                    </div>
                  }
                  customStyle={{ fontSize: "14px" }}
                />
              </div>
              <div className={classes.summaryEditRightBtns}>
                {/* Cancel */}
                <EAButton
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel();
                    setCanRegisterAndEnable(true);
                    setActionsListEmpty(false);
                    setNoDossierSelected(false);
                    setHasEmptyRefs(false);
                    setMultipleSameActionNotAllowed(false)
                  }}
                  content={i18next.t("rules.summary.actions.cancel")}
                  colorVariant="secondary"
                  customStyle={{
                    fontSize: "14px",
                    minWidth: "80px",
                    width: "80px",
                    minHeight: "32px",
                    height: "32px",
                    padding: 0, borderRadius: "12px",
                  }}
                />
                {/* Save Rule */}
                <EAButton
                  onClick={(e) => {
                    e.preventDefault();
                    isActiveRule ? handleSummaryEnable(false) : handleSave();
                  }}
                  content={i18next.t("rules.summary.actions.save")}
                  colorVariant={"primaryLight"}
                  disabled={isActiveRule && !canRegisterAndEnable}
                  customStyle={{
                    fontSize: "14px",
                    width: "130px",
                    minHeight: "32px",
                    height: "32px",
                    padding: 0, borderRadius: "12px",
                  }}
                />
                {/* Activate / Deactivate Rule */}
                {isActiveRule ? (
                  <EAButton
                    onClick={(e) => {
                      e.preventDefault();
                      if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS)) {
                        onDeactivate();
                      }
                    }}
                    disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS)}
                    content={i18next.t("rules.summary.actions.deactivate")}
                    colorVariant={"success"}
                    customStyle={{
                      fontSize: "14px",
                      width: "120px",
                      minHeight: "32px",
                      height: "32px",
                      padding: 0, borderRadius: "12px",
                    }}
                  />
                ) : (
                  <EAButton
                    onClick={(e) => {
                      e.preventDefault();
                      if (hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS)) {
                        handleSummaryEnable(true);
                      }
                    }}
                    disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_RULE_STATUS) || !canRegisterAndEnable}
                    content={i18next.t("rules.summary.actions.activate")}
                    customStyle={{
                      fontSize: "14px",
                      width: "120px",
                      minHeight: "32px",
                      height: "32px",
                      padding: 0, borderRadius: "12px",
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              {/* Cancel and go back */}
              <EAButton
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
                content={i18next.t("rules.summary.actions.cancel")}
                colorVariant="secondary"
                customStyle={{
                  fontSize: "14px",
                  width: "178px",
                  minHeight: "32px",
                  height: "32px",
                  padding: 0,
                  borderRadius: "12px",
                }}
              />

              {/* Validate and continue to finalize */}
              <EAButton
                onClick={(e) => {
                  e.preventDefault();
                  handleContinue();
                }}
                content={i18next.t("rules.summary.actions.validate")}
                customStyle={{
                  fontSize: "14px",
                  width: "178px",
                  minHeight: "32px",
                  height: "32px",
                  padding: 0,
                  borderRadius: "12px",
                }}
              />
            </>
          )}
        </div>
      </div>
    </EARuleAccordion>
  );
};


const CriteriaSummary = ({ criteria, criterionFieldOptions }) => {
  const { classes } = useStyles();
  const [criteriaToDisplay, setCriteriaToDisplay] = useState([]);

  const translateField = useCallback((fieldName) => {
    let entry = find(criterionFieldOptions, { value: fieldName });

    return entry === undefined ? fieldName : entry.label;
  }, [criterionFieldOptions]);

  const translateOperator = useCallback((operator, field) => {
    let availableCriterionOperatorOptions = getAvailableOperatorOptions(
      field,
      criterionFieldOptions
    );
    let entry = find(availableCriterionOperatorOptions, { value: operator });

    return entry === undefined ? operator : entry.label;
  }, [criterionFieldOptions]);

  const translateValues = useCallback((values, fieldName) => {
    let fieldEntry = find(criterionFieldOptions, { value: fieldName });
    let valueOptions =
      fieldEntry === undefined ? [] : fieldEntry.valueOptions;

    if (valueOptions && valueOptions.length > 0) {
      return values.map((value) => {
        let valueEntry = find(valueOptions, { value: value });
        return valueEntry === undefined ? value : valueEntry.label;
      });
    } else {
      if (fieldEntry?.type === 'ATTRIBUTE' && fieldEntry?.subType === 'date') {
        return values.map(d => moment(d).format(i18next.t("date.dateFormat")))
      }
      return values;
    }
  }, [criterionFieldOptions]);

  const mapCriteria = useCallback(() => {
    let filteredCrit = criteria.filter((c) => c.field !== "");

    return filteredCrit.map((c) => {
      return {
        criteriaField: translateField(c.field),
        criteriaOperator: translateOperator(c.operator, c.field),
        criteriaValues: translateValues(c.values, c.field).join("; "),
      };
    });
  }, [criteria, translateField, translateOperator, translateValues]);

  useEffect(() => {
    setCriteriaToDisplay(mapCriteria());
  }, [mapCriteria]);

  return (
    <Paper
      display="flex"
      alignItems="center"
      elevation={0}
      sx={{ padding: 2, position: "relative", marginTop: "16px", backgroundColor: "#FAFBFD" }}
    >
      <div className={classes.summaryBlocLabel}>
        {i18next.t("rules.summary.rulesConditionLabel")}
      </div>
      <Typography variant="p">
        <div className={classes.summaryListTitle}>
          {i18next.t("rules.summary.ruleCriteriaLabel")}
          {/*TODO à revoir une fois le choix du périmètre en place */}
          {/*<div className={classes.summaryListCategory}>*/}
          {/*  Périmètre au <EAIcon icon="thirdparty_green"/> <b>tiers</b>*/}
          {/*</div>*/}
        </div>
      </Typography>

      <Typography component="div" className={classes.blocValues}>
        {criteriaToDisplay?.map((val, index) => (
          <div
            key={`${val.criteriaField}-${index}`}
            className={classes.summaryListItem}
          >
            <span className={classes.summaryBullet}>
              {"\u2022"}
            </span>
            <span>{val.criteriaField}</span>{" "}
            <EAIcon icon="arrow/arrow-to-right_large" />{" "}
            <span>{val.criteriaValues}</span>{" "}
            <span className={classes.summaryListOperator}>
              {`(${val.criteriaOperator})`}
            </span>
          </div>
        ))}
      </Typography>
    </Paper>
  );
}

const ActionsSummary = ({ actions }) => {
  return (
    <Paper
      display="flex"
      alignItems="center"
      elevation={0}
      sx={{ padding: 2, position: "relative", marginTop: "16px", backgroundColor: "#FAFBFD" }}
    >
        <DossierCreationActionsSummary actions={actions?.filter(entry => entry.type === RuleActionType.DOSSIER_CREATION)} />
        <FinancialStrengthActionsSummary actions={actions?.filter(entry => entry.type === RuleActionType.FINANCIAL_STRENGTH_ACTIVATION)}
            actionTypeLabel={i18next.t("rules.summary.ruleActiverFinancialStrengthActionsSummaryTitle")}/>
        <FinancialStrengthActionsSummary actions={actions?.filter(entry => entry.type === RuleActionType.FINANCIAL_STRENGTH_DEACTIVATION)}
          actionTypeLabel={i18next.t("rules.summary.ruleDesactiverFinancialStrengthActionsSummaryTitle")}/>
    </Paper>
  );
}


const FinancialStrengthActionsSummary = ({ actions, actionTypeLabel }) => {
  const { classes } = useStyles();

  if(!actions || actions.length === 0) {
    return <></>
  }
  return (
    <>
      <div className={classes.summaryBlocLabel}>
        {i18next.t("rules.summary.rulesActionLabel")}
      </div>
      <Typography variant="p">
        <div className={classes.summaryListTitle}>
          {actionTypeLabel}
        </div>
      </Typography>
      </>
  );
}

const DossierCreationActionsSummary = ({ actions }) => {
  const { classes } = useStyles();

  const {
    dossierTypes,
  } = useContext(RulesContext);

  const translateDossierType = useCallback((code) => {
    let entry = find(dossierTypes, { value: code });
    return entry === undefined ? code : entry.label;
  }, [dossierTypes]);

  if(!actions || actions.length === 0) {
    return <></>
  }

  return (
    <>
      <div className={classes.summaryBlocLabel}>
        {i18next.t("rules.summary.rulesActionLabel")}
      </div>
      <Typography variant="p">
        <div className={classes.summaryListTitle}>
        {i18next.t("rules.summary.ruleCreationDossierActionsSummaryTitle")}
        </div>
      </Typography>

      {/* Only for dossier creation : */}
      <Typography component="div" className={classes.blocValues}>
        {actions?.map((action, index) => (
          <div
            key={`${action.id}-${index}`}
            className={classes.summaryListItem}
          >
            <span className={classes.summaryBullet}>{"\u2022"}</span>
            <span>{`${translateDossierType(action.parameters[RuleActionParameterNames.DOSSIER_TYPE_CODE])} `}</span>
            <span className={classes.summaryRefItem}>
              {i18next.t("rules.summary.dossierRef")}
              <span className={classes.summaryRefItemValue}>
                {action.parameters[RuleActionParameterNames.DOSSIER_REFERENCE]}
              </span>
            </span>
          </div>
        ))}
      </Typography></>
  );
}

export default RuleSummary;
