import React, { useContext, useState } from "react";
import i18next from "i18next";
import EADialog from "../../../components/common/EADialog";
import { Context } from "../../../states/Store";
import { ERROR, WARNING } from "../../../services/common/Constants";
import { updateAttribute } from "../../../services/edge/AttributesService";
import AttributeForm from "./AttributeForm";
import useStyles from "../../../style/js-style/containers/administration/attributes/AttributeEditDialogStyle";
import EditAttributeRecap from "../../../components/attributes/EditAttributeRecap";
import EAIcon from "../../../components/common/EAIcon";
import { hasAttributeListItems } from "../../../services/common/AttributeUtils";
import EAButton from "../../../components/common/EAButton";
import EAAlert from "../../../components/EAAlert";
import { Trans } from "react-i18next";
import { formatRuleNameList } from "../../../services/common/RulesUtils";
import { PrivilegeEnum, RoleBasedAccessControlContext } from "services/common/RolesUtils";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

const AttributeEditDialog = ({
  isOpen,
  attribute,
  onClose,
  bondType,
  onValidateSuccess,
  onDeleteOpen = false,
}) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [isValidateDisabled, setIsValidateDisabled] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [newFormData, setNewFormData] = useState();
  const [deletedValues, setDeletedValues] = useState([]);
  const [updateLabel, setUpdateLabel] = useState([]);
  const [newValues, setNewValues] = useState([]);
  const [updatedValues, setUpdatedValues] = useState([]);
  const { hasAnyOfPrivileges } = useContext(RoleBasedAccessControlContext);

  const FORM_ID = "attribute-update-form-id";

  const handleUpdateAttribute = async (attributeToSave) => {
    try {
      startLoading();
      await updateAttribute(
        state.account.id,
        attribute.id,
        attributeToSave,
        null
      );
      onValidateSuccess();
      onClose();
    } catch (error) {
      const err =
        error?.message?.messages?.error && error?.message?.messages?.error[0];
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: err?.code || "INTERNAL_ERROR" },
      });
    }finally {
      stopLoading();
    }
  };

  const handleFormValidation = (data) => {
    setNewFormData(data);
    setIsFormValidated(true);

    if (hasAttributeListItems(data.type)) {
      // deleted values
      setDeletedValues(
        attribute.values.filter(
          (attr) => !data.values.some((d) => d.id === attr.id)
        )
      );
      // new values
      setNewValues(data.values.filter((d) => d.id === ""));
      const updatedIds = attribute.values
        .filter((attr) =>
          data.values.some((d) => d.id === attr.id && d.value !== attr.value)
        )
        .map((attr) => attr.id);
      // updated values
      const updated = [];
      updatedIds.forEach((id) =>
        updated.push({
          id,
          oldValue: attribute.values.find((x) => x.id === id).value,
          newValue: data.values.find((x) => x.id === id).value,
        })
      );
      setUpdatedValues(updated);
    }

    // updated label
    setUpdateLabel([{
      id: "label",
      oldValue: attribute.label,
      newValue: data.label,
    }])
  };

  const cancelDialogHandler = () => {
    if (isFormValidated) {
      setIsFormValidated(false);
    } else {
      onClose();
    }
  };

  /*   const formatRuleList = () => {
      const resultList = [...new Set(attribute.referencingRules)]
        .map(val => ({value: val}));
      return resultList;
    } */

  return (
    <EADialog
      isOpen={isOpen}
      title={i18next.t("attributes.editTitle", {
        type: i18next.t("attributes." + bondType),
      })}
      subTitle={isFormValidated && ` - ${attribute.label}`}
      onCancel={cancelDialogHandler}
      onClose={onClose}
      isValidateDisabled={isValidateDisabled || isRequestLoading}
      formId={FORM_ID}
      maxWidth="popupsm"
      variant="rightControl"
      fullWidth
      onValidate={(e) => {
        e.stopPropagation();
        isFormValidated && handleUpdateAttribute(newFormData);
      }}
      validateBtnLabel={isFormValidated ? i18next.t("validate") : i18next.t("valid")}
    >
      {/* Before form validation and update : pop up*/}
      {!isFormValidated && (
        <>
          {(attribute?.referencingRules && attribute?.referencingRules.length > 0) && (
            <EAAlert severity={WARNING} >
              <div className={classes.eaAlertContent}>
                {attribute?.referencingRules.length === 1 ? (<div>
                  {i18next.t("attributes.warnings.usedInRule")} <b>{attribute.referencingRules[0]}</b>
                </div>) : (<div>
                  {i18next.t("attributes.warnings.usedInRules")} <b>{formatRuleNameList(attribute.referencingRules)}</b>
                </div>)}
              </div>
            </EAAlert>
          )}
          {(attribute?.referencingCalculations && attribute?.referencingCalculations.length > 0) && (
            <EAAlert severity={WARNING} >
              <div className={classes.eaAlertContent}>
                {attribute?.referencingCalculations.length === 1 ? (<div>
                  {i18next.t("attributes.warnings.usedInCalcul")} <b>{attribute.referencingCalculations[0]}</b>
                </div>) : (<div>
                  {i18next.t("attributes.warnings.usedInCalculs")} <b>{formatRuleNameList(attribute.referencingCalculations)}</b>
                </div>)}
              </div>
            </EAAlert>
          )}
          <AttributeForm
            formId={FORM_ID}
            formType="edit"
            attribute={attribute}
            bondType={bondType}
            submitHandler={handleFormValidation}
            setIsValidateDisabled={setIsValidateDisabled}
          />

          {/* Delete attribute */}
          <EAButton
            className={classes.deleteAttributeWrapper}
            borderless={true}
            colorVariant="error"
            onClick={() => {
              onClose();
              onDeleteOpen();
            }}
            disabled={
              bondType === "dossier" ?
                !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_DOSSIER_ATTRIBUTE)
                :
                !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_DELETE_THIRD_PARTY_ATTRIBUTE)
            }
            content={
              <div
                className={
                  bondType === "dossier"
                    ? !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_DOSSIER_ATTRIBUTE) ? classes.disabledDeleteBtn : ""
                    : !hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_ALL_THIRD_PARTY_ATTRIBUTE) ? classes.disabledDeleteBtn : ""
                }
              >
                <EAIcon icon={"delete_red"} className={classes.deleteAttributeImg} />
                {i18next.t("attributes.deleteAttribute")}
              </div>
            }
          />
        </>
      )}

      {/* After form validation and before update : Validation Recap */}
      {isFormValidated && (
        <>
          {(attribute?.referencingRules && attribute?.referencingRules.length > 0 && deletedValues.length > 0) && (
            <EAAlert severity={WARNING} >
              <div >
                <div style={{ whiteSpace: "pre-line" }}>
                  <Trans i18nKey="attributes.warnings.valueUsedInRule"> <b>La suppression</b> d'une valeur entraine <b>la désactivation des règles</b>. \n Les dossiers précédemment créés sont conservés.</Trans>
                </div>
              </div>
            </EAAlert>
          )}
          {(attribute?.referencingCalculations && attribute?.referencingCalculations.length > 0 && deletedValues.length > 0) && (
            <EAAlert severity={WARNING} >
              <div >
                <div style={{ whiteSpace: "pre-line" }}>
                  <Trans i18nKey="attributes.warnings.valueUsedInCalcul"> <b>La suppression</b> d'une valeur entraine <b>la désactivation du calcul</b>. \n Les résultats précédemment obtenus ne seront plus restitués sur les tiers.</Trans>
                </div>
              </div>
            </EAAlert>
          )}
          <EditAttributeRecap
            updateLabel={updateLabel}
            deletedValues={deletedValues}
            newValues={newValues}
            updatedValues={updatedValues}
            bondType={bondType}
          // TODO add list of concerned Rules
          //concernedRules={}
          />
        </>
      )}
    </EADialog>
  );
};

export default AttributeEditDialog;
