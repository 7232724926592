import React, { useContext, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import i18next from "i18next";
import { Context } from "../../states/Store";
import { stopDossier } from "../../services/edge/DossierService";
import { ERROR } from "../../services/common/Constants";
import EADialog from "../common/EADialog";
import EAInputBloc from "../common/EAInputBloc";
import useStyles from "../../style/js-style/components/common/dossiers/DossierDeactivationDialogStyle";
import EAIcon from "../common/EAIcon";
import FeedBackPopUp from "../FeedBackPopUp";
import {useRequestLoading} from "../common/hooks/useRequestLoading";

const FORM_ID = "doss-deactivation-form-id";
const commentMaxLength = 100;

const DossierDeactivationDialog = ({ dossierId, isOpen, handleClose, fromTp = false }) => {
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange" });

  const handleDeactivation = async (data) => {
    try {
      startLoading();
      await stopDossier(
        state.account.id,
        dossierId,
        data.description,
        dispatch
      );
      setIsDeactivated(true);
      reset({
        description: "",
      })
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: { type: ERROR, msg: "STOP_DOSSIER_ERROR" },
      });
    }finally {
      stopLoading();
    }
  };

  const handleDialogClose = () => {
    handleClose(isDeactivated);
    setIsDeactivated(false);
  }

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={handleDialogClose}
      formId={FORM_ID}
      hasActions={!isDeactivated}
      maxWidth="sm"
      fullWidth
      isFeedBack={isDeactivated}
      validateBtnLabel={"valid"}
      isValidateDisabled={isRequestLoading}
    >

      {/* Pop-up Archive */}
      {!isDeactivated ? (
        <form id={FORM_ID} onSubmit={handleSubmit(handleDeactivation)}>
          <Grid container spacing={2} alignItems="center">

            {/* Header : icon + title */}
            <Grid item xs={12} className={classes.popupHeader}>
              <EAIcon icon={"inventory"} className={classes.topHeaderIcon} />
              <span className={classes.popUpTitle}>{i18next.t("dossierSheet.popUpGoToArchiveDossier")}</span>
            </Grid>

            {/* Main content : comment */}
            <Grid item xs={12}>
              <EAInputBloc label={`${i18next.t("dossierSheet.comment")}`}>
                <Controller
                  control={control}
                  name="description"
                  rules={{
                    maxLength: {
                      value: commentMaxLength,
                      message: i18next.t("infos.E011_VAR", {
                        input: i18next.t("TheComment"),
                        maxLength: commentMaxLength,
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      id="deactivation-reason-detail"
                      variant="outlined"
                      fullWidth
                      {...field}
                      multiline
                      minRows={2}
                      maxRows={5}
                      error={!!errors.description}
                      helperText={
                        !!errors.description ? errors.description.message : ""
                      }
                    />
                  )}
                />
              </EAInputBloc>
            </Grid>
          </Grid>
        </form>
      )
      : (
        <FeedBackPopUp
          onClick={handleDialogClose}
          title={i18next.t('dossierSheet.dossierDeactivatedTitle')}
          imgSrc={"/img/icons/inventory.svg"}
          content={
            <>
              {i18next.t('dossierSheet.dossierDeactivated')}&nbsp;
              { fromTp &&
                <b className={classes.bold}>{i18next.t('dossierSheet.dossierDeactivatedFromTPInfo')}</b>
              } <br/>
              {i18next.t('dossierSheet.dossierDeactivatedTip')}
            </>
          }
        />
      )}
    </EADialog>
  );
};

export default DossierDeactivationDialog;
