import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './DragAndDropComponentStyle';
import EAIcon from 'components/common/EAIcon';
import EAButton from 'components/common/EAButton';
import { Context } from 'states/Store';
import { postApiMultipart } from 'services/edge/DossierModelService';
import { ERROR, SUCCESS } from 'services/common/Constants';
import i18next from 'i18next';
import { isEmpty } from "lodash";

function DragAndDropComponent({ fetchDossierModelList }) {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [files, setFiles] = useState();
  const [displayErrorStyle, setDisplayErrorStyle] = useState(false);

  const {
    getRootProps,
    getInputProps,
    fileRejections
  } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    }
  });

  useEffect(() => {
    if (state.account && files) {

      if (!isEmpty(fileRejections)) {
        setDisplayErrorStyle(true);
        setFiles();
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: i18next.t("demandes.dossierModel.createModelMulti.wrongFileFormat") },
        });
        return;
      }

      (async function fetchData() {
        try {

          const resp = await postApiMultipart(
            state.account.id,
            files[0]
          );
          if (!resp.ok) {
            let errors = resp?.headers?.get("errors");
            if (errors) {
              setDisplayErrorStyle(true);
              setFiles();
              let listErrors = errors.split(";");
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: listErrors } });
            } else {
              console.error(resp?.messages);
              setDisplayErrorStyle(true);
              setFiles();
              dispatch({
                type: "ALERT",
                alert: { type: ERROR, msg: "INTERNAL_ERROR" },
              });
            }
          } else {
            // SUCCESS
            setDisplayErrorStyle(false);
            fetchDossierModelList();
            setFiles();
            dispatch({
              type: "ALERT",
              alert: { type: SUCCESS, msg: i18next.t("demandes.dossierModel.createModelMulti.creationSuccess") },
            });
          }
        } catch (e) {
          console.error(e);
          dispatch({
            type: "ALERT",
            alert: { type: ERROR, msg: "INTERNAL_ERROR" },
          });
        }
      })();
    }
  }, [dispatch, state.account, files, fileRejections, fetchDossierModelList]);

  return (
    <div
      className={classes.dropZone}
      style={{
        border : displayErrorStyle ? "dashed 2px rgba(255, 153, 154, 1)" : "",
        background: displayErrorStyle ? "#fff5f5" : ""
      }}
    >
      <EAIcon icon="dragDrop_bg" className={classes.dropBackGround}/>
      <div  {...getRootProps()} className={classes.innerDropZone}>
        <input {...getInputProps()} />
        <EAIcon icon={displayErrorStyle ? "demandes/upload_cloud_red" : "demandes/upload_cloud_gray"} className={classes.iconImg} />
        <div className={classes.dropZoneNotice}>
          <div>
            <span className={displayErrorStyle ? classes.boldErrorText : classes.boldGreenText}> {i18next.t("demandes.dossierModel.createModelMulti.dragAndDrop")} </span>
            <span className={classes.boldText}>{i18next.t("demandes.dossierModel.createModelMulti.yourFile")}</span>
          </div>
          <div className={classes.subText}>{i18next.t("demandes.dossierModel.createModelMulti.dragAndDropFileType")}</div>
        </div>
        <EAButton
          colorVariant={displayErrorStyle ? "error" : "primary"}
          content={i18next.t("demandes.dossierModel.createModelMulti.selectFile")}
        />
      </div>
    </div>
  );
}

export default DragAndDropComponent;
