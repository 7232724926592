import React, { useCallback, useContext, useEffect } from "react";
import { Context } from "../../../states/Store";
import { getAccountMajorActivitiesCount } from "../../../services/edge/AccountService";
import { formatNumber } from "../../../services/common/Utils";
import { majorActivitiesColors, mapDepartmentsFilters } from "../../../services/common/ChartUtils";
import EAActivitiesChart from "../../../components/common/charts/EAActivitiesChart";
import * as am5 from "@amcharts/amcharts5";
import i18next from "i18next";
import ROUTES from "../../../config/Routes";
import { ThirdPartyBlocSkeleton } from "components/common/skeleton/SkeletonDashboard";
import { EANoData } from "components/common/EANoData";
import { localizeTranslate } from "i18n/LocalizeUtils";
import { ERROR } from "services/common/Constants";
import { useRef } from "react";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { isAbortError } from "services/common/ApiUtils";

function TpActivitiesChart() {
  const [state, dispatch] = useContext(Context);
  const [activitiesStates, setActivitiesStates] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasTPs, setHasTPs] = React.useState(false);
  const accountId = useSelectedAccountId();

  const abortCtrlRef = useRef();

  const fetchActivities = useCallback(
    (query) => {

      if(abortCtrlRef.current) {
        abortCtrlRef.current.abort();
      }
  
      abortCtrlRef.current = new AbortController();
      const signal = abortCtrlRef.current.signal;

      if (accountId && state.account) {
        (async () => {
          try {
            setIsLoading(true);
            const majorActivities = await getAccountMajorActivitiesCount(
              accountId,
              query,
              null,
              signal
            );

            const total = majorActivities.content
              ?.map((d) => d.count)
              .reduce((partialSum, a) => partialSum + a, 0);

              setHasTPs(total > 0)

            setActivitiesStates({
              name: "",
              children: majorActivities.content?.map((x) => ({
                code: x.majorCode,
                name: localizeTranslate(x.majorFr),
                value: x.count,
                formattedTotal: formatNumber(total),
                formattedValue: formatNumber(x.count || 0),
                percent: Math.round((x.count / total) * 100),
                color: am5.color(majorActivitiesColors[x.majorCode] ? majorActivitiesColors[x.majorCode] : '#9FA8DA'),
              })),
            });
            setIsLoading(false)
          } catch (e) {
            if(!isAbortError(e)) {
              dispatch({ type: "ALERT", alert: { type: ERROR, msg: e.message } });
            } 
          }
        })();
      }
    },
    [accountId, dispatch, state.account]
  );

  // Build filters query from graph filters
  useEffect(() => {
    let queryObj = {};
    if (state.graphFilters?.map?.length > 0) {
      const isCountry = state.graphFilters.map[0].keyLabel === i18next.t("dashboard.filters.country")
      if(isCountry) {
        queryObj.countries = state.graphFilters.map.map((x) => x.id);
      } else {
        queryObj.departments = mapDepartmentsFilters(state.graphFilters.map).map((x) => x.id);
      }
    }
    if (state.graphFilters?.pie?.length > 0) {
      queryObj.registrationStatus = state.graphFilters.pie.map((x) => x.id);
    }
    fetchActivities(queryObj);

    return () => {
      abortCtrlRef.current?.abort();
    }
  }, [fetchActivities, state.graphFilters?.map, state.graphFilters?.pie]);

  return (

       <>
         <EAActivitiesChart hidden={isLoading || !hasTPs} data={activitiesStates} tab={ROUTES.THIRDPARTIES} />

         {!isLoading && !hasTPs && (
              <EANoData
              style={{ width:'100%', height: 'calc(100% - 30px)', marginBottom: '30px' }}
              label={i18next.t("dashboard.thirdparties.nodata")}
              icon="noThirdparties"
            />
         )}

          {isLoading && (
            <ThirdPartyBlocSkeleton />
          )}
       </>
    )
}

export default TpActivitiesChart;
