import React, { useContext, useEffect, useState, useCallback } from "react";
import i18next from "i18next";
import { useForm, FormProvider } from "react-hook-form";

import EAIcon from "components/common/EAIcon";
import EADialog from "components/common/EADialog";
import useStyles from "style/js-style/containers/administration/roles/dialogsStyle";
import { Context } from "states/Store";
import { deleteAccountRole } from "services/edge/RoleService";
import { ERROR, SUCCESS } from "services/common/Constants";
import RoleSelectInput from "components/roles//RoleSelectInput";
import {Trans} from "react-i18next";


const RoleDeleteDialog = ({
  role,
  isRoleAssigned,
  baseRoleCandidates,
  isOpen,
  onClose,
  onValidateSuccess,
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [submitHandler, setSubmitHandler] = useState(null)

  const handleSimpleDelete = useCallback(async () => {
    try {
      await deleteAccountRole(state.account.id, role.id, null, dispatch);
      const roleName = role.name;

      dispatch({
        type: "ALERT",
        alert: {
          type: ERROR,
          msg: <Trans i18nKey="roles.alert.deleteRole"> Le rôle <span style={{color: "#990001", fontWeight: 600}}>"{{roleName}}"</span> a bien été supprimé.</Trans>
        },
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: {
          type: ERROR,
          msg: i18next.t("infos.INTERNAL_ERROR"),
        },
      });
    }
  }, [state.account, role, dispatch, onValidateSuccess]);

  const handleAssignedDelete = useCallback(async (data) => {
    try {
      if (data) {
        await deleteAccountRole(state.account.id, role.id, data.replacementRoleId, dispatch);
        const roleName = role.name;
        const attachmentName = baseRoleCandidates.find(obj => obj.value === data.replacementRoleId).label

        dispatch({
          type: "ALERT",
          alert: {
            type: SUCCESS,
            msg: (
              <Trans i18nKey="roles.alert.deleteAndAttachedRole">
                Les utilisateurs ont bien été rattachés au rôle <span style={{color: "#0B2810", fontWeight: 600}}>{{attachmentName}}</span>
                et le rôle <span style={{color: "#0B2810", fontWeight: 600}}>{{roleName}}</span> a bien été supprimé.
              </Trans>
            )
          },
        });

        onValidateSuccess();
      }
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: {
          type: ERROR,
          msg: i18next.t("infos.INTERNAL_ERROR"),
        },
      });
    }
  }, [state.account, role, dispatch, onValidateSuccess, baseRoleCandidates])

  const handleDelete = async () => {
    if (isRoleAssigned) {
      if (submitHandler) {
        await submitHandler();
      }
    } else {
      handleSimpleDelete();
    }
  }

  const [validIsDisabled, setValidIsDisabled] = useState(false);

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={handleDelete}
      fullWidth
      hasActions={true}
      maxWidth="popupxs"
      customBtnStyle={{ paddingBottom: "24px !important" }}
      customDialogStyle={{ paddingBottom: "0 !important" }}
      variant={"redValid"}
      btnSize={"large"}
      isValidateDisabled={validIsDisabled}
    >
      <div className={classes.root}>
        <EAIcon icon="roles/popup-remove" />
        <h2 className={classes.title} style={{ marginBottom: "16px" }}>
          {i18next.t("roles.popup.removeTitle")}
        </h2>

        <div className={classes.roleWrapper}>
          <span className={classes.roleName}>
            {role.name}
          </span>
        </div>

        {isRoleAssigned && (
          <NewRoleForm
            setSubmitHandler={setSubmitHandler}
            onSubmit={handleAssignedDelete}
            baseRoleCandidates={baseRoleCandidates}
            setValidIsDisabled={setValidIsDisabled}
          />
        )}
      </div>
    </EADialog>
  );
};

const NewRoleForm = ({ formId, setSubmitHandler, onSubmit, baseRoleCandidates, setValidIsDisabled }) => {
  const { classes } = useStyles();

  const formMethods = useForm({
    mode: "onChange",
    delayError: 500,
  });

  useEffect(() => {
    setSubmitHandler(() => formMethods.handleSubmit(onSubmit));

    return () => {
      setSubmitHandler(null);
    }
  }, [formMethods, setSubmitHandler, onSubmit])

  return (
    <FormProvider {...formMethods}>
      <form
        id={formId}
        onSubmit={() => {/*Do nothing*/ }}
      >
        <div className={classes.formWrapper}>
          <RoleSelectInput
            fieldName={`replacementRoleId`}
            label={i18next.t("roles.popup.newRole")}
            placeholder={i18next.t("roles.popup.parentPlaceholder")}
            options={baseRoleCandidates}
            isRequired
            validate={() => {
              return null;
            }}
            labelVariant='standard'
            variant={"large"}
            subTitle={i18next.t("roles.popup.subTitleNewRole")}
            setValidIsDisabled={setValidIsDisabled}
          />
        </div>
      </form>
    </FormProvider>
  )
}

export default RoleDeleteDialog;
