import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import useStyles from "../../style/js-style/components/thirdparties/ThirdpartyIdentifiersStyle"
import {PrivilegeEnum, RoleBasedAccessControlContext} from "../../services/common/RolesUtils";

function ThirdpartyIdentifiers({identifiers, size = 3}) {
    identifiers = identifiers?.filter(item => item.value || item.value === "");
    const shownIdentifiers = size !== "full" ? identifiers?.slice(0, size) : identifiers;
    const { classes } = useStyles();
    const { hasAnyOfPrivileges } = React.useContext(RoleBasedAccessControlContext);

    return (<>
        {shownIdentifiers?.map((item,index) => (
            <div className={index === 0 ? classes.thirdPartieCodeWrapper : ""} key={index}>
                <Tooltip title={<span className='dt' >{item.value || "" }{item.type ? ` (${item.type})` : ""}</span> }>
                    <span
                      className={
                          index === 0
                            ? classes.thirdPartieCode
                            : classes.thirdPartieIdContent
                      }
                    >
                        <span className="dt">{`${item.value || "" }${item.type ? ` (${item.type})` : ""}`}</span>
                    </span>
                </Tooltip>

                    {item.icon && (
                        <IconButton
                          size="small"
                          disabled={!hasAnyOfPrivileges(PrivilegeEnum.PRIVILEGE_EDIT_THIRD_PARTY)}
                          onClick={item.onIconClick}
                          className={index === 0
                            ? classes.thirdPartieCodeIcon
                            : ""}
                        >
                            {item.icon}
                        </IconButton>
                    )}

            </div> ))}
        </>);
}

export default ThirdpartyIdentifiers;
