import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import i18next from "i18next";
import clsx from "clsx";
import useStyles from "../../style/js-style/containers/doc-viewer/DocViewerTableStyle";

const StyledTableRow = (props) => {
  const { classes } = useStyles(props);
  return <TableRow className={classes.root} {...props} />;
};

const withoutTooltip = (item) => {
  return Array.isArray(item?.props?.children) || item?.props?.noTooltip;
};

const DocViewerTable = ({ header, body, noTranslation, noTranslationTexts, hideEmptyNotice = false }) => {
  const { classes } = useStyles();
  const getCellWidth = (index, rowLength) => {
    switch (rowLength) {
      case 2:
        return index === 0 ? 50 : 50;
      case 3:
        return index === 2 ? 10 : 45;
      case 4:
        return index === 3 ? 10 : index === 2 ? 40 : 25;
      default:
        return 100 / rowLength;
    }
  };

  const translate = (index, row) => {
    if(index === 0){
      return true
    }else if (noTranslation || (noTranslationTexts?.includes(row[0]))){
        return false
    }
    return true
  }

  return (
    <Table>
      {body ? (
        // With datas
        <>
          {header && (
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  colSpan={body[0]?.length || 1}
                  className={classes.header}
                >
                  <span>{header}</span>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {body.map((row, index) => (
              <StyledTableRow key={index}>
                {row.map((item, index2) => (
                  <TableCell
                    key={index2}
                    width={`${getCellWidth(index2, row.length)}%`}
                    className={
                      index2 === 0
                        ? classes.tableCellDescribe
                        : classes.tableCell
                    }
                  >
                    {withoutTooltip(item) ? (
                      item
                    ) : (
                      !Array.isArray(item) ?
                      <Tooltip title={item || ""} arrow>
                        <span className={clsx(translate(index2, row) ? "" : "dt", classes.displayedItem)}>{item}</span>
                      </Tooltip>
                      :
                      <Tooltip title={!Array.isArray(item) ? (item || "") :
                        <div style={{ whiteSpace: 'pre-line' }}>{item.join("\n")}</div>
                      } arrow>
                        <span className={classes.displayedItem}>{item.map((i)=>(
                          <div>{i}</div>
                        ))}</span>
                      </Tooltip>
                    )}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </>
      ) : hideEmptyNotice ? <></> : (
        // Without datas
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell
              width={100}
              className={clsx(classes.tableCell, classes.centered)}
            >
              <span className={classes.noDataContent}>
                {i18next.t("nil")}
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      ) }
    </Table>
  );
};
export default DocViewerTable;
