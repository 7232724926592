import React, { useState } from "react";
import { Fab, Tooltip } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import i18next from "i18next";
import { useEffect } from "react";
import {darkGrey, drawerWidth, lightGrey2} from "../../style/globalStyle";

const useStyles = makeStyles()(theme => ({
  root: {
    position: "fixed",
    bottom: "1rem",
    left: "50%",
    transform: "translateX(-50%)",
    marginLeft: drawerWidth/2,
    backgroundColor: darkGrey,
    zIndex: 9999,
    color: "#FFF",
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    "&:hover": {
      backgroundColor: lightGrey2
    }
  }
}));

export const scrollToTop = () => {
  window['scrollTo']({top: 0, behavior: 'smooth'})
}

function EAScrollToTop() {
  const { classes } = useStyles();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  
  const handleScroll = () => (window.pageYOffset && window.pageYOffset > 200) ? setShowScrollToTop(true) : setShowScrollToTop(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <>
      {showScrollToTop && (
        <Tooltip title={i18next.t("scrollToTop")}>
          <Fab className={classes.root} size="small" aria-label={i18next.t("scrollToTop")} onClick={scrollToTop}>
            <KeyboardArrowUpIcon />
          </Fab>
        </Tooltip>
      )}
    </>
  );
}

export default EAScrollToTop;
