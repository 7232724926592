import React, { useEffect, useState } from "react";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import i18next from "i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useStyles from "../../style/js-style/components/rules/EARuleAccordionStyle";
import EAIcon from "../common/EAIcon";

const ToggleContainer = ({isShown, element}) => {
  return(
    <Grid item xs={12} style={{ display: isShown ? 'block' : 'none', padding: '1rem' }}>
      {element}
    </Grid>
  )
}

function EARuleAccordion({
  title,
  buttons,
  children,
  icon,
  opened = false,
  disabled,
}) {
  const { classes } = useStyles();
  const [isShown, setIsShown] = useState(opened);

  useEffect(() => {
    setIsShown(opened & !disabled);
  }, [opened, disabled])

  return (
    <div className={disabled ? classes.rootDisabled : classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          className={classes.title}
        >
          {/* Header */}
          <Typography variant="h3">
            <div className={classes.rightBlock}>
              <EAIcon icon={icon} className={classes.icon} />
              <span className={classes.sectionTitle}>{title}</span>
            </div>
            <div className={classes.leftBlock}>
              {buttons?.map((btn, index) => {
                return <div key={index}>{btn}</div>;
              })}
              <IconButton
                size="small"
                aria-label={i18next.t(isShown ? "hide" : "show")}
                onClick={() => setIsShown(!disabled && !isShown)}
              >
                { !disabled && isShown
                  ? <KeyboardArrowUpIcon />
                  : <KeyboardArrowDownIcon />
                }
              </IconButton>
            </div>
          </Typography>
        </Grid>
        <ToggleContainer isShown={title === i18next.t("rules.configuration.title") && isShown} element={<Divider className={classes.divider}/>} />

        {/* Content */}
        <ToggleContainer isShown={isShown} element={children} />
      </Grid>
    </div>
  );
}
export default EARuleAccordion;
