import React from "react";
import useStyles from "../style/js-style/components/common/EABackgroundStyle";
import clsx from "clsx";

/**
 * Special background with 2 circles
 */
function EABackground() {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.circleLeft,classes.circle)}/>
      <div className={clsx(classes.circleRight,classes.circle)}/>
    </div>
  );
}

export default EABackground;
