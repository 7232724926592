import React, { useContext } from "react";
import { TablePagination, Skeleton } from "@mui/material";
import { Context } from "../../../states/Store";

function defaultLabelDisplayedRows({ from, to, count }) {
  return (
    <>
      <b>
        <span className="dt">{from}</span>
      </b>{" "}
      à <span className="dt">{to}</span> sur{" "}
      <b>
        {count !== -1 ? (
          <span className="dt">{count}</span>
        ) : (
          <>
            plus de <span className="dt">{to}</span>
          </>
        )}
      </b>
    </>
  );
}

/**
 * Custom pagination works with page displayed datagrid
 */
const EAPagination = ({ isLoading }) => {
  const [state, dispatch] = useContext(Context);

  // Update page in pagination state
  const handleChangePage = (event, pageNumber) => {
    const pagination = {
      rowPerPage: state.pagination.rowPerPage,
      page: pageNumber,
      count: state.pagination.count,
    };
    dispatch({ type: "PAGINATION", pagination: pagination });
  };

  // Update rowPerPage in pagination state
  const handleChangeMaxPage = (event) => {
    const pagination = {
      rowPerPage: event.target.value,
      page: state.pagination.page,
      count: state.pagination.count,
    };
    dispatch({ type: "PAGINATION", pagination: pagination });
  };

  return !isLoading && state.pagination?.count >= 0 ? (
    <TablePagination
      count={state.pagination.count || 0}
      onPageChange={(event, pageNumber) => handleChangePage(event, pageNumber)}
      page={state.pagination.page || 0}
      rowsPerPage={state.pagination.rowPerPage || 50}
      onRowsPerPageChange={(event) => handleChangeMaxPage(event)}
      component="div"
      rowsPerPageOptions={[10, 50, 100]}
      labelRowsPerPage="Lignes par page"
      labelDisplayedRows={defaultLabelDisplayedRows}
      sx={
        state.drawerIsOpened
          ? {
              position: "absolute",
              bottom: "20px",
              left: "290px",
              right: 0,
              width: "calc(100% - 340px)",
            }
          : {
              position: "absolute",
              bottom: "20px",
              left: "95px",
              right: 0,
              width: "calc(100% - 150px)",
            }
      }
    />
  ) : (
    <div
      style={
        state.drawerIsOpened
          ? {
              position: "absolute",
              bottom: "40px",
              left: "290px",
              right: 0,
              width: "calc(100% - 340px)",
              display: "flex",
              justifyContent: "space-between",
            }
          : {
              position: "absolute",
              bottom: "40px",
              left: "95px",
              right: 0,
              width: "calc(100% - 150px)",
              display: "flex",
              justifyContent: "space-between",
            }
      }
    >
      <Skeleton variant="rect" width={"200px"} animation="wave" height={30} />
      <Skeleton variant="rect" width={"200px"} animation="wave" height={30} />
    </div>
  );
};
export default EAPagination;
