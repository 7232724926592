import React, { useContext } from "react";
import i18next from "i18next";

import EAIcon from "components/common/EAIcon";
import EADialog from "components/common/EADialog";
import useStyles from "style/js-style/containers/administration/roles/dialogsStyle";
import RoleForm from "containers/administration/roles/form/RoleForm";
import { Context } from "states/Store";
import { ERROR, SUCCESS } from "services/common/Constants";
import { updateAccountRole } from "services/edge/RoleService";
import {Trans} from "react-i18next";


const FORM_ID = "rule-edition-form-id";

const RoleEditDialog = ({
  role,
  baseRoleCandidates,
  isOpen,
  onClose = () => { },
  onValidateSuccess = () => { },
}) => {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);

  const handleSubmit = async (data) => {
    try {
      await updateAccountRole(state.account.id, role.id, data, null);
      const roleName = data.name;

      dispatch({
        type: "ALERT",
        alert: {
          type: SUCCESS,
          msg: (role === undefined || !role.default)
            ? <Trans i18nKey="roles.alert.editRole"> Le rôle <span style={{color: "#0B2810", fontWeight: 600}}>"{{roleName}}"</span> a bien été modifié.</Trans>
            : <Trans i18nKey="roles.alert.renameRole"> Le rôle <span style={{color: "#0B2810", fontWeight: 600}}>"{{roleName}}"</span> a bien été renommé.</Trans>
        },
      });

      onValidateSuccess();
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: {
          type: ERROR,
          msg: i18next.t("infos.INTERNAL_ERROR"),
        },
      });
    }
  }

  return (
    <EADialog
      isOpen={isOpen}
      fullWidth
      maxWidth="popupxs"
      customBtnStyle={{ paddingBottom: "24px !important" }}
      customDialogStyle={{ paddingBottom: "0 !important" }}
      onCancel={onClose}
      hasActions={false}
    >
      <div className={classes.root}>
        <EAIcon icon="roles/popup-edit" />
        <h2 className={classes.title}>
          {(role === undefined || !role.default)
            ? i18next.t("roles.popup.editTitle")
            : i18next.t("roles.popup.renameTitle")
          }
        </h2>

        <div className={classes.mainContentWrapper}>
          <RoleForm
            role={role}
            formId={FORM_ID}
            submitHandler={handleSubmit}
            baseRoleCandidates={baseRoleCandidates}
            onCancel={onClose}
          />
        </div>
      </div>
    </EADialog>
  );
};

export default RoleEditDialog;
