import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogText: {
    textAlign: "center",
    width: "100%",
    whiteSpace: 'pre-wrap'
  },
  dialogTextTitle:{
    padding: "14px 0 34px 0",
  },
  dialogTextMessage:{
    fontSize: "14px",
    color: theme.palette.grey.grey500,
    paddingBottom: "16px",
  },
  formContainer: {
    [`${theme.breakpoints.up("sm")}`]: {
      display: "flex",
      alignItems: "baseline"
    },
    textAlign: 'right'
  },
  input: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",
      minWidth: "276px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #E2E2EA !important",
      },
    }
  },
  formLabel: {
    paddingRight: "21px",
    fontWeight: 700,
    color: theme.palette.grey.grey500,
    fontSize: "14px",
  },
  popupHeader:{
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  topHeaderIcon:{
    backgroundColor: theme.palette.error.error100,
    padding: "9px",
    borderRadius: "50%",
    width: "55px",
    height: "55px",
  },
}));

export default useStyles;
