import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import i18next from "i18next";
import useStyles from "../../style/js-style/components/attributes/AttributeTextFieldInputStyle";
import { get as lodashGet } from "lodash";
import { EDGE_DATE_FORMAT } from "services/common/Constants";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function EADatePicker(props) {
  const { classes } = useStyles();
  const {
    formMethods,
    name,
    isDisabled,
    sxClass,
    onError = () => {},
    isRequired = false,
    ...otherProps
  } = props;

  const onChange = (value) => {
    formMethods.setValue(name, value ? moment(value).format(EDGE_DATE_FORMAT) : value);
  };

  const onDatePickerError = (error, value) => {
    onError(error)
    if (!error) {
      if(isRequired && !value) {
        formMethods.setError(name, {
          type: "datepicker",
          message: "required",
        });
      } else {
        formMethods.clearErrors(name);
      }
      return;
    }
    if (error !== formMethods.formState.errors.expirationDate?.message) {
      formMethods.setError(name, {
        type: "datepicker",
        message: error,
      });
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      dateFormats={{ keyboardDate: i18next.t("date.dateFormat") }}
    >
      <DatePicker
        {...otherProps}
        sx={{ m: 0 }}
        toolbarTitle={i18next.t("date.toolbarTitle")}
        value={formMethods.getValues(name) || null}
        onChange={onChange}
        onError={onDatePickerError}
        format={EDGE_DATE_FORMAT}
        renderInput={(renderProps) => (
          <TextField
            {...renderProps}
            inputProps={{
              ...renderProps.inputProps,
              placeholder: i18next.t("date.placeholder"),
            }}
            className={classes.input}
            error={!!lodashGet(formMethods.formState.errors, name)}
            sx={sxClass}
            disabled={isDisabled}
            fullWidth
            helperText={
              !!lodashGet(formMethods.formState.errors, name)
                ? i18next.t(
                    `date.${lodashGet(formMethods.formState.errors, name).message}`
                  )
                : ""
            }
          />
        )}
      />
      </LocalizationProvider>
  );
}

export default EADatePicker;
