import {
  MenuItem,
  Select,
} from "@mui/material";
import i18next from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import { DefaultSelectProps } from "services/common/Constants";

const EASelectInput = ({
  fieldName,
  options,
  defaultOption,
  translateOptions = true,
  multiple = false,
  fullWidth = true,
}) => {
  const formMethods = useFormContext();

  return (
    <Controller
      control={formMethods.control}
      name={fieldName}
      render={({ field }) => (
        <Select
          {...field}
          id={`${fieldName}-select-id`}
          displayEmpty
          // onChange={(event) => formMethods.setValue(fieldName, event.target.value)}
          MenuProps={DefaultSelectProps.MenuProps}
          multiple={multiple}
          // inputProps={{style: {width: "100%"}}}
          style={{width: fullWidth ? "100%" : "auto"}}
        >
          {defaultOption && (
            <MenuItem key={defaultOption} value="">
              <em>{i18next.t(defaultOption)}</em>
            </MenuItem>
          )}
          {options?.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              <span className={translateOptions || item.value === 'notSpecified' ? "" : "dt"}>{item.label}</span>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default EASelectInput;
