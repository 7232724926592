import { getEnv } from "config/Config";
import { eaFetch } from "services/common/ApiUtils";

export async function getReferenceData(dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/list`
  );
}

export async function getListCountries(dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/list/countries`
  );
}

export async function getListActivity(level, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `365/list/activities${level ? "?level=" + level : ""}`
  );
}
