import { makeStyles } from 'tss-react/mui';
import {darkGrey} from "../../../../globalStyle";

const useStyles = makeStyles()((theme) => ({
    root: {
        zIndex: 2,
        marginBottom: "40px",
        "& .dossierInfoPaper": {
            padding: "1rem"
        },
        "& .completenessPaper": {
            padding: "1rem"
        },
        "& .dossierBtns": {
            display: "flex",
            justifyContent: "flex-end"
        },
        "& .deactivateBtn": {
            backgroundColor: darkGrey,
            color: "white",
            marginRight: "0.5rem"
        }
    },
    topHeaderIcon:{
        marginRight: "5px",
    },
    tab: {
        fontWeight: 600,
        borderRadius: '18px 18px 0 0',
        minWidth: "97px",
        height: "39px",
        marginRight: "2px",
        backgroundColor: "white",
        zIndex: 1,
        position: "relative",
        bottom: "-10px",
        textTransform: "none",
        fontSize: "18px",
        fontFamily: "Metric",
    },
    selectedTab: {
        color: "black !important",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -10px, rgba(0, 0, 0, 0.3) 0px 8px 13px -9px",
    },
    unselectedTab: {
        color: theme.palette.grey.grey400,
        opacity: 0.75,

        "& MuiTabs-scroller": {
            position: "absolute"
        },
    },
    tabsWrapper: {
        border: "none",
        borderBottom: 1,
        borderColor: 'divider',
    },
    addDate: {
        fontSize: "12px",
    },

    // Header background
    headerBackground:{
        //width: "100vw",
        height: "489px",
        position: "absolute",
        background: "no-repeat 100% 90% url('../../../../img/background/bg-folder.png')",
        top: 0,
        left: 0,
        right: 0,
    },
    headerBackgroundCompleted: {
        backgroundColor: theme.palette.primary.primary200,
    },
    headerBackgroundNotCompleted: {
        backgroundColor: theme.palette.grey.grey200,
    },
    headerBackgroundNonConforme: {
        backgroundColor: "#f9cdce",
    },
    headerBackgroundEnCours: {
        backgroundColor: "#c1cbea",
    },
    headerBackgroundActionRequise: {
        backgroundColor: "#faebce",
    },
    headerBackgroundInsuffisant: {
        backgroundColor: "#e6cbfc",
    },
    headerBackgroundArchived: {
        backgroundColor: theme.palette.grey.grey600,
    },
    archivedFontHeaderTheme: {
        color: "white",
    },
    btnControlWrapper: {
        position: "absolute",
        top: "100px",
        right: "20px",
        zIndex: 3,
    },
    // Utils
    backToFront: {
        zIndex: 2,
    },
    tabDisabled: {
        opacity: 0.75,
        background: "#E2E2EABF",
    },
}));

export default useStyles;
