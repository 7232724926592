import React from "react";
import { useLocation } from "react-router-dom";

export function getEnv(variable) {
  return process.env[variable] || window.env?.[variable];
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
