import React, { useCallback, useContext, useEffect, useState } from "react";
import i18next from "i18next";
import _ from "lodash";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useStyles from "style/js-style/containers/administration/calculator/CalculatorDialogStyle";
import EAIcon from "components/common/EAIcon";
import {
  CalculatorContext,
  TargetFieldCategory,
  getAvailableOperatorOptions,
  getTargetFieldOptionsForDocument,
  getTargetFieldOptionsForDossier,
  getTargetFieldOptionsForSourceVariable,
  getTargetFieldOptionsForThirdParty,
  parseTargetFieldCategoryFromVariable,
  parseTargetFieldFromVariable,
  getCriterionModel,
  parseDocumentTypeIdFromVariable,
  DocumentTypePropertyType,
  CalculationDeclarationContext,
} from "services/common/CalculatorUtils";
import CalculatorSelectInput from "components/calculator/CalculatorSelectInput";
import {
  AttributeType,
  FieldDisplayVariant,
  FieldType,
  Operator,
} from "services/common/Constants";
import CriterionValues from "./CriterionValues";
import { Box, List, ListItem, } from "@mui/material";


const Criteria = ({
  fieldName,
  onSizeChange,
  onCriteriaOrderChange
}) => {
  const { classes}  = useStyles();
  const formMethods = useFormContext();

  const {
    control,
    setValue
  } = formMethods;

  const {
    addCallback,
    removeCallBack,
    isValidVariable,
  } = useContext(CalculationDeclarationContext);

  const { fields: criteria, append, move } = useFieldArray({
    control,
    name: `${fieldName}`,
  });

  const onAddEntry = useCallback(() => {
    append(getCriterionModel());
    onSizeChange();
  }, [append, onSizeChange]);

  // Delete a criterion
  const onRemoveEntry = useCallback((id) => {
    setValue(fieldName, [...criteria.filter(criterion => criterion.id !== id)]);
    onSizeChange();
    removeCallBack(id);
  }, [fieldName, criteria, setValue, onSizeChange, removeCallBack]);

  const handleDragEnd = useCallback((result) => {
    if (result?.destination) {
      move(result.source.index, result.destination.index);
      onCriteriaOrderChange();
    }
  }, [move, onCriteriaOrderChange]);

  return (
    <>
      <div className={classes.branchCriteriaWrapper}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                }}
              >
                {criteria?.map((item, index) => {
                  return (
                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                      {(provided) => (
                        <ListItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              m: 0,
                              p: 2,
                              bgcolor: "rgba(242, 243, 247, 0.3)",
                              border: '1px solid',
                              borderColor: 'grey.300',
                              borderRadius: 1,
                              alignItems: 'center'
                            }}>
                            <Box sx={{
                              width: '5%',
                              color: '#C6CBD9'
                            }}>
                              <EAIcon icon={"drag-indicator"} />
                            </Box>
                            <Box sx={{
                              width: '5%',
                              color: '#F4B63E',
                              fontFamily: 'Roboto',
                              fontSize: '20px',
                              fontWeight: 600,
                              lineHeight: '24px',
                              letterSpacing: '0.15000000596046448px',
                              textAlign: 'left',
                            }}>
                              {`${index + 1}`}
                            </Box>
                            <Box sx={{ width: '90%' }}>
                              <Criterion
                                data={item}
                                fieldName={`${fieldName}.${index}`}
                                onRemove={() => onRemoveEntry(item.id)}
                                addCallBack={(callBack) => addCallback(item.id, callBack)}
                                isValidVariable={isValidVariable}
                              />
                            </Box>
                          </Box>
                        </ListItem>
                      )
                      }
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div >

      {/* Add a criterion */}
      < div
        className={classes.addCriterion}
        style={{ padding: "0 0 16px 24px", width: "180px", cursor: "pointer" }
        }
        onClick={() => onAddEntry()}
      >
        <EAIcon icon={"cross_green"} extension="png" style={{ width: "10px" }} />
        <span> {i18next.t("calculator.dialog.create.addCriterion")}</span>
      </div >
    </>
  );
}

const Criterion = ({ fieldName, onRemove, addCallBack, isValidVariable }) => {
  const { classes } = useStyles();
  const [showLabel, setShowLabel] = useState(true);

  const {
    countries,
    activities,
    thirdpartyAttributes,
    dossierAttributes,
    documentTypes,
    calculatedAttributes,
    thirdPartyIndicators,
    financialStrengthScores,
    financialStrengthRatings,
    sourceVariables,
    showInduedScores,
    showTransparencyScore,
    showFinancialStrengthScores,
    enableTransparencyScore
  } = useContext(CalculatorContext);

  const formMethods = useFormContext();
  const {
    setValue,
    clearErrors,
  } = formMethods;

  const [definedVariables, setDefinedVariables] = useState([]);
  const [availableVariableOptions, setAvailableVariableOptions] = useState([]);
  const [availableOperatorOptions, setAvailableOperatorOptions] = useState([]);
  const [availableValueOptions, setAvailableValueOptions] = useState([]);
  const [variantAutoComplete, setVariantAutoComplete] = useState(false);

  const [selectedFieldType, setSelectedFieldType] = useState();
  const [selectedFieldSubType, setSelectedFieldSubType] = useState('');
  const [selectedOperator, setSelectedOperator] = useState();

  let variables = useWatch({ name: `variables` });
  let currentCriterion = useWatch({ name: `${fieldName}` });

  useEffect(() => {
    const filteredVariables = _.filter(variables, isValidVariable);

    setDefinedVariables(filteredVariables);

    setAvailableVariableOptions(
      filteredVariables.map((entry) => ({
        value: entry.name,
        label: entry.name,
      })).sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      })
    );
  }, [variables, isValidVariable]);

  const getAvailableTargetFields = useCallback((variable) => {
    let availableTargetFields = [];

    switch (parseTargetFieldCategoryFromVariable(variable)) {
      case TargetFieldCategory.THIRD_PARTY:
        availableTargetFields = getTargetFieldOptionsForThirdParty(
          countries,
          activities,
          thirdpartyAttributes,
          calculatedAttributes,
          thirdPartyIndicators,
          financialStrengthScores,
          financialStrengthRatings,
          showInduedScores,
          showTransparencyScore,
          showFinancialStrengthScores,
          enableTransparencyScore
        );
        break;

      case TargetFieldCategory.DOSSIER:
        availableTargetFields = getTargetFieldOptionsForDossier(
          dossierAttributes
        );
        break;

      case TargetFieldCategory.DOCUMENT:
        availableTargetFields = getTargetFieldOptionsForDocument(
          _.find(documentTypes, { value: parseDocumentTypeIdFromVariable(variable) })?.properties || []
        );
        break;

      case TargetFieldCategory.SOURCE_VARIABLE:
        availableTargetFields = getTargetFieldOptionsForSourceVariable(
          sourceVariables
        );
        break;

      default:
        break;
    }

    return availableTargetFields;
  }, [
    countries,
    activities,
    thirdpartyAttributes,
    dossierAttributes,
    documentTypes,
    calculatedAttributes,
    thirdPartyIndicators,
    financialStrengthScores,
    financialStrengthRatings,
    sourceVariables,
    showInduedScores,
    showTransparencyScore,
    showFinancialStrengthScores,
    enableTransparencyScore
  ]);

  const getTargetFieldEntryFromVariable = useCallback((variable) => {
    const field = parseTargetFieldFromVariable(variable);
    const targetFields = getAvailableTargetFields(variable);
    return _.find(targetFields, { value: field });
  }, [getAvailableTargetFields]);

  // Change l'opérateur et reinitialise les valeurs
  const onOperatorChange = useCallback((newValue) => {
    setSelectedOperator(newValue);

    switch (newValue) {
      case Operator.IN_SET:
      case Operator.NOT_IN_SET:
      case Operator.IS_NULL:
      case Operator.NOT_NULL:
        setValue(`${fieldName}.values`, []);
        break;

      case Operator.EQUAL:
      case Operator.CONTAINS:
        const variable = _.find(definedVariables, { id: currentCriterion?.variableId });
        const targetFieldEntry = getTargetFieldEntryFromVariable(variable);

        if (
          (targetFieldEntry?.type === FieldType.ATTRIBUTE &&
            targetFieldEntry?.subType === AttributeType.LIST_MULTIPLE) ||
          (targetFieldEntry?.type === FieldType.FORM_FIELD &&
            targetFieldEntry?.subType === DocumentTypePropertyType.CASE_A_COCHER)
        ) {
          setValue(`${fieldName}.values`, []);
        } else {
          setValue(`${fieldName}.values`, [""]);
        }
        break;

      default:
        setValue(`${fieldName}.values`, [""]);
        break;
    }

    clearErrors(`${fieldName}.values`);
  }, [fieldName, setValue, definedVariables, getTargetFieldEntryFromVariable, currentCriterion, clearErrors]);

  const onVariableNameChange = useCallback((newValue) => {
    const variable = _.find(definedVariables, { name: newValue });

    if (variable !== undefined) {
      const targetFieldEntry = getTargetFieldEntryFromVariable(variable);

      setValue(`${fieldName}.variableId`, variable.id);
      setValue(`${fieldName}.variableType`, variable.type);

      setSelectedFieldType(targetFieldEntry?.type);
      setSelectedFieldSubType(targetFieldEntry?.subType || ``);

      //Criterion operator
      const filteredOperatorOptions = getAvailableOperatorOptions(targetFieldEntry.type, targetFieldEntry.subType || ``);
      setAvailableOperatorOptions(filteredOperatorOptions);

      if (filteredOperatorOptions?.length > 0) {
        setValue(`${fieldName}.operator`, filteredOperatorOptions[0].value);
        onOperatorChange(filteredOperatorOptions[0].value);
      } else {
        setValue(`${fieldName}.operator`, "");
      }
      clearErrors(`${fieldName}.operator`);

      //Criterion values
      setAvailableValueOptions(targetFieldEntry?.valueOptions);
      setVariantAutoComplete(targetFieldEntry.optionsDisplayVariant && targetFieldEntry.optionsDisplayVariant === FieldDisplayVariant.AUTOCOMPLETE
        ? true : false)

      setValue(`${fieldName}.values`, []);
      clearErrors(`${fieldName}.values`);
    }
  }, [fieldName, definedVariables, getTargetFieldEntryFromVariable, setValue, clearErrors, onOperatorChange]);

  const onVariableUpdate = useCallback((variable, action, value = null) => {
    if (variable.id === currentCriterion.variableId) {
      switch (action) {
        case "name":
          setValue(`${fieldName}.variableName`, value);
          break;
        case "target_field_category":
        case "target_field_nature":
        case "field":
        case "document_type_id":
        case "dossier_type_id":
          onVariableNameChange(variable.name);
          break;
        case "delete":
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [currentCriterion.variableId, fieldName, onVariableNameChange, setValue])

  // Quand l'id de la variable cible est modifié, ajoute ou remplace une callBack
  useEffect(() => {
    if (currentCriterion.variableId !== "") {
      addCallBack(onVariableUpdate);
    }
    // eslint-disable-next-line
  }, [currentCriterion.variableId, onVariableUpdate])

  // Récupère les opérateurs et valeurs des critères à la restitution ou modification des variables
  useEffect(() => {
    const variable = _.find(definedVariables, { id: currentCriterion?.variableId });
    const targetFieldEntry = getTargetFieldEntryFromVariable(variable);

    if (targetFieldEntry !== undefined && (selectedFieldType !== targetFieldEntry?.type || selectedFieldSubType !== targetFieldEntry?.subType)) {
      // MAJ type
      setSelectedFieldType(targetFieldEntry?.type);
      setSelectedFieldSubType(targetFieldEntry?.subType || ``);
      // MAJ opérateur
      const filteredOperatorOptions = getAvailableOperatorOptions(targetFieldEntry.type, targetFieldEntry.subType || ``);
      setAvailableOperatorOptions(filteredOperatorOptions);
      // MAJ valeurs
      setAvailableValueOptions(targetFieldEntry?.valueOptions);
      setVariantAutoComplete(targetFieldEntry.optionsDisplayVariant && targetFieldEntry.optionsDisplayVariant === FieldDisplayVariant.AUTOCOMPLETE
        ? true : false)
    }
  }, [currentCriterion, definedVariables, getTargetFieldEntryFromVariable, selectedFieldType, selectedFieldSubType])

  useEffect(() => {
    const variable = _.find(definedVariables, { name: currentCriterion?.variableName });
    const targetFieldEntry = getTargetFieldEntryFromVariable(variable);
    setSelectedFieldType(targetFieldEntry?.type);
    setSelectedFieldSubType(targetFieldEntry?.subType || ``);

    setSelectedOperator(currentCriterion?.operator);
  }, [currentCriterion, definedVariables, getTargetFieldEntryFromVariable]);

  useEffect(() => {
    setShowLabel(true);
    // setShowLabel(parseInt(fieldName?.slice(-1) || `0`) === 0);
    // eslint-disable-next-line
  }, [fieldName]);

  return (
    <div className={classes.variableBranchLineRoot}>
      <EAIcon
        icon={"highlight_off"}
        className={classes.variableBranchLineCrossIcon}
        style={{ top: "45px" }}
        onClick={() => onRemove()}
      />

      {/* Variable */}
      <div className={classes.variableLineNameItem}>
        <CalculatorSelectInput
          fieldName={`${fieldName}.variableName`}
          isRequired
          label={showLabel ? i18next.t("calculator.dialog.create.label.variable") : ``}
          placeholder={i18next.t(
            `calculator.dialog.create.placeholder.variableName`
          )}
          options={availableVariableOptions}
          onChange={(newValue) => {
            onVariableNameChange(newValue);
          }}
          validate={() => {
            return null;
          }}
          variant="standard"
          labelVariant="chip"
          className={classes.inputVariableNameBranch}
          dt
        />
      </div>

      {/* Opérateur */}
      <div className={classes.variableLineNameItem} style={{ width: "140px" }}>
        <CalculatorSelectInput
          fieldName={`${fieldName}.operator`}
          label={showLabel ? i18next.t(`calculator.dialog.create.label.operator`) : ``}
          isRequired
          placeholder={i18next.t(
            `calculator.dialog.create.placeholder.selectOperator`
          )}
          options={availableOperatorOptions}
          onChange={(newValue) => {
            onOperatorChange(newValue);
          }}
          validate={() => {
            return null;
          }}
          variant="standard"
          labelVariant="chip"
          className={classes.inputVariableNameBranch}
        />
      </div>

      {/* Valeurs */}
      <div className={classes.variableLineNameItem}>
        <CriterionValues
          fieldName={`${fieldName}.values`}
          showLabel={showLabel}
          selectedFieldType={selectedFieldType}
          selectedFieldSubType={selectedFieldSubType}
          selectedOperator={selectedOperator}
          availableValueOptions={availableValueOptions}
          variantAutoComplete={variantAutoComplete}
        />
      </div>
    </div>
  );
}

export default Criteria;
