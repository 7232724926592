import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogText: {
    margin: 0,
    textAlign: "center",
    width: "100%",
    whiteSpace: "pre-wrap",
  },
  mailInfoWrapper:{
    backgroundColor: theme.palette.grey.grey100,
    color: theme.palette.grey.grey700,
    padding: "1rem 0",
    width: "420px",
    margin: "8px auto"
  },
  imgWrapper:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey.grey100,
    borderRadius: "50%",
    width: "55px",
    height: "55px",
    margin: "0 0 1rem 0",
  },
  imgWrapperMulti:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey.grey100,
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    margin: "0 0 1rem 0",
  },
  imgContent:{
    width: "33px",
  },
  imgContentMulti:{
    width: "45px",
  },
  imgContentWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  bold: {
    fontWeight: 600,
    fontFamily: "Metric",
    fontSize: "20px",
    color: theme.palette.grey.grey700,
  }
}));

export default useStyles;
