import React, { useContext } from 'react';
import {
	Typography,
	TextField,
	Grid,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import i18next from 'i18next';
import { approveDocument } from '../../services/edge/DocumentService';
import EADialog from '../../components/common/EADialog';
import EAInputBloc from "../../components/common/EAInputBloc";
import EAIcon from '../../components/common/EAIcon';
import { Context } from '../../states/Store';
import useStyles from "../../style/js-style/containers/doc-viewer/StatusApproveDialogStyle.js";
import { ERROR } from '../../services/common/Constants';
import { isEmpty } from '../../services/common/Utils';
import {useRequestLoading} from "../../components/common/hooks/useRequestLoading";


const StatusApproveDialog = ({ isOpen, responseId, handleOnApprove, handleClose }) => {
	const { classes } = useStyles();
	const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
	const [state, dispatch] = useContext(Context);

	const id = isOpen ? 'filters-dialog' : undefined;

	const maxLengthComment = 1000;

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ mode: "onChange", delayError: 500 });

	const handleApprove = async (data) => {
		if (isEmpty(errors)) {
			const statusComment = {
				comment: data.comment
			  }
			try {
				startLoading();
				await approveDocument(state.account.id, responseId, statusComment, null);
				handleOnApprove();
			} catch (error) {
				dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DOC_STATUS_UPDATE_ERROR" } });
			}finally {
				stopLoading();
			}
		}
	}

	const handleDialogClose = () => {
		handleClose();
	}

	return (
		<EADialog
		  id={id}
		  isOpen={isOpen}
		  onCancel={handleDialogClose}
		  onValidate={handleSubmit(handleApprove)}
		  hasActions={true}
		  maxWidth="popupxs"
		  fullWidth
		  btnSize="regular"
		  isFeedBack={false}
		  customBtnStyle={{paddingBottom: "24px !important"}}
			customDialogStyle={{paddingBottom: "32px !important"}}
			isValidateDisabled={isRequestLoading}
		>
			<form>
					{/* Header */}
					<div className={classes.headerWrapper}>
						<EAIcon icon={"conforme"} className={classes.imgContent} />
					  <div>
						<Typography variant="body1" className={classes.dialogText}>
						  {i18next.t("viewer.status.approvePopupTitle")}
						</Typography>
					  </div>
					</div>

					<Grid item xs={12}>
              <EAInputBloc label={`${i18next.t("reason")}`}>
                <Controller
                  control={control}
                  name="comment"
                  rules={{
                    maxLength: {
                      value: maxLengthComment,
                      message: i18next.t("infos.E011_VAR", {
                        input: i18next.t("theReason"),
                        maxLength: maxLengthComment,
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      id="approbation-reason-detail"
                      variant="outlined"
                      fullWidth
											className={classes.inputText}
                      {...field}
                      multiline
                      minRows={2}
                      maxRows={5}
                      error={!!errors.comment}
                      helperText={
                        !!errors.comment ? errors.comment.message : ""
                      }
                    />
                  )}
                />
              </EAInputBloc>
            </Grid>
			</form>
		</EADialog>
	  );
	};

export default StatusApproveDialog;
