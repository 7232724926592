import React, { useContext, useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import _ from "lodash";

import EAAppBar from "./EAAppBar";
import EADrawer from "./EADrawer";
import EAScrollToTop from "./common/EASrollToTop";
import { useLocation } from "react-router";
import { Context, initialContextState } from "../states/Store";
import { useAccounts } from "./common/hooks/useAccounts";
import useStyles from "style/js-style/components/LayoutStyle";
import EASnackBar from "./EASnackBar";
import { RoleBasedAccessControlContext } from "services/common/RolesUtils";
import { getReferenceData } from "services/edge/ListService";
import { ERROR } from "services/common/Constants";

function Layout({ children, variant, crumbs, isAuthorized = true }) {
  const { classes } = useStyles();
  const [state, dispatch] = useContext(Context);
  const [fullCrumbs, setFullCrumbs] = useState();
  const [shownCrumbs, setShownCrumbs] = useState();
  const [currentPath, setCurrentPath] = useState("");
  let location = useLocation();
  const { isGlobalAdmin } = useContext(RoleBasedAccessControlContext);

  useAccounts();

  useEffect(() => {
    if (crumbs) {
      let newCrumbs = [...crumbs.data]
      if (state.crumbsData) {
        if (state.crumbsData.lastCrumbs) {
          newCrumbs.push(...state.crumbsData.lastCrumbs)
        }
      }
      if (!crumbs.hasAdditionalData || (crumbs.hasAdditionalData && state.crumbsData)) {
        setFullCrumbs(newCrumbs);
        setShownCrumbs(newCrumbs.filter(x => !x.hidden));
      }
    }
  }, [crumbs, state.crumbsData])


  // Fetch Reference Data
  useEffect(() => {
    async function getRefData() {
      try {
        const response = await getReferenceData(dispatch);

        if (response?.content) {
          dispatch({ type: "REFERENCE_DATA", data: response.content })
        }
      } catch (e) {
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: "INTERNAL_ERROR" },
        });
      }
    }
    if (state.isAuthDone) {
      getRefData();
    }
  }, [dispatch, state.isAuthDone]);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      dispatch({ type: "ALERT", alert: null });
      dispatch({ type: "CRUMBS", crumbsData: null });
      dispatch({ type: "PAGINATION", pagination: initialContextState.pagination });
      setCurrentPath(location.pathname);
    }
  }, [location, currentPath, dispatch])

  return !isAuthorized ? (
    <></>
  )
    : (
      <div className={classes.root}>
        <CssBaseline />
        {state.account && (isGlobalAdmin || !_.isEmpty(state.roles)) && (
          <>
            {variant !== "withoutAppBar" &&
              <EAAppBar crumbs={shownCrumbs} />
            }
            <EADrawer crumbs={fullCrumbs} />
            <div className={
              state.drawerIsOpened
                ? classes.largeDrawerContent
                : classes.content
            }
            >
              {variant !== "withoutAppBar" &&
                <div className={classes.toolbar} />
              }
              <div className="childSection">{children}</div>
              <EAScrollToTop />
              <EASnackBar />
            </div>
          </>
        )}
      </div>
    );
}

export default Layout;
