import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.grey.grey300,
    position: "absolute",
    left: "280px",
    marginTop: "1.5rem",
    fontSize: "14px",
    fontWeight: 700,
  },
  rootReduced: {
    color: theme.palette.grey.grey300,
    position: "absolute",
    left: "70px",
    marginTop: "1.5rem",
    fontSize: "14px",
    fontWeight: 700,
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "inherit"
  },
  lastPart: {
    fontWeight: 400,
  },
}));

export default useStyles;
