import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    height: "59vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  loaderContainer:{
    maxWidth: "644px",
    width: "100%",
    padding: "25px 100px",
    paddingBottom: "30px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  loaderRootSuccess: {
    backgroundColor: "#d7f4dc61",
    border: "1px solid #2d9f4036",
    borderLeft: "5px solid #2d9f4094",
  },
  loaderRootError: {
    backgroundColor: "#cc000108",
    border: "1px solid #cc00012b",
    borderLeft: "5px solid #cc000169",
  },
  generatingFileroot: {
    textAlign: "center",
    color : "#656565",
  },
  signatureSuccessContainer: {
    display: "flex",
    flexDirection: "row",
    placeContent: "center",
    width: "100%",
    marginBottom: "15px",
  },
  signatureSuccessTitle: {
    fontSize: "17px",
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
  },
  signatureErrorTitle: {
    color: "#ff0001ab",
  },
  signatureSuccessText: {
    textAlign: "center",
    fontSize: "16px",
    color: theme.palette.grey.grey800,
  },
  signatureSuccessIcon: {
    width: "24px",
    height: "24px",
  },
}));

export default useStyles;
