import { Box, Grid, Tab, Tabs } from "@mui/material";
import i18next from "i18next";
import React, { useContext } from "react";
import TabThirdparties from "./thirdparties/TabThirdparties";
import TabDossiers from "./dossiers/TabDossiers";
import TabDocuments from "./documents/TabDocuments";
import { useHistory } from "react-router-dom";
import { Context } from "../../states/Store";
import EATabPanel from "../../components/common/EATabPanel";
import { EAChartFilters } from "../../components/common/charts/EAChartFilters";
import useStyles from "../../style/js-style/containers/dashboard/DashboardStyle.js";
import { useQuery } from "../../config/Config";
import ROUTES from "../../config/Routes";
import clsx from "clsx";
import TabRisks from "./risks/TabRisks";
import { isEmpty, find as lodashFind} from "lodash";
import { hasOption } from "services/common/Utils";
import useSelectedAccountId from "components/common/hooks/useSelectedAccountId";
import { useEffect } from "react";

function a11yProps(tab) {
  return {
    id: `${tab}-tab`,
    "aria-controls": `${tab}-tabpanel`,
  };
}

const dashboardTabs = [
  { key: ROUTES.THIRDPARTIES },
  { key: ROUTES.DOSSIERS },
  { key: ROUTES.DOCUMENTS },
  { key: "risks", subscriptions: ["indued", "transparency", "financialStrength"] },
];

function DashBoard() {
  const [value, setValue] = React.useState(0);
  const tab = useQuery().get("tab");
  const history = useHistory();

  const redirectSuccess = window.location.hash === "#REDIRECT_SUCCESS";
  // if redirect success is true , we redirect to the last visited page before redirection ( stored in localstorage, named lastVisited)
  const lastVisited = localStorage.getItem("lastVisited");
  if (redirectSuccess && lastVisited) {
    history.push(lastVisited);
  }

  const { classes } = useStyles();
  const [state] = useContext(Context);

  const accountId = useSelectedAccountId();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(`/${accountId}/${ROUTES.DASHBOARD}?tab=${newValue}`);
  };

  useEffect(() => {
    setValue(parseInt(tab) || 0)
  }, [tab])

  const isTabDisplayed = (subs) => isEmpty(subs) || subs?.some(risk => hasOption(state.accounts, state.account.id, risk));

  return (
    <>
      <Grid container justifyContent="space-evenly" spacing={0}>
        <Grid item xs={12} className={classes.wrapper}>
          {/*  Decoration */}
          <div className={classes.circleLeft + " " + classes.circle}></div>
          <div className={classes.circleRight + " " + classes.circle}></div>

          {/* Labels */}
          <Box className={classes.tabsWrapper}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="dashboard tabs"
            >
              {dashboardTabs.map((tab, index) => isTabDisplayed(tab.subscriptions) ? (
                <Tab
                  key={tab.key}
                  label={i18next.t(`dashboard.${tab.key}.title`)}
                  {...a11yProps(tab.key)}
                  className={clsx(
                    classes.tab,
                    value === index
                      ? classes.selectedTab
                      : classes.unselectedTab
                  )}
                />
              ) : "")}
            </Tabs>
          </Box>

          {/* Label's content */}
          <EATabPanel value={value} index={0}>
            <TabThirdparties/>
          </EATabPanel>
          <EATabPanel value={value} index={1}>
            <TabDossiers/>
          </EATabPanel>
          <EATabPanel value={value} index={2}>
            <TabDocuments/>
          </EATabPanel>
          {isTabDisplayed(lodashFind(dashboardTabs, ["key", "risks"])?.subscriptions) && (
            <EATabPanel value={value} index={3}>
              <TabRisks/>
            </EATabPanel>
          )}
        </Grid>
      </Grid>
      {/* Filters on Risks if selected */}
      {value !== 3 && <EAChartFilters tab={dashboardTabs[value]?.key} />}
    </>
  );
}

export default DashBoard;
