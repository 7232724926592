import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    popupHeader:{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
    topHeaderIcon:{
        backgroundColor: theme.palette.grey.grey100,
        padding: "9px",
        borderRadius: "50%",
        marginBottom: "1rem",
        width: "55px",
        overflow: "visible",
    },
    popUpFeedIcon:{
        backgroundColor: theme.palette.grey.grey100,
        padding: "0.5rem",
        borderRadius: "50%",
    },
    popUpTitle: {
        fontSize: "20px",
        fontWeight: 600,
        fontFamily: "Metric",
        color: theme.palette.grey.grey700,
        marginBottom: "16px",
    },
    infoSubInput: {
        color: theme.palette.grey.grey500,
        fontWeight: 400,
        fontSize: "10px",
        marginTop: "8px",
        marginBottom: "8px",
    },
    popUpFeedBackCross:{
        position: "absolute",
        top: "1rem",
        right: "1rem",
        cursor: "pointer"
    },
    popUpFeedBackWrapper: {
        display: "flex", gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
        padding: "3rem 0 1rem 0"
    },
    feedBackPopUpTitle: {
        position: "absolute",
        top: "1rem",
        left: "1rem",
    },
    feedBackPopUpContent: {
        color: theme.palette.grey.grey500,
        fontSize: "14px",
    },
    bold: {
        color: theme.palette.grey.grey500,
        fontWeight: 700,
    },
}));

export default useStyles;
