import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    thirdPartieCodeWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "0.5rem",
    },
    thirdPartieCode: {
        color: theme.palette.grey.grey400,
        backgroundColor: theme.palette.grey.grey100 + "80", // 50% opacity on color
        borderRadius: "16px",
        padding: "0 1rem",
        lineHeight: "300%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        display: "-webkit-box",
        wordBreak: "break-all",
        fontSize: "0.8125rem",
        width: "100%"
    },
    thirdPartieCodeIcon:{
        backgroundColor: theme.palette.grey.grey100,
        borderRadius: "16px",
        padding: "0.35rem",
        marginLeft: "1rem",
    },
    thirdPartieIdContent: {
        color: "black",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        display: "-webkit-box",
        wordBreak: "break-all",
        fontSize: "0.8125rem",
    },
}));

export default useStyles;
