import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  icon: {
    width: "20px"
  },
  iconWrapper: {
    backgroundColor: theme.palette.primary.primary200,
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "28px",
    height: "28px",
    marginRight: "5px",
  },
  title:{
    display: "flex",
    alignItems: "center",
    fontSize: "25px",
    color: theme.palette.grey.grey800,
  },
  resultCount: {
    color: theme.palette.grey.grey300,
    fontSize: "12px",
    fontFamily : "Roboto",
    fontWeight: 700,
    position: "relative",
    top: "2px",
  },
  separator: {
    display: "inline-block",
    height: "20px",
    borderLeft: "1px solid " + theme.palette.grey.grey300,
    margin: "0 10px",
  },
  totalCount: {
    fontWeight: 400,
  },
  notificationCounter: {
    width: "16px",
    height: "16px",
    backgroundColor: theme.palette.error.error500,
    color: "#F4F4F4",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    position: "absolute",
    left: "20px",
    bottom: "16px",
  },
  disabledTitle: {
    opacity: 0.6
  }
}));

export default useStyles;
