import EADialog from "../common/EADialog";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "../../style/js-style/components/common/dossiers/DossierModifyDialogStyle";
import i18next from "i18next";
import EADatePicker from "../common/EADatePicker";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { EDGE_DATE_FORMAT, ERROR, FREE } from "../../services/common/Constants";
import { Context } from "../../states/Store";
import {
  getDossierSheet,
  updateDossier,
} from "../../services/edge/DossierService";
import EAIcon from "../common/EAIcon";
import _ from "lodash"
import {useRequestLoading} from "../common/hooks/useRequestLoading";
import { localizeTranslate } from "i18n/LocalizeUtils";

const FORM_ID = "dossier-modify-dialog-id";
const referenceMaxLength = 100;

const DossierModifyDialog = ({ dossier, isOpen, handleClose }) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [typeDossier, setTypeDossier] = useState(FREE);

  const formMethods = useForm({ mode: "onChange" });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const initTypeDossier = useCallback(() => {
    if (!dossier.dossierTypeCode) {
      setTypeDossier(FREE);
    } else {
      setTypeDossier(dossier.dossierTypeCode);
    }
  }, [dossier]);

  useEffect(() => {
    initTypeDossier();
  }, [initTypeDossier]);

  const handleUpdateDossier = async (data) => {
    if (_.isEmpty(errors)) {
      const dossierToUpdate = {
        dossierReference: data.dossierReference,
        expirationDate: data.expirationDate
          ? moment(data.expirationDate).format(EDGE_DATE_FORMAT)
          : null,
        dossierTypeCode: typeDossier,
        description : data.dossierDescription
      };
      try {
        startLoading();
        await updateDossier(
          state.account.id,
          dossier.dossierId,
          dossierToUpdate,
          dispatch
        );
        const result = await getDossierSheet(
          state.account.id,
          dossier.dossierId,
          i18next.language,
          dispatch
        );
        dispatch({ type: "DOSSIER", dossier: result.content });
        handleClose();
      } catch (error) {
        const err =
          error?.message?.messages?.error && error?.message?.messages?.error[0];
        dispatch({
          type: "ALERT",
          alert: { type: ERROR, msg: err?.code || "UPDATE_DOSSIER_ERROR" },
        });
      }finally {
        stopLoading();
      }
    }
  };

  return (
    <>
      <EADialog
        isOpen={isOpen}
        onCancel={handleClose}
        maxWidth={false}
        fullWidth
        formId={FORM_ID}
        isFixed
        isValidateDisabled={!_.isEmpty(errors) || isRequestLoading}
      >
        <form id={FORM_ID} onSubmit={handleSubmit(handleUpdateDossier)}>
          <Grid container className={classes.popUpModify}>
            {/* Title */}
            <Grid item xs={4}>
              <EAIcon icon={"edit_green"} />
              <Typography variant="h3">
                {i18next.t("dossierSheet.modifyFolderInfoTitle")}
              </Typography>
            </Grid>

            {/* Input */}
            <Grid item xs={4} className={classes.inputWrapper}>
              {/* Input : Folder */}
              <label htmlFor="folder" className={classes.label}>
                {i18next.t("dossierSheet.folder")}
              </label>
              <Controller
                control={control}
                name="dossierType"
                render={({ field }) => (
                  <TextField
                    id="dossier-type-input-id"
                    select
                    className={classes.folderInput}
                    variant="outlined"
                    fullWidth
                    {...field}
                    onChange={(e) => {
                      setTypeDossier(e.target.value);
                    }}
                    value={typeDossier}
                  >
                    <MenuItem key="empty" value={FREE}>
                      {i18next.t("free")}
                    </MenuItem>
                    {state.dossierTypes?.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.libelle}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {/* Input : Reference */}
              <label htmlFor="dossierReference" className={classes.label}>
                {i18next.t("dossierSheet.reference")}*
              </label>
              <Controller
                control={control}
                name="dossierReference"
                defaultValue={dossier.dossierReference}
                rules={{
                  required: i18next.t("infos.required"),
                  maxLength: {
                    value: referenceMaxLength,
                    message: i18next.t("infos.E011_VAR", {
                      input: i18next.t("theReference"),
                      maxLength: referenceMaxLength,
                    }),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    id="dossier-type-input-id"
                    variant="outlined"
                    className={classes.referenceInput}
                    {...field}
                    error={!!errors.dossierReference}
                    helperText={
                      !!errors.dossierReference
                        ? errors.dossierReference.message
                        : ""
                    }
                  />
                )}
              />

              {/* Input : End date */}
              <label htmlFor="expirationDate" className={classes.label}>
                {i18next.t("dossierSheet.expirationDate")}
              </label>
              <Controller
                control={control}
                name="expirationDate"
                defaultValue={dossier.expirationDate || null}
                render={({ field }) => (
                  <EADatePicker
                    minDate={moment().add(1, "days")}
                    formMethods={formMethods}
                    sxClass={{
                      width: "200px",
                      "& .MuiInputLabel-root": {
                        left: "31px",
                        top: "-9px"
                      },
                      "& .MuiInputLabel-shrink": {
                        left: "0px",
                        top: "0px"
                      },
                    }}
                    label={i18next.t("dossierSheet.expirationDateLabel")}
                    id="expirationDate"
                    getOpenDialogAriaText={() =>
                      `Choose date, selected date, 'fullDate')}`
                    }
                    {...field}
                    ref={null}
                  />
                )}
              />

              {/* Input : Description */}
              <label htmlFor="dossierReference" className={classes.label}>
                {i18next.t("dossierSheet.descriptionInput")}
              </label>

              <Controller
                control={control}
                name="dossierDescription"
                defaultValue={dossier.description || undefined}
                rules={{
                  maxLength: {
                    value: 300,
                    message: i18next.t("infos.E011_VAR", {
                      input: i18next.t("TheDescription"),
                      maxLength: 300,
                    }),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    id="description-type-input-id"
                    variant="outlined"
                    className={classes.descriptionInput}
                    {...field}
                    error={!!formMethods.formState.errors.dossierDescription}
                    helperText={
                      !!formMethods.formState.errors.dossierDescription
                        ? formMethods.formState.errors.dossierDescription.message
                        : ""
                    }
                  />
                )}
              />
            </Grid>

            {/* Documents list*/}
            {typeDossier !== FREE && (
              <Grid item xs={4}>
                <h3>{i18next.t("dossierSheet.documentsList")}</h3>
                <div className={classes.documentsListWrapper}>
                  {state.dossierTypes
                    ?.filter((d) => d.code === typeDossier)[0]
                    ?.requirements?.map((document, index) => (
                      <div className={classes.documentItem} key={index}>
                        <EAIcon icon={"document_grey"} />
                        <span>{localizeTranslate(document.descriptionFr)}</span>
                      </div>
                    ))}
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      </EADialog>
    </>
  );
};

export default DossierModifyDialog;
