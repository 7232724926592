import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from "@mui/material";
import i18next from "i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useStyles from "../style/js-style/components/EAAccordionStyle.js";
import TitleWithIcon from "./common/TitleWithIcon";

function EAAccordion({
  title,
  buttons,
  children,
  logo,
  counter,
  totalCount,
  opened = false,
  disabled = false,
  notificationCounter = 0
}) {
  const { classes } = useStyles();
  const [isShown, setIsShown] = useState(opened);
  return (
    <Accordion elevation={0} className={classes.root} expanded={isShown}>
      <AccordionSummary
        onClick={(e) => { e.preventDefault(); setIsShown(!isShown); }}
        className={classes.summary}
        expandIcon={<><KeyboardArrowDownIcon /></>}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            className={classes.title}
          >
            <Typography variant="h3">
              <TitleWithIcon
                title={title}
                icon={logo}
                resultCount={counter}
                totalCount={totalCount}
                variant={"accordion"}
                notificationCounter={notificationCounter}
                disabled={disabled}
              />

              <div className={classes.leftBlock}>
                {buttons?.map((btn, index) => {
                  return <div key={index} onClick={(e) => e.stopPropagation()}>{btn}</div>;
                })}
                {/* Arrow icon */}
                <IconButton
                  size="small"
                  aria-label={i18next.t(isShown ? "hide" : "show")}
                  disabled={disabled}
                  onClick={() => setIsShown(!isShown)}
                >
  
                </IconButton>
              </div>
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <>
          {children}
        </>
      </AccordionDetails>
    </Accordion>
  );
}
export default EAAccordion;
