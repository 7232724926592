import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  wrapper: {
    backgroundColor: "transparent",
    paddingTop: "0.25rem",
    paddingRight: "0.25rem",
    color: "##373534",
    fontFamily: "Metric",
    fontWeight: 600,
    fontSize: "18px",
    display: "flex"
  },
  isHovered: {
    backgroundColor: "#EFFBF1",
  },
  isHoveredSimple : {
    cursor: 'pointer',
  }
}));

export default useStyles;
