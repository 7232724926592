import { makeStyles } from 'tss-react/mui';
import {darkGrey} from "../../../../globalStyle";

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: "1.5rem",
    marginBottom: "1.5rem",
  },
  inputLabel: {
    marginBottom: "0.5rem",
    fontSize: "0.75rem",
    color: darkGrey,
  },
  select: {
    "& .MuiSelect-root": {
      paddingTop: "9px",
      paddingBottom: "9px",
    },
  },
}));

export default useStyles;
