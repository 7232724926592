import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey.grey100,
    height: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.7,
    boxShadow: "none",
  },
  label: {
    fontWeight: 600,
    color: theme.palette.grey.grey400,
    fontSize: "18px",
  },
  icon: {
    width: "42px",
    marginBottom: "15px",
  },
}));

export default useStyles;
