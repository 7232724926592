import React, { useState } from "react";
import { Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import useStyles from "style/js-style/containers/administration/roles/privileges/ConfigurationDropStyle";
import SwitchButton from "containers/administration/roles/privileges/common/SwitchButton";
import ConfigurationLine from "containers/administration/roles/privileges/common/ConfigurationLine";
import EAIcon from "components/common/EAIcon";


function ConfigurationDrop({ title, actions, update, type, authorizedDocumentTypes }) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const max1420screen = useMediaQuery("(max-width:1420px)");

  return (
    <div className={classes.root}>

      {/* Header */}
      <EAIcon icon="arrow/arrow-to-up_large" className={isOpen ? classes.arrowIcon : classes.arrowIconReverse} />
      <div className={classes.headerWrapper} onClick={() => setIsOpen(!isOpen)}>
        <div className={!max1420screen ? classes.titleWithIconWrapper : classes.titleWithIconWrapperMin}>
          <div className={classes.title}>
            {title}
          </div>
        </div>
        <div className={classes.blocWrapper}>
          <div className={classes.buttonWrapper}>
            {actions.rootActions.map((item, index) => (
              <SwitchButton key={index} name={item.name} action={item.action} isActive={item.isActive} isDisabled={!item.isGrantable} />
            ))}
          </div>
        </div>
      </div>
      {/* Content */}
      {isOpen &&
        <div className={classes.subItemsWrapper}>
          <Divider />
          {actions.items.map((item, index) => (
            <ConfigurationLine
              key={index}
              type="subItem"
              title={item.name}
              actions={item.actions}
            />
          ))}
          <ConfigurationLine
            actions={actions.actions}
            title="Actions"
            subActions={actions.subActions}
            type={type}
            authorizedDocumentTypes={authorizedDocumentTypes}
          />
        </div>
      }
    </div>
  )
}

export default ConfigurationDrop;
