import React, {useContext, useState} from "react";
import i18next from "i18next";
import {Context} from "../../../states/Store";
import {ERROR} from "../../../services/common/Constants";
import {deleteAttributes} from "../../../services/edge/AttributesService";
import EADialog from "../../../components/common/EADialog";
import useStyles from "../../../style/js-style/containers/administration/attributes/AttributeDeleteDialogStyle";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import EAIcon from "../../../components/common/EAIcon";
import RecapBloc from "../../../components/attributes/RecapBloc";
import {isEmpty} from "lodash";

const AttributeDeleteDialog = ({
  isOpen,
  attributesList,
  onClose,
  onValidateSuccess,
  bondType,
}) => {
  const [state, dispatch] = useContext(Context);
  const [isDeleteDone, setIsDeleteDone] = useState(false);
  const isMultipleAttributesSelected = attributesList?.length !== 1;
  const { classes } = useStyles();

  const handleDelete = async () => {
    try {
      const attributesIdsToDelete = attributesList?.map((x) => x.id);
      await deleteAttributes(state.account.id, attributesIdsToDelete, dispatch);
      setIsDeleteDone(true);
      onValidateSuccess();
    } catch (error) {
      dispatch({ type: "ALERT", alert: { type: ERROR, msg: "DELETE_ERROR" } });
    }
  };

  const isConcernedByRule = () => {
    for (let attribute of attributesList) {
      if (attribute?.referencingRules && attribute?.referencingRules.length > 0) {
        return true;
      }
    }
    return false;
  }

  // Vérifie si l'attribut est utilisé par un calcul
  const isConcernedByCalcul = () => {
    for (let attribute of attributesList) {
      if (attribute?.referencingCalculations && attribute?.referencingCalculations.length > 0) {
        return true;
      }
    }
  }

  const formatRuleList = () => {
    return [...new Set(attributesList?.filter(attribute => !isEmpty(attribute.referencingRules))
      .flatMap(x => x.referencingRules))]
      .map(val => ({value: val}));
  }

  const formatCalculList = () => {
    return [...new Set(attributesList?.filter(attribute => !isEmpty(attribute.referencingCalculations))
      .flatMap(x => x.referencingCalculations))]
      .map(val => ({value: val}));
  }

  const formatAttributeList = () => {
    return attributesList?.map(attribute=> ({value: attribute.label}));
  }

  return (
    <EADialog
      isOpen={isOpen}
      onCancel={onClose}
      onValidate={handleDelete}
      hasActions={!isDeleteDone}
      maxWidth="popupsm"
      fullWidth
      variant="redValid"
      btnSize="large"
      isFeedBack={isDeleteDone}
    >

      {/* After confirm */}
      {isDeleteDone && (
        <FeedBackPopUp
          onClick={onClose}
          title={
            isMultipleAttributesSelected
              ? i18next.t("attributes.deleteAttributes")
              : i18next.t("attributes.deleteAttribute")
          }
          imgSrc={"/img/icons/delete_red.svg"}
          variant={"warning"}
          isMultipleAttributesSelected={isMultipleAttributesSelected}
          imgSize={"small"}
        />
      )}

      {/* Before confirm */}
      {!isDeleteDone && (
        <>
          <div className={classes.root}>
              <>
              <div className={classes.imgWrapperMulti}>
                <EAIcon icon={"delete_red"} className={classes.imgContentMulti} />
              </div>
                <span className={classes.bolder}> {i18next.t("attributes.deleteConfirm")} </span>
                { isConcernedByCalcul() ? (
                  <span className={classes.rootMessageMulti}> {i18next.t("attributes.deleteAttributeWithCalcul")} </span>
                ) : isConcernedByRule() && (
                  <span className={classes.rootMessageMulti}> {i18next.t("attributes.deleteAttributeWithRule")} </span>
                )}
              </>
          </div>
          <div className={classes.attributeInfoBlocWrapper} >
          <div className={classes.attributeInfoBlocContent}>
          <RecapBloc
              icon="delete_red"
              extension="svg"
              text={i18next.t("attributes.deleteAll")}
              values={formatAttributeList()}
            />
            </div>
            { isConcernedByCalcul() && (
              <div className={classes.attributeInfoBlocContent}>
                <RecapBloc
                  icon="pause_circle_filled"
                  extension="svg"
                  text={i18next.t("attributes.deactivateCalcul")}
                  values={formatCalculList()}
                />
              </div>
              )}
            { isConcernedByRule() && (
              <div className={classes.attributeInfoBlocContent}>
                <RecapBloc
                  icon="pause_circle_filled"
                  extension="svg"
                  text={i18next.t("attributes.deactivateRule")}
                  values={formatRuleList()}
                />
              </div>
              )
            }
          </div>
        </>
      )}
    </EADialog>
  );
};

export default AttributeDeleteDialog;
