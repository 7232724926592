import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  addDossBtn: {
    color: "#2D9F40",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Metric",
  },
  removeBtnContainer: {
    minWidth: "40px",
  },
  removeBtn: {
    padding: 0,
  },
  formContainer: {
    backgroundColor: "#F2F3F7",
    border: `1px solid #F2F3F7`,
    borderRadius: "5px",
    paddingBottom: "24px",
    paddingTop: "24px",
    marginLeft: "18px",
    marginTop: "28px",
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.white,
    },
  },
  advancedSearchToggle: {
    color: theme.palette.grey.grey500,
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "600",
    minWidth: "106px"
  },
  advancedSearchIcon: {
    alignSelf: "flex-end",
  },
  advancedSearchContainer: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    marginLeft: "16px",
    marginTop: "16px",
    border: `1px solid ${theme.palette.grey.grey100}`,
    borderRadius: "5px",
  },
  mainSearchDivider: {
    width: "98%",
    margin: "16px",
    borderColor: "#E2E2EA",
  },
  advancedSearchDivider: {
    width: "99%",
    margin: "16px",
    borderColor: "#E2E2EA",
  },
}));

export default useStyles;