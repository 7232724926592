import React from "react";
import i18next from "i18next";
import { useFormContext, Controller } from "react-hook-form";
import _ from "lodash";

import {
  CalculationResultType,
  DocumentTypePropertyType,
} from "services/common/CalculatorUtils";
import CalculatorSelectInput from "components/calculator/CalculatorSelectInput";
import CalculatorStandardInput from "components/calculator/CalculatorStandardInput";
import EAInputBloc from "components/common/EAInputBloc";
import EADatePicker from "components/common/EADatePicker";
import {
  AttributeType,
  FieldType,
  Operator,
  REGEXP_DECIMAL,
  REGEXP_INTEGER,
  REGEXP_PERCENTAGE
} from "services/common/Constants";
import CalculatorMultipleSelectInput from "components/calculator/CalculatorMultipleSelectInput";
import CalculatorMultipleValueInput from "components/calculator/CalculatorMultipleValueInput";
import EAAutoCompleteInput from "components/inputs/EAAutoCompleteInput";


const CriterionValues = ({
  fieldName,
  showLabel,
  selectedFieldType,
  selectedFieldSubType,
  selectedOperator,
  availableValueOptions,
  variantAutoComplete
}) => {
  const formMethods = useFormContext();
  const {
    setError,
    clearErrors,
  } = formMethods;

  switch (selectedFieldType) {
    case FieldType.TEXT:
    case FieldType.ACTIVITY:
      return (
        <>
          {(() => {
            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
                return !_.isEmpty(availableValueOptions) ? (
                  variantAutoComplete === true ?
                    <EAInputBloc
                      label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                      customLabelStyle={{ fontSize: "14px" }}>
                      <EAAutoCompleteInput
                        fieldName={`${fieldName}.${0}`}
                        options={availableValueOptions}
                        placeholder={i18next.t(`rules.configuration.criteria.fields.value.autocompletePlaceholder`)}
                        isRequired
                        multiple={false}
                      />
                    </EAInputBloc>
                    :
                    <CalculatorSelectInput
                      fieldName={`${fieldName}.${0}`}
                      label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                      placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                      options={availableValueOptions}
                      uxVariant="criteriaInput"
                      variant="standard"
                      isRequired
                      validate={() => {
                        return null;
                      }}
                      labelVariant="chip"
                    />

                ) : (
                  <CalculatorStandardInput
                    fieldName={`${fieldName}.${0}`}
                    isRequired
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.indicateValue`)}
                    uxVariant="criteriaInput"
                    variant="standard"
                    validate={() => {
                      return null;
                    }}
                  />
                );

              case Operator.CONTAINS:
              case Operator.NOT_CONTAINS:
              case Operator.STARTS_WITH:
              case Operator.NOT_STARTS_WITH:
              case Operator.ENDS_WITH:
              case Operator.NOT_ENDS_WITH:
                return (
                  <CalculatorStandardInput
                    fieldName={`${fieldName}.${0}`}
                    isRequired
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.indicateValue`)}
                    uxVariant="criteriaInput"
                    variant="standard"
                    validate={() => {
                      return null;
                    }}
                  />
                );

              case Operator.IS_NULL:
              case Operator.NOT_NULL:
                return (<></>);

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                return !_.isEmpty(availableValueOptions) ? (
                  variantAutoComplete === true ?
                    <EAInputBloc label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                      customLabelStyle={{ fontSize: "14px" }}>
                      <EAAutoCompleteInput
                        fieldName={`${fieldName}`}
                        options={availableValueOptions}
                        placeholder={i18next.t(`rules.configuration.criteria.fields.value.autocompletePlaceholder`)}
                        isRequired
                        multiple={true}
                      />
                    </EAInputBloc>
                    :
                    <CalculatorMultipleSelectInput
                      fieldName={`${fieldName}`}
                      isRequired
                      label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                      placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                      options={availableValueOptions}
                      uxVariant="criteriaInput"
                      variant="standard"
                      validate={(value) => {
                        if (value.length === 0) {
                          setError(`${fieldName}`, {
                            type: 'manual',
                            message: i18next.t(`calculator.errors.criterionValueEmpty`)
                          });
                          return false;
                        } else {
                          clearErrors(`${fieldName}`);
                          return true;
                        }
                      }}
                    />
                ) : (
                  <CalculatorMultipleValueInput
                    fieldName={`${fieldName}`}
                    isRequired
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    uxVariant="criteriaInput"
                    validate={(value) => {
                      if (value.length === 0) {
                        setError(`${fieldName}`, {
                          type: 'manual',
                          message: i18next.t(`calculator.errors.criterionValueEmpty`)
                        });
                        return false;
                      } else {
                        clearErrors(`${fieldName}`);
                        return true;
                      }
                    }}
                  />
                );

              default:
                return (<></>);
            }
          })()}
        </>
      );

    case FieldType.NUMERIC:
      return (
        <>
          {(() => {
            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
                return !_.isEmpty(availableValueOptions) ? (
                  <CalculatorSelectInput
                    fieldName={`${fieldName}.${0}`}
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    options={availableValueOptions}
                    uxVariant="criteriaInput"
                    variant="standard"
                    isRequired
                    validate={() => {
                      return null;
                    }}
                    labelVariant="chip"
                  />
                ) : (
                  <CalculatorStandardInput
                    fieldName={`${fieldName}.${0}`}
                    isRequired
                    uxVariant="criteriaInput"
                    variant="standard"
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    validate={() => {
                      return null;
                    }}
                  />
                );

              case Operator.GREATER_THAN:
              case Operator.GREATER_OR_EQUAL:
              case Operator.LESS_THAN:
              case Operator.LESS_OR_EQUAL:
                return (
                  <CalculatorStandardInput
                    fieldName={`${fieldName}.${0}`}
                    isRequired
                    uxVariant="criteriaInput"
                    variant="standard"
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    validate={() => {
                      return null;
                    }}
                  />
                );

              case Operator.BETWEEN:
                return (
                  <>
                    {/*TODO*/}
                  </>
                );

              case Operator.IS_NULL:
              case Operator.NOT_NULL:
                return (<></>);

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                return !_.isEmpty(availableValueOptions) ? (
                  <CalculatorMultipleSelectInput
                    fieldName={`${fieldName}`}
                    isRequired
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    options={availableValueOptions}
                    uxVariant="criteriaInput"
                    variant="standard"
                    validate={(value) => {
                      if (value.length === 0) {
                        setError(`${fieldName}`, {
                          type: 'manual',
                          message: i18next.t(`calculator.errors.criterionValueEmpty`)
                        });
                        return false;
                      } else {
                        clearErrors(`${fieldName}`);
                        return true;
                      }
                    }}
                  />
                ) : (
                  <CalculatorMultipleValueInput
                    fieldName={`${fieldName}`}
                    isRequired
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    uxVariant="criteriaInput"
                    validate={(value) => {
                      if (value.length === 0) {
                        setError(`${fieldName}`, {
                          type: 'manual',
                          message: i18next.t(`calculator.errors.criterionValueEmpty`)
                        });
                        return false;
                      } else {
                        clearErrors(`${fieldName}`);
                        return true;
                      }
                    }}
                  />
                );

              default:
                return (<></>);

            }
          })()}
        </>
      );

    case FieldType.DATE:
      return (
        <>
          {(() => {
            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
              case Operator.GREATER_THAN:
              case Operator.GREATER_OR_EQUAL:
              case Operator.LESS_THAN:
              case Operator.LESS_OR_EQUAL:
                return (
                  <EAInputBloc label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}>
                    <Controller
                      control={formMethods.control}
                      name={`${fieldName}.${0}`}
                      render={({ field }) => (
                        <EADatePicker
                          name={field.name}
                          formMethods={formMethods}
                          disableHighlightToday
                          isRequired
                        />
                      )}
                    />
                  </EAInputBloc>
                );

              case Operator.BETWEEN:
                return (
                  <>
                    {/*TODO*/}
                  </>
                );

              case Operator.IS_NULL:
              case Operator.NOT_NULL:
                return (<></>);

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                return (
                  <>
                    {/*TODO*/}
                  </>
                );

              default:
                return (<></>);
            }
          })()}
        </>
      );

    case FieldType.BOOLEAN:
    case FieldType.CUSTOM:
    case FieldType.RISK:
      return (
        <>
          {(() => {
            switch (selectedOperator) {
              case Operator.EQUAL:
              case Operator.NOT_EQUAL:
                return (
                  <CalculatorSelectInput
                    fieldName={`${fieldName}.${0}`}
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    options={availableValueOptions}
                    uxVariant="criteriaInput"
                    variant="standard"
                    isRequired
                    validate={() => {
                      return null;
                    }}
                    labelVariant="chip"
                  />
                );

              case Operator.IN_SET:
              case Operator.NOT_IN_SET:
                return (
                  <CalculatorMultipleSelectInput
                    fieldName={`${fieldName}`}
                    isRequired
                    label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                    placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                    options={availableValueOptions}
                    uxVariant="criteriaInput"
                    variant="standard"
                    validate={(value) => {
                      if (value.length === 0) {
                        setError(`${fieldName}`, {
                          type: 'manual',
                          message: i18next.t(`calculator.errors.criterionValueEmpty`)
                        });
                        return false;
                      } else {
                        clearErrors(`${fieldName}`);
                        return true;
                      }
                    }}
                  />
                );

              default:
                return (<></>);
            }
          })()}
        </>
      );

    case FieldType.ATTRIBUTE:
      return (
        <>
          {(() => {
            switch (selectedFieldSubType) {
              case AttributeType.LIST_UNIQUE:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorSelectInput
                              fieldName={`${fieldName}.${0}`}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              isRequired
                              validate={() => {
                                return null;
                              }}
                              uxVariant="criteriaInput"
                              variant="standard"
                              labelVariant="chip"
                            />
                          ) : (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired
                              uxVariant="criteriaInput"
                              variant="standard"
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              validate={() => {
                                return null;
                              }}
                            />
                          );

                        case Operator.CONTAINS:
                        case Operator.NOT_CONTAINS:
                        case Operator.STARTS_WITH:
                        case Operator.NOT_STARTS_WITH:
                        case Operator.ENDS_WITH:
                        case Operator.NOT_ENDS_WITH:
                          return (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired
                              uxVariant="criteriaInput"
                              variant="standard"
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              validate={() => {
                                return null;
                              }}
                            />
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (
                            <>
                              {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                            </>
                          );

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorMultipleSelectInput
                              fieldName={`${fieldName}`}
                              isRequired
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              uxVariant="criteriaInput"
                              variant="standard"
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          ) : (
                            <CalculatorMultipleValueInput
                              fieldName={`${fieldName}`}
                              isRequired
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              uxVariant="criteriaInput"
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case AttributeType.LIST_MULTIPLE:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorSelectInput
                              fieldName={`${fieldName}.${0}`}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              isRequired
                              validate={() => {
                                return null;
                              }}
                              uxVariant="criteriaInput"
                              variant="standard"
                              labelVariant="chip"
                            />
                          ) : (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired
                              uxVariant="criteriaInput"
                              variant="standard"
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              validate={() => {
                                return null;
                              }}
                            />
                          );

                        case Operator.CONTAINS:
                          return (
                            <CalculatorMultipleSelectInput
                              fieldName={`${fieldName}`}
                              isRequired
                              options={availableValueOptions}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              uxVariant="criteriaInput"
                              variant="standard"
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (
                            <>
                              {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                            </>
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case AttributeType.INTEGER:
              case AttributeType.DECIMAL:
              case AttributeType.PERCENTAGE:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return !_.isEmpty(availableValueOptions) ? (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={availableValueOptions}
                                uxVariant="criteriaInput"
                                variant="standard"
                                isRequired
                                validate={(value) => {
                                  switch (selectedFieldSubType) {
                                    case AttributeType.INTEGER:
                                      return value && !value.match(REGEXP_INTEGER)
                                        ? i18next.t("attributes.errors.integer")
                                        : null;
                                    case AttributeType.DECIMAL:
                                      return value && !value.match(REGEXP_DECIMAL)
                                        ? i18next.t("attributes.errors.decimal")
                                        : null;
                                    case AttributeType.PERCENTAGE:
                                      return value && !value.match(REGEXP_PERCENTAGE)
                                        ? i18next.t("attributes.errors.percentage")
                                        : null;
                                    default:
                                      return null;
                                  }
                                }}
                                labelVariant="chip"
                              />
                            ) : (
                              <CalculatorStandardInput
                                fieldName={`${fieldName}.${0}`}
                                isRequired
                                uxVariant="criteriaInput"
                                variant="standard"
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={(value) => {
                                  switch (selectedFieldSubType) {
                                    case AttributeType.INTEGER:
                                      return value && !value.match(REGEXP_INTEGER)
                                        ? i18next.t("attributes.errors.integer")
                                        : null;
                                    case AttributeType.DECIMAL:
                                      return value && !value.match(REGEXP_DECIMAL)
                                        ? i18next.t("attributes.errors.decimal")
                                        : null;
                                    case AttributeType.PERCENTAGE:
                                      return value && !value.match(REGEXP_PERCENTAGE)
                                        ? i18next.t("attributes.errors.percentage")
                                        : null;
                                    default:
                                      return null;
                                  }
                                }}
                              />
                            );

                          case Operator.GREATER_THAN:
                          case Operator.GREATER_OR_EQUAL:
                          case Operator.LESS_THAN:
                          case Operator.LESS_OR_EQUAL:
                            return (
                              <CalculatorStandardInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={availableValueOptions}
                                uxVariant="criteriaInput"
                                variant="standard"
                                isRequired
                                validate={(value) => {
                                  switch (selectedFieldSubType) {
                                    case AttributeType.INTEGER:
                                      return value && !value.match(REGEXP_INTEGER)
                                        ? i18next.t("attributes.errors.integer")
                                        : null;
                                    case AttributeType.DECIMAL:
                                      return value && !value.match(REGEXP_DECIMAL)
                                        ? i18next.t("attributes.errors.decimal")
                                        : null;
                                    case AttributeType.PERCENTAGE:
                                      return value && !value.match(REGEXP_PERCENTAGE)
                                        ? i18next.t("attributes.errors.percentage")
                                        : null;
                                    default:
                                      return null;
                                  }
                                }}
                              />
                            );

                          case Operator.BETWEEN:
                            return (
                              <>
                                {/*TODO: Two values*/}
                              </>
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                              </>
                            );

                          case Operator.IN_SET:
                          case Operator.NOT_IN_SET:
                            return !_.isEmpty(availableValueOptions) ? (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={availableValueOptions}
                                uxVariant="criteriaInput"
                                variant="standard"
                                isRequired
                                validate={(value) => {
                                  let isValid = true;
                                  switch (selectedFieldSubType) {
                                    case AttributeType.INTEGER:
                                      if (value && !value.match(REGEXP_INTEGER)) {
                                        setError(`${fieldName}`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.integer")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    case AttributeType.DECIMAL:
                                      if (value && !value.match(REGEXP_DECIMAL)) {
                                        setError(`${fieldName}`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.decimal")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    case AttributeType.PERCENTAGE:
                                      if (value && !value.match(REGEXP_PERCENTAGE)) {
                                        setError(`${fieldName}`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.percentage")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    default:
                                      break;
                                  }

                                  return isValid;
                                }}
                                labelVariant="chip"
                              />
                            ) : (
                              <CalculatorMultipleValueInput
                                fieldName={`${fieldName}`}
                                isRequired
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                uxVariant="criteriaInput"
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={(value) => {
                                  let isValid = true;
                                  switch (selectedFieldSubType) {
                                    case AttributeType.INTEGER:
                                      if (value && !value.match(REGEXP_INTEGER)) {
                                        setError(`${fieldName}`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.integer")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    case AttributeType.DECIMAL:
                                      if (value && !value.match(REGEXP_DECIMAL)) {
                                        setError(`${fieldName}`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.decimal")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    case AttributeType.PERCENTAGE:
                                      if (value && !value.match(REGEXP_PERCENTAGE)) {
                                        setError(`${fieldName}`, {
                                          type: 'manual',
                                          message: i18next.t("attributes.errors.percentage")
                                        });

                                        isValid = false;
                                      }
                                      break;

                                    default:
                                      break;
                                  }

                                  return isValid;
                                }}
                              />
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              case AttributeType.BOOLEAN:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={[
                                  { value: "true", label: i18next.t("yes") },
                                  { value: "false", label: i18next.t("no") },
                                ]}
                                isRequired
                                validate={() => {
                                  return null;
                                }}
                                uxVariant="criteriaInput"
                                variant="standard"
                                labelVariant="chip"
                              />
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                              </>
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              case AttributeType.DATE:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                        case Operator.GREATER_THAN:
                        case Operator.GREATER_OR_EQUAL:
                        case Operator.LESS_THAN:
                        case Operator.LESS_OR_EQUAL:
                          return (
                            <EAInputBloc label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`} customLabelStyle={{ fontSize: "14px" }}>
                              <Controller
                                control={formMethods.control}
                                name={`${fieldName}.${0}`}
                                render={({ field }) => (
                                  <EADatePicker
                                    {...field}
                                    name={field.name}
                                    formMethods={formMethods}
                                    disableHighlightToday
                                    ref={null}
                                    isRequired
                                  />
                                )}
                              />
                            </EAInputBloc>
                          );


                        case Operator.BETWEEN:
                          return (
                            <>
                              {/*TODO*/}
                            </>
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (<></>);

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return (
                            <>
                              {/*TODO*/}
                            </>
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              default:
                break;
            }
          })()}
        </>
      );

    case FieldType.CALCULATED_ATTRIBUTE:
    case FieldType.INDICATOR:
    case FieldType.SOURCE_VARIABLE:
      return (
        <>
          {(() => {
            switch (selectedFieldSubType) {
              case CalculationResultType.STRING:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorSelectInput
                              fieldName={`${fieldName}.${0}`}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              isRequired
                              validate={() => {
                                return null;
                              }}
                              uxVariant="criteriaInput"
                              variant="standard"
                              labelVariant="chip"
                            />
                          ) : (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired={true}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.indicateValue`)}
                              uxVariant="criteriaInput"
                              variant="standard"
                              validate={() => {
                                return null;
                              }}
                            />
                          );

                        case Operator.CONTAINS:
                        case Operator.NOT_CONTAINS:
                        case Operator.STARTS_WITH:
                        case Operator.NOT_STARTS_WITH:
                        case Operator.ENDS_WITH:
                        case Operator.NOT_ENDS_WITH:
                          return (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired={true}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.indicateValue`)}
                              uxVariant="criteriaInput"
                              variant="standard"
                              validate={() => {
                                return null;
                              }}
                            />
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (<></>);

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorMultipleSelectInput
                              fieldName={`${fieldName}`}
                              isRequired
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              uxVariant="criteriaInput"
                              variant="standard"
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          ) : (
                            <CalculatorMultipleValueInput
                              fieldName={`${fieldName}`}
                              isRequired
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              uxVariant="criteriaInput"
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case CalculationResultType.NUMERIC:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return !_.isEmpty(availableValueOptions) ? (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={availableValueOptions}
                                uxVariant="criteriaInput"
                                variant="standard"
                                isRequired
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                                labelVariant="chip"
                              />
                            ) : (
                              <CalculatorStandardInput
                                fieldName={`${fieldName}.${0}`}
                                isRequired
                                uxVariant="criteriaInput"
                                variant="standard"
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                              />
                            );

                          case Operator.GREATER_THAN:
                          case Operator.GREATER_OR_EQUAL:
                          case Operator.LESS_THAN:
                          case Operator.LESS_OR_EQUAL:
                            return (
                              <CalculatorStandardInput
                                fieldName={`${fieldName}.${0}`}
                                isRequired
                                uxVariant="criteriaInput"
                                variant="standard"
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                              />
                            );

                          case Operator.BETWEEN:
                            return (
                              <>
                                {/*TODO: Two values*/}
                              </>
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS UNDEFINED*/}
                              </>
                            );

                          case Operator.IN_SET:
                          case Operator.NOT_IN_SET:
                            return !_.isEmpty(availableValueOptions) ? (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={availableValueOptions}
                                uxVariant="criteriaInput"
                                variant="standard"
                                isRequired
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                                labelVariant="chip"
                              />
                            ) : (
                              <CalculatorMultipleValueInput
                                fieldName={`${fieldName}`}
                                isRequired
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                uxVariant="criteriaInput"
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={(value) => {
                                  /*TODO*/
                                  return null;
                                }}
                              />
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              case CalculationResultType.BOOLEAN:
                return (
                  <>
                    {
                      (() => {
                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={[
                                  { value: "true", label: i18next.t("yes") },
                                  { value: "false", label: i18next.t("no") },
                                ]}
                                isRequired
                                validate={() => {
                                  return null;
                                }}
                                uxVariant="criteriaInput"
                                variant="standard"
                                labelVariant="chip"
                              />
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS UNDEFINED*/}
                              </>
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              default:
                break;
            }
          })()}
        </>
      );

    case FieldType.FORM_FIELD:
      return (
        <>
          {(() => {
            switch (selectedFieldSubType) {
              case DocumentTypePropertyType.CHAINE:
              case DocumentTypePropertyType.MULTILIGNES:
              case DocumentTypePropertyType.EMAIL:
              case DocumentTypePropertyType.TELEPHONE:
              case DocumentTypePropertyType.IBAN:
              case DocumentTypePropertyType.RADIO_BUTTON:
              case DocumentTypePropertyType.LIST_ITEM:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorSelectInput
                              fieldName={`${fieldName}.${0}`}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              isRequired
                              validate={() => {
                                /*TODO: custom validation if any*/
                                return null;
                              }}
                              uxVariant="criteriaInput"
                              variant="standard"
                              labelVariant="chip"
                            />
                          ) : (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired={true}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.indicateValue`)}
                              uxVariant="criteriaInput"
                              variant="standard"
                              validate={() => {
                                /*TODO: custom validation if any*/
                                return null;
                              }}
                            />
                          );

                        case Operator.CONTAINS:
                        case Operator.NOT_CONTAINS:
                        case Operator.STARTS_WITH:
                        case Operator.NOT_STARTS_WITH:
                        case Operator.ENDS_WITH:
                        case Operator.NOT_ENDS_WITH:
                          return (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired={true}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.indicateValue`)}
                              uxVariant="criteriaInput"
                              variant="standard"
                              validate={() => {
                                /*TODO: custom validation if any*/
                                return null;
                              }}
                            />
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (<></>);

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorMultipleSelectInput
                              fieldName={`${fieldName}`}
                              isRequired
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              uxVariant="criteriaInput"
                              variant="standard"
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          ) : (
                            <CalculatorMultipleValueInput
                              fieldName={`${fieldName}`}
                              isRequired
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              uxVariant="criteriaInput"
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case DocumentTypePropertyType.MONTANT:
              case DocumentTypePropertyType.NOMBRE:
              case DocumentTypePropertyType.DEVISE:
                return (
                  <>
                    {
                      (() => {
                        const validateValue = (value) => {
                          return value && !value.match(REGEXP_DECIMAL)
                            ? i18next.t("attributes.errors.decimal")
                            : null;
                        }

                        switch (selectedOperator) {
                          case Operator.EQUAL:
                          case Operator.NOT_EQUAL:
                            return !_.isEmpty(availableValueOptions) ? (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={availableValueOptions}
                                uxVariant="criteriaInput"
                                variant="standard"
                                isRequired
                                validate={validateValue}
                                labelVariant="chip"
                              />
                            ) : (
                              <CalculatorStandardInput
                                fieldName={`${fieldName}.${0}`}
                                isRequired
                                uxVariant="criteriaInput"
                                variant="standard"
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={validateValue}
                              />
                            );

                          case Operator.GREATER_THAN:
                          case Operator.GREATER_OR_EQUAL:
                          case Operator.LESS_THAN:
                          case Operator.LESS_OR_EQUAL:
                            return (
                              <CalculatorStandardInput
                                fieldName={`${fieldName}.${0}`}
                                isRequired
                                uxVariant="criteriaInput"
                                variant="standard"
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={validateValue}
                              />
                            );

                          case Operator.BETWEEN:
                            return (
                              <>
                                {/*TODO: Two values*/}
                              </>
                            );

                          case Operator.IS_NULL:
                          case Operator.NOT_NULL:
                            return (
                              <>
                                {/*MEANS UNDEFINED*/}
                              </>
                            );

                          case Operator.IN_SET:
                          case Operator.NOT_IN_SET:
                            return !_.isEmpty(availableValueOptions) ? (
                              <CalculatorSelectInput
                                fieldName={`${fieldName}.${0}`}
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                options={availableValueOptions}
                                uxVariant="criteriaInput"
                                variant="standard"
                                isRequired
                                validate={validateValue}
                                labelVariant="chip"
                              />
                            ) : (
                              <CalculatorMultipleValueInput
                                fieldName={`${fieldName}`}
                                isRequired
                                label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                                uxVariant="criteriaInput"
                                placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                                validate={(value) => {
                                  let isValid = true;

                                  if (value && !value.match(REGEXP_DECIMAL)) {
                                    setError(`${fieldName}`, {
                                      type: 'manual',
                                      message: i18next.t("attributes.errors.decimal")
                                    });

                                    isValid = false;
                                  }

                                  return isValid;
                                }}
                              />
                            );

                          default:
                            return (<></>);
                        }
                      })()
                    }
                  </>
                );

              case DocumentTypePropertyType.DATE:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                        case Operator.NOT_EQUAL:
                        case Operator.GREATER_THAN:
                        case Operator.GREATER_OR_EQUAL:
                        case Operator.LESS_THAN:
                        case Operator.LESS_OR_EQUAL:
                          return (
                            <EAInputBloc label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`} customLabelStyle={{ fontSize: "14px" }}>
                              <Controller
                                control={formMethods.control}
                                name={`${fieldName}.${0}`}
                                render={({ field }) => (
                                  <EADatePicker
                                    name={field.name}
                                    formMethods={formMethods}
                                    disableHighlightToday
                                    isRequired
                                  />
                                )}
                              />
                            </EAInputBloc>
                          );

                        case Operator.BETWEEN:
                          return (
                            <>
                              {/*TODO*/}
                            </>
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (<></>);

                        case Operator.IN_SET:
                        case Operator.NOT_IN_SET:
                          return (
                            <>
                              {/*TODO*/}
                            </>
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case DocumentTypePropertyType.CASE_A_COCHER:
                return (
                  <>
                    {(() => {
                      switch (selectedOperator) {
                        case Operator.EQUAL:
                          return !_.isEmpty(availableValueOptions) ? (
                            <CalculatorSelectInput
                              fieldName={`${fieldName}.${0}`}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              options={availableValueOptions}
                              isRequired
                              validate={() => {
                                /*TODO: custom validation if any*/
                                return null;
                              }}
                              uxVariant="criteriaInput"
                              variant="standard"
                              labelVariant="chip"
                            />
                          ) : (
                            <CalculatorStandardInput
                              fieldName={`${fieldName}.${0}`}
                              isRequired
                              uxVariant="criteriaInput"
                              variant="standard"
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              validate={() => {
                                /*TODO: custom validation if any*/
                                return null;
                              }}
                            />
                          );

                        case Operator.CONTAINS:
                          return (
                            <CalculatorMultipleSelectInput
                              fieldName={`${fieldName}`}
                              isRequired
                              options={availableValueOptions}
                              label={`${showLabel? i18next.t("calculator.dialog.create.label.values") : ""}`}
                              uxVariant="criteriaInput"
                              variant="standard"
                              placeholder={i18next.t(`calculator.dialog.create.placeholder.selectValues`)}
                              validate={(value) => {
                                if (value.length === 0) {
                                  setError(`${fieldName}`, {
                                    type: 'manual',
                                    message: i18next.t(`calculator.errors.criterionValueEmpty`)
                                  });
                                  return false;
                                } else {
                                  clearErrors(`${fieldName}`);
                                  return true;
                                }
                              }}
                            />
                          );

                        case Operator.IS_NULL:
                        case Operator.NOT_NULL:
                          return (
                            <>
                              {/*MEANS ATTRIBUTE IS UNDEFINED*/}
                            </>
                          );

                        default:
                          return (<></>);
                      }
                    })()}
                  </>
                );

              case DocumentTypePropertyType.LISTE_CHOIX:
                return (
                  <>
                    {/*TODO*/}
                  </>
                );

              default:
                break;
            }
          })()}
        </>
      );

    default:
      return (
        <></>
      );
  }
}

export default CriterionValues;
