import React, {useContext, useEffect, useState} from "react";
import {
  Grid,
  FormLabel,
  FormGroup,
  InputAdornment,
  IconButton,
  Tooltip,
  TextField,
  Divider,
} from "@mui/material";
import useStyles from "../../../style/js-style/containers/users/user-profile/UserChangePasswordDialogStyle";
import { Controller, useForm } from "react-hook-form";
import i18next from "i18next";
import { Context } from "../../../states/Store";
import { isEmpty } from "../../../services/common/Utils";
import { ERROR } from "../../../services/common/Constants";
import {
  changePassword,
  verifyUserPassword,
} from "../../../services/edge/UserService";
import EADialog from "../../../components/common/EADialog";
import EAIcon from "../../../components/common/EAIcon";
import FeedBackPopUp from "../../../components/FeedBackPopUp";
import { theme } from "../../../style/globalStyle";
import _ from "lodash";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {useRequestLoading} from "../../../components/common/hooks/useRequestLoading";

const PASSWORD_CHECKS = {
  length: ".{10,}", // Length
  upperLowerNumber: "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])", // At least one Uppercase, lowercase and number
  special: "[$@$!*%?_+-]", // Special Character
  repeated: "^(?!.*([a-zA-Z0-9])\\1{2,})", // Not more than 2 repeated Characters
};

const PasswordIcon = ({ show }) => {
  const { classes } = useStyles();
  return (
    <Tooltip title={i18next.t(show ? "show" : "hide")}>
      <span className={classes.passwordIcon}>
        <EAIcon icon={show ? "open-eye" : "closed-eye"} />
      </span>
    </Tooltip>
  );
};

const PasswordCheck = ({ check, label }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.passwordControlLine}>
      {check ? (
        <EAIcon icon={"input-check_checked"} />
      ) : (
        <EAIcon icon={"input-check_unchecked"} />
      )}
      <span className={classes.passwordControlLineText}>{label}</span>
    </div>
  );
};

const InputBloc = ({ label, children }) => {
  const { classes } = useStyles();
  return (
    <div>
      <Grid item xs={12} style={{ marginBottom: "15px" }}>
        <FormLabel className={classes.label}>{label}</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>{children}</FormGroup>
      </Grid>
    </div>
  );
};

const UserChangePasswordDialog = ({ isOpen, handleClose }) => {
  const { classes } = useStyles();
  const { isRequestLoading, startLoading, stopLoading } = useRequestLoading();
  const [state, dispatch] = useContext(Context);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    upperLowerNumber: false,
    special: false,
    repeated: false,
  });
  const id = isOpen ? "filters-dialog" : undefined;
  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    getValues,
    setError,
  } = useForm({ mode: "onChange", delayError: 200 });
  const [disabledValidate, setDisabledValidate] = useState(true);

  useEffect(() => {
      setDisabledValidate(
        !_.isEmpty(formState.errors.currentPassword) ||
        !_.isEmpty(formState.errors.newPassword) ||
        !_.isEmpty(formState.errors.confirmPassword)
      )
  }, [formState]);


  const handleShown = (key) => {
    setShowPassword((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleChangePassword = async (data) => {
    if (isEmpty(errors)) {
      try {
        startLoading();
        await changePassword(
          state.account.id,
          state.user.id,
          data.currentPassword,
          data.newPassword,
          null
        );
        setIsPasswordChanged(true);
      } catch (error) {
        if (
          error?.message?.error &&
          error?.message?.error[0]?.reason?.includes("Wrong password")
        ) {
          setError("currentPassword", {
            message: i18next.t("userProfile.errorIncorrectPassword"),
          });
        } else {
          dispatch({
            type: "ALERT",
            alert: {
              type: ERROR,
              msg: error?.messages?.error
                ? error?.messages?.error[0]
                : "INTERNAL_ERROR",
            },
          });
        }
      }finally {
        stopLoading();
      }
    }
  };

  const checkPassword = (pwd) => {
    // Check the conditions
    let ctr = 0;
    for (const [key, keyRegExp] of Object.entries(PASSWORD_CHECKS)) {
      const currentCheck = new RegExp(keyRegExp).test(pwd);
      if (currentCheck) {
        ctr++;
      }
      setPasswordChecks((prev) => ({
        ...prev,
        [key]: currentCheck,
      }));
    }

    if (ctr < 4) {
      return i18next.t("userProfile.errorNewIncorrectPassword");
    }
  };

  const checkNewPassword = (pwd) => {
    if (_.isEmpty(pwd)) {
      setPasswordChecks((prev) => ({
        ...prev,
        repeated: false,
      }));
      return i18next.t("infos.required");
    }
    const errorMsg = checkPassword(pwd);
    if (errorMsg) return errorMsg;
    return validateUserPwd(pwd, false);
  };

  const handleDialogClose = () => {
    handleClose();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateUserPwd = AwesomeDebouncePromise(
    async (value, isCurrentPwd) => {
      try {
        await verifyUserPassword(state.account.id, state.user.id, value, null);
        if(!isCurrentPwd) {
          return i18next.t("userProfile.errorNewPassword")
        }
      } catch (e) {
        if (e?.message?.error?.[0]?.reason?.includes("Wrong password") && isCurrentPwd) {
          return i18next.t("userProfile.errorIncorrectPassword");
        }
      }
    },
    500
  );

  return (
    <EADialog
      id={id}
      isOpen={isOpen}
      onCancel={handleDialogClose}
      onValidate={handleSubmit(handleChangePassword)}
      style={{ zIndex: 1301 }}
      hasActions={!isPasswordChanged}
      maxWidth={isPasswordChanged ? "popupxs" : "popupsm"}
      fullWidth
      validateBtnLabel={"valid"}
      isValidateDisabled={disabledValidate || isRequestLoading}
      title={
        !isPasswordChanged
          ? i18next.t("userProfile.modifyPasswordTitle")
          : i18next.t("userProfile.passwordIsModified")
      }
      customBtnStyle={{
        [`${theme.breakpoints.up("inch16")}`]: {
          paddingBottom: "20px !important",
        },
      }}
      customDialogStyle={{
        paddingBottom: "5px !important",
      }}
      isFeedBack={isPasswordChanged}
    >
      {/* Main content */}
      <form onSubmit={handleSubmit(handleChangePassword)}>
        <Grid container alignItems="flex-start">
          {isPasswordChanged && (
            <FeedBackPopUp
              onClick={handleDialogClose}
              imgSrc={"/img/icons/valid_green.svg"}
              content={i18next.t("userProfile.passwordChanged")}
              variant="valid"
              customDialogStyle={{
                [`${theme.breakpoints.up("inch16")}`]: {
                  marginBottom: "29px",
                },
              }}
            />
          )}

          {!isPasswordChanged && (
            <>
              {/* Current password */}
              <Grid item xs={12}>
                <InputBloc
                  label={`${i18next.t(
                    "userProfile.currentPasswordInputLabel"
                  )}`}
                >
                  <Controller
                    control={control}
                    name="currentPassword"
                    rules={{
                      required: i18next.t("infos.required"),
                      validate: (value) => validateUserPwd(value, true),
                    }}
                    render={({ field }) => (
                      <TextField
                        style={{ width: "calc(50% - 15px)" }}
                        id="currentPassword"
                        type={showPassword["current"] ? "text" : "password"}
                        variant="outlined"
                        className={classes.input}
                        placeholder={i18next.t(
                          "userProfile.placeHolderOldPassword"
                        )}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={i18next.t("passwordBtnAriaLabel")}
                                onClick={() => handleShown("current")}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                                sx={{
                                  position: "absolute",
                                  right: "16px",
                                }}
                              >
                                <PasswordIcon show={showPassword["current"]} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={!!errors.currentPassword}
                        helperText={
                          !!errors.currentPassword
                            ? errors.currentPassword.message
                            : " "
                        }
                        {...field}
                      />
                    )}
                  />
                </InputBloc>
              </Grid>

              <Divider className={classes.divider} style={{ marginTop: 0 }} />

              {/*  New Password */}
              <Grid
                item
                xs={6}
                className={classes.passwordItem}
                style={{ paddingRight: "15px" }}
              >
                <InputBloc
                  label={`${i18next.t("userProfile.newPasswordInputLabel")}`}
                >
                  <Controller
                    control={control}
                    name="newPassword"
                    rules={{
                      validate: (value) => checkNewPassword(value),
                    }}
                    render={({ field }) => (
                      <TextField
                        id="newPassword"
                        type={showPassword["new"] ? "text" : "password"}
                        variant="outlined"
                        className={classes.input}
                        placeholder={i18next.t(
                          "userProfile.placeHolderNewPassword"
                        )}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={i18next.t("passwordBtnAriaLabel")}
                                onClick={() => handleShown("new")}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                                sx={{
                                  position: "absolute",
                                  right: "16px",
                                }}
                              >
                                <PasswordIcon show={showPassword["new"]} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={!!errors.newPassword}
                        helperText={
                          !!errors.newPassword
                            ? errors.newPassword.message
                            : " "
                        }
                        {...field}
                      />
                    )}
                  />
                </InputBloc>
              </Grid>

              {/*  Confirmation Password */}
              <Grid item xs={6} className={classes.passwordItem}>
                <InputBloc
                  label={`${i18next.t(
                    "userProfile.confirmPasswordInputLabel"
                  )}`}
                >
                  <Controller
                    control={control}
                    name="confirmPassword"
                    rules={{
                      required: i18next.t("infos.required"),
                      validate: () =>
                        getValues("confirmPassword") ===
                        getValues("newPassword")
                          ? null
                          : i18next.t("userProfile.errorConfirmPassword"),
                    }}
                    render={({ field }) => (
                      <TextField
                        id="confirmPassword"
                        type={showPassword["confirm"] ? "text" : "password"}
                        variant="outlined"
                        className={classes.input}
                        placeholder={i18next.t(
                          "userProfile.placeHolderConfirmPassword"
                        )}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={i18next.t("passwordBtnAriaLabel")}
                                onClick={() => handleShown("confirm")}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                                sx={{
                                  position: "absolute",
                                  right: "16px",
                                }}
                              >
                                <PasswordIcon show={showPassword["confirm"]} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={!!errors.confirmPassword}
                        helperText={
                          !!errors.confirmPassword
                            ? errors.confirmPassword.message
                            : " "
                        }
                        {...field}
                      />
                    )}
                  />
                </InputBloc>
              </Grid>

              {/* Info password feedback */}
              <div className={classes.passwordControlWrapper}>
                {Object.keys(passwordChecks)?.map((check) => (
                  <PasswordCheck
                    key={check}
                    check={passwordChecks[check]}
                    label={i18next.t(`userProfile.checks.${check}`)}
                  />
                ))}
              </div>

              <Divider className={classes.divider} />
            </>
          )}
        </Grid>
      </form>
    </EADialog>
  );
};

export default UserChangePasswordDialog;
