import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import { getTooltip } from "../../../services/common/ChartUtils";
import { EALegendChart } from "./EALegendChart";
import { Context } from "../../../states/Store";
import i18next from "i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import useSelectedAccountId from "../hooks/useSelectedAccountId";
import { localizeTranslate } from "i18n/LocalizeUtils";

function EAPieChart({ id, data, tab, subTab }) {
  const [shownSeries, setShownSeries] = useState([]);
  const [state, dispatch] = useContext(Context);
  const seriesRef = useRef();
  const labelRef = useRef();
  const accountId = useSelectedAccountId();

  const theme = useTheme();
  const down1540 = useMediaQuery(theme.breakpoints.down("1540"));

  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    if (data) {
      root.setThemes([am5themes_Animated.new(root)]);
      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          radius: down1540 ? am5.p100 : am5.percent(80),
          innerRadius: down1540 ? am5.p50 : am5.percent(60),
          layout: root.verticalLayout,
        })
      );

      let series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: "value",
          categoryField: "itemLabel",
          legendValueText: "",
        })
      );

      series.set("tooltip", getTooltip(chart));

      // ---- Centered Label ---- //
      const label = root.tooltipContainer.children.push(
        am5.Label.new(root, {
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
        })
      );

      label.set(
        "html",
        `
        <div style="text-align: center">
          <div style="font-size: 14px; color: #7E7E8F">${localizeTranslate('Total')}</div>
          <div style="font-size: 36px; font-family: Metric; font-weight: 600; color: #373534">
            ${data.total}
          </div>
        </div>
      `
      );

      // ---- Slices ---- //
      series.slices.template.setAll({
        strokeOpacity: 0,
        templateField: "settings",
        tooltipText: "[bold]{itemLabel}[/] : {formattedValue}",
        cursorOverStyle: "pointer",
      });
      series.slices.template.states.create("hover", {
        fillOpacity: 0.5,
        scale: 1,
      });
      series.slices.template.states.create("active", {
        shiftRadius: 0,
        fillOpacity: 1,
        fill: am5.color("#FFC90B"),
      });

      series.slices.template.events.on("click", (ev) => {
          const slice = ev.target;
          const active = !slice.get("active");
          const selectedData = slice.dataItem.dataContext;
          dispatch({
            type: active ? "ADD_GRAPH_FILTER" : "REMOVE_GRAPH_FILTER",
            filter: {
              type: "pie",
              accountId,
              tab,
              subTab,
              data: {
                id: selectedData.id,
                type: selectedData.type,
                label: selectedData.itemLabel,
                keyLabel: i18next.t(`dashboard.filters.${selectedData.type}`),
              },
            },
          });
      });

      series.labels.template.setAll({
        templateField: "labelSettings",
        text: "[bold]{valuePercentTotal.formatNumber('0.')}%[/]",
        inside: true,
        baseRadius: am5.percent(60),
      });

      // ---- Hide ticks ---- //
      series.ticks.template.set("forceHidden", true);

      series.data.setAll(data.data);

      series.events.on("datavalidated", (ev) => {
        setShownSeries(ev.target.dataItems);
      });

      series.appear(1000, 100);

      seriesRef.current = series;
      labelRef.current = label;
    }

    return () => root?.dispose();
  }, [id, accountId, tab, subTab, down1540, dispatch, data]);

  // Set active selected items.
  useEffect(() => {
    if (data) {
      const slices = seriesRef.current.slices.values;

      slices?.forEach((slice) => {
        const sliceData = slice.dataItem.dataContext;
        const label = seriesRef.current.labels.values?.find(
          (label) => label.dataItem.dataContext.id === sliceData.id
        );
        const isActive = !!state.graphFilters.pie?.find(
          (x) => x.id === sliceData.id && x.type === sliceData.type
        );
        slice.set("active", isActive);
        label.set("textType", "adjusted")
        if (isActive) {
          label.set("fill", am5.color("#373534"));
        } else {
          label.set("fill", am5.color("#FFF"));
        }
      });
    }
  }, [data, state.graphFilters.pie]);

  return (
    <div
      style={{
        width: "100%",
        height: "500px",
      }}
    >
      <div id={id} style={{ width: "100%", height: "450px" }}></div>
      <EALegendChart
        id={id + "-legend"}
        chartType="pie"
        series={shownSeries}
        tab={tab}
        subTab={subTab}
      />
    </div>
  );
}

export default EAPieChart;
