import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {
        width: "100%",
        padding: "5px 33px 33px 33px",
    },
    inputWrapper: {
        backgroundColor: "rgb(242 243 247 / 17%)",
        border: "1px solid #E2E2EA",
        borderRadius: "9px",
        width: "295px",
        height: "35px",
        display: "flex",
        alignItems: "center",
        alignSelf: "flex-end",
        margin: "12px 0",
        paddingLeft: "10px",
        "& svg": {
            color: "#acacac",
            width: "20px",
        },
        "& .MuiInputBase-root": {
            width: "100%",
        },
        "& input": {
            width: "100%",
            height: "100%",
            color: "#515151",
        },
    },
}));

export default useStyles;
