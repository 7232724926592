import { getEnv } from "config/Config";
import { updateUserLanguage } from "services/edge/UserService";



export const EXCLUDED_TEXTS = [
    "Banque",
    "BIC",
    "Adresse",
    "Code postal",
    "Ville"
]

export const initLocalize = async (userLang, dispatch) => {
    window.Localize.initialize({
        key: getEnv("REACT_APP_LOCALIZE_ID"),
        rememberLanguage: true,
        targetLanguage: userLang,
        blockedClasses: ['dt'],
        translateAlt:false,
        translateMetaTags: false,
        translateNumbers: false,
        translateTitle: false,
        translateAriaLabels: false
    });

    window.Localize.on("setLanguage", function(data) {
        updateUserLanguage(data.to);
        dispatch({ type: "LANG", lang: data.to })
        return data.to;
    });
}

// Use this to translate with localize in JS when needed
export const localizeTranslate = (text) => {
    return window.Localize?.translate(text) || text;
}