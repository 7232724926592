import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root:{
        padding: "2.5rem !important",
    },
    rootFeed:{
        padding: "15px 18px 43px 18px !important",
    },
    cancelBtn: {
        backgroundColor: theme.palette.grey.grey100,
        color: theme.palette.grey.grey600,
        "&:hover": { backgroundColor: theme.palette.grey.grey200, },
    },
    validBtn: {
        backgroundColor: theme.palette.primary.primary600,
        "&:hover": { backgroundColor: theme.palette.primary.primary700, },
        boxShadow: "0px 11.28px 24.231px rgba(45, 159, 64, 0.115017), 0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772), 0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08), 0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228), 0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833)"
    },
    validBtnRed: {
        backgroundColor: theme.palette.error.error600,
        "&:hover": { backgroundColor: theme.palette.error.error700, },
        boxShadow: "0px 11.28px 24.231px rgba(204, 0, 1, 0.11), 0px 6.0308px 12.9551px rgba(204, 0, 1, 0.0953772), 0px 3.38082px 7.2625px rgba(204, 0, 1, 0.08), 0px 1.79553px 3.85706px rgba(204, 0, 1, 0.0646228), 0px 0.747159px 1.60501px rgba(204, 0, 1, 0.0449833)",
    },
    btnWrapper: {
        justifyContent: "center",
        gap: "6px",
        paddingBottom: "2rem",
    },
    btnRightWrapper:{
        justifyContent: "flex-end",
        gap: "6px",
        paddingBottom: "24px",
        paddingRight: "1.5rem",
    },
    title: {
        marginBottom: "29px",
    },
    cross: {
        position: "absolute",
        top: "23px",
        right: "23px",
        cursor: "pointer",
    },
}));

export default useStyles;
