import React, { useContext, useEffect, useState } from "react";
import EAPieChart from "../../../components/common/charts/EAPieChart";
import {
  buildPieStatusData,
  getShownPieDataByBarFilters,
} from "../../../services/common/ChartUtils";
import { isEmpty } from "lodash";
import { Context } from "../../../states/Store";
import ROUTES from "../../../config/Routes";

function DocumentsGlobalStatesChart({documentsCountByStatus}) {
  const [mappedStates, setMappedStates] = useState();
  const [shownStates, setShownStates] = useState();
  const [state] = useContext(Context);

  useEffect(() => {
    setMappedStates(buildPieStatusData(documentsCountByStatus, "documents"));
  }, [documentsCountByStatus]);

  // Filter Data when other graph items are selected
  useEffect(() => {
    if (mappedStates) {
      if (isEmpty(state.graphFilters.bar)) {
        setShownStates(mappedStates);
      } else {
        setShownStates(getShownPieDataByBarFilters(mappedStates.data, state.graphFilters.bar));
      }
    }
  }, [mappedStates, state.graphFilters.bar]);

  return (
    <EAPieChart
      id="documentsPresence"
      data={shownStates}
      tab={ROUTES.DOCUMENTS}
    />
  );
}

export default DocumentsGlobalStatesChart;
