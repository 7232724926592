import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    paperContainer: {
        padding: "28px 22px",
        borderRadius: "12px",
        borderTopLeftRadius: 0,
        minHeight: "500px"
    },
    graphAreaTitle: {
      textTransform: "none",
        color: "#373534",
        fontFamily: "Metric",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: "14px",
    },
    graphPaper: {
        position: "relative",
        borderRadius: "12px",
        padding: "24px 20px 24px 20px",
        backgroundColor: "#FCFDFD",
        border: "1px solid #F2F3F7",
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    },
    tab: {
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "12px 16px 12px 0",
      minWidth: "50px",
      zIndex: 2,
      position: "relative",
      top: "1px"
    },
    unselectedTab: {
      color: theme.palette.grey.grey600,
      borderBottom: "2px solid " + theme.palette.grey.grey200,
    },
    selectedTab: {
      color: theme.palette.primary.primary600,
      borderBottom: "3px solid " + theme.palette.primary.primary600,
    },
}));

export default useStyles;
