import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  // Layout
  mainWrapper:{
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "-16px",
    overflow: "hidden",
  },
  mainContentWrapper:{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "31px",
  },
  cardsWrapper:{
    display: "flex",
    flexDirection: "column",
    gap: "29px",
    minWidth: "300px",
    maxWidth: "400px",
    width: "25%",
  },

  // header
  header:{
    height: "216px",
    borderRadius: "4px",
    border: "1.5px solid white",
    background: "linear-gradient(112.83deg, rgba(255, 255, 255, 0.492) 0%, rgba(255, 255, 255, 0.48) 110.84%)",
    zIndex: 1,
    marginBottom: "20px",
    marginTop: "10px",
  },
  headerBG: {
    borderRadius: "12px",
    height: "92px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    backgroundImage: "url('/img/background/background-profil.png')",
    backgroundPosition: "100% 35%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    [`${theme.breakpoints.up("inch16")}`]: {
      height: "104px",
    },
  },
  headerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: "0 19px",
    marginLeft: "61px",
    position: "relative",
    top: "-35px",
  },
  headerInfoRole: {
    color: theme.palette.grey.grey500,
    fontSize: "14px",
  },
  headerInfoCircle: {
    width: "123px",
    height: "123px",
    borderRadius: "50%",
    border: "4px solid white",
    backgroundColor: theme.palette.primary.primary300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appBarWrapper: {
    position: "relative",
    top: "-6px",
  },

  // Main Content
  form:{
    flexGrow: 1,
    borderRadius: "4px",
    backgroundColor: "white",
    padding: "36px 26px 46px 26px",
    zIndex: 1,

    [`${theme.breakpoints.up("inch16")}`]: {
      padding: "36px 27px",
    },
  },
  password:{
    borderRadius: "4px",
    backgroundColor: "white",
    padding: "18px",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    [`${theme.breakpoints.up("inch16")}`]: {
      padding: "26px 20px",
    },
  },
  unregistered:{
    borderRadius: "4px",
    backgroundColor: "white",
    padding: "18px",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    [`${theme.breakpoints.up("inch16")}`]: {
      padding: "26px 20px",
    },
  },
  // cards content
  cardsTitle:{
    marginBottom: "10px",
  },
  cardsText: {
    color: theme.palette.grey.grey500,
    fontSize: "14px",
    whiteSpace: "pre-line",
  },
  cardsBoldText: {
    fontSize: "14px",
    color: theme.palette.grey.grey700,
    fontWeight: 700,
    whiteSpace: "pre-line",
  },
  btnModify: {
    fontSize: "12px",
    backgroundColor: "rgba(226, 226, 234, 0.5)",
    color: theme.palette.grey.grey500,
    border: 0,
    padding: "8px 31.5px",
    borderRadius: "12px",
    fontWeight: 600,
    marginTop: "10px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0.0px 1.0px 2.0px 0px rgba(0, 0, 0, 0.07)",
    },
  },
  btnUnregistered: {
    fontSize: "12px",
    backgroundColor: "transparent",
    color: theme.palette.grey.grey500,
    border: "1px solid " + theme.palette.grey.grey500,
    padding: "8px 31.5px",
    borderRadius: "12px",
    fontWeight: 600,
    marginTop: "10px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0.0px 1.0px 2.0px 0px rgba(0, 0, 0, 0.07)",
    },
  },

  // Decoration
  circleLeft:{
    top: "-30%",
    left: "-10%",
    background: "radial-gradient(67.22% 50% at 50% 50%, rgba(51, 231, 255, 0.105) 0%, rgba(245, 228, 255, 0) 100%)",
    opacity: 0.9,
  },
  circleRight:{
    top: "-10%",
    left: "45%",
    background: "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.105) 0%, rgba(245, 228, 255, 0) 100%)",
  },
  circle:{
    position: "absolute",
    borderRadius: "50%",
    height: "600px",
    width: "600px",
    zIndex: 0,
  },
  divider:{
    width: "100%",
    margin: "23px 0 34px 0",
  },

  // form contentformTitle
  formLabel: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey.grey500,
  },
  input: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",
      border: "1px solid #E2E2EA",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #E2E2EA !important",
      },
    }
  },
  inputError: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "16px",
      height: "40px",
      border: "1px solid #FFCCCC",
      color: theme.palette.error.error600,

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid #FFCCCC !important",
      },
    },

    "& .Mui-error": {
      color: theme.palette.error.error600,
    },
  },
  inputMSize : {
    width: "90%",
  },
  inputSSize : {
    width: "60%",
  },
  phoneError: {
    color: theme.palette.error.error600,
    position: "relative",
    left: "14px"
  },

  // btn
  cancelBtn: {
    marginRight: "14px",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Metric",
    color: theme.palette.grey.grey500 + " !important",
    backgroundColor: theme.palette.grey.grey100,
    borderRadius: "16px",
    width: "110px",
    padding: "11px 0",

    "&:hover":{
      backgroundColor: theme.palette.grey.grey100,
    }
  },
  validBtn: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Metric",
    color: "white !important",
    backgroundColor: theme.palette.primary.primary600 + " !important",
    borderRadius: "16px",
    boxShadow: "0px 11.28px 24.231px rgba(45, 159, 64, 0.115017), 0px 6.0308px 12.9551px rgba(45, 159, 64, 0.0953772), 0px 3.38082px 7.2625px rgba(45, 159, 64, 0.08), 0px 1.79553px 3.85706px rgba(45, 159, 64, 0.0646228), 0px 0.747159px 1.60501px rgba(45, 159, 64, 0.0449833)",
    width: "110px",
    padding: "11px 0",
  },

  // tab
  tabsWrapper: {
    marginBottom: "17px",
    marginLeft: "5px",
  },
  tab: {
    fontWeight: 700,
    minWidth: "97px",
    minHeight: "10px !important",
    zIndex: 1,
    textTransform: "none",
    fontSize: "14px",
    fontFamily: "Metric",
    padding: "11px !important"
  },
  selectedTab: {
    color: "#2D9F40 !important",
    borderBottom: "2px solid #2D9F40"
  },
  unselectedTab: {
    color: theme.palette.grey.grey600,
  },

  // Mes taches
  myTaskWrapper: {
    background: "linear-gradient(113deg, rgba(215, 244, 220, 0.36) 0%, rgba(215, 244, 220, 0.40) 110.84%)",
    border: "1.5px solid #B0E8B9",
    width: "100%",
    minHeight: "232px",
    marginBottom: "22px",
    display: "flex",
  },
  myTaskMainContentWrapper: {
    flex: 2,
    padding: "10px 8px",
    minWidth: "55vw"
  },
  myTaskMainContent: {
    marginLeft: "49px",
  },
  myTaskMainTitle: {
    color: "#000",
    fontFamily: "Metric",
    fontSize: "28px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    gap: "7px",
    marginBottom: "35px"
  },
  myTaskFrequency: {
    flex: 1,
  },
  myTaskFrequencyTitle: {
    color: "#373534",
    fontFamily: "Metric",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "22px",
    marginTop: "16px"
  },
  myTaskTitleIcon: {
    position: "relative",
    top: "2px"
  },

  // Evenements
  eventWrapper: {
    background: "linear-gradient(113deg, rgba(255, 255, 255, 0.49) 0%, rgba(255, 255, 255, 0.48) 110.84%)",
    border: "1.5px solid white",
    width: "100%",
    display: "flex",
  },
  eventMainContentWrapper: {
    flex: 2,
    padding: "10px 20px 10px 49px",
    zIndex: 9999,
    minWidth: "55vw"
  },
  eventMainContent: {},
  eventMainTitle: {
    color: "#000",
    fontFamily: "Metric",
    fontSize: "28px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    gap: "7px",
    marginBottom: "29px"
  },
  eventFrequency: {
    flex: 1,
  },
  eventFrequencyTitle: {
    color: "#373534",
    fontFamily: "Metric",
    fontSize: "18px",
    fontWeight: 600,
    marginTop: "16px"
  },
  eventTitleIcon: {
    position: "relative",
    top: "2px"
  },

  // Lines
  notificationItemLine: {
    display: "flex",
    marginBottom: "5px",
    marginRight: "29px",
  },
  notificationItemCheck: {
    backgroundColor: "#D7F4DC",
    width: "39px",
    height: "39px",
    borderRadius: "8px 0 0 8px",
    zIndex: 8,
  },
  notificationItemCheckItem: {
    position: "relative",
    left: "10px",
  },
  notificationItemText: {
    padding: "9px",
    borderRadius: "0 8px 8px 0",
    flex: 1,
    color: "#16161E",
    fontSize: "14px",
  },
  notificationLineCheckOverlay: {
    background: "white",
    opacity: "0.2",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0, left: 0, bottom: 0, right: 0,
    zIndex: 9999999,
    cursor: "auto"
  },

  // drop
  dropPanelRoot: {
    backgroundColor: "white",
    marginLeft: "49px",
  },
  subDropPanelRoot: {
    backgroundColor: "rgba(242, 243, 247, 0.50)",
    borderRadius: "8px",
    marginRight: "28px",
    marginBottom: "7px",
    paddingBottom: "7px"
  },
  dropPanelHeader: {
    height: "59px",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    justifyContent: "space-between",
  },
  subDropPanelHeader: {
    height: "39px",
    display: "flex",
    alignItems: "center",
    paddingRight: "16px",
    justifyContent: "space-between",
  },
  dropPanelTitle: {
    color: "#16161E",
    fontFamily: "Metric",
    fontSize: "18px",
    fontWeight: 600,
    textTransform: "uppercase"
  },
  dropPanelCheckAllIcon: {
    marginRight: "10px",
    cursor: "pointer"
  },
  dropPanelActionItem: {
    color: "#656575",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
    cursor: "pointer",
    marginRight: "9px",
  },
  dropPanelContent: {
    marginTop: "15px",
    marginLeft: "18px",
  },
  subDropPanelContent: {
    margin: "15px",
    backgroundColor: "rgba(255, 255, 255, 0.48)",
    border: "1px solid white",
  },
  saveButton: {
    minWidth: "32px !important",
    width: "32px",
    minHeight: "32px !important",
    height: "32px",
    padding: "0 !important",
    borderRadius: "12px !important",
    position: "absolute",
    top: "129px",
    right :"26px",
    zIndex: 2,
  },
}));

export default useStyles;
