import { getEnv } from "../../config/Config";
import { eaFetch } from "../common/ApiUtils";

export async function getAccountAttributesByBondType(accountId, bondType, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/attributes?bondType=${bondType}`
  );
}

export async function getAccountAttributeById(accountId, attributeId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/attributes/${attributeId}`
  );
}

export async function getAccountAttributeByLabelAndBondType(accountId, label, bondType, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/attributes/search?label=${encodeURIComponent(label)}&bondType=${bondType}`
  );
}

export async function createAttribute(accountId, attributeToSave, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/attributes`,
    attributeToSave
  );
}

export async function updateAttribute(accountId, attributeId, attributeToSave, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "PUT",
    `account/${accountId}/attributes/${attributeId}`,
    attributeToSave
  );
}

export async function deleteAttributes(accountId, attributesIdsToDelete, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "DELETE",
    `account/${accountId}/attributes`,
    attributesIdsToDelete
  );
}

export async function getThirdpartyAttributes(accountId, thirdpartyId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/thirdparties/${thirdpartyId}/attributes`
  );
}

export async function addThirdpartyAttribute(accountId, thirdpartyId, attributeToSave, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/thirdparties/${thirdpartyId}/attributes`,
    attributeToSave
  );
}

export async function getDossierAttributes(accountId, dossierId, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "GET",
    `account/${accountId}/dossiers/${dossierId}/attributes`
  );
}

export async function addDossierAttribute(accountId, dossierId, attributeToSave, dispatch) {
  return eaFetch(
    dispatch,
    getEnv("REACT_APP_EDGE"),
    "POST",
    `account/${accountId}/dossiers/${dossierId}/attributes`,
    attributeToSave
  );
}